import { useParams } from "react-router-dom";
import { useQueryClient } from "@tanstack/react-query";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
import { Select } from "@jhool-io/fe-components";
import { useFetchSessionNote } from "../../../../hooks/queries/note";
import { useEditDraftNote } from "../../../../hooks/mutations/note";
import useToast from "../../../../hooks/useToast";
import { useFetchDiagnosisCodes } from "../../../../hooks/queries";

interface EditNoteDiagnosisCodesFormProps {
    onFormSubmit(): void;
}

interface EditDiagnosisCodesPayload {
    diagnosis_codes: string[];
    secondary_diagnosis: string[];
    tertiary_diagnosis: string[];
}

export default function EditNoteDiagnosisCodesForm({
    onFormSubmit,
}: EditNoteDiagnosisCodesFormProps) {
    const params = useParams();
    const noteId = params.noteId as string;
    const clientId = params.clientId as string;

    const { data } = useFetchSessionNote(
        clientId,
        noteId,
        Boolean(clientId) && Boolean(noteId)
    );

    const { data: diagnosisCodes, isLoading, error } = useFetchDiagnosisCodes();

    const {
        handleSubmit,
        control,
        watch,
        formState: { errors },
    } = useForm<EditDiagnosisCodesPayload>({
        resolver: yupResolver(
            yup.object({
                diagnosis_codes: yup
                    .array()
                    .max(1, "You can only select 1 diagnosis")
                    .min(1, "Diagnosis is required")
                    .required("Diagnosis is required"),
                secondary_diagnosis: yup
                    .array()
                    .max(1, "You can only select 1 diagnosis"),
                tertiary_diagnosis: yup
                    .array()
                    .max(1, "You can only select 1 diagnosis"),
            })
        ),
        mode: "onChange",
        defaultValues: data?.data.note_content?.diagnosis_codes
            ? {
                  diagnosis_codes: data?.data.note_content?.diagnosis_codes,
                  secondary_diagnosis: [
                      data?.data.note_content?.secondary_diagnosis,
                  ],
                  tertiary_diagnosis: [
                      data?.data.note_content?.tertiary_diagnosis,
                  ],
              }
            : undefined,
    });

    const editNote = useEditDraftNote();

    const { toast } = useToast();

    const queryClient = useQueryClient();

    const diagnosisCodesFromForm = watch("diagnosis_codes");

    const secondaryDiagnosisCode = watch("secondary_diagnosis");

    const tertiaryDiagnosisCode = watch("tertiary_diagnosis");

    const DiagnosisCodesForSelect = diagnosisCodes?.data.map((diagnosis) => ({
        label: `${diagnosis.code} ${diagnosis.description}`,
        value: diagnosis.code,
    }));

    const onSubmit = (payload: EditDiagnosisCodesPayload) => {
        const dataToSend = {
            noteId: data?.data.note_id || "",
            data: {
                note_content: {
                    ...data?.data.note_content,
                    diagnosis_codes: payload.diagnosis_codes,
                    secondary_diagnosis: payload.secondary_diagnosis[0],
                    tertiary_diagnosis: payload.tertiary_diagnosis[0],
                },
            },
        };

        editNote.mutate(dataToSend, {
            onSuccess: async (res) => {
                await queryClient.invalidateQueries({
                    queryKey: [clientId, `session-note`, noteId],
                });

                toast({
                    mode: "success",
                    message:
                        res.message ||
                        "Note diagnosis codes edited successfully",
                });

                onFormSubmit();
            },
            onError: (err) => {
                toast({
                    mode: "error",
                    message:
                        err.response?.data.message ||
                        "Could not edit disgnosis codes at this time",
                });
            },
        });
    };

    return (
        <form id="edit-diagnosis-codes" onSubmit={handleSubmit(onSubmit)}>
            <div className="fg">
                <Controller
                    name="diagnosis_codes"
                    control={control}
                    render={({ field }) => (
                        <Select
                            label="Primary diagnosis"
                            placeholder={
                                (!data && isLoading && "Loading...") ||
                                (error &&
                                    !isLoading &&
                                    "Error loading diagnosis") ||
                                (data && !isLoading && "Primary diagnosis")
                            }
                            isSearchable
                            onChange={(val) => {
                                field.onChange(
                                    (
                                        val as {
                                            label: string;
                                            value: string;
                                        }[]
                                    ).map((code) => code.value)
                                );
                            }}
                            options={DiagnosisCodesForSelect}
                            isDisabled={isLoading || Boolean(error)}
                            hasError={!!errors.diagnosis_codes}
                            value={
                                DiagnosisCodesForSelect?.filter((diagnosis) =>
                                    field.value?.includes(diagnosis.value)
                                ) || null
                            }
                            errorText={
                                errors.diagnosis_codes?.type === "typeError"
                                    ? "Diagnosis codes are required"
                                    : errors.diagnosis_codes?.message
                            }
                            isMulti
                            multiHasValues={
                                diagnosisCodesFromForm &&
                                diagnosisCodesFromForm.length > 0
                            }
                            closeMenuOnSelect
                            isLongListInDialog
                        />
                    )}
                />
            </div>
            <div className="fg">
                <Controller
                    name="secondary_diagnosis"
                    control={control}
                    render={({ field }) => (
                        <Select
                            label="Secondary diagnosis"
                            placeholder={
                                (!data && isLoading && "Loading...") ||
                                (error &&
                                    !isLoading &&
                                    "Error loading diagnosis") ||
                                (data && !isLoading && "Secondary diagnosis")
                            }
                            isSearchable
                            onChange={(val) => {
                                field.onChange(
                                    (
                                        val as {
                                            label: string;
                                            value: string;
                                        }[]
                                    ).map((code) => code.value)
                                );
                            }}
                            options={DiagnosisCodesForSelect}
                            isDisabled={isLoading || Boolean(error)}
                            hasError={!!errors.secondary_diagnosis}
                            isMulti
                            value={
                                DiagnosisCodesForSelect?.filter((diagnosis) =>
                                    field.value?.includes(diagnosis.value)
                                ) || null
                            }
                            errorText={errors.secondary_diagnosis?.message}
                            isLongListInDialog
                            multiHasValues={
                                secondaryDiagnosisCode &&
                                secondaryDiagnosisCode.length > 0
                            }
                            closeMenuOnSelect
                        />
                    )}
                />
            </div>
            <div>
                <Controller
                    name="tertiary_diagnosis"
                    control={control}
                    render={({ field }) => (
                        <Select
                            label="Tertiary diagnosis"
                            placeholder={
                                (!data && isLoading && "Loading...") ||
                                (error &&
                                    !isLoading &&
                                    "Error loading diagnosis") ||
                                (data && !isLoading && "Tertiary diagnosis")
                            }
                            isSearchable
                            onChange={(val) => {
                                field.onChange(
                                    (
                                        val as {
                                            label: string;
                                            value: string;
                                        }[]
                                    ).map((code) => code.value)
                                );
                            }}
                            options={DiagnosisCodesForSelect}
                            isDisabled={isLoading || Boolean(error)}
                            hasError={!!errors.tertiary_diagnosis}
                            value={
                                DiagnosisCodesForSelect?.filter((diagnosis) =>
                                    field.value?.includes(diagnosis.value)
                                ) || null
                            }
                            errorText={errors.tertiary_diagnosis?.message}
                            isLongListInDialog
                            multiHasValues={
                                tertiaryDiagnosisCode &&
                                tertiaryDiagnosisCode.length > 0
                            }
                            isMulti
                        />
                    )}
                />
            </div>
        </form>
    );
}
