import { useQuery } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { handleRemoveEmptyParamFromQueryParams } from "../../../../utils/helpers";
import {
    IApiResponse,
    IPaginatedApiResponse,
} from "../../../../utils/types/api-response";
import {
    exportBillingSummaryReport,
    fetchBillingQueue,
    getInvoicePayment,
} from "../../services/billing.services";
import {
    IBillingSummaryFilters,
    IBillingFilterTypes,
    IBillingResponse,
    IGetPayment,
    IGetPaymentResponse,
    IBillingResponseSummary,
} from "../../types/billing.types";

// Fetch billing queue hook
export function useFetchInvoices(
    filters?: IBillingFilterTypes,
    enabled?: boolean
) {
    interface BillingApiResponse<DataType>
        extends IPaginatedApiResponse<DataType> {
        summary: IBillingResponseSummary;
    }

    return useQuery<
        BillingApiResponse<IBillingResponse[]>,
        AxiosError<IApiResponse<IBillingResponse[]>>
    >({
        queryKey: [
            "invoices",
            handleRemoveEmptyParamFromQueryParams(filters || {}),
        ],
        queryFn: () => fetchBillingQueue(filters),
        enabled,
    });
}

//  get invoice payment
export function useGetInvoicePayment(
    invoiceId: string,
    payload: IGetPayment,
    enabled?: boolean
) {
    return useQuery<IGetPaymentResponse, AxiosError<IGetPaymentResponse>>({
        queryKey: [
            "get-payment",
            handleRemoveEmptyParamFromQueryParams(payload),
        ],
        queryFn: () => getInvoicePayment(invoiceId, payload),
        enabled,
    });
}

export function useExportBillingSummaryReport(
    filters: IBillingSummaryFilters,
    isEnabled: boolean
) {
    return useQuery<Blob, AxiosError<string>>({
        queryKey: [
            "billing-summary-report",
            handleRemoveEmptyParamFromQueryParams(filters),
        ],
        queryFn: () =>
            exportBillingSummaryReport(
                handleRemoveEmptyParamFromQueryParams(filters)
            ),
        enabled: isEnabled,
        onSuccess: (data) => {
            const href = window.URL.createObjectURL(data);

            const anchorElement = document.createElement("a");
            anchorElement.href = href;

            document.body.appendChild(anchorElement);
            anchorElement.click();

            document.body.removeChild(anchorElement);
            window.URL.revokeObjectURL(href);
        },
    });
}
