import * as React from "react";
import {
    Button,
    DatePicker,
    Dialog,
    DialogContent,
    Input,
    Select,
    Tag,
    TextArea,
    Tooltip,
} from "@jhool-io/fe-components";
import ReactDOM from "react-dom";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Link, useParams, useSearchParams } from "react-router-dom";
import { useIsMutating, useQueryClient } from "@tanstack/react-query";
import { parseISO } from "date-fns";
import { FormatOptionLabelMeta } from "react-select";
import {
    IPaymentBreakdown,
    IUnmatchPaymentResponse,
    RemarkCodeDescription,
    RemarkCodes,
    WriteOffCodes,
    WriteOffCodesDecription,
} from "../../types/billing.types";
import UnmatchedPaymentIcon from "../../../../components/Icons/UnmatchPayment";
import DeletePaymentIcon from "../../../../components/Icons/DeletePayment";
import ConfirmPaymentIcon from "../../../../components/Icons/ConfirmPayment";
import MarkAsPendingIcon from "../../../../components/Icons/MarkAsPending";
import RefundPaymentIcon from "../../../../components/Icons/RefundPayment";
import PencilIcon from "../../../../components/Icons/Pencil";
import { APP_COLORS } from "../../../../utils/constants";
import {
    formatRemarkOptionLabel,
    paymentDateToDisplay,
} from "../../helpers/billing.helpers";
import {
    buildSuccessMessage,
    handleFormatDatePickerValue,
    handleRemoveEmptyParamFromQueryParams,
    makeStringFirstLetterCapital,
    removeEnumUnderscore,
    showMoneyInAppFormat,
    truncateString,
} from "../../../../utils/helpers";
import InfoIcon from "../../../../components/Icons/InfoIcon";
import { useDoesUserHavePermission } from "../../../../hooks/permissions";
import { UserPermisions } from "../../../../utils/types/user";
import SplitPaymentIcon from "../../../../components/Icons/SplitPayment";
import useToast from "../../../../hooks/useToast";
import {
    useUnMatchPayment,
    useUpdatePayment,
} from "../../../../hooks/mutations/billing";
import { IUpdatePayment } from "../../../../utils/types/billing";
import { usePostPayments } from "../../../remits/hooks/remits.mutations";
import { IPaginatedApiResponse } from "../../../../utils/types/api-response";
import SplitPaymentForm from "../SplitPaymentForm/SplitPaymentForm";
import PaymentActionForm from "../PaymentActionForm/PaymentActionForm";
import MinusCircleIcon from "../../../../components/Icons/MinusCircle";

interface PaymentItemProps {
    item: IPaymentBreakdown;
}

type Dialogs =
    | "unmatch_payment"
    | "move_payment"
    | "refund"
    | "confirm_payment";

type Options = {
    label: React.ReactNode;
    value: string | undefined;
};

export default function PaymentItem({ item }: PaymentItemProps) {
    const [dialogInView, setDialogInView] = React.useState<Dialogs | null>(
        null
    );
    const [localDateOfPayment, setLocalDateOfPayment] =
        React.useState<Date | null>(
            item.payment_date
                ? parseISO(
                      item.payment_date.replace("+00:00", "").replace("Z", "")
                  )
                : null
        );
    const [isEditing, setIsEditing] = React.useState(false);

    const [searchParams, setSearchParams] = useSearchParams();
    const params = useParams();
    const { toast } = useToast();
    const queryClient = useQueryClient();

    const changeWriteoffPaymentStatus = useUpdatePayment();
    const updatePayment = useUpdatePayment();
    const postPayments = usePostPayments();
    const unmatchPayment = useUnMatchPayment();
    const clientId = params.clientId as string;

    const splitPaymentIsMutating = useIsMutating(["split-payment"]);
    const confirmPaymentIsMutating = useIsMutating(["post-payments"]);
    const paymentIsMutating = useIsMutating(["add-payment", item.invoice_id]);

    const isRefundPayment =
        item.payment_type === "rci" ||
        item.payment_type === "ri" ||
        item.payment_type === "rpr100";

    const refundPaymentAmountToDisplay = isRefundPayment
        ? `(${showMoneyInAppFormat(item?.amount)})`
        : `${showMoneyInAppFormat(item?.amount)}`;

    const getRemarkCodesToDisplay = () => {
        const newRemarkCodes = item.remark_codes_payment;

        if (newRemarkCodes) return newRemarkCodes;

        if (
            !item.remark_codes ||
            (item.remark_codes && item.remark_codes.length === 0)
        ) {
            return null;
        }

        return Object.fromEntries(item.remark_codes.map((key) => [key, null]));
    };

    const remarkCodes = getRemarkCodesToDisplay();

    const {
        register,
        control,
        reset,
        setValue,
        handleSubmit,
        formState: { dirtyFields, errors },
    } = useForm<IUpdatePayment>({
        mode: "onChange",
        resolver: yupResolver(
            yup.object().shape({
                amount: yup.number().min(0),
                payment_date: yup.string(),
                writeoff_code: yup.string(),
                payment_notes: yup.string(),
                remark_codes_payment_form: yup.array().of(
                    yup.object().shape({
                        amount: yup
                            .mixed()
                            .test(
                                "is-amount-valid",
                                "Enter valid amount",
                                (value) => {
                                    if (
                                        value !== undefined &&
                                        value !== null &&
                                        value !== ""
                                    ) {
                                        return yup
                                            .number()
                                            .required("Enter valid amount")
                                            .min(0)
                                            .isValidSync(value);
                                    }

                                    return true;
                                }
                            ),
                        code: yup
                            .string()
                            .when("amount", (amount: string, field) =>
                                amount
                                    ? field.required("Code is required")
                                    : field
                            ),
                    })
                ),
            })
        ),
    });

    const { fields, append, remove } = useFieldArray({
        control,
        name: "remark_codes_payment_form",
    });

    const canUnmatchPayments = useDoesUserHavePermission([
        UserPermisions.CLIENT_INFO_PAYMENTS_WRITE,
        UserPermisions.CLIENT_INFO_INVOICE_WRITE,
    ]);

    const handleDialogInView = (dialog: Dialogs | null) => {
        setDialogInView(dialog);
    };

    const getBreakdownPaymentSource = (paymentType: string) => {
        if (paymentType === "pr100") return "PR100";
        if (
            paymentType === "i" ||
            paymentType === "wbi" ||
            paymentType === "ri"
        ) {
            return "Insurance";
        }
        return "Co-Insurance";
    };

    const getPaymentStatusTag = (status: string) => {
        let bgColor: string;
        let textColor: string;

        switch (status) {
            case "completed":
                bgColor = "rgba(204, 250, 233, 0.50)";
                textColor = "#00563E";
                break;
            case "failed":
                bgColor = "rgba(251, 199, 198, 0.50)";
                textColor = "#981F41";
                break;
            default:
                bgColor = "rgba(247, 229, 164, 0.50)";
                textColor = "#634D17";
        }

        return (
            <Tag
                bgColor={bgColor}
                textColor={textColor}
                title={makeStringFirstLetterCapital(status)}
                className="capitalize rounded-r4 px-8 text-xxs"
            />
        );
    };

    const writeOffCodesForSelect: Options[] = Object.values(WriteOffCodes).map(
        (writeoff) => ({
            value: writeoff,
            label: WriteOffCodesDecription[writeoff],
        })
    );

    const getWriteOffCodeDescription = (code: string) => {
        const writeOffDescriptions = Object.values(WriteOffCodesDecription);
        const matchingDescription = writeOffDescriptions?.find(
            (description) =>
                code?.toLowerCase() === description.split(":")[0]?.toLowerCase()
        );
        return matchingDescription || "--";
    };

    const handleEditButtonClicked = () => {
        setValue("amount", item.amount);
        setValue("payment_notes", item.payment_notes || "");
        setValue(
            "remark_codes_payment_form",
            remarkCodes
                ? Object.entries(remarkCodes).map(([code, amount]) => ({
                      code,
                      amount: amount === null ? "" : `${amount}`,
                  }))
                : [{ amount: "", code: "" }]
        );
        setValue("writeoff_code", item.writeoff_code || "");
        setValue("payment_date", item.payment_date || "");
        setLocalDateOfPayment(
            parseISO(item.payment_date.replace("+00:00", "").replace("Z", ""))
        );
        setIsEditing(() => true);
    };

    const getPaymentTypeTag = (
        type: string,
        method: string,
        info: {
            refund_reason: string;
            cheque_number: string;
            authorization_number: string;
        },
        writeoff_code?: string,
        auth_code?: string
    ) => {
        switch (type) {
            case "wbci":
            case "wbi":
                return (
                    <div className="flex items-center gap-x-4 whitespace-nowrap">
                        <Tag
                            bgColor="rgba(229, 229, 229, 0.50)"
                            textColor="#0B132B"
                            className="capitalize flex items-center font-normal px-8 py-4 h-24 rounded-r4"
                            title={
                                <>
                                    Write-off:{" "}
                                    <span className="uppercase">
                                        {writeoff_code}
                                    </span>
                                </>
                            }
                        />
                        <Tooltip
                            content={getWriteOffCodeDescription(
                                writeoff_code || "--"
                            )}
                            classNames="uppercase"
                        >
                            <Button variant="normal" size="auto">
                                <InfoIcon />
                            </Button>
                        </Tooltip>
                    </div>
                );
            case "ri":
            case "rci":
            case "rpr100":
                return (
                    <div>
                        <Tag
                            bgColor="rgba(204, 250, 233, 0.50)"
                            textColor="#00563E"
                            className="font-normal px-8 py-4 h-24 rounded-r4 whitespace-nowrap"
                            title={
                                <>
                                    Refund:{" "}
                                    {removeEnumUnderscore(
                                        info?.refund_reason
                                    ) ?? "Others"}
                                </>
                            }
                        />
                    </div>
                );
            case "i":
            case "ci":
            case "pr100":
                return (
                    <div className="flex items-center gap-x-4 whitespace-nowrap">
                        <Tag
                            bgColor="rgba(247, 229, 164, 0.50)"
                            textColor="#634D1"
                            className="capitalize flex items-center font-normal px-8 py-4 h-24 rounded-r4"
                            title={
                                <>
                                    Credit:{" "}
                                    {removeEnumUnderscore(
                                        method === "cheque" ? "check" : method
                                    )}
                                </>
                            }
                        />
                        {info?.cheque_number && (
                            <Tooltip
                                content={`CHECK NUMBER - ${info.cheque_number}`}
                                classNames="uppercase"
                            >
                                <Button variant="normal" size="auto">
                                    <InfoIcon />
                                </Button>
                            </Tooltip>
                        )}
                        {auth_code && (
                            <Tooltip
                                content={`AUTHORIZATION CODE - ${auth_code}`}
                                classNames="uppercase"
                            >
                                <Button variant="normal" size="auto">
                                    <InfoIcon />
                                </Button>
                            </Tooltip>
                        )}
                    </div>
                );
            case "pi":
                return (
                    <Tag
                        bgColor="rgba(172, 222, 250, 0.50)"
                        textColor="#165574"
                        className="font-normal px-8 py-4 h-24 rounded-r4"
                        title="Patient insurance"
                    />
                );

            default:
                return (
                    <div className="flex items-center gap-x-4 whitespace-nowrap">
                        <Tag
                            bgColor="rgba(229, 229, 229, 0.50)"
                            textColor="#0B132B"
                            className="capitalize flex items-center font-normal px-8 py-4 h-24 rounded-r4"
                            title={type ?? "others"}
                        />
                        {info && (
                            <Tooltip
                                content={info.refund_reason}
                                classNames="uppercase"
                            >
                                <Button variant="normal" size="auto">
                                    <InfoIcon />
                                </Button>
                            </Tooltip>
                        )}
                        {info?.authorization_number && (
                            <Tooltip
                                content={`AUTHORIZATION CODE - ${info.authorization_number}`}
                                classNames="uppercase"
                            >
                                <Button variant="normal" size="auto">
                                    <InfoIcon />
                                </Button>
                            </Tooltip>
                        )}
                    </div>
                );
        }
    };

    const remarkCodeForSelect: Options[] = Object.values(RemarkCodes)
        .map((remark) => ({
            value: remark, // Use the enum value as the option value
            label: RemarkCodeDescription[
                remark as keyof typeof RemarkCodeDescription
            ],
            // Use this for the description
        }))
        .sort((a, b) => a.value.localeCompare(b.value));

    const handleUpdateWriteoffPaymentStatus = (payload: IPaymentBreakdown) => {
        const dataToSend = {
            payment_id: payload.payment_id,
            amount: payload.amount,
            payment_date: handleFormatDatePickerValue(payload.payment_date),
            payment_status: "pending",
        };
        changeWriteoffPaymentStatus.mutate(dataToSend, {
            onSuccess: () => {
                queryClient.invalidateQueries({
                    queryKey: [
                        "get-payment",
                        handleRemoveEmptyParamFromQueryParams({
                            invoice_id: item.invoice_id,
                        }),
                    ],
                });

                toast({
                    mode: "success",
                    message: "Writeoff status updated successfully",
                });
            },

            onError: () => {
                toast({
                    mode: "error",
                    message:
                        "Error updating writeoff status please try again later",
                });
            },
        });
    };

    const handleUpdatePaymentRow = async (payload: IUpdatePayment) => {
        const payloadToSend: IUpdatePayment = {
            amount: item?.payment_type?.includes("wb")
                ? Number(payload.amount)
                : undefined,
            payment_notes: payload.payment_notes,
            payment_date: handleFormatDatePickerValue(payload.payment_date),
            remark_codes: payload.remark_codes,
            writeoff_code: payload.writeoff_code || undefined,
            payment_id: item?.payment_id || "",
        };

        if (dirtyFields.remark_codes_payment_form) {
            const getPairs = payload.remark_codes_payment_form?.map((pair) => ({
                code: pair.code,
                amount: pair.amount ? parseFloat(pair.amount) : null,
            }));

            payloadToSend.remark_codes_payment =
                getPairs && getPairs?.length > 0
                    ? getPairs?.reduce((acc, curr) => {
                          // eslint-disable-next-line no-param-reassign
                          if (curr.code) acc[curr.code] = curr.amount;
                          return acc;
                      }, {} as Record<string, number | null>)
                    : undefined;
        }

        // clear // remark_codes_payment_form used in form
        payloadToSend.remark_codes_payment_form = undefined;

        updatePayment.mutate(payloadToSend, {
            onSuccess: async () => {
                toast({
                    mode: "success",
                    message: "Payment updated successfully.",
                });

                if (item?.payment_status === "pending") {
                    await new Promise<void>((resolve, reject) => {
                        postPayments.mutate(
                            { payment_ids: [payload.payment_id] },
                            {
                                onSuccess: () => {
                                    toast({
                                        mode: "success",
                                        message:
                                            "Payment updated and confirmed successfully.",
                                    });
                                    resolve();
                                },
                                onError: (err) => {
                                    toast({
                                        mode: "error",
                                        message:
                                            "Could not confirm payment at this time",
                                    });
                                    reject(err);
                                },
                            }
                        );
                    });
                }
                queryClient.invalidateQueries({
                    queryKey: [
                        "get-payment",
                        handleRemoveEmptyParamFromQueryParams({
                            invoice_id: item.invoice_id,
                        }),
                    ],
                });
                queryClient.invalidateQueries({
                    queryKey: ["invoices"],
                });

                reset();
                setIsEditing(false);
            },
            onError: (err) => {
                toast({
                    mode: "error",
                    message:
                        err.response?.data.message ||
                        "Error updating payment please try again later",
                });
            },
        });
    };

    const handleUnMatchPayment = (paymentId: string) => {
        const invoiceId = item.invoice_id;
        const unMatchPaymentPayload = {
            matched_payments: {
                [invoiceId as string]: [paymentId],
            },
        };
        unmatchPayment.mutate(unMatchPaymentPayload, {
            onSuccess: (response) => {
                queryClient.setQueryData<
                    IPaginatedApiResponse<IUnmatchPaymentResponse>
                >(["get-payment"], (prev) => {
                    const prevRequired =
                        prev as IPaginatedApiResponse<IUnmatchPaymentResponse>;
                    return {
                        ...prevRequired,
                        invoice_status: response.status,
                    };
                });
                queryClient.invalidateQueries({
                    queryKey: [
                        "get-payment",
                        handleRemoveEmptyParamFromQueryParams({
                            invoice_id: item.invoice_id,
                        }),
                    ],
                });
                handleDialogInView(null);
                toast({
                    mode: "success",
                    message: `${buildSuccessMessage(
                        response.data.successful_payments,
                        response.data.failed_payments
                    )}`,
                });
            },
        });
    };

    const handlePostPayment = (id: string) => {
        postPayments.mutate(
            { payment_ids: [id] },
            {
                onSuccess(res) {
                    queryClient.invalidateQueries({
                        queryKey: [
                            "get-payment",
                            handleRemoveEmptyParamFromQueryParams({
                                invoice_id: item.invoice_id,
                            }),
                        ],
                    });
                    handleDialogInView(null);
                    toast({
                        mode: "success",
                        message: res.message || "Payment posted successfully.",
                    });
                },
                onError(err) {
                    toast({
                        mode: "error",
                        message:
                            err.response?.data.message ||
                            "Could not post payment.",
                    });
                },
            }
        );
    };

    return (
        <>
            <Dialog open={dialogInView === "unmatch_payment"}>
                <DialogContent
                    variant="center"
                    isDeleting={!item.transaction_id}
                    title={
                        item.transaction_id
                            ? "Unmatch Payment"
                            : "Delete Payment"
                    }
                    isSubmitBtnDisabled={unmatchPayment.isLoading}
                    isCancelBtnDisabled={unmatchPayment.isLoading}
                    handleCloseDialog={() => handleDialogInView(null)}
                    saveText={
                        item.transaction_id
                            ? "Unmatch Payment"
                            : "Delete Payment"
                    }
                    onSaveClick={
                        item.payment_id
                            ? () => handleUnMatchPayment(item.payment_id)
                            : undefined
                    }
                >
                    <>
                        <h3 className="font-medium text-xl mb-8">
                            {`Are you sure you want to ${
                                item.transaction_id ? "Unmatch" : "Delete"
                            } payment`}
                        </h3>
                        <p className="text-gray font-normal text-base">
                            {item.transaction_id ? (
                                <span>
                                    Unmatching this payment will remove it from
                                    this bill. <br />
                                    You will still be able to see this payment
                                    in the unmatched payment tab.
                                </span>
                            ) : (
                                <span>
                                    Deleting this payment will remove it from
                                    your records. <br />
                                    You will no longer be able to see this
                                    payment.
                                </span>
                            )}
                        </p>
                    </>
                </DialogContent>
            </Dialog>
            {item.amount &&
                item.payment_id &&
                item.invoice_id &&
                item.payment_date && (
                    <Dialog open={dialogInView === "move_payment"}>
                        <DialogContent
                            variant="center"
                            title="Split Payment"
                            handleCloseDialog={() => {
                                handleDialogInView(null);
                                searchParams.delete("is_pr100_split");
                                setSearchParams(searchParams);
                            }}
                            showFooter
                            wrapperClass="w-[700px]"
                            saveText="Split Payment"
                            isSubmitBtnDisabled={splitPaymentIsMutating > 0}
                            isCancelBtnDisabled={splitPaymentIsMutating > 0}
                            submitBtnFormValue="move-payment"
                        >
                            <SplitPaymentForm
                                paymentId={item.payment_id}
                                defaultAmount={item.amount}
                                onFormSubmit={() => handleDialogInView(null)}
                                invoiceToMoveId={item.invoice_id}
                                paymentDate={item.payment_date}
                            />
                        </DialogContent>
                    </Dialog>
                )}

            <Dialog open={dialogInView === "confirm_payment"}>
                <DialogContent
                    handleCloseDialog={() => handleDialogInView(null)}
                    variant="center"
                    classNames="p-24"
                    title="Confirm Payment"
                    saveText="Yes,confirm"
                    onSaveClick={() => handlePostPayment(item.payment_id)}
                    isCancelBtnDisabled={confirmPaymentIsMutating > 0}
                    isSubmitBtnDisabled={confirmPaymentIsMutating > 0}
                >
                    <div>
                        <h3 className="text-xl mb-8 font-medium">
                            Are you sure you want to confirm this payment?
                        </h3>
                        <p className="text-base text-gray font-normal">
                            By confirming, please note that this action is
                            irreversible
                        </p>
                    </div>
                </DialogContent>
            </Dialog>
            <Dialog open={dialogInView === "refund"}>
                <DialogContent
                    handleCloseDialog={() => handleDialogInView(null)}
                    variant="center"
                    title="Add refund"
                    saveText="Add refund"
                    submitBtnFormValue="action-form"
                    isSubmitBtnDisabled={paymentIsMutating > 0}
                    isCancelBtnDisabled={paymentIsMutating > 0}
                >
                    <PaymentActionForm
                        invoiceId={item.invoice_id}
                        clientId={clientId}
                        onFormSubmit={() => handleDialogInView(null)}
                        transactionId={item.transaction_id || undefined}
                        modalType="refund"
                    />
                </DialogContent>
            </Dialog>

            <div
                className="grid grid-cols-[auto_200px_100px_100px_250px_260px_200px_100px_100px_318px_200px] gap-12 items-center text-sm py-12 border-b border-secondary last:border-none px-24"
                key={item.payment_id}
            >
                <div className="flex items-center gap-8  max-w-[200px]">
                    {item.payment_type !== "pi" && (
                        <>
                            {canUnmatchPayments &&
                                item.payment_type !== "pi" &&
                                !item.has_split_payments && (
                                    <div>
                                        {item.transaction_id ? (
                                            <Tooltip content="Unmatch Payment">
                                                <Button
                                                    size="auto"
                                                    variant="normal"
                                                    onClick={() =>
                                                        handleDialogInView(
                                                            "unmatch_payment"
                                                        )
                                                    }
                                                >
                                                    <UnmatchedPaymentIcon />
                                                </Button>
                                            </Tooltip>
                                        ) : (
                                            <Tooltip content="Delete Payment">
                                                <Button
                                                    size="auto"
                                                    variant="normal"
                                                    onClick={() =>
                                                        handleDialogInView(
                                                            "unmatch_payment"
                                                        )
                                                    }
                                                >
                                                    <DeletePaymentIcon />
                                                </Button>
                                            </Tooltip>
                                        )}
                                    </div>
                                )}

                            {(item.payment_type === "ci" ||
                                item.payment_type === "pr100") &&
                                item.amount > 0 && (
                                    <Tooltip content="Split Payment">
                                        <Button
                                            size="auto"
                                            variant="normal"
                                            onClick={() => {
                                                handleDialogInView(
                                                    "move_payment"
                                                );
                                                searchParams.set(
                                                    "is_pr100_split",
                                                    "true"
                                                );
                                                setSearchParams(searchParams);
                                            }}
                                        >
                                            <SplitPaymentIcon />
                                        </Button>
                                    </Tooltip>
                                )}

                            {(item.payment_type === "wbi" ||
                                item.payment_type === "i") &&
                                item.payment_status === "pending" && (
                                    <Tooltip content="Confirm Payment">
                                        <Button
                                            size="auto"
                                            variant="normal"
                                            onClick={() =>
                                                handleDialogInView(
                                                    "confirm_payment"
                                                )
                                            }
                                        >
                                            <ConfirmPaymentIcon />
                                        </Button>
                                    </Tooltip>
                                )}
                            {(item.payment_type === "wbi" ||
                                item.payment_type === "i") &&
                                item.payment_status === "completed" && (
                                    <Tooltip content="Mark as Pending">
                                        <Button
                                            size="auto"
                                            variant="normal"
                                            onClick={() =>
                                                handleUpdateWriteoffPaymentStatus(
                                                    item
                                                )
                                            }
                                            disabled={
                                                changeWriteoffPaymentStatus.isLoading
                                            }
                                        >
                                            <MarkAsPendingIcon />
                                        </Button>
                                    </Tooltip>
                                )}

                            {item.refundable_amount &&
                            item.refundable_amount > 0 &&
                            item.payment_channel === "authorize.net" ? (
                                <Tooltip content="Refund">
                                    <Button
                                        size="auto"
                                        variant="normal"
                                        onClick={() => {
                                            handleDialogInView("refund");
                                        }}
                                    >
                                        <RefundPaymentIcon />
                                    </Button>
                                </Tooltip>
                            ) : null}
                            {!isEditing ? (
                                <Tooltip content="Edit payment">
                                    <Button
                                        size="auto"
                                        variant="normal"
                                        onClick={handleEditButtonClicked}
                                        disabled={isEditing}
                                    >
                                        <PencilIcon />
                                    </Button>
                                </Tooltip>
                            ) : (
                                <>
                                    <Tooltip content="Cancel">
                                        <Button
                                            size="auto"
                                            variant="normal"
                                            onClick={() => setIsEditing(false)}
                                            disabled={updatePayment.isLoading}
                                        >
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="16"
                                                height="17"
                                                viewBox="0 0 16 17"
                                                fill="none"
                                            >
                                                <path
                                                    d="M3.3335 3.83594L12.6668 13.1693M3.3335 13.1693L12.6668 3.83594"
                                                    stroke={
                                                        APP_COLORS.COLOR_DANGER
                                                    }
                                                    strokeWidth="1.5"
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                />
                                            </svg>
                                        </Button>
                                    </Tooltip>
                                    <Tooltip content="Save">
                                        <Button
                                            size="auto"
                                            variant="normal"
                                            onClick={() => {
                                                handleSubmit(
                                                    handleUpdatePaymentRow
                                                )();
                                            }}
                                            disabled={updatePayment.isLoading}
                                        >
                                            <ConfirmPaymentIcon
                                                fill={APP_COLORS.COLOR_PRIMARY}
                                            />
                                        </Button>
                                    </Tooltip>
                                </>
                            )}
                        </>
                    )}
                </div>
                <div className="flex gap-8 items-center w-[200px]">
                    {isEditing ? (
                        <div className="flex items-center gap-2">
                            <Controller
                                name="payment_date"
                                control={control}
                                render={({ field }) => (
                                    <DatePicker
                                        selected={localDateOfPayment}
                                        onChange={(date) => {
                                            field.onChange(date);
                                            setLocalDateOfPayment(date);
                                        }}
                                        className="h-28 w-[120px]"
                                        errorTextClassName="-mt-4 text-[10px]"
                                        popperClassName="!z-[11]"
                                        popperContainer={({ children }) =>
                                            ReactDOM.createPortal(
                                                children,
                                                document.body
                                            )
                                        }
                                        popperProps={{
                                            strategy: "fixed",
                                            modifiers: [
                                                {
                                                    name: "zIndex",
                                                    options: {
                                                        zIndex: 11,
                                                    },
                                                },
                                            ],
                                        }}
                                    />
                                )}
                            />
                        </div>
                    ) : (
                        <div className="flex gap-8 items-center">
                            {paymentDateToDisplay(
                                item.payment_date,
                                item.payment_channel || ""
                            )}
                        </div>
                    )}
                </div>

                <span className="w-100px">
                    {getBreakdownPaymentSource(item.payment_type)}
                </span>

                <span className="w-[100px]">
                    {getPaymentStatusTag(item.payment_status)}
                </span>

                <div className="flex gap-x-4 gap-y-8 flex-wrap items-center w-[250px]">
                    {isEditing && item.payment_type.includes("wb") ? (
                        <Controller
                            name="writeoff_code"
                            control={control}
                            render={({ field }) => (
                                <Select
                                    placeholder="Select code"
                                    isLongListInDialog
                                    onChange={(val) =>
                                        field.onChange((val as Options).value)
                                    }
                                    options={writeOffCodesForSelect}
                                    defaultValue={writeOffCodesForSelect.filter(
                                        (code) =>
                                            field.value?.includes(
                                                code.value || ""
                                            )
                                    )}
                                    isSearchable
                                    formatOptionLabel={(selectData, meta) => {
                                        return meta.context === "menu"
                                            ? formatRemarkOptionLabel(
                                                  selectData,
                                                  meta,
                                                  getWriteOffCodeDescription,
                                                  "writeoff_code"
                                              )
                                            : truncateString(
                                                  (selectData as Options)
                                                      .label as string,
                                                  10
                                              );
                                    }}
                                    classNames={{
                                        container: () => "w-[120px] !z-[12]",
                                        control: () => "!h-28",
                                        menu: () => "!w-[200px]",
                                        option: () => "!text-[11px]",
                                        input: () =>
                                            "!text-xs !h-20 !p-0 !my-0",
                                        menuPortal: () => "!z-[11]",
                                    }}
                                />
                            )}
                        />
                    ) : (
                        <>
                            {getPaymentTypeTag(
                                item.payment_type,
                                item.payment_method,
                                item.payment_info,
                                item.writeoff_code,
                                item.auth_code
                            )}
                        </>
                    )}
                </div>

                <div className="w-[260px]">
                    <div>
                        {isEditing ? (
                            <div>
                                {Array.from(
                                    { length: fields.length },
                                    (_, i) => (
                                        <div
                                            key={`${i + 1} key`}
                                            className="flex items-start mb-8 gap-x-8"
                                        >
                                            <Controller
                                                name={`remark_codes_payment_form.${i}.code`}
                                                control={control}
                                                render={({ field }) => (
                                                    <Select
                                                        placeholder="Remark code"
                                                        isSearchable
                                                        options={
                                                            remarkCodeForSelect
                                                        }
                                                        onChange={(val) =>
                                                            field.onChange(
                                                                (val as Options)
                                                                    .value
                                                            )
                                                        }
                                                        value={remarkCodeForSelect.find(
                                                            (code) =>
                                                                field.value ===
                                                                (code.value ||
                                                                    "")
                                                        )}
                                                        formatOptionLabel={(
                                                            selectData: unknown,
                                                            formatOptionLabelMeta: FormatOptionLabelMeta<unknown>
                                                        ) => {
                                                            return formatOptionLabelMeta.context ===
                                                                "menu"
                                                                ? (
                                                                      selectData as Options
                                                                  ).label
                                                                : (
                                                                      (
                                                                          selectData as Options
                                                                      )
                                                                          .value as string
                                                                  ).toUpperCase();
                                                        }}
                                                        isLongListInDialog
                                                        classNames={{
                                                            menuPortal: () =>
                                                                "!z-[11]",
                                                            container: () =>
                                                                "w-[120px]",
                                                            control: () =>
                                                                "!h-28",
                                                            menu: () =>
                                                                "!w-[200px]",
                                                            option: () =>
                                                                "!text-[11px]",
                                                            input: () =>
                                                                "!text-xs !h-20 !p-0 !my-0",
                                                        }}
                                                        hasError={
                                                            !!errors
                                                                ?.remark_codes_payment_form?.[
                                                                i
                                                            ]?.code
                                                        }
                                                        errorText={
                                                            errors
                                                                ?.remark_codes_payment_form?.[
                                                                i
                                                            ]?.code?.message
                                                        }
                                                        errorTextClassName="text-[10px] mt-4 block"
                                                    />
                                                )}
                                            />
                                            <div>
                                                <Input
                                                    {...register(
                                                        `remark_codes_payment_form.${i}.amount`
                                                    )}
                                                    placeholder="Amount"
                                                    hasError={
                                                        !!errors
                                                            ?.remark_codes_payment_form?.[
                                                            i
                                                        ]?.amount
                                                    }
                                                    errorText={
                                                        errors
                                                            ?.remark_codes_payment_form?.[
                                                            i
                                                        ]?.amount
                                                            ? "Enter valid amount"
                                                            : ""
                                                    }
                                                    className="h-28 w-[100px]"
                                                    errorTextClassName="-mt-4 text-[10px]"
                                                    showCurrency
                                                />
                                            </div>
                                            <Button
                                                title="Remove pair"
                                                size="auto"
                                                variant="normal"
                                                className={
                                                    !!errors
                                                        ?.remark_codes_payment_form?.[
                                                        i
                                                    ]?.code ||
                                                    !!errors
                                                        ?.remark_codes_payment_form?.[
                                                        i
                                                    ]?.amount
                                                        ? "self-start"
                                                        : "self-center"
                                                }
                                                onClick={() => remove(i)}
                                                disabled={fields.length === 1}
                                            >
                                                <MinusCircleIcon />
                                            </Button>
                                        </div>
                                    )
                                )}
                                <div className="flex items-center justify-center">
                                    <Button
                                        size="auto"
                                        variant="normal"
                                        className="text-xs underline font-medium leading-none"
                                        onClick={() =>
                                            append(
                                                { amount: "", code: "" },
                                                {
                                                    shouldFocus: false,
                                                }
                                            )
                                        }
                                    >
                                        Add
                                    </Button>
                                </div>
                            </div>
                        ) : (
                            <div className="flex gap-8 flex-wrap">
                                {remarkCodes
                                    ? Object.entries(remarkCodes).map(
                                          ([code, amount]) => (
                                              <div
                                                  key={code}
                                                  className="flex items-center gap-x-4"
                                              >
                                                  <Tag
                                                      bgColor="#BAECD1"
                                                      textColor="#44474F"
                                                      className="uppercase flex items-center font-normal px-4 py-0 h-20 rounded-r8 text-xs"
                                                      title={code}
                                                  />
                                                  <Tooltip
                                                      content={
                                                          amount
                                                              ? showMoneyInAppFormat(
                                                                    amount
                                                                )
                                                              : "--"
                                                      }
                                                  >
                                                      <Button
                                                          variant="normal"
                                                          size="auto"
                                                      >
                                                          <InfoIcon />
                                                      </Button>
                                                  </Tooltip>
                                              </div>
                                          )
                                      )
                                    : "--"}
                            </div>
                        )}
                    </div>
                </div>
                <div className="flex flex-wrap w-[318px]">
                    {isEditing && item.payment_type.includes("wb") ? (
                        <Input
                            {...register("amount")}
                            className="h-28 w-[100px]"
                            showCurrency
                        />
                    ) : (
                        <div
                            className={`flex gap-8 items-center ${
                                isRefundPayment ? "text-danger" : "text-black"
                            }`}
                        >
                            {item.parent_payment_id &&
                            item.payment_type !== "rci" ? (
                                <Tooltip content="Split Payment">
                                    <span
                                        style={{
                                            display: "flex",
                                            alignItems: "center",
                                            columnGap: "5px",
                                        }}
                                        className={`flex items-center gap-x-[5px] ${
                                            isRefundPayment
                                                ? "text-danger"
                                                : "text-black"
                                        }`}
                                    >
                                        {refundPaymentAmountToDisplay}
                                        <InfoIcon />
                                    </span>
                                </Tooltip>
                            ) : (
                                <span>{refundPaymentAmountToDisplay}</span>
                            )}
                        </div>
                    )}
                </div>
                <span className="overflow-x-auto w-[100px]">
                    {item.eft_number ? (
                        <Link
                            to={`/remit/EOB/${item.remittance_id}`}
                            target="_blank"
                            rel="noreferrer"
                            className="text-primary text-sm underline"
                        >
                            {item.eft_number}
                        </Link>
                    ) : (
                        "--"
                    )}
                </span>
                <span className="w-[100px]">
                    {Object.keys(item.posted_by).length > 0
                        ? `${item.posted_by.first_name} ${item.posted_by.last_name}`
                        : "Mantle"}
                </span>
                <div className="flex flex-wrap w-[318px]">
                    {isEditing ? (
                        <TextArea
                            {...register("payment_notes")}
                            className="h-[28px] w-[200px] resize-y py-0"
                            placeholder="Notes"
                        />
                    ) : (
                        item.payment_notes || "--"
                    )}
                </div>
                <span className="flex flex-wrap w-[200px]">
                    {item.payment_description || "--"}
                </span>
            </div>
        </>
    );
}
