import * as React from "react";
import { useQueryClient } from "@tanstack/react-query";
import {
    Button,
    DatePicker,
    Dialog,
    DialogContent,
    Input,
    Select,
    Tag,
    TextArea,
    Tooltip,
} from "@jhool-io/fe-components";
import { parseISO } from "date-fns";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { FormatOptionLabelMeta } from "react-select";
import { Link, useParams } from "react-router-dom";
import ReactDOM from "react-dom";
import {
    IClaimAdjustments,
    IInsurancePayments,
} from "../../../types/remits.types";
import DeletePaymentIcon from "../../../../../components/Icons/DeletePayment";
import PencilIcon from "../../../../../components/Icons/Pencil";
import ConfirmPaymentIcon from "../../../../../components/Icons/ConfirmPayment";
import { APP_COLORS } from "../../../../../utils/constants";
import useToast from "../../../../../hooks/useToast";
import { usePostPayments } from "../../../hooks/remits.mutations";
import {
    useUnMatchPayment,
    useUpdatePayment,
} from "../../../../../hooks/mutations/billing";
import { IUpdatePayment } from "../../../../../utils/types/billing";
import {
    buildSuccessMessage,
    displayNameInRightFormat,
    formatDate,
    handleFormatDatePickerValue,
    handleRemoveEmptyParamFromQueryParams,
    makeStringFirstLetterCapital,
    removeEnumUnderscore,
    showMoneyInAppFormat,
    truncateString,
} from "../../../../../utils/helpers";
import {
    RemarkCodeDescription,
    RemarkCodes,
    WriteOffCodes,
    WriteOffCodesDecription,
} from "../../../../billing/types/billing.types";
import { formatRemarkOptionLabel } from "../../../../billing/helpers/billing.helpers";
import InfoIcon from "../../../../../components/Icons/InfoIcon";
import MinusCircleIcon from "../../../../../components/Icons/MinusCircle";

interface InsurancePaymentItemProps {
    payment: IInsurancePayments;
    remitClaimAdjustments: IClaimAdjustments[];
    remittanceId: string;
    invoiceId: string;
}

type Dialogs = "delete_payment" | "post_payment" | "edit_warning";

type Options = {
    label: React.ReactNode;
    value: string | undefined;
};

type RemarkCodePair = {
    code: string;
    amount: number | null;
};

export default function InsurancePaymentItem({
    payment,
    remitClaimAdjustments,
    remittanceId,
    invoiceId,
}: InsurancePaymentItemProps) {
    const [isEditing, setIsEditing] = React.useState(false);
    const [dialogInView, setDialogInView] = React.useState<Dialogs | null>(
        null
    );
    const [rcValidationError, setRCValidationError] = React.useState<
        string | null
    >(null);
    const [localDateOfPayment, setLocalDateOfPayment] =
        React.useState<Date | null>(
            payment.payment_date
                ? parseISO(
                      payment.payment_date
                          .replace("+00:00", "")
                          .replace("Z", "")
                  )
                : null
        );

    const getRemarkCodesToDisplay = () => {
        const newRemarkCodes = payment.remark_codes_payment;

        if (newRemarkCodes) return newRemarkCodes;

        if (
            !payment.remark_codes ||
            (payment.remark_codes && payment.remark_codes.length === 0)
        ) {
            return null;
        }

        return Object.fromEntries(
            payment.remark_codes.map((key) => [key, null])
        );
    };

    const remarkCodes = getRemarkCodesToDisplay();

    const {
        register,
        control,
        reset,
        setValue,
        watch,
        handleSubmit,
        formState: { errors, dirtyFields },
    } = useForm<IUpdatePayment>({
        mode: "onSubmit",
        resolver: yupResolver(
            yup.object().shape({
                remark_codes_payment_form: yup.array().of(
                    yup.object().shape({
                        amount: yup
                            .mixed()
                            .test(
                                "is-amount-valid",
                                "Enter valid amount",
                                (value) => {
                                    if (
                                        value !== undefined &&
                                        value !== null &&
                                        value !== ""
                                    ) {
                                        return yup
                                            .number()
                                            .required("Enter valid amount")
                                            .min(0)
                                            .isValidSync(value);
                                    }

                                    return true;
                                }
                            ),
                        code: yup
                            .string()
                            .when("amount", (amount: string, field) =>
                                amount
                                    ? field.required("Code is required")
                                    : field
                            ),
                    })
                ),
                amount: yup.number().min(0),
                payment_date: yup.string(),
                writeoff_code: yup.string(),
                payment_notes: yup.string(),
            })
        ),
    });

    const { fields, append, remove } = useFieldArray({
        control,
        name: "remark_codes_payment_form",
    });

    const updatePayment = useUpdatePayment();

    const params = useParams();

    const { noteId } = params;
    const { claimId } = params;

    const queryClient = useQueryClient();

    const { toast } = useToast();

    const postPayments = usePostPayments();

    const deletePayment = useUnMatchPayment();

    const writeOffCodesForSelect: Options[] = Object.values(WriteOffCodes).map(
        (item) => ({
            value: item,
            label: WriteOffCodesDecription[item],
        })
    );

    const remarkCodeForSelect: Options[] = Object.values(RemarkCodes)
        .map((item) => ({
            value: item, // Use the enum value as the option value
            label: RemarkCodeDescription[
                item as keyof typeof RemarkCodeDescription
            ],
            // Use this for the description
        }))
        .sort((a, b) => a.value.localeCompare(b.value));

    const getWriteOffCodeDescription = (code: string) => {
        const writeOffDescriptions = Object.values(WriteOffCodesDecription);
        const matchingDescription = writeOffDescriptions?.find(
            (description) =>
                code?.toLowerCase() === description.split(":")[0]?.toLowerCase()
        );
        return matchingDescription || "--";
    };

    const isRefundPayment = () => {
        const isRefund = payment.payment_type === "ri";

        return isRefund
            ? `(${showMoneyInAppFormat(payment?.amount)})`
            : `${showMoneyInAppFormat(payment?.amount)}`;
    };

    const handleEditButtonClicked = () => {
        setValue("amount", payment.amount);
        setValue("payment_notes", payment.payment_notes || "");
        setValue(
            "remark_codes_payment_form",
            remarkCodes
                ? Object.entries(remarkCodes).map(([code, amount]) => ({
                      code,
                      amount: amount === null ? "" : `${amount}`,
                  }))
                : [{ amount: "", code: "" }]
        );
        setValue("writeoff_code", payment.writeoff_code || "");
        setValue("payment_date", payment.payment_date || "");
        setLocalDateOfPayment(
            parseISO(
                payment.payment_date.replace("+00:00", "").replace("Z", "")
            )
        );
        setIsEditing(() => true);
    };

    const getStatusTag = () => {
        let bgColor: string;
        let textColor: string;

        switch (payment.payment_status) {
            case "completed":
                bgColor = "rgba(204, 250, 233, 0.50)";
                textColor = "#00563E";
                break;
            case "failed":
                bgColor = "rgba(251, 199, 198, 0.50)";
                textColor = "#981F41";
                break;
            default:
                bgColor = "rgba(247, 229, 164, 0.50)";
                textColor = "#634D17";
        }

        return { bgColor, textColor };
    };

    const getPaymentTypeTag = React.useCallback(
        (
            type: string,
            method: string,
            info?: {
                refund_reason?: string;
                cheque_number?: string;
                authorization_number?: string;
            },
            writeoff_code?: string,
            auth_code?: string
        ) => {
            switch (type) {
                case "wbi":
                    return (
                        <div className="flex items-center gap-x-4 whitespace-nowrap">
                            <Tag
                                bgColor="rgba(229, 229, 229, 0.50)"
                                textColor="#0B132B"
                                className="capitalize flex items-center font-normal px-8 py-4 h-24 rounded-r4"
                                title={
                                    <>
                                        Write-off:{" "}
                                        <span className="uppercase">
                                            {writeoff_code}
                                        </span>
                                    </>
                                }
                            />
                            <Tooltip
                                content={getWriteOffCodeDescription(
                                    writeoff_code || "--"
                                )}
                                classNames="uppercase"
                            >
                                <Button variant="normal" size="auto">
                                    <InfoIcon />
                                </Button>
                            </Tooltip>
                        </div>
                    );
                case "ri":
                case "rpr100":
                    return (
                        <span>
                            <Tag
                                bgColor="rgba(204, 250, 233, 0.50)"
                                textColor="#00563E"
                                className="font-normal px-8 py-4 h-24 rounded-r4 whitespace-nowrap"
                                title={
                                    <>
                                        Refund:{" "}
                                        {info?.refund_reason
                                            ? removeEnumUnderscore(
                                                  info?.refund_reason
                                              )
                                            : "Others"}
                                    </>
                                }
                            />
                        </span>
                    );
                case "i":
                case "ci":
                case "pr100":
                    return (
                        <div className="flex items-center gap-x-4 whitespace-nowrap">
                            <Tag
                                bgColor="rgba(247, 229, 164, 0.50)"
                                textColor="#634D1"
                                className="capitalize flex items-center font-normal px-8 py-4 h-24 rounded-r4"
                                title={
                                    <>
                                        Credit:{" "}
                                        {removeEnumUnderscore(
                                            method === "cheque"
                                                ? "check"
                                                : method
                                        )}
                                    </>
                                }
                            />
                            {info?.cheque_number && (
                                <Tooltip
                                    content={`CHECK NUMBER - ${info.cheque_number}`}
                                    classNames="uppercase"
                                >
                                    <Button variant="normal" size="auto">
                                        <InfoIcon />
                                    </Button>
                                </Tooltip>
                            )}
                            {auth_code && (
                                <Tooltip
                                    content={`AUTHORIZATION CODE - ${auth_code}`}
                                    classNames="uppercase"
                                >
                                    <Button variant="normal" size="auto">
                                        <InfoIcon />
                                    </Button>
                                </Tooltip>
                            )}
                            {info?.authorization_number && (
                                <Tooltip
                                    content={`AUTHORIZATION CODE - ${info.authorization_number}`}
                                    classNames="uppercase"
                                >
                                    <Button variant="normal" size="auto">
                                        <InfoIcon />
                                    </Button>
                                </Tooltip>
                            )}
                        </div>
                    );
                case "pi":
                    return (
                        <Tag
                            bgColor="rgba(172, 222, 250, 0.50)"
                            textColor="#165574"
                            className="font-normal px-8 py-4 h-24 rounded-r4"
                            title="Patient insurance"
                        />
                    );

                default:
                    return (
                        <div className="flex items-center gap-x-4 whitespace-nowrap">
                            <Tag
                                bgColor="rgba(229, 229, 229, 0.50)"
                                textColor="#0B132B"
                                className="capitalize flex items-center font-normal px-8 py-4 h-24 rounded-r4"
                                title={type ?? "others"}
                            />
                            {info?.refund_reason && (
                                <Tooltip
                                    content={info.refund_reason}
                                    classNames="uppercase"
                                >
                                    <Button variant="normal" size="auto">
                                        <InfoIcon />
                                    </Button>
                                </Tooltip>
                            )}
                        </div>
                    );
            }
        },
        []
    );

    const validateRemarkCodesPair = () => {
        const pairs = watch("remark_codes_payment_form") || [];
        const validCodesAndAmounts = remitClaimAdjustments
            ? remitClaimAdjustments.flatMap((item) => [
                  {
                      code: `${item.group_code}${item.reason_code}`.toLowerCase(),
                      amount: item.amount,
                  },
                  ...(item.reason_code_2
                      ? [
                            {
                                code: `${item.group_code}${item.reason_code_2}`.toLowerCase(),
                                amount: item.amount_2,
                            },
                        ]
                      : []),
              ])
            : [];

        // Validate each item
        const validPairs: RemarkCodePair[] = [];

        let errorMessage = "";

        const transformedPair = pairs.map((item) => ({
            code: item.code,
            amount: item.amount ? parseFloat(item.amount) : null,
        }));

        transformedPair.forEach((item) => {
            if (
                validCodesAndAmounts.find(
                    (innerItem) => innerItem.code === item.code
                )
            ) {
                validPairs.push(item);
                const correctAmountForCode = validCodesAndAmounts.find(
                    (innerItem) => innerItem.code === item.code
                )?.amount;

                errorMessage =
                    correctAmountForCode !== item.amount
                        ? `${item.code} - should have an amount of ${correctAmountForCode}\n`
                        : "";

                setRCValidationError(() =>
                    errorMessage.trim() ? errorMessage : null
                );
            }
        });

        if (errorMessage) {
            setDialogInView(() =>
                errorMessage.trim() ? "edit_warning" : null
            );
            return false;
        }
        return true;
    };

    const handleDeletePayment = (id: string) => {
        const deletePaymentPayload = {
            matched_payments: {
                [invoiceId]: [id],
            },
        };
        deletePayment.mutate(deletePaymentPayload, {
            onSuccess: (response) => {
                queryClient.invalidateQueries([
                    "remit-claims",
                    {
                        note_id: noteId,
                    },
                ]);

                queryClient.invalidateQueries({
                    queryKey: [
                        "get-claim",
                        handleRemoveEmptyParamFromQueryParams({
                            claim_id: claimId,
                        }),
                    ],
                });

                setDialogInView(null);
                toast({
                    mode: "success",
                    message: `${buildSuccessMessage(
                        response.data.successful_payments,
                        response.data.failed_payments
                    )}`,
                });
            },
            onError: (err) => {
                toast({
                    mode: "error",
                    message:
                        err.response?.data.message ||
                        "Could not delete payment at this time",
                });
            },
        });
    };

    const handlePostPayment = (id: string) => {
        postPayments.mutate(
            { payment_ids: [id] },
            {
                onSuccess: (res) => {
                    queryClient.invalidateQueries([
                        "remit-claims",
                        {
                            note_id: noteId,
                        },
                    ]);
                    toast({
                        mode: "success",
                        message:
                            res.message || "Payment(s) posted successfully.",
                    });
                    setDialogInView(null);
                },
                onError: (err) => {
                    toast({
                        mode: "error",
                        message:
                            err.response?.data.message ||
                            "Could not post payment(s).",
                    });
                },
            }
        );
    };

    const handleUpdatePayment = async (data: IUpdatePayment) => {
        const payload: IUpdatePayment = {
            amount: payment?.payment_type?.includes("wb")
                ? Number(data.amount)
                : undefined,
            payment_notes: data.payment_notes,
            payment_date: handleFormatDatePickerValue(data.payment_date),
            remark_codes: data.remark_codes,
            writeoff_code: data.writeoff_code || undefined,
            payment_id: payment?.payment_id || "",
        };

        if (dirtyFields.remark_codes_payment_form) {
            const getPairs = data.remark_codes_payment_form?.map((pair) => ({
                code: pair.code,
                amount: pair.amount ? parseFloat(pair.amount) : null,
            }));

            payload.remark_codes_payment =
                getPairs && getPairs?.length > 0
                    ? getPairs?.reduce((acc, curr) => {
                          // eslint-disable-next-line no-param-reassign
                          if (curr.code) acc[curr.code] = curr.amount;
                          return acc;
                      }, {} as Record<string, number | null>)
                    : undefined;
        }

        // clear // remark_codes_payment_form used in form
        payload.remark_codes_payment_form = undefined;

        updatePayment.mutate(payload, {
            onSuccess: async () => {
                toast({
                    mode: "success",
                    message: "Payment updated successfully.",
                });

                if (payment?.payment_status === "pending") {
                    await new Promise<void>((resolve, reject) => {
                        postPayments.mutate(
                            { payment_ids: [payload.payment_id] },
                            {
                                onSuccess: () => {
                                    toast({
                                        mode: "success",
                                        message:
                                            "Payment updated and confirmed successfully.",
                                    });
                                    resolve();
                                },
                                onError: (error) => {
                                    toast({
                                        mode: "error",
                                        message:
                                            "Could not confirm payment at this time",
                                    });
                                    reject(error);
                                },
                            }
                        );
                    });
                }
                queryClient.invalidateQueries({
                    queryKey: ["get-payment"],
                });
                queryClient.invalidateQueries({
                    queryKey: ["invoices"],
                });
                queryClient.invalidateQueries({
                    queryKey: [
                        "remit-claims",
                        handleRemoveEmptyParamFromQueryParams({
                            note_id: noteId,
                        }),
                    ],
                });
                reset();
                setIsEditing(false);
                setDialogInView(null);
                setRCValidationError(null);
            },
            onError: (err) => {
                toast({
                    mode: "error",
                    message:
                        err.response?.data.message ||
                        "Error updating payment please try again later",
                });
            },
        });
    };

    return (
        <>
            <Dialog open={dialogInView === "edit_warning"}>
                <DialogContent
                    title="Edit Payment(s)"
                    saveText="Edit payment(s)"
                    variant="center"
                    handleCloseDialog={() => {
                        setDialogInView(null);
                        setRCValidationError(null);
                    }}
                    onSaveClick={() => handleSubmit(handleUpdatePayment)()}
                    isSubmitBtnDisabled={updatePayment.isLoading}
                    isCancelBtnDisabled={updatePayment.isLoading}
                >
                    <div>
                        <h3 className="font-medium text-xl mb-8">
                            Are you sure you want to update payment(s)?
                        </h3>
                        <p className="text-base text-danger">
                            {rcValidationError}
                        </p>
                    </div>
                </DialogContent>
            </Dialog>
            <Dialog open={dialogInView === "delete_payment"}>
                <DialogContent
                    handleCloseDialog={() => setDialogInView(null)}
                    isDeleting
                    variant="center"
                    classNames="p-24"
                    title="Delete Payment"
                    saveText="Delete Payment"
                    onSaveClick={() => handleDeletePayment(payment.payment_id)}
                    isSubmitBtnDisabled={deletePayment.isLoading}
                    isCancelBtnDisabled={deletePayment.isLoading}
                >
                    <>
                        <h3 className="font-medium text-xl mb-8">
                            Are you sure you want to Delete this payment?
                        </h3>
                        <p className="text-gray font-normal text-base">
                            <span>
                                Deleting this payment will remove it from your
                                records. <br />
                                You will no longer be able to see this payment.
                            </span>
                        </p>
                    </>
                </DialogContent>
            </Dialog>
            <Dialog open={dialogInView === "post_payment"}>
                <DialogContent
                    handleCloseDialog={() => setDialogInView(null)}
                    variant="center"
                    classNames="p-24"
                    title="Confirm Payment"
                    saveText="Yes,confirm"
                    onSaveClick={() => handlePostPayment(payment.payment_id)}
                    isCancelBtnDisabled={postPayments.isLoading}
                    isSubmitBtnDisabled={postPayments.isLoading}
                >
                    <h3 className="px-8 text-center font-medium text-lg mb-8">
                        Are you sure you want to confirm this payment?
                    </h3>
                </DialogContent>
            </Dialog>
            <div className="grid gap-x-12 grid-cols-[100px_150px_300px_100px_120px_260px_150px_100px_100px_200px]  border-b border-b-strokedark p-12 text-sm text-nowrap font-normal">
                <div className="flex items-center gap-x-4 w-[100px]">
                    {!isEditing ? (
                        <>
                            <Tooltip content="delete payment">
                                <Button
                                    size="auto"
                                    variant="normal"
                                    onClick={() => {
                                        setDialogInView("delete_payment");
                                    }}
                                    className="px-4"
                                >
                                    <DeletePaymentIcon />
                                </Button>
                            </Tooltip>
                            <Tooltip content="edit payment">
                                <Button
                                    size="auto"
                                    variant="normal"
                                    onClick={handleEditButtonClicked}
                                    className="px-4"
                                    disabled={isEditing}
                                >
                                    <PencilIcon />
                                </Button>
                            </Tooltip>
                            {payment.payment_status !== "completed" ||
                            (payment.payment_type !== "wbi" &&
                                payment.payment_status !== "completed") ? (
                                <Tooltip content="confirm payment">
                                    <Button
                                        size="auto"
                                        variant="normal"
                                        onClick={() =>
                                            setDialogInView("post_payment")
                                        }
                                        className="px-4"
                                    >
                                        <ConfirmPaymentIcon />
                                    </Button>
                                </Tooltip>
                            ) : null}
                        </>
                    ) : (
                        <>
                            <Tooltip content="cancel">
                                <Button
                                    size="auto"
                                    variant="normal"
                                    onClick={() => setIsEditing(false)}
                                    className="px-4"
                                    disabled={updatePayment.isLoading}
                                >
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="16"
                                        height="17"
                                        viewBox="0 0 16 17"
                                        fill="none"
                                    >
                                        <path
                                            d="M3.3335 3.83594L12.6668 13.1693M3.3335 13.1693L12.6668 3.83594"
                                            stroke={APP_COLORS.COLOR_DANGER}
                                            strokeWidth="1.5"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        />
                                    </svg>
                                </Button>
                            </Tooltip>
                            <Tooltip content="save">
                                <Button
                                    size="auto"
                                    variant="normal"
                                    onClick={() => {
                                        if (validateRemarkCodesPair()) {
                                            handleSubmit(handleUpdatePayment)();
                                        }
                                    }}
                                    className="px-4"
                                    disabled={updatePayment.isLoading}
                                >
                                    <ConfirmPaymentIcon
                                        fill={APP_COLORS.COLOR_PRIMARY}
                                    />
                                </Button>
                            </Tooltip>
                        </>
                    )}
                </div>
                <div className="flex gap-8 items-center">
                    {isEditing ? (
                        <div className="w-[120px]">
                            <Controller
                                name="payment_date"
                                control={control}
                                render={({ field }) => (
                                    <DatePicker
                                        selected={localDateOfPayment}
                                        onChange={(date) => {
                                            field.onChange(date);
                                            setLocalDateOfPayment(date);
                                        }}
                                        className="h-28 w-[120px]"
                                        popperContainer={({ children }) =>
                                            ReactDOM.createPortal(
                                                children,
                                                document.body
                                            )
                                        }
                                        popperProps={{
                                            strategy: "fixed",
                                            modifiers: [
                                                {
                                                    name: "zIndex",
                                                    options: {
                                                        zIndex: 10,
                                                    },
                                                },
                                            ],
                                        }}
                                    />
                                )}
                            />
                        </div>
                    ) : (
                        formatDate(payment.payment_date, true) || "--"
                    )}
                </div>
                <div className="flex items-center">
                    {isEditing && payment.payment_type.includes("wb") ? (
                        <Controller
                            name="writeoff_code"
                            control={control}
                            render={({ field }) => (
                                <Select
                                    placeholder="Select code"
                                    isLongListInDialog
                                    onChange={(val) =>
                                        field.onChange((val as Options).value)
                                    }
                                    options={writeOffCodesForSelect}
                                    defaultValue={writeOffCodesForSelect.filter(
                                        (code) =>
                                            field.value?.includes(
                                                code.value || ""
                                            )
                                    )}
                                    isSearchable
                                    formatOptionLabel={(data, meta) => {
                                        return meta.context === "menu"
                                            ? formatRemarkOptionLabel(
                                                  data,
                                                  meta,
                                                  getWriteOffCodeDescription,
                                                  "writeoff_code"
                                              )
                                            : truncateString(
                                                  (data as Options)
                                                      .label as string,
                                                  10
                                              );
                                    }}
                                    classNames={{
                                        container: () => "w-[120px]",
                                        control: () => "!h-28",
                                        menu: () => "!w-[200px]",
                                        option: () => "!text-[11px]",
                                        input: () =>
                                            "!text-xs !h-20 !p-0 !my-0",
                                    }}
                                />
                            )}
                        />
                    ) : (
                        <>
                            {getPaymentTypeTag(
                                payment.payment_type,
                                payment.payment_method,
                                payment.payment_info,
                                payment.writeoff_code,
                                payment.auth_code
                            )}
                        </>
                    )}
                </div>
                <div
                    className={`flex flex-wrap items-center ${
                        payment.payment_type === "ri"
                            ? "text-danger"
                            : "text-black"
                    }`}
                >
                    {isEditing && payment.payment_type.includes("wb") ? (
                        <Input
                            {...register("amount")}
                            className="h-28 w-[100px]"
                            showCurrency
                        />
                    ) : (
                        isRefundPayment()
                    )}
                </div>
                <div className="flex items-center">
                    <span>
                        <Tag
                            bgColor={getStatusTag().bgColor}
                            textColor={getStatusTag().textColor}
                            title={makeStringFirstLetterCapital(
                                payment.payment_status
                            )}
                            className="capitalize rounded-r4 px-8 h-24 text-xs py-4"
                        />
                    </span>
                </div>
                <div>
                    {isEditing ? (
                        <div>
                            {Array.from({ length: fields.length }, (_, i) => (
                                <div
                                    key={`${payment.payment_id} key`}
                                    className="flex items-start mb-8 gap-x-8"
                                >
                                    <Controller
                                        name={`remark_codes_payment_form.${i}.code`}
                                        control={control}
                                        render={({ field }) => (
                                            <Select
                                                placeholder="Remark code"
                                                isSearchable
                                                options={remarkCodeForSelect}
                                                onChange={(val) =>
                                                    field.onChange(
                                                        (val as Options).value
                                                    )
                                                }
                                                value={remarkCodeForSelect.find(
                                                    (code) =>
                                                        field.value ===
                                                        (code.value || "")
                                                )}
                                                formatOptionLabel={(
                                                    selectData: unknown,
                                                    formatOptionLabelMeta: FormatOptionLabelMeta<unknown>
                                                ) => {
                                                    return formatOptionLabelMeta.context ===
                                                        "menu"
                                                        ? (
                                                              selectData as Options
                                                          ).label
                                                        : (
                                                              (
                                                                  selectData as Options
                                                              ).value as string
                                                          ).toUpperCase();
                                                }}
                                                isLongListInDialog
                                                classNames={{
                                                    container: () =>
                                                        "w-[120px]",
                                                    control: () => "!h-28",
                                                    menu: () => "!w-[200px]",
                                                    option: () =>
                                                        "!text-[11px]",
                                                    input: () =>
                                                        "!text-xs !h-20 !p-0 !my-0",
                                                    menuPortal: () => "!z-[11]",
                                                }}
                                                hasError={
                                                    !!errors
                                                        ?.remark_codes_payment_form?.[
                                                        i
                                                    ]?.code
                                                }
                                                errorText={
                                                    errors
                                                        ?.remark_codes_payment_form?.[
                                                        i
                                                    ]?.code?.message
                                                }
                                                errorTextClassName="text-[10px] mt-4 block"
                                            />
                                        )}
                                    />
                                    <div>
                                        <Input
                                            {...register(
                                                `remark_codes_payment_form.${i}.amount`
                                            )}
                                            key={`amount-${payment.payment_id}`}
                                            onFocus={(e) => {
                                                e.stopPropagation();
                                            }}
                                            placeholder="Amount"
                                            hasError={
                                                !!errors
                                                    ?.remark_codes_payment_form?.[
                                                    i
                                                ]?.amount
                                            }
                                            errorText={
                                                errors
                                                    ?.remark_codes_payment_form?.[
                                                    i
                                                ]?.amount
                                                    ? "Enter valid amount"
                                                    : ""
                                            }
                                            className="h-28 w-[100px]"
                                            errorTextClassName="-mt-4 text-[10px]"
                                            showCurrency
                                        />
                                    </div>
                                    <Button
                                        title="Remove pair"
                                        size="auto"
                                        variant="normal"
                                        className={
                                            !!errors
                                                ?.remark_codes_payment_form?.[i]
                                                ?.code ||
                                            !!errors
                                                ?.remark_codes_payment_form?.[i]
                                                ?.amount
                                                ? "self-start"
                                                : "self-center"
                                        }
                                        onClick={() => remove(i)}
                                        disabled={fields.length === 1}
                                    >
                                        <MinusCircleIcon />
                                    </Button>
                                </div>
                            ))}
                            <div className="flex items-center justify-center">
                                <Button
                                    size="auto"
                                    variant="normal"
                                    className="text-xs underline font-medium leading-none"
                                    onClick={() =>
                                        append(
                                            { amount: "", code: "" },
                                            {
                                                shouldFocus: false,
                                            }
                                        )
                                    }
                                >
                                    Add
                                </Button>
                            </div>
                        </div>
                    ) : (
                        <div className="flex gap-8 flex-wrap">
                            {remarkCodes
                                ? Object.entries(remarkCodes).map(
                                      ([code, amount]) => (
                                          <div
                                              key={code}
                                              className="flex items-center gap-x-4"
                                          >
                                              <Tag
                                                  bgColor="#BAECD1"
                                                  textColor="#44474F"
                                                  className="uppercase flex items-center font-normal px-4 py-0 h-20 rounded-r8 text-xs"
                                                  title={code}
                                              />
                                              <Tooltip
                                                  content={
                                                      amount
                                                          ? showMoneyInAppFormat(
                                                                amount
                                                            )
                                                          : "--"
                                                  }
                                              >
                                                  <Button
                                                      variant="normal"
                                                      size="auto"
                                                  >
                                                      <InfoIcon />
                                                  </Button>
                                              </Tooltip>
                                          </div>
                                      )
                                  )
                                : "--"}
                        </div>
                    )}
                </div>
                <div className="flex items-center">
                    {payment.eft_number ? (
                        <Link
                            to={`/remit/EOB/${remittanceId}`}
                            target="_blank"
                            rel="noreferrer"
                            className="text-primary text-sm underline"
                        >
                            {payment.eft_number}
                        </Link>
                    ) : (
                        "--"
                    )}
                </div>
                <div className="flex items-center">
                    {formatDate(payment.date_posted) || "--"}
                </div>
                <div className="flex items-center">
                    {Object.keys(payment.posted_by).length > 0
                        ? displayNameInRightFormat({
                              firstName: payment.posted_by.first_name,
                              lastName: payment.posted_by.last_name,
                          })
                        : "Mantle"}
                </div>
                <div className="flex flex-wrap w-[200px] items-center">
                    {isEditing ? (
                        <TextArea
                            {...register("payment_notes")}
                            className="h-[28px] w-[200px] resize-y py-0"
                            placeholder="Notes"
                        />
                    ) : (
                        payment.payment_notes || "--"
                    )}
                </div>
            </div>
        </>
    );
}
