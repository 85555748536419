import { AxiosProgressEvent } from "axios";
import axios from "../../../utils/axios";
import { handleRemoveEmptyParamFromQueryParams } from "../../../utils/helpers";
import {
    IAddManualRemit,
    IAddRemitClaims,
    IArchiveRemitPayload,
    IBatchRemitFilters,
    IDeleteManualBatchRemit,
    IDeleteManualClaimPayload,
    IPostPaymentsPayload,
    IRemitsFilters,
    IUpdateRemitStatus,
} from "../types/remits.types";

export async function fetchBatchRemits(filters: IBatchRemitFilters) {
    const response = await axios.get(`/batch-remits`, {
        params: handleRemoveEmptyParamFromQueryParams(filters),
    });
    return response.data;
}

export async function fetchRemitClaims(filters: IRemitsFilters) {
    const response = await axios.get(`/remits`, {
        params: handleRemoveEmptyParamFromQueryParams(filters),
    });
    return response.data;
}

// @deprecated
export async function postPayment(paymentId: string) {
    const response = await axios.patch(`/post-payment/${paymentId}`);
    return response.data;
}

export async function postPayments(payload: IPostPaymentsPayload) {
    const response = await axios.patch(`/post-payments`, payload);
    return response.data;
}

export async function updateRemitStatus(payload: IUpdateRemitStatus) {
    const response = await axios.patch(`/batch-remits`, payload);
    return response.data;
}

export async function deleteManualBatchRemit(payload: IDeleteManualBatchRemit) {
    const response = await axios.delete(`/batch-remits`, { data: payload });
    return response.data;
}

export async function fetchSingleRemitDocuments(
    remitId: string,
    sourceIsMantle?: boolean
) {
    const response = await axios.get(
        sourceIsMantle
            ? `/remit/eob/${remitId}?file_source=mantle`
            : `/remit/eob/${remitId}`
    );
    return response.data;
}

export async function addRemit(
    payload: FormData,
    onUploadProgress?: (progressEvent: AxiosProgressEvent) => void
) {
    const response = await axios.post(`/batch-remits`, payload, {
        onUploadProgress,
    });
    return response.data;
}

export async function addRemitClaims(
    batchRemitId: string,
    payload: IAddRemitClaims
) {
    const response = await axios.post(`/batch-remits/${batchRemitId}`, {
        ...payload,
    });
    return response.data;
}

/** Endpoint to delet remit documents */
export async function deleteRemitDocument(
    remitId: string,
    remittanceAttachmentId: string
) {
    const response = await axios.delete(
        `/remit/eob/${remitId}/attachments/${remittanceAttachmentId}`
    );

    return response.data;
}

export async function archiveRemit(payload: IArchiveRemitPayload) {
    const response = await axios.patch(`/remit/archive`, payload);

    return response.data;
}

export async function editRemit(
    batchRemitId: string,
    payload: IAddManualRemit
) {
    const response = await axios.patch(
        `/batch-remits/${batchRemitId}`,
        payload
    );
    return response.data;
}

export async function deleteManualRemitClaims(
    batchRemitId: string,
    payload: IDeleteManualClaimPayload
) {
    const response = await axios.delete(
        `/batch-remits/${batchRemitId}/claims`,
        {
            data: payload,
        }
    );
    return response.data;
}
