import { useParams } from "react-router-dom";
import { IBillingResponse } from "../../types/billing.types";
import { useGetInvoicePayment } from "../../hooks/queries/billing.queries";
import AddBillPaymentForm from "../AddBillPaymentForm/AddBillPaymentForm";
import { useFetchSessionNote } from "../../../../hooks/queries/note";
import PaymentItem from "../PaymentItem/PaymentItem";

interface IPaymentBreakdownProps {
    row: IBillingResponse;
    isExpanded: boolean;
}

export default function PaymentBreakdown({
    row,
    isExpanded,
}: IPaymentBreakdownProps) {
    const params = useParams();
    const clientId = params.clientId as string;
    const noteId = params.noteId as string;

    const getInvoicePayload = {
        invoice_id: row.invoice_id,
    };

    const { data, isLoading, isSuccess } = useGetInvoicePayment(
        row.invoice_id,
        getInvoicePayload,
        isExpanded
    );

    const noteDetails = useFetchSessionNote(
        clientId,
        noteId,
        Boolean(clientId) && Boolean(noteId)
    );
    //
    return (
        <div className="-ml-10 pt-12 bg-primary-50">
            <div className="grid grid-cols-[auto_200px_100px_100px_250px_260px_200px_100px_100px_318px_200px] gap-12 items-end px-24 pb-3 text-gray text-xs font-semibold border-b border-secondary">
                <span className="min-w-[30px] max-w-[200px]" />
                <span className="w-[200px]">PAYMENT DATE</span>
                <span className="w-[100px]">SOURCE</span>
                <span className="w-[100px]">PAYMENT STATUS</span>
                <span className="w-[250px]">TYPE</span>
                <span className="w-[260px]">REMARK CODES</span>
                <span className="w-[200px]">PAYMENT AMOUNT</span>
                <span className="w-[100px]">EFT</span>
                <span className="w-[100px]">POSTED BY</span>
                <span className="w-[318px]">PAYMENT NOTE</span>
                <span className="w-[200px]">DESCRIPTION</span>
            </div>
            {isLoading ? (
                <div
                    data-testid="loader"
                    className="flex items-center h-[100px] w-[1200px] max-w-ful justify-center"
                >
                    <div className="small-loader !w-[30px] !h-[30px]" />
                </div>
            ) : null}
            {!isLoading &&
                isSuccess &&
                data?.payment_breakdown?.map((item) => (
                    <PaymentItem key={item.payment_id} item={item} />
                ))}

            <div className="px-24 py-12 bg-white border-b border-b-strokedark bg-[rgbA(223,255,224,0.5)]">
                <AddBillPaymentForm
                    invoiceId={row.invoice_id}
                    claimId={noteDetails?.data?.data?.claim_id}
                    invoiceNoteId={noteId}
                />
            </div>
        </div>
    );
}
