import cn from "classnames";
import * as yup from "yup";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import React from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { useQueryClient } from "@tanstack/react-query";
import {
    Alert,
    DatePicker,
    Input,
    Select,
    Tag,
    TextArea,
} from "@jhool-io/fe-components";

import { FormatOptionLabelMeta } from "react-select";
import CheckPrimaryColorIcon from "../../../../components/Icons/CheckPrimaryColor";
import useToast from "../../../../hooks/useToast";
import {
    handleFormatDatePickerValue,
    makeStringFirstLetterCapital,
    removeEnumUnderscore,
    formatDate,
    truncateString,
    handleRemoveEmptyParamFromQueryParams,
} from "../../../../utils/helpers";
import { IPaginatedApiResponse } from "../../../../utils/types/api-response";
import { useAddInvoicePayment } from "../../../../hooks/mutations/billing";
import {
    IAddPayment,
    IAddPaymentResponse,
    PaymentMethod,
    PaymentModals,
    PaymentType,
    RefundReason,
    RemarkCodeDescription,
    RemarkCodes,
    WriteOffCodes,
    WriteOffCodesDecription,
    WriteOffSource,
} from "../../types/billing.types";
import { useFetchRemitClaims } from "../../../remits/hooks/remits.queries";
import Skeleton from "../../../../components/Skeleton/Skeleton";
import { useFetchSessionNote } from "../../../../hooks/queries/note";
import {
    useFetchInvoices,
    useGetInvoicePayment,
} from "../../hooks/queries/billing.queries";

type Options = {
    label: React.ReactNode;
    value: string | undefined;
};
interface FormProps extends IAddPayment {
    invoice_id: string;
    payment_amount: string;
    claim_id: string;
    remittance_id?: string;
    remark_codes?: string[];
}
interface ActionFormProps {
    // the ID of the invoice.
    invoiceId?: string;
    // the ID of the client.
    clientId: string;
    // function to call when the form's submit button is clicked.
    onFormSubmit(): void;
    // modal types
    modalType?: PaymentModals | null;
    // writeoff action types
    writeOffAction?: "move" | "close" | null;
    // optional transaction id to be used if provided
    transactionId?: string;
}

const writeOffCodeForSelect: Options[] = [
    ...Object.values(WriteOffCodes).map((item) => ({
        value: item,
        label: <>{WriteOffCodesDecription[item]} </>,
    })),
];

const remarkCodeForSelect: Options[] = Object.values(RemarkCodes)
    .map((item) => ({
        value: item, // Use the enum value as the option value
        label: RemarkCodeDescription[
            item as keyof typeof RemarkCodeDescription
        ], // Use this for the description
    }))
    .sort((a, b) => a.value.localeCompare(b.value));

const refundReasonForSelect: Options[] = [
    ...Object.values(RefundReason).map((item) => ({
        value: item,
        label: <>{makeStringFirstLetterCapital(removeEnumUnderscore(item))} </>,
    })),
];

export default function PaymentActionForm({
    invoiceId,
    onFormSubmit,
    modalType,
    writeOffAction,
    transactionId,
}: ActionFormProps) {
    const params = useParams();
    const { clientId, noteId } = params;

    const [paymentDate, setPaymentDate] = React.useState<Date | null>(
        new Date()
    );
    // const [paymentType, setPaymentType] = React.useState<string>(
    //     modalType && modalType !== "payment" ? modalType : "credit"
    // );
    const [paymentMethod, setPaymentMethod] = React.useState<string>();
    const [paymentSource, setPaymentSource] =
        React.useState<string>("insurance");

    const [searchParams, setSearchParams] = useSearchParams();

    // Toast for success and error states
    const { toast } = useToast();

    const { data: invoiceData, isLoading: invoiceIsLoading } = useFetchInvoices(
        {
            client_id: clientId,
            payment_view: true,
        }
    );

    const remitId = searchParams.get("remit_in_view");

    // Query client
    const queryClient = useQueryClient();
    const {
        register,
        control,
        handleSubmit,
        formState: { errors },
        watch,
        setValue,
    } = useForm<FormProps>({
        resolver: yupResolver(
            yup.object({
                payment_amount: yup
                    .number()
                    .required("Please enter a valid amount"),
                invoice_id: yup.string(),
                payment_date: yup.date().required("Payment date is required"),
                payment_notes: yup.string(),
                auth_code: yup.string(),
                remark_codes: yup.array().default([]),
                payment_description: yup
                    .string()
                    .max(255, "Character limit reached"),
                payment_type: yup.string(),
            })
        ),
        mode: "onChange",
        defaultValues: {
            payment_type:
                modalType && modalType !== "payment" ? modalType : "credit",
        },
    });

    const invoicId = watch("invoice_id");

    const selectedTransactionId = watch("transaction_id");

    const remarkCodes = watch("remark_codes");

    const paymentTypeForm = watch("payment_type") || "";

    const paymentSourcesForSelect: Options[] = [
        ...Object.values(WriteOffSource).map((item) => ({
            value: item,
            label:
                item === WriteOffSource.PR100
                    ? "PR100"
                    : makeStringFirstLetterCapital(removeEnumUnderscore(item)),
        })),
    ];

    const paymentTypeForSelect: Options[] = [
        ...Object.values(PaymentType).map((item) => ({
            value: item,
            label: makeStringFirstLetterCapital(removeEnumUnderscore(item)),
        })),
    ];

    const paymentMethodForSelect: Options[] = [
        ...Object.values(PaymentMethod).map((item) => ({
            value: item,
            label:
                item === "cheque"
                    ? "Check"
                    : makeStringFirstLetterCapital(removeEnumUnderscore(item)),
        })),
    ];

    const sessionInfoForSelect: Options[] = [
        ...Object.values(invoiceData?.data || []).map((invoice) => ({
            value: invoice.invoice_id,
            label: (
                <div className="flex items-center gap-x-16">
                    <Tag
                        title={`Date: ${formatDate(invoice.date_of_service)}`}
                        textColor="#0B132B"
                        bgColor="#F7FAF5"
                    />
                    {invoice.cpt_code && (
                        <Tag
                            title={`CPT: ${invoice.cpt_code}`}
                            textColor="#0B132B"
                            bgColor="#F7FAF5"
                        />
                    )}
                </div>
            ),
        })),
    ];

    const getInvoicePayload = {
        invoice_id: invoiceId as string,
    };

    const { data: paymentData, isLoading: paymentsLoading } =
        useGetInvoicePayment(
            invoiceId as string,
            getInvoicePayload,
            Boolean(invoiceId)
        );

    const paymentsArray =
        paymentData?.payment_breakdown &&
        paymentData.payment_breakdown.filter(
            (payment) => payment.payment_type === "ci"
        );

    const paymentInfoForSelect: Options[] = [
        ...Object.values(paymentsArray || []).map((payment) => ({
            value: payment.transaction_id,
            label: (
                <div className="flex items-center gap-x-16">
                    <Tag
                        title={`Date: ${formatDate(payment.payment_date)}`}
                        textColor="#0B132B"
                        bgColor="#F7FAF5"
                    />

                    {payment.refundable_amount ? (
                        <Tag
                            title={`Refundable amount: $${payment.refundable_amount}`}
                            textColor="#0B132B"
                            bgColor="#F7FAF5"
                        />
                    ) : null}
                </div>
            ),
        })),
    ];

    const selectedInvoiceDetails = invoiceData?.data.find(
        (invoice) => invoice.invoice_id === invoicId
    );

    const selectedPayment = transactionId
        ? paymentsArray?.find(
              (payment) => payment.transaction_id === transactionId
          )
        : paymentsArray?.find(
              (payment) => payment.transaction_id === selectedTransactionId
          );

    // Fetch note related to this bill
    const noteDetails = useFetchSessionNote(
        clientId || "",
        noteId || selectedInvoiceDetails?.note_id || "",
        (Boolean(clientId) && Boolean(noteId)) ||
            (Boolean(clientId) && Boolean(selectedInvoiceDetails))
    );

    // hook for fetching remit claims
    const remitClaims = useFetchRemitClaims(
        {
            claim_id: noteDetails?.data?.data.claim_id || "",
        },
        Boolean(noteDetails?.data?.data.claim_id)
    );

    // hook to add payment
    const addPayment = useAddInvoicePayment(invoicId || invoiceId || "");

    const paymentTypeToSend = () => {
        let type;
        if (paymentSource === "insurance") {
            switch (paymentTypeForm) {
                case "write_off":
                    type = "wbi";
                    break;
                case "refund":
                    type = "ri";
                    break;
                default:
                    type = "i";
            }
        } else if (paymentSource === "co_insurance") {
            switch (paymentTypeForm) {
                case "write_off":
                    type = "wbci";
                    break;
                case "refund":
                    type = "rci";
                    break;
                default:
                    type = "ci";
            }
        } else if (paymentSource === "pr100") {
            switch (paymentTypeForm) {
                case "refund":
                    type = "rpr100";
                    break;
                default:
                    type = "pr100";
            }
        }

        return type;
    };

    const writeOffSourceForSelect: Options[] = [
        ...Object.values(WriteOffSource).map((item) => ({
            value: item,
            label: (
                <div
                    className={cn("flex justify-between items-center", {
                        " text-primary": paymentSource === item,
                    })}
                    key={item}
                >
                    {makeStringFirstLetterCapital(removeEnumUnderscore(item))}
                    {paymentSource === item ? <CheckPrimaryColorIcon /> : null}
                </div>
            ),
        })),
    ];

    const claimRemitsForSelect = remitClaims?.data?.data.map((remit) => ({
        value: remit.remittance_id,
        label: `DOS: ${formatDate(remit.date_of_service)} / CN: ${
            remit.claim_number || "--"
        } / PD: ${formatDate(remit.remit_payment_date)}`,
    }));

    const isInsurancePayment = searchParams.get("payment_type") === "insurance";

    const onSubmit = (data: FormProps) => {
        const addPaymentPayload = {
            ...data,
            payment_amount: parseFloat(data?.payment_amount),
            payment_date: data.payment_date
                ? handleFormatDatePickerValue(data.payment_date)
                : handleFormatDatePickerValue(Date()),
            payment_type: paymentTypeToSend(),
            claim_id: noteDetails.data?.data.claim_id || undefined,
            remittance_id: data.remittance_id || remitId || undefined,
            remark_codes: data.remark_codes || undefined,
            invoice_id: undefined,
            close_invoice: writeOffAction === "close",
            // move_remaining: writeOffAction === "move",
            refund_payment_externally: Boolean(
                selectedPayment?.refundable_amount &&
                    selectedPayment?.refundable_amount > 0
            ),
            transaction_id: data.transaction_id || transactionId,
        };

        addPayment.mutate(addPaymentPayload, {
            onSuccess: (response) => {
                queryClient.setQueryData<
                    IPaginatedApiResponse<IAddPaymentResponse>
                >(["get-payment"], (prev) => {
                    const prevRequired =
                        prev as IPaginatedApiResponse<IAddPaymentResponse>;
                    return {
                        ...prevRequired,
                        message: response.message,
                    };
                });

                queryClient.invalidateQueries({
                    queryKey: [
                        "invoices",
                        handleRemoveEmptyParamFromQueryParams({
                            client_id: clientId,
                        }),
                    ],
                });

                queryClient.invalidateQueries({
                    queryKey: [
                        "invoices",
                        handleRemoveEmptyParamFromQueryParams({
                            invoice_id: invoiceId || invoicId,
                        }),
                    ],
                });

                queryClient.invalidateQueries({
                    queryKey: ["get-payment"],
                });
                queryClient.invalidateQueries({
                    queryKey: [
                        "remit-claims",
                        handleRemoveEmptyParamFromQueryParams({
                            note_id: noteId,
                        }),
                    ],
                });

                toast({
                    mode: "success",
                    message: response.message || "Payment added successfully",
                });
                onFormSubmit();
            },

            onError: (err) => {
                if (
                    err?.response?.data.errors &&
                    err?.response?.data?.errors?.length > 0 &&
                    err?.response?.data?.errors[0].path === "payment_amount" &&
                    err?.response?.data?.errors[0].message === "must be >= 0"
                ) {
                    toast({
                        mode: "error",
                        message: "Amount cannot be less $1",
                    });
                } else {
                    toast({
                        mode: "error",
                        message:
                            err.response?.data.message ||
                            "Cannot add payment at this time",
                    });
                }
            },
        });
    };

    const getWriteOffSubmitBtnValue = () => {
        if (writeOffAction === "move") {
            return "writeoff-move-form";
        }
        if (writeOffAction === "close") {
            return "writeoff-close-form";
        }
        return "action-form";
    };

    return (
        <>
            {modalType === "payment" && (
                <form
                    id="action-form"
                    name="action-form"
                    onSubmit={handleSubmit(onSubmit)}
                >
                    {!noteId && (
                        <>
                            {invoiceIsLoading && (
                                <Skeleton className=" h-40 rounded-r6 w-full" />
                            )}
                            <div className="fg">
                                {invoiceData?.data && (
                                    <Controller
                                        name="invoice_id"
                                        control={control}
                                        defaultValue={
                                            sessionInfoForSelect.find(
                                                (session) =>
                                                    session.value === invoiceId
                                            )?.value
                                        }
                                        render={({ field }) => (
                                            <Select
                                                label="Session"
                                                placeholder="Session"
                                                options={sessionInfoForSelect}
                                                onChange={(val) =>
                                                    field.onChange(
                                                        (val as Options).value
                                                    )
                                                }
                                                defaultValue={sessionInfoForSelect.filter(
                                                    (session) =>
                                                        session.value ===
                                                        invoiceId
                                                )}
                                            />
                                        )}
                                    />
                                )}
                            </div>
                        </>
                    )}

                    <div className="fg">
                        <Select
                            label="Source"
                            placeholder="Source"
                            defaultValue={{
                                value: "insurance",
                                label: "Insurance",
                            }}
                            onChange={(val) => {
                                setPaymentSource(
                                    (val as Options).value as string
                                );
                                setValue("payment_type", "credit");
                            }}
                            options={paymentSourcesForSelect}
                            isDisabled={isInsurancePayment}
                        />
                    </div>

                    <div className="fg">
                        <Controller
                            name="payment_type"
                            control={control}
                            render={({ field }) => (
                                <Select
                                    label="Payment Type"
                                    placeholder="Payment Type"
                                    options={
                                        paymentSource === "pr100"
                                            ? paymentTypeForSelect.filter(
                                                  (item) =>
                                                      item.value !== "write_off"
                                              )
                                            : paymentTypeForSelect
                                    }
                                    onChange={(val) =>
                                        field.onChange((val as Options).value)
                                    }
                                    defaultValue={paymentTypeForSelect.find(
                                        (item) =>
                                            modalType && modalType !== "payment"
                                                ? item.value === modalType
                                                : item.value === "credit"
                                    )}
                                />
                            )}
                        />
                    </div>

                    {paymentSource === "insurance" && !remitId && (
                        <div className="fg">
                            <Controller
                                name="remittance_id"
                                control={control}
                                defaultValue={claimRemitsForSelect?.[0]?.value}
                                render={({ field }) => (
                                    <Select
                                        label="Claim remits"
                                        placeholder="Claim remits"
                                        options={claimRemitsForSelect}
                                        defaultValue={claimRemitsForSelect?.[0]}
                                        onChange={(val) =>
                                            field.onChange(
                                                (val as Options).value
                                            )
                                        }
                                    />
                                )}
                            />
                        </div>
                    )}

                    {paymentTypeForm === "refund" && (
                        <div className="fg">
                            <Input
                                {...register("payment_amount")}
                                label="Refund Amount"
                                placeholder="Refund Amount"
                                hasError={!!errors.payment_amount}
                                errorText={
                                    errors.payment_amount?.type === "typeError"
                                        ? "This field is required and must be a number"
                                        : "Amount must be greater or equal to 0"
                                }
                                showCurrency
                            />
                        </div>
                    )}

                    {paymentTypeForm === "write_off" && (
                        <div className="fg">
                            <Input
                                {...register("payment_amount")}
                                label="WriteOff Amount"
                                placeholder="WriteOff Amount"
                                hasError={!!errors.payment_amount}
                                errorText={
                                    errors.payment_amount?.type === "typeError"
                                        ? "This field is required and must be a number"
                                        : "Amount must be greater or equal to 0"
                                }
                                showCurrency
                            />
                        </div>
                    )}

                    {paymentTypeForm === "credit" && (
                        <div className="fg">
                            <Input
                                {...register("payment_amount")}
                                label="Payment Amount"
                                placeholder="Payment Amount"
                                hasError={!!errors.payment_amount}
                                errorText={
                                    errors.payment_amount?.type === "typeError"
                                        ? "This field is required and must be a number"
                                        : "Amount must be greater or equal to 0"
                                }
                                showCurrency
                            />
                        </div>
                    )}

                    {paymentTypeForm === "write_off" && (
                        <div className="fg">
                            <Controller
                                name="payment_date"
                                control={control}
                                defaultValue={handleFormatDatePickerValue(
                                    Date()
                                )}
                                render={({ field }) => (
                                    <DatePicker
                                        label="Writeoff Date"
                                        onChange={(date) => {
                                            field.onChange(date);
                                            setPaymentDate(date);
                                        }}
                                        selected={paymentDate}
                                        hasError={!!errors.payment_date}
                                        errorText={
                                            errors.payment_date?.type ===
                                            "typeError"
                                                ? "invalid date value"
                                                : errors.payment_date?.message
                                        }
                                    />
                                )}
                            />
                        </div>
                    )}

                    {paymentTypeForm === "refund" && (
                        <div className="fg">
                            <Controller
                                name="payment_date"
                                control={control}
                                defaultValue={handleFormatDatePickerValue(
                                    Date()
                                )}
                                render={({ field }) => (
                                    <DatePicker
                                        label="Refund Date"
                                        onChange={(date) => {
                                            field.onChange(date);
                                            setPaymentDate(date);
                                        }}
                                        selected={paymentDate}
                                        hasError={!!errors.payment_date}
                                        errorText={
                                            errors.payment_date?.type ===
                                            "typeError"
                                                ? "invalid date value"
                                                : errors.payment_date?.message
                                        }
                                    />
                                )}
                            />
                        </div>
                    )}

                    {paymentTypeForm === "credit" && (
                        <div className="fg">
                            <Controller
                                name="payment_method"
                                control={control}
                                render={({ field }) => (
                                    <Select
                                        label="Payment Method"
                                        placeholder="Payment Method"
                                        options={paymentMethodForSelect}
                                        defaultValue="Payment Method"
                                        wrapperClass="select"
                                        onChange={(val) => {
                                            field.onChange(
                                                (val as Options).value
                                            );
                                            setPaymentMethod(
                                                (val as Options).value
                                            );
                                        }}
                                    />
                                )}
                            />
                        </div>
                    )}

                    {paymentMethod === "cheque" && (
                        <div className="fg">
                            <Input
                                {...register("payment_info.cheque_number")}
                                label="Cheque Number"
                                placeholder="Cheque Number"
                            />
                        </div>
                    )}

                    {(paymentMethod === "card" ||
                        paymentMethod === "other") && (
                        <div className="fg">
                            <Input
                                {...register("auth_code")}
                                label="Authorization Code"
                                placeholder="Authorization Code"
                                hasError={!!errors.auth_code}
                                errorText={errors.auth_code?.message}
                            />
                        </div>
                    )}

                    {paymentTypeForm === "write_off" && (
                        <div className="fg">
                            <Controller
                                name="writeoff_code"
                                control={control}
                                render={({ field }) => (
                                    <Select
                                        label="Writeoff Code"
                                        isSearchable
                                        placeholder="Write-Off Code"
                                        options={writeOffCodeForSelect}
                                        wrapperClass="select"
                                        onChange={(val) =>
                                            field.onChange(
                                                (val as Options).value
                                            )
                                        }
                                        isLongListInDialog
                                    />
                                )}
                            />
                        </div>
                    )}

                    {paymentSource === "insurance" && (
                        <div className="fg">
                            <Controller
                                name="remark_codes"
                                control={control}
                                render={({ field }) => (
                                    <Select
                                        label="Remark Codes"
                                        placeholder="Remark Codes"
                                        isSearchable
                                        options={remarkCodeForSelect}
                                        onChange={(val) => {
                                            field.onChange(
                                                (val as Options[]).map(
                                                    (code) => code.value
                                                )
                                            );
                                        }}
                                        formatOptionLabel={(
                                            data: unknown,
                                            formatOptionLabelMeta: FormatOptionLabelMeta<unknown>
                                        ) => {
                                            return formatOptionLabelMeta.context ===
                                                "menu"
                                                ? (data as Options).label
                                                : truncateString(
                                                      (data as Options)
                                                          .label as string,
                                                      60
                                                  );
                                        }}
                                        isMulti
                                        multiHasValues={
                                            remarkCodes &&
                                            remarkCodes.length > 0
                                        }
                                        isLongListInDialog
                                    />
                                )}
                            />
                        </div>
                    )}

                    {paymentTypeForm === "refund" && (
                        <div className="fg">
                            <Controller
                                name="payment_info.refund_reason"
                                control={control}
                                render={({ field }) => (
                                    <Select
                                        label="Refund Reason"
                                        placeholder="Refund Reason"
                                        isSearchable
                                        options={refundReasonForSelect}
                                        wrapperClass="select"
                                        onChange={(val) =>
                                            field.onChange(
                                                (val as Options).value
                                            )
                                        }
                                        isLongListInDialog
                                    />
                                )}
                            />
                        </div>
                    )}

                    {paymentTypeForm === "credit" && (
                        <div className="fg">
                            <Controller
                                name="payment_date"
                                control={control}
                                defaultValue={handleFormatDatePickerValue(
                                    Date()
                                )}
                                render={({ field }) => (
                                    <DatePicker
                                        label="Payment Date"
                                        onChange={(date) => {
                                            field.onChange(date);
                                            setPaymentDate(date);
                                        }}
                                        selected={paymentDate}
                                        hasError={!!errors.payment_date}
                                        errorText={
                                            errors.payment_date?.type ===
                                            "typeError"
                                                ? "invalid date value"
                                                : errors.payment_date?.message
                                        }
                                    />
                                )}
                            />
                        </div>
                    )}

                    <div className="fg">
                        <TextArea
                            {...register("payment_notes")}
                            label="Notes"
                            placeholder="Notes"
                            hasError={!!errors.payment_notes}
                            errorText={errors.payment_notes?.message}
                        />
                    </div>
                </form>
            )}
            {modalType === "write_off" && (
                <form
                    id={getWriteOffSubmitBtnValue()}
                    name="action-form"
                    onSubmit={handleSubmit(onSubmit)}
                >
                    {!noteId && (
                        <>
                            {invoiceIsLoading && (
                                <Skeleton className=" h-40 rounded-r6 w-full" />
                            )}
                            <div className="fg">
                                {invoiceData?.data && (
                                    <Controller
                                        name="invoice_id"
                                        control={control}
                                        defaultValue={
                                            sessionInfoForSelect.find(
                                                (session) =>
                                                    session.value === invoiceId
                                            )?.value
                                        }
                                        render={({ field }) => (
                                            <Select
                                                label="Session"
                                                placeholder="Session"
                                                options={sessionInfoForSelect}
                                                onChange={(val) =>
                                                    field.onChange(
                                                        (val as Options).value
                                                    )
                                                }
                                                defaultValue={sessionInfoForSelect.filter(
                                                    (session) =>
                                                        session.value ===
                                                        invoiceId
                                                )}
                                            />
                                        )}
                                    />
                                )}
                            </div>
                        </>
                    )}
                    <div className="fg">
                        <Select
                            label="Source"
                            placeholder="Source"
                            options={writeOffSourceForSelect}
                            defaultValue={{
                                value: "insurance",
                                label: "Insurance",
                            }}
                            onChange={(val) => {
                                setPaymentSource(
                                    (val as Options).value as string
                                );

                                if ((val as Options).value === "insurance") {
                                    searchParams.set(
                                        "writeoff_type",
                                        "insurance"
                                    );
                                    setSearchParams(searchParams);
                                }
                                if ((val as Options).value === "co_insurance") {
                                    searchParams.delete("writeoff_type");
                                    setSearchParams(searchParams);
                                }
                            }}
                            isDisabled={isInsurancePayment}
                        />
                    </div>

                    {paymentSource === "insurance" && !remitId && (
                        <div className="fg">
                            <Controller
                                name="remittance_id"
                                control={control}
                                defaultValue={claimRemitsForSelect?.[0]?.value}
                                render={({ field }) => (
                                    <Select
                                        label="Claim remits"
                                        placeholder="Claim remits"
                                        options={claimRemitsForSelect}
                                        defaultValue={claimRemitsForSelect?.[0]}
                                        onChange={(val) =>
                                            field.onChange(
                                                (val as Options).value
                                            )
                                        }
                                    />
                                )}
                            />
                        </div>
                    )}
                    <div className="fg">
                        <Input
                            {...register("payment_amount")}
                            label="Writeoff Amount"
                            placeholder="Writeoff Amount"
                            hasError={!!errors.payment_amount}
                            errorText={
                                errors.payment_amount?.type === "typeError"
                                    ? "This field is required and must be a number"
                                    : "Amount must be greater or equal to 0"
                            }
                            showCurrency
                        />
                    </div>
                    <div className="fg">
                        <Controller
                            name="payment_date"
                            control={control}
                            defaultValue={handleFormatDatePickerValue(Date())}
                            render={({ field }) => (
                                <DatePicker
                                    label="Writeoff Date"
                                    onChange={(date) => {
                                        field.onChange(date);
                                        setPaymentDate(date);
                                    }}
                                    selected={paymentDate}
                                    hasError={!!errors.payment_date}
                                    errorText={
                                        errors.payment_date?.type ===
                                        "typeError"
                                            ? "invalid date value"
                                            : errors.payment_date?.message
                                    }
                                />
                            )}
                        />
                    </div>
                    <div className="fg">
                        <Controller
                            name="writeoff_code"
                            control={control}
                            render={({ field }) => (
                                <Select
                                    label="Writeoff Code"
                                    placeholder="Write-Off Code"
                                    isSearchable
                                    options={writeOffCodeForSelect}
                                    onChange={(val) =>
                                        field.onChange((val as Options).value)
                                    }
                                    isLongListInDialog
                                />
                            )}
                        />
                    </div>
                    {paymentSource === "insurance" && (
                        <div className="fg">
                            <Controller
                                name="remark_codes"
                                control={control}
                                render={({ field }) => (
                                    <Select
                                        label="Remark Codes"
                                        placeholder="Remark Codes"
                                        isSearchable
                                        options={remarkCodeForSelect}
                                        onChange={(val) => {
                                            field.onChange(
                                                (val as Options[]).map(
                                                    (code) => code.value
                                                )
                                            );
                                        }}
                                        formatOptionLabel={(
                                            data: unknown,
                                            formatOptionLabelMeta: FormatOptionLabelMeta<unknown>
                                        ) => {
                                            return formatOptionLabelMeta.context ===
                                                "menu"
                                                ? (data as Options).label
                                                : truncateString(
                                                      (data as Options)
                                                          .label as string,
                                                      60
                                                  );
                                        }}
                                        isMulti
                                        multiHasValues={
                                            remarkCodes &&
                                            remarkCodes.length > 0
                                        }
                                        isLongListInDialog
                                    />
                                )}
                            />
                        </div>
                    )}
                    <div className="fg">
                        <TextArea
                            {...register("payment_notes")}
                            label="Notes"
                            placeholder="Notes"
                            hasError={!!errors.payment_notes}
                            errorText={errors.payment_notes?.message}
                        />
                    </div>
                </form>
            )}
            {modalType === "refund" && (
                <form
                    id="action-form"
                    name="action-form"
                    onSubmit={handleSubmit(onSubmit)}
                >
                    {!noteId && !transactionId && (
                        <>
                            {invoiceIsLoading && (
                                <Skeleton className="h-40 rounded-r6 w-full" />
                            )}
                            <div className="fg">
                                {invoiceData?.data && (
                                    <Controller
                                        name="invoice_id"
                                        control={control}
                                        defaultValue={
                                            sessionInfoForSelect.find(
                                                (session) =>
                                                    session.value === invoiceId
                                            )?.value
                                        }
                                        render={({ field }) => (
                                            <Select
                                                label="Session"
                                                placeholder="Session"
                                                options={sessionInfoForSelect}
                                                onChange={(val) =>
                                                    field.onChange(
                                                        (val as Options).value
                                                    )
                                                }
                                                defaultValue={sessionInfoForSelect.filter(
                                                    (session) =>
                                                        session.value ===
                                                        invoiceId
                                                )}
                                            />
                                        )}
                                    />
                                )}
                            </div>
                        </>
                    )}

                    {!isInsurancePayment && (
                        <>
                            {paymentsLoading && (
                                <div className="fg">
                                    <Skeleton className=" h-40 rounded-r6 w-full" />
                                </div>
                            )}
                            {paymentsArray && paymentsArray.length > 0 && (
                                <div className="fg">
                                    <Controller
                                        name="transaction_id"
                                        control={control}
                                        defaultValue={
                                            paymentInfoForSelect.find(
                                                (payment) =>
                                                    payment.value ===
                                                    transactionId
                                            )?.value
                                        }
                                        render={({ field }) => (
                                            <Select
                                                label="Payment"
                                                placeholder="Payment"
                                                options={paymentInfoForSelect}
                                                onChange={(val) =>
                                                    field.onChange(
                                                        (val as Options).value
                                                    )
                                                }
                                                defaultValue={paymentInfoForSelect.find(
                                                    (payment) =>
                                                        payment.value ===
                                                        transactionId
                                                )}
                                            />
                                        )}
                                    />
                                </div>
                            )}

                            {selectedPayment?.refundable_amount &&
                                selectedPayment?.refundable_amount > 0 && (
                                    <Alert
                                        type="info"
                                        description="This refund will be made on Authorize.net"
                                        classNames="mb-32"
                                    />
                                )}
                        </>
                    )}

                    {!transactionId && !selectedTransactionId && (
                        <>
                            <div className="fg">
                                <Select
                                    label="Source"
                                    placeholder="Source"
                                    options={writeOffSourceForSelect}
                                    defaultValue={{
                                        value: "insurance",
                                        label: "Insurance",
                                    }}
                                    onChange={(val) =>
                                        setPaymentSource(
                                            (val as Options).value as string
                                        )
                                    }
                                    isDisabled={isInsurancePayment}
                                />
                            </div>
                            {paymentSource === "insurance" && !remitId && (
                                <div className="fg">
                                    <Controller
                                        name="remittance_id"
                                        control={control}
                                        render={({ field }) => (
                                            <Select
                                                label="Claim remits"
                                                placeholder="Claim remits"
                                                options={
                                                    claimRemitsForSelect || []
                                                }
                                                defaultValue={
                                                    claimRemitsForSelect?.[0]
                                                }
                                                onChange={(val) =>
                                                    field.onChange(
                                                        (val as Options).value
                                                    )
                                                }
                                            />
                                        )}
                                    />
                                </div>
                            )}
                        </>
                    )}

                    <div className="fg">
                        <Input
                            {...register("payment_amount")}
                            label="Refund Amount"
                            placeholder="Refund Amount"
                            hasError={!!errors.payment_amount}
                            errorText={
                                errors.payment_amount?.type === "typeError"
                                    ? "This field is required and must be a number"
                                    : "Amount must be greater or equal to 0"
                            }
                            showCurrency
                        />
                    </div>

                    {paymentSource === "insurance" &&
                        !transactionId &&
                        !selectedTransactionId && (
                            <div className="fg">
                                <Controller
                                    name="remark_codes"
                                    control={control}
                                    render={({ field }) => (
                                        <Select
                                            label="Remark Codes"
                                            placeholder="Remark Codes"
                                            isSearchable
                                            options={remarkCodeForSelect}
                                            onChange={(val) => {
                                                field.onChange(
                                                    (val as Options[]).map(
                                                        (code) => code.value
                                                    )
                                                );
                                            }}
                                            formatOptionLabel={(
                                                data: unknown,
                                                formatOptionLabelMeta: FormatOptionLabelMeta<unknown>
                                            ) => {
                                                return formatOptionLabelMeta.context ===
                                                    "menu"
                                                    ? (data as Options).label
                                                    : truncateString(
                                                          (data as Options)
                                                              .label as string,
                                                          60
                                                      );
                                            }}
                                            isMulti
                                            multiHasValues={
                                                remarkCodes &&
                                                remarkCodes.length > 0
                                            }
                                            isLongListInDialog
                                        />
                                    )}
                                />
                            </div>
                        )}

                    <div className="fg">
                        <Controller
                            name="payment_date"
                            control={control}
                            defaultValue={handleFormatDatePickerValue(Date())}
                            render={({ field }) => (
                                <DatePicker
                                    label="Refund Date"
                                    onChange={(date) => {
                                        field.onChange(date);
                                        setPaymentDate(date);
                                    }}
                                    selected={paymentDate}
                                    hasError={!!errors.payment_date}
                                    errorText={
                                        errors.payment_date?.type ===
                                        "typeError"
                                            ? "invalid date value"
                                            : errors.payment_date?.message
                                    }
                                />
                            )}
                        />
                    </div>

                    <div className="fg">
                        <Controller
                            name="payment_info.refund_reason"
                            control={control}
                            render={({ field }) => (
                                <Select
                                    label="Refund Reason"
                                    placeholder="Refund Reason"
                                    options={refundReasonForSelect}
                                    onChange={(val) =>
                                        field.onChange((val as Options).value)
                                    }
                                    isLongListInDialog
                                />
                            )}
                        />
                    </div>
                    <div className="fg">
                        <TextArea
                            {...register("payment_notes")}
                            label="Notes"
                            placeholder="Notes"
                            hasError={!!errors.payment_notes}
                            errorText={errors.payment_notes?.message}
                        />
                    </div>

                    {selectedPayment ? (
                        <div className="fg">
                            <TextArea
                                {...register("payment_description")}
                                label="Description"
                                placeholder="Description"
                                defaultValue={`Overpayment for session fee ${
                                    selectedPayment?.date_of_service
                                        ? formatDate(
                                              selectedPayment?.date_of_service
                                          )
                                        : ""
                                }`}
                                hasError={!!errors.payment_description}
                                errorText={errors.payment_description?.message}
                                maxLength={256}
                            />
                        </div>
                    ) : null}
                </form>
            )}
        </>
    );
}
