import { combineReducers, configureStore } from "@reduxjs/toolkit";
import navReducer from "./features/nav/navSlice";
import practiceReducer from "./features/practice/practiceSlice";

const rootReducer = combineReducers({
    nav: navReducer,
    userPractice: practiceReducer,
});

export const store = (preloadedState?: Partial<RootState>) => {
    return configureStore({
        reducer: rootReducer,
        preloadedState,
    });
};

export type RootState = ReturnType<typeof rootReducer>;
export type AppStore = ReturnType<typeof store>;

export type AppDispatch = AppStore["dispatch"];
