export default function MarkAsPendingIcon() {
    return (
        <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M7.99992 9.66927C8.92039 9.66927 9.66659 8.92308 9.66659 8.0026C9.66659 7.08213 8.92039 6.33594 7.99992 6.33594C7.07944 6.33594 6.33325 7.08213 6.33325 8.0026C6.33325 8.92308 7.07944 9.66927 7.99992 9.66927Z"
                stroke="#0B132B"
                strokeWidth="1.2"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M12.3333 6.33594V9.66927"
                stroke="#0B132B"
                strokeWidth="1.2"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M3.33341 14.6693C4.80617 14.6693 6.00008 13.4754 6.00008 12.0026C6.00008 10.5298 4.80617 9.33594 3.33341 9.33594C1.86066 9.33594 0.666748 10.5298 0.666748 12.0026C0.666748 13.4754 1.86066 14.6693 3.33341 14.6693Z"
                stroke="#0B132B"
                strokeWidth="1.2"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M3.50008 11.1719V11.7919C3.50008 12.0252 3.38009 12.2452 3.17342 12.3652L2.66675 12.6719"
                stroke="#0B132B"
                strokeWidth="1.2"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M1.33325 10.1307V5.9974C1.33325 3.66406 2.66659 2.66406 4.66659 2.66406H11.3333C13.3333 2.66406 14.6666 3.66406 14.6666 5.9974V9.9974C14.6666 12.3307 13.3333 13.3307 11.3333 13.3307H5.66659"
                stroke="#0B132B"
                strokeWidth="1.2"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}
