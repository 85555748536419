import * as React from "react";
import {
    Button,
    DatePicker,
    Input,
    Select,
    Tag,
    TextArea,
    Tooltip,
} from "@jhool-io/fe-components";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import ReactDOM from "react-dom";
import { useParams, useSearchParams } from "react-router-dom";
import { useQueryClient } from "@tanstack/react-query";
import { FormatOptionLabelMeta } from "react-select";
import { APP_COLORS } from "../../../../utils/constants";
import CheckCircleIcon from "../../../../components/Icons/CheckCirlce";
import ConfirmPaymentIcon from "../../../../components/Icons/ConfirmPayment";
import {
    formatDate,
    handleFormatDatePickerValue,
    handleRemoveEmptyParamFromQueryParams,
    makeStringFirstLetterCapital,
    removeEnumUnderscore,
    truncateString,
} from "../../../../utils/helpers";
import {
    IAddPayment,
    IAddPaymentResponse,
    PaymentMethod,
    PaymentType,
    RefundReason,
    RemarkCodeDescription,
    RemarkCodes,
    WriteOffCodes,
    WriteOffCodesDecription,
    WriteOffSource,
} from "../../types/billing.types";
import {
    formatRemarkOptionLabel,
    paymentDateToDisplay,
} from "../../helpers/billing.helpers";
import { useFetchRemitClaims } from "../../../remits/hooks/remits.queries";
import { useFetchSessionNote } from "../../../../hooks/queries/note";
import { useAddInvoicePayment } from "../../../../hooks/mutations/billing";
import { useGetInvoicePayment } from "../../hooks/queries/billing.queries";
import Skeleton from "../../../../components/Skeleton/Skeleton";
import useToast from "../../../../hooks/useToast";
import { IPaginatedApiResponse } from "../../../../utils/types/api-response";
import InfoBlueIcon from "../../../../components/Icons/InfoBlue";

interface AddBillPaymentFormProps {
    invoiceId: string;
    invoiceNoteId?: string;
    claimId?: string;
    transactionId?: string;
}

type Options = {
    label: React.ReactNode;
    value: string | undefined;
};

export default function AddBillPaymentForm({
    invoiceId,
    invoiceNoteId,
    claimId,
    transactionId,
}: AddBillPaymentFormProps) {
    const [paymentSource, setPaymentSource] =
        React.useState<string>("insurance");
    const [paymentDate, setPaymentDate] = React.useState<Date | null>(
        new Date()
    );
    const [formKey, setFormKey] = React.useState(0);

    const [searchParams] = useSearchParams();
    const params = useParams();

    const { clientId, noteId } = params;

    const addPayment = useAddInvoicePayment(invoiceId);

    const {
        register,
        control,
        handleSubmit,
        formState: { errors, dirtyFields },
        watch,
        setValue,
        reset,
    } = useForm<IAddPayment>({
        resolver: yupResolver(
            yup.object({
                remark_codes_payment_form: yup.array().of(
                    yup.object().shape({
                        amount: yup
                            .mixed()
                            .test(
                                "is-amount-valid",
                                "Enter valid amount",
                                (value) => {
                                    if (
                                        value !== undefined &&
                                        value !== null &&
                                        value !== ""
                                    ) {
                                        return yup
                                            .number()
                                            .required("Enter valid amount")
                                            .min(0)
                                            .isValidSync(value);
                                    }

                                    return true;
                                }
                            ),
                        code: yup
                            .string()
                            .when("amount", (amount: string, field) =>
                                amount
                                    ? field.required("Code is required")
                                    : field
                            ),
                    })
                ),
                payment_amount: yup
                    .number()
                    .required("Please enter a valid amount"),
                invoice_id: yup.string(),
                payment_date: yup.date().required("Payment date is required"),
                payment_notes: yup.string(),
                auth_code: yup.string(),
                remark_codes: yup.array().default([]),
                payment_description: yup
                    .string()
                    .max(255, "Character limit reached"),
                payment_type: yup.string(),
            })
        ),
        mode: "onChange",
        defaultValues: {
            payment_type: "credit",
            payment_date: handleFormatDatePickerValue(new Date()),
            remark_codes_payment_form: [{ code: "", amount: "" }],
        },
    });

    const { fields, append, remove } = useFieldArray({
        control,
        name: "remark_codes_payment_form",
    });

    const paymentType = watch("payment_type") || "";
    const paymentMethod = watch("payment_method");
    const selectedTransactionId = watch("transaction_id");

    const { toast } = useToast();
    const queryClient = useQueryClient();

    const remitId = searchParams.get("remit_in_view");

    const getInvoicePayload = {
        invoice_id: invoiceId,
    };

    const noteDetails = useFetchSessionNote(
        clientId || "",
        noteId || invoiceNoteId || "",
        (Boolean(clientId) && Boolean(noteId)) ||
            (Boolean(clientId) && Boolean(invoiceNoteId))
    );

    const { data: paymentData, isLoading: paymentsLoading } =
        useGetInvoicePayment(
            invoiceId as string,
            getInvoicePayload,
            Boolean(invoiceId)
        );

    const paymentsArray =
        paymentData?.payment_breakdown &&
        paymentData.payment_breakdown.filter(
            (payment) =>
                payment.payment_type === "ci" ||
                payment.payment_type === "pr100"
        );

    const paymentInfoForSelect: Options[] = Object.values(
        paymentsArray || []
    ).map((payment) => ({
        value: payment.transaction_id,
        label: (
            <div className="flex items-center gap-x-4 justify-between">
                <Tag
                    title={`Date: ${paymentDateToDisplay(
                        payment.payment_date,
                        payment.payment_channel || ""
                    )}`}
                    textColor="#0B132B"
                    bgColor="#F7FAF5"
                    className="text-xss font-medium py-0 h-[20px]"
                />

                {payment.refundable_amount ? (
                    <Tag
                        title={`Refundable amount: $${payment.refundable_amount}`}
                        textColor="#0B132B"
                        bgColor="#F7FAF5"
                        className="text-xss font-medium py-0 h-[20px]"
                    />
                ) : null}
            </div>
        ),
    }));

    const remitClaims = useFetchRemitClaims(
        {
            claim_id: noteDetails?.data?.data.claim_id || "",
        },
        Boolean(noteDetails?.data?.data.claim_id)
    );

    const paymentTypeToSend = () => {
        let type;
        if (paymentSource === "insurance") {
            switch (paymentType) {
                case "write_off":
                    type = "wbi";
                    break;
                case "refund":
                    type = "ri";
                    break;
                default:
                    type = "i";
            }
        } else if (paymentSource === "co_insurance") {
            switch (paymentType) {
                case "write_off":
                    type = "wbci";
                    break;
                case "refund":
                    type = "rci";
                    break;
                default:
                    type = "ci";
            }
        } else if (paymentSource === "pr100") {
            switch (paymentType) {
                case "refund":
                    type = "rpr100";
                    break;
                default:
                    type = "pr100";
            }
        }

        return type;
    };

    const selectedPayment = transactionId
        ? paymentsArray?.find(
              (payment) => payment.transaction_id === transactionId
          )
        : paymentsArray?.find(
              (payment) => payment.transaction_id === selectedTransactionId
          );

    const claimRemitsForSelect = remitClaims?.data?.data.map((remit) => ({
        value: remit.remittance_id,
        label: `DOS: ${formatDate(remit.date_of_service)} / CN: ${
            remit.claim_number || "--"
        } / PD: ${formatDate(remit.remit_payment_date)}`,
    }));

    const paymentSourcesForSelect: Options[] = Object.values(
        WriteOffSource
    ).map((item) => ({
        value: item,
        label:
            item === WriteOffSource.PR100
                ? "PR100"
                : makeStringFirstLetterCapital(removeEnumUnderscore(item)),
    }));

    const paymentTypeForSelect: Options[] = Object.values(PaymentType).map(
        (item) => ({
            value: item,
            label: makeStringFirstLetterCapital(removeEnumUnderscore(item)),
        })
    );

    const remarkCodeForSelect: Options[] = Object.values(RemarkCodes)
        .map((item) => ({
            value: item, // Use the enum value as the option value
            label: RemarkCodeDescription[
                item as keyof typeof RemarkCodeDescription
            ], // Use this for the description
        }))
        .sort((a, b) => a.value.localeCompare(b.value));

    const writeOffCodesForSelect: Options[] = Object.values(WriteOffCodes).map(
        (item) => ({
            value: item,
            label: WriteOffCodesDecription[item],
        })
    );

    const getWriteOffCodeDescription = (code: string) => {
        const writeOffDescriptions = Object.values(WriteOffCodesDecription);
        const matchingDescription = writeOffDescriptions?.find(
            (description) =>
                code?.toLowerCase() === description.split(":")[0]?.toLowerCase()
        );
        return matchingDescription || "--";
    };

    const paymentMethodForSelect: Options[] = [
        ...Object.values(PaymentMethod).map((item) => ({
            value: item,
            label:
                item === "cheque"
                    ? "Check"
                    : makeStringFirstLetterCapital(removeEnumUnderscore(item)),
        })),
    ];

    const refundReasonForSelect: Options[] = [
        ...Object.values(RefundReason).map((item) => ({
            value: item,
            label: (
                <>{makeStringFirstLetterCapital(removeEnumUnderscore(item))} </>
            ),
        })),
    ];

    const handleFormSubmit = (wbAction?: "close" | "hold") => {
        return (data: IAddPayment) => {
            const payload = {
                ...data,
                payment_amount: parseFloat(data?.payment_amount),
                payment_method: data.payment_method || undefined,
                payment_date: handleFormatDatePickerValue(data.payment_date),
                payment_type: paymentTypeToSend(),
                remittance_id: data.remittance_id || remitId || undefined,
                claim_id: claimId || undefined,
                remark_codes: data.remark_codes || undefined,
                close_invoice: wbAction === "close",
                refund_payment_externally: Boolean(
                    selectedPayment?.refundable_amount &&
                        selectedPayment?.refundable_amount > 0
                ),
                transaction_id: data.transaction_id || transactionId,
                writeoff_code: data.writeoff_code || undefined,
            };

            if (dirtyFields.remark_codes_payment_form) {
                const getPairs = data.remark_codes_payment_form?.map(
                    (pair) => ({
                        code: pair.code,
                        amount: pair.amount ? parseFloat(pair.amount) : null,
                    })
                );

                payload.remark_codes_payment =
                    getPairs && getPairs?.length > 0
                        ? getPairs?.reduce((acc, curr) => {
                              // eslint-disable-next-line no-param-reassign
                              if (curr.code) acc[curr.code] = curr.amount;
                              return acc;
                          }, {} as Record<string, number | null>)
                        : undefined;
            }

            // clear remark_codes_payment_form used in form
            payload.remark_codes_payment_form = undefined;

            addPayment.mutate(payload, {
                onSuccess: (response) => {
                    queryClient.setQueryData<
                        IPaginatedApiResponse<IAddPaymentResponse>
                    >(["get-payment"], (prev) => {
                        const prevRequired =
                            prev as IPaginatedApiResponse<IAddPaymentResponse>;
                        return {
                            ...prevRequired,
                            message: response.message,
                        };
                    });

                    queryClient.invalidateQueries({
                        queryKey: [
                            "invoices",
                            handleRemoveEmptyParamFromQueryParams({
                                client_id: clientId,
                            }),
                        ],
                    });

                    queryClient.invalidateQueries({
                        queryKey: [
                            "invoices",
                            handleRemoveEmptyParamFromQueryParams({
                                invoice_id: invoiceId,
                            }),
                        ],
                    });

                    queryClient.invalidateQueries({
                        queryKey: [
                            "get-payment",
                            handleRemoveEmptyParamFromQueryParams({
                                invoice_id: invoiceId,
                            }),
                        ],
                    });
                    queryClient.invalidateQueries({
                        queryKey: [
                            "remit-claims",
                            handleRemoveEmptyParamFromQueryParams({
                                note_id: noteId,
                            }),
                        ],
                    });

                    toast({
                        mode: "success",
                        message:
                            response.message || "Payment added successfully",
                    });
                    reset({
                        payment_amount: "",
                        payment_notes: "",
                        payment_date: handleFormatDatePickerValue(new Date()),
                        remark_codes: [],
                        writeoff_code: "",
                        auth_code: "",
                        payment_method: "",
                        payment_type: "credit",
                        payment_info: {
                            authorization_number: "",
                            cheque_number: "",
                            refund_reason: "",
                            payor: "",
                        },
                        remark_codes_payment_form: [{ code: "", amount: "" }],
                    });

                    setFormKey((prevKey) => prevKey + 1);
                },

                onError: (err) => {
                    if (
                        err?.response?.data.errors &&
                        err?.response?.data?.errors?.length > 0 &&
                        err?.response?.data?.errors[0].path ===
                            "payment_amount" &&
                        err?.response?.data?.errors[0].message ===
                            "must be >= 0"
                    ) {
                        toast({
                            mode: "error",
                            message: "Amount cannot be less $1",
                        });
                    } else {
                        toast({
                            mode: "error",
                            message:
                                err.response?.data.message ||
                                "Cannot add payment at this time",
                        });
                    }
                },
            });
        };
    };

    return (
        <div className="flex items-start justify-start" key={formKey}>
            <div className="flex items-start gap-x-8 px-12">
                {paymentType === "write_off" ? (
                    <Tooltip content="save and close">
                        <Button
                            size="auto"
                            variant="normal"
                            className="px-4 h-28 flex items-center justify-center"
                            disabled={addPayment.isLoading}
                            onClick={() =>
                                handleSubmit(handleFormSubmit("close"))()
                            }
                        >
                            <CheckCircleIcon />
                        </Button>
                    </Tooltip>
                ) : null}
                <Tooltip
                    content={
                        paymentType === "write_off" ? "save and hold" : "save"
                    }
                >
                    <Button
                        size="auto"
                        variant="normal"
                        className="px-4 h-28 flex items-center justify-center"
                        disabled={addPayment.isLoading}
                        onClick={() => handleSubmit(handleFormSubmit("hold"))()}
                    >
                        <ConfirmPaymentIcon fill={APP_COLORS.COLOR_PRIMARY} />
                    </Button>
                </Tooltip>
            </div>
            <div className="flex items-start gap-x-24 pr-24 justify-normal">
                <div className="w-[120px]">
                    <Controller
                        name="payment_date"
                        control={control}
                        defaultValue={handleFormatDatePickerValue(Date())}
                        render={({ field }) => (
                            <DatePicker
                                onChange={(date) => {
                                    field.onChange(date);
                                    setPaymentDate(date);
                                }}
                                selected={paymentDate}
                                hasError={!!errors.payment_date}
                                errorText={
                                    errors.payment_date?.type === "typeError"
                                        ? "invalid date value"
                                        : errors.payment_date?.message
                                }
                                className="h-28 w-[120px]"
                                popperContainer={({ children }) =>
                                    ReactDOM.createPortal(
                                        children,
                                        document.body
                                    )
                                }
                                errorTextClassName="-mt-4 text-[10px]"
                                popperClassName="!z-[11]"
                                popperProps={{
                                    strategy: "fixed",
                                    modifiers: [
                                        {
                                            name: "zIndex",
                                            options: {
                                                zIndex: 12,
                                            },
                                        },
                                    ],
                                }}
                            />
                        )}
                    />
                </div>
                <Select
                    placeholder="Source"
                    defaultValue={{
                        value: "insurance",
                        label: "Insurance",
                    }}
                    onChange={(val) => {
                        setPaymentSource((val as Options).value as string);
                        setValue("payment_type", "credit");
                    }}
                    options={paymentSourcesForSelect}
                    classNames={{
                        container: () => "w-[120px]",
                        control: () => "!h-28",
                        menu: () => "!w-[120px] !z-[11]",
                        option: () => "!text-[11px]",
                        input: () => "!text-xs !h-20 !p-0 !my-0",
                        menuPortal: () => "!z-[11]",
                    }}
                    isLongListInDialog
                />
                <Controller
                    name="payment_type"
                    control={control}
                    render={({ field }) => (
                        <Select
                            isLongListInDialog
                            placeholder="Payment Type"
                            options={
                                paymentSource === "pr100"
                                    ? paymentTypeForSelect.filter(
                                          (item) => item.value !== "write_off"
                                      )
                                    : paymentTypeForSelect
                            }
                            onChange={(val) =>
                                field.onChange((val as Options).value)
                            }
                            defaultValue={paymentTypeForSelect.find(
                                (item) => item.value === "credit"
                            )}
                            classNames={{
                                container: () => "w-[120px]",
                                control: () => "!h-28",
                                menu: () => "!w-[120px]",
                                option: () => "!text-[11px]",
                                input: () => "!text-xs !h-20 !p-0 !my-0",
                                menuPortal: () => "!z-[11]",
                            }}
                        />
                    )}
                />
                {paymentType === "refund" ? (
                    <>
                        {paymentsLoading && (
                            <Skeleton className="w-[300px] h-28 rounded-r6" />
                        )}
                        {paymentsArray && paymentsArray.length > 0 && (
                            <div>
                                <Controller
                                    name="transaction_id"
                                    control={control}
                                    defaultValue={
                                        paymentInfoForSelect.find(
                                            (payment) =>
                                                payment.value === transactionId
                                        )?.value
                                    }
                                    render={({ field }) => (
                                        <Select
                                            placeholder="Payment"
                                            options={paymentInfoForSelect}
                                            onChange={(val) =>
                                                field.onChange(
                                                    (val as Options).value
                                                )
                                            }
                                            defaultValue={paymentInfoForSelect.find(
                                                (payment) =>
                                                    payment.value ===
                                                    transactionId
                                            )}
                                            isLongListInDialog
                                            classNames={{
                                                container: () => "w-[300px]",
                                                control: () => "!h-28",
                                                menu: () => "!w-[300px]",
                                                option: () => "!text-[11px]",
                                                input: () =>
                                                    "!text-xs !h-20 !p-0 !my-0",
                                                menuPortal: () => "!z-[11]",
                                            }}
                                        />
                                    )}
                                />
                                {selectedPayment?.refundable_amount &&
                                    selectedPayment?.refundable_amount > 0 && (
                                        <div className="font-medium text-[11px] mt-4">
                                            <span className="flex items-center gap-x-4 text-[rgb(0,85,153)]">
                                                <InfoBlueIcon className="size-[12px] shrink-0" />
                                                This refund will be made on
                                                Authorize.net
                                            </span>
                                        </div>
                                    )}
                            </div>
                        )}
                    </>
                ) : null}
                {paymentSource === "insurance" && !remitId ? (
                    <Controller
                        name="remittance_id"
                        control={control}
                        defaultValue={claimRemitsForSelect?.[0]?.value}
                        render={({ field }) => (
                            <Select
                                placeholder="Claim remits"
                                options={claimRemitsForSelect}
                                defaultValue={claimRemitsForSelect?.[0]}
                                onChange={(val) =>
                                    field.onChange((val as Options).value)
                                }
                                isLongListInDialog
                                className="z-[11]"
                                classNames={{
                                    container: () => "w-[400px] !z-[11]",
                                    control: () => "!h-28",
                                    menu: () => "!w-[400px]",
                                    option: () => "!text-[11px]",
                                    input: () => "!text-xs !h-20 !p-0 !my-0",
                                    menuPortal: () => "!z-[11]",
                                }}
                            />
                        )}
                    />
                ) : null}
                {paymentType === "credit" ? (
                    <Controller
                        name="payment_method"
                        control={control}
                        render={({ field }) => (
                            <Select
                                placeholder="Payment method"
                                options={paymentMethodForSelect}
                                onChange={(val) => {
                                    field.onChange((val as Options).value);
                                }}
                                isLongListInDialog
                                classNames={{
                                    container: () => "w-[150px]",
                                    control: () => "!h-28",
                                    menu: () => "!w-[150px]",
                                    option: () => "!text-[11px]",
                                    input: () => "!text-xs !h-20 !p-0 !my-0",
                                    menuPortal: () => "!z-[11]",
                                }}
                            />
                        )}
                    />
                ) : null}
                {paymentType === "credit" && paymentMethod === "cheque" ? (
                    <Input
                        {...register("payment_info.cheque_number")}
                        placeholder="Check number"
                        className="h-28 w-[150px]"
                    />
                ) : null}
                {paymentType === "credit" &&
                (paymentMethod === "card" || paymentMethod === "other") ? (
                    <Input
                        {...register("auth_code")}
                        placeholder="Authorization Code"
                        hasError={!!errors.auth_code}
                        errorText={errors.auth_code?.message}
                        className="h-28 w-[150px]"
                    />
                ) : null}
                <div className="w-[120px]">
                    <Input
                        {...register("payment_amount")}
                        placeholder="Amount"
                        hasError={!!errors.payment_amount}
                        errorText={
                            errors.payment_amount?.type === "typeError"
                                ? "This field is required and must be a number"
                                : "Amount must be greater or equal to 0"
                        }
                        className="h-28 w-[120px]"
                        errorTextClassName="-mt-4 text-[10px]"
                        showCurrency
                    />
                </div>
                {paymentType === "write_off" ? (
                    <Controller
                        name="writeoff_code"
                        control={control}
                        render={({ field }) => (
                            <Select
                                isSearchable
                                placeholder="Write-off code"
                                options={writeOffCodesForSelect}
                                onChange={(val) =>
                                    field.onChange((val as Options).value)
                                }
                                isLongListInDialog
                                formatOptionLabel={(data, meta) => {
                                    return meta.context === "menu"
                                        ? formatRemarkOptionLabel(
                                              data,
                                              meta,
                                              getWriteOffCodeDescription,
                                              "writeoff_code"
                                          )
                                        : truncateString(
                                              (data as Options).label as string,
                                              10
                                          );
                                }}
                                classNames={{
                                    container: () => "w-[150px]",
                                    control: () => "!h-28",
                                    menu: () => "!w-[200px]",
                                    option: () => "!text-[11px]",
                                    input: () => "!text-xs !h-20 !p-0 !my-0",
                                    menuPortal: () => "!z-[11]",
                                }}
                            />
                        )}
                    />
                ) : null}
                {paymentType === "refund" ? (
                    <Controller
                        name="payment_info.refund_reason"
                        control={control}
                        render={({ field }) => (
                            <Select
                                placeholder="Refund reason"
                                isSearchable
                                options={refundReasonForSelect}
                                onChange={(val) =>
                                    field.onChange((val as Options).value)
                                }
                                isLongListInDialog
                                classNames={{
                                    container: () => "w-[150px]",
                                    control: () => "!h-28",
                                    menu: () => "!w-[200px]",
                                    option: () => "!text-[11px]",
                                    input: () => "!text-xs !h-20 !p-0 !my-0",
                                    menuPortal: () => "!z-[11]",
                                }}
                            />
                        )}
                    />
                ) : null}
                {paymentSource === "insurance" ? (
                    <div>
                        {Array.from({ length: fields.length }, (_, i) => (
                            <div
                                key={`${i + 1} key`}
                                className="flex items-start gap-x-8 mb-8"
                            >
                                <Controller
                                    name={`remark_codes_payment_form.${i}.code`}
                                    control={control}
                                    render={({ field }) => (
                                        <Select
                                            placeholder="Remark code"
                                            isSearchable
                                            options={remarkCodeForSelect}
                                            onChange={(val) =>
                                                field.onChange(
                                                    (val as Options).value
                                                )
                                            }
                                            formatOptionLabel={(
                                                selectData: unknown,
                                                formatOptionLabelMeta: FormatOptionLabelMeta<unknown>
                                            ) => {
                                                return formatOptionLabelMeta.context ===
                                                    "menu"
                                                    ? (selectData as Options)
                                                          .label
                                                    : (
                                                          (
                                                              selectData as Options
                                                          ).value as string
                                                      ).toUpperCase();
                                            }}
                                            isLongListInDialog
                                            classNames={{
                                                container: () => "w-[120px]",
                                                control: () => "!h-28",
                                                menu: () => "!w-[200px]",
                                                option: () => "!text-[11px]",
                                                input: () =>
                                                    "!text-xs !h-20 !p-0 !my-0",
                                                menuPortal: () => "!z-[11]",
                                            }}
                                            hasError={
                                                !!errors
                                                    ?.remark_codes_payment_form?.[
                                                    i
                                                ]?.code
                                            }
                                            errorText={
                                                errors
                                                    ?.remark_codes_payment_form?.[
                                                    i
                                                ]?.code?.message
                                            }
                                            errorTextClassName="text-[10px] mt-4 block"
                                        />
                                    )}
                                />
                                <Input
                                    {...register(
                                        `remark_codes_payment_form.${i}.amount`
                                    )}
                                    placeholder="Amount"
                                    hasError={
                                        !!errors?.remark_codes_payment_form?.[i]
                                            ?.amount
                                    }
                                    errorText={
                                        errors?.remark_codes_payment_form?.[i]
                                            ?.amount
                                            ? "Enter valid amount"
                                            : ""
                                    }
                                    className="h-28 w-[100px]"
                                    errorTextClassName="-mt-4 text-[10px]"
                                    showCurrency
                                />
                                <Button
                                    title="Remove pair"
                                    size="auto"
                                    variant="normal"
                                    className={
                                        !!errors?.remark_codes_payment_form?.[i]
                                            ?.code ||
                                        !!errors?.remark_codes_payment_form?.[i]
                                            ?.amount
                                            ? "self-start"
                                            : "self-center"
                                    }
                                    onClick={() => remove(i)}
                                    disabled={fields.length === 1}
                                >
                                    <svg
                                        width="17"
                                        height="17"
                                        viewBox="0 0 17 17"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <g clipPath="url(#clip0_8472_14963)">
                                            <path
                                                d="M12.2502 8.5026H4.25016M14.9168 8.5026C14.9168 12.1845 11.932 15.1693 8.25016 15.1693C4.56826 15.1693 1.5835 12.1845 1.5835 8.5026C1.5835 4.8207 4.56826 1.83594 8.25016 1.83594C11.932 1.83594 14.9168 4.8207 14.9168 8.5026Z"
                                                stroke="#282A2E"
                                                strokeWidth="1.5"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                            />
                                        </g>
                                        <defs>
                                            <clipPath id="clip0_8472_14963">
                                                <rect
                                                    width="16"
                                                    height="16"
                                                    fill="white"
                                                    transform="translate(0.25 0.5)"
                                                />
                                            </clipPath>
                                        </defs>
                                    </svg>
                                </Button>
                            </div>
                        ))}
                        <div className="flex items-center justify-center">
                            <Button
                                size="auto"
                                variant="normal"
                                className="text-xs underline font-medium leading-none"
                                onClick={() =>
                                    append(
                                        { amount: "", code: "" },
                                        {
                                            shouldFocus: false,
                                        }
                                    )
                                }
                            >
                                Add
                            </Button>
                        </div>
                    </div>
                ) : null}

                <TextArea
                    {...register("payment_notes")}
                    placeholder="Payment notes"
                    className="h-[28px] w-[200px] resize-y py-0"
                />
            </div>
        </div>
    );
}
