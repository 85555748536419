import * as React from "react";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
import { useQueryClient } from "@tanstack/react-query";
import { useLocation } from "react-router-dom";
import {
    Input,
    DatePicker,
    Button,
    Popover,
    PopoverTrigger,
    PopoverContent,
    SearchInput,
    Alert,
    Select,
} from "@jhool-io/fe-components";
import { INewTask } from "../../types/tasks.types";
import { useAddTask } from "../../hooks/tasks.mutations";
import useToast from "../../../../hooks/useToast";
import {
    useFetchClientBasicAndPersonalInfo,
    useFetchClientList,
} from "../../../../hooks/queries/client";
import { useDebounce } from "../../../../hooks/helpers";
import styles from "./AddTask.module.scss";
import { useFetchUsersList } from "../../../../hooks/queries/user-management";
import {
    cn,
    removeEnumUnderscore,
    truncateString,
} from "../../../../utils/helpers";
import { AddTextEditor } from "../../../../components/TextEditor/AddTextEditor/AddTextEditor";
import ChevronDownIcon from "../../../../components/Icons/ChevronDown";
import { APP_COLORS } from "../../../../utils/constants";
import ListState from "../../../../components/ListState/ListState";
import Skeleton from "../../../../components/Skeleton/Skeleton";
import { useAppSelector } from "../../../../hooks/useRedux";

interface AddTaskProps {
    // Function to call when form submit button is clicked
    onFormSubmit(): void;
}

type ComboOption = {
    value: string;
    label: string;
};

const schema = yup.object({
    title: yup.string().required("Title is required"),
    description: yup.string(),
    due_date: yup.date().required("Due date is required"),
    client_id: yup.string(),
    assignee_user_id: yup.string(),
});

export default function AddTask({ onFormSubmit }: AddTaskProps) {
    // Local component states
    const [dueDate, setDueDate] = React.useState<Date | null>(null);
    const [clientSearchString, setClientSearchString] = React.useState("");
    const [internalUserSearchString, setInternalUserSearchString] =
        React.useState("");
    const [showAssigneeOptions, setShowAssigneeOptions] = React.useState(false);
    const [selectedClient, setSelectedClient] =
        React.useState<ComboOption | null>(null);
    const [shoudlAllowLessThanTwoHours, setShouldAllowLessThanTwoHours] =
        React.useState<null | "asked" | "allow">(null);
    const [taskDescription, setTaskDescription] = React.useState("");
    const [showClientListPopover, setShowClientListPopover] =
        React.useState(false);

    const usersListContainerRef = React.useRef<HTMLDivElement>(null);
    const clientsListContainerRef = React.useRef<HTMLDivElement>(null);

    const { practice } = useAppSelector((state) => state.userPractice);

    const debouncedClientSearchValue = useDebounce(clientSearchString, 500);

    const debouncedUserSearchString = useDebounce(
        internalUserSearchString,
        500
    );

    const location = useLocation();

    const urlPathname = location.pathname.match(/\/clients\/([a-z0-9-]+)/i);

    const clientId = urlPathname && urlPathname[1] ? urlPathname[1] : null;

    const clientDetails = useFetchClientBasicAndPersonalInfo(
        clientId as string,
        Boolean(clientId)
    );

    const clientFromUrl = {
        value: clientId || "",
        label:
            clientDetails.data &&
            `${clientDetails.data.data.first_name} ${clientDetails.data.data.last_name}`,
    };

    const selectedValue = selectedClient?.label || clientFromUrl?.label || "";

    // Fetch clients
    const clientsList = useFetchClientList({
        page: 1,
        search_string: debouncedClientSearchValue.toLowerCase(),
        provider_id: practice?.provider_id || "",
        limit: 20,
    });

    // Fetch providers
    const internalUsersList = useFetchUsersList(
        {
            search_string: debouncedUserSearchString.toLowerCase(),
        },
        showAssigneeOptions && internalUserSearchString.length > 0
    );

    const InternalUsersForSelect = internalUsersList.data?.data.map((user) => ({
        label: `${user.first_name} ${user.last_name}`,
        value: user.user_id,
    }));

    // Get filtered options for users list
    const filteredUsers = InternalUsersForSelect?.filter((option) =>
        option.label
            .toLowerCase()
            .includes(internalUserSearchString.toLowerCase())
    );

    // React hook form values
    const {
        control,
        handleSubmit,
        register,
        formState: { errors },
        watch,
    } = useForm<INewTask>({
        resolver: yupResolver(schema),
        mode: "onChange",
    });

    const assigneeIds = watch("assignee_user_ids");

    const { mutate } = useAddTask();

    // Query client
    const queryClient = useQueryClient();

    const { toast } = useToast();

    const onSubmit = (data: INewTask) => {
        const dataToSend = {
            ...data,
            client_id: clientId ? clientFromUrl.value : selectedClient?.value,
            description: taskDescription as string,
            due_date: data.due_date,
        };

        mutate(dataToSend, {
            onSuccess: (res) => {
                toast({
                    mode: "success",
                    message: res.message || "Task added successfully",
                });
                setShouldAllowLessThanTwoHours(null);

                queryClient.invalidateQueries({
                    queryKey: ["todos"],
                });
                onFormSubmit();
            },
            onError: (err) => {
                toast({
                    mode: "error",
                    message:
                        err.response?.data.message ||
                        "Could not add task at this time",
                });
            },
        });
    };

    return (
        <form id="add-todo" name="add-todo" onSubmit={handleSubmit(onSubmit)}>
            <div className="fg">
                <Input
                    {...register("title")}
                    label="Task title"
                    placeholder="Task title"
                    hasError={!!errors.title}
                    errorText={errors.title?.message}
                    isRequired
                />
            </div>
            <div className="fg">
                <AddTextEditor
                    title="Add description"
                    titleClass={styles.descriptionLabel}
                    onEditorTextChange={setTaskDescription}
                    isRequiredFieldProvided
                />
            </div>
            <div className="fg">
                <Controller
                    name="due_date"
                    control={control}
                    render={({ field }) => (
                        <DatePicker
                            label="Due date"
                            selected={dueDate}
                            minDate={new Date(Date.now())}
                            hasError={!!errors.due_date}
                            onChange={(date) => {
                                field.onChange(date);
                                setDueDate(date);
                            }}
                            errorText={
                                errors.due_date?.type === "typeError"
                                    ? "invalid date value"
                                    : errors.due_date?.message
                            }
                            shouldCloseOnSelect={false}
                            popperPlacement="top-end"
                            placeholderText="MM/DD/YYYY"
                            isRequired
                        />
                    )}
                />
            </div>

            <div className="fg" ref={usersListContainerRef}>
                <Controller
                    name="assignee_user_ids"
                    control={control}
                    render={({ field }) => (
                        <Select
                            label="Assignees"
                            placeholder="Assignees(Type multiple)"
                            isMulti
                            isSearchable
                            filterOption={() => true}
                            onChange={(val) => {
                                field.onChange(
                                    (
                                        val as {
                                            label: string;
                                            value: string;
                                        }[]
                                    ).map((code) => code.value)
                                );
                            }}
                            onInputChange={(value) => {
                                setInternalUserSearchString(value);
                                setShowAssigneeOptions(true);
                            }}
                            onBlur={() => {
                                setShowAssigneeOptions(false);
                            }}
                            hasError={!!errors.assignee_user_ids}
                            errorText={errors.assignee_user_ids?.message}
                            options={filteredUsers}
                            noOptionsMessage={() =>
                                internalUsersList.isLoading
                                    ? "Loading..."
                                    : "No options"
                            }
                            multiHasValues={
                                assigneeIds && assigneeIds.length > 0
                            }
                            isLongListInDialog
                            menuIsOpen={internalUserSearchString.length > 0}
                            openMenuOnClick={false}
                        />
                    )}
                />
            </div>

            <div className="fg" ref={clientsListContainerRef}>
                <Popover
                    open={showClientListPopover}
                    onOpenChange={setShowClientListPopover}
                >
                    <PopoverTrigger
                        asChild
                        onClick={() => setShowClientListPopover(true)}
                        className="w-full"
                    >
                        <Button
                            size="auto"
                            variant="normal"
                            className={cn(
                                "px-16 h-40 shadow-active gap-x-8 text-xs data-[state=open]:border-primary font-medium flex justify-between",
                                {
                                    capitalize: selectedValue,
                                }
                            )}
                        >
                            {selectedValue
                                ? truncateString(selectedValue, 18)
                                : "Select a client"}
                            <ChevronDownIcon stroke={APP_COLORS.COLOR_BLACK} />
                        </Button>
                    </PopoverTrigger>

                    <PopoverContent
                        className="px-4 pt-[3px] py-[3px] w-[316px] rounded-r8 border-none shadow-[0px_0px_0px_1px_#98a1b219,_0px_15px_35px_-5px_#11182626,_0px_5px_15px_0px_#00000014] z-[40] popover-full"
                        align="start"
                        container={clientsListContainerRef.current}
                    >
                        <SearchInput
                            placeholder="Search by client name"
                            containerClass="h-24"
                            onChange={(e) =>
                                setClientSearchString(e.target.value)
                            }
                        />

                        <div className="flex flex-col items-start max-h-[230px] overflow-y-auto">
                            {clientsList.isLoading && (
                                <div className="flex flex-col gap-y-4 items-stretch w-full">
                                    {[1, 2, 3, 4, 5].map((item) => (
                                        <Skeleton
                                            key={item}
                                            width="100%"
                                            height="24px"
                                            containerTestId="client-loader"
                                        />
                                    ))}
                                </div>
                            )}

                            {clientsList.error && (
                                <ListState
                                    isError
                                    context="general"
                                    stateHelperText="Please try again later"
                                    errorMsg={
                                        clientsList.error?.response?.data
                                            ?.message || "Error loading clients"
                                    }
                                />
                            )}

                            {clientsList.data?.data && clientsList.isSuccess && (
                                <>
                                    <Button
                                        size="auto"
                                        variant="normal"
                                        className="text-xs px-[6px] py-4 font-medium capitalize w-full justify-start !rounded-none"
                                        onClick={() => {
                                            setClientSearchString("");
                                            setSelectedClient(null);
                                            setShowClientListPopover(false);
                                        }}
                                    >
                                        All
                                    </Button>
                                    {clientsList?.data?.data.map((client) => (
                                        <Button
                                            key={client.client_id}
                                            size="auto"
                                            variant="normal"
                                            className="text-xs px-[6px] py-4 font-medium capitalize w-full justify-start !rounded-none"
                                            onClick={() => {
                                                setSelectedClient({
                                                    label: removeEnumUnderscore(
                                                        `${client.first_name} ${client.last_name}`
                                                    ),
                                                    value: client.client_id,
                                                });
                                                setShowClientListPopover(false);
                                            }}
                                        >
                                            {`${client.first_name} ${client.last_name}`}
                                        </Button>
                                    ))}
                                </>
                            )}
                        </div>
                    </PopoverContent>
                </Popover>
            </div>

            {shoudlAllowLessThanTwoHours === "asked" ? (
                <div className="flex flex-col md:flex-row items-center gap-16 mt-24">
                    <Alert
                        type="warning"
                        description="This task is due in less than two hours, and you won't get a reminder. Are you sure you want to continue?"
                    />

                    <Button
                        variant="primary"
                        onClick={() => setShouldAllowLessThanTwoHours("allow")}
                    >
                        Continue
                    </Button>
                </div>
            ) : null}
        </form>
    );
}
