export default function SplitPaymentIcon() {
    return (
        <svg
            width="14"
            height="14"
            viewBox="0 0 14 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M7.00188 13.8324C10.7319 13.8324 13.8322 10.7387 13.8322 7.00216C13.8322 3.27216 10.725 0.171875 6.99502 0.171875C3.25873 0.171875 0.171875 3.27216 0.171875 7.00216C0.171875 10.7387 3.2653 13.8324 7.00188 13.8324ZM7.00216 12.6942C3.84102 12.6942 1.31616 10.1627 1.31616 7.00216C1.31616 3.84787 3.83416 1.31016 6.99502 1.31016C10.149 1.31016 12.6867 3.84816 12.6936 7.00216C12.7002 10.163 10.1556 12.6942 7.00159 12.6942M6.98159 5.34159C7.46359 5.34159 7.80502 5.02016 7.80502 4.60473C7.80502 4.1293 7.47016 3.8013 6.98159 3.8013C6.53302 3.8013 6.18445 4.1293 6.18445 4.60473C6.18445 5.01987 6.53302 5.34159 6.98159 5.34159ZM4.39016 7.57102H9.60645C9.98159 7.57102 10.2293 7.37673 10.2293 7.02187C10.2293 6.65359 9.99502 6.45273 9.60645 6.45273H4.39045C3.9953 6.45273 3.75445 6.65359 3.75445 7.02187C3.75445 7.37673 4.00873 7.57102 4.39045 7.57102M6.98188 10.2296C7.46388 10.2296 7.8053 9.9013 7.8053 9.48616C7.8053 9.01073 7.47045 8.6893 6.98188 8.6893C6.5333 8.6893 6.18473 9.01073 6.18473 9.48645C6.18473 9.9013 6.5333 10.2293 6.98188 10.2293"
                fill="#0B132B"
            />
        </svg>
    );
}
