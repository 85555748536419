import { useMutation } from "@tanstack/react-query";
import { AxiosError } from "axios";

import { IApiResponse } from "../../../utils/types/api-response";
import { INewTask, ITask, IUpdateTaskPayload } from "../types/tasks.types";
import {
    addTask,
    cancelSession,
    deleteTasks,
    updateTask,
} from "../services/tasks.services";

export function useAddTask() {
    return useMutation<
        IApiResponse<IApiResponse<ITask>>,
        AxiosError<IApiResponse<ITask>>,
        INewTask
    >({
        mutationKey: ["add-todo"],
        mutationFn: (data: INewTask) => addTask(data),
    });
}

export function useUpdateTask() {
    return useMutation<
        IApiResponse<ITask>,
        AxiosError<IApiResponse<ITask>>,
        IUpdateTaskPayload
    >({
        mutationKey: ["update-todo"],
        mutationFn: (data: IUpdateTaskPayload) => updateTask(data),
    });
}

export function useCancelSession() {
    interface ISessionData {
        session_history_ids: string[];
        reason: string;
    }

    interface ICancelResponse {
        cancelled_session_history_ids: string[];
        failed_session_history_ids: string[];
    }

    return useMutation<
        IApiResponse<ICancelResponse>,
        AxiosError<ICancelResponse>,
        ISessionData
    >({
        mutationKey: ["cancel-session"],
        mutationFn: (data: ISessionData) => cancelSession(data),
    });
}

export function useDeleteTasks() {
    interface ApiResponse {
        message: string;
    }

    return useMutation<ApiResponse, AxiosError<ApiResponse>, string[]>({
        mutationKey: ["delete-tasks"],
        mutationFn: (taskIds: string[]) => deleteTasks(taskIds),
    });
}
