import * as React from "react";
import cn from "classnames";
import { useGetDiagnosisCodesDisplay } from "../../../../hooks/helpers";
import styles from "../../../../modules/notes/components/NoteContent/NoteContent.module.scss";
import CheckIcon from "../../../Icons/Check";
import { formatDate, removeEnumUnderscore } from "../../../../utils/helpers";
import {
    FamilyIntakeNotePayload,
    IAddendumDetails,
} from "../../../../utils/types/notes";
import {
    FAMILY_INTAKE_FAMILY_NOTE_DETAILS,
    FAMILY_INTAKE_NOTE_DETAILS,
    INTAKE_SECTIONS,
} from "../../../../utils/constants";
import NoteContentItem from "../NoteContentItem/NoteContentItem";
import { AppointmentLocationTeletherapy } from "../../../../utils/types/session";
import useGetDiagnosisCodeToDisplay from "../../../../modules/notes/hooks";

interface ViewFamilyIntakeNoteProps {
    noteContent: {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        [key: string]: any;
    };
    fieldAddendums: {
        [key: string]: IAddendumDetails[];
    } | null;
}

export default function ViewFamilyIntakeNote({
    noteContent,
    fieldAddendums,
}: ViewFamilyIntakeNoteProps) {
    const diagonosisCodes = useGetDiagnosisCodesDisplay(
        noteContent.diagnosis_codes
    );

    const isCheckboxChecked = (key: string[], checkboxValue: string) => {
        return Boolean(
            key?.find((value) => value.trim() === checkboxValue?.trim())
        );
    };

    return (
        <>
            <p className={styles.sectionheader}>{`Family Count - ${
                noteContent?.family?.length || 0
            }`}</p>
            <NoteContentItem
                headerText={FAMILY_INTAKE_NOTE_DETAILS.appointment_location}
                fieldId="appointment_location"
                addendums={fieldAddendums?.appointment_location}
                content={
                    removeEnumUnderscore(noteContent.appointment_location) ||
                    "--"
                }
            />
            <NoteContentItem
                headerText={FAMILY_INTAKE_NOTE_DETAILS.delivery_option}
                fieldId="delivery_option"
                addendums={fieldAddendums?.delivery_option}
                content={noteContent.delivery_option || "--"}
            />
            {noteContent.other_delivery_option &&
            noteContent.delivery_option ===
                AppointmentLocationTeletherapy.OTHER ? (
                <NoteContentItem
                    headerText={
                        FAMILY_INTAKE_NOTE_DETAILS.other_delivery_option
                    }
                    fieldId="other_delivery_option"
                    addendums={fieldAddendums?.other_delivery_option}
                    content={noteContent.other_delivery_option || "--"}
                />
            ) : null}
            <NoteContentItem
                headerText={FAMILY_INTAKE_NOTE_DETAILS.provider_location}
                fieldId="provider_location"
                addendums={fieldAddendums?.provider_location}
                content={noteContent.provider_location || "--"}
            />
            {noteContent.other_provider_location &&
            noteContent.provider_location ===
                AppointmentLocationTeletherapy.OTHER ? (
                <NoteContentItem
                    headerText={
                        FAMILY_INTAKE_NOTE_DETAILS.other_provider_location
                    }
                    fieldId="other_provider_location"
                    addendums={fieldAddendums?.other_provider_location}
                    content={noteContent.other_provider_location || "--"}
                />
            ) : null}
            <NoteContentItem
                headerText={
                    FAMILY_INTAKE_NOTE_DETAILS.consent_was_received_for_the_teletherapy_session
                }
                fieldId="consent_was_received_for_the_teletherapy_session"
                addendums={
                    fieldAddendums?.consent_was_received_for_the_teletherapy_session
                }
                content={
                    <div className={styles.checkbox}>
                        <span
                            className={cn(styles.checkbox_wrap, {
                                [styles.checked]:
                                    noteContent.consent_was_received_for_the_teletherapy_session,
                            })}
                        >
                            <CheckIcon />
                        </span>
                        {
                            FAMILY_INTAKE_NOTE_DETAILS.consent_was_received_for_the_teletherapy_session
                        }
                    </div>
                }
            />
            <NoteContentItem
                headerText={
                    FAMILY_INTAKE_NOTE_DETAILS.the_teletherapy_session_was_conducted_via_video
                }
                fieldId="the_teletherapy_session_was_conducted_via_video"
                addendums={
                    fieldAddendums?.the_teletherapy_session_was_conducted_via_video
                }
                content={
                    <div className={styles.checkbox}>
                        <span
                            className={cn(styles.checkbox_wrap, {
                                [styles.checked]:
                                    noteContent.the_teletherapy_session_was_conducted_via_video,
                            })}
                        >
                            <CheckIcon />
                        </span>
                        {
                            FAMILY_INTAKE_NOTE_DETAILS.the_teletherapy_session_was_conducted_via_video
                        }
                    </div>
                }
            />
            <NoteContentItem
                headerText={FAMILY_INTAKE_NOTE_DETAILS.diagnosis_codes}
                fieldId="diagnosis_codes"
                addendums={fieldAddendums?.diagnosis_codes}
                content={
                    diagonosisCodes && Array.isArray(diagonosisCodes) ? (
                        <div className={styles.checkboxes}>
                            {diagonosisCodes.map((code) => (
                                <div className={styles.checkbox} key={code}>
                                    <span
                                        className={cn(styles.checkbox_wrap, {
                                            [styles.checked]: true,
                                        })}
                                    >
                                        <CheckIcon />
                                    </span>
                                    {code}
                                </div>
                            ))}
                        </div>
                    ) : (
                        "--"
                    )
                }
            />
            <NoteContentItem
                headerText={FAMILY_INTAKE_NOTE_DETAILS.secondary_diagnosis}
                fieldId="secondary_diagnosis"
                addendums={fieldAddendums?.secondary_diagnosis}
                content={useGetDiagnosisCodeToDisplay(
                    noteContent.secondary_diagnosis
                )}
            />
            <NoteContentItem
                headerText={FAMILY_INTAKE_NOTE_DETAILS.tertiary_diagnosis}
                fieldId="tertiary_diagnosis"
                addendums={fieldAddendums?.tertiary_diagnosis}
                content={useGetDiagnosisCodeToDisplay(
                    noteContent.tertiary_diagnosis
                )}
            />
            <p className={styles.sectionheader} id={INTAKE_SECTIONS.identity}>
                Identities
            </p>
            {noteContent.family &&
                noteContent.family.map(
                    (family: FamilyIntakeNotePayload, i: number) => (
                        <React.Fragment key={`${i + 1}`}>
                            <NoteContentItem
                                headerText={
                                    FAMILY_INTAKE_FAMILY_NOTE_DETAILS.name
                                }
                                fieldId={`name-${noteContent.family[i].family_id}`}
                                addendums={
                                    fieldAddendums?.[
                                        `name-${noteContent.family[i].family_id}`
                                    ]
                                }
                                content={family.name || "--"}
                            />
                            <NoteContentItem
                                headerText={
                                    FAMILY_INTAKE_FAMILY_NOTE_DETAILS.preferred_name
                                }
                                fieldId={`preferred_name-${noteContent.family[i].family_id}`}
                                addendums={
                                    fieldAddendums?.[
                                        `preferred_name-${noteContent.family[i].family_id}`
                                    ]
                                }
                                content={family.preferred_name || "--"}
                            />
                            <NoteContentItem
                                headerText={
                                    FAMILY_INTAKE_FAMILY_NOTE_DETAILS.gender
                                }
                                fieldId={`gender-${noteContent.family[i].family_id}`}
                                addendums={
                                    fieldAddendums?.[
                                        `gender-${noteContent.family[i].family_id}`
                                    ]
                                }
                                content={family.gender || "--"}
                            />
                            <NoteContentItem
                                headerText={
                                    FAMILY_INTAKE_FAMILY_NOTE_DETAILS.other_gender
                                }
                                fieldId={`other_gender-${noteContent.family[i].family_id}`}
                                addendums={
                                    fieldAddendums?.[
                                        `other_gender-${noteContent.family[i].family_id}`
                                    ]
                                }
                                content={family.other_gender || "--"}
                            />
                            <NoteContentItem
                                headerText={
                                    FAMILY_INTAKE_FAMILY_NOTE_DETAILS.pronouns
                                }
                                fieldId={`pronouns-${noteContent.family[i].family_id}`}
                                addendums={
                                    fieldAddendums?.[
                                        `pronouns-${noteContent.family[i].family_id}`
                                    ]
                                }
                                content={family.pronouns || "--"}
                            />
                            <NoteContentItem
                                headerText={
                                    FAMILY_INTAKE_FAMILY_NOTE_DETAILS.religion
                                }
                                fieldId={`religion-${noteContent.family[i].family_id}`}
                                addendums={
                                    fieldAddendums?.[
                                        `religion-${noteContent.family[i].family_id}`
                                    ]
                                }
                                content={family.religion || "--"}
                            />
                            <NoteContentItem
                                headerText={
                                    FAMILY_INTAKE_FAMILY_NOTE_DETAILS.education
                                }
                                fieldId={`education-${noteContent.family[i].family_id}`}
                                addendums={
                                    fieldAddendums?.[
                                        `education-${noteContent.family[i].family_id}`
                                    ]
                                }
                                content={family.education || "--"}
                            />
                            <NoteContentItem
                                headerText={
                                    FAMILY_INTAKE_FAMILY_NOTE_DETAILS.race
                                }
                                fieldId={`race-${noteContent.family[i].family_id}`}
                                addendums={
                                    fieldAddendums?.[
                                        `race-${noteContent.family[i].family_id}`
                                    ]
                                }
                                content={
                                    removeEnumUnderscore(family.race) || "--"
                                }
                            />
                            <NoteContentItem
                                headerText={
                                    FAMILY_INTAKE_FAMILY_NOTE_DETAILS.ethinicity
                                }
                                fieldId={`ethinicity-${noteContent.family[i].family_id}`}
                                addendums={
                                    fieldAddendums?.[
                                        `ethinicity-${noteContent.family[i].family_id}`
                                    ]
                                }
                                content={family.ethinicity || "--"}
                            />
                            <NoteContentItem
                                headerText={
                                    FAMILY_INTAKE_FAMILY_NOTE_DETAILS.sexual_orientation
                                }
                                fieldId={`sexual_orientation-${noteContent.family[i].family_id}`}
                                addendums={
                                    fieldAddendums?.[
                                        `sexual_orientation-${noteContent.family[i].family_id}`
                                    ]
                                }
                                content={family.sexual_orientation || "--"}
                            />
                            <NoteContentItem
                                headerText={
                                    FAMILY_INTAKE_FAMILY_NOTE_DETAILS.other_sexual_orientation
                                }
                                fieldId={`other_sexual_orientation-${noteContent.family[i].family_id}`}
                                addendums={
                                    fieldAddendums?.[
                                        `other_sexual_orientation-${noteContent.family[i].family_id}`
                                    ]
                                }
                                content={
                                    family.other_sexual_orientation || "--"
                                }
                            />
                            <NoteContentItem
                                headerText={
                                    FAMILY_INTAKE_FAMILY_NOTE_DETAILS.current_employment
                                }
                                fieldId={`current_employment-${noteContent.family[i].family_id}`}
                                addendums={
                                    fieldAddendums?.[
                                        `current_employment-${noteContent.family[i].family_id}`
                                    ]
                                }
                                content={family.current_employment || "--"}
                            />
                            <NoteContentItem
                                headerText={
                                    FAMILY_INTAKE_FAMILY_NOTE_DETAILS.current_employment_details
                                }
                                fieldId={`current_employment_details-${noteContent.family[i].family_id}`}
                                addendums={
                                    fieldAddendums?.[
                                        `current_employment_details-${noteContent.family[i].family_id}`
                                    ]
                                }
                                content={
                                    family.current_employment_details || "--"
                                }
                            />
                            <NoteContentItem
                                headerText={
                                    FAMILY_INTAKE_FAMILY_NOTE_DETAILS.relationship_status
                                }
                                fieldId={`relationship_status-${noteContent.family[i].family_id}`}
                                addendums={
                                    fieldAddendums?.[
                                        `relationship_status-${noteContent.family[i].family_id}`
                                    ]
                                }
                                content={family.relationship_status || "--"}
                            />
                            <NoteContentItem
                                headerText={
                                    FAMILY_INTAKE_FAMILY_NOTE_DETAILS.name_of_partner
                                }
                                fieldId={`name_of_partner-${noteContent.family[i].family_id}`}
                                addendums={
                                    fieldAddendums?.[
                                        `name_of_partner-${noteContent.family[i].family_id}`
                                    ]
                                }
                                content={family.name_of_partner || "--"}
                            />
                            <NoteContentItem
                                headerText={
                                    FAMILY_INTAKE_FAMILY_NOTE_DETAILS.emergency_contact_information
                                }
                                fieldId={`emergency_contact_information-${noteContent.family[i].family_id}`}
                                addendums={
                                    fieldAddendums?.[
                                        `emergency_contact_information-${noteContent.family[i].family_id}`
                                    ]
                                }
                                content={
                                    family.emergency_contact_information || "--"
                                }
                            />
                            <NoteContentItem
                                headerText={
                                    FAMILY_INTAKE_FAMILY_NOTE_DETAILS.legal_problems
                                }
                                fieldId={`legal_problems-${noteContent.family[i].family_id}`}
                                addendums={
                                    fieldAddendums?.[
                                        `legal_problems-${noteContent.family[i].family_id}`
                                    ]
                                }
                                content={family.legal_problems || "--"}
                            />
                        </React.Fragment>
                    )
                )}
            <p
                className={styles.sectionheader}
                id={INTAKE_SECTIONS["presenting concerns"]}
            >
                Presenting Concerns
            </p>
            <NoteContentItem
                headerText={
                    FAMILY_INTAKE_NOTE_DETAILS.what_are_the_problem_you_are_seeking_help_for
                }
                fieldId="what_are_the_problem_you_are_seeking_help_for"
                addendums={
                    fieldAddendums?.what_are_the_problem_you_are_seeking_help_for
                }
                content={
                    noteContent.what_are_the_problem_you_are_seeking_help_for ||
                    "--"
                }
            />
            <p className={styles.sectionheader} id={INTAKE_SECTIONS.symptoms}>
                Symptoms
            </p>
            {noteContent.family &&
                noteContent.family.map(
                    (family: FamilyIntakeNotePayload, i: number) => (
                        <React.Fragment key={`${i + 1}`}>
                            <p className={styles.sectionheader}>
                                Client {i + 1}{" "}
                                {family.name ? `- ${family.name}` : ""}
                            </p>
                            <NoteContentItem
                                headerText={
                                    FAMILY_INTAKE_FAMILY_NOTE_DETAILS.anxiety
                                }
                                fieldId={`anxiety-${noteContent.family[i].family_id}`}
                                addendums={
                                    fieldAddendums?.[
                                        `anxiety-${noteContent.family[i].family_id}`
                                    ]
                                }
                                content={
                                    <div className={styles.items}>
                                        <div className={styles.checkbox}>
                                            <span
                                                className={cn(
                                                    styles.checkbox_wrap,
                                                    {
                                                        [styles.checked]:
                                                            family.anxiety,
                                                    }
                                                )}
                                            >
                                                <CheckIcon />
                                            </span>
                                            {
                                                FAMILY_INTAKE_FAMILY_NOTE_DETAILS.anxiety
                                            }
                                        </div>
                                    </div>
                                }
                            />
                            <NoteContentItem
                                headerText={
                                    FAMILY_INTAKE_FAMILY_NOTE_DETAILS.anxiety_frequency
                                }
                                fieldId={`anxiety_frequency-${noteContent.family[i].family_id}`}
                                addendums={
                                    fieldAddendums?.[
                                        `anxiety_frequency-${noteContent.family[i].family_id}`
                                    ]
                                }
                                content={family.anxiety_frequency || "--"}
                            />
                            <NoteContentItem
                                headerText={
                                    FAMILY_INTAKE_FAMILY_NOTE_DETAILS.anxiety_details
                                }
                                fieldId={`anxiety_details-${noteContent.family[i].family_id}`}
                                addendums={
                                    fieldAddendums?.[
                                        `anxiety_details-${noteContent.family[i].family_id}`
                                    ]
                                }
                                content={family.anxiety_details || "--"}
                            />

                            <NoteContentItem
                                headerText={
                                    FAMILY_INTAKE_FAMILY_NOTE_DETAILS.panic_attacks
                                }
                                fieldId={`panic_attacks-${noteContent.family[i].family_id}`}
                                addendums={
                                    fieldAddendums?.[
                                        `panic_attacks-${noteContent.family[i].family_id}`
                                    ]
                                }
                                content={
                                    <div className={styles.items}>
                                        <div className={styles.checkbox}>
                                            <span
                                                className={cn(
                                                    styles.checkbox_wrap,
                                                    {
                                                        [styles.checked]:
                                                            family.panic_attacks,
                                                    }
                                                )}
                                            >
                                                <CheckIcon />
                                            </span>
                                            {
                                                FAMILY_INTAKE_FAMILY_NOTE_DETAILS.panic_attacks
                                            }
                                        </div>
                                    </div>
                                }
                            />
                            <NoteContentItem
                                headerText={
                                    FAMILY_INTAKE_FAMILY_NOTE_DETAILS.panic_attacks_freqency
                                }
                                fieldId={`panic_attacks_freqency-${noteContent.family[i].family_id}`}
                                addendums={
                                    fieldAddendums?.[
                                        `panic_attacks_freqency-${noteContent.family[i].family_id}`
                                    ]
                                }
                                content={family.panic_attacks_freqency || "--"}
                            />
                            <NoteContentItem
                                headerText={
                                    FAMILY_INTAKE_FAMILY_NOTE_DETAILS.panic_attacks_details
                                }
                                fieldId={`panic_attacks_details-${noteContent.family[i].family_id}`}
                                addendums={
                                    fieldAddendums?.[
                                        `panic_attacks_details-${noteContent.family[i].family_id}`
                                    ]
                                }
                                content={family.panic_attacks_details || "--"}
                            />
                            <NoteContentItem
                                headerText={
                                    FAMILY_INTAKE_FAMILY_NOTE_DETAILS.depression
                                }
                                fieldId={`depression-${noteContent.family[i].family_id}`}
                                addendums={
                                    fieldAddendums?.[
                                        `depression-${noteContent.family[i].family_id}`
                                    ]
                                }
                                content={
                                    <div className={styles.items}>
                                        <div className={styles.checkbox}>
                                            <span
                                                className={cn(
                                                    styles.checkbox_wrap,
                                                    {
                                                        [styles.checked]:
                                                            family.depression,
                                                    }
                                                )}
                                            >
                                                <CheckIcon />
                                            </span>
                                            {
                                                FAMILY_INTAKE_FAMILY_NOTE_DETAILS.depression
                                            }
                                        </div>
                                    </div>
                                }
                            />
                            <NoteContentItem
                                headerText={
                                    FAMILY_INTAKE_FAMILY_NOTE_DETAILS.depression_freqency
                                }
                                fieldId={`depression_freqency-${noteContent.family[i].family_id}`}
                                addendums={
                                    fieldAddendums?.[
                                        `depression_freqency-${noteContent.family[i].family_id}`
                                    ]
                                }
                                content={family.depression_freqency || "--"}
                            />
                            <NoteContentItem
                                headerText={
                                    FAMILY_INTAKE_FAMILY_NOTE_DETAILS.depression_details
                                }
                                fieldId={`depression_details-${noteContent.family[i].family_id}`}
                                addendums={
                                    fieldAddendums?.[
                                        `depression_details-${noteContent.family[i].family_id}`
                                    ]
                                }
                                content={family.depression_details || "--"}
                            />
                            <NoteContentItem
                                headerText={
                                    FAMILY_INTAKE_FAMILY_NOTE_DETAILS.selected_symptoms
                                }
                                fieldId={`selected_symptoms-${noteContent.family[i].family_id}`}
                                addendums={
                                    fieldAddendums?.[
                                        `selected_symptoms-${noteContent.family[i].family_id}`
                                    ]
                                }
                                content={
                                    family?.selected_symptoms?.length > 0 ? (
                                        <div className={styles.checkboxes}>
                                            {isCheckboxChecked(
                                                family?.selected_symptoms,
                                                "Easily distracted"
                                            ) && (
                                                <div
                                                    className={styles.checkbox}
                                                >
                                                    <span
                                                        className={cn(
                                                            styles.checkbox_wrap,
                                                            {
                                                                [styles.checked]:
                                                                    isCheckboxChecked(
                                                                        family?.selected_symptoms,
                                                                        "Easily distracted"
                                                                    ),
                                                            }
                                                        )}
                                                    >
                                                        <CheckIcon />
                                                    </span>
                                                    Easily distracted
                                                </div>
                                            )}
                                            {isCheckboxChecked(
                                                family?.selected_symptoms,
                                                "Impulsive"
                                            ) && (
                                                <div
                                                    className={styles.checkbox}
                                                >
                                                    <span
                                                        className={cn(
                                                            styles.checkbox_wrap,
                                                            {
                                                                [styles.checked]:
                                                                    isCheckboxChecked(
                                                                        family?.selected_symptoms,
                                                                        "Impulsive"
                                                                    ),
                                                            }
                                                        )}
                                                    >
                                                        <CheckIcon />
                                                    </span>
                                                    Impulsive
                                                </div>
                                            )}
                                            {isCheckboxChecked(
                                                family.selected_symptoms,
                                                "Paranoia"
                                            ) && (
                                                <div
                                                    className={styles.checkbox}
                                                >
                                                    <span
                                                        className={cn(
                                                            styles.checkbox_wrap,
                                                            {
                                                                [styles.checked]:
                                                                    isCheckboxChecked(
                                                                        family.selected_symptoms,
                                                                        "Paranoia"
                                                                    ),
                                                            }
                                                        )}
                                                    >
                                                        <CheckIcon />
                                                    </span>
                                                    Paranoia
                                                </div>
                                            )}
                                            {isCheckboxChecked(
                                                family.selected_symptoms,
                                                "Alcohol"
                                            ) && (
                                                <div
                                                    className={styles.checkbox}
                                                >
                                                    <span
                                                        className={cn(
                                                            styles.checkbox_wrap,
                                                            {
                                                                [styles.checked]:
                                                                    isCheckboxChecked(
                                                                        family.selected_symptoms,
                                                                        "Alcohol"
                                                                    ),
                                                            }
                                                        )}
                                                    >
                                                        <CheckIcon />
                                                    </span>
                                                    Alcohol
                                                </div>
                                            )}
                                            {isCheckboxChecked(
                                                family.selected_symptoms,
                                                "Anger outburst"
                                            ) && (
                                                <div
                                                    className={styles.checkbox}
                                                >
                                                    <span
                                                        className={cn(
                                                            styles.checkbox_wrap,
                                                            {
                                                                [styles.checked]:
                                                                    isCheckboxChecked(
                                                                        family.selected_symptoms,
                                                                        "Anger outburst"
                                                                    ),
                                                            }
                                                        )}
                                                    >
                                                        <CheckIcon />
                                                    </span>
                                                    Anger outburst
                                                </div>
                                            )}
                                            {isCheckboxChecked(
                                                family.selected_symptoms,
                                                "Unable to feel pleasure"
                                            ) && (
                                                <div
                                                    className={styles.checkbox}
                                                >
                                                    <span
                                                        className={cn(
                                                            styles.checkbox_wrap,
                                                            {
                                                                [styles.checked]:
                                                                    isCheckboxChecked(
                                                                        family.selected_symptoms,
                                                                        "Unable to feel pleasure"
                                                                    ),
                                                            }
                                                        )}
                                                    >
                                                        <CheckIcon />
                                                    </span>
                                                    Unable to feel pleasure
                                                </div>
                                            )}
                                            {isCheckboxChecked(
                                                family.selected_symptoms,
                                                "Excessive energy"
                                            ) && (
                                                <div
                                                    className={styles.checkbox}
                                                >
                                                    <span
                                                        className={cn(
                                                            styles.checkbox_wrap,
                                                            {
                                                                [styles.checked]:
                                                                    isCheckboxChecked(
                                                                        family.selected_symptoms,
                                                                        "Excessive energy"
                                                                    ),
                                                            }
                                                        )}
                                                    >
                                                        <CheckIcon />
                                                    </span>
                                                    Excessive energy
                                                </div>
                                            )}
                                            {isCheckboxChecked(
                                                family.selected_symptoms,
                                                "Recreational drug use"
                                            ) && (
                                                <div
                                                    className={styles.checkbox}
                                                >
                                                    <span
                                                        className={cn(
                                                            styles.checkbox_wrap,
                                                            {
                                                                [styles.checked]:
                                                                    isCheckboxChecked(
                                                                        family.selected_symptoms,
                                                                        "Recreational drug use"
                                                                    ),
                                                            }
                                                        )}
                                                    >
                                                        <CheckIcon />
                                                    </span>
                                                    Recreational drug use
                                                </div>
                                            )}
                                            {isCheckboxChecked(
                                                family.selected_symptoms,
                                                "Tobacco"
                                            ) && (
                                                <div
                                                    className={styles.checkbox}
                                                >
                                                    <span
                                                        className={cn(
                                                            styles.checkbox_wrap,
                                                            {
                                                                [styles.checked]:
                                                                    isCheckboxChecked(
                                                                        family.selected_symptoms,
                                                                        "Tobacco"
                                                                    ),
                                                            }
                                                        )}
                                                    >
                                                        <CheckIcon />
                                                    </span>
                                                    Tobacco
                                                </div>
                                            )}
                                            {isCheckboxChecked(
                                                family.selected_symptoms,
                                                "Racing thoughts"
                                            ) && (
                                                <div
                                                    className={styles.checkbox}
                                                >
                                                    <span
                                                        className={cn(
                                                            styles.checkbox_wrap,
                                                            {
                                                                [styles.checked]:
                                                                    isCheckboxChecked(
                                                                        family.selected_symptoms,
                                                                        "Racing thoughts"
                                                                    ),
                                                            }
                                                        )}
                                                    >
                                                        <CheckIcon />
                                                    </span>
                                                    Racing thoughts
                                                </div>
                                            )}
                                        </div>
                                    ) : (
                                        "--"
                                    )
                                }
                            />
                            <NoteContentItem
                                headerText={
                                    FAMILY_INTAKE_FAMILY_NOTE_DETAILS.other_symptoms
                                }
                                fieldId={`other_symptoms-${noteContent.family[i].family_id}`}
                                addendums={
                                    fieldAddendums?.[
                                        `other_symptoms-${noteContent.family[i].family_id}`
                                    ]
                                }
                                content={family.other_symptoms || "--"}
                            />
                            <NoteContentItem
                                headerText={
                                    FAMILY_INTAKE_FAMILY_NOTE_DETAILS.physical_symptoms
                                }
                                fieldId={`physical_symptoms-${noteContent.family[i].family_id}`}
                                addendums={
                                    fieldAddendums?.[
                                        `physical_symptoms-${noteContent.family[i].family_id}`
                                    ]
                                }
                                content={family.physical_symptoms || "--"}
                            />
                            <NoteContentItem
                                headerText={
                                    FAMILY_INTAKE_FAMILY_NOTE_DETAILS.medication_physical_psychiatric
                                }
                                fieldId={`medication_physical_psychiatric-${noteContent.family[i].family_id}`}
                                addendums={
                                    fieldAddendums?.[
                                        `medication_physical_psychiatric-${noteContent.family[i].family_id}`
                                    ]
                                }
                                content={
                                    family.medication_physical_psychiatric ||
                                    "--"
                                }
                            />
                            <NoteContentItem
                                headerText={
                                    FAMILY_INTAKE_FAMILY_NOTE_DETAILS.sleeping_habits_and_concerns
                                }
                                fieldId={`sleeping_habits_and_concerns-${noteContent.family[i].family_id}`}
                                addendums={
                                    fieldAddendums?.[
                                        `sleeping_habits_and_concerns-${noteContent.family[i].family_id}`
                                    ]
                                }
                                content={
                                    family.sleeping_habits_and_concerns || "--"
                                }
                            />
                            <NoteContentItem
                                headerText={
                                    FAMILY_INTAKE_FAMILY_NOTE_DETAILS.difficulties_with_appetite_or_eating_patterns
                                }
                                fieldId={`difficulties_with_appetite_or_eating_patterns-${noteContent.family[i].family_id}`}
                                addendums={
                                    fieldAddendums?.[
                                        `difficulties_with_appetite_or_eating_patterns-${noteContent.family[i].family_id}`
                                    ]
                                }
                                content={
                                    family.difficulties_with_appetite_or_eating_patterns ||
                                    "--"
                                }
                            />
                        </React.Fragment>
                    )
                )}
            {noteContent.family &&
                noteContent.family.map(
                    (family: FamilyIntakeNotePayload, i: number) => (
                        <React.Fragment key={`${i + 1}`}>
                            <p
                                className={styles.sectionheader}
                                id={INTAKE_SECTIONS["family history"]}
                            >
                                Family history
                            </p>
                            <p className={styles.sectionheader}>
                                Client {i + 1}{" "}
                                {family.name ? `- ${family.name}` : ""}
                            </p>
                            <NoteContentItem
                                headerText={
                                    FAMILY_INTAKE_FAMILY_NOTE_DETAILS.parents_stepparents_history
                                }
                                fieldId={`parents_stepparents_history-${noteContent.family[i].family_id}`}
                                addendums={
                                    fieldAddendums?.[
                                        `parents_stepparents_history-${noteContent.family[i].family_id}`
                                    ]
                                }
                                content={
                                    family.parents_stepparents_history || "--"
                                }
                            />
                            <NoteContentItem
                                headerText={
                                    FAMILY_INTAKE_FAMILY_NOTE_DETAILS.siblings_history
                                }
                                fieldId={`siblings_history-${noteContent.family[i].family_id}`}
                                addendums={
                                    fieldAddendums?.[
                                        `siblings_history-${noteContent.family[i].family_id}`
                                    ]
                                }
                                content={family.siblings_history || "--"}
                            />
                            <NoteContentItem
                                headerText={
                                    FAMILY_INTAKE_FAMILY_NOTE_DETAILS.grandparents_history
                                }
                                fieldId={`grandparents_history-${noteContent.family[i].family_id}`}
                                addendums={
                                    fieldAddendums?.[
                                        `grandparents_history-${noteContent.family[i].family_id}`
                                    ]
                                }
                                content={family.grandparents_history || "--"}
                            />
                            <NoteContentItem
                                headerText={
                                    FAMILY_INTAKE_FAMILY_NOTE_DETAILS.children_history
                                }
                                fieldId={`children_history-${noteContent.family[i].family_id}`}
                                addendums={
                                    fieldAddendums?.[
                                        `children_history-${noteContent.family[i].family_id}`
                                    ]
                                }
                                content={family.children_history || "--"}
                            />
                            <NoteContentItem
                                headerText={
                                    FAMILY_INTAKE_FAMILY_NOTE_DETAILS.other_family_members_history
                                }
                                fieldId={`other_family_members_history-${noteContent.family[i].family_id}`}
                                addendums={
                                    fieldAddendums?.[
                                        `other_family_members_history-${noteContent.family[i].family_id}`
                                    ]
                                }
                                content={
                                    family.other_family_members_history || "--"
                                }
                            />

                            <p
                                className={styles.sectionheader}
                                id={
                                    INTAKE_SECTIONS[
                                        "family psychiatric history"
                                    ]
                                }
                            >
                                Family Psychiatric History
                            </p>
                            <NoteContentItem
                                headerText={
                                    FAMILY_INTAKE_FAMILY_NOTE_DETAILS.family_members_alcohol_abuse
                                }
                                fieldId={`family_members_alcohol_abuse-${noteContent.family[i].family_id}`}
                                addendums={
                                    fieldAddendums?.[
                                        `family_members_alcohol_abuse-${noteContent.family[i].family_id}`
                                    ]
                                }
                                content={
                                    family?.family_members_alcohol_abuse
                                        ?.length > 0 ? (
                                        <div className={styles.checkboxes}>
                                            {isCheckboxChecked(
                                                family.family_members_alcohol_abuse,
                                                "All"
                                            ) && (
                                                <div
                                                    className={styles.checkbox}
                                                >
                                                    <span
                                                        className={cn(
                                                            styles.checkbox_wrap,
                                                            {
                                                                [styles.checked]:
                                                                    isCheckboxChecked(
                                                                        family.family_members_alcohol_abuse,
                                                                        "All"
                                                                    ),
                                                            }
                                                        )}
                                                    >
                                                        <CheckIcon />
                                                    </span>
                                                    All
                                                </div>
                                            )}
                                            {isCheckboxChecked(
                                                family.family_members_alcohol_abuse,
                                                "Father"
                                            ) && (
                                                <div
                                                    className={styles.checkbox}
                                                >
                                                    <span
                                                        className={cn(
                                                            styles.checkbox_wrap,
                                                            {
                                                                [styles.checked]:
                                                                    isCheckboxChecked(
                                                                        family.family_members_alcohol_abuse,
                                                                        "Father"
                                                                    ),
                                                            }
                                                        )}
                                                    >
                                                        <CheckIcon />
                                                    </span>
                                                    Father
                                                </div>
                                            )}
                                            {isCheckboxChecked(
                                                family.family_members_alcohol_abuse,
                                                "Mother"
                                            ) && (
                                                <div
                                                    className={styles.checkbox}
                                                >
                                                    <span
                                                        className={cn(
                                                            styles.checkbox_wrap,
                                                            {
                                                                [styles.checked]:
                                                                    isCheckboxChecked(
                                                                        family.family_members_alcohol_abuse,
                                                                        "Mother"
                                                                    ),
                                                            }
                                                        )}
                                                    >
                                                        <CheckIcon />
                                                    </span>
                                                    Mother
                                                </div>
                                            )}
                                            {isCheckboxChecked(
                                                family.family_members_alcohol_abuse,
                                                "Child/Children"
                                            ) && (
                                                <div
                                                    className={styles.checkbox}
                                                >
                                                    <span
                                                        className={cn(
                                                            styles.checkbox_wrap,
                                                            {
                                                                [styles.checked]:
                                                                    isCheckboxChecked(
                                                                        family.family_members_alcohol_abuse,
                                                                        "Child/Children"
                                                                    ),
                                                            }
                                                        )}
                                                    >
                                                        <CheckIcon />
                                                    </span>
                                                    Child/Children
                                                </div>
                                            )}
                                            {isCheckboxChecked(
                                                family.family_members_alcohol_abuse,
                                                "Brother"
                                            ) && (
                                                <div
                                                    className={styles.checkbox}
                                                >
                                                    <span
                                                        className={cn(
                                                            styles.checkbox_wrap,
                                                            {
                                                                [styles.checked]:
                                                                    isCheckboxChecked(
                                                                        family.family_members_alcohol_abuse,
                                                                        "Brother"
                                                                    ),
                                                            }
                                                        )}
                                                    >
                                                        <CheckIcon />
                                                    </span>
                                                    Brother
                                                </div>
                                            )}
                                            {isCheckboxChecked(
                                                family.family_members_alcohol_abuse,
                                                "Sister"
                                            ) && (
                                                <div
                                                    className={styles.checkbox}
                                                >
                                                    <span
                                                        className={cn(
                                                            styles.checkbox_wrap,
                                                            {
                                                                [styles.checked]:
                                                                    isCheckboxChecked(
                                                                        family.family_members_alcohol_abuse,
                                                                        "Sister"
                                                                    ),
                                                            }
                                                        )}
                                                    >
                                                        <CheckIcon />
                                                    </span>
                                                    Sister
                                                </div>
                                            )}
                                            {isCheckboxChecked(
                                                family.family_members_alcohol_abuse,
                                                "Other"
                                            ) && (
                                                <div
                                                    className={styles.checkbox}
                                                >
                                                    <span
                                                        className={cn(
                                                            styles.checkbox_wrap,
                                                            {
                                                                [styles.checked]:
                                                                    isCheckboxChecked(
                                                                        family.family_members_alcohol_abuse,
                                                                        "Other"
                                                                    ),
                                                            }
                                                        )}
                                                    >
                                                        <CheckIcon />
                                                    </span>
                                                    Other
                                                </div>
                                            )}
                                        </div>
                                    ) : (
                                        "--"
                                    )
                                }
                            />
                            <NoteContentItem
                                headerText={
                                    FAMILY_INTAKE_FAMILY_NOTE_DETAILS.family_members_anxiety
                                }
                                fieldId={`family_members_anxiety-${noteContent.family[i].family_id}`}
                                addendums={
                                    fieldAddendums?.[
                                        `family_members_anxiety-${noteContent.family[i].family_id}`
                                    ]
                                }
                                content={
                                    family?.family_members_anxiety?.length >
                                    0 ? (
                                        <div className={styles.checkboxes}>
                                            {isCheckboxChecked(
                                                family.family_members_anxiety,
                                                "All"
                                            ) && (
                                                <div
                                                    className={styles.checkbox}
                                                >
                                                    <span
                                                        className={cn(
                                                            styles.checkbox_wrap,
                                                            {
                                                                [styles.checked]:
                                                                    isCheckboxChecked(
                                                                        family.family_members_anxiety,
                                                                        "All"
                                                                    ),
                                                            }
                                                        )}
                                                    >
                                                        <CheckIcon />
                                                    </span>
                                                    All
                                                </div>
                                            )}
                                            {isCheckboxChecked(
                                                family.family_members_anxiety,
                                                "Father"
                                            ) && (
                                                <div
                                                    className={styles.checkbox}
                                                >
                                                    <span
                                                        className={cn(
                                                            styles.checkbox_wrap,
                                                            {
                                                                [styles.checked]:
                                                                    isCheckboxChecked(
                                                                        family.family_members_anxiety,
                                                                        "Father"
                                                                    ),
                                                            }
                                                        )}
                                                    >
                                                        <CheckIcon />
                                                    </span>
                                                    Father
                                                </div>
                                            )}
                                            {isCheckboxChecked(
                                                family.family_members_anxiety,
                                                "Mother"
                                            ) && (
                                                <div
                                                    className={styles.checkbox}
                                                >
                                                    <span
                                                        className={cn(
                                                            styles.checkbox_wrap,
                                                            {
                                                                [styles.checked]:
                                                                    isCheckboxChecked(
                                                                        family.family_members_anxiety,
                                                                        "Mother"
                                                                    ),
                                                            }
                                                        )}
                                                    >
                                                        <CheckIcon />
                                                    </span>
                                                    Mother
                                                </div>
                                            )}
                                            {isCheckboxChecked(
                                                family.family_members_anxiety,
                                                "Child/Children"
                                            ) && (
                                                <div
                                                    className={styles.checkbox}
                                                >
                                                    <span
                                                        className={cn(
                                                            styles.checkbox_wrap,
                                                            {
                                                                [styles.checked]:
                                                                    isCheckboxChecked(
                                                                        family.family_members_anxiety,
                                                                        "Child/Children"
                                                                    ),
                                                            }
                                                        )}
                                                    >
                                                        <CheckIcon />
                                                    </span>
                                                    Child/Children
                                                </div>
                                            )}
                                            {isCheckboxChecked(
                                                family.family_members_anxiety,
                                                "Brother"
                                            ) && (
                                                <div
                                                    className={styles.checkbox}
                                                >
                                                    <span
                                                        className={cn(
                                                            styles.checkbox_wrap,
                                                            {
                                                                [styles.checked]:
                                                                    isCheckboxChecked(
                                                                        family.family_members_anxiety,
                                                                        "Brother"
                                                                    ),
                                                            }
                                                        )}
                                                    >
                                                        <CheckIcon />
                                                    </span>
                                                    Brother
                                                </div>
                                            )}
                                            {isCheckboxChecked(
                                                family.family_members_anxiety,
                                                "Sister"
                                            ) && (
                                                <div
                                                    className={styles.checkbox}
                                                >
                                                    <span
                                                        className={cn(
                                                            styles.checkbox_wrap,
                                                            {
                                                                [styles.checked]:
                                                                    isCheckboxChecked(
                                                                        family.family_members_anxiety,
                                                                        "Sister"
                                                                    ),
                                                            }
                                                        )}
                                                    >
                                                        <CheckIcon />
                                                    </span>
                                                    Sister
                                                </div>
                                            )}
                                            {isCheckboxChecked(
                                                family.family_members_anxiety,
                                                "Other"
                                            ) && (
                                                <div
                                                    className={styles.checkbox}
                                                >
                                                    <span
                                                        className={cn(
                                                            styles.checkbox_wrap,
                                                            {
                                                                [styles.checked]:
                                                                    isCheckboxChecked(
                                                                        family.family_members_anxiety,
                                                                        "Other"
                                                                    ),
                                                            }
                                                        )}
                                                    >
                                                        <CheckIcon />
                                                    </span>
                                                    Other
                                                </div>
                                            )}
                                        </div>
                                    ) : (
                                        "--"
                                    )
                                }
                            />

                            <NoteContentItem
                                headerText={
                                    FAMILY_INTAKE_FAMILY_NOTE_DETAILS.family_members_bipolar_disorder
                                }
                                fieldId={`family_members_bipolar_disorder-${noteContent.family[i].family_id}`}
                                addendums={
                                    fieldAddendums?.[
                                        `family_members_bipolar_disorder-${noteContent.family[i].family_id}`
                                    ]
                                }
                                content={
                                    family?.family_members_bipolar_disorder
                                        ?.length > 0 ? (
                                        <div className={styles.checkboxes}>
                                            {isCheckboxChecked(
                                                family.family_members_bipolar_disorder,
                                                "All"
                                            ) && (
                                                <div
                                                    className={styles.checkbox}
                                                >
                                                    <span
                                                        className={cn(
                                                            styles.checkbox_wrap,
                                                            {
                                                                [styles.checked]:
                                                                    isCheckboxChecked(
                                                                        family.family_members_bipolar_disorder,
                                                                        "All"
                                                                    ),
                                                            }
                                                        )}
                                                    >
                                                        <CheckIcon />
                                                    </span>
                                                    All
                                                </div>
                                            )}
                                            {isCheckboxChecked(
                                                family.family_members_bipolar_disorder,
                                                "Father"
                                            ) && (
                                                <div
                                                    className={styles.checkbox}
                                                >
                                                    <span
                                                        className={cn(
                                                            styles.checkbox_wrap,
                                                            {
                                                                [styles.checked]:
                                                                    isCheckboxChecked(
                                                                        family.family_members_bipolar_disorder,
                                                                        "Father"
                                                                    ),
                                                            }
                                                        )}
                                                    >
                                                        <CheckIcon />
                                                    </span>
                                                    Father
                                                </div>
                                            )}
                                            {isCheckboxChecked(
                                                family.family_members_bipolar_disorder,
                                                "Mother"
                                            ) && (
                                                <div
                                                    className={styles.checkbox}
                                                >
                                                    <span
                                                        className={cn(
                                                            styles.checkbox_wrap,
                                                            {
                                                                [styles.checked]:
                                                                    isCheckboxChecked(
                                                                        family.family_members_bipolar_disorder,
                                                                        "Mother"
                                                                    ),
                                                            }
                                                        )}
                                                    >
                                                        <CheckIcon />
                                                    </span>
                                                    Mother
                                                </div>
                                            )}
                                            {isCheckboxChecked(
                                                family.family_members_bipolar_disorder,
                                                "Child/Children"
                                            ) && (
                                                <div
                                                    className={styles.checkbox}
                                                >
                                                    <span
                                                        className={cn(
                                                            styles.checkbox_wrap,
                                                            {
                                                                [styles.checked]:
                                                                    isCheckboxChecked(
                                                                        family.family_members_bipolar_disorder,
                                                                        "Child/Children"
                                                                    ),
                                                            }
                                                        )}
                                                    >
                                                        <CheckIcon />
                                                    </span>
                                                    Child/Children
                                                </div>
                                            )}
                                            {isCheckboxChecked(
                                                family.family_members_bipolar_disorder,
                                                "Brother"
                                            ) && (
                                                <div
                                                    className={styles.checkbox}
                                                >
                                                    <span
                                                        className={cn(
                                                            styles.checkbox_wrap,
                                                            {
                                                                [styles.checked]:
                                                                    isCheckboxChecked(
                                                                        family.family_members_bipolar_disorder,
                                                                        "Brother"
                                                                    ),
                                                            }
                                                        )}
                                                    >
                                                        <CheckIcon />
                                                    </span>
                                                    Brother
                                                </div>
                                            )}
                                            {isCheckboxChecked(
                                                family.family_members_bipolar_disorder,
                                                "Sister"
                                            ) && (
                                                <div
                                                    className={styles.checkbox}
                                                >
                                                    <span
                                                        className={cn(
                                                            styles.checkbox_wrap,
                                                            {
                                                                [styles.checked]:
                                                                    isCheckboxChecked(
                                                                        family.family_members_bipolar_disorder,
                                                                        "Sister"
                                                                    ),
                                                            }
                                                        )}
                                                    >
                                                        <CheckIcon />
                                                    </span>
                                                    Sister
                                                </div>
                                            )}
                                            {isCheckboxChecked(
                                                family.family_members_bipolar_disorder,
                                                "Other"
                                            ) && (
                                                <div
                                                    className={styles.checkbox}
                                                >
                                                    <span
                                                        className={cn(
                                                            styles.checkbox_wrap,
                                                            {
                                                                [styles.checked]:
                                                                    isCheckboxChecked(
                                                                        family.family_members_bipolar_disorder,
                                                                        "Other"
                                                                    ),
                                                            }
                                                        )}
                                                    >
                                                        <CheckIcon />
                                                    </span>
                                                    Other
                                                </div>
                                            )}
                                        </div>
                                    ) : (
                                        "--"
                                    )
                                }
                            />
                            <NoteContentItem
                                headerText={
                                    FAMILY_INTAKE_FAMILY_NOTE_DETAILS.family_members_depression
                                }
                                fieldId={`family_members_depression-${noteContent.family[i].family_id}`}
                                addendums={
                                    fieldAddendums?.[
                                        `family_members_depression-${noteContent.family[i].family_id}`
                                    ]
                                }
                                content={
                                    family?.family_members_depression?.length >
                                    0 ? (
                                        <div className={styles.checkboxes}>
                                            {isCheckboxChecked(
                                                family.family_members_depression,
                                                "All"
                                            ) && (
                                                <div
                                                    className={styles.checkbox}
                                                >
                                                    <span
                                                        className={cn(
                                                            styles.checkbox_wrap,
                                                            {
                                                                [styles.checked]:
                                                                    isCheckboxChecked(
                                                                        family.family_members_depression,
                                                                        "All"
                                                                    ),
                                                            }
                                                        )}
                                                    >
                                                        <CheckIcon />
                                                    </span>
                                                    All
                                                </div>
                                            )}
                                            {isCheckboxChecked(
                                                family.family_members_depression,
                                                "Father"
                                            ) && (
                                                <div
                                                    className={styles.checkbox}
                                                >
                                                    <span
                                                        className={cn(
                                                            styles.checkbox_wrap,
                                                            {
                                                                [styles.checked]:
                                                                    isCheckboxChecked(
                                                                        family.family_members_depression,
                                                                        "Father"
                                                                    ),
                                                            }
                                                        )}
                                                    >
                                                        <CheckIcon />
                                                    </span>
                                                    Father
                                                </div>
                                            )}
                                            {isCheckboxChecked(
                                                family.family_members_depression,
                                                "Mother"
                                            ) && (
                                                <div
                                                    className={styles.checkbox}
                                                >
                                                    <span
                                                        className={cn(
                                                            styles.checkbox_wrap,
                                                            {
                                                                [styles.checked]:
                                                                    isCheckboxChecked(
                                                                        family.family_members_depression,
                                                                        "Mother"
                                                                    ),
                                                            }
                                                        )}
                                                    >
                                                        <CheckIcon />
                                                    </span>
                                                    Mother
                                                </div>
                                            )}
                                            {isCheckboxChecked(
                                                family.family_members_depression,
                                                "Child/Children"
                                            ) && (
                                                <div
                                                    className={styles.checkbox}
                                                >
                                                    <span
                                                        className={cn(
                                                            styles.checkbox_wrap,
                                                            {
                                                                [styles.checked]:
                                                                    isCheckboxChecked(
                                                                        family.family_members_depression,
                                                                        "Child/Children"
                                                                    ),
                                                            }
                                                        )}
                                                    >
                                                        <CheckIcon />
                                                    </span>
                                                    Child/Children
                                                </div>
                                            )}
                                            {isCheckboxChecked(
                                                family.family_members_depression,
                                                "Brother"
                                            ) && (
                                                <div
                                                    className={styles.checkbox}
                                                >
                                                    <span
                                                        className={cn(
                                                            styles.checkbox_wrap,
                                                            {
                                                                [styles.checked]:
                                                                    isCheckboxChecked(
                                                                        family.family_members_depression,
                                                                        "Brother"
                                                                    ),
                                                            }
                                                        )}
                                                    >
                                                        <CheckIcon />
                                                    </span>
                                                    Brother
                                                </div>
                                            )}
                                            {isCheckboxChecked(
                                                family.family_members_depression,
                                                "Sister"
                                            ) && (
                                                <div
                                                    className={styles.checkbox}
                                                >
                                                    <span
                                                        className={cn(
                                                            styles.checkbox_wrap,
                                                            {
                                                                [styles.checked]:
                                                                    isCheckboxChecked(
                                                                        family.family_members_depression,
                                                                        "Sister"
                                                                    ),
                                                            }
                                                        )}
                                                    >
                                                        <CheckIcon />
                                                    </span>
                                                    Sister
                                                </div>
                                            )}
                                            {isCheckboxChecked(
                                                family.family_members_depression,
                                                "Other"
                                            ) && (
                                                <div
                                                    className={styles.checkbox}
                                                >
                                                    <span
                                                        className={cn(
                                                            styles.checkbox_wrap,
                                                            {
                                                                [styles.checked]:
                                                                    isCheckboxChecked(
                                                                        family.family_members_depression,
                                                                        "Other"
                                                                    ),
                                                            }
                                                        )}
                                                    >
                                                        <CheckIcon />
                                                    </span>
                                                    Other
                                                </div>
                                            )}
                                        </div>
                                    ) : (
                                        "--"
                                    )
                                }
                            />
                            <NoteContentItem
                                headerText={
                                    FAMILY_INTAKE_FAMILY_NOTE_DETAILS.family_members_ptsd
                                }
                                fieldId={`family_members_ptsd-${noteContent.family[i].family_id}`}
                                addendums={
                                    fieldAddendums?.[
                                        `family_members_ptsd-${noteContent.family[i].family_id}`
                                    ]
                                }
                                content={
                                    family?.family_members_ptsd?.length > 0 ? (
                                        <div className={styles.checkboxes}>
                                            {isCheckboxChecked(
                                                family.family_members_ptsd,
                                                "All"
                                            ) && (
                                                <div
                                                    className={styles.checkbox}
                                                >
                                                    <span
                                                        className={cn(
                                                            styles.checkbox_wrap,
                                                            {
                                                                [styles.checked]:
                                                                    isCheckboxChecked(
                                                                        family.family_members_ptsd,
                                                                        "All"
                                                                    ),
                                                            }
                                                        )}
                                                    >
                                                        <CheckIcon />
                                                    </span>
                                                    All
                                                </div>
                                            )}
                                            {isCheckboxChecked(
                                                family.family_members_ptsd,
                                                "Father"
                                            ) && (
                                                <div
                                                    className={styles.checkbox}
                                                >
                                                    <span
                                                        className={cn(
                                                            styles.checkbox_wrap,
                                                            {
                                                                [styles.checked]:
                                                                    isCheckboxChecked(
                                                                        family.family_members_ptsd,
                                                                        "Father"
                                                                    ),
                                                            }
                                                        )}
                                                    >
                                                        <CheckIcon />
                                                    </span>
                                                    Father
                                                </div>
                                            )}
                                            {isCheckboxChecked(
                                                family.family_members_ptsd,
                                                "Mother"
                                            ) && (
                                                <div
                                                    className={styles.checkbox}
                                                >
                                                    <span
                                                        className={cn(
                                                            styles.checkbox_wrap,
                                                            {
                                                                [styles.checked]:
                                                                    isCheckboxChecked(
                                                                        family.family_members_ptsd,
                                                                        "Mother"
                                                                    ),
                                                            }
                                                        )}
                                                    >
                                                        <CheckIcon />
                                                    </span>
                                                    Mother
                                                </div>
                                            )}
                                            {isCheckboxChecked(
                                                family.family_members_ptsd,
                                                "Child/Children"
                                            ) && (
                                                <div
                                                    className={styles.checkbox}
                                                >
                                                    <span
                                                        className={cn(
                                                            styles.checkbox_wrap,
                                                            {
                                                                [styles.checked]:
                                                                    isCheckboxChecked(
                                                                        family.family_members_ptsd,
                                                                        "Child/Children"
                                                                    ),
                                                            }
                                                        )}
                                                    >
                                                        <CheckIcon />
                                                    </span>
                                                    Child/Children
                                                </div>
                                            )}
                                            {isCheckboxChecked(
                                                family.family_members_ptsd,
                                                "Brother"
                                            ) && (
                                                <div
                                                    className={styles.checkbox}
                                                >
                                                    <span
                                                        className={cn(
                                                            styles.checkbox_wrap,
                                                            {
                                                                [styles.checked]:
                                                                    isCheckboxChecked(
                                                                        family.family_members_ptsd,
                                                                        "Brother"
                                                                    ),
                                                            }
                                                        )}
                                                    >
                                                        <CheckIcon />
                                                    </span>
                                                    Brother
                                                </div>
                                            )}
                                            {isCheckboxChecked(
                                                family.family_members_ptsd,
                                                "Sister"
                                            ) && (
                                                <div
                                                    className={styles.checkbox}
                                                >
                                                    <span
                                                        className={cn(
                                                            styles.checkbox_wrap,
                                                            {
                                                                [styles.checked]:
                                                                    isCheckboxChecked(
                                                                        family.family_members_ptsd,
                                                                        "Sister"
                                                                    ),
                                                            }
                                                        )}
                                                    >
                                                        <CheckIcon />
                                                    </span>
                                                    Sister
                                                </div>
                                            )}
                                            {isCheckboxChecked(
                                                family.family_members_ptsd,
                                                "Other"
                                            ) && (
                                                <div
                                                    className={styles.checkbox}
                                                >
                                                    <span
                                                        className={cn(
                                                            styles.checkbox_wrap,
                                                            {
                                                                [styles.checked]:
                                                                    isCheckboxChecked(
                                                                        family.family_members_ptsd,
                                                                        "Other"
                                                                    ),
                                                            }
                                                        )}
                                                    >
                                                        <CheckIcon />
                                                    </span>
                                                    Other
                                                </div>
                                            )}
                                        </div>
                                    ) : (
                                        "--"
                                    )
                                }
                            />
                            <NoteContentItem
                                headerText={
                                    FAMILY_INTAKE_FAMILY_NOTE_DETAILS.family_members_schizophrenia
                                }
                                fieldId={`family_members_schizophrenia-${noteContent.family[i].family_id}`}
                                addendums={
                                    fieldAddendums?.[
                                        `family_members_schizophrenia-${noteContent.family[i].family_id}`
                                    ]
                                }
                                content={
                                    family?.family_members_schizophrenia
                                        ?.length > 0 ? (
                                        <div className={styles.checkboxes}>
                                            {isCheckboxChecked(
                                                family.family_members_schizophrenia,
                                                "All"
                                            ) && (
                                                <div
                                                    className={styles.checkbox}
                                                >
                                                    <span
                                                        className={cn(
                                                            styles.checkbox_wrap,
                                                            {
                                                                [styles.checked]:
                                                                    isCheckboxChecked(
                                                                        family.family_members_schizophrenia,
                                                                        "All"
                                                                    ),
                                                            }
                                                        )}
                                                    >
                                                        <CheckIcon />
                                                    </span>
                                                    All
                                                </div>
                                            )}
                                            {isCheckboxChecked(
                                                family.family_members_schizophrenia,
                                                "Father"
                                            ) && (
                                                <div
                                                    className={styles.checkbox}
                                                >
                                                    <span
                                                        className={cn(
                                                            styles.checkbox_wrap,
                                                            {
                                                                [styles.checked]:
                                                                    isCheckboxChecked(
                                                                        family.family_members_schizophrenia,
                                                                        "Father"
                                                                    ),
                                                            }
                                                        )}
                                                    >
                                                        <CheckIcon />
                                                    </span>
                                                    Father
                                                </div>
                                            )}
                                            {isCheckboxChecked(
                                                family.family_members_schizophrenia,
                                                "Mother"
                                            ) && (
                                                <div
                                                    className={styles.checkbox}
                                                >
                                                    <span
                                                        className={cn(
                                                            styles.checkbox_wrap,
                                                            {
                                                                [styles.checked]:
                                                                    isCheckboxChecked(
                                                                        family.family_members_schizophrenia,
                                                                        "Mother"
                                                                    ),
                                                            }
                                                        )}
                                                    >
                                                        <CheckIcon />
                                                    </span>
                                                    Mother
                                                </div>
                                            )}
                                            {isCheckboxChecked(
                                                family.family_members_schizophrenia,
                                                "Child/Children"
                                            ) && (
                                                <div
                                                    className={styles.checkbox}
                                                >
                                                    <span
                                                        className={cn(
                                                            styles.checkbox_wrap,
                                                            {
                                                                [styles.checked]:
                                                                    isCheckboxChecked(
                                                                        family.family_members_schizophrenia,
                                                                        "Child/Children"
                                                                    ),
                                                            }
                                                        )}
                                                    >
                                                        <CheckIcon />
                                                    </span>
                                                    Child/Children
                                                </div>
                                            )}
                                            {isCheckboxChecked(
                                                family.family_members_schizophrenia,
                                                "Brother"
                                            ) && (
                                                <div
                                                    className={styles.checkbox}
                                                >
                                                    <span
                                                        className={cn(
                                                            styles.checkbox_wrap,
                                                            {
                                                                [styles.checked]:
                                                                    isCheckboxChecked(
                                                                        family.family_members_schizophrenia,
                                                                        "Brother"
                                                                    ),
                                                            }
                                                        )}
                                                    >
                                                        <CheckIcon />
                                                    </span>
                                                    Brother
                                                </div>
                                            )}
                                            {isCheckboxChecked(
                                                family.family_members_schizophrenia,
                                                "Sister"
                                            ) && (
                                                <div
                                                    className={styles.checkbox}
                                                >
                                                    <span
                                                        className={cn(
                                                            styles.checkbox_wrap,
                                                            {
                                                                [styles.checked]:
                                                                    isCheckboxChecked(
                                                                        family.family_members_schizophrenia,
                                                                        "Sister"
                                                                    ),
                                                            }
                                                        )}
                                                    >
                                                        <CheckIcon />
                                                    </span>
                                                    Sister
                                                </div>
                                            )}
                                            {isCheckboxChecked(
                                                family.family_members_schizophrenia,
                                                "Other"
                                            ) && (
                                                <div
                                                    className={styles.checkbox}
                                                >
                                                    <span
                                                        className={cn(
                                                            styles.checkbox_wrap,
                                                            {
                                                                [styles.checked]:
                                                                    isCheckboxChecked(
                                                                        family.family_members_schizophrenia,
                                                                        "Other"
                                                                    ),
                                                            }
                                                        )}
                                                    >
                                                        <CheckIcon />
                                                    </span>
                                                    Other
                                                </div>
                                            )}
                                        </div>
                                    ) : (
                                        "--"
                                    )
                                }
                            />
                            <NoteContentItem
                                headerText={
                                    FAMILY_INTAKE_FAMILY_NOTE_DETAILS.family_members_substance_abuse
                                }
                                fieldId={`family_members_substance_abuse-${noteContent.family[i].family_id}`}
                                addendums={
                                    fieldAddendums?.[
                                        `family_members_substance_abuse-${noteContent.family[i].family_id}`
                                    ]
                                }
                                content={
                                    family?.family_members_substance_abuse
                                        ?.length > 0 ? (
                                        <div className={styles.checkboxes}>
                                            {isCheckboxChecked(
                                                family.family_members_substance_abuse,
                                                "All"
                                            ) && (
                                                <div
                                                    className={styles.checkbox}
                                                >
                                                    <span
                                                        className={cn(
                                                            styles.checkbox_wrap,
                                                            {
                                                                [styles.checked]:
                                                                    isCheckboxChecked(
                                                                        family.family_members_substance_abuse,
                                                                        "All"
                                                                    ),
                                                            }
                                                        )}
                                                    >
                                                        <CheckIcon />
                                                    </span>
                                                    All
                                                </div>
                                            )}
                                            {isCheckboxChecked(
                                                family.family_members_substance_abuse,
                                                "Father"
                                            ) && (
                                                <div
                                                    className={styles.checkbox}
                                                >
                                                    <span
                                                        className={cn(
                                                            styles.checkbox_wrap,
                                                            {
                                                                [styles.checked]:
                                                                    isCheckboxChecked(
                                                                        family.family_members_substance_abuse,
                                                                        "Father"
                                                                    ),
                                                            }
                                                        )}
                                                    >
                                                        <CheckIcon />
                                                    </span>
                                                    Father
                                                </div>
                                            )}
                                            {isCheckboxChecked(
                                                family.family_members_substance_abuse,
                                                "Mother"
                                            ) && (
                                                <div
                                                    className={styles.checkbox}
                                                >
                                                    <span
                                                        className={cn(
                                                            styles.checkbox_wrap,
                                                            {
                                                                [styles.checked]:
                                                                    isCheckboxChecked(
                                                                        family.family_members_substance_abuse,
                                                                        "Mother"
                                                                    ),
                                                            }
                                                        )}
                                                    >
                                                        <CheckIcon />
                                                    </span>
                                                    Mother
                                                </div>
                                            )}
                                            {isCheckboxChecked(
                                                family.family_members_substance_abuse,
                                                "Child/Children"
                                            ) && (
                                                <div
                                                    className={styles.checkbox}
                                                >
                                                    <span
                                                        className={cn(
                                                            styles.checkbox_wrap,
                                                            {
                                                                [styles.checked]:
                                                                    isCheckboxChecked(
                                                                        family.family_members_substance_abuse,
                                                                        "Child/Children"
                                                                    ),
                                                            }
                                                        )}
                                                    >
                                                        <CheckIcon />
                                                    </span>
                                                    Child/Children
                                                </div>
                                            )}
                                            {isCheckboxChecked(
                                                family.family_members_substance_abuse,
                                                "Brother"
                                            ) && (
                                                <div
                                                    className={styles.checkbox}
                                                >
                                                    <span
                                                        className={cn(
                                                            styles.checkbox_wrap,
                                                            {
                                                                [styles.checked]:
                                                                    isCheckboxChecked(
                                                                        family.family_members_substance_abuse,
                                                                        "Brother"
                                                                    ),
                                                            }
                                                        )}
                                                    >
                                                        <CheckIcon />
                                                    </span>
                                                    Brother
                                                </div>
                                            )}
                                            {isCheckboxChecked(
                                                family.family_members_substance_abuse,
                                                "Sister"
                                            ) && (
                                                <div
                                                    className={styles.checkbox}
                                                >
                                                    <span
                                                        className={cn(
                                                            styles.checkbox_wrap,
                                                            {
                                                                [styles.checked]:
                                                                    isCheckboxChecked(
                                                                        family.family_members_substance_abuse,
                                                                        "Sister"
                                                                    ),
                                                            }
                                                        )}
                                                    >
                                                        <CheckIcon />
                                                    </span>
                                                    Sister
                                                </div>
                                            )}
                                            {isCheckboxChecked(
                                                family.family_members_substance_abuse,
                                                "Other"
                                            ) && (
                                                <div
                                                    className={styles.checkbox}
                                                >
                                                    <span
                                                        className={cn(
                                                            styles.checkbox_wrap,
                                                            {
                                                                [styles.checked]:
                                                                    isCheckboxChecked(
                                                                        family.family_members_substance_abuse,
                                                                        "Other"
                                                                    ),
                                                            }
                                                        )}
                                                    >
                                                        <CheckIcon />
                                                    </span>
                                                    Other
                                                </div>
                                            )}
                                        </div>
                                    ) : (
                                        "--"
                                    )
                                }
                            />
                            <NoteContentItem
                                headerText={
                                    FAMILY_INTAKE_FAMILY_NOTE_DETAILS.family_members_substance_abuse_substance
                                }
                                fieldId={`family_members_substance_abuse_substance-${noteContent.family[i].family_id}`}
                                addendums={
                                    fieldAddendums?.[
                                        `family_members_substance_abuse_substance-${noteContent.family[i].family_id}`
                                    ]
                                }
                                content={
                                    family.family_members_substance_abuse_substance ||
                                    "--"
                                }
                            />
                        </React.Fragment>
                    )
                )}
            <p
                className={styles.sectionheader}
                id={INTAKE_SECTIONS["mental health history"]}
            >
                Mental health history
            </p>
            {noteContent.family &&
                noteContent.family.map(
                    (family: FamilyIntakeNotePayload, i: number) => (
                        <React.Fragment key={`${i + 1}`}>
                            <p className={styles.sectionheader}>
                                Client {i + 1}{" "}
                                {family.name ? `- ${family.name}` : ""}
                            </p>
                            <NoteContentItem
                                headerText={
                                    FAMILY_INTAKE_FAMILY_NOTE_DETAILS.trauma_abuse_history
                                }
                                fieldId={`trauma_abuse_history-${noteContent.family[i].family_id}`}
                                addendums={
                                    fieldAddendums?.[
                                        `trauma_abuse_history-${noteContent.family[i].family_id}`
                                    ]
                                }
                                content={family.trauma_abuse_history || "--"}
                            />
                            <NoteContentItem
                                headerText={
                                    FAMILY_INTAKE_FAMILY_NOTE_DETAILS.previous_mental_health_treatment_oupatient
                                }
                                fieldId={`previous_mental_health_treatment_oupatient-${noteContent.family[i].family_id}`}
                                addendums={
                                    fieldAddendums?.[
                                        `previous_mental_health_treatment_oupatient-${noteContent.family[i].family_id}`
                                    ]
                                }
                                content={
                                    family.previous_mental_health_treatment_oupatient ||
                                    "--"
                                }
                            />
                            <NoteContentItem
                                headerText={
                                    FAMILY_INTAKE_FAMILY_NOTE_DETAILS.impatient_psychiatric_hospitalization
                                }
                                fieldId={`impatient_psychiatric_hospitalization-${noteContent.family[i].family_id}`}
                                addendums={
                                    fieldAddendums?.[
                                        `impatient_psychiatric_hospitalization-${noteContent.family[i].family_id}`
                                    ]
                                }
                                content={
                                    family.impatient_psychiatric_hospitalization ||
                                    "--"
                                }
                            />
                            <NoteContentItem
                                headerText={
                                    FAMILY_INTAKE_FAMILY_NOTE_DETAILS.relevant_medical_history
                                }
                                fieldId={`relevant_medical_history-${noteContent.family[i].family_id}`}
                                addendums={
                                    fieldAddendums?.[
                                        `relevant_medical_history-${noteContent.family[i].family_id}`
                                    ]
                                }
                                content={
                                    family.relevant_medical_history || "--"
                                }
                            />
                        </React.Fragment>
                    )
                )}
            {noteContent.family &&
                noteContent.family.map(
                    (family: FamilyIntakeNotePayload, i: number) => (
                        <React.Fragment key={`${i + 1}`}>
                            <p className={styles.sectionheader}>
                                Client {i + 1}{" "}
                                {family.name ? `- ${family.name}` : ""}
                            </p>
                            <p
                                className={styles.sectionheader}
                                id={INTAKE_SECTIONS["suicide risk assessment"]}
                            >
                                Suicide risk assessment
                            </p>
                            <NoteContentItem
                                headerText={`1. ${FAMILY_INTAKE_FAMILY_NOTE_DETAILS.been_so_distressed_you_seriously_wished_to_end_your_life}`}
                                fieldId={`been_so_distressed_you_seriously_wished_to_end_your_life-${noteContent.family[i].family_id}`}
                                addendums={
                                    fieldAddendums?.[
                                        `been_so_distressed_you_seriously_wished_to_end_your_life-${noteContent.family[i].family_id}`
                                    ]
                                }
                                content={
                                    family.been_so_distressed_you_seriously_wished_to_end_your_life ||
                                    "--"
                                }
                            />
                            <NoteContentItem
                                headerText={`2. ${FAMILY_INTAKE_FAMILY_NOTE_DETAILS.has_anything_happened_recently_to_make_you_feel_you_dont_want_to_live}`}
                                fieldId={`has_anything_happened_recently_to_make_you_feel_you_dont_want_to_live-${noteContent.family[i].family_id}`}
                                addendums={
                                    fieldAddendums?.[
                                        `has_anything_happened_recently_to_make_you_feel_you_dont_want_to_live-${noteContent.family[i].family_id}`
                                    ]
                                }
                                content={
                                    family.has_anything_happened_recently_to_make_you_feel_you_dont_want_to_live ||
                                    "--"
                                }
                            />
                            <NoteContentItem
                                headerText={
                                    FAMILY_INTAKE_FAMILY_NOTE_DETAILS.describe_has_anything_happened_recently_to_make_you_feel_you_dont_want_to_live
                                }
                                fieldId={`describe_has_anything_happened_recently_to_make_you_feel_you_dont_want_to_live-${noteContent.family[i].family_id}`}
                                addendums={
                                    fieldAddendums?.[
                                        `describe_has_anything_happened_recently_to_make_you_feel_you_dont_want_to_live-${noteContent.family[i].family_id}`
                                    ]
                                }
                                content={
                                    family.describe_has_anything_happened_recently_to_make_you_feel_you_dont_want_to_live ||
                                    "--"
                                }
                            />
                            <NoteContentItem
                                headerText={`3. ${FAMILY_INTAKE_FAMILY_NOTE_DETAILS.a_specific_plan_of_how_you_would_kill_yourself}`}
                                fieldId={`a_specific_plan_of_how_you_would_kill_yourself-${noteContent.family[i].family_id}`}
                                addendums={
                                    fieldAddendums?.[
                                        `a_specific_plan_of_how_you_would_kill_yourself-${noteContent.family[i].family_id}`
                                    ]
                                }
                                content={
                                    family.a_specific_plan_of_how_you_would_kill_yourself ||
                                    "--"
                                }
                            />
                            <NoteContentItem
                                headerText={`4. ${FAMILY_INTAKE_FAMILY_NOTE_DETAILS.access_to_weapons_or_means_of_hurting_self}`}
                                fieldId={`access_to_weapons_or_means_of_hurting_self-${noteContent.family[i].family_id}`}
                                addendums={
                                    fieldAddendums?.[
                                        `access_to_weapons_or_means_of_hurting_self-${noteContent.family[i].family_id}`
                                    ]
                                }
                                content={
                                    family.access_to_weapons_or_means_of_hurting_self ||
                                    "--"
                                }
                            />
                            <NoteContentItem
                                headerText={`5. ${FAMILY_INTAKE_FAMILY_NOTE_DETAILS.made_serious_suicide_attempts}`}
                                fieldId={`made_serious_suicide_attempts-${noteContent.family[i].family_id}`}
                                addendums={
                                    fieldAddendums?.[
                                        `made_serious_suicide_attempts-${noteContent.family[i].family_id}`
                                    ]
                                }
                                content={
                                    family.made_serious_suicide_attempts || "--"
                                }
                            />
                            <NoteContentItem
                                headerText={`6. ${FAMILY_INTAKE_FAMILY_NOTE_DETAILS.purposely_done_something_to_hurt_yourself}`}
                                fieldId={`purposely_done_something_to_hurt_yourself-${noteContent.family[i].family_id}`}
                                addendums={
                                    fieldAddendums?.[
                                        `purposely_done_something_to_hurt_yourself-${noteContent.family[i].family_id}`
                                    ]
                                }
                                content={
                                    family.purposely_done_something_to_hurt_yourself ||
                                    "--"
                                }
                            />
                            <NoteContentItem
                                headerText={
                                    FAMILY_INTAKE_FAMILY_NOTE_DETAILS.describe_done_something_to_hurt_yourself
                                }
                                fieldId={`describe_done_something_to_hurt_yourself-${noteContent.family[i].family_id}`}
                                addendums={
                                    fieldAddendums?.[
                                        `describe_done_something_to_hurt_yourself-${noteContent.family[i].family_id}`
                                    ]
                                }
                                content={
                                    family.describe_done_something_to_hurt_yourself ||
                                    "--"
                                }
                            />
                            <NoteContentItem
                                headerText={`7. ${FAMILY_INTAKE_FAMILY_NOTE_DETAILS.heard_voices_telling_you_to_hurt_yourself}`}
                                fieldId={`heard_voices_telling_you_to_hurt_yourself-${noteContent.family[i].family_id}`}
                                addendums={
                                    fieldAddendums?.[
                                        `heard_voices_telling_you_to_hurt_yourself-${noteContent.family[i].family_id}`
                                    ]
                                }
                                content={
                                    family.heard_voices_telling_you_to_hurt_yourself ||
                                    "--"
                                }
                            />
                            <NoteContentItem
                                headerText={`8. ${FAMILY_INTAKE_FAMILY_NOTE_DETAILS.had_relatives_who_attempted_or_commited_sucide}`}
                                fieldId={`had_relatives_who_attempted_or_commited_sucide-${noteContent.family[i].family_id}`}
                                addendums={
                                    fieldAddendums?.[
                                        `had_relatives_who_attempted_or_commited_sucide-${noteContent.family[i].family_id}`
                                    ]
                                }
                                content={
                                    family.had_relatives_who_attempted_or_commited_sucide ||
                                    "--"
                                }
                            />
                            <NoteContentItem
                                headerText={`9. ${FAMILY_INTAKE_FAMILY_NOTE_DETAILS.had_thoughts_of_killing_or_seriously_hurting_someone}`}
                                fieldId={`had_thoughts_of_killing_or_seriously_hurting_someone-${noteContent.family[i].family_id}`}
                                addendums={
                                    fieldAddendums?.[
                                        `had_thoughts_of_killing_or_seriously_hurting_someone-${noteContent.family[i].family_id}`
                                    ]
                                }
                                content={
                                    family.had_thoughts_of_killing_or_seriously_hurting_someone ||
                                    "--"
                                }
                            />

                            <NoteContentItem
                                headerText={`10. ${FAMILY_INTAKE_FAMILY_NOTE_DETAILS.heard_voices_telling_you_to_hurt_others}`}
                                fieldId={`heard_voices_telling_you_to_hurt_others-${noteContent.family[i].family_id}`}
                                addendums={
                                    fieldAddendums?.[
                                        `heard_voices_telling_you_to_hurt_others-${noteContent.family[i].family_id}`
                                    ]
                                }
                                content={
                                    family.heard_voices_telling_you_to_hurt_others ||
                                    "--"
                                }
                            />
                            <NoteContentItem
                                headerText={`11. ${FAMILY_INTAKE_FAMILY_NOTE_DETAILS.hurt_someone_or_destroyed_property_on_purpose}`}
                                fieldId={`hurt_someone_or_destroyed_property_on_purpose-${noteContent.family[i].family_id}`}
                                addendums={
                                    fieldAddendums?.[
                                        `hurt_someone_or_destroyed_property_on_purpose-${noteContent.family[i].family_id}`
                                    ]
                                }
                                content={
                                    family.hurt_someone_or_destroyed_property_on_purpose ||
                                    "--"
                                }
                            />
                            <NoteContentItem
                                headerText={`12. ${FAMILY_INTAKE_FAMILY_NOTE_DETAILS.slapped_kicked_punched_someone_with_intent_to_harm}`}
                                fieldId={`slapped_kicked_punched_someone_with_intent_to_harm-${noteContent.family[i].family_id}`}
                                addendums={
                                    fieldAddendums?.[
                                        `slapped_kicked_punched_someone_with_intent_to_harm-${noteContent.family[i].family_id}`
                                    ]
                                }
                                content={
                                    family.slapped_kicked_punched_someone_with_intent_to_harm ||
                                    "--"
                                }
                            />
                            <NoteContentItem
                                headerText={`13. ${FAMILY_INTAKE_FAMILY_NOTE_DETAILS.been_arrested_or_detained_for_violent_behavior}`}
                                fieldId={`been_arrested_or_detained_for_violent_behavior-${noteContent.family[i].family_id}`}
                                addendums={
                                    fieldAddendums?.[
                                        `been_arrested_or_detained_for_violent_behavior-${noteContent.family[i].family_id}`
                                    ]
                                }
                                content={
                                    family.been_arrested_or_detained_for_violent_behavior ||
                                    "--"
                                }
                            />
                            <NoteContentItem
                                headerText={`14. ${FAMILY_INTAKE_FAMILY_NOTE_DETAILS.been_to_jail_for_any_reason}`}
                                fieldId={`been_to_jail_for_any_reason-${noteContent.family[i].family_id}`}
                                addendums={
                                    fieldAddendums?.[
                                        `been_to_jail_for_any_reason-${noteContent.family[i].family_id}`
                                    ]
                                }
                                content={
                                    family.been_to_jail_for_any_reason || "--"
                                }
                            />
                            <NoteContentItem
                                headerText={`15. ${FAMILY_INTAKE_FAMILY_NOTE_DETAILS.been_on_probation_for_any_reason}`}
                                fieldId={`been_on_probation_for_any_reason-${noteContent.family[i].family_id}`}
                                addendums={
                                    fieldAddendums?.[
                                        `been_on_probation_for_any_reason-${noteContent.family[i].family_id}`
                                    ]
                                }
                                content={
                                    family.been_on_probation_for_any_reason ||
                                    "--"
                                }
                            />
                            <NoteContentItem
                                headerText={`16. ${FAMILY_INTAKE_FAMILY_NOTE_DETAILS.do_you_have_access_to_guns}`}
                                fieldId={`do_you_have_access_to_guns-${noteContent.family[i].family_id}`}
                                addendums={
                                    fieldAddendums?.[
                                        `do_you_have_access_to_guns-${noteContent.family[i].family_id}`
                                    ]
                                }
                                content={
                                    family.do_you_have_access_to_guns || "--"
                                }
                            />
                            <NoteContentItem
                                headerText={`16. ${FAMILY_INTAKE_FAMILY_NOTE_DETAILS.describe_do_you_have_access_to_guns}`}
                                fieldId={`describe_do_you_have_access_to_guns-${noteContent.family[i].family_id}`}
                                addendums={
                                    fieldAddendums?.[
                                        `describe_do_you_have_access_to_guns-${noteContent.family[i].family_id}`
                                    ]
                                }
                                content={
                                    family.describe_do_you_have_access_to_guns ||
                                    "--"
                                }
                            />
                        </React.Fragment>
                    )
                )}
            {noteContent.family &&
                noteContent.family.map(
                    (family: FamilyIntakeNotePayload, i: number) => (
                        <React.Fragment key={`${i + 1}`}>
                            <p className={styles.sectionheader}>
                                Client {i + 1}{" "}
                                {family.name ? `- ${family.name}` : ""}
                            </p>
                            <p
                                className={styles.sectionheader}
                                id={INTAKE_SECTIONS["mental status exam"]}
                            >
                                Mental status exam
                            </p>
                            <NoteContentItem
                                headerText={
                                    FAMILY_INTAKE_FAMILY_NOTE_DETAILS.mental_health_appearance
                                }
                                fieldId={`mental_health_appearance-${noteContent.family[i].family_id}`}
                                addendums={
                                    fieldAddendums?.[
                                        `mental_health_appearance-${noteContent.family[i].family_id}`
                                    ]
                                }
                                content={
                                    family?.mental_health_appearance?.length >
                                    0 ? (
                                        <div className={styles.checkboxes}>
                                            {isCheckboxChecked(
                                                family.mental_health_appearance,
                                                "Neat"
                                            ) && (
                                                <div
                                                    className={styles.checkbox}
                                                >
                                                    <span
                                                        className={cn(
                                                            styles.checkbox_wrap,
                                                            {
                                                                [styles.checked]:
                                                                    isCheckboxChecked(
                                                                        family.mental_health_appearance,
                                                                        "Neat"
                                                                    ),
                                                            }
                                                        )}
                                                    >
                                                        <CheckIcon />
                                                    </span>
                                                    Neat
                                                </div>
                                            )}
                                            {isCheckboxChecked(
                                                family.mental_health_appearance,
                                                "Unkempt"
                                            ) && (
                                                <div
                                                    className={styles.checkbox}
                                                >
                                                    <span
                                                        className={cn(
                                                            styles.checkbox_wrap,
                                                            {
                                                                [styles.checked]:
                                                                    isCheckboxChecked(
                                                                        family.mental_health_appearance,
                                                                        "Unkempt"
                                                                    ),
                                                            }
                                                        )}
                                                    >
                                                        <CheckIcon />
                                                    </span>
                                                    Unkempt
                                                </div>
                                            )}
                                            {isCheckboxChecked(
                                                family.mental_health_appearance,
                                                "Thin"
                                            ) && (
                                                <div
                                                    className={styles.checkbox}
                                                >
                                                    <span
                                                        className={cn(
                                                            styles.checkbox_wrap,
                                                            {
                                                                [styles.checked]:
                                                                    isCheckboxChecked(
                                                                        family.mental_health_appearance,
                                                                        "Thin"
                                                                    ),
                                                            }
                                                        )}
                                                    >
                                                        <CheckIcon />
                                                    </span>
                                                    Thin
                                                </div>
                                            )}
                                            {isCheckboxChecked(
                                                family.mental_health_appearance,
                                                "Average"
                                            ) && (
                                                <div
                                                    className={styles.checkbox}
                                                >
                                                    <span
                                                        className={cn(
                                                            styles.checkbox_wrap,
                                                            {
                                                                [styles.checked]:
                                                                    isCheckboxChecked(
                                                                        family.mental_health_appearance,
                                                                        "Average"
                                                                    ),
                                                            }
                                                        )}
                                                    >
                                                        <CheckIcon />
                                                    </span>
                                                    Average
                                                </div>
                                            )}
                                            {isCheckboxChecked(
                                                family.mental_health_appearance,
                                                "Overweight"
                                            ) && (
                                                <div
                                                    className={styles.checkbox}
                                                >
                                                    <span
                                                        className={cn(
                                                            styles.checkbox_wrap,
                                                            {
                                                                [styles.checked]:
                                                                    isCheckboxChecked(
                                                                        family.mental_health_appearance,
                                                                        "Overweight"
                                                                    ),
                                                            }
                                                        )}
                                                    >
                                                        <CheckIcon />
                                                    </span>
                                                    Overweight
                                                </div>
                                            )}
                                            {isCheckboxChecked(
                                                family.mental_health_appearance,
                                                "Pale"
                                            ) && (
                                                <div
                                                    className={styles.checkbox}
                                                >
                                                    <span
                                                        className={cn(
                                                            styles.checkbox_wrap,
                                                            {
                                                                [styles.checked]:
                                                                    isCheckboxChecked(
                                                                        family.mental_health_appearance,
                                                                        "Pale"
                                                                    ),
                                                            }
                                                        )}
                                                    >
                                                        <CheckIcon />
                                                    </span>
                                                    Pale
                                                </div>
                                            )}
                                            {isCheckboxChecked(
                                                family.mental_health_appearance,
                                                "Tanned"
                                            ) && (
                                                <div
                                                    className={styles.checkbox}
                                                >
                                                    <span
                                                        className={cn(
                                                            styles.checkbox_wrap,
                                                            {
                                                                [styles.checked]:
                                                                    isCheckboxChecked(
                                                                        family.mental_health_appearance,
                                                                        "Tanned"
                                                                    ),
                                                            }
                                                        )}
                                                    >
                                                        <CheckIcon />
                                                    </span>
                                                    Tanned
                                                </div>
                                            )}
                                        </div>
                                    ) : (
                                        "--"
                                    )
                                }
                            />
                            <NoteContentItem
                                headerText={
                                    FAMILY_INTAKE_FAMILY_NOTE_DETAILS.mental_health_movement
                                }
                                fieldId={`mental_health_movement-${noteContent.family[i].family_id}`}
                                addendums={
                                    fieldAddendums?.[
                                        `mental_health_movement-${noteContent.family[i].family_id}`
                                    ]
                                }
                                content={
                                    family?.mental_health_movement?.length >
                                    0 ? (
                                        <div className={styles.checkboxes}>
                                            {isCheckboxChecked(
                                                family.mental_health_movement,
                                                "Coordinated"
                                            ) && (
                                                <div
                                                    className={styles.checkbox}
                                                >
                                                    <span
                                                        className={cn(
                                                            styles.checkbox_wrap,
                                                            {
                                                                [styles.checked]:
                                                                    isCheckboxChecked(
                                                                        family.mental_health_movement,
                                                                        "Coordinated"
                                                                    ),
                                                            }
                                                        )}
                                                    >
                                                        <CheckIcon />
                                                    </span>
                                                    Coordinated
                                                </div>
                                            )}
                                            {isCheckboxChecked(
                                                family.mental_health_movement,
                                                "Uncoordinated"
                                            ) && (
                                                <div
                                                    className={styles.checkbox}
                                                >
                                                    <span
                                                        className={cn(
                                                            styles.checkbox_wrap,
                                                            {
                                                                [styles.checked]:
                                                                    isCheckboxChecked(
                                                                        family.mental_health_movement,
                                                                        "Uncoordinated"
                                                                    ),
                                                            }
                                                        )}
                                                    >
                                                        <CheckIcon />
                                                    </span>
                                                    Uncoordinated
                                                </div>
                                            )}
                                            {isCheckboxChecked(
                                                family.mental_health_movement,
                                                "Mannerisms/oddities"
                                            ) && (
                                                <div
                                                    className={styles.checkbox}
                                                >
                                                    <span
                                                        className={cn(
                                                            styles.checkbox_wrap,
                                                            {
                                                                [styles.checked]:
                                                                    isCheckboxChecked(
                                                                        family.mental_health_movement,
                                                                        "Mannerisms/oddities"
                                                                    ),
                                                            }
                                                        )}
                                                    >
                                                        <CheckIcon />
                                                    </span>
                                                    Mannerisms/oddities
                                                </div>
                                            )}
                                            {isCheckboxChecked(
                                                family.mental_health_movement,
                                                "Good eye contact"
                                            ) && (
                                                <div
                                                    className={styles.checkbox}
                                                >
                                                    <span
                                                        className={cn(
                                                            styles.checkbox_wrap,
                                                            {
                                                                [styles.checked]:
                                                                    isCheckboxChecked(
                                                                        family.mental_health_movement,
                                                                        "Good eye contact"
                                                                    ),
                                                            }
                                                        )}
                                                    >
                                                        <CheckIcon />
                                                    </span>
                                                    Good eye contact
                                                </div>
                                            )}
                                            {isCheckboxChecked(
                                                family.mental_health_movement,
                                                "Kept eyes downcast"
                                            ) && (
                                                <div
                                                    className={styles.checkbox}
                                                >
                                                    <span
                                                        className={cn(
                                                            styles.checkbox_wrap,
                                                            {
                                                                [styles.checked]:
                                                                    isCheckboxChecked(
                                                                        family.mental_health_movement,
                                                                        "Kept eyes downcast"
                                                                    ),
                                                            }
                                                        )}
                                                    >
                                                        <CheckIcon />
                                                    </span>
                                                    Kept eyes downcast
                                                </div>
                                            )}
                                            {isCheckboxChecked(
                                                family.mental_health_movement,
                                                "Stared into space"
                                            ) && (
                                                <div
                                                    className={styles.checkbox}
                                                >
                                                    <span
                                                        className={cn(
                                                            styles.checkbox_wrap,
                                                            {
                                                                [styles.checked]:
                                                                    isCheckboxChecked(
                                                                        family.mental_health_movement,
                                                                        "Stared into space"
                                                                    ),
                                                            }
                                                        )}
                                                    >
                                                        <CheckIcon />
                                                    </span>
                                                    Stared into space
                                                </div>
                                            )}
                                        </div>
                                    ) : (
                                        "--"
                                    )
                                }
                            />
                            <NoteContentItem
                                headerText={
                                    FAMILY_INTAKE_FAMILY_NOTE_DETAILS.mental_health_speech_behaviour
                                }
                                fieldId={`mental_health_speech_behaviour-${noteContent.family[i].family_id}`}
                                addendums={
                                    fieldAddendums?.[
                                        `mental_health_speech_behaviour-${noteContent.family[i].family_id}`
                                    ]
                                }
                                content={
                                    family?.mental_health_speech_behaviour
                                        ?.length > 0 ? (
                                        <div className={styles.checkboxes}>
                                            {isCheckboxChecked(
                                                family.mental_health_speech_behaviour,
                                                "Unintelligible"
                                            ) && (
                                                <div
                                                    className={styles.checkbox}
                                                >
                                                    <span
                                                        className={cn(
                                                            styles.checkbox_wrap,
                                                            {
                                                                [styles.checked]:
                                                                    isCheckboxChecked(
                                                                        family.mental_health_speech_behaviour,
                                                                        "Unintelligible"
                                                                    ),
                                                            }
                                                        )}
                                                    >
                                                        <CheckIcon />
                                                    </span>
                                                    Unintelligible
                                                </div>
                                            )}
                                            {isCheckboxChecked(
                                                family.mental_health_speech_behaviour,
                                                "Stammer/stutter"
                                            ) && (
                                                <div
                                                    className={styles.checkbox}
                                                >
                                                    <span
                                                        className={cn(
                                                            styles.checkbox_wrap,
                                                            {
                                                                [styles.checked]:
                                                                    isCheckboxChecked(
                                                                        family.mental_health_speech_behaviour,
                                                                        "Stammer/stutter"
                                                                    ),
                                                            }
                                                        )}
                                                    >
                                                        <CheckIcon />
                                                    </span>
                                                    Stammer/stutter
                                                </div>
                                            )}
                                            {isCheckboxChecked(
                                                family.mental_health_speech_behaviour,
                                                "Mumbles"
                                            ) && (
                                                <div
                                                    className={styles.checkbox}
                                                >
                                                    <span
                                                        className={cn(
                                                            styles.checkbox_wrap,
                                                            {
                                                                [styles.checked]:
                                                                    isCheckboxChecked(
                                                                        family.mental_health_speech_behaviour,
                                                                        "Mumbles"
                                                                    ),
                                                            }
                                                        )}
                                                    >
                                                        <CheckIcon />
                                                    </span>
                                                    Mumbles
                                                </div>
                                            )}
                                            {isCheckboxChecked(
                                                family.mental_health_speech_behaviour,
                                                "Clear"
                                            ) && (
                                                <div
                                                    className={styles.checkbox}
                                                >
                                                    <span
                                                        className={cn(
                                                            styles.checkbox_wrap,
                                                            {
                                                                [styles.checked]:
                                                                    isCheckboxChecked(
                                                                        family.mental_health_speech_behaviour,
                                                                        "Clear"
                                                                    ),
                                                            }
                                                        )}
                                                    >
                                                        <CheckIcon />
                                                    </span>
                                                    Clear
                                                </div>
                                            )}
                                            {isCheckboxChecked(
                                                family.mental_health_speech_behaviour,
                                                "Precise"
                                            ) && (
                                                <div
                                                    className={styles.checkbox}
                                                >
                                                    <span
                                                        className={cn(
                                                            styles.checkbox_wrap,
                                                            {
                                                                [styles.checked]:
                                                                    isCheckboxChecked(
                                                                        family.mental_health_speech_behaviour,
                                                                        "Precise"
                                                                    ),
                                                            }
                                                        )}
                                                    >
                                                        <CheckIcon />
                                                    </span>
                                                    Precise
                                                </div>
                                            )}
                                            {isCheckboxChecked(
                                                family.mental_health_speech_behaviour,
                                                "Strident"
                                            ) && (
                                                <div
                                                    className={styles.checkbox}
                                                >
                                                    <span
                                                        className={cn(
                                                            styles.checkbox_wrap,
                                                            {
                                                                [styles.checked]:
                                                                    isCheckboxChecked(
                                                                        family.mental_health_speech_behaviour,
                                                                        "Strident"
                                                                    ),
                                                            }
                                                        )}
                                                    >
                                                        <CheckIcon />
                                                    </span>
                                                    Strident
                                                </div>
                                            )}
                                            {isCheckboxChecked(
                                                family.mental_health_speech_behaviour,
                                                "Distant"
                                            ) && (
                                                <div
                                                    className={styles.checkbox}
                                                >
                                                    <span
                                                        className={cn(
                                                            styles.checkbox_wrap,
                                                            {
                                                                [styles.checked]:
                                                                    isCheckboxChecked(
                                                                        family.mental_health_speech_behaviour,
                                                                        "Distant"
                                                                    ),
                                                            }
                                                        )}
                                                    >
                                                        <CheckIcon />
                                                    </span>
                                                    Distant
                                                </div>
                                            )}
                                            {isCheckboxChecked(
                                                family.mental_health_speech_behaviour,
                                                "Squeaky"
                                            ) && (
                                                <div
                                                    className={styles.checkbox}
                                                >
                                                    <span
                                                        className={cn(
                                                            styles.checkbox_wrap,
                                                            {
                                                                [styles.checked]:
                                                                    isCheckboxChecked(
                                                                        family.mental_health_speech_behaviour,
                                                                        "Squeaky"
                                                                    ),
                                                            }
                                                        )}
                                                    >
                                                        <CheckIcon />
                                                    </span>
                                                    Squeaky
                                                </div>
                                            )}
                                            {isCheckboxChecked(
                                                family.mental_health_speech_behaviour,
                                                "Soft"
                                            ) && (
                                                <div
                                                    className={styles.checkbox}
                                                >
                                                    <span
                                                        className={cn(
                                                            styles.checkbox_wrap,
                                                            {
                                                                [styles.checked]:
                                                                    isCheckboxChecked(
                                                                        family.mental_health_speech_behaviour,
                                                                        "Soft"
                                                                    ),
                                                            }
                                                        )}
                                                    >
                                                        <CheckIcon />
                                                    </span>
                                                    Soft
                                                </div>
                                            )}
                                            {isCheckboxChecked(
                                                family.mental_health_speech_behaviour,
                                                "Weak"
                                            ) && (
                                                <div
                                                    className={styles.checkbox}
                                                >
                                                    <span
                                                        className={cn(
                                                            styles.checkbox_wrap,
                                                            {
                                                                [styles.checked]:
                                                                    isCheckboxChecked(
                                                                        family.mental_health_speech_behaviour,
                                                                        "Weak"
                                                                    ),
                                                            }
                                                        )}
                                                    >
                                                        <CheckIcon />
                                                    </span>
                                                    Weak
                                                </div>
                                            )}
                                            {isCheckboxChecked(
                                                family.mental_health_speech_behaviour,
                                                "Normal"
                                            ) && (
                                                <div
                                                    className={styles.checkbox}
                                                >
                                                    <span
                                                        className={cn(
                                                            styles.checkbox_wrap,
                                                            {
                                                                [styles.checked]:
                                                                    isCheckboxChecked(
                                                                        family.mental_health_speech_behaviour,
                                                                        "Normal"
                                                                    ),
                                                            }
                                                        )}
                                                    >
                                                        <CheckIcon />
                                                    </span>
                                                    Normal
                                                </div>
                                            )}
                                            {isCheckboxChecked(
                                                family.mental_health_speech_behaviour,
                                                "Hurried"
                                            ) && (
                                                <div
                                                    className={styles.checkbox}
                                                >
                                                    <span
                                                        className={cn(
                                                            styles.checkbox_wrap,
                                                            {
                                                                [styles.checked]:
                                                                    isCheckboxChecked(
                                                                        family.mental_health_speech_behaviour,
                                                                        "Hurried"
                                                                    ),
                                                            }
                                                        )}
                                                    >
                                                        <CheckIcon />
                                                    </span>
                                                    Hurried
                                                </div>
                                            )}
                                            {isCheckboxChecked(
                                                family.mental_health_speech_behaviour,
                                                "Loud"
                                            ) && (
                                                <div
                                                    className={styles.checkbox}
                                                >
                                                    <span
                                                        className={cn(
                                                            styles.checkbox_wrap,
                                                            {
                                                                [styles.checked]:
                                                                    isCheckboxChecked(
                                                                        family.mental_health_speech_behaviour,
                                                                        "Loud"
                                                                    ),
                                                            }
                                                        )}
                                                    >
                                                        <CheckIcon />
                                                    </span>
                                                    Loud
                                                </div>
                                            )}
                                        </div>
                                    ) : (
                                        "--"
                                    )
                                }
                            />
                            <NoteContentItem
                                headerText={
                                    FAMILY_INTAKE_FAMILY_NOTE_DETAILS.mental_health_affect_mood
                                }
                                fieldId={`mental_health_affect_mood-${noteContent.family[i].family_id}`}
                                addendums={
                                    fieldAddendums?.[
                                        `mental_health_affect_mood-${noteContent.family[i].family_id}`
                                    ]
                                }
                                content={
                                    family?.mental_health_affect_mood?.length >
                                    0 ? (
                                        <div className={styles.checkboxes}>
                                            {isCheckboxChecked(
                                                family.mental_health_affect_mood,
                                                "Blunted"
                                            ) && (
                                                <div
                                                    className={styles.checkbox}
                                                >
                                                    <span
                                                        className={cn(
                                                            styles.checkbox_wrap,
                                                            {
                                                                [styles.checked]:
                                                                    isCheckboxChecked(
                                                                        family.mental_health_affect_mood,
                                                                        "Blunted"
                                                                    ),
                                                            }
                                                        )}
                                                    >
                                                        <CheckIcon />
                                                    </span>
                                                    Blunted
                                                </div>
                                            )}
                                            {isCheckboxChecked(
                                                family.mental_health_affect_mood,
                                                "Constricted"
                                            ) && (
                                                <div
                                                    className={styles.checkbox}
                                                >
                                                    <span
                                                        className={cn(
                                                            styles.checkbox_wrap,
                                                            {
                                                                [styles.checked]:
                                                                    isCheckboxChecked(
                                                                        family.mental_health_affect_mood,
                                                                        "Constricted"
                                                                    ),
                                                            }
                                                        )}
                                                    >
                                                        <CheckIcon />
                                                    </span>
                                                    Constricted
                                                </div>
                                            )}
                                            {isCheckboxChecked(
                                                family.mental_health_affect_mood,
                                                "Normal"
                                            ) && (
                                                <div
                                                    className={styles.checkbox}
                                                >
                                                    <span
                                                        className={cn(
                                                            styles.checkbox_wrap,
                                                            {
                                                                [styles.checked]:
                                                                    isCheckboxChecked(
                                                                        family.mental_health_affect_mood,
                                                                        "Normal"
                                                                    ),
                                                            }
                                                        )}
                                                    >
                                                        <CheckIcon />
                                                    </span>
                                                    Normal
                                                </div>
                                            )}
                                            {isCheckboxChecked(
                                                family.mental_health_affect_mood,
                                                "Broad"
                                            ) && (
                                                <div
                                                    className={styles.checkbox}
                                                >
                                                    <span
                                                        className={cn(
                                                            styles.checkbox_wrap,
                                                            {
                                                                [styles.checked]:
                                                                    isCheckboxChecked(
                                                                        family.mental_health_affect_mood,
                                                                        "Broad"
                                                                    ),
                                                            }
                                                        )}
                                                    >
                                                        <CheckIcon />
                                                    </span>
                                                    Broad
                                                </div>
                                            )}
                                            {isCheckboxChecked(
                                                family.mental_health_affect_mood,
                                                "Inappropriate"
                                            ) && (
                                                <div
                                                    className={styles.checkbox}
                                                >
                                                    <span
                                                        className={cn(
                                                            styles.checkbox_wrap,
                                                            {
                                                                [styles.checked]:
                                                                    isCheckboxChecked(
                                                                        family.mental_health_affect_mood,
                                                                        "Inappropriate"
                                                                    ),
                                                            }
                                                        )}
                                                    >
                                                        <CheckIcon />
                                                    </span>
                                                    Inappropriate
                                                </div>
                                            )}
                                            {isCheckboxChecked(
                                                family.mental_health_affect_mood,
                                                "Indifferent to problems"
                                            ) && (
                                                <div
                                                    className={styles.checkbox}
                                                >
                                                    <span
                                                        className={cn(
                                                            styles.checkbox_wrap,
                                                            {
                                                                [styles.checked]:
                                                                    isCheckboxChecked(
                                                                        family.mental_health_affect_mood,
                                                                        "Indifferent to problems"
                                                                    ),
                                                            }
                                                        )}
                                                    >
                                                        <CheckIcon />
                                                    </span>
                                                    Indifferent to problems
                                                </div>
                                            )}
                                            {isCheckboxChecked(
                                                family.mental_health_affect_mood,
                                                "Congruent"
                                            ) && (
                                                <div
                                                    className={styles.checkbox}
                                                >
                                                    <span
                                                        className={cn(
                                                            styles.checkbox_wrap,
                                                            {
                                                                [styles.checked]:
                                                                    isCheckboxChecked(
                                                                        family.mental_health_affect_mood,
                                                                        "Congruent"
                                                                    ),
                                                            }
                                                        )}
                                                    >
                                                        <CheckIcon />
                                                    </span>
                                                    Congruent
                                                </div>
                                            )}
                                            {isCheckboxChecked(
                                                family.mental_health_affect_mood,
                                                "Irritated"
                                            ) && (
                                                <div
                                                    className={styles.checkbox}
                                                >
                                                    <span
                                                        className={cn(
                                                            styles.checkbox_wrap,
                                                            {
                                                                [styles.checked]:
                                                                    isCheckboxChecked(
                                                                        family.mental_health_affect_mood,
                                                                        "Irritated"
                                                                    ),
                                                            }
                                                        )}
                                                    >
                                                        <CheckIcon />
                                                    </span>
                                                    Irritated
                                                </div>
                                            )}
                                            {isCheckboxChecked(
                                                family.mental_health_affect_mood,
                                                "Hostile"
                                            ) && (
                                                <div
                                                    className={styles.checkbox}
                                                >
                                                    <span
                                                        className={cn(
                                                            styles.checkbox_wrap,
                                                            {
                                                                [styles.checked]:
                                                                    isCheckboxChecked(
                                                                        family.mental_health_affect_mood,
                                                                        "Hostile"
                                                                    ),
                                                            }
                                                        )}
                                                    >
                                                        <CheckIcon />
                                                    </span>
                                                    Hostile
                                                </div>
                                            )}
                                            {isCheckboxChecked(
                                                family.mental_health_affect_mood,
                                                "Flushing"
                                            ) && (
                                                <div
                                                    className={styles.checkbox}
                                                >
                                                    <span
                                                        className={cn(
                                                            styles.checkbox_wrap,
                                                            {
                                                                [styles.checked]:
                                                                    isCheckboxChecked(
                                                                        family.mental_health_affect_mood,
                                                                        "Flushing"
                                                                    ),
                                                            }
                                                        )}
                                                    >
                                                        <CheckIcon />
                                                    </span>
                                                    Flushing
                                                </div>
                                            )}
                                            {isCheckboxChecked(
                                                family.mental_health_affect_mood,
                                                "Dizziness"
                                            ) && (
                                                <div
                                                    className={styles.checkbox}
                                                >
                                                    <span
                                                        className={cn(
                                                            styles.checkbox_wrap,
                                                            {
                                                                [styles.checked]:
                                                                    isCheckboxChecked(
                                                                        family.mental_health_affect_mood,
                                                                        "Dizziness"
                                                                    ),
                                                            }
                                                        )}
                                                    >
                                                        <CheckIcon />
                                                    </span>
                                                    Dizziness
                                                </div>
                                            )}
                                            {isCheckboxChecked(
                                                family.mental_health_affect_mood,
                                                "Can't sit still"
                                            ) && (
                                                <div
                                                    className={styles.checkbox}
                                                >
                                                    <span
                                                        className={cn(
                                                            styles.checkbox_wrap,
                                                            {
                                                                [styles.checked]:
                                                                    isCheckboxChecked(
                                                                        family.mental_health_affect_mood,
                                                                        "Can't sit still"
                                                                    ),
                                                            }
                                                        )}
                                                    >
                                                        <CheckIcon />
                                                    </span>
                                                    Can&apos;t sit still
                                                </div>
                                            )}
                                            {isCheckboxChecked(
                                                family.mental_health_affect_mood,
                                                "Panicked"
                                            ) && (
                                                <div
                                                    className={styles.checkbox}
                                                >
                                                    <span
                                                        className={cn(
                                                            styles.checkbox_wrap,
                                                            {
                                                                [styles.checked]:
                                                                    isCheckboxChecked(
                                                                        family.mental_health_affect_mood,
                                                                        "Panicked"
                                                                    ),
                                                            }
                                                        )}
                                                    >
                                                        <CheckIcon />
                                                    </span>
                                                    Panicked
                                                </div>
                                            )}
                                            {isCheckboxChecked(
                                                family.mental_health_affect_mood,
                                                "Fearful"
                                            ) && (
                                                <div
                                                    className={styles.checkbox}
                                                >
                                                    <span
                                                        className={cn(
                                                            styles.checkbox_wrap,
                                                            {
                                                                [styles.checked]:
                                                                    isCheckboxChecked(
                                                                        family.mental_health_affect_mood,
                                                                        "Fearful"
                                                                    ),
                                                            }
                                                        )}
                                                    >
                                                        <CheckIcon />
                                                    </span>
                                                    Fearful
                                                </div>
                                            )}
                                            {isCheckboxChecked(
                                                family.mental_health_affect_mood,
                                                "Wretched"
                                            ) && (
                                                <div
                                                    className={styles.checkbox}
                                                >
                                                    <span
                                                        className={cn(
                                                            styles.checkbox_wrap,
                                                            {
                                                                [styles.checked]:
                                                                    isCheckboxChecked(
                                                                        family.mental_health_affect_mood,
                                                                        "Wretched"
                                                                    ),
                                                            }
                                                        )}
                                                    >
                                                        <CheckIcon />
                                                    </span>
                                                    Wretched
                                                </div>
                                            )}
                                            {isCheckboxChecked(
                                                family.mental_health_affect_mood,
                                                "Melancholy"
                                            ) && (
                                                <div
                                                    className={styles.checkbox}
                                                >
                                                    <span
                                                        className={cn(
                                                            styles.checkbox_wrap,
                                                            {
                                                                [styles.checked]:
                                                                    isCheckboxChecked(
                                                                        family.mental_health_affect_mood,
                                                                        "Melancholy"
                                                                    ),
                                                            }
                                                        )}
                                                    >
                                                        <CheckIcon />
                                                    </span>
                                                    Melancholy
                                                </div>
                                            )}
                                            {isCheckboxChecked(
                                                family.mental_health_affect_mood,
                                                "Sad"
                                            ) && (
                                                <div
                                                    className={styles.checkbox}
                                                >
                                                    <span
                                                        className={cn(
                                                            styles.checkbox_wrap,
                                                            {
                                                                [styles.checked]:
                                                                    isCheckboxChecked(
                                                                        family.mental_health_affect_mood,
                                                                        "Sad"
                                                                    ),
                                                            }
                                                        )}
                                                    >
                                                        <CheckIcon />
                                                    </span>
                                                    Sad
                                                </div>
                                            )}
                                            {isCheckboxChecked(
                                                family.mental_health_affect_mood,
                                                "Hopeless"
                                            ) && (
                                                <div
                                                    className={styles.checkbox}
                                                >
                                                    <span
                                                        className={cn(
                                                            styles.checkbox_wrap,
                                                            {
                                                                [styles.checked]:
                                                                    isCheckboxChecked(
                                                                        family.mental_health_affect_mood,
                                                                        "Hopeless"
                                                                    ),
                                                            }
                                                        )}
                                                    >
                                                        <CheckIcon />
                                                    </span>
                                                    Hopeless
                                                </div>
                                            )}
                                            {isCheckboxChecked(
                                                family.mental_health_affect_mood,
                                                "Bored"
                                            ) && (
                                                <div
                                                    className={styles.checkbox}
                                                >
                                                    <span
                                                        className={cn(
                                                            styles.checkbox_wrap,
                                                            {
                                                                [styles.checked]:
                                                                    isCheckboxChecked(
                                                                        family.mental_health_affect_mood,
                                                                        "Bored"
                                                                    ),
                                                            }
                                                        )}
                                                    >
                                                        <CheckIcon />
                                                    </span>
                                                    Bored
                                                </div>
                                            )}
                                            {isCheckboxChecked(
                                                family.mental_health_affect_mood,
                                                "Sorrow"
                                            ) && (
                                                <div
                                                    className={styles.checkbox}
                                                >
                                                    <span
                                                        className={cn(
                                                            styles.checkbox_wrap,
                                                            {
                                                                [styles.checked]:
                                                                    isCheckboxChecked(
                                                                        family.mental_health_affect_mood,
                                                                        "Sorrow"
                                                                    ),
                                                            }
                                                        )}
                                                    >
                                                        <CheckIcon />
                                                    </span>
                                                    Sorrow
                                                </div>
                                            )}
                                            {isCheckboxChecked(
                                                family.mental_health_affect_mood,
                                                "Grief"
                                            ) && (
                                                <div
                                                    className={styles.checkbox}
                                                >
                                                    <span
                                                        className={cn(
                                                            styles.checkbox_wrap,
                                                            {
                                                                [styles.checked]:
                                                                    isCheckboxChecked(
                                                                        family.mental_health_affect_mood,
                                                                        "Grief"
                                                                    ),
                                                            }
                                                        )}
                                                    >
                                                        <CheckIcon />
                                                    </span>
                                                    Grief
                                                </div>
                                            )}
                                            {isCheckboxChecked(
                                                family.mental_health_affect_mood,
                                                "Flight of ideas"
                                            ) && (
                                                <div
                                                    className={styles.checkbox}
                                                >
                                                    <span
                                                        className={cn(
                                                            styles.checkbox_wrap,
                                                            {
                                                                [styles.checked]:
                                                                    isCheckboxChecked(
                                                                        family.mental_health_affect_mood,
                                                                        "Flight of ideas"
                                                                    ),
                                                            }
                                                        )}
                                                    >
                                                        <CheckIcon />
                                                    </span>
                                                    Flight of ideas
                                                </div>
                                            )}
                                            {isCheckboxChecked(
                                                family.mental_health_affect_mood,
                                                "Manic"
                                            ) && (
                                                <div
                                                    className={styles.checkbox}
                                                >
                                                    <span
                                                        className={cn(
                                                            styles.checkbox_wrap,
                                                            {
                                                                [styles.checked]:
                                                                    isCheckboxChecked(
                                                                        family.mental_health_affect_mood,
                                                                        "Manic"
                                                                    ),
                                                            }
                                                        )}
                                                    >
                                                        <CheckIcon />
                                                    </span>
                                                    Manic
                                                </div>
                                            )}
                                            {isCheckboxChecked(
                                                family.mental_health_affect_mood,
                                                "Hypomanic"
                                            ) && (
                                                <div
                                                    className={styles.checkbox}
                                                >
                                                    <span
                                                        className={cn(
                                                            styles.checkbox_wrap,
                                                            {
                                                                [styles.checked]:
                                                                    isCheckboxChecked(
                                                                        family.mental_health_affect_mood,
                                                                        "Hypomanic"
                                                                    ),
                                                            }
                                                        )}
                                                    >
                                                        <CheckIcon />
                                                    </span>
                                                    Hypomanic
                                                </div>
                                            )}
                                            {isCheckboxChecked(
                                                family.mental_health_affect_mood,
                                                "Ashamed"
                                            ) && (
                                                <div
                                                    className={styles.checkbox}
                                                >
                                                    <span
                                                        className={cn(
                                                            styles.checkbox_wrap,
                                                            {
                                                                [styles.checked]:
                                                                    isCheckboxChecked(
                                                                        family.mental_health_affect_mood,
                                                                        "Ashamed"
                                                                    ),
                                                            }
                                                        )}
                                                    >
                                                        <CheckIcon />
                                                    </span>
                                                    Ashamed
                                                </div>
                                            )}
                                            {isCheckboxChecked(
                                                family.mental_health_affect_mood,
                                                "Embarrassed"
                                            ) && (
                                                <div
                                                    className={styles.checkbox}
                                                >
                                                    <span
                                                        className={cn(
                                                            styles.checkbox_wrap,
                                                            {
                                                                [styles.checked]:
                                                                    isCheckboxChecked(
                                                                        family.mental_health_affect_mood,
                                                                        "Embarrassed"
                                                                    ),
                                                            }
                                                        )}
                                                    >
                                                        <CheckIcon />
                                                    </span>
                                                    Embarrassed
                                                </div>
                                            )}
                                            {isCheckboxChecked(
                                                family.mental_health_affect_mood,
                                                "Apologetic"
                                            ) && (
                                                <div
                                                    className={styles.checkbox}
                                                >
                                                    <span
                                                        className={cn(
                                                            styles.checkbox_wrap,
                                                            {
                                                                [styles.checked]:
                                                                    isCheckboxChecked(
                                                                        family.mental_health_affect_mood,
                                                                        "Apologetic"
                                                                    ),
                                                            }
                                                        )}
                                                    >
                                                        <CheckIcon />
                                                    </span>
                                                    Apologetic
                                                </div>
                                            )}
                                            {isCheckboxChecked(
                                                family.mental_health_affect_mood,
                                                "Calm"
                                            ) && (
                                                <div
                                                    className={styles.checkbox}
                                                >
                                                    <span
                                                        className={cn(
                                                            styles.checkbox_wrap,
                                                            {
                                                                [styles.checked]:
                                                                    isCheckboxChecked(
                                                                        family.mental_health_affect_mood,
                                                                        "Calm"
                                                                    ),
                                                            }
                                                        )}
                                                    >
                                                        <CheckIcon />
                                                    </span>
                                                    Calm
                                                </div>
                                            )}
                                            {isCheckboxChecked(
                                                family.mental_health_affect_mood,
                                                "Guilty"
                                            ) && (
                                                <div
                                                    className={styles.checkbox}
                                                >
                                                    <span
                                                        className={cn(
                                                            styles.checkbox_wrap,
                                                            {
                                                                [styles.checked]:
                                                                    isCheckboxChecked(
                                                                        family.mental_health_affect_mood,
                                                                        "Guilty"
                                                                    ),
                                                            }
                                                        )}
                                                    >
                                                        <CheckIcon />
                                                    </span>
                                                    Guilty
                                                </div>
                                            )}
                                            {isCheckboxChecked(
                                                family.mental_health_affect_mood,
                                                "Flat"
                                            ) && (
                                                <div
                                                    className={styles.checkbox}
                                                >
                                                    <span
                                                        className={cn(
                                                            styles.checkbox_wrap,
                                                            {
                                                                [styles.checked]:
                                                                    isCheckboxChecked(
                                                                        family.mental_health_affect_mood,
                                                                        "Flat"
                                                                    ),
                                                            }
                                                        )}
                                                    >
                                                        <CheckIcon />
                                                    </span>
                                                    Flat
                                                </div>
                                            )}
                                        </div>
                                    ) : (
                                        "--"
                                    )
                                }
                            />
                            <NoteContentItem
                                headerText={
                                    FAMILY_INTAKE_FAMILY_NOTE_DETAILS.mental_health_attention_concentration_effort
                                }
                                fieldId={`mental_health_attention_concentration_effort-${noteContent.family[i].family_id}`}
                                addendums={
                                    fieldAddendums?.[
                                        `mental_health_attention_concentration_effort-${noteContent.family[i].family_id}`
                                    ]
                                }
                                content={
                                    family
                                        ?.mental_health_attention_concentration_effort
                                        ?.length > 0 ? (
                                        <div className={styles.checkboxes}>
                                            {isCheckboxChecked(
                                                family.mental_health_attention_concentration_effort,
                                                "Sluggish"
                                            ) && (
                                                <div
                                                    className={styles.checkbox}
                                                >
                                                    <span
                                                        className={cn(
                                                            styles.checkbox_wrap,
                                                            {
                                                                [styles.checked]:
                                                                    isCheckboxChecked(
                                                                        family.mental_health_attention_concentration_effort,
                                                                        "Sluggish"
                                                                    ),
                                                            }
                                                        )}
                                                    >
                                                        <CheckIcon />
                                                    </span>
                                                    Sluggish
                                                </div>
                                            )}
                                            {isCheckboxChecked(
                                                family.mental_health_attention_concentration_effort,
                                                "Flat"
                                            ) && (
                                                <div
                                                    className={styles.checkbox}
                                                >
                                                    <span
                                                        className={cn(
                                                            styles.checkbox_wrap,
                                                            {
                                                                [styles.checked]:
                                                                    isCheckboxChecked(
                                                                        family.mental_health_attention_concentration_effort,
                                                                        "Flat"
                                                                    ),
                                                            }
                                                        )}
                                                    >
                                                        <CheckIcon />
                                                    </span>
                                                    Flat
                                                </div>
                                            )}
                                            {isCheckboxChecked(
                                                family.mental_health_attention_concentration_effort,
                                                "Distractible"
                                            ) && (
                                                <div
                                                    className={styles.checkbox}
                                                >
                                                    <span
                                                        className={cn(
                                                            styles.checkbox_wrap,
                                                            {
                                                                [styles.checked]:
                                                                    isCheckboxChecked(
                                                                        family.mental_health_attention_concentration_effort,
                                                                        "Distractible"
                                                                    ),
                                                            }
                                                        )}
                                                    >
                                                        <CheckIcon />
                                                    </span>
                                                    Distractible
                                                </div>
                                            )}
                                            {isCheckboxChecked(
                                                family.mental_health_attention_concentration_effort,
                                                "Normal energy"
                                            ) && (
                                                <div
                                                    className={styles.checkbox}
                                                >
                                                    <span
                                                        className={cn(
                                                            styles.checkbox_wrap,
                                                            {
                                                                [styles.checked]:
                                                                    isCheckboxChecked(
                                                                        family.mental_health_attention_concentration_effort,
                                                                        "Normal energy"
                                                                    ),
                                                            }
                                                        )}
                                                    >
                                                        <CheckIcon />
                                                    </span>
                                                    Normal energy
                                                </div>
                                            )}
                                            {isCheckboxChecked(
                                                family.mental_health_attention_concentration_effort,
                                                "Eager"
                                            ) && (
                                                <div
                                                    className={styles.checkbox}
                                                >
                                                    <span
                                                        className={cn(
                                                            styles.checkbox_wrap,
                                                            {
                                                                [styles.checked]:
                                                                    isCheckboxChecked(
                                                                        family.mental_health_attention_concentration_effort,
                                                                        "Eager"
                                                                    ),
                                                            }
                                                        )}
                                                    >
                                                        <CheckIcon />
                                                    </span>
                                                    Eager
                                                </div>
                                            )}
                                            {isCheckboxChecked(
                                                family.mental_health_attention_concentration_effort,
                                                "Indifferent"
                                            ) && (
                                                <div
                                                    className={styles.checkbox}
                                                >
                                                    <span
                                                        className={cn(
                                                            styles.checkbox_wrap,
                                                            {
                                                                [styles.checked]:
                                                                    isCheckboxChecked(
                                                                        family.mental_health_attention_concentration_effort,
                                                                        "Indifferent"
                                                                    ),
                                                            }
                                                        )}
                                                    >
                                                        <CheckIcon />
                                                    </span>
                                                    Indifferent
                                                </div>
                                            )}
                                            {isCheckboxChecked(
                                                family.mental_health_attention_concentration_effort,
                                                "Scattered"
                                            ) && (
                                                <div
                                                    className={styles.checkbox}
                                                >
                                                    <span
                                                        className={cn(
                                                            styles.checkbox_wrap,
                                                            {
                                                                [styles.checked]:
                                                                    isCheckboxChecked(
                                                                        family.mental_health_attention_concentration_effort,
                                                                        "Scattered"
                                                                    ),
                                                            }
                                                        )}
                                                    >
                                                        <CheckIcon />
                                                    </span>
                                                    Scattered
                                                </div>
                                            )}
                                            {isCheckboxChecked(
                                                family.mental_health_attention_concentration_effort,
                                                "Baffled"
                                            ) && (
                                                <div
                                                    className={styles.checkbox}
                                                >
                                                    <span
                                                        className={cn(
                                                            styles.checkbox_wrap,
                                                            {
                                                                [styles.checked]:
                                                                    isCheckboxChecked(
                                                                        family.mental_health_attention_concentration_effort,
                                                                        "Baffled"
                                                                    ),
                                                            }
                                                        )}
                                                    >
                                                        <CheckIcon />
                                                    </span>
                                                    Baffled
                                                </div>
                                            )}
                                            {isCheckboxChecked(
                                                family.mental_health_attention_concentration_effort,
                                                "Perplexed"
                                            ) && (
                                                <div
                                                    className={styles.checkbox}
                                                >
                                                    <span
                                                        className={cn(
                                                            styles.checkbox_wrap,
                                                            {
                                                                [styles.checked]:
                                                                    isCheckboxChecked(
                                                                        family.mental_health_attention_concentration_effort,
                                                                        "Perplexed"
                                                                    ),
                                                            }
                                                        )}
                                                    >
                                                        <CheckIcon />
                                                    </span>
                                                    Perplexed
                                                </div>
                                            )}
                                            {isCheckboxChecked(
                                                family.mental_health_attention_concentration_effort,
                                                "Hurried"
                                            ) && (
                                                <div
                                                    className={styles.checkbox}
                                                >
                                                    <span
                                                        className={cn(
                                                            styles.checkbox_wrap,
                                                            {
                                                                [styles.checked]:
                                                                    isCheckboxChecked(
                                                                        family.mental_health_attention_concentration_effort,
                                                                        "Hurried"
                                                                    ),
                                                            }
                                                        )}
                                                    >
                                                        <CheckIcon />
                                                    </span>
                                                    Hurried
                                                </div>
                                            )}
                                            {isCheckboxChecked(
                                                family.mental_health_attention_concentration_effort,
                                                "Organized"
                                            ) && (
                                                <div
                                                    className={styles.checkbox}
                                                >
                                                    <span
                                                        className={cn(
                                                            styles.checkbox_wrap,
                                                            {
                                                                [styles.checked]:
                                                                    isCheckboxChecked(
                                                                        family.mental_health_attention_concentration_effort,
                                                                        "Organized"
                                                                    ),
                                                            }
                                                        )}
                                                    >
                                                        <CheckIcon />
                                                    </span>
                                                    Organized
                                                </div>
                                            )}
                                            {isCheckboxChecked(
                                                family.mental_health_attention_concentration_effort,
                                                "Rigid"
                                            ) && (
                                                <div
                                                    className={styles.checkbox}
                                                >
                                                    <span
                                                        className={cn(
                                                            styles.checkbox_wrap,
                                                            {
                                                                [styles.checked]:
                                                                    isCheckboxChecked(
                                                                        family.mental_health_attention_concentration_effort,
                                                                        "Rigid"
                                                                    ),
                                                            }
                                                        )}
                                                    >
                                                        <CheckIcon />
                                                    </span>
                                                    Rigid
                                                </div>
                                            )}
                                            {isCheckboxChecked(
                                                family.mental_health_attention_concentration_effort,
                                                "Pleasant"
                                            ) && (
                                                <div
                                                    className={styles.checkbox}
                                                >
                                                    <span
                                                        className={cn(
                                                            styles.checkbox_wrap,
                                                            {
                                                                [styles.checked]:
                                                                    isCheckboxChecked(
                                                                        family.mental_health_attention_concentration_effort,
                                                                        "Pleasant"
                                                                    ),
                                                            }
                                                        )}
                                                    >
                                                        <CheckIcon />
                                                    </span>
                                                    Pleasant
                                                </div>
                                            )}
                                            {isCheckboxChecked(
                                                family.mental_health_attention_concentration_effort,
                                                "Cooperative"
                                            ) && (
                                                <div
                                                    className={styles.checkbox}
                                                >
                                                    <span
                                                        className={cn(
                                                            styles.checkbox_wrap,
                                                            {
                                                                [styles.checked]:
                                                                    isCheckboxChecked(
                                                                        family.mental_health_attention_concentration_effort,
                                                                        "Cooperative"
                                                                    ),
                                                            }
                                                        )}
                                                    >
                                                        <CheckIcon />
                                                    </span>
                                                    Cooperative
                                                </div>
                                            )}
                                            {isCheckboxChecked(
                                                family.mental_health_attention_concentration_effort,
                                                "Dependent"
                                            ) && (
                                                <div
                                                    className={styles.checkbox}
                                                >
                                                    <span
                                                        className={cn(
                                                            styles.checkbox_wrap,
                                                            {
                                                                [styles.checked]:
                                                                    isCheckboxChecked(
                                                                        family.mental_health_attention_concentration_effort,
                                                                        "Dependent"
                                                                    ),
                                                            }
                                                        )}
                                                    >
                                                        <CheckIcon />
                                                    </span>
                                                    Dependent
                                                </div>
                                            )}
                                            {isCheckboxChecked(
                                                family.mental_health_attention_concentration_effort,
                                                "Abusive"
                                            ) && (
                                                <div
                                                    className={styles.checkbox}
                                                >
                                                    <span
                                                        className={cn(
                                                            styles.checkbox_wrap,
                                                            {
                                                                [styles.checked]:
                                                                    isCheckboxChecked(
                                                                        family.mental_health_attention_concentration_effort,
                                                                        "Abusive"
                                                                    ),
                                                            }
                                                        )}
                                                    >
                                                        <CheckIcon />
                                                    </span>
                                                    Abusive
                                                </div>
                                            )}
                                            {isCheckboxChecked(
                                                family.mental_health_attention_concentration_effort,
                                                "Superior"
                                            ) && (
                                                <div
                                                    className={styles.checkbox}
                                                >
                                                    <span
                                                        className={cn(
                                                            styles.checkbox_wrap,
                                                            {
                                                                [styles.checked]:
                                                                    isCheckboxChecked(
                                                                        family.mental_health_attention_concentration_effort,
                                                                        "Superior"
                                                                    ),
                                                            }
                                                        )}
                                                    >
                                                        <CheckIcon />
                                                    </span>
                                                    Superior
                                                </div>
                                            )}
                                            {isCheckboxChecked(
                                                family.mental_health_attention_concentration_effort,
                                                "Stubborn"
                                            ) && (
                                                <div
                                                    className={styles.checkbox}
                                                >
                                                    <span
                                                        className={cn(
                                                            styles.checkbox_wrap,
                                                            {
                                                                [styles.checked]:
                                                                    isCheckboxChecked(
                                                                        family.mental_health_attention_concentration_effort,
                                                                        "Stubborn"
                                                                    ),
                                                            }
                                                        )}
                                                    >
                                                        <CheckIcon />
                                                    </span>
                                                    Stubborn
                                                </div>
                                            )}
                                            {isCheckboxChecked(
                                                family.mental_health_attention_concentration_effort,
                                                "Belligerent"
                                            ) && (
                                                <div
                                                    className={styles.checkbox}
                                                >
                                                    <span
                                                        className={cn(
                                                            styles.checkbox_wrap,
                                                            {
                                                                [styles.checked]:
                                                                    isCheckboxChecked(
                                                                        family.mental_health_attention_concentration_effort,
                                                                        "Belligerent"
                                                                    ),
                                                            }
                                                        )}
                                                    >
                                                        <CheckIcon />
                                                    </span>
                                                    Belligerent
                                                </div>
                                            )}
                                            {isCheckboxChecked(
                                                family.mental_health_attention_concentration_effort,
                                                "Argumentative"
                                            ) && (
                                                <div
                                                    className={styles.checkbox}
                                                >
                                                    <span
                                                        className={cn(
                                                            styles.checkbox_wrap,
                                                            {
                                                                [styles.checked]:
                                                                    isCheckboxChecked(
                                                                        family.mental_health_attention_concentration_effort,
                                                                        "Argumentative"
                                                                    ),
                                                            }
                                                        )}
                                                    >
                                                        <CheckIcon />
                                                    </span>
                                                    Argumentative
                                                </div>
                                            )}
                                            {isCheckboxChecked(
                                                family.mental_health_attention_concentration_effort,
                                                "Hostile"
                                            ) && (
                                                <div
                                                    className={styles.checkbox}
                                                >
                                                    <span
                                                        className={cn(
                                                            styles.checkbox_wrap,
                                                            {
                                                                [styles.checked]:
                                                                    isCheckboxChecked(
                                                                        family.mental_health_attention_concentration_effort,
                                                                        "Hostile"
                                                                    ),
                                                            }
                                                        )}
                                                    >
                                                        <CheckIcon />
                                                    </span>
                                                    Hostile
                                                </div>
                                            )}
                                            {isCheckboxChecked(
                                                family.mental_health_attention_concentration_effort,
                                                "Demanding"
                                            ) && (
                                                <div
                                                    className={styles.checkbox}
                                                >
                                                    <span
                                                        className={cn(
                                                            styles.checkbox_wrap,
                                                            {
                                                                [styles.checked]:
                                                                    isCheckboxChecked(
                                                                        family.mental_health_attention_concentration_effort,
                                                                        "Demanding"
                                                                    ),
                                                            }
                                                        )}
                                                    >
                                                        <CheckIcon />
                                                    </span>
                                                    Demanding
                                                </div>
                                            )}
                                            {isCheckboxChecked(
                                                family.mental_health_attention_concentration_effort,
                                                "Resentful"
                                            ) && (
                                                <div
                                                    className={styles.checkbox}
                                                >
                                                    <span
                                                        className={cn(
                                                            styles.checkbox_wrap,
                                                            {
                                                                [styles.checked]:
                                                                    isCheckboxChecked(
                                                                        family.mental_health_attention_concentration_effort,
                                                                        "Resentful"
                                                                    ),
                                                            }
                                                        )}
                                                    >
                                                        <CheckIcon />
                                                    </span>
                                                    Resentful
                                                </div>
                                            )}
                                            {isCheckboxChecked(
                                                family.mental_health_attention_concentration_effort,
                                                "Surly"
                                            ) && (
                                                <div
                                                    className={styles.checkbox}
                                                >
                                                    <span
                                                        className={cn(
                                                            styles.checkbox_wrap,
                                                            {
                                                                [styles.checked]:
                                                                    isCheckboxChecked(
                                                                        family.mental_health_attention_concentration_effort,
                                                                        "Surly"
                                                                    ),
                                                            }
                                                        )}
                                                    >
                                                        <CheckIcon />
                                                    </span>
                                                    Surly
                                                </div>
                                            )}
                                            {isCheckboxChecked(
                                                family.mental_health_attention_concentration_effort,
                                                "Guarded"
                                            ) && (
                                                <div
                                                    className={styles.checkbox}
                                                >
                                                    <span
                                                        className={cn(
                                                            styles.checkbox_wrap,
                                                            {
                                                                [styles.checked]:
                                                                    isCheckboxChecked(
                                                                        family.mental_health_attention_concentration_effort,
                                                                        "Guarded"
                                                                    ),
                                                            }
                                                        )}
                                                    >
                                                        <CheckIcon />
                                                    </span>
                                                    Guarded
                                                </div>
                                            )}
                                            {isCheckboxChecked(
                                                family.mental_health_attention_concentration_effort,
                                                "Indifferent"
                                            ) && (
                                                <div
                                                    className={styles.checkbox}
                                                >
                                                    <span
                                                        className={cn(
                                                            styles.checkbox_wrap,
                                                            {
                                                                [styles.checked]:
                                                                    isCheckboxChecked(
                                                                        family.mental_health_attention_concentration_effort,
                                                                        "Indifferent"
                                                                    ),
                                                            }
                                                        )}
                                                    >
                                                        <CheckIcon />
                                                    </span>
                                                    Indifferent
                                                </div>
                                            )}
                                        </div>
                                    ) : (
                                        "--"
                                    )
                                }
                            />
                            <NoteContentItem
                                headerText={
                                    FAMILY_INTAKE_FAMILY_NOTE_DETAILS.mental_health_presentation_of_self
                                }
                                fieldId={`mental_health_presentation_of_self-${noteContent.family[i].family_id}`}
                                addendums={
                                    fieldAddendums?.[
                                        `mental_health_presentation_of_self-${noteContent.family[i].family_id}`
                                    ]
                                }
                                content={
                                    family?.mental_health_presentation_of_self
                                        ?.length > 0 ? (
                                        <div className={styles.checkboxes}>
                                            {isCheckboxChecked(
                                                family.mental_health_presentation_of_self,
                                                "Withdrawn"
                                            ) && (
                                                <div
                                                    className={styles.checkbox}
                                                >
                                                    <span
                                                        className={cn(
                                                            styles.checkbox_wrap,
                                                            {
                                                                [styles.checked]:
                                                                    isCheckboxChecked(
                                                                        family.mental_health_presentation_of_self,
                                                                        "Withdrawn"
                                                                    ),
                                                            }
                                                        )}
                                                    >
                                                        <CheckIcon />
                                                    </span>
                                                    Withdrawn
                                                </div>
                                            )}
                                            {isCheckboxChecked(
                                                family.mental_health_presentation_of_self,
                                                "Threatened"
                                            ) && (
                                                <div
                                                    className={styles.checkbox}
                                                >
                                                    <span
                                                        className={cn(
                                                            styles.checkbox_wrap,
                                                            {
                                                                [styles.checked]:
                                                                    isCheckboxChecked(
                                                                        family.mental_health_presentation_of_self,
                                                                        "Threatened"
                                                                    ),
                                                            }
                                                        )}
                                                    >
                                                        <CheckIcon />
                                                    </span>
                                                    Threatened
                                                </div>
                                            )}
                                            {isCheckboxChecked(
                                                family.mental_health_presentation_of_self,
                                                "Vulnerable"
                                            ) && (
                                                <div
                                                    className={styles.checkbox}
                                                >
                                                    <span
                                                        className={cn(
                                                            styles.checkbox_wrap,
                                                            {
                                                                [styles.checked]:
                                                                    isCheckboxChecked(
                                                                        family.mental_health_presentation_of_self,
                                                                        "Vulnerable"
                                                                    ),
                                                            }
                                                        )}
                                                    >
                                                        <CheckIcon />
                                                    </span>
                                                    Vulnerable
                                                </div>
                                            )}
                                            {isCheckboxChecked(
                                                family.mental_health_presentation_of_self,
                                                "Shy"
                                            ) && (
                                                <div
                                                    className={styles.checkbox}
                                                >
                                                    <span
                                                        className={cn(
                                                            styles.checkbox_wrap,
                                                            {
                                                                [styles.checked]:
                                                                    isCheckboxChecked(
                                                                        family.mental_health_presentation_of_self,
                                                                        "Shy"
                                                                    ),
                                                            }
                                                        )}
                                                    >
                                                        <CheckIcon />
                                                    </span>
                                                    Shy
                                                </div>
                                            )}
                                            {isCheckboxChecked(
                                                family.mental_health_presentation_of_self,
                                                "Friendly"
                                            ) && (
                                                <div
                                                    className={styles.checkbox}
                                                >
                                                    <span
                                                        className={cn(
                                                            styles.checkbox_wrap,
                                                            {
                                                                [styles.checked]:
                                                                    isCheckboxChecked(
                                                                        family.mental_health_presentation_of_self,
                                                                        "Friendly"
                                                                    ),
                                                            }
                                                        )}
                                                    >
                                                        <CheckIcon />
                                                    </span>
                                                    Friendly
                                                </div>
                                            )}
                                            {isCheckboxChecked(
                                                family.mental_health_presentation_of_self,
                                                "Confident"
                                            ) && (
                                                <div
                                                    className={styles.checkbox}
                                                >
                                                    <span
                                                        className={cn(
                                                            styles.checkbox_wrap,
                                                            {
                                                                [styles.checked]:
                                                                    isCheckboxChecked(
                                                                        family.mental_health_presentation_of_self,
                                                                        "Confident"
                                                                    ),
                                                            }
                                                        )}
                                                    >
                                                        <CheckIcon />
                                                    </span>
                                                    Confident
                                                </div>
                                            )}
                                            {isCheckboxChecked(
                                                family.mental_health_presentation_of_self,
                                                "Grandiose"
                                            ) && (
                                                <div
                                                    className={styles.checkbox}
                                                >
                                                    <span
                                                        className={cn(
                                                            styles.checkbox_wrap,
                                                            {
                                                                [styles.checked]:
                                                                    isCheckboxChecked(
                                                                        family.mental_health_presentation_of_self,
                                                                        "Grandiose"
                                                                    ),
                                                            }
                                                        )}
                                                    >
                                                        <CheckIcon />
                                                    </span>
                                                    Grandiose
                                                </div>
                                            )}
                                            {isCheckboxChecked(
                                                family.mental_health_presentation_of_self,
                                                "Humble"
                                            ) && (
                                                <div
                                                    className={styles.checkbox}
                                                >
                                                    <span
                                                        className={cn(
                                                            styles.checkbox_wrap,
                                                            {
                                                                [styles.checked]:
                                                                    isCheckboxChecked(
                                                                        family.mental_health_presentation_of_self,
                                                                        "Humble"
                                                                    ),
                                                            }
                                                        )}
                                                    >
                                                        <CheckIcon />
                                                    </span>
                                                    Humble
                                                </div>
                                            )}
                                            {isCheckboxChecked(
                                                family.mental_health_presentation_of_self,
                                                "Self-doubting"
                                            ) && (
                                                <div
                                                    className={styles.checkbox}
                                                >
                                                    <span
                                                        className={cn(
                                                            styles.checkbox_wrap,
                                                            {
                                                                [styles.checked]:
                                                                    isCheckboxChecked(
                                                                        family.mental_health_presentation_of_self,
                                                                        "Self-doubting"
                                                                    ),
                                                            }
                                                        )}
                                                    >
                                                        <CheckIcon />
                                                    </span>
                                                    Self-doubting
                                                </div>
                                            )}
                                            {isCheckboxChecked(
                                                family.mental_health_presentation_of_self,
                                                "Hopeful"
                                            ) && (
                                                <div
                                                    className={styles.checkbox}
                                                >
                                                    <span
                                                        className={cn(
                                                            styles.checkbox_wrap,
                                                            {
                                                                [styles.checked]:
                                                                    isCheckboxChecked(
                                                                        family.mental_health_presentation_of_self,
                                                                        "Hopeful"
                                                                    ),
                                                            }
                                                        )}
                                                    >
                                                        <CheckIcon />
                                                    </span>
                                                    Hopeful
                                                </div>
                                            )}
                                            {isCheckboxChecked(
                                                family.mental_health_presentation_of_self,
                                                "Pessimistic"
                                            ) && (
                                                <div
                                                    className={styles.checkbox}
                                                >
                                                    <span
                                                        className={cn(
                                                            styles.checkbox_wrap,
                                                            {
                                                                [styles.checked]:
                                                                    isCheckboxChecked(
                                                                        family.mental_health_presentation_of_self,
                                                                        "Pessimistic"
                                                                    ),
                                                            }
                                                        )}
                                                    >
                                                        <CheckIcon />
                                                    </span>
                                                    Pessimistic
                                                </div>
                                            )}
                                            {isCheckboxChecked(
                                                family.mental_health_presentation_of_self,
                                                "Overindulgent"
                                            ) && (
                                                <div
                                                    className={styles.checkbox}
                                                >
                                                    <span
                                                        className={cn(
                                                            styles.checkbox_wrap,
                                                            {
                                                                [styles.checked]:
                                                                    isCheckboxChecked(
                                                                        family.mental_health_presentation_of_self,
                                                                        "Overindulgent"
                                                                    ),
                                                            }
                                                        )}
                                                    >
                                                        <CheckIcon />
                                                    </span>
                                                    Overindulgent
                                                </div>
                                            )}
                                            {isCheckboxChecked(
                                                family.mental_health_presentation_of_self,
                                                "Warm hearted"
                                            ) && (
                                                <div
                                                    className={styles.checkbox}
                                                >
                                                    <span
                                                        className={cn(
                                                            styles.checkbox_wrap,
                                                            {
                                                                [styles.checked]:
                                                                    isCheckboxChecked(
                                                                        family.mental_health_presentation_of_self,
                                                                        "Warm hearted"
                                                                    ),
                                                            }
                                                        )}
                                                    >
                                                        <CheckIcon />
                                                    </span>
                                                    Warm hearted
                                                </div>
                                            )}
                                            {isCheckboxChecked(
                                                family.mental_health_presentation_of_self,
                                                "Distant"
                                            ) && (
                                                <div
                                                    className={styles.checkbox}
                                                >
                                                    <span
                                                        className={cn(
                                                            styles.checkbox_wrap,
                                                            {
                                                                [styles.checked]:
                                                                    isCheckboxChecked(
                                                                        family.mental_health_presentation_of_self,
                                                                        "Distant"
                                                                    ),
                                                            }
                                                        )}
                                                    >
                                                        <CheckIcon />
                                                    </span>
                                                    Distant
                                                </div>
                                            )}
                                            {isCheckboxChecked(
                                                family.mental_health_presentation_of_self,
                                                "Harsh"
                                            ) && (
                                                <div
                                                    className={styles.checkbox}
                                                >
                                                    <span
                                                        className={cn(
                                                            styles.checkbox_wrap,
                                                            {
                                                                [styles.checked]:
                                                                    isCheckboxChecked(
                                                                        family.mental_health_presentation_of_self,
                                                                        "Harsh"
                                                                    ),
                                                            }
                                                        )}
                                                    >
                                                        <CheckIcon />
                                                    </span>
                                                    Harsh
                                                </div>
                                            )}
                                        </div>
                                    ) : (
                                        "--"
                                    )
                                }
                            />
                            <NoteContentItem
                                headerText={
                                    FAMILY_INTAKE_FAMILY_NOTE_DETAILS.mental_health_cognition
                                }
                                fieldId={`mental_health_cognition-${noteContent.family[i].family_id}`}
                                addendums={
                                    fieldAddendums?.[
                                        `mental_health_cognition-${noteContent.family[i].family_id}`
                                    ]
                                }
                                content={
                                    family?.mental_health_cognition?.length >
                                    0 ? (
                                        <div className={styles.checkboxes}>
                                            {isCheckboxChecked(
                                                family.mental_health_cognition,
                                                "Indecisive"
                                            ) && (
                                                <div
                                                    className={styles.checkbox}
                                                >
                                                    <span
                                                        className={cn(
                                                            styles.checkbox_wrap,
                                                            {
                                                                [styles.checked]:
                                                                    isCheckboxChecked(
                                                                        family.mental_health_cognition,
                                                                        "Indecisive"
                                                                    ),
                                                            }
                                                        )}
                                                    >
                                                        <CheckIcon />
                                                    </span>
                                                    Indecisive
                                                </div>
                                            )}
                                            {isCheckboxChecked(
                                                family.mental_health_cognition,
                                                "Decisive"
                                            ) && (
                                                <div
                                                    className={styles.checkbox}
                                                >
                                                    <span
                                                        className={cn(
                                                            styles.checkbox_wrap,
                                                            {
                                                                [styles.checked]:
                                                                    isCheckboxChecked(
                                                                        family.mental_health_cognition,
                                                                        "Decisive"
                                                                    ),
                                                            }
                                                        )}
                                                    >
                                                        <CheckIcon />
                                                    </span>
                                                    Decisive
                                                </div>
                                            )}
                                            {isCheckboxChecked(
                                                family.mental_health_cognition,
                                                "Some insight"
                                            ) && (
                                                <div
                                                    className={styles.checkbox}
                                                >
                                                    <span
                                                        className={cn(
                                                            styles.checkbox_wrap,
                                                            {
                                                                [styles.checked]:
                                                                    isCheckboxChecked(
                                                                        family.mental_health_cognition,
                                                                        "Some insight"
                                                                    ),
                                                            }
                                                        )}
                                                    >
                                                        <CheckIcon />
                                                    </span>
                                                    Some insight
                                                </div>
                                            )}
                                            {isCheckboxChecked(
                                                family.mental_health_cognition,
                                                "Sluggish recall"
                                            ) && (
                                                <div
                                                    className={styles.checkbox}
                                                >
                                                    <span
                                                        className={cn(
                                                            styles.checkbox_wrap,
                                                            {
                                                                [styles.checked]:
                                                                    isCheckboxChecked(
                                                                        family.mental_health_cognition,
                                                                        "Sluggish recall"
                                                                    ),
                                                            }
                                                        )}
                                                    >
                                                        <CheckIcon />
                                                    </span>
                                                    Sluggish recall
                                                </div>
                                            )}
                                            {isCheckboxChecked(
                                                family.mental_health_cognition,
                                                "Normal memory"
                                            ) && (
                                                <div
                                                    className={styles.checkbox}
                                                >
                                                    <span
                                                        className={cn(
                                                            styles.checkbox_wrap,
                                                            {
                                                                [styles.checked]:
                                                                    isCheckboxChecked(
                                                                        family.mental_health_cognition,
                                                                        "Normal memory"
                                                                    ),
                                                            }
                                                        )}
                                                    >
                                                        <CheckIcon />
                                                    </span>
                                                    Normal memory
                                                </div>
                                            )}
                                            {isCheckboxChecked(
                                                family.mental_health_cognition,
                                                "Low intelligent"
                                            ) && (
                                                <div
                                                    className={styles.checkbox}
                                                >
                                                    <span
                                                        className={cn(
                                                            styles.checkbox_wrap,
                                                            {
                                                                [styles.checked]:
                                                                    isCheckboxChecked(
                                                                        family.mental_health_cognition,
                                                                        "Low intelligent"
                                                                    ),
                                                            }
                                                        )}
                                                    >
                                                        <CheckIcon />
                                                    </span>
                                                    Low intelligent
                                                </div>
                                            )}
                                            {isCheckboxChecked(
                                                family.mental_health_cognition,
                                                "Good orientation"
                                            ) && (
                                                <div
                                                    className={styles.checkbox}
                                                >
                                                    <span
                                                        className={cn(
                                                            styles.checkbox_wrap,
                                                            {
                                                                [styles.checked]:
                                                                    isCheckboxChecked(
                                                                        family.mental_health_cognition,
                                                                        "Good orientation"
                                                                    ),
                                                            }
                                                        )}
                                                    >
                                                        <CheckIcon />
                                                    </span>
                                                    Good orientation
                                                </div>
                                            )}
                                            {isCheckboxChecked(
                                                family.mental_health_cognition,
                                                "Defective reality"
                                            ) && (
                                                <div
                                                    className={styles.checkbox}
                                                >
                                                    <span
                                                        className={cn(
                                                            styles.checkbox_wrap,
                                                            {
                                                                [styles.checked]:
                                                                    isCheckboxChecked(
                                                                        family.mental_health_cognition,
                                                                        "Defective reality"
                                                                    ),
                                                            }
                                                        )}
                                                    >
                                                        <CheckIcon />
                                                    </span>
                                                    Defective reality
                                                </div>
                                            )}
                                            {isCheckboxChecked(
                                                family.mental_health_cognition,
                                                "Flight of ideas"
                                            ) && (
                                                <div
                                                    className={styles.checkbox}
                                                >
                                                    <span
                                                        className={cn(
                                                            styles.checkbox_wrap,
                                                            {
                                                                [styles.checked]:
                                                                    isCheckboxChecked(
                                                                        family.mental_health_cognition,
                                                                        "Flight of ideas"
                                                                    ),
                                                            }
                                                        )}
                                                    >
                                                        <CheckIcon />
                                                    </span>
                                                    Flight of ideas
                                                </div>
                                            )}
                                            {isCheckboxChecked(
                                                family.mental_health_cognition,
                                                "Incoherent thought process"
                                            ) && (
                                                <div
                                                    className={styles.checkbox}
                                                >
                                                    <span
                                                        className={cn(
                                                            styles.checkbox_wrap,
                                                            {
                                                                [styles.checked]:
                                                                    isCheckboxChecked(
                                                                        family.mental_health_cognition,
                                                                        "Incoherent thought process"
                                                                    ),
                                                            }
                                                        )}
                                                    >
                                                        <CheckIcon />
                                                    </span>
                                                    Incoherent thought process
                                                </div>
                                            )}
                                            {isCheckboxChecked(
                                                family.mental_health_cognition,
                                                "Procrastinates"
                                            ) && (
                                                <div
                                                    className={styles.checkbox}
                                                >
                                                    <span
                                                        className={cn(
                                                            styles.checkbox_wrap,
                                                            {
                                                                [styles.checked]:
                                                                    isCheckboxChecked(
                                                                        family.mental_health_cognition,
                                                                        "Procrastinates"
                                                                    ),
                                                            }
                                                        )}
                                                    >
                                                        <CheckIcon />
                                                    </span>
                                                    Procrastinates
                                                </div>
                                            )}
                                            {isCheckboxChecked(
                                                family.mental_health_cognition,
                                                "Follows through"
                                            ) && (
                                                <div
                                                    className={styles.checkbox}
                                                >
                                                    <span
                                                        className={cn(
                                                            styles.checkbox_wrap,
                                                            {
                                                                [styles.checked]:
                                                                    isCheckboxChecked(
                                                                        family.mental_health_cognition,
                                                                        "Follows through"
                                                                    ),
                                                            }
                                                        )}
                                                    >
                                                        <CheckIcon />
                                                    </span>
                                                    Follows through
                                                </div>
                                            )}
                                            {isCheckboxChecked(
                                                family.mental_health_cognition,
                                                "Full insight"
                                            ) && (
                                                <div
                                                    className={styles.checkbox}
                                                >
                                                    <span
                                                        className={cn(
                                                            styles.checkbox_wrap,
                                                            {
                                                                [styles.checked]:
                                                                    isCheckboxChecked(
                                                                        family.mental_health_cognition,
                                                                        "Full insight"
                                                                    ),
                                                            }
                                                        )}
                                                    >
                                                        <CheckIcon />
                                                    </span>
                                                    Full insight
                                                </div>
                                            )}
                                            {isCheckboxChecked(
                                                family.mental_health_cognition,
                                                "Amnesia"
                                            ) && (
                                                <div
                                                    className={styles.checkbox}
                                                >
                                                    <span
                                                        className={cn(
                                                            styles.checkbox_wrap,
                                                            {
                                                                [styles.checked]:
                                                                    isCheckboxChecked(
                                                                        family.mental_health_cognition,
                                                                        "Amnesia"
                                                                    ),
                                                            }
                                                        )}
                                                    >
                                                        <CheckIcon />
                                                    </span>
                                                    Amnesia
                                                </div>
                                            )}
                                            {isCheckboxChecked(
                                                family.mental_health_cognition,
                                                "Intelligent"
                                            ) && (
                                                <div
                                                    className={styles.checkbox}
                                                >
                                                    <span
                                                        className={cn(
                                                            styles.checkbox_wrap,
                                                            {
                                                                [styles.checked]:
                                                                    isCheckboxChecked(
                                                                        family.mental_health_cognition,
                                                                        "Intelligent"
                                                                    ),
                                                            }
                                                        )}
                                                    >
                                                        <CheckIcon />
                                                    </span>
                                                    Intelligent
                                                </div>
                                            )}
                                            {isCheckboxChecked(
                                                family.mental_health_cognition,
                                                "Normal judgment"
                                            ) && (
                                                <div
                                                    className={styles.checkbox}
                                                >
                                                    <span
                                                        className={cn(
                                                            styles.checkbox_wrap,
                                                            {
                                                                [styles.checked]:
                                                                    isCheckboxChecked(
                                                                        family.mental_health_cognition,
                                                                        "Normal judgment"
                                                                    ),
                                                            }
                                                        )}
                                                    >
                                                        <CheckIcon />
                                                    </span>
                                                    Normal judgment
                                                </div>
                                            )}
                                            {isCheckboxChecked(
                                                family.mental_health_cognition,
                                                "Intact reality"
                                            ) && (
                                                <div
                                                    className={styles.checkbox}
                                                >
                                                    <span
                                                        className={cn(
                                                            styles.checkbox_wrap,
                                                            {
                                                                [styles.checked]:
                                                                    isCheckboxChecked(
                                                                        family.mental_health_cognition,
                                                                        "Intact reality"
                                                                    ),
                                                            }
                                                        )}
                                                    >
                                                        <CheckIcon />
                                                    </span>
                                                    Intact reality
                                                </div>
                                            )}
                                            {isCheckboxChecked(
                                                family.mental_health_cognition,
                                                "Normal abstraction"
                                            ) && (
                                                <div
                                                    className={styles.checkbox}
                                                >
                                                    <span
                                                        className={cn(
                                                            styles.checkbox_wrap,
                                                            {
                                                                [styles.checked]:
                                                                    isCheckboxChecked(
                                                                        family.mental_health_cognition,
                                                                        "Normal abstraction"
                                                                    ),
                                                            }
                                                        )}
                                                    >
                                                        <CheckIcon />
                                                    </span>
                                                    Normal abstraction
                                                </div>
                                            )}
                                            {isCheckboxChecked(
                                                family.mental_health_cognition,
                                                "Loose thought process"
                                            ) && (
                                                <div
                                                    className={styles.checkbox}
                                                >
                                                    <span
                                                        className={cn(
                                                            styles.checkbox_wrap,
                                                            {
                                                                [styles.checked]:
                                                                    isCheckboxChecked(
                                                                        family.mental_health_cognition,
                                                                        "Loose thought process"
                                                                    ),
                                                            }
                                                        )}
                                                    >
                                                        <CheckIcon />
                                                    </span>
                                                    Loose thought process
                                                </div>
                                            )}
                                            {isCheckboxChecked(
                                                family.mental_health_cognition,
                                                "Clear thought process"
                                            ) && (
                                                <div
                                                    className={styles.checkbox}
                                                >
                                                    <span
                                                        className={cn(
                                                            styles.checkbox_wrap,
                                                            {
                                                                [styles.checked]:
                                                                    isCheckboxChecked(
                                                                        family.mental_health_cognition,
                                                                        "Clear thought process"
                                                                    ),
                                                            }
                                                        )}
                                                    >
                                                        <CheckIcon />
                                                    </span>
                                                    Clear thought process
                                                </div>
                                            )}
                                            {isCheckboxChecked(
                                                family.mental_health_cognition,
                                                "Easily confused"
                                            ) && (
                                                <div
                                                    className={styles.checkbox}
                                                >
                                                    <span
                                                        className={cn(
                                                            styles.checkbox_wrap,
                                                            {
                                                                [styles.checked]:
                                                                    isCheckboxChecked(
                                                                        family.mental_health_cognition,
                                                                        "Easily confused"
                                                                    ),
                                                            }
                                                        )}
                                                    >
                                                        <CheckIcon />
                                                    </span>
                                                    Easily confused
                                                </div>
                                            )}
                                            {isCheckboxChecked(
                                                family.mental_health_cognition,
                                                "No insight"
                                            ) && (
                                                <div
                                                    className={styles.checkbox}
                                                >
                                                    <span
                                                        className={cn(
                                                            styles.checkbox_wrap,
                                                            {
                                                                [styles.checked]:
                                                                    isCheckboxChecked(
                                                                        family.mental_health_cognition,
                                                                        "No insight"
                                                                    ),
                                                            }
                                                        )}
                                                    >
                                                        <CheckIcon />
                                                    </span>
                                                    No insight
                                                </div>
                                            )}
                                            {isCheckboxChecked(
                                                family.mental_health_cognition,
                                                "Forgetful"
                                            ) && (
                                                <div
                                                    className={styles.checkbox}
                                                >
                                                    <span
                                                        className={cn(
                                                            styles.checkbox_wrap,
                                                            {
                                                                [styles.checked]:
                                                                    isCheckboxChecked(
                                                                        family.mental_health_cognition,
                                                                        "Forgetful"
                                                                    ),
                                                            }
                                                        )}
                                                    >
                                                        <CheckIcon />
                                                    </span>
                                                    Forgetful
                                                </div>
                                            )}
                                            {isCheckboxChecked(
                                                family.mental_health_cognition,
                                                "Confuses time frames/sequence"
                                            ) && (
                                                <div
                                                    className={styles.checkbox}
                                                >
                                                    <span
                                                        className={cn(
                                                            styles.checkbox_wrap,
                                                            {
                                                                [styles.checked]:
                                                                    isCheckboxChecked(
                                                                        family.mental_health_cognition,
                                                                        "Confuses time frames/sequence"
                                                                    ),
                                                            }
                                                        )}
                                                    >
                                                        <CheckIcon />
                                                    </span>
                                                    Confuses time
                                                    frames/sequence
                                                </div>
                                            )}
                                            {isCheckboxChecked(
                                                family.mental_health_cognition,
                                                "Lack of common sense"
                                            ) && (
                                                <div
                                                    className={styles.checkbox}
                                                >
                                                    <span
                                                        className={cn(
                                                            styles.checkbox_wrap,
                                                            {
                                                                [styles.checked]:
                                                                    isCheckboxChecked(
                                                                        family.mental_health_cognition,
                                                                        "Lack of common sense"
                                                                    ),
                                                            }
                                                        )}
                                                    >
                                                        <CheckIcon />
                                                    </span>
                                                    Lack of common sense
                                                </div>
                                            )}
                                            {isCheckboxChecked(
                                                family.mental_health_cognition,
                                                "Victimization"
                                            ) && (
                                                <div
                                                    className={styles.checkbox}
                                                >
                                                    <span
                                                        className={cn(
                                                            styles.checkbox_wrap,
                                                            {
                                                                [styles.checked]:
                                                                    isCheckboxChecked(
                                                                        family.mental_health_cognition,
                                                                        "Victimization"
                                                                    ),
                                                            }
                                                        )}
                                                    >
                                                        <CheckIcon />
                                                    </span>
                                                    Victimization
                                                </div>
                                            )}
                                            {isCheckboxChecked(
                                                family.mental_health_cognition,
                                                "Confused present location/date/persons, places"
                                            ) && (
                                                <div
                                                    className={styles.checkbox}
                                                >
                                                    <span
                                                        className={cn(
                                                            styles.checkbox_wrap,
                                                            {
                                                                [styles.checked]:
                                                                    isCheckboxChecked(
                                                                        family.mental_health_cognition,
                                                                        "Confused present location/date/persons, places"
                                                                    ),
                                                            }
                                                        )}
                                                    >
                                                        <CheckIcon />
                                                    </span>
                                                    Confused present
                                                    location/date/persons,
                                                    places
                                                </div>
                                            )}
                                            {isCheckboxChecked(
                                                family.mental_health_cognition,
                                                "Faulty reasoning"
                                            ) && (
                                                <div
                                                    className={styles.checkbox}
                                                >
                                                    <span
                                                        className={cn(
                                                            styles.checkbox_wrap,
                                                            {
                                                                [styles.checked]:
                                                                    isCheckboxChecked(
                                                                        family.mental_health_cognition,
                                                                        "Faulty reasoning"
                                                                    ),
                                                            }
                                                        )}
                                                    >
                                                        <CheckIcon />
                                                    </span>
                                                    Faulty reasoning
                                                </div>
                                            )}
                                            {isCheckboxChecked(
                                                family.mental_health_cognition,
                                                "Impoverished stream of thoughts"
                                            ) && (
                                                <div
                                                    className={styles.checkbox}
                                                >
                                                    <span
                                                        className={cn(
                                                            styles.checkbox_wrap,
                                                            {
                                                                [styles.checked]:
                                                                    isCheckboxChecked(
                                                                        family.mental_health_cognition,
                                                                        "Impoverished stream of thoughts"
                                                                    ),
                                                            }
                                                        )}
                                                    >
                                                        <CheckIcon />
                                                    </span>
                                                    Impoverished stream of
                                                    thoughts
                                                </div>
                                            )}
                                            {isCheckboxChecked(
                                                family.mental_health_cognition,
                                                "Normal stream of thoughts"
                                            ) && (
                                                <div
                                                    className={styles.checkbox}
                                                >
                                                    <span
                                                        className={cn(
                                                            styles.checkbox_wrap,
                                                            {
                                                                [styles.checked]:
                                                                    isCheckboxChecked(
                                                                        family.mental_health_cognition,
                                                                        "Normal stream of thoughts"
                                                                    ),
                                                            }
                                                        )}
                                                    >
                                                        <CheckIcon />
                                                    </span>
                                                    Normal stream of thoughts
                                                </div>
                                            )}
                                            {isCheckboxChecked(
                                                family.mental_health_cognition,
                                                "Normal stream of thoughts"
                                            ) && (
                                                <div
                                                    className={styles.checkbox}
                                                >
                                                    <span
                                                        className={cn(
                                                            styles.checkbox_wrap,
                                                            {
                                                                [styles.checked]:
                                                                    isCheckboxChecked(
                                                                        family.mental_health_cognition,
                                                                        "Normal stream of thoughts"
                                                                    ),
                                                            }
                                                        )}
                                                    >
                                                        <CheckIcon />
                                                    </span>
                                                    Normal stream of thoughts
                                                </div>
                                            )}
                                        </div>
                                    ) : (
                                        "--"
                                    )
                                }
                            />
                            <NoteContentItem
                                headerText={
                                    FAMILY_INTAKE_FAMILY_NOTE_DETAILS.patients_ability_and_capability_to_respond_to_treatment
                                }
                                fieldId={`patients_ability_and_capability_to_respond_to_treatment-${noteContent.family[i].family_id}`}
                                addendums={
                                    fieldAddendums?.[
                                        `patients_ability_and_capability_to_respond_to_treatment-${noteContent.family[i].family_id}`
                                    ]
                                }
                                content={
                                    family
                                        ?.patients_ability_and_capability_to_respond_to_treatment
                                        ?.length > 0 ? (
                                        <div className={styles.checkboxes}>
                                            {isCheckboxChecked(
                                                family.patients_ability_and_capability_to_respond_to_treatment,
                                                "Consistent, good-quality patient-oriented evidence"
                                            ) && (
                                                <div
                                                    className={styles.checkbox}
                                                >
                                                    <span
                                                        className={cn(
                                                            styles.checkbox_wrap,
                                                            {
                                                                [styles.checked]:
                                                                    isCheckboxChecked(
                                                                        family.patients_ability_and_capability_to_respond_to_treatment,
                                                                        "Consistent, good-quality patient-oriented evidence"
                                                                    ),
                                                            }
                                                        )}
                                                    >
                                                        <CheckIcon />
                                                    </span>
                                                    Consistent, good-quality
                                                    patient-oriented evidence
                                                </div>
                                            )}
                                            {isCheckboxChecked(
                                                family.patients_ability_and_capability_to_respond_to_treatment,
                                                "Diminished/poor or limited-quality patient-oriented evidence"
                                            ) && (
                                                <div
                                                    className={styles.checkbox}
                                                >
                                                    <span
                                                        className={cn(
                                                            styles.checkbox_wrap,
                                                            {
                                                                [styles.checked]:
                                                                    isCheckboxChecked(
                                                                        family.patients_ability_and_capability_to_respond_to_treatment,
                                                                        "Diminished/poor or limited-quality patient-oriented evidence"
                                                                    ),
                                                            }
                                                        )}
                                                    >
                                                        <CheckIcon />
                                                    </span>
                                                    Diminished/poor or
                                                    limited-quality
                                                    patient-oriented evidence
                                                </div>
                                            )}
                                        </div>
                                    ) : (
                                        "--"
                                    )
                                }
                            />
                            <NoteContentItem
                                headerText={
                                    FAMILY_INTAKE_FAMILY_NOTE_DETAILS.details_for_diminished_poor_limited_quality_patient_oriented_evidence
                                }
                                fieldId={`details_for_diminished_poor_limited_quality_patient_oriented_evidence-${noteContent.family[i].family_id}`}
                                addendums={
                                    fieldAddendums?.[
                                        `details_for_diminished_poor_limited_quality_patient_oriented_evidence-${noteContent.family[i].family_id}`
                                    ]
                                }
                                content={
                                    family.details_for_diminished_poor_limited_quality_patient_oriented_evidence ||
                                    "--"
                                }
                            />
                        </React.Fragment>
                    )
                )}
            <p className={styles.sectionheader} id={INTAKE_SECTIONS.summary}>
                Summary
            </p>
            <NoteContentItem
                headerText={
                    FAMILY_INTAKE_NOTE_DETAILS.people_present_at_session
                }
                fieldId="people_present_at_session"
                addendums={fieldAddendums?.people_present_at_session}
                content={
                    noteContent?.people_present_at_session?.length > 0 ? (
                        <div className={styles.checkboxes}>
                            {isCheckboxChecked(
                                noteContent?.people_present_at_session,
                                "Patient"
                            ) && (
                                <div className={styles.checkbox}>
                                    <span
                                        className={cn(styles.checkbox_wrap, {
                                            [styles.checked]: isCheckboxChecked(
                                                noteContent?.people_present_at_session,
                                                "Patient"
                                            ),
                                        })}
                                    >
                                        <CheckIcon />
                                    </span>
                                    Patient
                                </div>
                            )}
                            {isCheckboxChecked(
                                noteContent?.people_present_at_session,
                                "Mother"
                            ) && (
                                <div className={styles.checkbox}>
                                    <span
                                        className={cn(styles.checkbox_wrap, {
                                            [styles.checked]: isCheckboxChecked(
                                                noteContent?.people_present_at_session,
                                                "Mother"
                                            ),
                                        })}
                                    >
                                        <CheckIcon />
                                    </span>
                                    Mother
                                </div>
                            )}
                            {isCheckboxChecked(
                                noteContent?.people_present_at_session,
                                "Stepfather"
                            ) && (
                                <div className={styles.checkbox}>
                                    <span
                                        className={cn(styles.checkbox_wrap, {
                                            [styles.checked]: isCheckboxChecked(
                                                noteContent?.people_present_at_session,
                                                "Stepfather"
                                            ),
                                        })}
                                    >
                                        <CheckIcon />
                                    </span>
                                    Stepfather
                                </div>
                            )}
                            {isCheckboxChecked(
                                noteContent?.people_present_at_session,
                                "Spouse"
                            ) && (
                                <div className={styles.checkbox}>
                                    <span
                                        className={cn(styles.checkbox_wrap, {
                                            [styles.checked]: isCheckboxChecked(
                                                noteContent?.people_present_at_session,
                                                "Spouse"
                                            ),
                                        })}
                                    >
                                        <CheckIcon />
                                    </span>
                                    Spouse
                                </div>
                            )}
                            {isCheckboxChecked(
                                noteContent?.people_present_at_session,
                                "Father"
                            ) && (
                                <div className={styles.checkbox}>
                                    <span
                                        className={cn(styles.checkbox_wrap, {
                                            [styles.checked]: isCheckboxChecked(
                                                noteContent?.people_present_at_session,
                                                "Father"
                                            ),
                                        })}
                                    >
                                        <CheckIcon />
                                    </span>
                                    Father
                                </div>
                            )}
                            {isCheckboxChecked(
                                noteContent?.people_present_at_session,
                                "Stepmother"
                            ) && (
                                <div className={styles.checkbox}>
                                    <span
                                        className={cn(styles.checkbox_wrap, {
                                            [styles.checked]: isCheckboxChecked(
                                                noteContent?.people_present_at_session,
                                                "Stepmother"
                                            ),
                                        })}
                                    >
                                        <CheckIcon />
                                    </span>
                                    Stepmother
                                </div>
                            )}
                            {isCheckboxChecked(
                                noteContent?.people_present_at_session,
                                "Partner"
                            ) && (
                                <div className={styles.checkbox}>
                                    <span
                                        className={cn(styles.checkbox_wrap, {
                                            [styles.checked]: isCheckboxChecked(
                                                noteContent?.people_present_at_session,
                                                "Partner"
                                            ),
                                        })}
                                    >
                                        <CheckIcon />
                                    </span>
                                    Partner
                                </div>
                            )}
                            {isCheckboxChecked(
                                noteContent?.people_present_at_session,
                                "Guardian"
                            ) && (
                                <div className={styles.checkbox}>
                                    <span
                                        className={cn(styles.checkbox_wrap, {
                                            [styles.checked]: isCheckboxChecked(
                                                noteContent?.people_present_at_session,
                                                "Guardian"
                                            ),
                                        })}
                                    >
                                        <CheckIcon />
                                    </span>
                                    Guardian
                                </div>
                            )}
                            {isCheckboxChecked(
                                noteContent?.people_present_at_session,
                                "Other"
                            ) && (
                                <div className={styles.checkbox}>
                                    <span
                                        className={cn(styles.checkbox_wrap, {
                                            [styles.checked]: isCheckboxChecked(
                                                noteContent?.people_present_at_session,
                                                "Other"
                                            ),
                                        })}
                                    >
                                        <CheckIcon />
                                    </span>
                                    Other
                                </div>
                            )}
                        </div>
                    ) : (
                        "--"
                    )
                }
            />
            <NoteContentItem
                headerText={
                    FAMILY_INTAKE_NOTE_DETAILS.other_person_present_at_session
                }
                fieldId="other_person_present_at_session"
                addendums={fieldAddendums?.other_person_present_at_session}
                content={noteContent.other_person_present_at_session || "--"}
            />
            <NoteContentItem
                headerText={
                    FAMILY_INTAKE_NOTE_DETAILS.type_of_treatment_recommended
                }
                fieldId="type_of_treatment_recommended"
                addendums={fieldAddendums?.type_of_treatment_recommended}
                content={
                    noteContent?.type_of_treatment_recommended?.length > 0 ? (
                        <div className={styles.checkboxes}>
                            {isCheckboxChecked(
                                noteContent?.type_of_treatment_recommended,
                                "Individual"
                            ) && (
                                <div className={styles.checkbox}>
                                    <span
                                        className={cn(styles.checkbox_wrap, {
                                            [styles.checked]: isCheckboxChecked(
                                                noteContent?.type_of_treatment_recommended,
                                                "Individual"
                                            ),
                                        })}
                                    >
                                        <CheckIcon />
                                    </span>
                                    Individual
                                </div>
                            )}
                            {isCheckboxChecked(
                                noteContent?.type_of_treatment_recommended,
                                "Family"
                            ) && (
                                <div className={styles.checkbox}>
                                    <span
                                        className={cn(styles.checkbox_wrap, {
                                            [styles.checked]: isCheckboxChecked(
                                                noteContent?.type_of_treatment_recommended,
                                                "Family"
                                            ),
                                        })}
                                    >
                                        <CheckIcon />
                                    </span>
                                    Family
                                </div>
                            )}
                            {isCheckboxChecked(
                                noteContent?.type_of_treatment_recommended,
                                "Group"
                            ) && (
                                <div className={styles.checkbox}>
                                    <span
                                        className={cn(styles.checkbox_wrap, {
                                            [styles.checked]: isCheckboxChecked(
                                                noteContent?.type_of_treatment_recommended,
                                                "Group"
                                            ),
                                        })}
                                    >
                                        <CheckIcon />
                                    </span>
                                    Group
                                </div>
                            )}
                            {isCheckboxChecked(
                                noteContent?.type_of_treatment_recommended,
                                "Collateral/Parenting"
                            ) && (
                                <div className={styles.checkbox}>
                                    <span
                                        className={cn(styles.checkbox_wrap, {
                                            [styles.checked]: isCheckboxChecked(
                                                noteContent?.type_of_treatment_recommended,
                                                "Collateral/Parenting"
                                            ),
                                        })}
                                    >
                                        <CheckIcon />
                                    </span>
                                    Collateral/Parenting
                                </div>
                            )}
                        </div>
                    ) : (
                        "--"
                    )
                }
            />
            <NoteContentItem
                headerText={
                    FAMILY_INTAKE_NOTE_DETAILS.treatment_modality_intervention
                }
                fieldId="treatment_modality_intervention"
                addendums={fieldAddendums?.treatment_modality_intervention}
                content={
                    noteContent?.treatment_modality_intervention?.length > 0 ? (
                        <div className={styles.checkboxes}>
                            {isCheckboxChecked(
                                noteContent?.treatment_modality_intervention,
                                "Cognitive/Behavioral"
                            ) && (
                                <div className={styles.checkbox}>
                                    <span
                                        className={cn(styles.checkbox_wrap, {
                                            [styles.checked]: isCheckboxChecked(
                                                noteContent?.treatment_modality_intervention,
                                                "Cognitive/Behavioral"
                                            ),
                                        })}
                                    >
                                        <CheckIcon />
                                    </span>
                                    Cognitive/Behavioral
                                </div>
                            )}
                            {isCheckboxChecked(
                                noteContent?.treatment_modality_intervention,
                                "Behavioral Modification"
                            ) && (
                                <div className={styles.checkbox}>
                                    <span
                                        className={cn(styles.checkbox_wrap, {
                                            [styles.checked]: isCheckboxChecked(
                                                noteContent?.treatment_modality_intervention,
                                                "Behavioral Modification"
                                            ),
                                        })}
                                    >
                                        <CheckIcon />
                                    </span>
                                    Behavioral Modification
                                </div>
                            )}
                            {isCheckboxChecked(
                                noteContent?.treatment_modality_intervention,
                                "Supportive"
                            ) && (
                                <div className={styles.checkbox}>
                                    <span
                                        className={cn(styles.checkbox_wrap, {
                                            [styles.checked]: isCheckboxChecked(
                                                noteContent?.treatment_modality_intervention,
                                                "Supportive"
                                            ),
                                        })}
                                    >
                                        <CheckIcon />
                                    </span>
                                    Supportive
                                </div>
                            )}
                            {isCheckboxChecked(
                                noteContent?.treatment_modality_intervention,
                                "Marital/Couples Therapy"
                            ) && (
                                <div className={styles.checkbox}>
                                    <span
                                        className={cn(styles.checkbox_wrap, {
                                            [styles.checked]: isCheckboxChecked(
                                                noteContent?.treatment_modality_intervention,
                                                "Marital/Couples Therapy"
                                            ),
                                        })}
                                    >
                                        <CheckIcon />
                                    </span>
                                    Marital/Couples Therapy
                                </div>
                            )}
                            {isCheckboxChecked(
                                noteContent?.treatment_modality_intervention,
                                "Family Therapy"
                            ) && (
                                <div className={styles.checkbox}>
                                    <span
                                        className={cn(styles.checkbox_wrap, {
                                            [styles.checked]: isCheckboxChecked(
                                                noteContent?.treatment_modality_intervention,
                                                "Family Therapy"
                                            ),
                                        })}
                                    >
                                        <CheckIcon />
                                    </span>
                                    Family Therapy
                                </div>
                            )}
                            {isCheckboxChecked(
                                noteContent?.treatment_modality_intervention,
                                "Stress Management"
                            ) && (
                                <div className={styles.checkbox}>
                                    <span
                                        className={cn(styles.checkbox_wrap, {
                                            [styles.checked]: isCheckboxChecked(
                                                noteContent?.treatment_modality_intervention,
                                                "Stress Management"
                                            ),
                                        })}
                                    >
                                        <CheckIcon />
                                    </span>
                                    Stress Management
                                </div>
                            )}
                            {isCheckboxChecked(
                                noteContent?.treatment_modality_intervention,
                                "Psychodynamic"
                            ) && (
                                <div className={styles.checkbox}>
                                    <span
                                        className={cn(styles.checkbox_wrap, {
                                            [styles.checked]: isCheckboxChecked(
                                                noteContent?.treatment_modality_intervention,
                                                "Psychodynamic"
                                            ),
                                        })}
                                    >
                                        <CheckIcon />
                                    </span>
                                    Psychodynamic
                                </div>
                            )}
                            {isCheckboxChecked(
                                noteContent?.treatment_modality_intervention,
                                "Parent Training"
                            ) && (
                                <div className={styles.checkbox}>
                                    <span
                                        className={cn(styles.checkbox_wrap, {
                                            [styles.checked]: isCheckboxChecked(
                                                noteContent?.treatment_modality_intervention,
                                                "Parent Training"
                                            ),
                                        })}
                                    >
                                        <CheckIcon />
                                    </span>
                                    Parent Training
                                </div>
                            )}
                            {isCheckboxChecked(
                                noteContent?.treatment_modality_intervention,
                                "Crisis Intervention"
                            ) && (
                                <div className={styles.checkbox}>
                                    <span
                                        className={cn(styles.checkbox_wrap, {
                                            [styles.checked]: isCheckboxChecked(
                                                noteContent?.treatment_modality_intervention,
                                                "Crisis Intervention"
                                            ),
                                        })}
                                    >
                                        <CheckIcon />
                                    </span>
                                    Crisis Intervention
                                </div>
                            )}
                            {isCheckboxChecked(
                                noteContent?.treatment_modality_intervention,
                                "Other"
                            ) && (
                                <div className={styles.checkbox}>
                                    <span
                                        className={cn(styles.checkbox_wrap, {
                                            [styles.checked]: isCheckboxChecked(
                                                noteContent?.treatment_modality_intervention,
                                                "Other"
                                            ),
                                        })}
                                    >
                                        <CheckIcon />
                                    </span>
                                    Other
                                </div>
                            )}
                        </div>
                    ) : (
                        "--"
                    )
                }
            />
            <NoteContentItem
                headerText={
                    FAMILY_INTAKE_NOTE_DETAILS.other_treatment_modality_intervention
                }
                fieldId="other_treatment_modality_intervention"
                addendums={
                    fieldAddendums?.other_treatment_modality_intervention
                }
                content={
                    noteContent.other_treatment_modality_intervention || "--"
                }
            />
            <p
                className={styles.sectionheader}
                id={INTAKE_SECTIONS["treatment goals"]}
            >
                Treatment goals
            </p>
            <NoteContentItem
                headerText={FAMILY_INTAKE_NOTE_DETAILS.treatment_goal_one}
                fieldId="treatment_goal_one"
                addendums={fieldAddendums?.treatment_goal_one}
                content={noteContent.treatment_goal_one || "--"}
            />
            <NoteContentItem
                headerText={FAMILY_INTAKE_NOTE_DETAILS.treatment_goal_two}
                fieldId="treatment_goal_two"
                addendums={fieldAddendums?.treatment_goal_two}
                content={noteContent.treatment_goal_two || "--"}
            />
            <NoteContentItem
                headerText={FAMILY_INTAKE_NOTE_DETAILS.treatment_goal_three}
                fieldId="treatment_goal_three"
                addendums={fieldAddendums?.treatment_goal_three}
                content={noteContent.treatment_goal_three || "--"}
            />
            <NoteContentItem
                headerText={FAMILY_INTAKE_NOTE_DETAILS.frequency_of_sessions}
                fieldId="frequency_of_sessions"
                addendums={fieldAddendums?.frequency_of_sessions}
                content={noteContent.frequency_of_sessions || "--"}
            />

            <NoteContentItem
                headerText={
                    FAMILY_INTAKE_NOTE_DETAILS.other_frequency_of_sessions
                }
                fieldId="other_frequency_of_sessions"
                addendums={fieldAddendums?.other_frequency_of_sessions}
                content={noteContent.other_frequency_of_sessions || "--"}
            />

            <NoteContentItem
                headerText={FAMILY_INTAKE_NOTE_DETAILS.date_of_next_session}
                fieldId="date_of_next_session"
                addendums={fieldAddendums?.date_of_next_session}
                content={
                    noteContent.date_of_next_session
                        ? formatDate(noteContent.date_of_next_session, true)
                        : "--"
                }
            />
            <NoteContentItem
                headerText={FAMILY_INTAKE_NOTE_DETAILS.subjective_report}
                fieldId="subjective_report"
                addendums={fieldAddendums?.subjective_report}
                content={noteContent.subjective_report || "--"}
            />
            <NoteContentItem
                headerText={FAMILY_INTAKE_NOTE_DETAILS.summary}
                fieldId="summary"
                addendums={fieldAddendums?.summary}
                content={noteContent.summary || "--"}
            />
            <NoteContentItem
                headerText={FAMILY_INTAKE_NOTE_DETAILS.prognosis}
                fieldId="prognosis"
                addendums={fieldAddendums?.prognosis}
                content={noteContent.prognosis || "--"}
            />
            <NoteContentItem
                headerText={
                    FAMILY_INTAKE_NOTE_DETAILS.communication_factors_impacting_treatment
                }
                fieldId="communication_factors_impacting_treatment"
                addendums={
                    fieldAddendums?.communication_factors_impacting_treatment
                }
                content={
                    noteContent?.communication_factors_impacting_treatment
                        ?.length > 0 ? (
                        <div className={styles.checkboxes}>
                            <div className={styles.checkbox}>
                                <span
                                    className={cn(styles.checkbox_wrap, {
                                        [styles.checked]: isCheckboxChecked(
                                            noteContent?.communication_factors_impacting_treatment,
                                            "Need to manage maladaptive communication (e.g., related to high anxiety, high reactivity repeated questions, or disagreement) among participants"
                                        ),
                                    })}
                                >
                                    <CheckIcon />
                                </span>
                                Need to manage maladaptive communication (e.g.,
                                related to high anxiety, high reactivity
                                repeated questions, or disagreement) among
                                participants
                            </div>
                            <div className={styles.checkbox}>
                                <span
                                    className={cn(styles.checkbox_wrap, {
                                        [styles.checked]: isCheckboxChecked(
                                            noteContent?.communication_factors_impacting_treatment,
                                            "Caregiver emotions or behaviors interfered with the caregiver's understanding and ability"
                                        ),
                                    })}
                                >
                                    <CheckIcon />
                                </span>
                                Caregiver emotions or behaviors interfered with
                                the caregiver&apos;s understanding and ability
                            </div>
                            <div className={styles.checkbox}>
                                <span
                                    className={cn(styles.checkbox_wrap, {
                                        [styles.checked]: isCheckboxChecked(
                                            noteContent?.communication_factors_impacting_treatment,
                                            "Evidence or disclosure of a sentinel event and mandated reporting to a third party"
                                        ),
                                    })}
                                >
                                    <CheckIcon />
                                </span>
                                Evidence or disclosure of a sentinel event and
                                mandated reporting to a third party
                            </div>
                            <div className={styles.checkbox}>
                                <span
                                    className={cn(styles.checkbox_wrap, {
                                        [styles.checked]: isCheckboxChecked(
                                            noteContent?.communication_factors_impacting_treatment,
                                            "Use of play equipment or other physical devices to communicate with the client to overcome barriers to therapeutic or diagnostic interaction"
                                        ),
                                    })}
                                >
                                    <CheckIcon />
                                </span>
                                Use of play equipment or other physical devices
                                to communicate with the client to overcome
                                barriers to therapeutic or diagnostic
                                interaction
                            </div>
                            <div className={styles.checkbox}>
                                <span
                                    className={cn(styles.checkbox_wrap, {
                                        [styles.checked]: isCheckboxChecked(
                                            noteContent?.communication_factors_impacting_treatment,
                                            "Client has not developed, or lost, either the expressive language communication skills to explain their symptoms and respond to treatment"
                                        ),
                                    })}
                                >
                                    <CheckIcon />
                                </span>
                                Client has not developed, or lost, either the
                                expressive language communication skills to
                                explain their symptoms and respond to treatment
                            </div>
                            <div className={styles.checkbox}>
                                <span
                                    className={cn(styles.checkbox_wrap, {
                                        [styles.checked]: isCheckboxChecked(
                                            noteContent?.communication_factors_impacting_treatment,
                                            "Client lacks the receptive communication skills to understand the therapist when using typical language for communication"
                                        ),
                                    })}
                                >
                                    <CheckIcon />
                                </span>
                                Client lacks the receptive communication skills
                                to understand the therapist when using typical
                                language for communication
                            </div>
                        </div>
                    ) : null
                }
            />

            <NoteContentItem
                headerText={
                    FAMILY_INTAKE_NOTE_DETAILS.details_around_communication_factors_impacting_treatment
                }
                fieldId="details_around_communication_factors_impacting_treatment"
                addendums={
                    fieldAddendums?.details_around_communication_factors_impacting_treatment
                }
                content={
                    noteContent.details_around_communication_factors_impacting_treatment ||
                    "--"
                }
            />
        </>
    );
}
