import * as React from "react";
import {
    Control,
    Controller,
    FormState,
    UseFormRegister,
    UseFormSetValue,
    UseFormWatch,
} from "react-hook-form";
import { DatePicker, Input, RadioInput } from "@jhool-io/fe-components";
import {
    IClaimDetailsContent,
    IClaimInsuranceType,
    SingleClaim,
} from "../../../types/claims.types";
import { formatDate } from "../../../../../utils/helpers";

interface PersonalInfoFormProps {
    setValue: UseFormSetValue<IClaimDetailsContent>;
    register: UseFormRegister<IClaimDetailsContent>;
    formState: FormState<IClaimDetailsContent>;
    control: Control<IClaimDetailsContent, unknown>;
    watch: UseFormWatch<IClaimDetailsContent>;
    claimDetails: SingleClaim;
}

export default function PersonalInfoForm({
    setValue,
    claimDetails,
    register,
    control,
    watch,
    formState: { errors },
}: PersonalInfoFormProps) {
    const [hasAnotherPlan, setHasAnotherPlan] = React.useState(false);

    React.useEffect(() => {
        const claimContent = claimDetails.claim_content;

        // subscriber / insured details
        setValue(
            "insuranceType",
            claimContent.insuranceType as IClaimInsuranceType
        );
        setValue("subscriberId", claimContent.subscriberId);
        setValue(
            "subscriberFirstName",
            `${claimContent.subscriberFirstName ?? ""}`
        );
        setValue(
            "subscriberMiddleName",
            `${claimContent.subscriberMiddleName ?? ""}`
        );
        setValue(
            "subscriberLastName",
            `${claimContent.subscriberLastName ?? ""}`
        );

        setValue("subscriberAddress", claimContent.subscriberAddress ?? "");
        setValue("subscriberCity", claimContent.subscriberCity ?? "");
        setValue("subscriberState", claimContent.subscriberState ?? "");
        setValue("subscriberPhone", claimContent.subscriberPhone ?? "");
        setValue("subscriberZip", claimContent.subscriberZip ?? "");
        setValue("subscriberPolicyGroup", claimContent.subscriberPolicyGroup);
        setValue("insuranceGroupName", claimContent.insuranceGroupName);
        setValue("subscriberSex", claimContent.subscriberSex);
        setValue(
            "subscriberBirthdate",
            formatDate(claimContent.subscriberBirthdate, true, "MM/dd/yyyy")
        );

        // patient details
        setValue("patientFirstName", `${claimContent.patientFirstName ?? ""}`);
        setValue(
            "patientMiddleName",
            `${claimContent.patientMiddleName ?? ""}`
        );
        setValue("patientLastName", `${claimContent.patientLastName ?? ""}`);

        setValue("patientAddress", claimContent.patientAddress);
        setValue("patientCity", claimContent.patientCity);
        setValue("patientState", claimContent.patientState);
        setValue("patientPhone", claimContent.patientPhone);
        setValue("patientZip", claimContent.patientZip);
        setValue("patientSex", claimContent.patientSex);
        setValue(
            "patientBirthdate",
            formatDate(claimContent.patientBirthdate, true, "MM/dd/yyyy")
        );

        setValue(
            "patientRelationshipToSubscriber",
            claimContent.patientRelationshipToSubscriber
        );
        setValue(
            "isConditionRelatedToEmployment",
            claimContent.isConditionRelatedToEmployment || "N"
        );
        setValue(
            "isConditionAutoAccidentRelated",
            claimContent.isConditionAutoAccidentRelated || "N"
        );
        setValue("autoAccidentState", claimContent.autoAccidentState);
        setValue(
            "isConditionOtherAccidentRelated",
            claimContent.isConditionOtherAccidentRelated || "N"
        );
        setValue(
            "otherInsurancePlanProgram",
            claimContent.otherInsurancePlanProgram
        );

        setValue("otherSubscriberId", claimContent.otherSubscriberId);
        setValue(
            "otherSubscriberFirstName",
            `${claimContent.otherSubscriberFirstName ?? ""} ${
                claimContent.otherSubscriberMiddleName ?? ""
            } ${claimContent.otherSubscriberLastName}`
        );
        setValue("otherClaimId", claimContent.otherClaimId);

        setValue("patientSignature", claimContent.patientSignature);
        setValue(
            "patientSignatureDate",
            formatDate(claimContent.patientSignatureDate, true, "yyyy-MM-dd")
        );
        setValue("subscriberSignature", claimContent.subscriberSignature);
    }, [claimDetails.claim_content, setValue]);

    React.useEffect(() => {
        if (
            watch("otherInsurancePlanProgram") &&
            watch("otherSubscriberFirstName")
        ) {
            setHasAnotherPlan(true);
        }
    }, [watch]);

    return (
        <div className="py-48 md:px-24 border-b border-b-strokedark">
            <h4 className="text-sm font-semibold mb-24 uppercase md:sticky top-20 z-[4] text-primary">
                Personal & Insured Info
            </h4>

            <div className="flex flex-col md:flex-row gap-12 md:gap-32 mb-24">
                <div className="flex-1">
                    <h5
                        className="text-xs font-semibold
                    leading-tight mb-2
                    "
                    >
                        Insurance Type
                    </h5>
                </div>

                <div className="flex-[3]">
                    <div className="flex flex-wrap lg:grid grid-cols-3 gap-12 max-w-[80%]">
                        <Controller
                            control={control}
                            name="insuranceType"
                            render={({ field }) => (
                                <div className="relative">
                                    <RadioInput
                                        label="Medicare"
                                        checked={
                                            field.value ===
                                            IClaimInsuranceType.MEDICARE
                                        }
                                        onChange={() =>
                                            setValue(
                                                "insuranceType",
                                                IClaimInsuranceType.MEDICARE
                                            )
                                        }
                                        wrapperClass="min-w-[85px]"
                                    />
                                    <span className="text-primary text-xss font-semibold ml-1 absolute top-[50%] -translate-y-[50%] left-[90px] hidden md:block">
                                        (Medicare#)
                                    </span>
                                </div>
                            )}
                        />

                        <Controller
                            control={control}
                            name="insuranceType"
                            render={({ field }) => (
                                <div className="relative">
                                    <RadioInput
                                        label="Medicaid"
                                        checked={
                                            field.value ===
                                            IClaimInsuranceType.MEDICAID
                                        }
                                        onChange={() =>
                                            setValue(
                                                "insuranceType",
                                                IClaimInsuranceType.MEDICAID
                                            )
                                        }
                                        wrapperClass="min-w-[85px]"
                                    />
                                    <span className="text-primary text-xss font-semibold ml-1 absolute top-[50%] -translate-y-[50%] left-[90px] hidden md:block">
                                        (Medicaid#)
                                    </span>
                                </div>
                            )}
                        />

                        <Controller
                            control={control}
                            name="insuranceType"
                            render={({ field }) => (
                                <div className="relative">
                                    <RadioInput
                                        label="Tricare"
                                        checked={
                                            field.value ===
                                            IClaimInsuranceType.TRICARE
                                        }
                                        onChange={() =>
                                            setValue(
                                                "insuranceType",
                                                IClaimInsuranceType.TRICARE
                                            )
                                        }
                                        wrapperClass="min-w-[85px]"
                                    />
                                    <span className="text-primary text-xss font-semibold ml-1 absolute top-[50%] -translate-y-[50%] left-[90px] hidden md:block">
                                        (ID/DOD#)
                                    </span>
                                </div>
                            )}
                        />

                        <Controller
                            control={control}
                            name="insuranceType"
                            render={({ field }) => (
                                <div className="relative">
                                    <RadioInput
                                        label="Champva"
                                        checked={
                                            field.value ===
                                            IClaimInsuranceType.CHAMPVA
                                        }
                                        onChange={() =>
                                            setValue(
                                                "insuranceType",
                                                IClaimInsuranceType.CHAMPVA
                                            )
                                        }
                                        wrapperClass="min-w-[85px]"
                                    />
                                    <span className="text-primary text-xss font-semibold ml-1 absolute top-[50%] -translate-y-[50%] left-[90px] hidden md:block">
                                        (Member ID#)
                                    </span>
                                </div>
                            )}
                        />

                        <Controller
                            control={control}
                            name="insuranceType"
                            render={({ field }) => (
                                <div className="relative">
                                    <RadioInput
                                        label="Group Health Plan"
                                        checked={
                                            field.value ===
                                            IClaimInsuranceType.GROUP_HEALTH_PLAN
                                        }
                                        onChange={() =>
                                            setValue(
                                                "insuranceType",
                                                IClaimInsuranceType.GROUP_HEALTH_PLAN
                                            )
                                        }
                                        wrapperClass="min-w-[85px]"
                                    />
                                    <span className="text-primary text-xss font-semibold ml-1 absolute top-[50%] -translate-y-[50%] left-[140px] hidden md:block">
                                        (ID#)
                                    </span>
                                </div>
                            )}
                        />

                        <Controller
                            control={control}
                            name="insuranceType"
                            render={({ field }) => (
                                <div className="relative">
                                    <RadioInput
                                        label="FECA BLK LNG"
                                        checked={
                                            field.value ===
                                            IClaimInsuranceType.FECA_BLK_LNG
                                        }
                                        onChange={() =>
                                            setValue(
                                                "insuranceType",
                                                IClaimInsuranceType.FECA_BLK_LNG
                                            )
                                        }
                                        wrapperClass="min-w-[85px]"
                                    />
                                    <span className="text-primary text-xss font-semibold ml-1 absolute top-[50%] -translate-y-[50%] left-[120px] hidden md:block">
                                        (ID#)
                                    </span>
                                </div>
                            )}
                        />

                        <Controller
                            control={control}
                            name="insuranceType"
                            render={({ field }) => (
                                <div className="relative">
                                    <RadioInput
                                        label="Other"
                                        checked={
                                            field.value ===
                                            IClaimInsuranceType.OTHER
                                        }
                                        onChange={() =>
                                            setValue(
                                                "insuranceType",
                                                IClaimInsuranceType.OTHER
                                            )
                                        }
                                        wrapperClass="min-w-[85px]"
                                    />
                                    <span className="text-primary text-xss font-semibold ml-1  absolute top-[50%] -translate-y-[50%] left-[70px] hidden md:block">
                                        (ID#)
                                    </span>
                                </div>
                            )}
                        />
                    </div>
                </div>
            </div>

            <div className="flex flex-col md:flex-row gap-12 md:gap-32 mb-24">
                <div className="flex-1">
                    <h5 className="text-xs font-semibold leading-tight mb-2">
                        Insured&apos;s ID Number
                    </h5>
                </div>

                <div className="flex-[3] flex flex-wrap gap-12">
                    <Input
                        placeholder="AB1234567"
                        className="md:max-w-[40%]"
                        {...register("subscriberId")}
                        hasError={!!errors.subscriberId}
                        errorText={errors.subscriberId?.message}
                    />
                </div>
            </div>

            <div className="flex flex-col md:flex-row gap-12 md:gap-32 mb-24">
                <div className="flex-1">
                    <h5 className="text-xs font-semibold leading-tight mb-2">
                        Insured&apos;s Name
                    </h5>
                    <p className="text-gray mb-[0.6rem] text-xss font-semibold">
                        (Last Name, First name, Middle Initial)
                    </p>
                </div>

                <div className="flex-[3]">
                    <div className="flex flex-col md:flex-row gap-12 md:max-w-[80%]">
                        <Input
                            placeholder="Last Name"
                            {...register("subscriberLastName")}
                            hasError={!!errors.subscriberLastName}
                            errorText={errors.subscriberLastName?.message}
                        />

                        <Input
                            placeholder="First Name"
                            {...register("subscriberFirstName")}
                            hasError={!!errors.subscriberFirstName}
                            errorText={errors.subscriberFirstName?.message}
                        />

                        <Input
                            placeholder="Middle Initial"
                            {...register("subscriberMiddleName")}
                            hasError={!!errors.subscriberMiddleName}
                            errorText={errors.subscriberMiddleName?.message}
                        />
                    </div>
                </div>
            </div>

            <div className="flex flex-col md:flex-row gap-12 md:gap-32 mb-24">
                <div className="flex-1">
                    <h5 className="text-xs font-semibold leading-tight mb-2">
                        Insured&apos;s Address
                    </h5>
                    <p className="text-gray mb-[0.6rem] text-xss font-semibold">
                        (No., Street)
                    </p>
                </div>

                <div className="flex-[3]">
                    <div className="flex flex-wrap gap-12 md:max-w-[80%]">
                        <Input
                            placeholder="Brooklyn, Upper East Side, Financial District"
                            className="w-full"
                            {...register("subscriberAddress")}
                            hasError={!!errors.subscriberAddress}
                            errorText={errors.subscriberAddress?.message}
                        />
                        <div className="flex flex-col md:flex-row items-center gap-12 w-full">
                            <Input
                                placeholder="Brooklyn"
                                {...register("subscriberCity")}
                                hasError={!!errors.subscriberCity}
                                errorText={errors.subscriberCity?.message}
                            />
                            <Input
                                placeholder="NY"
                                {...register("subscriberState")}
                                hasError={!!errors.subscriberState}
                                errorText={errors.subscriberState?.message}
                            />
                            <Input
                                placeholder="(01) 159 969 739"
                                {...register("subscriberPhone")}
                                hasError={!!errors.subscriberPhone}
                                errorText={errors.subscriberPhone?.message}
                            />
                            <Input
                                placeholder="11208"
                                {...register("subscriberZip")}
                                hasError={!!errors.subscriberZip}
                                errorText={errors.subscriberZip?.message}
                            />
                        </div>
                    </div>
                </div>
            </div>

            <div className="flex flex-col md:flex-row gap-12 md:gap-32 mb-24">
                <div className="flex-1">
                    <h5 className="text-xs font-semibold leading-tight mb-2">
                        Insured&apos;s Policy Group or FECA Number
                    </h5>
                </div>

                <div className="flex-[3] flex flex-wrap gap-12">
                    <Input
                        placeholder="1234567"
                        className="md:max-w-[40%]"
                        {...register("subscriberPolicyGroup")}
                        hasError={!!errors.subscriberPolicyGroup}
                        errorText={errors.subscriberPolicyGroup?.message}
                    />
                </div>
            </div>

            <div className="flex flex-col md:flex-row gap-12 md:gap-32 mb-24">
                <div className="flex-1">
                    <h5 className="text-xs font-semibold leading-tight mb-2">
                        Insurance Plan Name or Program Name
                    </h5>
                </div>

                <div className="flex-[3] flex flex-wrap gap-12">
                    <Input
                        placeholder="Other"
                        className="md:max-w-[40%]"
                        {...register("insuranceGroupName")}
                        hasError={!!errors.insuranceGroupName}
                        errorText={errors.insuranceGroupName?.message}
                    />
                </div>
            </div>

            <div className="flex flex-col md:flex-row gap-12 md:gap-32 mb-24">
                <div className="flex-1">
                    <h5 className="text-xs font-semibold leading-tight mb-2">
                        Insured&apos;s Date of Birth
                    </h5>
                </div>

                <div className="flex-[3]">
                    <div className="flex flex-wrap gap-12 lg:max-w-[40%]">
                        <Controller
                            control={control}
                            name="subscriberBirthdate"
                            render={({ field }) => (
                                <DatePicker
                                    selected={
                                        field.value
                                            ? new Date(field.value)
                                            : null
                                    }
                                    value={field.value}
                                    onChange={(date) => {
                                        field.onChange(date);
                                    }}
                                />
                            )}
                        />
                    </div>
                </div>
            </div>

            <div className="flex flex-col md:flex-row gap-12 md:gap-32 mb-24">
                <div className="flex-1">
                    <h5 className="text-xs font-semibold leading-tight mb-2">
                        Insured&apos;s Sex
                    </h5>
                </div>

                <div className="flex-[3] flex flex-wrap gap-12">
                    <Controller
                        name="subscriberSex"
                        control={control}
                        render={({ field }) => (
                            <RadioInput
                                label="M"
                                checked={field.value === "M"}
                                onChange={() => setValue("subscriberSex", "M")}
                                wrapperClass="min-w-[125px]"
                            />
                        )}
                    />

                    <Controller
                        name="subscriberSex"
                        control={control}
                        render={({ field }) => (
                            <RadioInput
                                label="F"
                                checked={field.value === "F"}
                                onChange={() => setValue("subscriberSex", "F")}
                                wrapperClass="min-w-[125px]"
                            />
                        )}
                    />
                </div>
            </div>

            <div className="flex flex-col md:flex-row gap-12 md:gap-32 mb-24">
                <div className="flex-1">
                    <h5 className="text-xs font-semibold leading-tight mb-2">
                        Patient&apos;s Name
                    </h5>
                    <p className="text-gray mb-[0.6rem] text-xss font-semibold">
                        (Last Name, First name, Middle Initial)
                    </p>
                </div>

                <div className="flex-[3] ">
                    <div className="flex flex-col md:flex-row gap-12 md:max-w-[80%]">
                        <Input
                            placeholder="Last Name"
                            {...register("patientLastName")}
                            hasError={!!errors.patientLastName}
                            errorText={errors.patientLastName?.message}
                        />

                        <Input
                            placeholder="First Name"
                            {...register("patientFirstName")}
                            hasError={!!errors.patientFirstName}
                            errorText={errors.patientFirstName?.message}
                        />

                        <Input
                            placeholder="Middle Initial"
                            {...register("patientMiddleName")}
                            hasError={!!errors.patientMiddleName}
                            errorText={errors.patientMiddleName?.message}
                        />
                    </div>
                </div>
            </div>

            <div className="flex flex-col md:flex-row gap-12 md:gap-32 mb-24">
                <div className="flex-1">
                    <h5 className="text-xs font-semibold leading-tight mb-2">
                        Patient&apos;s Address
                    </h5>
                    <p className="text-gray mb-[0.6rem] text-xss font-semibold">
                        (No., Street)
                    </p>
                </div>

                <div className="flex-[3]">
                    <div className="flex flex-wrap gap-12 md:max-w-[80%]">
                        <Input
                            placeholder="Brooklyn, Upper East Side, Financial District"
                            className="w-full"
                            {...register("patientAddress")}
                            hasError={!!errors.patientAddress}
                            errorText={errors.patientAddress?.message}
                        />
                        <div className="flex flex-col md:flex-row items-center gap-12 w-full">
                            <Input
                                placeholder="Brooklyn"
                                {...register("patientCity")}
                                hasError={!!errors.patientCity}
                                errorText={errors.patientCity?.message}
                            />
                            <Input
                                placeholder="NY"
                                {...register("patientState")}
                                hasError={!!errors.patientState}
                                errorText={errors.patientState?.message}
                            />
                            <Input
                                placeholder="(01) 159 969 739"
                                {...register("patientPhone")}
                                hasError={!!errors.patientPhone}
                                errorText={errors.patientPhone?.message}
                            />
                            <Input
                                placeholder="11208"
                                {...register("patientZip")}
                                hasError={!!errors.patientZip}
                                errorText={errors.patientZip?.message}
                            />
                        </div>
                    </div>
                </div>
            </div>

            <div className="flex flex-col md:flex-row gap-12 md:gap-32 mb-24">
                <div className="flex-1">
                    <h5 className="text-xs font-semibold leading-tight mb-2">
                        Patient&apos;s Date of Birth
                    </h5>
                </div>

                <div className="flex-[3]">
                    <div className="flex flex-wrap gap-12 md:max-w-[40%]">
                        <Controller
                            control={control}
                            name="patientBirthdate"
                            render={({ field }) => (
                                <DatePicker
                                    selected={
                                        field.value
                                            ? new Date(field.value)
                                            : null
                                    }
                                    value={field.value}
                                    onChange={(date) => {
                                        field.onChange(date);
                                    }}
                                />
                            )}
                        />
                    </div>
                </div>
            </div>

            <div className="flex flex-col md:flex-row gap-12 md:gap-32 mb-24">
                <div className="flex-1">
                    <h5 className=" text-xs font-semibold leading-tight mb-2">
                        Patient&apos;s Sex
                    </h5>
                </div>

                <div className="flex-[3] flex flex-wrap gap-12">
                    <Controller
                        name="patientSex"
                        control={control}
                        render={({ field }) => (
                            <RadioInput
                                label="M"
                                checked={field.value === "M"}
                                onChange={() => setValue("patientSex", "M")}
                                wrapperClass="min-w-[120px]"
                            />
                        )}
                    />

                    <Controller
                        name="patientSex"
                        control={control}
                        render={({ field }) => (
                            <RadioInput
                                label="F"
                                checked={field.value === "F"}
                                onChange={() => setValue("patientSex", "F")}
                                wrapperClass="min-w-[120px]"
                            />
                        )}
                    />
                </div>
            </div>

            <div className="flex flex-col md:flex-row gap-12 md:gap-32 mb-24">
                <div className="flex-1">
                    <h5 className="text-xs font-semibold leading-tight mb-2">
                        Patient&apos;s Relationship to the Insured
                    </h5>
                </div>

                <div className="flex-[3] flex flex-wrap gap-12">
                    <Controller
                        control={control}
                        name="patientRelationshipToSubscriber"
                        render={({ field }) => (
                            <RadioInput
                                label="Self"
                                wrapperClass="min-w-[120px]"
                                checked={field.value === "18"}
                                onChange={() =>
                                    setValue(
                                        "patientRelationshipToSubscriber",
                                        "18"
                                    )
                                }
                            />
                        )}
                    />

                    <Controller
                        control={control}
                        name="patientRelationshipToSubscriber"
                        render={({ field }) => (
                            <RadioInput
                                label="Spouse"
                                wrapperClass="min-w-[120px]"
                                checked={field.value === "01"}
                                onChange={() =>
                                    setValue(
                                        "patientRelationshipToSubscriber",
                                        "01"
                                    )
                                }
                            />
                        )}
                    />

                    <Controller
                        control={control}
                        name="patientRelationshipToSubscriber"
                        render={({ field }) => (
                            <RadioInput
                                label="Child"
                                wrapperClass="min-w-[120px]"
                                checked={field.value === "19"}
                                onChange={() =>
                                    setValue(
                                        "patientRelationshipToSubscriber",
                                        "19"
                                    )
                                }
                            />
                        )}
                    />

                    <Controller
                        control={control}
                        name="patientRelationshipToSubscriber"
                        render={({ field }) => (
                            <RadioInput
                                label="Other"
                                wrapperClass="min-w-[120px]"
                                checked={field.value === "G8"}
                                onChange={() =>
                                    setValue(
                                        "patientRelationshipToSubscriber",
                                        "G8"
                                    )
                                }
                            />
                        )}
                    />
                </div>
            </div>

            <div className="flex flex-col md:flex-row gap-12 md:gap-32 mb-24">
                <div className="flex-1">
                    <h5 className="text-xs font-semibold leading-tight mb-2">
                        Is Patient&apos;s Condition related to
                    </h5>
                </div>

                <div className="flex-[3]">
                    <div className="flex flex-wrap gap-24 md:max-w-[80%]">
                        <div>
                            <h6 className="text-gray text-xs font-medium mb-4">
                                Employment
                            </h6>
                            <div className="flex items-center gap-12">
                                <Controller
                                    name="isConditionRelatedToEmployment"
                                    control={control}
                                    render={({ field }) => (
                                        <RadioInput
                                            label="Yes"
                                            wrapperClass="min-w-[80px]"
                                            onChange={() =>
                                                setValue(
                                                    "isConditionRelatedToEmployment",
                                                    "Y"
                                                )
                                            }
                                            checked={field.value === "Y"}
                                        />
                                    )}
                                />

                                <Controller
                                    name="isConditionRelatedToEmployment"
                                    control={control}
                                    render={({ field }) => (
                                        <RadioInput
                                            label="No"
                                            wrapperClass="min-w-[80px]"
                                            onChange={() =>
                                                setValue(
                                                    "isConditionRelatedToEmployment",
                                                    "N"
                                                )
                                            }
                                            checked={field.value === "N"}
                                        />
                                    )}
                                />
                            </div>
                        </div>

                        <div>
                            <h6 className="text-gray text-xs font-medium mb-4">
                                Auto accident
                            </h6>

                            <div className="flex items-center gap-12">
                                <Controller
                                    name="isConditionAutoAccidentRelated"
                                    control={control}
                                    render={({ field }) => (
                                        <RadioInput
                                            label="Yes"
                                            wrapperClass="min-w-[80px]"
                                            onChange={() =>
                                                setValue(
                                                    "isConditionAutoAccidentRelated",
                                                    "Y"
                                                )
                                            }
                                            checked={field.value === "Y"}
                                        />
                                    )}
                                />

                                <Controller
                                    name="isConditionAutoAccidentRelated"
                                    control={control}
                                    render={({ field }) => (
                                        <RadioInput
                                            label="No"
                                            wrapperClass="min-w-[80px]"
                                            onChange={() =>
                                                setValue(
                                                    "isConditionAutoAccidentRelated",
                                                    "N"
                                                )
                                            }
                                            checked={field.value === "N"}
                                        />
                                    )}
                                />
                            </div>
                        </div>

                        <div>
                            <h6 className="text-gray text-xs font-medium mb-4">
                                Other accident
                            </h6>

                            <div className="flex items-center gap-12">
                                <Controller
                                    control={control}
                                    name="isConditionOtherAccidentRelated"
                                    render={({ field }) => (
                                        <RadioInput
                                            label="Yes"
                                            wrapperClass="min-w-[80px]"
                                            onChange={() =>
                                                setValue(
                                                    "isConditionOtherAccidentRelated",
                                                    "Y"
                                                )
                                            }
                                            checked={field.value === "Y"}
                                        />
                                    )}
                                />

                                <Controller
                                    control={control}
                                    name="isConditionOtherAccidentRelated"
                                    render={({ field }) => (
                                        <RadioInput
                                            label="No"
                                            wrapperClass="min-w-[80px]"
                                            onChange={() =>
                                                setValue(
                                                    "isConditionOtherAccidentRelated",
                                                    "N"
                                                )
                                            }
                                            checked={field.value === "N"}
                                        />
                                    )}
                                />
                            </div>
                        </div>
                    </div>

                    {watch("isConditionAutoAccidentRelated") === "Y" ? (
                        <div className="w-full">
                            <h6 className="text-gray text-xs font-medium mb-4">
                                Place(State)
                            </h6>
                            <Input
                                placeholder="Brooklyn, Upper East Side, Financial District"
                                className="max-w-[40%]"
                                {...register("autoAccidentState")}
                                hasError={!!errors.autoAccidentState}
                                errorText={errors.autoAccidentState?.message}
                            />
                        </div>
                    ) : null}
                </div>
            </div>

            <div className="flex flex-col md:flex-row gap-12 md:gap-32 mb-24">
                <div className="flex-1">
                    <h5 className="text-xs font-semibold leading-tight mb-2">
                        Is there another Health Benefit Plan
                    </h5>
                </div>
                <div className="flex-[3] flex flex-wrap gap-12">
                    <RadioInput
                        label="Yes"
                        wrapperClass="min-w-[120px]"
                        checked={hasAnotherPlan}
                        onChange={() => setHasAnotherPlan(true)}
                    />

                    <RadioInput
                        label="No"
                        wrapperClass="min-w-[120px]"
                        checked={!hasAnotherPlan}
                        onChange={() => setHasAnotherPlan(false)}
                    />
                </div>
            </div>

            {hasAnotherPlan ? (
                <>
                    <div className="flex flex-col md:flex-row gap-12 md:gap-32 mb-24">
                        <div className="flex-1">
                            <h5 className="text-xs font-semibold leading-tight mb-2">
                                Other Insured&apos; Name
                            </h5>
                            <p className="text-gray mb-[0.6rem] text-xss font-semibold">
                                (Last Name, First name, Middle Initial)
                            </p>
                        </div>

                        <div className="flex-[3] flex flex-wrap gap-12">
                            <Input
                                placeholder="Woods, Nathaniel, A"
                                className="md:max-w-[40%]"
                                {...register("otherSubscriberFirstName")}
                                hasError={!!errors.otherSubscriberFirstName}
                                errorText={
                                    errors.otherSubscriberFirstName?.message
                                }
                            />
                        </div>
                    </div>

                    <div className="flex flex-col md:flex-row gap-12 md:gap-32 mb-24">
                        <div className="flex-1">
                            <h5 className="text-xs font-semibold leading-tight mb-2">
                                Other Insured&apos;s Policy Or Group Number
                            </h5>
                        </div>

                        <div className="flex-[3] flex flex-wrap gap-12">
                            <Input
                                placeholder="124110"
                                className="md:max-w-[40%]"
                                {...register("otherSubscriberId")}
                                hasError={!!errors.otherSubscriberId}
                                errorText={errors.otherSubscriberId?.message}
                            />
                        </div>
                    </div>

                    <div className="flex flex-col md:flex-row gap-12 md:gap-32 mb-24">
                        <div className="flex-1">
                            <h5 className="text-xs font-semibold leading-tight mb-2">
                                Insurance Plan Name or Program Name
                            </h5>
                        </div>

                        <div className="flex-[3] flex flex-wrap gap-12">
                            <Input
                                placeholder="Program Name"
                                className="md:max-w-[40%]"
                                {...register("otherInsurancePlanProgram")}
                                hasError={!!errors.otherInsurancePlanProgram}
                                errorText={
                                    errors.otherInsurancePlanProgram?.message
                                }
                            />
                        </div>
                    </div>
                </>
            ) : null}

            <div className="flex flex-col md:flex-row gap-12 md:gap-32 mb-24">
                <div className="flex-1">
                    <h5 className="text-xs font-semibold leading-tight mb-2">
                        Patient&apos;s or Authorized Person&apos;s Signature
                    </h5>
                    <p className="text-gray mb-[0.6rem] text-xss font-semibold">
                        I authorise the release of any medical or other
                        information necessary to process this claim. I also
                        request payment of government benefits either to myself
                        or to the party who accepts assignment below.
                    </p>
                </div>

                <div className="flex-[3] flex flex-wrap gap-12">
                    <div className="min-w-[260px]">
                        <h6 className="text-gray text-xs font-medium mb-4">
                            Signed
                        </h6>
                        <Input
                            placeholder="Signature"
                            {...register("patientSignature")}
                            hasError={!!errors.patientSignature}
                            errorText={errors.patientSignature?.message}
                        />
                    </div>

                    <div>
                        <h6 className="text-gray text-xs font-medium mb-4">
                            Date
                        </h6>
                        <div className="flex items-center gap-4">
                            <Controller
                                control={control}
                                name="patientSignatureDate"
                                render={({ field }) => (
                                    <DatePicker
                                        selected={new Date(field.value)}
                                        value={new Date(
                                            field.value
                                        ).toDateString()}
                                        onChange={(date) => {
                                            if (date) {
                                                setValue(
                                                    "patientSignatureDate",
                                                    formatDate(
                                                        new Date(
                                                            date
                                                        ).toDateString(),
                                                        true,
                                                        "yyyy-MM-dd"
                                                    )
                                                );
                                            }
                                        }}
                                    />
                                )}
                            />
                        </div>
                    </div>
                </div>
            </div>

            <div className="flex flex-col md:flex-row gap-12 md:gap-32 mb-24">
                <div className="flex-1">
                    <h5 className="text-xs font-semibold leading-tight mb-2">
                        Insured&apos;s or Authorized Person&apos;s Signature
                    </h5>
                    <p className="text-gray mb-[0.6rem] text-xss font-semibold">
                        I authorise payment of medical benefits to the
                        undersigned physician or supplier for services described
                        below.
                    </p>
                </div>

                <div className="flex-[3] flex flex-wrap gap-12">
                    <div className="min-w-[260px]">
                        <h6 className="text-gray text-xs font-medium mb-4">
                            Signed
                        </h6>
                        <Input
                            placeholder="Signature"
                            {...register("subscriberSignature")}
                            hasError={!!errors.subscriberSignature}
                            errorText={errors.subscriberSignature?.message}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}
