/* eslint-disable react/no-unstable-nested-components */
import * as React from "react";
import {
    Button,
    DatePicker,
    Input,
    RadioInput,
    Select,
} from "@jhool-io/fe-components";

import {
    Control,
    Controller,
    FormState,
    useFieldArray,
    UseFormRegister,
    UseFormSetValue,
    UseFormWatch,
} from "react-hook-form";
import { IClaimDetailsContent, SingleClaim } from "../../../types/claims.types";

import { formatDate, removeEnumUnderscore } from "../../../../../utils/helpers";
import { SessionPlaceOfServiceCode } from "../../../../../utils/types/session";
import { CPT_CODES } from "../../../../notes/types/notes.types";

interface ServicesInfoFormProps {
    setValue: UseFormSetValue<IClaimDetailsContent>;
    claimDetails: SingleClaim;
    register: UseFormRegister<IClaimDetailsContent>;
    formState: FormState<IClaimDetailsContent>;
    control: Control<IClaimDetailsContent, unknown>;
    watch: UseFormWatch<IClaimDetailsContent>;
}

export default function ServicesInfoForm({
    setValue,
    claimDetails,
    register,
    formState: { errors },
    control,
    watch,
}: ServicesInfoFormProps) {
    const { fields, append, remove } = useFieldArray({
        control,
        name: "serviceDetails",
    });

    type Options = {
        label: string;
        value: string;
    };

    const PlaceOfServiceOptions = [
        { label: "None", value: "" },
        ...Object.values(SessionPlaceOfServiceCode).map((place) => ({
            value: place.toUpperCase(),
            label: place,
        })),
    ];

    const CPTOptions = [
        { label: "None", value: "" },
        ...CPT_CODES.sort((a, b) => Number(a) - Number(b)).map((code) => ({
            label: removeEnumUnderscore(code),
            value: code,
        })),
    ];

    const DiagnosisPointersOptions = ["A", "B", "C"].map((option) => ({
        value: option,
        label: option,
    }));

    const modifierCodeOptions = [
        { value: "", label: "None" },
        ...["21", "22", "24", "25", "59", "93", "95", "99", "GT"].map(
            (code) => ({
                value: code,
                label: code,
            })
        ),
    ];

    const addNewRow = () => {
        append({
            fromDate: "",
            toDate: "",
            placeOfServiceCode: "",
            procedureCode: "",
            modifiers: [],
            diagnosisPointers: [],
            charge: 0,
            units: 0,
            renderingProviderNPI: "",
            renderingProviderNumQualifier: "",
            isEmergency: "",
            isEPSDT: "",
            isFamilyPlan: "",
            renderingProviderNumber: "",
            notes: "",
        });
    };

    React.useEffect(() => {
        const claimContent = claimDetails.claim_content;

        setValue("totalCharge", claimContent.totalCharge);
        setValue("amountPaid", claimContent.amountPaid);
        setValue("federalTaxIdType", claimContent.federalTaxIdType);
        setValue("federalTaxId", claimContent.federalTaxId);
        setValue(
            "claimReferenceOrPatientAcctNo",
            claimContent.claimReferenceOrPatientAcctNo
        );
        setValue("acceptAssignment", claimContent.acceptAssignment);
        setValue(
            "renderingProviderSignatureDate",
            formatDate(
                claimContent.renderingProviderSignatureDate,
                true,
                "yyyy-MM-dd"
            )
        );
        setValue("facilityName", claimContent.facilityName);
        setValue("facilityAddress1", claimContent.facilityAddress1);
        setValue("facilityCity", claimContent.facilityCity);
        setValue("facilityState", claimContent.facilityState);
        setValue("facilityZip", claimContent.facilityZip);
        setValue("facilityNPI", claimContent.facilityNPI);
        setValue("billingProviderName", claimContent.billingProviderName);
        setValue(
            "billingProviderAddress1",
            claimContent.billingProviderAddress1
        );
        setValue("billingProviderCity", claimContent.billingProviderCity);
        setValue("billingProviderState", claimContent.billingProviderState);
        setValue("billingProviderPhone", claimContent.billingProviderPhone);
        setValue("billingProviderZipCode", claimContent.billingProviderZipCode);
        setValue("billingProviderNPI", claimContent.billingProviderNPI);

        if (claimDetails?.claim_content?.serviceDetails) {
            setValue("serviceDetails", claimContent.serviceDetails);
        }
    }, [claimDetails.claim_content, setValue]);

    return (
        <div className="py-48 md:px-24">
            <h4 className="text-sm font-semibold mb-24 uppercase md:sticky top-20 z-[4] text-primary">
                Services
            </h4>

            <div className="w-full overflow-x-auto">
                <div className="h-auto rounded-r8 bg-white border border-strokedark min-w-[1800px] overflow-x-auto">
                    <div className="grid grid-cols-[repeat(17,minmax(0,auto))] gap-x-16 px-12 py-12 bg-gray-50 text-gray font-semibold">
                        <div className="col-span-3">
                            A. <br /> Date(s) of service:
                        </div>
                        <div className="col-span-3" />
                        <div className="col-span-7">
                            C. <br /> Procedures, Services or Supplies (Explain
                            Unusual Circumstances)
                        </div>
                        <div className="col-span-4" />
                    </div>

                    <div className="grid grid-cols-[2fr_2fr_2fr_2fr_2fr_2fr_2fr_2fr_2fr_2fr_2fr_2fr_2fr_2fr_2fr_auto] gap-x-4 px-16 py-2 border-b border-strokelight text-gray font-semibold">
                        <span>
                            From <br />
                            <span>MM</span> <span>DD</span> <span>YY</span>
                        </span>
                        <span>
                            To <br />
                            <span>MM</span> <span>DD</span> <span>YY</span>
                        </span>
                        <span className="col-span-3">
                            B. <br /> Place of service
                        </span>
                        <span>CPT/HCPS</span>
                        <span>MODIFIER A</span>
                        <span>MODIFIER B</span>
                        <span>MODIFIER C</span>
                        <span>MODIFIER D</span>
                        <span>
                            D. <br /> Diagnosis Pointer
                        </span>
                        <span>
                            E. <br /> Charges
                        </span>
                        <span>
                            F. <br /> Days or Units
                        </span>
                        <span>
                            G. <br /> Rendering Provider ID
                        </span>
                        <span />
                    </div>

                    {fields.map((row, index) => (
                        <div
                            key={row.id}
                            className="grid grid-cols-[2fr_2fr_2fr_2fr_2fr_2fr_2fr_2fr_2fr_2fr_2fr_2fr_2fr_2fr_2fr_auto] gap-x-8 px-4 py-2 border-b border-strokelight"
                        >
                            <div>
                                <Controller
                                    control={control}
                                    name={`serviceDetails.${index}.fromDate`}
                                    render={({ field }) => {
                                        const fromDateFromIndex0 =
                                            watch("serviceDetails")[0]
                                                ?.fromDate || "";
                                        const selectedDate =
                                            index === 0
                                                ? field.value
                                                : fromDateFromIndex0;

                                        return (
                                            <DatePicker
                                                selected={
                                                    selectedDate
                                                        ? new Date(selectedDate)
                                                        : null
                                                }
                                                value={
                                                    selectedDate
                                                        ? formatDate(
                                                              new Date(
                                                                  selectedDate
                                                              ).toDateString(),
                                                              true,
                                                              "MM/dd/yyyy"
                                                          )
                                                        : ""
                                                }
                                                onChange={(date) => {
                                                    field.onChange(date);
                                                }}
                                                disabled
                                            />
                                        );
                                    }}
                                />
                            </div>

                            <div>
                                <Controller
                                    control={control}
                                    name={`serviceDetails.${index}.fromDate`}
                                    render={({ field }) => {
                                        const toDateFromIndex0 =
                                            watch("serviceDetails")[0]
                                                ?.fromDate || "";

                                        const selectedDate =
                                            index === 0
                                                ? field.value
                                                : toDateFromIndex0;

                                        return (
                                            <DatePicker
                                                selected={
                                                    selectedDate
                                                        ? new Date(selectedDate)
                                                        : null
                                                }
                                                value={
                                                    selectedDate
                                                        ? formatDate(
                                                              new Date(
                                                                  selectedDate
                                                              ).toDateString(),
                                                              true,
                                                              "MM/dd/yyyy"
                                                          )
                                                        : ""
                                                }
                                                onChange={(date) => {
                                                    field.onChange(date);
                                                }}
                                                disabled
                                            />
                                        );
                                    }}
                                />
                            </div>

                            <div className="col-span-3">
                                <Controller
                                    name={`serviceDetails.${index}.placeOfServiceCode`}
                                    control={control}
                                    defaultValue={
                                        PlaceOfServiceOptions.find(
                                            (pos) =>
                                                pos.value ===
                                                watch(
                                                    `serviceDetails.${index}.placeOfServiceCode`
                                                )
                                        )?.value
                                    }
                                    render={({ field }) => (
                                        <Select
                                            placeholder=""
                                            options={PlaceOfServiceOptions}
                                            onChange={(val) =>
                                                field.onChange(
                                                    (val as Options).value
                                                )
                                            }
                                            defaultValue={PlaceOfServiceOptions.find(
                                                (pos) =>
                                                    pos.value ===
                                                    watch(
                                                        `serviceDetails.${index}.placeOfServiceCode`
                                                    )
                                            )}
                                            isSearchable
                                            isLongListInDialog
                                        />
                                    )}
                                />
                            </div>

                            <div>
                                <Controller
                                    name={`serviceDetails.${index}.procedureCode`}
                                    control={control}
                                    defaultValue={
                                        CPTOptions.find(
                                            (code) =>
                                                code.value ===
                                                watch(
                                                    `serviceDetails.${index}.procedureCode`
                                                )
                                        )?.value
                                    }
                                    render={({ field }) => (
                                        <Select
                                            placeholder=""
                                            options={CPTOptions}
                                            onChange={(val) =>
                                                field.onChange(
                                                    (val as Options).value
                                                )
                                            }
                                            isSearchable
                                            defaultValue={CPTOptions.find(
                                                (code) =>
                                                    code.value ===
                                                    watch(
                                                        `serviceDetails.${index}.procedureCode`
                                                    )
                                            )}
                                            isLongListInDialog
                                        />
                                    )}
                                />
                            </div>

                            {["A", "B", "C", "D"].map((label, modIndex) => {
                                return (
                                    <div key={label}>
                                        <Controller
                                            name={`serviceDetails.${index}.modifiers.${modIndex}`}
                                            control={control}
                                            render={({ field }) => (
                                                <Select
                                                    placeholder=""
                                                    options={
                                                        modifierCodeOptions
                                                    }
                                                    onChange={(val) =>
                                                        field.onChange(
                                                            (val as Options)
                                                                .value
                                                        )
                                                    }
                                                    isSearchable
                                                    value={modifierCodeOptions.find(
                                                        (code) =>
                                                            code.value ===
                                                            field.value
                                                    )}
                                                    isLongListInDialog
                                                />
                                            )}
                                        />
                                    </div>
                                );
                            })}

                            <div>
                                <Controller
                                    name={`serviceDetails.${index}.diagnosisPointers`}
                                    control={control}
                                    defaultValue={
                                        watch(
                                            `serviceDetails.${index}.diagnosisPointers`
                                        ) ?? []
                                    }
                                    render={({ field }) => (
                                        <Select
                                            placeholder=""
                                            options={DiagnosisPointersOptions}
                                            onChange={(val) =>
                                                field.onChange([
                                                    (val as Options).value,
                                                ])
                                            }
                                            isSearchable
                                            defaultValue={DiagnosisPointersOptions.find(
                                                (code) =>
                                                    code.value ===
                                                    watch(
                                                        `serviceDetails.${index}.diagnosisPointers`
                                                    )?.[0]
                                            )}
                                            isLongListInDialog
                                        />
                                    )}
                                />
                            </div>

                            <div>
                                <Input
                                    {...register(
                                        `serviceDetails.${index}.charge`,
                                        {
                                            valueAsNumber: true,
                                        }
                                    )}
                                    type="number"
                                />
                            </div>

                            <div>
                                <Input
                                    {...register(
                                        `serviceDetails.${index}.units`,
                                        {
                                            valueAsNumber: true,
                                        }
                                    )}
                                    type="number"
                                />
                            </div>

                            <div>
                                <Input
                                    {...register(
                                        `serviceDetails.${index}.renderingProviderNPI`
                                    )}
                                />
                            </div>

                            <div>
                                <Button
                                    variant="normal"
                                    disabled={index === 0}
                                    onClick={() => remove(index)}
                                    className="flex items-center gap-6 border border-strokedark text-xs 
                                        hover:bg-strokedark"
                                >
                                    <span>-</span> Remove
                                </Button>
                            </div>
                        </div>
                    ))}

                    {fields.length < 5 && (
                        <div className="px-16 py-4 border-t border-strokelight">
                            <Button
                                onClick={addNewRow}
                                className="flex items-center gap-2 ml-auto text-xs"
                            >
                                <span>+</span> Add new
                            </Button>
                        </div>
                    )}
                </div>
            </div>

            <div className="flex flex-col md:flex-row gap-12 md:gap-32 mb-24 mt-32">
                <div className="flex-1">
                    <h5
                        className="text-xs font-semibold
                    leading-tight mb-2
                    "
                    >
                        Total Charge
                    </h5>
                </div>
                <div className="flex-[3] flex flex-wrap gap-12">
                    <div className="min-w-[260px]">
                        <h6 className="text-gray text-xs font-medium mb-4">
                            $ USD
                        </h6>

                        <Input
                            placeholder="00"
                            type="number"
                            {...register("totalCharge")}
                            hasError={!!errors.totalCharge}
                            errorText={errors.totalCharge?.message}
                            showCurrency
                        />
                    </div>
                </div>
            </div>

            <div className="flex flex-col md:flex-row gap-12 md:gap-32 mb-24 mt-32">
                <div className="flex-1">
                    <h5 className="text-xs font-semibold leading-tight mb-2">
                        Amount Paid
                    </h5>
                </div>
                <div className="flex-[3] flex flex-wrap gap-12">
                    <div className="min-w-[260px]">
                        <h6 className="text-gray text-xs font-medium mb-4">
                            $ USD
                        </h6>
                        <Input
                            placeholder="00"
                            type="number"
                            {...register("amountPaid")}
                            hasError={!!errors.amountPaid}
                            errorText={errors.amountPaid?.message}
                            showCurrency
                        />
                    </div>
                </div>
            </div>

            <div className="flex flex-col md:flex-row gap-12 md:gap-32 mb-24">
                <div className="flex-1">
                    <h5 className="text-xs font-semibold leading-tight mb-2">
                        Federal Tax I.D Type
                    </h5>
                </div>

                <div className="flex-[3] flex flex-wrap gap-12">
                    <Controller
                        name="federalTaxIdType"
                        control={control}
                        render={({ field }) => (
                            <RadioInput
                                label="SSN"
                                wrapperClass="min-w-[120px]"
                                checked={field.value === "S"}
                                onChange={() =>
                                    setValue("federalTaxIdType", "S")
                                }
                            />
                        )}
                    />

                    <Controller
                        name="federalTaxIdType"
                        control={control}
                        render={({ field }) => (
                            <RadioInput
                                label="EIN"
                                wrapperClass="min-w-[120px]"
                                checked={field.value === "E"}
                                onChange={() =>
                                    setValue("federalTaxIdType", "E")
                                }
                            />
                        )}
                    />
                </div>
            </div>

            <div className="flex flex-col md:flex-row gap-12 md:gap-32 mb-24 mt-32">
                <div className="flex-1">
                    <h5 className="text-xs font-semibold leading-tight mb-2">
                        Federal Tax ID
                    </h5>
                </div>
                <div className="flex-[3] flex flex-wrap gap-12">
                    <Input
                        placeholder="1086275B"
                        className="max-w-[40%]"
                        {...register("federalTaxId")}
                        hasError={!!errors.federalTaxId}
                        errorText={errors.federalTaxId?.message}
                    />
                </div>
            </div>

            <div className="flex flex-col md:flex-row gap-12 md:gap-32 mb-24 mt-32">
                <div className="flex-1">
                    <h5 className="text-xs font-semibold leading-tight mb-2">
                        Patient&apos;s Account Number
                    </h5>
                </div>
                <div className="flex-[3] flex flex-wrap gap-12">
                    <Input
                        placeholder="1086275B"
                        className="max-w-[40%]"
                        {...register("claimReferenceOrPatientAcctNo")}
                        hasError={!!errors.claimReferenceOrPatientAcctNo}
                        errorText={
                            errors.claimReferenceOrPatientAcctNo?.message
                        }
                    />
                </div>
            </div>

            <div className="flex flex-col md:flex-row gap-12 md:gap-32 mb-24">
                <div className="flex-1">
                    <h5 className="text-xs font-semibold leading-tight mb-2">
                        Accept Assignment
                    </h5>
                </div>

                <div className="flex-[3] flex flex-wrap gap-12">
                    <Controller
                        name="acceptAssignment"
                        control={control}
                        render={({ field }) => (
                            <RadioInput
                                label="Yes"
                                wrapperClass="min-w-[120px]"
                                checked={field.value === "Y"}
                                onChange={() =>
                                    setValue("acceptAssignment", "Y")
                                }
                            />
                        )}
                    />

                    <Controller
                        name="acceptAssignment"
                        control={control}
                        render={({ field }) => (
                            <RadioInput
                                label="No"
                                wrapperClass="min-w-[120px]"
                                checked={field.value === "N"}
                                onChange={() =>
                                    setValue("acceptAssignment", "N")
                                }
                            />
                        )}
                    />
                </div>
            </div>

            <div className="flex flex-col md:flex-row gap-12 md:gap-32 mb-24">
                <div className="flex-1">
                    <h5 className="text-xs font-semibold leading-tight mb-2">
                        Signature Of Physician and Supplier
                    </h5>
                    <p className="text-gray mb-[0.6rem] text-xss font-semibold">
                        I certify that the elements on the reverse apply to this
                        bill and are made a part thereof.
                    </p>
                </div>

                <div className="flex-[3] flex flex-wrap gap-12">
                    <div className="min-w-[260px]">
                        <h6 className="text-gray text-xs font-medium mb-4">
                            Signed
                        </h6>
                        <Input
                            placeholder="Signature"
                            {...register("referringProviderFirstName")}
                            hasError={!!errors.referringProviderFirstName}
                            errorText={
                                errors.referringProviderFirstName?.message
                            }
                        />
                    </div>

                    <div>
                        <h6 className="text-gray text-xs font-medium mb-4">
                            Date
                        </h6>
                        <div className="flex items-center">
                            <Controller
                                control={control}
                                name="renderingProviderSignatureDate"
                                render={({ field }) => {
                                    return (
                                        <DatePicker
                                            selected={new Date(field.value)}
                                            value={new Date(
                                                field.value
                                            ).toDateString()}
                                            onChange={(date) => {
                                                if (date) {
                                                    setValue(
                                                        "renderingProviderSignatureDate",
                                                        formatDate(
                                                            new Date(
                                                                date
                                                            ).toDateString(),
                                                            true,
                                                            "yyyy-MM-dd"
                                                        )
                                                    );
                                                }
                                            }}
                                        />
                                    );
                                }}
                            />
                        </div>
                    </div>
                </div>
            </div>

            <div className="flex flex-col md:flex-row gap-12 md:gap-32 mb-24">
                <div className="flex-1">
                    <h5 className="text-xs font-semibold leading-tight mb-2">
                        Service Facility Name
                    </h5>
                </div>

                <div className="flex-[3] flex flex-wrap gap-12">
                    <Input
                        placeholder="Service Facility Name"
                        className="max-w-[40%]"
                        {...register("facilityName")}
                        hasError={!!errors.facilityName}
                        errorText={errors.facilityName?.message}
                    />
                </div>
            </div>

            <div className="flex flex-col md:flex-row gap-12 md:gap-32 mb-24">
                <div className="flex-1">
                    <h5 className="text-xs font-semibold leading-tight mb-2">
                        Service Facility Info & PH#
                    </h5>
                </div>

                <div className="flex-[3]">
                    <div className="flex flex-wrap gap-12 md:max-w-[80%]">
                        <Input
                            placeholder="Brooklyn, Upper East Side, Financial District"
                            className="w-full"
                            {...register("facilityAddress1")}
                            hasError={!!errors.facilityAddress1}
                            errorText={errors.facilityAddress1?.message}
                        />
                        <div className="flex flex-col md:flex-row items-center gap-12 w-full">
                            <Input
                                placeholder="Brooklyn"
                                {...register("facilityCity")}
                                hasError={!!errors.facilityCity}
                                errorText={errors.facilityCity?.message}
                            />
                            <Input
                                placeholder="NY"
                                {...register("facilityState")}
                                hasError={!!errors.facilityState}
                                errorText={errors.facilityState?.message}
                            />
                            <Input
                                placeholder="11208"
                                {...register("facilityZip")}
                                hasError={!!errors.facilityZip}
                                errorText={errors.facilityZip?.message}
                            />
                        </div>

                        <div className=" w-full">
                            <h6 className="text-gray text-xs font-medium mb-4">
                                Facility NPI
                            </h6>
                            <Input
                                placeholder="1086275B"
                                className="max-w-[260px]"
                                {...register("facilityNPI")}
                                hasError={!!errors.facilityNPI}
                                errorText={errors.facilityNPI?.message}
                            />
                        </div>
                    </div>
                </div>
            </div>

            <div className="flex flex-col md:flex-row gap-12 md:gap-32 mb-24">
                <div className="flex-1">
                    <h5 className="text-xs font-semibold leading-tight mb-2">
                        Billing Provider Name
                    </h5>
                </div>

                <div className="flex-[3] flex flex-wrap gap-12">
                    <Input
                        placeholder="Billing Provider Name"
                        className="max-w-[40%]"
                        {...register("billingProviderName")}
                        hasError={!!errors.billingProviderName}
                        errorText={errors.billingProviderName?.message}
                    />
                </div>
            </div>

            <div className="flex flex-col md:flex-row gap-12 md:gap-32 mb-24">
                <div className="flex-1">
                    <h5 className="text-xs font-semibold leading-tight mb-2">
                        Billing Provider Info & PH#
                    </h5>
                </div>

                <div className="flex-[3]">
                    <div className="flex flex-wrap gap-12 md:max-w-[80%]">
                        <Input
                            placeholder="Brooklyn, Upper East Side, Financial District"
                            className="w-full"
                            {...register("billingProviderAddress1")}
                            hasError={!!errors.billingProviderAddress1}
                            errorText={errors.billingProviderAddress1?.message}
                        />
                        <div className="flex flex-col md:flex-row items-center gap-12 w-full">
                            <Input
                                placeholder="Brooklyn"
                                {...register("billingProviderCity")}
                                hasError={!!errors.billingProviderCity}
                                errorText={errors.billingProviderCity?.message}
                            />
                            <Input
                                placeholder="NY"
                                {...register("billingProviderState")}
                                hasError={!!errors.billingProviderState}
                                errorText={errors.billingProviderState?.message}
                            />
                            <Input
                                placeholder="(01) 159 969 739"
                                {...register("billingProviderPhone")}
                                hasError={!!errors.billingProviderPhone}
                                errorText={errors.billingProviderPhone?.message}
                            />
                            <Input
                                placeholder="11208"
                                {...register("billingProviderZipCode")}
                                hasError={!!errors.billingProviderZipCode}
                                errorText={
                                    errors.billingProviderZipCode?.message
                                }
                            />
                        </div>

                        <div className=" w-full">
                            <h6 className="text-gray text-xs font-medium mb-4">
                                NPI
                            </h6>
                            <Input
                                placeholder="1086275B"
                                className="max-w-[260px]"
                                {...register("billingProviderNPI")}
                                hasError={!!errors.billingProviderNPI}
                                errorText={errors.billingProviderNPI?.message}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
