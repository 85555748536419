import * as React from "react";
import { useParams } from "react-router-dom";
import { Document, Page } from "react-pdf";
import { Pagination } from "@jhool-io/fe-components";
import { useFetchBatchRemits } from "../../../hooks/remits.queries";
import { useFetchDocument } from "../../../../../hooks/queries";
import Navbar from "../../../../../components/Navbar/Navbar";
import { RemitSource } from "../../../types/remits.types";

export default function BatchEOB() {
    // Get id from url params
    const params = useParams();
    const remitId = params.remitId || "";
    const [documentPageNumber, setDocumentPageNumber] = React.useState(1);
    const [documentNumPages, setDocumentNumPages] = React.useState<
        null | number
    >(null);

    const batchRemitData = useFetchBatchRemits({
        batch_remit_id: remitId,
    });

    const batchRemitEOB = useFetchDocument(
        batchRemitData.data?.data[0]?.remit_batch_eob_url as string,
        Boolean(batchRemitData.data?.data[0]?.remit_batch_eob_url)
    );

    const decodedString = () => {
        if (batchRemitEOB.data?.data) {
            return Buffer.from(batchRemitEOB.data?.data, "base64").toString(
                "utf-8"
            );
        }
        return "";
    };

    const documentToDisplay = () => {
        if (
            batchRemitData.data?.data[0].remit_source ===
                RemitSource.ELECTRONIC &&
            batchRemitData.data?.data[0].remit_batch_eob_url &&
            batchRemitEOB.data?.data
        ) {
            return (
                <div className="whitespace-pre-wrap">
                    <pre>{decodedString()}</pre>
                </div>
            );
        }

        if (
            batchRemitData.data?.data[0].remit_source === RemitSource.MANUAL &&
            batchRemitData.data?.data[0].remit_batch_eob_url &&
            batchRemitEOB.data?.data
        ) {
            if (/^image/.test(batchRemitEOB.data?.content_type || "")) {
                return (
                    <>
                        {batchRemitEOB.isLoading && (
                            <span className=" text-sm flex items-center justify-center font-medium h-12">
                                Loading document....
                            </span>
                        )}
                        {batchRemitEOB.error && (
                            <span className="text-danger text-sm flex items-center justify-center font-medium  h-12">
                                Could not load document at this time
                            </span>
                        )}
                        {batchRemitEOB.data && (
                            <div className="h-full w-full">
                                <img
                                    className="w-full h-full object-contain"
                                    src={`data:image/png;base64,${batchRemitEOB.data?.data}`}
                                    alt={
                                        batchRemitData.data.data[0]
                                            .remittance_batch_id
                                    }
                                />
                            </div>
                        )}
                    </>
                );
            }

            return (
                <Document
                    error={
                        <span className="text-danger text-sm flex items-center justify-center font-medium  h-12">
                            Could not load pdf at this time
                        </span>
                    }
                    loading={
                        <span className=" text-sm flex items-center justify-center font-medium h-12">
                            Loading pdf..
                        </span>
                    }
                    onLoadSuccess={({ numPages: nextNumPages }) => {
                        setDocumentNumPages(nextNumPages);
                        setDocumentPageNumber(1);
                    }}
                    onItemClick={({ pageNumber: itemPageNumber }) => {
                        setDocumentPageNumber(Number(itemPageNumber));
                    }}
                    file={`data:image/png;base64,${batchRemitEOB.data?.data}`}
                >
                    <Page pageNumber={documentPageNumber} className="!h-auto" />
                    {documentNumPages && documentNumPages > 1 && (
                        <Pagination
                            totalCount={documentNumPages}
                            totalPages={documentNumPages}
                            currentPage={documentPageNumber}
                            onPageChange={(page) => setDocumentPageNumber(page)}
                            isCentered
                        />
                    )}
                </Document>
            );
        }
        return "";
    };

    return (
        <>
            <Navbar title="EOB" />
            <div className="page">{documentToDisplay()}</div>
        </>
    );
}
