/* eslint-disable react/no-unstable-nested-components */
import * as React from "react";
import { ColumnDef, Row } from "@tanstack/react-table";
import {
    Alert,
    Button,
    Card,
    Checkbox,
    Dialog,
    DialogContent,
    Dropdown,
    DropdownContent,
    DropdownItem,
    DropdownTrigger,
    Input,
    Pagination,
    Table,
    Tag,
} from "@jhool-io/fe-components";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useParams, useSearchParams } from "react-router-dom";
import { useQueryClient } from "@tanstack/react-query";
import { useForm } from "react-hook-form";
import {
    IBillingResponse,
    IMatchedPaymentResponse,
    ISplitPaymentPayload,
    ISplitPaymentPayloadSingleBill,
    IUnmatchedPaymentResponse,
    PaymentStatus,
} from "../../../../utils/types/billing";
import {
    buildSuccessMessageForMatchedPayments,
    cn,
    formatDate,
    getTotalAmountOfTableRowsSelected,
    handleRemoveEmptyParamFromQueryParams,
    makeStringFirstLetterCapital,
    removeEnumUnderscore,
    showMoneyInAppFormat,
} from "../../../../utils/helpers";
import { useFetchClientUnmatchedPayment } from "../../../../hooks/queries/billing";
import Skeleton from "../../../../components/Skeleton/Skeleton";
import ChevronDownIcon from "../../../../components/Icons/ChevronDown";
import {
    APP_COLORS,
    CPT_CODES_DESCRIPTION,
    LISTS_DEFAULT_LIMIT,
} from "../../../../utils/constants";
import CheckPrimaryColorIcon from "../../../../components/Icons/CheckPrimaryColor";
import ListState from "../../../../components/ListState/ListState";
import {
    useAddUnmatchedPayment,
    useSplitPayment,
} from "../../../../hooks/mutations/billing";
import useToast from "../../../../hooks/useToast";
import { IPaginatedApiResponse } from "../../../../utils/types/api-response";
import { NoteLabels, NoteTypes } from "../../../../utils/types/notes";
import InfoIcon from "../../../../components/Icons/InfoIcon";
import Tooltip from "../../../../components/Tooltip/Tooltip";
import { getBillStatusTag } from "../../helpers/billing.helpers";
import { BillStatus } from "../../types/billing.types";
import { useFetchInvoices } from "../../hooks/queries/billing.queries";

interface UnmatchedPaymentListProps {
    /** Invoice/bill balance */
    invoiceBalance: number;
    /** Invoice/bill in view id */
    invoiceId: string;
    /** Function to call after performing action in modal */
    onFormSubmit: () => void;
    /** Client session date of service */
    dateOfService: string;
}

const columns: ColumnDef<IUnmatchedPaymentResponse>[] = [
    {
        id: "select",
        header: ({ table }) => (
            <Checkbox
                handleChange={table.getToggleAllRowsSelectedHandler()}
                indeterminate={table.getIsSomeRowsSelected()}
                label=""
                name="row"
                value="row"
                isChecked={table.getIsAllRowsSelected()}
            />
        ),
        cell: ({ row }) => (
            <div>
                <Checkbox
                    handleChange={row.getToggleSelectedHandler()}
                    indeterminate={row.getIsSomeSelected()}
                    id={row.original.unmatched_payment_id}
                    label=""
                    name="row"
                    value="row"
                    isChecked={row.getIsSelected()}
                />
            </div>
        ),
    },

    {
        accessorKey: "payment_date",
        header: "PAYMENT DATE",
        cell: ({ row }) => (
            <span>{formatDate(row.original.payment_date) || "--"}</span>
        ),
    },
    {
        accessorKey: "amount",
        header: "AMOUNT",
        cell: ({ row }) => (
            <span>{showMoneyInAppFormat(row.original.amount) || "--"}</span>
        ),
    },
    {
        accessorKey: "payment_method",
        header: "PAYMENT METHOD",
        cell: ({ row }) => (
            <span>
                {makeStringFirstLetterCapital(row.original.payment_method) ||
                    "--"}
            </span>
        ),
    },
    {
        accessorKey: "auth_code",
        header: "AUTH CODE",
        cell: ({ row }) => <span>{row.original.auth_code || "--"}</span>,
    },
    {
        accessorKey: "processor_payment_status",
        header: "PROCESSOR PAYMENT STATUS",
        cell: ({ row }) => (
            <span>
                {makeStringFirstLetterCapital(
                    row?.original?.processor_payment_status || "--"
                )}
            </span>
        ),
    },
    {
        accessorKey: "payment_status",
        header: "BILL STATUS",
        cell: ({ row }) => {
            let bgColor: string;
            let textColor: string;

            switch (row.original.payment_status) {
                case PaymentStatus.COMPLETED:
                    textColor = "#00563E";
                    bgColor = "rgba(204, 250, 233, 0.50)";
                    break;
                case PaymentStatus.PENDING:
                    textColor = "#634D17";
                    bgColor = "rgba(247, 229, 164, 0.50)";
                    break;
                case PaymentStatus.FAILED:
                    textColor = "#981F41";
                    bgColor = "rgba(251, 199, 198, 0.50)";
                    break;
                case PaymentStatus.REFUNDED:
                    textColor = "#165574";
                    bgColor = "rgba(172, 222, 250, 0.50)";
                    break;
                case PaymentStatus.VOIDED:
                    textColor = "#981F41";
                    bgColor = "rgba(251, 199, 198, 0.50)";
                    break;
                default:
                    textColor = "#165574";
                    bgColor = "rgba(172, 222, 250, 0.50)";
            }

            return (
                <Tag
                    bgColor={bgColor}
                    textColor={textColor}
                    title={row.original.payment_status}
                    className="capitalize w-auto h-auto text-nowrap px-8 py-4 rounded-r4"
                />
            );
        },
    },
];

export default function UnmatchedPaymentList({
    invoiceBalance,
    invoiceId,
    onFormSubmit,
    dateOfService,
}: UnmatchedPaymentListProps) {
    const [searchParams, setSearchParams] = useSearchParams();

    const [showSplitPaymentModal, setShowSplitPaymentModal] =
        React.useState(false);

    // For single bill unmatched payments
    const [getSelectedBillPayments, setGetSelectedBillPayments] =
        React.useState<Row<IUnmatchedPaymentResponse>[]>();
    const [rowSelection, setRowSelection] = React.useState({});
    // For selected bills
    const [getSelectedBillsData, setGetSelectedBillsData] =
        React.useState<Row<IBillingResponse>[]>();
    const [rowSelectionInvoices, setRowSelectionInvoices] = React.useState({});

    const params = useParams();
    const clientId = params.clientId as string;

    const unmatchPageFilter = Number(searchParams.get("unmatch_page")) || 1;
    const unmatchPaymentStatusFilter = searchParams.get("payment_status") || "";
    const unmatchLimitFilter =
        Number(searchParams.get("unmatch_limit")) || LISTS_DEFAULT_LIMIT;

    const invoicesPageFilter = Number(searchParams.get("invoice_page")) || 1;
    const invoicesLimitFilter =
        Number(searchParams.get("invoice_limit")) || LISTS_DEFAULT_LIMIT;
    const canSplitPayment = searchParams.get("split_payment");

    // React-hook-form for split payment form
    const {
        register,
        handleSubmit,
        reset,
        formState: { errors },
        watch,
    } = useForm<ISplitPaymentPayloadSingleBill>({
        resolver: yupResolver(
            yup.object().shape({
                bill: yup.object().shape({
                    amount: yup
                        .string()
                        .matches(
                            /^(?!^\.)(\d+(\.\d+)?|\.\d+)$/,
                            "Please enter a valid amount"
                        ),
                }),
                invoices: yup.array().of(
                    yup.object().shape({
                        amount: yup
                            .string()
                            .matches(
                                /^(?!^\.)(\d+(\.\d+)?|\.\d+)$/,
                                "Please enter a valid amount"
                            ),
                    })
                ),
            })
        ),
        mode: "onChange",
    });

    const { data, isLoading, isSuccess, error } =
        useFetchClientUnmatchedPayment({
            page: unmatchPageFilter,
            payment_status: unmatchPaymentStatusFilter,
            limit: unmatchLimitFilter,
            client_id: clientId,
        });

    // Fetch invoices to split
    const invoices = useFetchInvoices({
        client_id: clientId,
        limit: invoicesLimitFilter,
        page: invoicesPageFilter,
    });

    // hook for matching payment
    const matchPayments = useAddUnmatchedPayment();

    // Mutation hook for splitting payment
    const splitPayment = useSplitPayment();

    // Query client
    const queryClient = useQueryClient();

    const { toast } = useToast();

    const formInvoices = watch("invoices");
    const bill = watch("bill");

    // Get selected unmatched payment to split
    const paymentInView =
        getSelectedBillPayments && getSelectedBillPayments[0]?.original;

    const total = getSelectedBillPayments?.reduce(
        (sum: number, item) => sum + item.original.amount,
        0
    );

    const handlePaymentStatusFilter = (paymentFilter: string) => {
        if (paymentFilter === "") searchParams.delete("payment_status");
        else searchParams.set("payment_status", paymentFilter);

        searchParams.set("unmatchPageFilter", "1");
        setSearchParams(searchParams);
    };

    // onChange handler for unmatched payments list page filter
    const handleUnmatchedPaymentPageChange = (page: number) => {
        searchParams.set("unmatch_page", String(page));
        setSearchParams(searchParams);
    };

    // onChange handler for invoice list page filter
    const handleInvoicePageChange = (page: number) => {
        searchParams.set("invoice_page", String(page));
        setSearchParams(searchParams);
    };

    const handleSplitPayment = () => {
        searchParams.set("split_payment", "true");
        setSearchParams(searchParams);
    };

    const handleCancelSplitPayment = () => {
        searchParams.delete("split_payment");
        setSearchParams(searchParams);
    };

    const handleToggleSplitPaymentModal = () => {
        setShowSplitPaymentModal((state) => !state);
    };

    const handleBreakdownEnableRowSelection = (row: Row<IBillingResponse>) => {
        return (
            row.original.coinsurance_amount_collected <
            row.original.coinsurance_amount
        );
    };

    // match payment handler
    const handleMatchPayment = (isPr100?: boolean) => {
        if (getSelectedBillPayments) {
            const selectedIds = getSelectedBillPayments.map(
                (item: Row<IUnmatchedPaymentResponse>) =>
                    item.original.unmatched_payment_id
            );
            const matchPaymentPayload = {
                unmatched_payment_matches: {
                    [invoiceId]: selectedIds,
                },
                is_pr_100: isPr100,
            };
            matchPayments.mutate(matchPaymentPayload, {
                onSuccess: (response) => {
                    queryClient.setQueryData<
                        IPaginatedApiResponse<IMatchedPaymentResponse>
                    >(["get-payment"], (prev) => {
                        const prevRequired =
                            prev as IPaginatedApiResponse<IMatchedPaymentResponse>;
                        return {
                            ...prevRequired,
                            invoice_status: response.status,
                        };
                    });
                    queryClient.invalidateQueries({
                        queryKey: ["get-payment"],
                    });
                    queryClient.setQueryData<
                        IPaginatedApiResponse<IMatchedPaymentResponse>
                    >(["client-unmatched"], (prev) => {
                        const prevRequired =
                            prev as IPaginatedApiResponse<IMatchedPaymentResponse>;
                        return {
                            ...prevRequired,
                            invoice_status: response.status,
                        };
                    });
                    queryClient.invalidateQueries({
                        queryKey: ["client-unmatched"],
                    });
                    queryClient.invalidateQueries({
                        queryKey: [
                            "invoices",
                            handleRemoveEmptyParamFromQueryParams({
                                invoice_id: invoiceId,
                            }),
                        ],
                    });

                    toast({
                        mode: "success",
                        message: `${buildSuccessMessageForMatchedPayments(
                            response.data.added_payments,
                            response.data.unadded_payments
                        )}`,
                    });
                    onFormSubmit();
                },
            });
        }
    };

    // Onsubmit function for split payment form
    const onSplitPaymentFormSubmit = (payload: ISplitPaymentPayload) => {
        if (getSelectedBillsData) {
            const dataToSend = {
                payment_id: paymentInView?.unmatched_payment_id || "",
                invoices: payload.invoices.map((invoice, i) => ({
                    amount: Number(invoice.amount),
                    invoice_id:
                        getSelectedBillsData &&
                        (getSelectedBillsData[i].original.invoice_id as string),
                })),
            };

            dataToSend.invoices = [
                ...dataToSend.invoices,
                {
                    amount: Number(bill.amount),
                    invoice_id: invoiceId,
                },
            ];

            splitPayment.mutate(dataToSend, {
                onSuccess: async (res) => {
                    toast({
                        mode: "success",
                        message: res.message || "Payment spliting successful",
                    });
                    await queryClient.invalidateQueries({
                        queryKey: ["unmatched"],
                    });
                    await queryClient.invalidateQueries({
                        queryKey: ["get-payment"],
                    });
                    handleToggleSplitPaymentModal();
                    onFormSubmit();
                },
                onError: (err) => {
                    toast({
                        mode: "error",
                        message:
                            err.response?.data.message ||
                            "Could not split payment at this time, please try again later",
                    });
                },
            });
        }
    };

    const invoicesColumns: ColumnDef<IBillingResponse>[] = [
        {
            id: "select",
            header: ({ table }) => (
                <Checkbox
                    handleChange={table.getToggleAllRowsSelectedHandler()}
                    indeterminate={table.getIsSomeRowsSelected()}
                    label=""
                    name="row"
                    value="row"
                    isChecked={table.getIsAllRowsSelected()}
                />
            ),
            cell: ({ row }) => (
                <div
                    hidden={
                        row.original.coinsurance_amount_collected >=
                        row.original.coinsurance_amount
                    }
                >
                    <Checkbox
                        handleChange={row.getToggleSelectedHandler()}
                        indeterminate={row.getIsSomeSelected()}
                        id={row.original.invoice_id}
                        label=""
                        name="row"
                        value="row"
                        isChecked={row.getIsSelected()}
                    />
                </div>
            ),
        },
        {
            header: " ",
            columns: [
                {
                    accessorKey: "client_name",
                    header: "CLIENT",
                    cell: ({ row }) => (
                        <div>
                            <div>
                                <span>
                                    {`${row.original.client.first_name} ${row.original.client.last_name}` ||
                                        "--"}
                                </span>
                            </div>
                        </div>
                    ),
                },
            ],
        },
        {
            header: " ",
            columns: [
                {
                    accessorKey: "date_of_service",
                    header: "DATE OF SERVICE",
                    cell: ({ row }) => (
                        <span>
                            {formatDate(row.original.date_of_service) || "--"}
                        </span>
                    ),
                },
            ],
        },
        {
            accessorKey: "cpt_code",
            header: "CPT CODE",
            cell: ({ row }) => {
                if (row.original.note_type === NoteTypes.CANCELLATION_NOTE) {
                    return "NO SHOW";
                }

                if (row.original.cpt_code) {
                    return (
                        <Tooltip
                            content={
                                CPT_CODES_DESCRIPTION[
                                    row.original.cpt_code as string
                                ] || ""
                            }
                            showArrow
                        >
                            <span
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                    columnGap: "5px",
                                }}
                            >
                                {removeEnumUnderscore(row.original.cpt_code)}
                                <InfoIcon />
                            </span>
                        </Tooltip>
                    );
                }

                return "--";
            },
        },
        {
            header: " ",
            columns: [
                {
                    accessorKey: "note_type",
                    header: "NOTE",
                    cell: ({ row }) =>
                        row.original.note_type
                            ? NoteLabels[row.original.note_type as string]
                            : "--",
                },
            ],
        },
        {
            header: " ",
            columns: [
                {
                    accessorKey: "provider_name",
                    header: "CLINICIAN",
                    cell: ({ row }) => (
                        <span>
                            {`${row.original.provider.first_name} ${row.original.provider.last_name}` ||
                                "--"}
                        </span>
                    ),
                },
            ],
        },
        {
            header: " ",
            columns: [
                {
                    accessorKey: "insurance_provider",
                    header: "INS PROVIDER",
                    cell: ({ row }) => (
                        <span>{row.original.insurance_provider || "--"}</span>
                    ),
                },
            ],
        },
        {
            header: "INSURANCE",
            columns: [
                {
                    accessorKey: "insurance_charge",
                    header: "CHARGE",
                    cell: ({ row }) =>
                        showMoneyInAppFormat(row.original.insurance_amount),
                },
                {
                    accessorKey: "insurance_amount_collected",
                    header: "COLLECTED",
                    cell: ({ row }) =>
                        showMoneyInAppFormat(
                            row.original.insurance_amount_collected
                        ),
                },
            ],
        },
        {
            header: "CO-INSURANCE",
            columns: [
                {
                    accessorKey: "coinsurance_charge",
                    header: "CHARGE",
                    cell: ({ row }) =>
                        showMoneyInAppFormat(row.original.coinsurance_amount),
                },
                {
                    accessorKey: "coinsurance_amount_collected",
                    header: "COLLECTED",
                    cell: ({ row }) =>
                        showMoneyInAppFormat(
                            row.original.coinsurance_amount_collected
                        ),
                },
            ],
        },

        {
            accessorKey: "invoice_status",
            header: "BILL STATUS",
            cell: ({ row }) => (
                <span>
                    {getBillStatusTag(
                        row.original.invoice_status as BillStatus
                    )}
                </span>
            ),
        },
    ];

    return (
        <>
            <Dialog open={showSplitPaymentModal}>
                <DialogContent
                    title="Split Payment"
                    classNames="px-0"
                    variant="center"
                    handleCloseDialog={() => {
                        handleToggleSplitPaymentModal();
                        reset();
                    }}
                    saveText="Split payment"
                    submitBtnFormValue="split-payment"
                    isSubmitBtnDisabled={
                        splitPayment.isLoading ||
                        (formInvoices &&
                            bill &&
                            [...formInvoices, bill].reduce(
                                (accumulator, currentValue) =>
                                    accumulator + Number(currentValue.amount),
                                0
                            ) !== paymentInView?.amount)
                    }
                    isCancelBtnDisabled={
                        splitPayment.isLoading ||
                        (formInvoices &&
                            bill &&
                            [...formInvoices, bill].reduce(
                                (accumulator, currentValue) =>
                                    accumulator + currentValue.amount,
                                0
                            ) !== paymentInView?.amount)
                    }
                >
                    <>
                        <div className="px-24">
                            <Alert
                                type="info"
                                title="Split payment on selected bills"
                                description={`Total amount of bills selected to split payment across must equal ${
                                    paymentInView
                                        ? showMoneyInAppFormat(
                                              paymentInView.amount
                                          )
                                        : "Payment amount"
                                }`}
                            />
                        </div>
                        <div className="mt-[22px]">
                            <p className="text-xs font-medium px-24 mb-12">
                                Input amount to split across bills
                            </p>
                            <form
                                onSubmit={handleSubmit(
                                    onSplitPaymentFormSubmit
                                )}
                                id="split-payment"
                            >
                                {getSelectedBillPayments && (
                                    <div className="px-24 py-16 border-t border-t-strokelight">
                                        <div className="flex justify-between text-xs font-semibold items-center mb-12">
                                            <p className="text-black">
                                                {`Open Bill: ${formatDate(
                                                    dateOfService
                                                )}`}
                                            </p>
                                            <p className="text-[#FD1414]">
                                                {`Balance: 
                                                    ${showMoneyInAppFormat(
                                                        Math.abs(invoiceBalance)
                                                    )}`}
                                            </p>
                                        </div>
                                        <Input
                                            {...register(`bill.amount`)}
                                            label="Split amount"
                                            hasError={!!errors?.bill?.amount}
                                            errorText={
                                                errors?.bill?.amount?.message
                                            }
                                            showCurrency
                                        />
                                    </div>
                                )}
                                {getSelectedBillsData?.map(
                                    (
                                        invoice: Row<IBillingResponse>,
                                        i: number
                                    ) => {
                                        const balance =
                                            invoice.original
                                                .coinsurance_amount -
                                                invoice.original
                                                    .coinsurance_amount_collected <=
                                            0
                                                ? 0
                                                : invoice.original
                                                      .coinsurance_amount -
                                                  invoice.original
                                                      .coinsurance_amount_collected;
                                        return (
                                            <div
                                                className="px-24 py-16 border-t border-t-strokelight"
                                                key={
                                                    invoice.original.invoice_id
                                                }
                                            >
                                                <div className="flex justify-between text-xs font-semibold items-center mb-12">
                                                    <p className="text-black">
                                                        {`Open Bill: ${formatDate(
                                                            invoice.original
                                                                .date_of_service
                                                        )}`}
                                                    </p>
                                                    <p className="text-[#FD1414]">
                                                        {`Balance: 
                                                    ${showMoneyInAppFormat(
                                                        balance
                                                    )}`}
                                                    </p>
                                                </div>
                                                <Input
                                                    {...register(
                                                        `invoices.${i}.amount`
                                                    )}
                                                    label="Split amount"
                                                    hasError={
                                                        !!errors?.invoices?.[i]
                                                            ?.amount
                                                    }
                                                    errorText={
                                                        errors?.invoices?.[i]
                                                            ?.amount?.message
                                                    }
                                                    showCurrency
                                                />
                                            </div>
                                        );
                                    }
                                )}
                            </form>
                        </div>
                    </>
                </DialogContent>
            </Dialog>
            <div
                className={cn({
                    "flex justify-between": canSplitPayment,
                })}
            >
                <div
                    className={cn("max-h-[90dvh] overflow-y-scroll", {
                        "w-[49%]": canSplitPayment,
                    })}
                >
                    <Card classNames="flex gap-x-20 mb-24">
                        <div className="flex flex-col items-start gap-y-4">
                            <span className="uppercase text-gray font-medium text-xs">
                                Total NO OF UNMATCHED
                            </span>
                            <span className="capitalize font-regular text-sm">
                                {isLoading && (
                                    <Skeleton
                                        containerTestId="unmatched-payments-loader"
                                        width={15}
                                        height={15}
                                    />
                                )}
                                {error && "0"}
                                {data?.total_count ||
                                    (!isLoading && !error && "0")}
                            </span>
                        </div>
                        <div className="flex flex-col items-start gap-y-4">
                            <span className="uppercase text-gray font-medium text-xs">
                                Total Amount of unmatched
                            </span>
                            <span className="capitalize font-regular text-sm">
                                {isLoading && (
                                    <Skeleton
                                        width={15}
                                        height={15}
                                        containerTestId="unmatched-payments-loader"
                                    />
                                )}
                                {error ? 0 : null}
                                {data?.total_amount ||
                                    (!isLoading && !error && "0")}
                            </span>
                        </div>
                    </Card>
                    <div className="bg-[#FBFBFB] p-16 mb-24">
                        <h3 className="font-semibold text-base capitalize mb-12">
                            Payment Details
                        </h3>
                        <Card classNames="flex gap-x-20">
                            <div className="flex flex-col items-start gap-y-4">
                                <span className="uppercase text-gray font-medium text-xs">
                                    Balance
                                </span>
                                <span
                                    className={cn(
                                        "capitalize font-regular text-sm",
                                        {
                                            "text-danger": invoiceBalance > 0,
                                        }
                                    )}
                                >
                                    {invoiceBalance <= 0 ? (
                                        showMoneyInAppFormat(
                                            Math.abs(invoiceBalance)
                                        )
                                    ) : (
                                        <>
                                            (
                                            {showMoneyInAppFormat(
                                                invoiceBalance
                                            )}
                                            )
                                        </>
                                    )}
                                </span>
                            </div>
                            <div className="flex flex-col items-start gap-y-4">
                                <span className="uppercase text-gray font-medium text-xs">
                                    SELECTED AMOUNT
                                </span>
                                <span className="capitalize font-regular text-sm">
                                    {showMoneyInAppFormat(total || 0)}
                                </span>
                            </div>
                            <div className="flex flex-col items-start gap-y-4">
                                <span className="uppercase text-gray font-medium text-xs">
                                    Filter By Payment Status
                                </span>
                                <Dropdown>
                                    <DropdownTrigger asChild>
                                        <Button
                                            variant="normal"
                                            size="auto"
                                            className="w-full px-16 justify-between h-32 rounded-[16px] shadow-morebtn relative gap-x-8 z-[1] text-sm data-[state=open]:border-primary-800 capitalize"
                                        >
                                            {unmatchPaymentStatusFilter ||
                                                "All Payments"}
                                            <ChevronDownIcon
                                                stroke={APP_COLORS.COLOR_BLACK}
                                            />
                                        </Button>
                                    </DropdownTrigger>
                                    <DropdownContent
                                        maxHeight={216}
                                        className="overflow-y-auto capitalize"
                                    >
                                        <DropdownItem
                                            className="flex items-center text-xs gap-x-8"
                                            onClick={() =>
                                                handlePaymentStatusFilter("")
                                            }
                                        >
                                            All Payments
                                            {!unmatchPaymentStatusFilter ? (
                                                <CheckPrimaryColorIcon />
                                            ) : null}
                                        </DropdownItem>
                                        {Object.values(PaymentStatus).map(
                                            (item) => (
                                                <DropdownItem
                                                    key={item}
                                                    className="flex items-center text-xs gap-x-8"
                                                    onClick={() =>
                                                        handlePaymentStatusFilter(
                                                            item
                                                        )
                                                    }
                                                >
                                                    {item}
                                                    {unmatchPaymentStatusFilter ===
                                                    item ? (
                                                        <CheckPrimaryColorIcon />
                                                    ) : null}
                                                </DropdownItem>
                                            )
                                        )}
                                    </DropdownContent>
                                </Dropdown>
                            </div>
                        </Card>
                        {total && total < Math.abs(invoiceBalance) ? (
                            <p className="my-2 text-xs text-danger">
                                The selected fee is lower than the client&apos;s
                                balance
                            </p>
                        ) : null}
                    </div>
                    {isLoading && (
                        <Skeleton
                            type="table"
                            count={5}
                            containerTestId="unmatched-payments-loader"
                        />
                    )}
                    {error && error?.response?.status !== 404 && (
                        <ListState
                            isError
                            stateHelperText="Try reloading this page to solve this issue"
                            errorMsg={
                                error?.response?.data.message ||
                                `Cannot display unmatched payments at this time please try again later`
                            }
                        />
                    )}
                    {data && isSuccess && data.data.length === 0 && (
                        <ListState
                            stateHelperText="Unmatched payments will appear here"
                            isError={false}
                            emptyMessage="There are no unmatched payments yet"
                        />
                    )}
                    {isSuccess && data && data.data.length > 0 && (
                        <>
                            {getTotalAmountOfTableRowsSelected(rowSelection)
                                .amount > 0 &&
                                !canSplitPayment && (
                                    <div className="flex gap-x-16 mb-12">
                                        <Button
                                            variant="primary"
                                            onClick={() => handleMatchPayment()}
                                            disabled={matchPayments.isLoading}
                                        >
                                            Match as coinsurance
                                        </Button>
                                        <Button
                                            variant="primary"
                                            onClick={() =>
                                                handleMatchPayment(true)
                                            }
                                            disabled={matchPayments.isLoading}
                                        >
                                            Match as PR100
                                        </Button>

                                        {getSelectedBillPayments &&
                                            getSelectedBillPayments.length ===
                                                1 &&
                                            !canSplitPayment && (
                                                <Button
                                                    variant="secondary"
                                                    onClick={handleSplitPayment}
                                                >
                                                    Split payment
                                                </Button>
                                            )}
                                    </div>
                                )}
                            <Table
                                columns={columns}
                                data={data?.data}
                                rowSelection={rowSelection}
                                setRowSelection={setRowSelection}
                                setGetSelectedData={setGetSelectedBillPayments}
                                hasPagination={data.total_count > 20}
                                enableRowSelection={!canSplitPayment}
                                pagination={
                                    <Pagination
                                        totalCount={data.total_count}
                                        totalPages={data.total_pages}
                                        currentPage={unmatchPageFilter}
                                        onPageChange={
                                            handleUnmatchedPaymentPageChange
                                        }
                                        limit={unmatchLimitFilter}
                                    />
                                }
                            />
                        </>
                    )}
                </div>
                {canSplitPayment && (
                    <div
                        className={cn("max-h-[90dvh] overflow-y-scroll", {
                            "w-[49%]": canSplitPayment,
                        })}
                    >
                        {invoices.error &&
                            invoices.error?.response?.status !== 404 && (
                                <ListState
                                    isError
                                    stateHelperText="Try reloading this page to solve this issue"
                                    errorMsg={
                                        invoices.error?.response?.data
                                            .message ||
                                        `Cannot display invoices at this time please try again later`
                                    }
                                />
                            )}
                        {invoices.isLoading && (
                            <Skeleton
                                type="table"
                                containerTestId="invoicelist-loader"
                                count={6}
                            />
                        )}
                        {invoices.isLoading && (
                            <Skeleton
                                type="table"
                                containerTestId="invoicelist-loader"
                                count={6}
                            />
                        )}
                        {invoices.isSuccess &&
                            invoices.data &&
                            invoices.data.data.length > 0 && (
                                <>
                                    <div className="flex items-center mb-12 gap-x-16">
                                        {getSelectedBillsData &&
                                            getSelectedBillsData.length > 0 && (
                                                <Button
                                                    onClick={
                                                        handleToggleSplitPaymentModal
                                                    }
                                                >
                                                    Complete split payment
                                                </Button>
                                            )}
                                        <Button
                                            variant="secondary"
                                            onClick={handleCancelSplitPayment}
                                        >
                                            Cancel
                                        </Button>
                                    </div>
                                    <Table
                                        columns={invoicesColumns}
                                        data={invoices.data?.data.filter(
                                            (item) =>
                                                item.invoice_id !== invoiceId
                                        )}
                                        rowSelection={rowSelectionInvoices}
                                        setRowSelection={
                                            setRowSelectionInvoices
                                        }
                                        setGetSelectedData={
                                            setGetSelectedBillsData
                                        }
                                        enableRowSelection={
                                            handleBreakdownEnableRowSelection
                                        }
                                        hasPagination={
                                            invoices.data.total_count > 20
                                        }
                                        pagination={
                                            <Pagination
                                                totalCount={
                                                    invoices.data.total_count
                                                }
                                                totalPages={
                                                    invoices.data.total_pages
                                                }
                                                currentPage={invoicesPageFilter}
                                                onPageChange={
                                                    handleInvoicePageChange
                                                }
                                                limit={invoicesLimitFilter}
                                            />
                                        }
                                    />
                                </>
                            )}
                    </div>
                )}
            </div>
        </>
    );
}
