import { FamilyIntakeNotePayload } from "../../../utils/types/notes";

export interface IEditCancellationNotePayload {
    reason: string;
    signature?: string;
}

export interface IEditFamilyIntakeNote {
    family: FamilyIntakeNotePayload[];
    signature?: string;
    appointment_location: string;
    diagnosis_codes: string[];
    secondary_diagnosis: string[];
    tertiary_diagnosis: string[];
    delivery_option: string;
    provider_location: string;
    other_provider_location?: string;
    other_delivery_option?: string;
    consent_was_received_for_the_teletherapy_session: boolean;
    the_teletherapy_session_was_conducted_via_video: boolean;
    duration: string;
    what_are_the_problem_you_are_seeking_help_for: string;
    people_present_at_session: string[];
    other_person_present_at_session: string;
    type_of_treatment_recommended: string[];
    treatment_modality_intervention: string[];
    other_treatment_modality_intervention: string;
    treatment_goal_one: string;
    treatment_goal_two: string;
    treatment_goal_three: string;
    frequency_of_sessions: string;
    other_frequency_of_sessions: string;
    date_of_next_session: string;
    subjective_report: string;
    summary: string;
    prognosis: string;
    communication_factors_impacting_treatment: string[];
    details_around_communication_factors_impacting_treatment: string;
}

export interface IEditGeneralNotesPayload {
    update_note_content: string;
    signature?: string;
    date_of_update: string;
}

export interface IEditPauseNotificationNote {
    pause_reason: string;
    signature?: string;
}

export interface IEditIndividualIntakeNote {
    appointment_location: string;
    diagnosis_codes: string[];
    secondary_diagnosis: string[];
    tertiary_diagnosis: string[];
    delivery_option: string;
    provider_location: string;
    other_provider_location?: string;
    consent_was_received_for_the_teletherapy_session: boolean;
    the_teletherapy_session_was_conducted_via_video: boolean;
    other_delivery_option?: string;
    duration: string;
    gender: string;
    other_gender: string;
    preferred_name: string;
    pronouns: string;
    other_sexual_orientation: string;
    religion: string;
    education: string;
    race: string;
    ethinicity: string;
    sexual_orientation: string;
    current_employment: string;
    current_employment_details: string;
    relationship_status: string;
    name_of_partner: string;
    emergency_contact_information: string;
    legal_problems: string;
    what_are_the_problem_you_are_seeking_help_for: string;
    anxiety_frequency: string;
    anxiety_details: string;
    panic_attacks_freqency: string;
    panic_attacks_details: string;
    depression_freqency: string;
    depression_details: string;
    selected_symptoms: string;
    other_symptoms: string[];
    physical_symptoms: string;
    medication_physical_psychiatric: string;
    sleeping_habits_and_concerns: string;
    difficulties_with_appetite_or_eating_patterns: string;
    parents_stepparents_history: string;
    siblings_history: string;
    grandparents_history: string;
    children_history: string;
    other_family_members_history: string;
    family_members_alcohol_abuse: string[];
    family_members_anxiety: string[];
    family_members_bipolar_disorder: string[];
    family_members_depression: string[];
    family_members_ptsd: string[];
    family_members_schizophrenia: string[];
    family_members_substance_abuse: string[];
    family_members_substance_abuse_substance: string;
    trauma_abuse_history: string;
    previous_mental_health_treatment_oupatient: string;
    impatient_psychiatric_hospitalization: string;
    relevant_medical_history: string;
    been_so_distressed_you_seriously_wished_to_end_your_life: string;
    has_anything_happened_recently_to_make_you_feel_you_dont_want_to_live: string;
    describe_has_anything_happened_recently_to_make_you_feel_you_dont_want_to_live: string;
    a_specific_plan_of_how_you_would_kill_yourself: string;
    access_to_weapons_or_means_of_hurting_self: string;
    made_serious_suicide_attempts: string;
    purposely_done_something_to_hurt_yourself: string;
    describe_done_something_to_hurt_yourself: string;
    heard_voices_telling_you_to_hurt_yourself: string;
    had_relatives_who_attempted_or_commited_sucide: string;
    had_thoughts_of_killing_or_seriously_hurting_someone: string;
    heard_voices_telling_you_to_hurt_others: string;
    hurt_someone_or_destroyed_property_on_purpose: string;
    slapped_kicked_punched_someone_with_intent_to_harm: string;
    been_arrested_or_detained_for_violent_behavior: string;
    been_to_jail_for_any_reason: string;
    been_on_probation_for_any_reason: string;
    do_you_have_access_to_guns: string;
    describe_do_you_have_access_to_guns: string;
    mental_health_appearance: string[];
    mental_health_movement: string[];
    mental_health_speech_behaviour: string[];
    mental_health_affect_mood: string[];
    mental_health_attention_concentration_effort: string[];
    mental_health_presentation_of_self: string[];
    mental_health_cognition: string[];
    patients_ability_and_capability_to_respond_to_treatment: string[];
    details_for_diminished_poor_limited_quality_patient_oriented_evidence: string;
    people_present_at_session: string[];
    other_person_present_at_session: string;
    type_of_treatment_recommended: string[];
    treatment_modality_intervention: string[];
    other_treatment_modality_intervention: string;
    treatment_goal_one: string;
    treatment_goal_two: string;
    treatment_goal_three: string;
    frequency_of_sessions: string;
    other_frequency_of_sessions: string;
    date_of_next_session: string;
    subjective_report: string;
    summary: string;
    prognosis: string;
    contact_information_for_both_parents: string;
    name_address_of_personal_physician: string;
    date_of_last_exam: string;
    communication_factors_impacting_treatment: string[];
    details_around_communication_factors_impacting_treatment: string;
    anxiety?: boolean;
    panic_attacks?: boolean;
    depression?: boolean;
    signature?: string;
}

export interface IEditRecordOfDisclosurePayload {
    duration: string;
    diagnosis_codes: string[];
    secondary_diagnosis?: string[];
    tertiary_diagnosis?: string[];
    disclosure_to_whom: string;
    disclosure_type: string;
    purpose_of_disclosure: string;
    type_disclosed: string;
    method_of_disclosure: string;
    record_of_disclosure_of_phi_ephi: string;
    consent_to_charge: boolean;
    signature?: string;
}

export interface IEditSafetyPlanPayload {
    warning_signs: string;
    internal_coping_strategies: string;
    people_and_social_settings_that_provide_distraction: string;
    people_whom_i_can_ask_for_help: string;
    professionals_or_agencies_i_can_contact_during_a_crisis: string;
    making_the_environment_safe: string;
    the_one_thing_that_is_important_to_me_and_worth_living_for_is: string;
    how_likely_is_the_client_to_use_this_safety_plan: string;
    how_likely_is_the_client_to_adhere_to_this_safety_plan: string;
    detail_hloc_options_and_next_steps: string;
    where_will_the_client_keep_the_safety_plan_and_how_will_it_be_located_during_a_crisis: string;
    is_the_format_appropriate_for_client_capacity_and_circumstances: string;
    when_should_follow_up_be_done_for_this_client: string;
    frequency_of_sessions: string;
    other_frequency_of_sessions: string;
    date_of_next_session: string;
    date_created: string;
    signature: string;
}

export interface IEditSoapNote {
    isClientPresent: boolean;
    duration: string;
    appointment_location: string;
    delivery_option: string;
    provider_location: string;
    other_provider_location?: string;
    consent_was_received_for_the_teletherapy_session: boolean;
    the_teletherapy_session_was_conducted_via_video: boolean;
    diagnosis_codes: string[];
    secondary_diagnosis: string[];
    tertiary_diagnosis: string[];
    emotional_behavioural_symptoms_demonstrated: string;
    causing: string;
    intention_for_session: string;
    other_delivery_option?: string;
    subjective: string;
    objective: string;
    assessment: string;
    cognitive_behavioral_therapies: string[];
    relationship_based_interventions: string[];
    other: string[];
    plan: string;
    frequency_of_sessions: string;
    other_frequency_of_sessions: string;
    date_of_next_session: string;
    prognosis: string;
    client_response: string;
    goal_one: string;
    goal_two: string;
    goal_three: string;
    progress_one: string;
    progress_two: string;
    progress_three: string;
    communication_factors_impacting_treatment: string[];
    details_around_communication_factors_impacting_treatment: string;
    signature?: string;
}

export interface IEditSupervisionNotePayload {
    supervision_note: string;
    client_progress: string;
    signature?: string;
}

export interface IEditSupervisorCheckInNotePayload {
    update: string;
    signature?: string;
}

export interface IEditTerminationNotePayload {
    duration: string;
    appointment_location: string;
    delivery_option: string;
    provider_location: string;
    other_provider_location?: string;
    consent_was_received_for_the_teletherapy_session: boolean;
    the_teletherapy_session_was_conducted_via_video: boolean;
    total_number_of_sessions: string;
    other_delivery_option?: string;
    diagnosis_codes: string[];
    secondary_diagnosis: string[];
    tertiary_diagnosis: string[];
    first_session_date: string;
    last_session_date: string;
    presenting_problems: string;
    planned_treatment_and_goals: string;
    course_of_treatment: string;
    patient_final_condition: string;
    prognosis: string;
    reason_for_termination: string;
    discharge_plan_and_follow_up: string;
    date_created: string;
    signature: string;
}

export interface IEditTreatmentReviewNotePayload {
    isClientPresent: boolean;
    duration: string;
    appointment_location: string;
    delivery_option: string;
    provider_location: string;
    other_provider_location?: string;
    consent_was_received_for_the_teletherapy_session: boolean;
    the_teletherapy_session_was_conducted_via_video: boolean;
    other_delivery_option?: string;
    diagnosis_codes: string[];
    secondary_diagnosis: string[];
    tertiary_diagnosis: string[];
    what_are_the_problem_you_are_seeking_help_for: string;
    anxiety_frequency: string;
    anxiety_details: string;
    panic_attacks_freqency: string;
    panic_attacks_details: string;
    depression_freqency: string;
    depression_details: string;
    selected_symptoms: string;
    other_symptoms: string[];
    physical_symptoms: string;
    medication_physical_psychiatric: string;
    sleeping_habits_and_concerns: string;
    difficulties_with_appetite_or_eating_patterns: string;
    been_so_distressed_you_seriously_wished_to_end_your_life: string;
    has_anything_happened_recently_to_make_you_feel_you_dont_want_to_live: string;
    describe_has_anything_happened_recently_to_make_you_feel_you_dont_want_to_live: string;
    a_specific_plan_of_how_you_would_kill_yourself: string;
    access_to_weapons_or_means_of_hurting_self: string;
    made_serious_suicide_attempts: string;
    purposely_done_something_to_hurt_yourself: string;
    describe_done_something_to_hurt_yourself: string;
    heard_voices_telling_you_to_hurt_yourself: string;
    had_relatives_who_attempted_or_commited_sucide: string;
    had_thoughts_of_killing_or_seriously_hurting_someone: string;
    heard_voices_telling_you_to_hurt_others: string;
    hurt_someone_or_destroyed_property_on_purpose: string;
    slapped_kicked_punched_someone_with_intent_to_harm: string;
    been_arrested_or_detained_for_violent_behavior: string;
    been_to_jail_for_any_reason: string;
    been_on_probation_for_any_reason: string;
    do_you_have_access_to_guns: string;
    describe_do_you_have_access_to_guns: string;
    patients_ability_and_capability_to_respond_to_treatment: string[];
    details_for_diminished_poor_limited_quality_patient_oriented_evidence: string;
    people_present_at_session: string[];
    other_person_present_at_session: string;
    type_of_treatment_recommended: string[];
    treatment_modality_intervention: string[];
    other_treatment_modality_intervention: string;
    treatment_goal_one: string;
    treatment_goal_two: string;
    treatment_goal_three: string;
    medication_updates: string;
    frequency_of_sessions: string;
    other_frequency_of_sessions: string;
    expected_termination_date: string;
    plan_of_care: string;
    prognosis: string;
    subjective_report: string;
    summary: string;
    anxiety?: boolean;
    panic_attacks?: boolean;
    depression?: boolean;
    signature: string;
}

export type EditNoteFormsPayload =
    | IEditCancellationNotePayload
    | IEditFamilyIntakeNote
    | IEditGeneralNotesPayload
    | IEditIndividualIntakeNote
    | IEditRecordOfDisclosurePayload
    | IEditSafetyPlanPayload
    | IEditSoapNote
    | IEditSupervisionNotePayload
    | IEditSupervisorCheckInNotePayload
    | IEditTerminationNotePayload
    | IEditTreatmentReviewNotePayload
    | IEditPauseNotificationNote;

export enum NoteTypes {
    INDIVIDUAL_INTAKE_NOTE = "individual_intake_note",
    MINOR_INTAKE_NOTE = "minor_intake_note",
    FAMILY_INTAKE_NOTE = "family_intake_note",
    FAMILY_TREATMENT_REVIEW = "family_treatment_review",
    INDIVIDUAL_TREATMENT_REVIEW = "individual_treatment_review",
    INDIVIDUAL_SOAP_NOTE = "individual_soap_note",
    FAMILY_SOAP_NOTE = "family_soap_note",
    RECORD_OF_DISCLOSURE = "record_of_disclosure",
    CANCELLATION_NOTE = "cancellation_note",
    SUPERVISION_NOTE = "supervision_note",
    TERMINATION_NOTE = "termination_note",
    SAFETY_PLAN = "safety_plan",
    GENERAL_UPDATE_NOTE = "general_update_note",
    SUPERVISOR_CHECK_IN = "supervisor_check_in",
    PAUSE_NOTIFICATION_NOTE = "pause_notification_note",
}

interface INoteLabels {
    [key: string]: string;
}

export const NoteLabels: INoteLabels = {
    individual_intake_note: "Individual intake note",
    family_intake_note: "Family intake note",
    // Leaving this for old notes currently on the app
    treatment_review: "Individual treatment review",
    individual_treatment_review: "Individual treatment review",
    family_treatment_review: "Family treatment review",
    individual_soap_note: "Individual soap note",
    family_soap_note: "Family soap note",
    record_of_disclosure: "Record of disclosure",
    cancellation_note: "No show/late cancel",
    supervision_note: "Supervision note",
    termination_note: "Termination note",
    safety_plan: "Safety plan",
    general_update_note: "General update note",
    supervisor_check_in: "Supervisor check-in note",
    minor_intake_note: "Minor intake note",
    pause_notification_note: "Pause notification note",
};

export enum NoteStatus {
    CODED = "coded",
    DRAFT = "draft",
    PENDING_SUPERVISOR_REVIEW = "pending_supervisor_review",
    CHANGES_REQUESTED = "changes_requested",
    PENDING_CODE_REVIEW = "pending_code_review",
}

export const CPT_CODES = [
    "90847",
    "99449",
    "90837",
    "90834",
    "90846",
    "90832",
    "90791",
    "99448",
];

export enum SessionServiceType {
    CHILD_THERAPY = "child_therapy",
    FAMILY_THERAPY = "family_therapy",
    INDIVIDUAL_THERAPY = "individual_therapy",
    INTUITIVE_EATING = "intuitive_eating",
    MEDICATION_MANAGEMENT = "medication_management",
    PSYCHIATRIC_COUNSELING = "psychiatric_counseling",
    MENTAL_HEALTH_ASSESSMENT = "mental_health_assessment",
    TELETHERAPY = "teletherapy",
}

export const noteTypesForExport = [
    NoteTypes.INDIVIDUAL_SOAP_NOTE,
    NoteTypes.INDIVIDUAL_INTAKE_NOTE,
    NoteTypes.FAMILY_INTAKE_NOTE,
    NoteTypes.SAFETY_PLAN,
];

export interface INote {
    note_id: string;
    provider?: {
        first_name: string;
        last_name: string;
        user_id?: string;
    };
    date_of_service: string | null;
    note_type: NoteTypes;
    note_url?: string;
    client: {
        client_id: string;
        first_name: string;
        last_name: string;
        insurance_provider_name?: string;
        self_pay?: boolean;
    };
    cpt_code: string;
    status: NoteStatus;
    modifier_codes?: string[];
    is_archived?: boolean;
    session_history_id: string;
    appointment_type: SessionServiceType;
    created_at: string;
}

export interface ISuperviseeNote {
    note_id: string;
    date_of_service: string | null;
    note_type: NoteTypes;
    cpt_code: string;
    status: NoteStatus;
    provider: {
        first_name: string;
        last_name: string;
        user_id: string;
    };
    client: {
        client_id: string;
        first_name: string;
        last_name: string;
        insurance_provider_name?: string;
        self_pay?: boolean;
    };
    is_archived?: boolean;
    session_history_id: string;
    appointment_type: SessionServiceType;
    created_at: string;
}

export interface INotesListFilterTypes {
    client_name?: string;
    status?: string[];
    type?: string[];
    cpt_code?: string;
    from_date?: null | Date;
    to_date?: null | Date;
    page?: number;
    provider_name?: string;
    limit?: number;
    show_archived?: string;
    sort_attribute?: string;
    sort_order?: string;
    client_id?: string;
    insurance_provider_id?: string;
    is_flagged_for_audit?: boolean;
}

export interface ISuperviseeNotesListFilters {
    provider_name: string;
    cpt_code?: string;
    type: string[];
    from_date: null | Date;
    to_date: null | Date;
    page: number;
    status: string[];
    limit: number;
    show_archived?: string;
}
export interface IGetMessages {
    message_id: string;
    message: string;
    sender_user_id: string;
    sender_first_name: string;
    sender_last_name: string;
    is_public: boolean;
    sent_time: string;
    recipient_user_id: string;
    recipient_first_name: string;
    recipient_last_name: string;
}

export interface IDeleteNoteMessage {
    message_id: string;
}
export interface IDeleteNoteMessageResponse {
    deleted: boolean;
}
export interface IAddNoteMessage {
    message: string;
    is_public: boolean;
    recipient_user_id?: string;
}
export interface IAddNoteMessageResponse {
    message_id: string;
    message: string;
    sender_user_id: string;
    sender_first_name: string;
    sender_last_name: string;
    is_public: boolean;
    sent_time: string;
}

export interface INoteAuditDashboardList {
    date_of_service: string;
    overdue_period_secs: number;
    session_service: SessionServiceType;
    note_status: NoteStatus;
    note_id: string;
    provider: {
        id: string;
        first_name: string;
        last_name: string;
        credential: string;
    };
    supervisor: {
        id: string;
        first_name: string;
        last_name: string;
    };
    client: {
        id: string;
        first_name: string;
        last_name: string;
    };
    author: {
        id: string;
        first_name: string;
        last_name: string;
    };
}

export interface INoteAuditDashboardResponse {
    from_date: string;
    to_date: string;
    summary: {
        overdue_notes_count: number;
        average_time_to_sign_note_secs: number;
        average_time_to_complete_first_review_secs: number;
    };
    overdue_notes: INoteAuditDashboardList[];
}

export interface IRequestDetails {
    request_id: string;
    request_details: string;
    request_reason: string;
    request_datetime: string;
    requester: {
        id: string;
        first_name: string;
        last_name: string;
    };
}

export interface IFlagNote {
    note_ids_to_flag_for_audit: string[];
    flag_for_audit_status: string;
    flag_for_audit_notes?: string;
}

export enum FlaggedNoteForAuditStatus {
    FLAGGED = "flagged",
    SENT_FOR_CHANGES = "sent_for_changes",
    REQUIRES_ACTION = "requires_action",
    UNFLAGGED = "unflagged",
}

export interface IClientNoteWithDos {
    note_id: string;
    note_url: string;
    type: NoteTypes;
    source: string;
    created_at: string;
    date_of_service: string;
    provider: {
        provider_id: string;
        first_name: string;
        last_name: string;
    };
}
