import { v4 as uuidv4 } from "uuid";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useQueryClient } from "@tanstack/react-query";
import { FamilyIntakeNotePayload } from "../../../utils/types/notes";
import { EditNoteFormsPayload } from "../types/notes.types";
import {
    AppointmentLocation,
    AppointmentLocationTeletherapy,
    SessionPlaceOfServiceCode,
} from "../../../utils/types/session";
import {
    formatZonedTimeToUtc,
    handleFormatDatePickerValue,
    showUserLocalTime,
} from "../../../utils/helpers";
import {
    useEditDraftNote,
    useSignSessionNote,
} from "../../../hooks/mutations/note";
import { useFetchSessionNote } from "../../../hooks/queries/note";
import useToast from "../../../hooks/useToast";
import { useAppSelector } from "../../../hooks/useRedux";

export function useEditNote() {
    const params = useParams();
    const noteId = params.noteId as string;
    const clientId = params.clientId as string;

    const [searchParams, setSearchParams] = useSearchParams();

    const queryClient = useQueryClient();
    const navigate = useNavigate();
    const { toast } = useToast();

    const { practice } = useAppSelector((state) => state.userPractice);

    const { data } = useFetchSessionNote(
        clientId,
        noteId,
        Boolean(clientId) && Boolean(noteId)
    );

    const editDraftNote = useEditDraftNote();
    const signSessionNote = useSignSessionNote();

    const validateInputs = (payload: EditNoteFormsPayload) => {
        let diagnosisCodes: string[] | null = null;
        // we have this field in safety plan note
        let whenShouldFollowUpBeDoneForThisClient: string | null = null;
        // we have these fields in termination note
        let lastSessionDate: string | null = null;
        let firstSessionDate: string | null = null;
        let dateOfNextSession: string | null = null;
        let recordOfDisclosureOfPhiEphiDate: string | null = null;
        let dateOfUpdate: string | null = null;
        let dateCreated: string | null = null;
        let dateOfLastExam: string | null = null;
        let expectedTerminationDate: string | null = null;
        let detailsForDiminishedPoorLimitedQualityPatientOrientedEvidence = "";
        let arrayOfdetailsForDiminishedPoorLimitedQualityPatientOrientedEvidences: string[] =
            [];
        let family: FamilyIntakeNotePayload[] = [];
        let deliveryOption: string | undefined;
        let otherDeliveryOption: string | undefined;
        let otherFrequencyOfSession: string | undefined;
        let providerLocation: string | undefined;
        let otherProviderLocation: string | undefined;
        let consentWasReceivedForTheTeletherapySession: boolean | undefined;
        let theTeletherapySessionWasConductedViaVideo: boolean | undefined;
        let posCode: string | undefined;
        let sessionStartTime: string | undefined;
        let sessionEndTime: string | undefined;
        let secondaryDiagnosisCode: string | null = null;
        let tertiaryDiagnosisCode: string | null = null;

        if ("diagnosis_codes" in payload) {
            /* this is coming in as an array of string(codes) */
            diagnosisCodes = payload.diagnosis_codes;
        }

        if ("secondary_diagnosis" in payload) {
            secondaryDiagnosisCode =
                payload.secondary_diagnosis &&
                payload.secondary_diagnosis?.length > 0
                    ? payload.secondary_diagnosis?.[0]
                    : null;
        }

        if ("tertiary_diagnosis" in payload) {
            tertiaryDiagnosisCode =
                payload.tertiary_diagnosis &&
                payload.tertiary_diagnosis.length > 0
                    ? payload.tertiary_diagnosis?.[0]
                    : null;
        }

        if ("appointment_location" in payload) {
            if (
                payload.appointment_location === AppointmentLocation.IN_PERSON
            ) {
                deliveryOption = undefined;
                posCode = SessionPlaceOfServiceCode.OFFICE;
                otherDeliveryOption = undefined;
                consentWasReceivedForTheTeletherapySession = undefined;
                theTeletherapySessionWasConductedViaVideo = undefined;
                providerLocation = undefined;
                otherProviderLocation = undefined;
            } else if (
                payload.appointment_location === AppointmentLocation.TELETHERAPY
            ) {
                deliveryOption = payload.delivery_option;
                otherDeliveryOption = payload.other_delivery_option;
                consentWasReceivedForTheTeletherapySession =
                    payload.consent_was_received_for_the_teletherapy_session;
                theTeletherapySessionWasConductedViaVideo =
                    payload.the_teletherapy_session_was_conducted_via_video;
                providerLocation = payload.provider_location;
                otherProviderLocation = payload.other_provider_location;

                if (
                    payload.delivery_option ===
                    AppointmentLocationTeletherapy.HOME
                ) {
                    posCode =
                        SessionPlaceOfServiceCode.TELEHEALTH_PROVIDED_IN_PATIENTS_HOME;
                } else if (
                    payload.delivery_option ===
                        AppointmentLocationTeletherapy.OFFICE ||
                    payload.delivery_option ===
                        AppointmentLocationTeletherapy.OTHER
                ) {
                    posCode =
                        SessionPlaceOfServiceCode.TELEHEALTH_PROVIDED_OTHER_THAN_IN_PATIENTS_HOME;
                }

                if (
                    payload.delivery_option !==
                    AppointmentLocationTeletherapy.OTHER
                ) {
                    otherDeliveryOption = undefined;
                }

                if (
                    payload.provider_location !==
                    AppointmentLocationTeletherapy.OTHER
                ) {
                    otherProviderLocation = undefined;
                }
            }
        }

        if ("frequency_of_sessions" in payload) {
            if (payload.frequency_of_sessions !== "Other") {
                otherFrequencyOfSession = undefined;
            } else {
                otherFrequencyOfSession = payload.other_frequency_of_sessions;
            }
        }

        if ("when_should_follow_up_be_done_for_this_client" in payload) {
            whenShouldFollowUpBeDoneForThisClient =
                payload.when_should_follow_up_be_done_for_this_client
                    ? `${handleFormatDatePickerValue(
                          payload.when_should_follow_up_be_done_for_this_client
                      )}T00:00:00.000`
                    : null;
        }

        if ("last_session_date" in payload) {
            lastSessionDate = payload.last_session_date
                ? formatZonedTimeToUtc(payload.last_session_date)
                : null;
        }

        if ("first_session_date" in payload) {
            firstSessionDate = payload?.first_session_date
                ? formatZonedTimeToUtc(payload.first_session_date)
                : null;
        }

        if ("date_of_next_session" in payload) {
            dateOfNextSession = payload?.date_of_next_session
                ? formatZonedTimeToUtc(payload?.date_of_next_session)
                : null;
        }

        if ("date_of_last_exam" in payload) {
            dateOfLastExam = payload?.date_of_last_exam
                ? formatZonedTimeToUtc(payload?.date_of_last_exam)
                : null;
        }

        if ("record_of_disclosure_of_phi_ephi" in payload) {
            recordOfDisclosureOfPhiEphiDate =
                payload?.record_of_disclosure_of_phi_ephi
                    ? formatZonedTimeToUtc(
                          payload?.record_of_disclosure_of_phi_ephi
                      )
                    : null;
        }

        if ("date_of_update" in payload) {
            dateOfUpdate = payload?.date_of_update
                ? formatZonedTimeToUtc(payload.date_of_update)
                : null;
        }

        if ("date_created" in payload) {
            dateCreated = payload?.date_created
                ? formatZonedTimeToUtc(payload.date_created)
                : null;
        }

        if ("expected_termination_date" in payload) {
            expectedTerminationDate = payload?.expected_termination_date
                ? formatZonedTimeToUtc(payload?.expected_termination_date)
                : null;
        }

        if (
            "patients_ability_and_capability_to_respond_to_treatment" in payload
        ) {
            const shouldHaveDetails =
                payload.patients_ability_and_capability_to_respond_to_treatment
                    ? payload.patients_ability_and_capability_to_respond_to_treatment.includes(
                          "Diminished/poor or limited-quality patient-oriented evidence"
                      )
                    : false;

            detailsForDiminishedPoorLimitedQualityPatientOrientedEvidence =
                shouldHaveDetails
                    ? payload.details_for_diminished_poor_limited_quality_patient_oriented_evidence
                    : "";
        }

        if ("family" in payload) {
            const arrayOfShouldHaveDetails = payload.family.map((fam) => {
                return (
                    fam.patients_ability_and_capability_to_respond_to_treatment?.includes(
                        "Diminished/poor or limited-quality patient-oriented evidence"
                    ) || false
                );
            });

            arrayOfdetailsForDiminishedPoorLimitedQualityPatientOrientedEvidences =
                payload.family.map((fam, i) =>
                    arrayOfShouldHaveDetails[i]
                        ? fam.details_for_diminished_poor_limited_quality_patient_oriented_evidence
                        : ""
                );

            family = payload.family.map(
                (fam: FamilyIntakeNotePayload, i: number) => ({
                    ...fam,
                    details_for_diminished_poor_limited_quality_patient_oriented_evidence:
                        arrayOfdetailsForDiminishedPoorLimitedQualityPatientOrientedEvidences[
                            i
                        ],
                    family_id: fam?.family_id || uuidv4(),
                })
            );
        }

        return {
            noteId,
            data: {
                pos_code: posCode,
                note_content: {
                    ...payload,
                    diagnosis_codes: diagnosisCodes || undefined,
                    delivery_option: deliveryOption,
                    other_delivery_option: otherDeliveryOption,
                    provider_location: providerLocation,
                    other_provider_location: otherProviderLocation,
                    consent_was_received_for_the_teletherapy_session:
                        consentWasReceivedForTheTeletherapySession,
                    the_teletherapy_session_was_conducted_via_video:
                        theTeletherapySessionWasConductedViaVideo,
                    when_should_follow_up_be_done_for_this_client:
                        whenShouldFollowUpBeDoneForThisClient,
                    first_session_date: firstSessionDate,
                    last_session_date: lastSessionDate,
                    date_of_next_session: dateOfNextSession,
                    expected_termination_date: expectedTerminationDate,
                    date_of_last_exam: dateOfLastExam,
                    record_of_disclosure_of_phi_ephi:
                        recordOfDisclosureOfPhiEphiDate,
                    date_of_update: dateOfUpdate,
                    date_created: dateCreated,
                    other_frequency_of_sessions: otherFrequencyOfSession,
                    details_for_diminished_poor_limited_quality_patient_oriented_evidence:
                        detailsForDiminishedPoorLimitedQualityPatientOrientedEvidence,
                    family,
                    secondary_diagnosis: secondaryDiagnosisCode || undefined,
                    tertiary_diagnosis: tertiaryDiagnosisCode || undefined,
                },
                date_of_service: data?.data.date_of_service as string,
                session_start_time: sessionStartTime
                    ? formatZonedTimeToUtc(sessionStartTime)
                    : (data?.data.session_start_time as string),
                session_end_time: sessionEndTime
                    ? formatZonedTimeToUtc(sessionEndTime)
                    : (data?.data.session_end_time as string),
                reason_for_short_duration: data?.data.reason_for_short_duration
                    ? data?.data.reason_for_short_duration
                    : undefined,
            },
        };
    };

    const handleEditDraftNote = (payload: EditNoteFormsPayload) => {
        const payloadToSend = validateInputs(payload);

        editDraftNote.mutate(payloadToSend, {
            onSuccess: async (res) => {
                await queryClient.invalidateQueries({
                    queryKey: [clientId, `session-note`, noteId],
                });

                toast({
                    mode: "success",
                    message: res.message || "Draft note updated successfully",
                });

                navigate(`/notes/${clientId}/${noteId}?viewFrom=session_note`, {
                    replace: true,
                });
            },
            onError: (err) => {
                toast({
                    mode: "error",
                    message:
                        err.response?.data.message ||
                        "Could not edit draft note at this time",
                });
            },
        });
    };

    const handleAutoSaveNote = (payload: EditNoteFormsPayload) => {
        searchParams.delete("action", "with_signature");
        setSearchParams(searchParams);

        const payloadToSend = validateInputs(payload);

        editDraftNote.mutate(payloadToSend, {
            onSuccess: async () => {
                await queryClient.invalidateQueries({
                    queryKey: [clientId, `session-note`, noteId],
                });

                searchParams.set("lastSaved", showUserLocalTime());
                setSearchParams(searchParams);
            },
        });
    };

    const handleSignNote = (payload: EditNoteFormsPayload) => {
        signSessionNote.mutate(
            {
                noteId,
                data: {
                    signature: payload.signature as string,
                    signature_date_time: new Date(Date.now()).toISOString(),
                },
            },
            {
                onSuccess: async (response) => {
                    toast({
                        mode: "success",
                        message: response.message || "Note signed successfully",
                    });

                    await queryClient.invalidateQueries({
                        queryKey: [clientId, `session-note`, noteId],
                    });

                    await queryClient.invalidateQueries({
                        queryKey: [
                            practice?.is_supervisor ? `notes` : clientId,
                            `session-note`,
                            noteId,
                        ],
                    });

                    navigate(
                        `/notes/${clientId}/${noteId}?viewFrom=session_note`,
                        { replace: true }
                    );
                },
                onError: (err) => {
                    toast({
                        mode: "error",
                        message:
                            err.response?.data.message ||
                            "Could not sign note at this time",
                    });
                },
            }
        );
    };

    return {
        handleEditDraftNote,
        handleAutoSaveNote,
        handleSignNote,
    };
}
