import React from "react";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useQueryClient } from "@tanstack/react-query";
import { parseISO } from "date-fns";
import { Button, Alert, DatePicker, Select } from "@jhool-io/fe-components";
import { useSearchParams } from "react-router-dom";
import { useFetchTasks } from "../../../../hooks/queries/tasks";
import { useFetchUsersList } from "../../../../hooks/queries/user-management";
import { formatZonedTimeToUtc } from "../../../../utils/helpers";
import { IUpdateTask, TaskStatus } from "../../../../utils/types/tasks";
import { useUpdateTask } from "../../../../hooks/mutations/tasks";
import useToast from "../../../../hooks/useToast";
import { AddTextEditor } from "../../../../components/TextEditor/AddTextEditor/AddTextEditor";
import styles from "./ReassignTask.module.scss";
import { useDebounce } from "../../../../hooks/helpers";

interface ReassignTaskProps {
    // Function to call when form submit button is clicked
    onFormSubmit(): void;
    /**
     * Current action
     */
    action?: "reassign" | "forward";
}

const schema = yup.object({
    due_date: yup.date(),
    description: yup.string(),
    assignee_user_ids: yup.array().required("Field is required"),
});

export default function ReassignTask({
    onFormSubmit,
    action = "reassign",
}: ReassignTaskProps) {
    const [internalUserSearchString, setInternalUserSearchString] =
        React.useState("");
    const [showAssigneeOptions, setShowAssigneeOptions] = React.useState(false);
    const [shoudlAllowLessThanTwoHours, setShouldAllowLessThanTwoHours] =
        React.useState<null | "asked" | "allow">(null);
    const [taskDescription, setTaskDescription] = React.useState("");

    const [searchParams] = useSearchParams();

    const { data } = useFetchTasks({
        status: [
            TaskStatus.COMPLETED,
            TaskStatus.IN_PROGRESS,
            TaskStatus.NOT_STARTED,
        ],
        todo_id: searchParams.get("task_id") || "",
    });

    // React hook form values
    const {
        handleSubmit,
        control,
        formState: { errors },
        watch,
    } = useForm<IUpdateTask>({
        resolver: yupResolver(schema),
        mode: "onChange",
        defaultValues: data?.data[0].assignees
            ? {
                  ...data?.data[0],
                  assignee_user_ids:
                      action === "reassign"
                          ? data?.data[0].assignees?.map((user) => user.user_id)
                          : undefined,
              }
            : undefined,
    });

    // Local component states
    const [localDueDate, setLocalDueDate] = React.useState<Date | null>(
        data?.data[0].due_date ? parseISO(data?.data[0].due_date) : null
    );

    const debouncedUserSearchString = useDebounce(
        internalUserSearchString,
        500
    );

    // Fetch providers
    const internalUsersList = useFetchUsersList(
        {
            search_string: debouncedUserSearchString.toLowerCase(),
        },
        showAssigneeOptions && internalUserSearchString.length > 0
    );

    const InternalUsersForSelect = internalUsersList.data?.data.map((user) => ({
        label: `${user.first_name} ${user.last_name}`,
        value: user.user_id,
    }));

    // Get filtered options for users list
    const filteredUsers = InternalUsersForSelect?.filter((option) =>
        option.label
            .toLowerCase()
            .includes(internalUserSearchString.toLowerCase())
    );

    const assigneeIds = watch("assignee_user_ids");

    const queryClient = useQueryClient();

    const { toast } = useToast();

    const update = useUpdateTask();

    const onSubmit = (values: IUpdateTask) => {
        const payload = {
            todos_to_update: [
                {
                    ...values,
                    todo_id: data?.data[0].todo_id as string,
                    status: data?.data[0].status as TaskStatus,
                    title: data?.data[0].title as string,
                    description: taskDescription || undefined,
                    due_date: values.due_date
                        ? formatZonedTimeToUtc(values.due_date)
                        : undefined,
                },
            ],
        };

        update.mutate(payload, {
            onSuccess: () => {
                queryClient.invalidateQueries({
                    queryKey: ["todos"],
                });

                toast({
                    mode: "success",
                    message: "Task updated successfully!",
                });
                onFormSubmit();
            },

            onError: (err) => {
                toast({
                    mode: "error",
                    message:
                        err.response?.data.message ||
                        "Could not update the task",
                });
            },
        });
    };

    const popoverLabel = action === "forward" ? "Forward to" : "Re-assign to";

    const popoverPlaceholder =
        action === "forward"
            ? "Forward to(Type multiple)"
            : "Re-assign to(Type multiple)";

    return (
        <form id="reassign-task" onSubmit={handleSubmit(onSubmit)}>
            <p className="text-xs font-medium">
                {action === "reassign" &&
                    "Select one or more users to reassign this task to"}
                {action === "forward" &&
                    "Select one or more users to forward this task to"}
            </p>

            <div className="fg">
                <Controller
                    name="assignee_user_ids"
                    control={control}
                    render={({ field }) => (
                        <Select
                            label={popoverLabel}
                            placeholder={popoverPlaceholder}
                            isMulti
                            isSearchable
                            filterOption={() => true}
                            onChange={(val) => {
                                field.onChange(
                                    (
                                        val as {
                                            label: string;
                                            value: string;
                                        }[]
                                    ).map((code) => code.value)
                                );
                            }}
                            onInputChange={(value) => {
                                setInternalUserSearchString(value);
                                setShowAssigneeOptions(true);
                            }}
                            onBlur={() => {
                                setShowAssigneeOptions(false);
                            }}
                            hasError={!!errors.assignee_user_ids}
                            errorText={errors.assignee_user_ids?.message}
                            options={filteredUsers}
                            noOptionsMessage={() =>
                                internalUsersList.isLoading
                                    ? "Loading..."
                                    : "No options"
                            }
                            multiHasValues={
                                assigneeIds && assigneeIds.length > 0
                            }
                            isLongListInDialog
                            defaultValue={
                                action === "reassign"
                                    ? InternalUsersForSelect?.filter((user) =>
                                          field.value?.includes(user.value)
                                      ) || null
                                    : null
                            }
                            menuIsOpen={internalUserSearchString.length > 0}
                            openMenuOnClick={false}
                        />
                    )}
                />
            </div>

            {action === "forward" ? (
                <>
                    <div className="my-32">
                        <Controller
                            name="due_date"
                            control={control}
                            render={({ field }) => (
                                <div className="fg">
                                    <DatePicker
                                        label="Due date"
                                        selected={localDueDate}
                                        minDate={new Date(Date.now())}
                                        hasError={!!errors?.due_date}
                                        onChange={(date) => {
                                            field.onChange(date);
                                            setLocalDueDate(date);
                                        }}
                                        errorText={
                                            errors?.due_date?.type ===
                                            "typeError"
                                                ? "Invalid date value"
                                                : errors?.due_date?.message
                                        }
                                        shouldCloseOnSelect={false}
                                        popperPlacement="auto"
                                    />
                                </div>
                            )}
                        />
                    </div>

                    <AddTextEditor
                        title="Add description"
                        onEditorTextChange={setTaskDescription}
                        isRequiredFieldProvided
                        defaultValue={data?.data[0].description || ""}
                        titleClass={styles.descriptionLabel}
                        editorClass={styles.descriptionWrapper}
                    />
                </>
            ) : null}

            {shoudlAllowLessThanTwoHours === "asked" ? (
                <div className="flex flex-col mt-24 gap-12 md:flex-row items-center">
                    <Alert
                        type="warning"
                        description="This task is due in less than two hours, and you won't get a reminder. Are you sure you want to continue?"
                    />

                    <Button
                        variant="primary"
                        size="small"
                        onClick={() => setShouldAllowLessThanTwoHours("allow")}
                        type="button"
                    >
                        Continue
                    </Button>
                </div>
            ) : null}
        </form>
    );
}
