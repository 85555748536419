import * as React from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { ColumnDef } from "@tanstack/react-table";
import { Pagination, Table } from "@jhool-io/fe-components";
import {
    displayNameInRightFormat,
    formatDate,
    makeStringFirstLetterCapital,
    removeEnumUnderscore,
} from "../../../../utils/helpers";
import Skeleton from "../../../../components/Skeleton/Skeleton";
import ListState from "../../../../components/ListState/ListState";
import { useFetchNotesChangesRequest } from "../../hooks/notes.queries";
import { IRequestDetails } from "../../types/notes.types";
import { ViewEditorText } from "../../../../components/TextEditor/ViewEditorText/ViewEditorText";
import MobileListItem from "../../../../shared-ui/MobileListItem/MobileListItem";
import { LISTS_DEFAULT_LIMIT } from "../../../../utils/constants";

const columns: ColumnDef<IRequestDetails>[] = [
    {
        accessorKey: "date",
        header: "date requested",
        cell: ({ row }) => formatDate(row.original.request_datetime),
    },
    {
        accessorKey: "request_id",
        header: "Time",
        cell: ({ row }) =>
            formatDate(row.original.request_datetime, false, "h:mma"),
    },
    {
        accessorKey: "requester",
        header: "Requester",
        cell: ({ row }) => (
            <span className="capitalize">
                {displayNameInRightFormat({
                    firstName: row.original.requester.first_name,
                    lastName: row.original.requester.last_name,
                })}
            </span>
        ),
    },
    {
        accessorKey: "request_reason",
        header: "Reason for request",
        cell: ({ row }) =>
            makeStringFirstLetterCapital(
                removeEnumUnderscore(row.original.request_reason)
            ),
    },
    {
        accessorKey: "request_details",
        header: "Description",
        cell: ({ row }) => (
            <ViewEditorText text={row.original.request_details} />
        ),
    },
];

export default function ChangesRequested() {
    // Component local states
    const [searchParams, setSearchParams] = useSearchParams();

    // Get note id from url
    const params = useParams();
    const noteId = params.noteId as string;

    const limitFilter =
        Number(searchParams.get("request_change_limit")) || LISTS_DEFAULT_LIMIT;
    const pageFilter = Number(searchParams.get("request_change_page")) || 1;

    const { data, error, isLoading, isSuccess } = useFetchNotesChangesRequest(
        noteId,
        limitFilter,
        pageFilter
    );

    const handleSetPageFilter = (page: number) => {
        searchParams.set("request_change_page", String(page));
        setSearchParams(searchParams);
    };

    const handleSetLimitFilter = (limit: number) => {
        searchParams.set("request_change_limit", String(limit));
        setSearchParams(searchParams);
    };

    return (
        <>
            {isLoading && (
                <Skeleton
                    containerTestId="changes-requested-loader"
                    count={6}
                    type="table"
                />
            )}
            {error && error.response?.status !== 404 && (
                <ListState
                    isError
                    stateHelperText="Try reloading this page to solve this issue"
                    errorMsg={
                        error?.response?.data.message ||
                        `Cannot display changes requested at this time please try again later`
                    }
                />
            )}
            {data && isSuccess && data.data.length === 0 && (
                <ListState
                    isError={false}
                    stateHelperText="Changes requested from your supervisor will show here"
                    emptyMessage="No changes requested"
                />
            )}
            {data && isSuccess && data.data.length > 0 && (
                <>
                    <div className="hidden md:block">
                        <Table
                            columns={columns}
                            data={data.data}
                            hasPagination={data.total_count > 20}
                            pagination={
                                <Pagination
                                    totalCount={data.total_count}
                                    totalPages={data.total_pages}
                                    currentPage={pageFilter}
                                    onPageChange={handleSetPageFilter}
                                    limit={limitFilter}
                                    onLimitChange={handleSetLimitFilter}
                                />
                            }
                        />
                    </div>
                    <div className="block md:hidden" role="table">
                        <div className="flex flex-col gap-y-12">
                            {data.data.map((request) => (
                                <MobileListItem
                                    key={request.request_id}
                                    showCheckButton={false}
                                    topChildren={
                                        <div className="flex flex-row gap-8">
                                            <span className="capitalize font-semibold text-base">
                                                {`${request.requester.first_name} ${request.requester.last_name}`}
                                            </span>
                                        </div>
                                    }
                                    bottomChildren={
                                        <div className="flex flex-col gap-y-8 w-full items-start">
                                            <div className="flex items-center w-full text-left gap-x-4">
                                                <div className="basis-1/2">
                                                    <span className="block font-medium text-xss uppercase">
                                                        date requested
                                                    </span>
                                                    <span className="block text-gray font-semibold text-xs">
                                                        {formatDate(
                                                            request.request_datetime
                                                        )}
                                                    </span>
                                                </div>
                                                <div className="basis-1/2">
                                                    <span className="block font-medium text-xss uppercase">
                                                        Time
                                                    </span>
                                                    <span className="block text-gray font-semibold text-xs">
                                                        {formatDate(
                                                            request.request_datetime,
                                                            false,
                                                            "h:mma"
                                                        )}
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="w-full">
                                                <span className="block font-medium text-xss uppercase mb-4">
                                                    Reason for request
                                                </span>
                                                <div className="block text-gray font-semibold text-xs">
                                                    {makeStringFirstLetterCapital(
                                                        removeEnumUnderscore(
                                                            request.request_reason
                                                        )
                                                    )}
                                                </div>
                                            </div>
                                            <div className="w-full">
                                                <span className="block font-medium text-xss uppercase mb-4">
                                                    description
                                                </span>
                                                <div className="block *:!text-gray *:!font-semibold *:!text-xs">
                                                    <ViewEditorText
                                                        text={
                                                            request.request_details
                                                        }
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    }
                                />
                            ))}
                        </div>
                        {data.total_count > 20 && (
                            <div className="bg-white rounded-r8 p-12 mt-16">
                                <Pagination
                                    totalCount={data.total_count}
                                    totalPages={data.total_pages}
                                    currentPage={pageFilter}
                                    onPageChange={handleSetPageFilter}
                                    limit={limitFilter}
                                    onLimitChange={handleSetLimitFilter}
                                />
                            </div>
                        )}
                    </div>
                </>
            )}
        </>
    );
}
