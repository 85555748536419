/* eslint-disable react/no-unstable-nested-components */
import * as React from "react";
import {
    Button,
    Card,
    Dialog,
    DialogContent,
    Pagination,
    Tooltip,
} from "@jhool-io/fe-components";
import { Link, useSearchParams } from "react-router-dom";
import { useIsMutating, useQueryClient } from "@tanstack/react-query";
import { Document, Page } from "react-pdf";
import { IClaimAdjustments, IRemit } from "../../types/remits.types";
import {
    cn,
    displayNameInRightFormat,
    showMoneyInAppFormat,
} from "../../../../utils/helpers";
import {
    useFetchSingleRemitDocuments,
    useFetchSingleRemitEOB,
} from "../../hooks/remits.queries";
import ListState from "../../../../components/ListState/ListState";
import { useGetInvoicePayment } from "../../../../hooks/queries/billing";
import useToast from "../../../../hooks/useToast";
import { useDeleteRemitDocument } from "../../hooks/remits.mutations";
import ZoomInIcon from "../../../../components/Icons/ZoomIn";
import ZoomOutIcon from "../../../../components/Icons/ZoomOut";
import InfoBlueIcon from "../../../../components/Icons/InfoBlue";
import FilePicker from "../../../../components/FilePicker/FilePicker";
import { SupportedUploadMethod } from "../../../../utils/types/filepicker";
import { useFetchDocument } from "../../../../hooks/queries";
import { ClientDocumentTypes } from "../../../../utils/types/client";
import TrashIcon from "../../../../components/Icons/Trash";
import ArrowRightIcon from "../../../../components/Icons/ArrowRight";
import InfoCircleIcon from "../../../../components/Icons/InfoCircle";
import { APP_COLORS } from "../../../../utils/constants";
import Skeleton from "../../../../components/Skeleton/Skeleton";
import PaymentActionForm from "../../../billing/components/PaymentActionForm/PaymentActionForm";
import { PaymentModals } from "../../../billing/types/billing.types";
import { ISelectedPayment } from "../../../../utils/types/billing";
import UpdatePaymentForm from "../UpdatePaymentForm/UpdatePaymentForm";
import InsurancePayments from "../InsurancePayments/InsurancePayments";

type RemitDocumentDialog = "add-doc" | "view-doc" | "delete-doc";
interface RemitProps {
    remitData: IRemit;
}
// TODO - Reduce modal states in this file

export default function Remit({ remitData }: RemitProps) {
    const [searchParams, setSearchParams] = useSearchParams();
    const [modalInView, setModalInView] = React.useState<PaymentModals | null>(
        null
    );
    const [documentDialogInView, setDocumentDialogInView] =
        React.useState<RemitDocumentDialog | null>(null);
    const [documentInViewId, setDocumentInViewId] = React.useState<
        string | null
    >(null);
    const [EOBpageNumber, setEOBPageNumber] = React.useState(1);
    const [EOBNumPages, setEOBNumPages] = React.useState<null | number>(null);
    const [documentPageNumber, setDocumentPageNumber] = React.useState(1);
    const [documentNumPages, setDocumentNumPages] = React.useState<
        null | number
    >(null);
    const [scale, setScale] = React.useState(3);
    const [showPaymentDialog, setShowPaymentDialog] = React.useState(false);
    const [selectedPayment, setSelectedPayment] =
        React.useState<ISelectedPayment | null>(null);
    const [writeOffAction, setWriteOffAction] = React.useState<
        "move" | "close" | null
    >(null);

    const [documentToDeleteId, setDocumentToDeleteId] = React.useState<
        string | null
    >(null);

    let count = 1;

    // payload to get invoice
    const getInvoicePayload = {
        invoice_id: remitData ? remitData.invoice_id : "",
        payment_type: "i",
    };

    // get payment invoice data
    const { data: invoiceData } = useGetInvoicePayment(
        getInvoicePayload.invoice_id,
        getInvoicePayload,
        Boolean(remitData) && Boolean(remitData.invoice_id)
    );

    // get remitEOB
    const singleRemitEOB = useFetchSingleRemitEOB(
        remitData ? remitData.remittance_id : "",
        Boolean(remitData) && Boolean(remitData.remittance_id)
    );

    const deleteDocument = useDeleteRemitDocument(
        remitData ? remitData.remittance_id : "",
        documentToDeleteId || ""
    );

    const singleRemitFiles = useFetchSingleRemitDocuments(
        remitData ? remitData.remittance_id : "",
        Boolean(remitData)
    );

    const insurancePaymentIsMutating = useIsMutating([
        "add-payment",
        remitData ? remitData.invoice_id : "",
    ]);

    const selectedDocumentDetails = singleRemitFiles.data?.data?.find(
        (item) => item.remittance_attachment_id === documentInViewId
    );

    const remitFile = useFetchDocument(
        selectedDocumentDetails?.url as string,
        Boolean(documentInViewId) && Boolean(selectedDocumentDetails)
    );

    const isUpdatingPayment = useIsMutating(["update-payment"]);

    const handleModalInView = (modal: PaymentModals | null) => {
        if (modal !== null) searchParams.set("payment_type", "insurance");
        else searchParams.delete("payment_type");
        setModalInView(modal);
        setSearchParams(searchParams);
    };

    const zoomIn = () => {
        if (scale < 3) {
            setScale((prevScale: number) => prevScale + 0.5);
        }
    };

    const zoomOut = () => {
        if (scale > 1) {
            setScale((prevScale: number) => prevScale - 0.5);
        }
    };

    const getInsurancePaymentModalHeader = () => {
        switch (modalInView) {
            case "write_off":
                return "Write Off";
            case "payment":
                return "Add insurance";
            default:
                return "Add refund";
        }
    };

    const getInsurancePaymentModalSaveText = () => {
        switch (modalInView) {
            case "write_off":
                return "Add write-off";
            case "payment":
                return "Add payment";
            case "refund":
                return "Add refund";
            default:
                return "Add payment";
        }
    };

    const queryClient = useQueryClient();

    const { toast } = useToast();

    // reused css classes for remit payment summary section
    const summaryWrapperClass =
        "mb-16 last:mb-0 text-xs font-medium flex items-center gap-x-12";
    const summaryBottomWrapperClass =
        "mb-12 last:mb-0 text-xs font-medium flex items-center gap-x-12";
    const summaryItemRightClass =
        "ml-auto flex items-center gap-x-12 font-semibold w-[calc(350px-24px)] *:flex-1";
    const summaryBottomItemRightClass =
        "ml-auto font-semibold w-[169px] pl-24 text-left";

    const remitPaymentSummaryOutstanding =
        remitData.insurance_charged -
        remitData.pr100_collected -
        (remitData.insurance_paid +
            remitData.insurance_writeoff +
            remitData.patient_paid +
            remitData.patient_writeoff);

    const handleCloseUpdatePaymentDialog = () => {
        setShowPaymentDialog(false);
        setSelectedPayment(null);
    };

    const handleDeleteDocument = () => {
        deleteDocument.mutate(null, {
            onSuccess: (res) => {
                queryClient.invalidateQueries({
                    queryKey: ["remit-docs", remitData.remittance_id],
                });
                toast({
                    mode: "success",
                    message:
                        res.message ||
                        "Insurance attachment deleted successfully!",
                });
                setDocumentToDeleteId(null);
                setDocumentDialogInView(null);
            },
            onError: (err) => {
                toast({
                    mode: "error",
                    message:
                        err.response?.data.message ||
                        "Could not delete remit document at this time",
                });
            },
        });
    };

    const fileToDisplay = () => {
        if (
            selectedDocumentDetails &&
            /^image/.test(selectedDocumentDetails?.metadata?.content_type || "")
        ) {
            return (
                <>
                    {remitFile.isLoading && (
                        <span className=" text-sm flex items-center justify-center font-medium h-12">
                            Loading document....
                        </span>
                    )}
                    {remitFile.error && (
                        <span className="text-danger text-sm flex items-center justify-center font-medium  h-12">
                            Could not load document at this time
                        </span>
                    )}
                    {remitFile.data && (
                        <div className="h-full w-full">
                            <img
                                className="w-full h-full object-contain"
                                src={`data:image/png;base64,${remitFile.data?.data}`}
                                alt={selectedDocumentDetails?.name}
                            />
                        </div>
                    )}
                </>
            );
        }

        return (
            <Document
                error={
                    <span className="text-danger text-sm flex items-center justify-center font-medium  h-12">
                        Could not load pdf at this time
                    </span>
                }
                loading={
                    <span className=" text-sm flex items-center justify-center font-medium h-12">
                        Loading pdf..
                    </span>
                }
                onLoadSuccess={({ numPages: nextNumPages }) => {
                    setDocumentNumPages(nextNumPages);
                    setDocumentPageNumber(1);
                }}
                onItemClick={({ pageNumber: itemPageNumber }) => {
                    setDocumentPageNumber(Number(itemPageNumber));
                }}
                file={`data:image/png;base64,${remitFile.data?.data}`}
            >
                <Page
                    height={550}
                    scale={scale}
                    pageNumber={documentPageNumber}
                />
                {documentNumPages && documentNumPages > 1 && (
                    <Pagination
                        totalCount={documentNumPages}
                        totalPages={documentNumPages}
                        currentPage={documentPageNumber}
                        onPageChange={(page) => setDocumentPageNumber(page)}
                        pageSize={1}
                        isCentered
                    />
                )}
            </Document>
        );
    };

    const handleCloseDialog = () => {
        handleModalInView(null);
        setWriteOffAction(null);
        searchParams.delete("writeoff_type");
        setSearchParams(searchParams, {
            replace: true,
        });
    };

    return (
        <>
            {remitData && (
                <Dialog
                    open={
                        modalInView === "payment" ||
                        modalInView === "write_off" ||
                        modalInView === "refund"
                    }
                >
                    <DialogContent
                        handleCloseDialog={handleCloseDialog}
                        variant="center"
                        title={getInsurancePaymentModalHeader()}
                        saveText={
                            searchParams.get("writeoff_type") === "insurance"
                                ? "Save & Hold"
                                : getInsurancePaymentModalSaveText()
                        }
                        submitBtnFormValue="action-form"
                        isSubmitBtnDisabled={insurancePaymentIsMutating > 0}
                        isCancelBtnDisabled={insurancePaymentIsMutating > 0}
                        canCancelButtonSubmit
                        onCancelClick={() => {
                            setModalInView(null);
                        }}
                        cancelBtnFormValue="writeoff-move-form"
                        backBtnFormValue="writeoff-close-form"
                        canBackButtonSubmit
                        showBackBtn={
                            searchParams.get("writeoff_type") === "insurance"
                        }
                        backText="Save & Close Bill"
                        onBackClick={() => {
                            if (
                                searchParams.get("writeoff_type") ===
                                "insurance"
                            ) {
                                setWriteOffAction("close");
                            }
                        }}
                    >
                        <>
                            <h3 className="text-sm mb-16 font-semibold">
                                CLIENT DETAILS
                            </h3>
                            <div>
                                <div className="border-b border-b-strokedark pb-[30px] grid grid-cols-[repeat(auto-fill,minmax(15rem,1fr))] mb-16">
                                    <div className="flex flex-col gap-y-4 items-start">
                                        <p className="text-gray text-xs uppercase font-medium">
                                            CLIENT NAME
                                        </p>
                                        <Link
                                            to={`/clients/${remitData.client.client_id}`}
                                            className="underline text-primary text-sm capitalize"
                                        >
                                            {displayNameInRightFormat({
                                                firstName:
                                                    remitData?.client
                                                        .first_name,
                                                lastName:
                                                    remitData?.client.last_name,
                                            })}
                                        </Link>
                                    </div>
                                    <div className="flex flex-col gap-y-4 items-start">
                                        <p className="text-gray text-xs uppercase font-medium">
                                            INSURANCE PROVIDER
                                        </p>
                                        <p className="text-sm uppercase">
                                            {remitData.insurance_provider_name ||
                                                "--"}
                                        </p>
                                    </div>
                                    <div className="flex flex-col gap-y-4 items-start">
                                        <p className="text-gray text-xs uppercase font-medium">
                                            INSURANCE BALANCE
                                        </p>
                                        {invoiceData?.accounting ? (
                                            <p
                                                className={cn(
                                                    "text-sm uppercase",
                                                    {
                                                        "text-danger":
                                                            invoiceData?.accounting >
                                                            0,
                                                    }
                                                )}
                                            >
                                                {invoiceData?.accounting > 0
                                                    ? `(${showMoneyInAppFormat(
                                                          invoiceData?.accounting
                                                      )})`
                                                    : showMoneyInAppFormat(
                                                          Math.abs(
                                                              invoiceData?.accounting
                                                          )
                                                      )}
                                            </p>
                                        ) : (
                                            "--"
                                        )}
                                    </div>
                                </div>
                            </div>
                            <PaymentActionForm
                                invoiceId={remitData.invoice_id || ""}
                                clientId={remitData.client.client_id}
                                onFormSubmit={() => handleModalInView(null)}
                                modalType={modalInView}
                                writeOffAction={writeOffAction}
                            />
                        </>
                    </DialogContent>
                </Dialog>
            )}

            <Dialog
                open={
                    documentDialogInView === "add-doc" ||
                    documentDialogInView === "view-doc"
                }
            >
                <DialogContent
                    handleCloseDialog={() => {
                        setDocumentDialogInView(null);
                    }}
                    variant={
                        documentDialogInView === "add-doc" ? "center" : "drawer"
                    }
                    showFooter={false}
                    title={
                        documentDialogInView === "add-doc"
                            ? "Upload document"
                            : ""
                    }
                >
                    {documentDialogInView === "add-doc" ? (
                        <FilePicker
                            name="filepicker"
                            uploadMethods={[
                                {
                                    method: SupportedUploadMethod.manualupload,
                                },
                                {
                                    method: SupportedUploadMethod.googledrive,
                                },
                                {
                                    method: SupportedUploadMethod.dropbox,
                                },
                            ]}
                            uploadType="remit-file"
                            successCallback={() => {
                                setDocumentDialogInView(null);
                            }}
                            documentToUploadType={ClientDocumentTypes.OTHER}
                            remitId={remitData.remittance_id}
                            remitName={`${remitData.client.first_name} ${remitData.client.last_name}`}
                        />
                    ) : (
                        fileToDisplay()
                    )}
                </DialogContent>
            </Dialog>

            <Dialog open={documentDialogInView === "delete-doc"}>
                <DialogContent
                    title="Delete document"
                    saveText="Delete document"
                    cancelText="Cancel"
                    handleCloseDialog={() => {
                        setDocumentDialogInView(null);
                    }}
                    variant="center"
                    onSaveClick={handleDeleteDocument}
                    isDeleting
                    isSubmitBtnDisabled={deleteDocument.isLoading}
                    isCancelBtnDisabled={deleteDocument.isLoading}
                >
                    <>
                        <h3 className="font-medium text-xl mb-8">
                            Are you sure you want to delete this document?
                        </h3>
                        <p className="text-gray font-normal text-base">
                            By deleting this document, you won’t be able to
                            recover it.
                        </p>
                    </>
                </DialogContent>
            </Dialog>

            {selectedPayment && (
                <Dialog open={showPaymentDialog}>
                    <DialogContent
                        handleCloseDialog={handleCloseUpdatePaymentDialog}
                        variant="center"
                        title="Update payment"
                        submitBtnFormValue="update-payment"
                        isSubmitBtnDisabled={isUpdatingPayment > 0}
                        isCancelBtnDisabled={isUpdatingPayment > 0}
                    >
                        <UpdatePaymentForm
                            onFormSubmit={handleCloseUpdatePaymentDialog}
                            selectedPayment={selectedPayment}
                        />
                    </DialogContent>
                </Dialog>
            )}

            {remitData && (
                <Card classNames="px-24 py-12 bg-white rounded-r8 border border-[#c0c7cf33] overflow-x-auto">
                    <div className="min-w-[700px] max-w-full overflow-x-auto">
                        <div className="mb-12 text-sm font-bold flex items-center gap-x-12 ">
                            <span>Payment summary</span>
                            <div className="ml-auto flex items-center gap-x-12 font-semibold w-[350px]">
                                <span className="flex-1">Patient</span>
                                <span className="flex-1">Insurance</span>
                            </div>
                        </div>
                        <div className="px-8 py-12 rounded-r8 bg-card-bg">
                            <div className="px-16 py-12 bg-white rounded-r6 mb-4">
                                <div className={summaryWrapperClass}>
                                    <span>Charged</span>
                                    <div className={summaryItemRightClass}>
                                        <span>
                                            {showMoneyInAppFormat(
                                                remitData.patient_charged
                                            )}
                                        </span>
                                        <span className="pl-24">
                                            {showMoneyInAppFormat(
                                                remitData.insurance_charged
                                            )}
                                        </span>
                                    </div>
                                </div>
                                <div className={summaryWrapperClass}>
                                    <span>Expected</span>
                                    <div className={summaryItemRightClass}>
                                        <span>
                                            {showMoneyInAppFormat(
                                                remitData.patient_charged
                                            )}
                                        </span>
                                        <span className="pl-24 items-center flex gap-x-8">
                                            {remitData.insurance_expected
                                                ? showMoneyInAppFormat(
                                                      remitData.insurance_expected
                                                  )
                                                : "--"}
                                            {remitData.insurance_expected >
                                                remitData.insurance_paid && (
                                                <Tooltip
                                                    content="Expected amount is higher than paid amount"
                                                    classNames="gap-x-8"
                                                >
                                                    <Button
                                                        variant="normal"
                                                        size="auto"
                                                    >
                                                        <InfoCircleIcon
                                                            stroke={
                                                                APP_COLORS.COLOR_DANGER
                                                            }
                                                            fill={
                                                                APP_COLORS.COLOR_DANGER
                                                            }
                                                        />
                                                    </Button>
                                                </Tooltip>
                                            )}
                                        </span>
                                    </div>
                                </div>
                                <div className={summaryWrapperClass}>
                                    <span>UCR</span>
                                    <div className={summaryItemRightClass}>
                                        <span />
                                        <span className="items-center flex gap-x-8 pl-24">
                                            {remitData.ucr ?? "--"}
                                        </span>
                                    </div>
                                </div>
                                <div className={summaryWrapperClass}>
                                    <span>Paid</span>
                                    <div className={summaryItemRightClass}>
                                        <span className="text-[#059] flex items-center gap-x-8">
                                            {showMoneyInAppFormat(
                                                remitData.patient_paid
                                            )}
                                            {remitData.patient_paid <
                                            remitData.patient_charged ? (
                                                <Tooltip
                                                    content="Paid amount is lower than charged amount"
                                                    classNames="gap-x-8"
                                                >
                                                    <Button
                                                        size="auto"
                                                        variant="normal"
                                                    >
                                                        <InfoBlueIcon />
                                                    </Button>
                                                </Tooltip>
                                            ) : null}
                                        </span>
                                        <span className="text-[#059] pl-24 items-center flex gap-x-8">
                                            {showMoneyInAppFormat(
                                                remitData.insurance_paid
                                            )}
                                            <Tooltip
                                                content="Payments marked as ‘completed’ will appear here"
                                                classNames="gap-x-8"
                                            >
                                                <Button
                                                    size="auto"
                                                    variant="normal"
                                                >
                                                    <InfoBlueIcon />
                                                </Button>
                                            </Tooltip>
                                        </span>
                                    </div>
                                </div>
                                <div className={summaryWrapperClass}>
                                    <span>PR100</span>
                                    <div className={summaryItemRightClass}>
                                        <span className="items-center flex gap-x-8">
                                            {remitData.pr100_collected ||
                                            remitData.pr100_collected === 0
                                                ? showMoneyInAppFormat(
                                                      remitData.pr100_collected
                                                  )
                                                : "--"}
                                        </span>
                                        <span className="pl-24" />
                                    </div>
                                </div>
                                <div className={summaryWrapperClass}>
                                    <span>Write-off</span>
                                    <div className={summaryItemRightClass}>
                                        <span>
                                            {showMoneyInAppFormat(
                                                remitData.patient_writeoff
                                            )}
                                        </span>
                                        <span className="pl-24">
                                            {showMoneyInAppFormat(
                                                remitData.insurance_writeoff
                                            )}
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div className="px-16 py-12 bg-white rounded-r6">
                                <div className={summaryBottomWrapperClass}>
                                    <span>INS Charged</span>
                                    <span
                                        className={summaryBottomItemRightClass}
                                    >
                                        {showMoneyInAppFormat(
                                            remitData.insurance_charged
                                        )}
                                    </span>
                                </div>
                                <div className={summaryBottomWrapperClass}>
                                    <span>PT Paid + INS Paid + Writeoff</span>
                                    <span
                                        className={summaryBottomItemRightClass}
                                    >
                                        {showMoneyInAppFormat(
                                            remitData.patient_paid +
                                                remitData.insurance_paid +
                                                remitData.patient_writeoff +
                                                remitData.insurance_writeoff
                                        )}
                                    </span>
                                </div>
                                <div className={summaryBottomWrapperClass}>
                                    <span
                                        className={
                                            remitPaymentSummaryOutstanding < 0
                                                ? "text-danger"
                                                : "text-primary"
                                        }
                                    >
                                        Outstanding
                                    </span>
                                    <span
                                        className={cn(
                                            `${summaryBottomItemRightClass} text-primary`,
                                            {
                                                "text-danger":
                                                    remitPaymentSummaryOutstanding <
                                                    0,
                                            }
                                        )}
                                    >
                                        {remitPaymentSummaryOutstanding < 0
                                            ? `(${showMoneyInAppFormat(
                                                  Math.abs(
                                                      remitPaymentSummaryOutstanding
                                                  )
                                              )})`
                                            : showMoneyInAppFormat(
                                                  remitPaymentSummaryOutstanding
                                              )}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </Card>
            )}

            {/* Insurance Payments Table Flow */}
            {remitData && (
                <InsurancePayments
                    insuransePayments={remitData.insurance_payments || []}
                    invoiceId={remitData.invoice_id}
                    remitClaimAdjustments={remitData.claim_adjustments}
                    remittanceId={remitData.remittance_id}
                />
            )}

            {/* Adjustments Table Flow */}
            <div className="mt-32">
                {remitData &&
                    (remitData.claim_adjustments === null ||
                        (remitData.claim_adjustments &&
                            remitData.claim_adjustments.length === 0)) && (
                        <ListState
                            stateHelperText="Adjustments will appear here once they are added"
                            isError={false}
                            emptyMessage="No adjustments added yet"
                        />
                    )}

                {remitData &&
                    remitData.claim_adjustments &&
                    remitData.claim_adjustments.length > 0 && (
                        <Card classNames="p-24 overflow-x-auto max-w-full">
                            <p className="text-sm font-bold mb-24">
                                Adjustments
                            </p>
                            {/* adjustments header */}
                            <div className="grid grid-cols-12 w-full text-gray text-xs font-semibold leading-7 gap-24 p-12 border-[1px] border-strokelight rounded-t-r8">
                                <p className="col-span-1">No.</p>
                                <p className="col-span-2">REASON CODE</p>
                                <p className="col-span-7">DESCRIPTION</p>
                                <p className="col-span-2">AMOUNT</p>
                            </div>
                            {/* adjustments body */}

                            {remitData.claim_adjustments.flatMap(
                                (adjustment: IClaimAdjustments) => {
                                    const rows: JSX.Element[] = [];

                                    const reasonEntries = Object.entries(
                                        adjustment
                                    )
                                        .filter(([key]) =>
                                            key.startsWith("reason_code")
                                        )
                                        .map(([key, value]) => {
                                            const suffix = key.replace(
                                                "reason_code",
                                                ""
                                            ); // Get all possible suffix (_2, _3, and so on)
                                            const descriptionKey =
                                                `reason_description${suffix}` as keyof IClaimAdjustments;
                                            const amountKey =
                                                `amount${suffix}` as keyof IClaimAdjustments;

                                            return {
                                                code: value as string,
                                                description:
                                                    (adjustment[
                                                        descriptionKey
                                                    ] as string) || "",
                                                amount:
                                                    (adjustment[
                                                        amountKey
                                                    ] as number) || 0,
                                            };
                                        })
                                        .filter(
                                            (entry) =>
                                                entry.code?.trim() ||
                                                entry.description?.trim() ||
                                                entry.amount !== 0
                                        );

                                    reasonEntries.forEach((entry) => {
                                        rows.push(
                                            <div
                                                key={`${adjustment.group_code}-${entry.code}`}
                                                className="grid grid-cols-12 items-center w-full border-[1px] border-strokelight border-t-0 p-12 text-left text-sm leading-8 gap-24 hover:bg-secondary-light"
                                            >
                                                <p className="col-span-1">
                                                    {count}
                                                </p>
                                                <p className="col-span-2">
                                                    {`${
                                                        adjustment.group_code
                                                    }-${entry.code || "--"}` ||
                                                        "--"}
                                                </p>
                                                <p className="col-span-7">
                                                    {entry.description || "--"}
                                                </p>
                                                <p className="col-span-2">
                                                    {showMoneyInAppFormat(
                                                        entry.amount
                                                    )}
                                                </p>
                                            </div>
                                        );
                                        count += 1; // Increase count
                                    });

                                    return rows;
                                }
                            )}
                            {/* adjustments footer */}
                            {remitData.claim_adjustments.length ? (
                                <div className="grid grid-cols-12 items-center w-full border-[1px] border-strokelight border-t-0 rounded-b-r8 p-12 text-sm leading-8 gap-24 font-bold hover:bg-secondary-light">
                                    <p className="col-span-1" />
                                    <p className="col-span-2" />
                                    <p className="col-span-7">Total:</p>
                                    <p className="col-span-2">
                                        {showMoneyInAppFormat(
                                            remitData.claim_adjustments.reduce(
                                                (acc, item) => {
                                                    return (
                                                        acc +
                                                        Object.entries(item)
                                                            .filter(([key]) =>
                                                                key.startsWith(
                                                                    "amount"
                                                                )
                                                            )
                                                            .reduce(
                                                                (
                                                                    sum,
                                                                    [, value]
                                                                ) =>
                                                                    sum +
                                                                    ((value as number) ||
                                                                        0),
                                                                0
                                                            )
                                                    );
                                                },
                                                0
                                            )
                                        )}
                                    </p>
                                </div>
                            ) : null}
                        </Card>
                    )}
            </div>

            {/* EOB */}
            {remitData && (
                <Card classNames="p-24 overflow-x-auto max-w-full mt-32">
                    <p className="text-sm font-bold" id="#eob-section">
                        EOB
                    </p>
                    {singleRemitEOB.isLoading && (
                        <Skeleton
                            className="w-full h-[500px]"
                            containerTestId="remit-eob-loader"
                        />
                    )}
                    {singleRemitEOB.error && (
                        <ListState
                            isError
                            stateHelperText="Try reloading this page to solve this issue"
                            errorMsg="Cannot display remit EOB at this time. Please try again later"
                            cardClass="mt-32"
                        />
                    )}
                    {singleRemitEOB.data?.data && (
                        <div className="p-8 bg-secondary-light rounded-r12 border border-[rgba(231,_231,_231,_0.4)] flex justify-center">
                            <Document
                                error={
                                    <span className="text-danger text-sm flex items-center justify-center font-medium  h-12">
                                        Could not load pdf at this time
                                    </span>
                                }
                                loading={
                                    <span className=" text-sm flex items-center justify-center font-medium h-12">
                                        Loading pdf..
                                    </span>
                                }
                                onLoadSuccess={({ numPages: nextNumPages }) => {
                                    setEOBNumPages(nextNumPages);
                                    setEOBPageNumber(1);
                                }}
                                onItemClick={({
                                    pageNumber: itemPageNumber,
                                }) => {
                                    setEOBPageNumber(Number(itemPageNumber));
                                }}
                                file={`data:image/png;base64,${singleRemitEOB.data?.data}`}
                            >
                                <Page
                                    pageNumber={EOBpageNumber}
                                    height={550}
                                    scale={scale}
                                    className="!w-full"
                                />
                                <div className="flex justify-center items-center mt-6">
                                    <Button
                                        variant="normal"
                                        onClick={zoomIn}
                                        disabled={scale === 3}
                                    >
                                        <ZoomInIcon />
                                    </Button>

                                    <Button
                                        variant="normal"
                                        onClick={zoomOut}
                                        disabled={scale === 1}
                                    >
                                        <ZoomOutIcon />
                                    </Button>
                                </div>

                                {EOBNumPages && EOBNumPages > 1 && (
                                    <Pagination
                                        totalCount={EOBNumPages}
                                        totalPages={EOBNumPages}
                                        currentPage={EOBpageNumber}
                                        onPageChange={(page) =>
                                            setEOBPageNumber(page)
                                        }
                                        pageSize={1}
                                        isCentered
                                    />
                                )}
                            </Document>
                        </div>
                    )}
                </Card>
            )}

            {/* Remit files */}
            {remitData && (
                <div className="w-full bg-white rounded-r8 border border-strokedark mt-32">
                    <div className="flex flex-col gap-y-12 items-start min-[500px]:flex-row min-[500px]:justify-between min-[500px]:items-center px-24 py-[29px]  border-b border-b-stroke-divider">
                        <h3 className="text-base font-bold">Documents</h3>
                        <Button
                            onClick={() => setDocumentDialogInView("add-doc")}
                            disabled={
                                singleRemitFiles.isLoading ||
                                Boolean(singleRemitFiles.error)
                            }
                        >
                            Upload document
                        </Button>
                    </div>
                    {singleRemitFiles.isLoading && (
                        <div className="p-24 grid gap-8 grid-cols-[repeat(auto-fill,minmax(262px,1fr))]">
                            <Skeleton
                                className="h-[100px]"
                                containerTestId="remit-doc-loader"
                            />
                            <Skeleton
                                className="h-[100px]"
                                containerTestId="remit-doc-loader"
                            />
                            <Skeleton
                                className="h-[100px]"
                                containerTestId="remit-doc-loader"
                            />
                            <Skeleton
                                className="h-[100px]"
                                containerTestId="remit-doc-loader"
                            />
                        </div>
                    )}

                    {singleRemitFiles.error && (
                        <ListState
                            stateHelperText="Try reloading this page to solve this issue"
                            emptyMessage={
                                singleRemitFiles.error.response?.data.message ||
                                "Cannot display document at this time"
                            }
                            isError
                        />
                    )}
                    {singleRemitFiles.data && (
                        <>
                            {!singleRemitFiles.data.data && (
                                <ListState
                                    stateHelperText="Remit documents will appear here when added"
                                    emptyMessage="No documents yet"
                                    isError={false}
                                />
                            )}
                            {singleRemitFiles.data.data &&
                                singleRemitFiles.data.data.length > 0 && (
                                    <div className="p-24 grid gap-8 grid-cols-[repeat(auto-fill,minmax(262px,1fr))]">
                                        {singleRemitFiles.data.data.map(
                                            (attachment) => (
                                                <div
                                                    className="flex flex-col gap-y-24 bg-card-bg rounded-r8 p-16"
                                                    key={
                                                        attachment.remittance_attachment_id
                                                    }
                                                >
                                                    <span className="text-sm text-black font-medium text-left">
                                                        {attachment.name}
                                                    </span>

                                                    <div className="flex justify-between items-center">
                                                        <Button
                                                            variant="normal"
                                                            className="p-0"
                                                            onClick={() => {
                                                                setDocumentToDeleteId(
                                                                    attachment.remittance_attachment_id
                                                                );
                                                                setDocumentDialogInView(
                                                                    "delete-doc"
                                                                );
                                                            }}
                                                        >
                                                            <TrashIcon stroke="#1A738D" />
                                                        </Button>
                                                        <Button
                                                            variant="normal"
                                                            onClick={() => {
                                                                setDocumentDialogInView(
                                                                    "view-doc"
                                                                );
                                                                setDocumentInViewId(
                                                                    attachment.remittance_attachment_id
                                                                );
                                                            }}
                                                            className="p-0 text-xs font-semibold text-primary"
                                                        >
                                                            View doc
                                                            <ArrowRightIcon stroke="#1A738D" />
                                                        </Button>
                                                    </div>
                                                </div>
                                            )
                                        )}
                                    </div>
                                )}
                        </>
                    )}
                </div>
            )}
        </>
    );
}
