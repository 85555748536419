import * as React from "react";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import {
    Button,
    DatePicker,
    Input,
    Select,
    TextArea,
    Tooltip,
} from "@jhool-io/fe-components";
import ReactDOM from "react-dom";
import { useParams, useSearchParams } from "react-router-dom";
import { useQueryClient } from "@tanstack/react-query";
import { FormatOptionLabelMeta } from "react-select";
import {
    IAddInsurancePayment,
    IAddPaymentResponse,
    PaymentMethod,
    PaymentType,
    RefundReason,
    RemarkCodeDescription,
    RemarkCodes,
    WriteOffCodes,
    WriteOffCodesDecription,
} from "../../../billing/types/billing.types";
import { APP_COLORS } from "../../../../utils/constants";
import ConfirmPaymentIcon from "../../../../components/Icons/ConfirmPayment";
import { useAddInvoicePayment } from "../../../../hooks/mutations/billing";
import {
    formatDate,
    handleFormatDatePickerValue,
    handleRemoveEmptyParamFromQueryParams,
    makeStringFirstLetterCapital,
    removeEnumUnderscore,
    truncateString,
} from "../../../../utils/helpers";
import { formatRemarkOptionLabel } from "../../../billing/helpers/billing.helpers";
import useToast from "../../../../hooks/useToast";
import { IPaginatedApiResponse } from "../../../../utils/types/api-response";
import CheckCircleIcon from "../../../../components/Icons/CheckCirlce";
import MinusCircleIcon from "../../../../components/Icons/MinusCircle";
import { useFetchRemitClaims } from "../../hooks/remits.queries";

interface AddRemitInsurancePaymentFormProps {
    invoiceId: string;
    onCancelClick: () => void;
    onFormSubmit: () => void;
}

type Options = {
    label: React.ReactNode;
    value: string | undefined;
};

export default function AddRemitInsurancePaymentForm({
    invoiceId,
    onCancelClick,
    onFormSubmit,
}: AddRemitInsurancePaymentFormProps) {
    const [paymentDate, setPaymentDate] = React.useState<Date | null>(
        new Date()
    );

    const [searchParams] = useSearchParams();

    const { claimId, noteId, clientId } = useParams();

    const remitId = searchParams.get("remit_in_view");

    const addPayment = useAddInvoicePayment(invoiceId);

    const {
        data: remitData,
        isLoading,
        error,
    } = useFetchRemitClaims(
        {
            note_id: noteId,
        },
        Boolean(noteId) && Boolean(!remitId)
    );

    const claimRemitsForSelect =
        remitData?.data.map((remit) => ({
            value: remit.remittance_id,
            label: `DOS: ${formatDate(remit.date_of_service)} / CN: ${
                remit.claim_number || "--"
            } / PD: ${formatDate(remit.remit_payment_date)}`,
        })) || [];

    const {
        register,
        control,
        watch,
        handleSubmit,
        formState: { errors, dirtyFields },
    } = useForm<IAddInsurancePayment>({
        mode: "onChange",
        resolver: yupResolver(
            yup.object().shape({
                remark_codes_payment_form: yup.array().of(
                    yup.object().shape({
                        amount: yup
                            .mixed()
                            .test(
                                "is-amount-valid",
                                "Enter valid amount",
                                (value) => {
                                    if (
                                        value !== undefined &&
                                        value !== null &&
                                        value !== ""
                                    ) {
                                        return yup
                                            .number()
                                            .required("Enter valid amount")
                                            .isValidSync(value);
                                    }

                                    return true;
                                }
                            ),
                        code: yup
                            .string()
                            .when("amount", (amount: string, field) =>
                                amount
                                    ? field.required("Code is required")
                                    : field
                            ),
                    })
                ),
                payment_amount: yup
                    .number()
                    .required("Please enter a valid amount"),

                payment_date: yup.date().required("Payment date is required"),
            })
        ),
        defaultValues: {
            payment_type: "credit",
            payment_date: handleFormatDatePickerValue(new Date()),
            remark_codes_payment_form: [{ code: "", amount: "" }],
        },
    });

    const { fields, append, remove } = useFieldArray({
        control,
        name: "remark_codes_payment_form",
    });

    const paymentType = watch("payment_type");
    const paymentMethod = watch("payment_method");

    const { toast } = useToast();
    const queryClient = useQueryClient();

    const remitIdForForm =
        remitId ||
        (remitData?.data && remitData?.data?.length > 1
            ? watch("remittance_id")
            : remitData?.data?.[0].remittance_id);

    const paymentTypeForSelect: Options[] = Object.values(PaymentType).map(
        (item) => ({
            value: item,
            label: makeStringFirstLetterCapital(removeEnumUnderscore(item)),
        })
    );

    const remarkCodeForSelect: Options[] = Object.values(RemarkCodes)
        .map((item) => ({
            value: item, // Use the enum value as the option value
            label: RemarkCodeDescription[
                item as keyof typeof RemarkCodeDescription
            ], // Use this for the description
        }))
        .sort((a, b) => a.value.localeCompare(b.value));

    const writeOffCodesForSelect: Options[] = Object.values(WriteOffCodes).map(
        (item) => ({
            value: item,
            label: WriteOffCodesDecription[item],
        })
    );

    const getWriteOffCodeDescription = (code: string) => {
        const writeOffDescriptions = Object.values(WriteOffCodesDecription);
        const matchingDescription = writeOffDescriptions?.find(
            (description) =>
                code?.toLowerCase() === description.split(":")[0]?.toLowerCase()
        );
        return matchingDescription || "--";
    };

    const refundReasonForSelect: Options[] = [
        ...Object.values(RefundReason).map((item) => ({
            value: item,
            label: (
                <>{makeStringFirstLetterCapital(removeEnumUnderscore(item))} </>
            ),
        })),
    ];

    const paymentMethodForSelect: Options[] = [
        ...Object.values(PaymentMethod).map((item) => ({
            value: item,
            label:
                item === "cheque"
                    ? "Check"
                    : makeStringFirstLetterCapital(removeEnumUnderscore(item)),
        })),
    ];

    const getPaymentType = (type: string) => {
        if (type === "credit") return "i";

        if (type === "refund") return "ri";

        return "wbi";
    };

    const handleFormSubmit = (wbAction?: "close" | "hold") => {
        return (data: IAddInsurancePayment) => {
            const payload = {
                ...data,
                payment_amount: parseFloat(data?.payment_amount),
                payment_method: data.payment_method,
                payment_date: handleFormatDatePickerValue(data.payment_date),
                payment_type: getPaymentType(data.payment_type),
                claim_id: claimId,
                remittance_id: remitIdForForm,
                remark_codes: data.remark_codes || undefined,
                close_invoice: wbAction === "close",
            };

            if (dirtyFields.remark_codes_payment_form) {
                const getPairs = data.remark_codes_payment_form?.map(
                    (pair) => ({
                        code: pair.code,
                        amount: pair.amount ? parseFloat(pair.amount) : null,
                    })
                );

                payload.remark_codes_payment =
                    getPairs && getPairs?.length > 0
                        ? getPairs?.reduce((acc, curr) => {
                              // eslint-disable-next-line no-param-reassign
                              if (curr.code) acc[curr.code] = curr.amount;
                              return acc;
                          }, {} as Record<string, number | null>)
                        : undefined;
            }

            // clear remark_codes_payment_form used in form
            payload.remark_codes_payment_form = undefined;

            addPayment.mutate(payload, {
                onSuccess: (response) => {
                    queryClient.setQueryData<
                        IPaginatedApiResponse<IAddPaymentResponse>
                    >(["get-payment"], (prev) => {
                        const prevRequired =
                            prev as IPaginatedApiResponse<IAddPaymentResponse>;
                        return {
                            ...prevRequired,
                            message: response.message,
                        };
                    });

                    queryClient.invalidateQueries({
                        queryKey: [
                            "invoices",
                            handleRemoveEmptyParamFromQueryParams({
                                client_id: clientId,
                            }),
                        ],
                    });

                    queryClient.invalidateQueries({
                        queryKey: [
                            "invoices",
                            handleRemoveEmptyParamFromQueryParams({
                                invoice_id: invoiceId,
                            }),
                        ],
                    });

                    queryClient.invalidateQueries({
                        queryKey: ["get-payment"],
                    });
                    queryClient.invalidateQueries({
                        queryKey: [
                            "remit-claims",
                            handleRemoveEmptyParamFromQueryParams({
                                note_id: noteId,
                            }),
                        ],
                    });

                    toast({
                        mode: "success",
                        message:
                            response.message || "Payment added successfully",
                    });
                    onFormSubmit();
                },

                onError: (err) => {
                    if (
                        err?.response?.data.errors &&
                        err?.response?.data?.errors?.length > 0 &&
                        err?.response?.data?.errors[0].path ===
                            "payment_amount" &&
                        err?.response?.data?.errors[0].message ===
                            "must be >= 0"
                    ) {
                        toast({
                            mode: "error",
                            message: "Amount cannot be less $1",
                        });
                    } else {
                        toast({
                            mode: "error",
                            message:
                                err.response?.data.message ||
                                "Cannot add payment at this time",
                        });
                    }
                },
            });
        };
    };

    return (
        <div className="flex items-start">
            <div className="flex items-center gap-x-8 px-12">
                <Tooltip content="cancel">
                    <Button
                        size="auto"
                        variant="normal"
                        onClick={(e) => {
                            e.stopPropagation();
                            onCancelClick();
                        }}
                        className="px-4 h-28 flex items-center justify-center"
                        disabled={addPayment.isLoading}
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="17"
                            viewBox="0 0 16 17"
                            fill="none"
                        >
                            <path
                                d="M3.3335 3.83594L12.6668 13.1693M3.3335 13.1693L12.6668 3.83594"
                                stroke={APP_COLORS.COLOR_DANGER}
                                strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            />
                        </svg>
                    </Button>
                </Tooltip>
                {paymentType === "write_off" ? (
                    <Tooltip content="save and close">
                        <Button
                            size="auto"
                            variant="normal"
                            className="px-4 h-28 flex items-center justify-center"
                            disabled={addPayment.isLoading}
                            onClick={() =>
                                handleSubmit(handleFormSubmit("close"))()
                            }
                        >
                            <CheckCircleIcon />
                        </Button>
                    </Tooltip>
                ) : null}
                <Tooltip
                    content={
                        paymentType === "write_off" ? "save and hold" : "save"
                    }
                >
                    <Button
                        size="auto"
                        variant="normal"
                        className="px-4"
                        disabled={addPayment.isLoading}
                        onClick={() => handleSubmit(handleFormSubmit("hold"))()}
                    >
                        <ConfirmPaymentIcon fill={APP_COLORS.COLOR_PRIMARY} />
                    </Button>
                </Tooltip>
            </div>

            <div className="flex items-start gap-x-24 pr-24">
                <div className="w-[120px]">
                    <Controller
                        name="payment_date"
                        control={control}
                        defaultValue={handleFormatDatePickerValue(Date())}
                        render={({ field }) => (
                            <DatePicker
                                onChange={(date) => {
                                    field.onChange(date);
                                    setPaymentDate(date);
                                }}
                                selected={paymentDate}
                                hasError={!!errors.payment_date}
                                errorText={
                                    errors.payment_date?.type === "typeError"
                                        ? "invalid date value"
                                        : errors.payment_date?.message
                                }
                                className="h-28 w-[120px]"
                                popperContainer={({ children }) =>
                                    ReactDOM.createPortal(
                                        children,
                                        document.body
                                    )
                                }
                                errorTextClassName="-mt-4 text-[10px]"
                                popperProps={{
                                    strategy: "fixed",
                                    modifiers: [
                                        {
                                            name: "zIndex",
                                            options: {
                                                zIndex: 10,
                                            },
                                        },
                                    ],
                                }}
                            />
                        )}
                    />
                </div>

                <Controller
                    name="payment_type"
                    control={control}
                    render={({ field }) => (
                        <Select
                            placeholder="Payment Type"
                            options={paymentTypeForSelect}
                            onChange={(val) =>
                                field.onChange((val as Options).value)
                            }
                            defaultValue={paymentTypeForSelect.find(
                                (item) => item.value === "credit"
                            )}
                            classNames={{
                                container: () => "w-[120px]",
                                control: () => "!h-28",
                                menu: () => "!w-[120px]",
                                option: () => "!text-[11px]",
                                input: () => "!text-xs !h-20 !p-0 !my-0",
                            }}
                            isLongListInDialog
                        />
                    )}
                />
                {!remitId && remitData?.data && remitData?.data?.length > 1 ? (
                    <Controller
                        name="remittance_id"
                        control={control}
                        defaultValue={claimRemitsForSelect?.[0]?.value}
                        render={({ field }) => (
                            <Select
                                placeholder="Claim remits"
                                options={claimRemitsForSelect}
                                defaultValue={claimRemitsForSelect?.[0]}
                                onChange={(val) =>
                                    field.onChange((val as Options).value)
                                }
                                isDisabled={Boolean(error) || isLoading}
                                isLongListInDialog
                                className="z-[11]"
                                classNames={{
                                    container: () => "w-[400px] !z-[11]",
                                    control: () => "!h-28",
                                    menu: () => "!w-[400px]",
                                    option: () => "!text-[11px]",
                                    input: () => "!text-xs !h-20 !p-0 !my-0",
                                    menuPortal: () => "!z-[11]",
                                }}
                            />
                        )}
                    />
                ) : null}
                {paymentType === "credit" ? (
                    <Controller
                        name="payment_method"
                        control={control}
                        render={({ field }) => (
                            <Select
                                placeholder="Payment method"
                                options={paymentMethodForSelect}
                                wrapperClass="select"
                                onChange={(val) => {
                                    field.onChange((val as Options).value);
                                }}
                                isLongListInDialog
                                classNames={{
                                    container: () => "w-[150px]",
                                    control: () => "!h-28",
                                    menu: () => "!w-[150px]",
                                    option: () => "!text-[11px]",
                                    input: () => "!text-xs !h-20 !p-0 !my-0",
                                }}
                            />
                        )}
                    />
                ) : null}
                {paymentMethod === "cheque" ? (
                    <Input
                        {...register("payment_info.cheque_number")}
                        placeholder="Check number"
                        className="h-28 w-[150px]"
                    />
                ) : null}
                {paymentMethod === "card" || paymentMethod === "other" ? (
                    <Input
                        {...register("auth_code")}
                        placeholder="Authorization Code"
                        hasError={!!errors.auth_code}
                        errorText={errors.auth_code?.message}
                        className="h-28 w-[150px]"
                    />
                ) : null}
                {paymentType === "write_off" ? (
                    <div className="w-[150px]">
                        <Controller
                            name="writeoff_code"
                            control={control}
                            render={({ field }) => (
                                <Select
                                    isSearchable
                                    placeholder="Write-off code"
                                    options={writeOffCodesForSelect}
                                    onChange={(val) =>
                                        field.onChange((val as Options).value)
                                    }
                                    isLongListInDialog
                                    formatOptionLabel={(data, meta) => {
                                        return meta.context === "menu"
                                            ? formatRemarkOptionLabel(
                                                  data,
                                                  meta,
                                                  getWriteOffCodeDescription,
                                                  "writeoff_code"
                                              )
                                            : truncateString(
                                                  (data as Options)
                                                      .label as string,
                                                  10
                                              );
                                    }}
                                    classNames={{
                                        container: () => "w-[150px]",
                                        control: () => "!h-28",
                                        menu: () => "!w-[200px]",
                                        option: () => "!text-[11px]",
                                        input: () =>
                                            "!text-xs !h-20 !p-0 !my-0",
                                    }}
                                />
                            )}
                        />
                    </div>
                ) : null}
                <div className="w-[120px]">
                    <Input
                        {...register("payment_amount")}
                        hasError={!!errors.payment_amount}
                        errorText={
                            errors.payment_amount?.type === "typeError"
                                ? "This field is required and must be a number"
                                : "Amount must be greater or equal to 0"
                        }
                        className="h-28 w-[120px]"
                        showCurrency
                        placeholder="Amount"
                        errorTextClassName="-mt-4 text-[10px]"
                    />
                </div>

                {paymentType === "refund" ? (
                    <Controller
                        name="payment_info.refund_reason"
                        control={control}
                        render={({ field }) => (
                            <Select
                                placeholder="Refund reason"
                                isSearchable
                                options={refundReasonForSelect}
                                onChange={(val) =>
                                    field.onChange((val as Options).value)
                                }
                                isLongListInDialog
                                classNames={{
                                    container: () => "w-[150px]",
                                    control: () => "!h-28",
                                    menu: () => "!w-[200px]",
                                    option: () => "!text-[11px]",
                                    input: () => "!text-xs !h-20 !p-0 !my-0",
                                }}
                            />
                        )}
                    />
                ) : null}
                <div>
                    {Array.from({ length: fields.length }, (_, i) => (
                        <div
                            key={`${i + 1} key`}
                            className="flex items-start gap-x-8 mb-8"
                        >
                            <Controller
                                name={`remark_codes_payment_form.${i}.code`}
                                control={control}
                                render={({ field }) => (
                                    <Select
                                        placeholder="Remark code"
                                        isSearchable
                                        options={remarkCodeForSelect}
                                        onChange={(val) =>
                                            field.onChange(
                                                (val as Options).value
                                            )
                                        }
                                        formatOptionLabel={(
                                            selectData: unknown,
                                            formatOptionLabelMeta: FormatOptionLabelMeta<unknown>
                                        ) => {
                                            return formatOptionLabelMeta.context ===
                                                "menu"
                                                ? (selectData as Options).label
                                                : (
                                                      (selectData as Options)
                                                          .value as string
                                                  ).toUpperCase();
                                        }}
                                        isLongListInDialog
                                        classNames={{
                                            container: () => "w-[120px]",
                                            control: () => "!h-28",
                                            menu: () => "!w-[200px]",
                                            option: () => "!text-[11px]",
                                            input: () =>
                                                "!text-xs !h-20 !p-0 !my-0",
                                            menuPortal: () => "!z-[11]",
                                        }}
                                        hasError={
                                            !!errors
                                                ?.remark_codes_payment_form?.[i]
                                                ?.code
                                        }
                                        errorText={
                                            errors?.remark_codes_payment_form?.[
                                                i
                                            ]?.code?.message
                                        }
                                        errorTextClassName="text-[10px] mt-4 block"
                                    />
                                )}
                            />
                            <Input
                                {...register(
                                    `remark_codes_payment_form.${i}.amount`
                                )}
                                placeholder="Amount"
                                hasError={
                                    !!errors?.remark_codes_payment_form?.[i]
                                        ?.amount
                                }
                                errorText={
                                    errors?.remark_codes_payment_form?.[i]
                                        ?.amount
                                        ? "Enter valid amount"
                                        : ""
                                }
                                className="h-28 w-[100px]"
                                errorTextClassName="-mt-4 text-[10px]"
                                showCurrency
                            />
                            <Button
                                title="Remove pair"
                                size="auto"
                                variant="normal"
                                className={
                                    !!errors?.remark_codes_payment_form?.[i]
                                        ?.code ||
                                    !!errors?.remark_codes_payment_form?.[i]
                                        ?.amount
                                        ? "self-start"
                                        : "self-center"
                                }
                                onClick={() => remove(i)}
                                disabled={fields.length === 1}
                            >
                                <MinusCircleIcon />
                            </Button>
                        </div>
                    ))}
                    <div className="flex items-center justify-center">
                        <Button
                            size="auto"
                            variant="normal"
                            className="text-xs underline font-medium leading-none"
                            onClick={() =>
                                append(
                                    { amount: "", code: "" },
                                    {
                                        shouldFocus: false,
                                    }
                                )
                            }
                        >
                            Add
                        </Button>
                    </div>
                </div>

                <TextArea
                    {...register("payment_notes")}
                    placeholder="Payment notes"
                    className="h-[28px] w-[200px] resize-y py-0"
                />
            </div>
        </div>
    );
}
