import cn from "classnames";
import { useNavigate } from "react-router-dom";
import Button from "../Button/Button";
import BackIcon from "../Icons/BackIcon";
import styles from "./BackLink.module.scss";

interface BackLinkProps {
    /** Route to naviagate back to */
    to?: string;
    /** Optional class for styling */
    buttonClass?: string;
    /** Optional style prop to add inline styles */
    style?: Record<string, string>;
    /** function to run if button is not a link */
    onClick?: () => void;
    /** Text to display instead of "Back" */
    backText?: string;
    /** Optional button title  */
    title?: string;
}

export default function BackLink({
    to,
    buttonClass,
    style,
    onClick,
    backText,
    title,
}: BackLinkProps) {
    const navigate = useNavigate();

    const handleButtonClick = () => {
        return to ? navigate(to) : onClick?.();
    };

    return (
        <Button
            mode="transparent"
            onClick={handleButtonClick}
            className={cn(styles.link, buttonClass)}
            size="auto"
            type="button"
            style={style}
            ariaLabel="back button"
            title={title}
        >
            <BackIcon /> {backText || "Back"}
        </Button>
    );
}
