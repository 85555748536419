import * as React from "react";
import {
    Button,
    DatePicker,
    Dialog,
    DialogContent,
    Dropdown,
    DropdownContent,
    DropdownItem,
    DropdownTrigger,
    Popover,
    PopoverContent,
    PopoverTrigger,
    SearchInput,
    Tabs,
} from "@jhool-io/fe-components";
import { useSearchParams } from "react-router-dom";
import { format } from "date-fns";
import { useIsMutating } from "@tanstack/react-query";
import ChevronDownIcon from "../../../../components/Icons/ChevronDown";
import { APP_COLORS } from "../../../../utils/constants";
import { useFetchInsuranceProviders } from "../../../../hooks/queries/client";
import { ClaimStatus, ClaimsHasRemit } from "../../types/claims.types";
import {
    cn,
    convertDateFilterStringToDate,
    makeStringFirstLetterCapital,
    removeEnumUnderscore,
    truncateString,
} from "../../../../utils/helpers";
import { useGetClaimsListsStats } from "../../hooks/claims.queries";
import { IAppCustomDates } from "../../../../utils/types";
import Skeleton from "../../../../components/Skeleton/Skeleton";
import { useDebounce } from "../../../../hooks/helpers";
import CheckPrimaryColorIcon from "../../../../components/Icons/CheckPrimaryColor";
import CreateManualClaimForm from "../CreateManualClaimForm/CreateManualClaimForm";
import { Source } from "../../../../utils/types/notes";
import FilterButton from "../../../../shared-ui/Buttons/FilterButton/FilterButton";
import TabItemLoader from "../../../../shared-ui/TabItemLoader/TabItemLoader";

const ReadyForSubmissionList = React.lazy(
    () => import("./ReadyForSubmissionList/ReadyForSubmissionList")
);
const AwaitingResponseList = React.lazy(
    () => import("./AwaitingResponseList/AwaitingResponseList")
);
const RequiresActionList = React.lazy(
    () => import("./RequiresActionList/RequiresActionList")
);
const ClosedClaimsList = React.lazy(
    () => import("./ClosedClaimsList/ClosedClaimsList")
);
const RemitReceivedlClaimsList = React.lazy(
    () => import("./RemitReceivedClaimsList/RemitReceivedClaimsList")
);

export default function ClaimsLists() {
    const [searchParams, setSearchParams] = useSearchParams();
    const [showDialog, setShowDialog] = React.useState(false);

    const tabInUrl = searchParams.get("tab");

    const handleSetTabInUrl = (tab: string) => {
        searchParams.set("tab", tab);
        localStorage.setItem("claimslist_filters", searchParams.toString());
        setSearchParams(searchParams);
    };

    const searchString = searchParams.get("search") || "";

    const searchStringFilter = useDebounce(searchString, 500);

    const fromDateFilter = searchParams.get("note_dos_from");
    const toDateFilter = searchParams.get("note_dos_to");

    const insuranceProviderFilter =
        searchParams.get("insurance_provider") || "";
    const claimsStatusFilter = searchParams.get("status");
    const hasRemitStatusFilter = searchParams.get("has_remits") || "";
    const customDateFilter =
        (searchParams.get("date_range") as IAppCustomDates) ||
        IAppCustomDates.LAST_90_DAYS;

    const getHasRemitFilter = () => {
        if (hasRemitStatusFilter === ClaimsHasRemit.SHOW_CLAIMS_WITH_REMIT) {
            return "true";
        }
        if (hasRemitStatusFilter === ClaimsHasRemit.HIDE_CLAIMS_WITH_REMIT) {
            return "false";
        }
        return "";
    };

    const claimSourceFilter = searchParams.get("source") || "";
    const showVoidedFilter = searchParams.get("show_voided") || "false";

    const fromDateFilterToDate = convertDateFilterStringToDate(fromDateFilter);
    const toDateFilterToDate = convertDateFilterStringToDate(toDateFilter);

    const { data: claimsStats, isLoading: cliamsStatsLoading } =
        useGetClaimsListsStats({
            claim_statuses: claimsStatusFilter
                ? [claimsStatusFilter]
                : undefined,
            custom_date: customDateFilter,
            search_string: searchStringFilter,
            note_dos_from: fromDateFilter,
            has_remits: getHasRemitFilter(),
            note_dos_to: toDateFilter,
            insurance_provider_id: insuranceProviderFilter,
            page: 1,
            limit: 20,
            claim_source: claimSourceFilter,
            show_voided: showVoidedFilter,
        });

    const insuranceProviders = useFetchInsuranceProviders();

    const tabItems = [
        {
            key: "submission_ready",
            label: "Ready for submission",
            children: (
                <React.Suspense fallback={<TabItemLoader />}>
                    <ReadyForSubmissionList searchString={searchStringFilter} />
                </React.Suspense>
            ),
            onClick: () => handleSetTabInUrl("submission_ready"),
            count: cliamsStatsLoading ? (
                <Skeleton className="h-16 px-[4px] rounded-full" />
            ) : (
                claimsStats?.data.ready_for_submission_count
            ),
        },
        {
            key: "awaiting_response",
            label: "Awaiting response",
            children: (
                <React.Suspense fallback={<TabItemLoader />}>
                    <AwaitingResponseList searchString={searchStringFilter} />
                </React.Suspense>
            ),
            onClick: () => handleSetTabInUrl("awaiting_response"),
            count: cliamsStatsLoading ? (
                <Skeleton className="h-16 px-[4px] rounded-full" />
            ) : (
                claimsStats?.data.awaiting_response_count
            ),
        },
        {
            key: "remit_received",
            label: "Remit received",
            children: (
                <React.Suspense fallback={<TabItemLoader />}>
                    <RemitReceivedlClaimsList
                        searchString={searchStringFilter}
                    />
                </React.Suspense>
            ),
            onClick: () => handleSetTabInUrl("remit_received"),
            count: cliamsStatsLoading ? (
                <Skeleton className="h-16 px-[4px] rounded-full" />
            ) : (
                claimsStats?.data.remit_received_count
            ),
        },
        {
            key: "requires_action",
            label: "Requires action",
            children: (
                <React.Suspense fallback={<TabItemLoader />}>
                    <RequiresActionList searchString={searchStringFilter} />
                </React.Suspense>
            ),
            onClick: () => handleSetTabInUrl("requires_action"),
            count: cliamsStatsLoading ? (
                <Skeleton className="h-16 px-[4px] rounded-full" />
            ) : (
                claimsStats?.data.requires_action_count
            ),
        },
        {
            key: "closed",
            label: "Closed",
            children: (
                <React.Suspense fallback={<TabItemLoader />}>
                    <ClosedClaimsList searchString={searchStringFilter} />
                </React.Suspense>
            ),
            onClick: () => handleSetTabInUrl("closed"),
            count: cliamsStatsLoading ? (
                <Skeleton className="h-16 px-[4px] rounded-full" />
            ) : (
                claimsStats?.data.closed_count
            ),
        },
    ];

    // onChange handler for search input
    const handleSetSearchString = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.value === "") searchParams.delete("search");
        else searchParams.set("search", e.target.value);
        localStorage.setItem("claimslist_filters", searchParams.toString());
        setSearchParams(searchParams);
    };

    const statusOptionsForSelect = [
        ...Object.values(ClaimStatus).map((status) => {
            return status;
        }),
    ];

    const customDateForSelect = [
        ...Object.values(IAppCustomDates).map((item) => {
            return item;
        }),
    ];

    const handleFromDateFilterChange = (date: Date | null) => {
        if (date === null) searchParams.delete("note_dos_from");
        else searchParams.set("note_dos_from", format(date, "yyyy-MM-dd"));
        searchParams.set("page", "1");
        localStorage.setItem("claimslist_filters", searchParams.toString());
        setSearchParams(searchParams);
    };

    const handleToDateFilterChange = (date: Date | null) => {
        if (date === null) searchParams.delete("note_dos_to");
        else searchParams.set("note_dos_to", format(date, "yyyy-MM-dd"));
        searchParams.set("page", "1");
        localStorage.setItem("claimslist_filters", searchParams.toString());
        setSearchParams(searchParams);
    };

    const handleInsuranceProviderChange = (value: string) => {
        if (value === "") searchParams.delete("insurance_provider");
        else searchParams.set("insurance_provider", value);
        searchParams.set("page", "1");
        localStorage.setItem("claimslist_filters", searchParams.toString());
        setSearchParams(searchParams);
    };

    const handleStatusChange = (value: string) => {
        if (value === "") searchParams.delete("status");
        else searchParams.set("status", value);
        searchParams.set("page", "1");
        localStorage.setItem("claimslist_filters", searchParams.toString());
        setSearchParams(searchParams);
    };

    const handleHasRemitChange = (value: string) => {
        if (value === "") searchParams.delete("has_remits");
        else searchParams.set("has_remits", value);
        searchParams.set("page", "1");
        localStorage.setItem("claimslist_filters", searchParams.toString());
        setSearchParams(searchParams);
    };
    const handleCustomDateChange = (value: IAppCustomDates) => {
        searchParams.set("date_range", value);
        searchParams.set("page", "1");
        localStorage.setItem("claimslist_filters", searchParams.toString());
        setSearchParams(searchParams);
    };

    const handleClaimSourceChange = (value: string) => {
        if (value === "") searchParams.delete("source");
        else searchParams.set("source", value);
        searchParams.set("page", "1");
        localStorage.setItem("claimslist_filters", searchParams.toString());
        setSearchParams(searchParams);
    };

    const handleShowVoidedFilterChange = (status: string) => {
        searchParams.set("show_voided", status);
        setSearchParams(searchParams);
    };

    const getInsuranceTextToDisplay = React.useMemo(
        () =>
            insuranceProviders?.data?.data.find(
                (provider) =>
                    provider.insurance_provider_id === insuranceProviderFilter
            )?.alias,
        [insuranceProviderFilter, insuranceProviders?.data?.data]
    );

    const getVoidedClaimFilterToDisplay = () => {
        if (showVoidedFilter === "true") {
            return "Voided claims";
        }
        return "Unvoided claims";
    };

    const createClaimIsMutating = useIsMutating(["create-manual-claim"]);

    return (
        <>
            <Dialog open={showDialog}>
                <DialogContent
                    variant="center"
                    title="Create manual claim"
                    saveText="Create claim"
                    handleCloseDialog={() => setShowDialog(false)}
                    submitBtnFormValue="create-claim"
                    isSubmitBtnDisabled={createClaimIsMutating > 0}
                    isCancelBtnDisabled={createClaimIsMutating > 0}
                >
                    <CreateManualClaimForm
                        onFormSubmit={() => setShowDialog(false)}
                    />
                </DialogContent>
            </Dialog>
            <div className="flex flex-col gap-y-24 mb-32 bg-white p-16 rounded-r8">
                <div className=" flex justify-between items-center flex-wrap gap-x-12 gap-y-16">
                    <SearchInput
                        containerClass="w-[400px]"
                        placeholder="Search by client, claim no or clinician"
                        onChange={handleSetSearchString}
                        defaultValue={searchString}
                    />

                    <Button onClick={() => setShowDialog(true)}>
                        Create manual claim
                    </Button>
                </div>
                <div className="flex gap-x-12 gap-y-16 flex-wrap">
                    <Dropdown>
                        <DropdownTrigger asChild>
                            <Button
                                size="auto"
                                variant="normal"
                                disabled={
                                    Boolean(insuranceProviders.error) ||
                                    insuranceProviders.isLoading
                                }
                                className={cn(
                                    "px-16 h-32 shadow-active gap-x-8 text-sm data-[state=open]:border-primary font-medium capitalize",
                                    {
                                        uppercase: insuranceProviderFilter,
                                    }
                                )}
                            >
                                {insuranceProviders.isLoading && "Loading.."}
                                {insuranceProviders.error &&
                                    "Error loading providers"}
                                {insuranceProviders?.data?.data && (
                                    <>
                                        {getInsuranceTextToDisplay
                                            ? truncateString(
                                                  removeEnumUnderscore(
                                                      getInsuranceTextToDisplay
                                                  )
                                              )
                                            : "Insurance"}
                                        <ChevronDownIcon
                                            stroke={APP_COLORS.COLOR_BLACK}
                                        />
                                    </>
                                )}
                            </Button>
                        </DropdownTrigger>
                        <DropdownContent
                            align="start"
                            width="auto"
                            maxHeight={216}
                            className="overflow-y-auto"
                        >
                            <DropdownItem
                                className={cn(
                                    "flex gap-x-8 items-center text-xs font-medium",
                                    {
                                        "text-primary":
                                            insuranceProviderFilter === "",
                                    }
                                )}
                                onClick={() =>
                                    handleInsuranceProviderChange("")
                                }
                            >
                                All
                                {insuranceProviderFilter === "" ? (
                                    <CheckPrimaryColorIcon />
                                ) : null}
                            </DropdownItem>
                            {insuranceProviders?.data?.data
                                .sort((a, b) => a.alias.localeCompare(b.alias))
                                .map((provider) => (
                                    <DropdownItem
                                        className={cn(
                                            "flex gap-x-8 items-center uppercase text-xs font-medium",
                                            {
                                                "text-primary":
                                                    insuranceProviderFilter ===
                                                    provider.insurance_provider_id,
                                            }
                                        )}
                                        key={provider.insurance_provider_id}
                                        onClick={() =>
                                            handleInsuranceProviderChange(
                                                provider.insurance_provider_id
                                            )
                                        }
                                    >
                                        {provider.alias}
                                        {insuranceProviderFilter ===
                                        provider.insurance_provider_id ? (
                                            <CheckPrimaryColorIcon />
                                        ) : null}
                                    </DropdownItem>
                                ))}
                        </DropdownContent>
                    </Dropdown>
                    <Dropdown>
                        <DropdownTrigger asChild>
                            <Button
                                size="auto"
                                variant="normal"
                                className="px-16 h-32 shadow-active gap-x-8 text-sm data-[state=open]:border-primary font-medium capitalize"
                            >
                                <>
                                    {claimsStatusFilter
                                        ? truncateString(
                                              removeEnumUnderscore(
                                                  claimsStatusFilter
                                              )
                                          )
                                        : "Status"}
                                    <ChevronDownIcon
                                        stroke={APP_COLORS.COLOR_BLACK}
                                    />
                                </>
                            </Button>
                        </DropdownTrigger>
                        <DropdownContent
                            align="start"
                            width="auto"
                            maxHeight={216}
                            className="overflow-y-auto"
                        >
                            <DropdownItem
                                className={cn(
                                    "flex gap-x-8 items-center text-xs font-medium",
                                    {
                                        "text-primary":
                                            claimsStatusFilter === "",
                                    }
                                )}
                                onClick={() => handleStatusChange("")}
                            >
                                All
                                {!claimsStatusFilter ? (
                                    <CheckPrimaryColorIcon />
                                ) : null}
                            </DropdownItem>
                            {statusOptionsForSelect.map((status) => (
                                <DropdownItem
                                    className={cn(
                                        "flex gap-x-8 items-center capitalize text-xs font-medium",
                                        {
                                            "text-primary":
                                                claimsStatusFilter === status,
                                        }
                                    )}
                                    key={status}
                                    onClick={() => handleStatusChange(status)}
                                >
                                    {removeEnumUnderscore(status)}
                                    {claimsStatusFilter === status ? (
                                        <CheckPrimaryColorIcon />
                                    ) : null}
                                </DropdownItem>
                            ))}
                        </DropdownContent>
                    </Dropdown>
                    <Dropdown>
                        <DropdownTrigger asChild>
                            <Button
                                size="auto"
                                variant="normal"
                                className="px-16 h-32 shadow-active gap-x-8 text-sm data-[state=open]:border-primary font-medium capitalize"
                            >
                                {customDateFilter
                                    ? truncateString(
                                          removeEnumUnderscore(customDateFilter)
                                      )
                                    : "Date range"}
                                <ChevronDownIcon
                                    stroke={APP_COLORS.COLOR_BLACK}
                                />
                            </Button>
                        </DropdownTrigger>
                        <DropdownContent
                            align="start"
                            width={200}
                            maxHeight={216}
                            className="overflow-y-auto"
                        >
                            {customDateForSelect.map((range) => (
                                <DropdownItem
                                    className={cn(
                                        "flex gap-x-8 items-center capitalize text-xs font-medium",
                                        {
                                            "text-primary":
                                                customDateFilter === range,
                                        }
                                    )}
                                    key={range}
                                    onClick={() =>
                                        handleCustomDateChange(range)
                                    }
                                >
                                    {removeEnumUnderscore(range)}{" "}
                                    {customDateFilter === range ? (
                                        <CheckPrimaryColorIcon />
                                    ) : null}
                                </DropdownItem>
                            ))}
                        </DropdownContent>
                    </Dropdown>
                    <Popover>
                        <PopoverTrigger asChild>
                            <Button
                                size="auto"
                                variant="normal"
                                className="px-16 h-32 shadow-active gap-x-8 text-sm data-[state=open]:border-primary font-medium self-start md:self-center"
                            >
                                Date
                                <ChevronDownIcon
                                    stroke={APP_COLORS.COLOR_BLACK}
                                />
                            </Button>
                        </PopoverTrigger>
                        <PopoverContent align="end" className="p-16 gap-y-12">
                            <DatePicker
                                label="From"
                                placeholderText="MM/DD/YYYY"
                                selected={fromDateFilterToDate}
                                onChange={handleFromDateFilterChange}
                                maxDate={
                                    toDateFilterToDate || new Date(Date.now())
                                }
                                isClearable
                            />
                            <DatePicker
                                label="To"
                                placeholderText="MM/DD/YYYY"
                                selected={toDateFilterToDate}
                                onChange={handleToDateFilterChange}
                                minDate={fromDateFilterToDate}
                                isClearable
                            />
                        </PopoverContent>
                    </Popover>
                    {/* <Button>Export</Button> */}
                    <Dropdown>
                        <DropdownTrigger asChild>
                            <Button
                                size="auto"
                                variant="normal"
                                className="px-16 h-32 shadow-active gap-x-8 text-sm data-[state=open]:border-primary font-medium capitalize"
                            >
                                <>
                                    {hasRemitStatusFilter
                                        ? truncateString(
                                              removeEnumUnderscore(
                                                  hasRemitStatusFilter
                                              )
                                          )
                                        : "Has Remit"}
                                    <ChevronDownIcon
                                        stroke={APP_COLORS.COLOR_BLACK}
                                    />
                                </>
                            </Button>
                        </DropdownTrigger>
                        <DropdownContent
                            align="start"
                            width="auto"
                            maxHeight={216}
                            className="overflow-y-auto"
                        >
                            <DropdownItem
                                className={cn(
                                    "flex gap-x-8 items-center text-xs font-medium",
                                    {
                                        "text-primary":
                                            hasRemitStatusFilter === "",
                                    }
                                )}
                                onClick={() => handleHasRemitChange("")}
                            >
                                Show All Claims
                                {!hasRemitStatusFilter ? (
                                    <CheckPrimaryColorIcon />
                                ) : null}
                            </DropdownItem>
                            <DropdownItem
                                className={cn(
                                    "flex gap-x-8 items-center text-xs font-medium",
                                    {
                                        "text-primary":
                                            hasRemitStatusFilter ===
                                            ClaimsHasRemit.SHOW_CLAIMS_WITH_REMIT,
                                    }
                                )}
                                onClick={() =>
                                    handleHasRemitChange(
                                        ClaimsHasRemit.SHOW_CLAIMS_WITH_REMIT
                                    )
                                }
                            >
                                {makeStringFirstLetterCapital(
                                    removeEnumUnderscore(
                                        ClaimsHasRemit.SHOW_CLAIMS_WITH_REMIT
                                    )
                                )}
                                {hasRemitStatusFilter ===
                                ClaimsHasRemit.SHOW_CLAIMS_WITH_REMIT ? (
                                    <CheckPrimaryColorIcon />
                                ) : null}
                            </DropdownItem>
                            <DropdownItem
                                className={cn(
                                    "flex gap-x-8 items-center text-xs font-medium",
                                    {
                                        "text-primary":
                                            hasRemitStatusFilter ===
                                            ClaimsHasRemit.HIDE_CLAIMS_WITH_REMIT,
                                    }
                                )}
                                onClick={() =>
                                    handleHasRemitChange(
                                        ClaimsHasRemit.HIDE_CLAIMS_WITH_REMIT
                                    )
                                }
                            >
                                {makeStringFirstLetterCapital(
                                    removeEnumUnderscore(
                                        ClaimsHasRemit.HIDE_CLAIMS_WITH_REMIT
                                    )
                                )}
                                {hasRemitStatusFilter ===
                                ClaimsHasRemit.HIDE_CLAIMS_WITH_REMIT ? (
                                    <CheckPrimaryColorIcon />
                                ) : null}
                            </DropdownItem>
                        </DropdownContent>
                    </Dropdown>
                    <Dropdown>
                        <DropdownTrigger asChild>
                            <Button
                                size="auto"
                                variant="normal"
                                className="px-16 h-32 shadow-active gap-x-8 text-sm data-[state=open]:border-primary font-medium capitalize"
                            >
                                <>
                                    {claimSourceFilter
                                        ? truncateString(claimSourceFilter)
                                        : "Source"}
                                    <ChevronDownIcon
                                        stroke={APP_COLORS.COLOR_BLACK}
                                    />
                                </>
                            </Button>
                        </DropdownTrigger>
                        <DropdownContent
                            align="start"
                            width="auto"
                            maxHeight={216}
                            className="overflow-y-auto"
                        >
                            <DropdownItem
                                className={cn(
                                    "flex gap-x-8 items-center text-xs font-medium",
                                    {
                                        "text-primary":
                                            claimSourceFilter === "",
                                    }
                                )}
                                onClick={() => handleClaimSourceChange("")}
                            >
                                Show All Claims
                                {!claimSourceFilter ? (
                                    <CheckPrimaryColorIcon />
                                ) : null}
                            </DropdownItem>
                            <DropdownItem
                                className={cn(
                                    "flex gap-x-8 items-center text-xs font-medium",
                                    {
                                        "text-primary":
                                            claimSourceFilter === Source.MANUAL,
                                    }
                                )}
                                onClick={() =>
                                    handleClaimSourceChange(Source.MANUAL)
                                }
                            >
                                {Source.MANUAL}
                                {claimSourceFilter === Source.MANUAL ? (
                                    <CheckPrimaryColorIcon />
                                ) : null}
                            </DropdownItem>
                            <DropdownItem
                                className={cn(
                                    "flex gap-x-8 items-center text-xs font-medium",
                                    {
                                        "text-primary":
                                            claimSourceFilter ===
                                            Source.ELECTRONIC,
                                    }
                                )}
                                onClick={() =>
                                    handleClaimSourceChange(Source.ELECTRONIC)
                                }
                            >
                                {Source.ELECTRONIC}
                                {claimSourceFilter === Source.ELECTRONIC ? (
                                    <CheckPrimaryColorIcon />
                                ) : null}
                            </DropdownItem>
                        </DropdownContent>
                    </Dropdown>
                    <Dropdown>
                        <DropdownTrigger asChild>
                            <FilterButton
                                classNames="normal-case"
                                text={getVoidedClaimFilterToDisplay()}
                            />
                        </DropdownTrigger>
                        <DropdownContent>
                            <DropdownItem
                                className={cn(
                                    "flex gap-x-8 items-center text-xs font-medium",
                                    {
                                        "text-primary":
                                            showVoidedFilter === "true",
                                    }
                                )}
                                onClick={() =>
                                    handleShowVoidedFilterChange("true")
                                }
                            >
                                Voided claims
                                {showVoidedFilter === "true" ? (
                                    <CheckPrimaryColorIcon />
                                ) : null}
                            </DropdownItem>
                            <DropdownItem
                                className={cn(
                                    "flex gap-x-8 items-center text-xs font-medium",
                                    {
                                        "text-primary":
                                            showVoidedFilter === "false",
                                    }
                                )}
                                onClick={() =>
                                    handleShowVoidedFilterChange("false")
                                }
                            >
                                Unvoided claims
                                {showVoidedFilter === "false" ? (
                                    <CheckPrimaryColorIcon />
                                ) : null}
                            </DropdownItem>
                        </DropdownContent>
                    </Dropdown>
                </div>
            </div>
            <Tabs
                items={tabItems}
                size="medium"
                className="w-full rounded-b-none"
                defaultActiveKey={tabInUrl || tabItems[0].key}
            />
        </>
    );
}
