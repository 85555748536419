/* eslint-disable react/no-unstable-nested-components */
import * as React from "react";
import { ColumnDef, Row } from "@tanstack/react-table";
import { Button, SearchInput, Table, Tag } from "@jhool-io/fe-components";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { IProviderCaseload } from "../../types/providers.types";
import {
    convertTimeTo12HoursFormat,
    displayNameInRightFormat,
    handleDisplayClientEmailCorrectly,
    makeStringFirstLetterCapital,
    removeEnumUnderscore,
} from "../../../../utils/helpers";
import Tooltip from "../../../../components/Tooltip/Tooltip";
import InfoIcon from "../../../../components/Icons/InfoIcon";
import ListState from "../../../../components/ListState/ListState";
import Skeleton from "../../../../components/Skeleton/Skeleton";
import { useFetchProvidersCaseload } from "../../hooks/providers.queries";
import { useDebounce } from "../../../../hooks/helpers";
import MobileListItem from "../../../../shared-ui/MobileListItem/MobileListItem";
import { isMinor } from "../../../../utils/helpers/age";
import useShowInsuranceOverSelfPay from "../../../practice-settings/hooks/useShowInsuranceOverSelfPay";
import { useAppSelector } from "../../../../hooks/useRedux";

export default function ProviderCaseload() {
    const [searchParams, setSearchParams] = useSearchParams();
    const params = useParams();
    const providerId = params.providerId as string;

    const { practice } = useAppSelector((state) => state.userPractice);

    const showInsOverSelfPay = useShowInsuranceOverSelfPay();

    const searchString = searchParams.get("search") || "";

    const searchStringFilter = useDebounce(searchString, 500);

    const { data, isLoading, isSuccess, error } = useFetchProvidersCaseload({
        providerId: providerId || (practice?.provider_id as string),
        search_string: searchStringFilter,
        enabled: Boolean(providerId || (practice?.provider_id as string)),
    });

    // onChange handler for search input
    const handleSetSearchString = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.value === "") searchParams.delete("search");
        else searchParams.set("search", e.target.value);

        setSearchParams(searchParams);
    };

    const navigate = useNavigate();

    const handleRowClick = (row: Row<IProviderCaseload>) => {
        navigate(`/clients/${row.original.client.client_id}?tab=session_notes`);
    };

    const columns: ColumnDef<IProviderCaseload>[] = React.useMemo(
        () => [
            {
                accessorKey: "client",
                header: "CLIENT NAME",
                cell: ({ row }) => (
                    <span className="flex items-center gap-x-4">
                        {displayNameInRightFormat({
                            firstName: row.original.client.first_name,
                            lastName: row.original.client.last_name,
                        })}
                        {isMinor(row.original.client.age) ? (
                            <span>
                                <Tag
                                    title="Minor"
                                    textColor="#981F41"
                                    bgColor="rgba(251, 199, 198, 0.50)"
                                    className="font-normal px-8 py-4 h-24 rounded-r4"
                                />
                            </span>
                        ) : null}
                    </span>
                ),
            },
            {
                accessorKey: "preferred_name",
                header: "PREFERRED NAME",
                cell: ({ row }) => row.original.client.preferred_name || "--",
            },
            {
                accessorKey: "pronouns",
                header: "PRONOUNS",
                cell: ({ row }) => row.original.client.pronouns || "--",
            },
            {
                accessorKey: "contact",
                header: "CONTACT",
                cell: ({ row }) => row.original.client.contact,
            },
            {
                accessorKey: "email",
                header: "EMAIL",
                cell: ({ row }) =>
                    handleDisplayClientEmailCorrectly(
                        row.original.client.email
                    ),
            },
            {
                accessorKey: "insurance_provider_name",
                header: "INSURANCE PROVIDER",
                cell: ({ row }) => (
                    <span
                        className={
                            row.original.client.self_pay && !showInsOverSelfPay
                                ? "normal-case"
                                : "uppercase"
                        }
                    >
                        {row.original.client.self_pay && !showInsOverSelfPay
                            ? "Self pay"
                            : removeEnumUnderscore(
                                  row.original.client.insurance_provider_name ||
                                      ""
                              )}
                    </span>
                ),
            },
            {
                accessorKey: "appointments",
                header: "APPOINTMENTS",
                cell: ({ row }) =>
                    row.original.appointments.length > 1 ? (
                        <div className="flex gap-x-4">
                            {row.original.appointments.length}
                            <Tooltip
                                trigger="hover"
                                content={row.original.appointments.map(
                                    (appointment) => (
                                        <p
                                            key={appointment.session_day}
                                            className=""
                                        >
                                            {appointment.session_day}{" "}
                                            {convertTimeTo12HoursFormat(
                                                `${appointment.session_start_hr}:${appointment.session_start_min}`
                                            )}
                                            ,
                                            {makeStringFirstLetterCapital(
                                                appointment.recurrence_rule
                                            )}
                                        </p>
                                    )
                                )}
                                showArrow
                            >
                                <Button
                                    size="auto"
                                    variant="normal"
                                    type="button"
                                    aria-label="info-icon"
                                    className=""
                                >
                                    <InfoIcon />
                                </Button>
                            </Tooltip>
                        </div>
                    ) : (
                        <span className="">
                            {row.original.appointments[0].session_day}{" "}
                            {convertTimeTo12HoursFormat(
                                `${row.original.appointments[0].session_start_hr}:${row.original.appointments[0].session_start_min}`
                            )}
                            ,
                            {makeStringFirstLetterCapital(
                                row.original.appointments[0].recurrence_rule
                            )}
                        </span>
                    ),
            },
        ],
        [showInsOverSelfPay]
    );

    return (
        <>
            <div className="mb-32 bg-white p-16 rounded-r8">
                <SearchInput
                    containerClass="w-[400px]"
                    placeholder="Search"
                    onChange={handleSetSearchString}
                    defaultValue={searchString}
                />
            </div>
            {isLoading && (
                <Skeleton
                    type="table"
                    containerTestId="providers-loader"
                    width="100%"
                />
            )}
            {error && (
                <ListState
                    isError
                    stateHelperText="Try reloading this page to solve this issue"
                    errorMsg={
                        error?.response?.data.message ||
                        `Cannot display providers list at this time please try again later`
                    }
                />
            )}
            {data && isSuccess && data.data.length === 0 && (
                <ListState
                    listHeader="PROVIDERS CASELOAD"
                    emptyMessage="No clients on this provider's caseload"
                    isError={false}
                    stateHelperText="Providers caseload will appear here when added"
                    emptyBtnProps={{ showButton: false }}
                />
            )}
            {data && isSuccess && data.data.length > 0 && (
                <>
                    <div className="hidden sm:block">
                        <Table
                            columns={columns}
                            data={data.data}
                            handleRowClick={handleRowClick}
                        />
                    </div>
                    <div role="table" className="block sm:hidden">
                        <div className="flex flex-col gap-y-12">
                            {data.data.map((client) => (
                                <MobileListItem
                                    key={client.client.client_id}
                                    topChildren={
                                        <div className="flex items-center gap-x-8 text-base font-semibold capitalize">
                                            {displayNameInRightFormat({
                                                firstName:
                                                    client.client.first_name,
                                                lastName:
                                                    client.client.last_name,
                                            })}
                                            {isMinor(client.client.age) ? (
                                                <span>
                                                    <Tag
                                                        title="Minor"
                                                        textColor="#981F41"
                                                        bgColor="rgba(251, 199, 198, 0.50)"
                                                        className="font-normal px-8 py-4 h-24 rounded-r4"
                                                    />
                                                </span>
                                            ) : null}
                                        </div>
                                    }
                                    bottomChildren={
                                        <Button
                                            variant="normal"
                                            size="auto"
                                            className="flex flex-col gap-y-8 w-full items-start"
                                            onClick={() =>
                                                navigate(
                                                    `/clients/${client.client.client_id}?tab=session_notes`
                                                )
                                            }
                                        >
                                            <div className="flex items-center w-full text-left gap-x-4">
                                                <span className="w-1/2 text-gray font-semibold text-xs">
                                                    Preferred name:
                                                </span>
                                                <span className="w-1/2 font-semibold text-xs">
                                                    {client.client
                                                        .preferred_name || "--"}
                                                </span>
                                            </div>
                                            <div className="flex items-center w-full text-left gap-x-4">
                                                <span className="w-1/2 text-gray font-semibold text-xs">
                                                    Pronouns:
                                                </span>
                                                <span className="w-1/2 font-semibold text-xs">
                                                    {client.client.pronouns ||
                                                        "--"}
                                                </span>
                                            </div>
                                            <div className="flex items-center w-full text-left gap-x-4">
                                                <span className="w-1/2 text-gray font-semibold text-xs">
                                                    Email:
                                                </span>
                                                <span className="w-1/2 font-semibold text-xs">
                                                    {client.client.email ||
                                                        "--"}
                                                </span>
                                            </div>
                                            <div className="flex items-center w-full text-left gap-x-4">
                                                <span className="w-1/2 text-gray font-semibold text-xs">
                                                    Insurance Provider:
                                                </span>
                                                <span className="w-1/2 font-semibold text-xs uppercase">
                                                    {
                                                        client.client
                                                            .insurance_provider_name
                                                    }
                                                </span>
                                            </div>
                                            <div className="flex items-center w-full text-left gap-x-4">
                                                <span className="w-1/2 text-gray font-semibold text-xs">
                                                    Appointments:
                                                </span>
                                                <span className="w-1/2 font-semibold text-xs">
                                                    {client.appointments.map(
                                                        (appointment) => {
                                                            return (
                                                                <p
                                                                    key={
                                                                        appointment.session_day
                                                                    }
                                                                >
                                                                    {
                                                                        appointment.session_day
                                                                    }{" "}
                                                                    {convertTimeTo12HoursFormat(
                                                                        `${appointment.session_start_hr}:${appointment.session_start_min}`
                                                                    )}
                                                                    ,
                                                                    {makeStringFirstLetterCapital(
                                                                        appointment.recurrence_rule
                                                                    )}
                                                                </p>
                                                            );
                                                        }
                                                    )}
                                                </span>
                                            </div>
                                        </Button>
                                    }
                                />
                            ))}
                        </div>
                    </div>
                </>
            )}
        </>
    );
}
