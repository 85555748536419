/* eslint-disable react/no-unstable-nested-components */
import cn from "classnames";
import * as React from "react";
import { ColumnDef, ExpandedState, Row } from "@tanstack/react-table";
import { format } from "date-fns";
import {
    Link,
    useNavigate,
    useParams,
    useSearchParams,
} from "react-router-dom";
import { useIsMutating, useQueryClient } from "@tanstack/react-query";
import {
    Button,
    Checkbox,
    DatePicker,
    Dialog,
    DialogContent,
    Dropdown,
    DropdownContent,
    DropdownItem,
    DropdownTrigger,
    Pagination,
    Popover,
    PopoverContent,
    PopoverTrigger,
    Table,
    Tooltip,
} from "@jhool-io/fe-components";
import {
    buildSuccessMessage,
    convertDateFilterStringToDate,
    displayNameInRightFormat,
    formatDate,
    getTotalAmountOfTableRowsSelected,
    removeEnumUnderscore,
    showMoneyInAppFormat,
} from "../../../../utils/helpers";
import {
    APP_COLORS,
    CPT_CODES,
    CPT_CODES_DESCRIPTION,
    LISTS_DEFAULT_LIMIT,
} from "../../../../utils/constants";
import { useFetchClientBasicAndPersonalInfo } from "../../../../hooks/queries/client";
import {
    useDoesUserHavePermission,
    useDoesUserHaveRole,
} from "../../../../hooks/permissions";
import { UserPermisions, UserRole } from "../../../../utils/types/user";
import useToast from "../../../../hooks/useToast";
import {
    BillStatus,
    BillStatusLabels,
    IBillingResponse,
    PaymentModals,
} from "../../../billing/types/billing.types";
import InfoIcon from "../../../../components/Icons/InfoIcon";
import Skeleton from "../../../../components/Skeleton/Skeleton";
import ListState from "../../../../components/ListState/ListState";
import UnionUpIcon from "../../../../components/Icons/UnionUp";
import UnionDownIcon from "../../../../components/Icons/UnionDown";
import UnmatchedPaymentList from "../../../billing/components/UnmatchedPaymentList/UnmatchedPaymentList";
import InvoicePaymentForm from "./InvoicePaymentForm/InvoicePaymentForm";
import PaymentsOverview from "../../../billing/components/PaymentsOverview/PaymentsOverview";
import ChevronDownIcon from "../../../../components/Icons/ChevronDown";
import CheckPrimaryColorIcon from "../../../../components/Icons/CheckPrimaryColor";
import CreditCards from "../Insurance/CreditCards/CreditCards";
import { useFetchInvoices } from "../../../billing/hooks/queries/billing.queries";
import { getBillStatusTag } from "../../../billing/helpers/billing.helpers";
import InfoCircleIcon from "../../../../components/Icons/InfoCircle";
import ChargeClient from "../../../billing/components/ChargeClientForm/ChargeClientForm";
import AddBulkWriteOffForm from "../../../billing/components/WriteOff/AddBulkWriteOffForm/AddBulkWriteOffForm";
import FilterButton from "../../../../shared-ui/Buttons/FilterButton/FilterButton";
import TableFiltersDisplay from "../../../../shared-ui/TableFiltersDisplay/TableFiltersDisplay";
import { useUpdateInvoiceStatus } from "../../../payments/hooks/payments.mutations";
import PendingResolutionForm, {
    PendingResolutionMetaData,
} from "../../../billing/components/PendingResolutionForm/PendingResolutionForm";
import useShowInsuranceOverSelfPay from "../../../practice-settings/hooks/useShowInsuranceOverSelfPay";
import { NoteTypes } from "../../../notes/types/notes.types";
import UpdateForm from "../../../billing/components/UpdateForm/UpdateForm";
import PencilIcon from "../../../../components/Icons/Pencil";
import PaymentBreakdown from "../../../billing/components/PaymentBreakdown/PaymentBreakdown";
import styles from "./ClientClaimsList.module.scss";

interface ISubComponent {
    // Eslint is misbehaving here giving a false error, so ading this.
    // eslint-disable-next-line react/no-unused-prop-types
    row: Row<IBillingResponse>;
}

export default function ClientClaimsList() {
    const [rowSelection, setRowSelection] = React.useState({});
    const [expandedRows, setExpandedRows] = React.useState<ExpandedState>({});
    const [modalInView, setModalInView] = React.useState<PaymentModals | null>(
        null
    );
    const [propsForPendingResolution, setPropsForPendingResolution] =
        React.useState<PendingResolutionMetaData | null>(null);
    const [showPendingReolutionDialog, setShowPendingResolutionDialog] =
        React.useState(false);
    const [getSelectedData, setGetSelectedData] =
        React.useState<Row<IBillingResponse>[]>();
    const [showChargeModal, setShowChargeModal] = React.useState(false);
    const [invoiceToUpdateId, setInvoiceToUpdateId] = React.useState<
        string | null
    >(null);

    const [searchParams, setSearchParams] = useSearchParams();

    const [editingInsuranceRowId, setEditingInsuranceRowId] = React.useState<
        string | null
    >();

    const [insuranceChargeAmount, setInsuranceChargeAmount] = React.useState(0);
    const [editingCoInsuranceRowId, setEditingCoInsuranceRowId] =
        React.useState<string | null>();

    const [coInsuranceChargeAmount, setCoInsuranceChargeAmount] =
        React.useState(0);

    // check if logged in user has permission to perform payment actions
    const canUserPerformAction = useDoesUserHavePermission([
        UserPermisions.CLIENT_INFO_INVOICE_WRITE,
        UserPermisions.CLIENT_INFO_ALL_ADMIN,
    ]);

    const getBillStatus = () => {
        const invoiceStatusFilter = searchParams.getAll("invoice_status[]");

        if (invoiceStatusFilter.length > 0) {
            if (invoiceStatusFilter.includes("all")) {
                return [];
            }
            return invoiceStatusFilter;
        }

        return [];
    };

    const selectedBills = getSelectedData?.map((item) => item.original);

    const pageFilter = Number(searchParams.get("page")) || 1;
    const limitFilter =
        Number(searchParams.get("limit")) || LISTS_DEFAULT_LIMIT;
    const cptCodeFilter = searchParams.get("cpt_code") || "";
    // const invoiceStatusFilter = getBillStatus() || "";
    const invoiceStatusFilter = getBillStatus() || [];
    const fromDateFilter = searchParams.get("from_date");
    const toDateFilter = searchParams.get("to_date");

    const fromDateFilterToDate = convertDateFilterStringToDate(fromDateFilter);
    const toDateFilterToDate = convertDateFilterStringToDate(toDateFilter);

    // Get id from url params
    const params = useParams();
    const clientId = params.clientId as string;

    const navigate = useNavigate();

    const showOpenInvoiceBulkAction =
        getSelectedData &&
        getSelectedData.length > 0 &&
        getSelectedData.length ===
            getSelectedData.filter(
                (row) => row.original.invoice_status !== BillStatus.OPEN
            ).length;

    const showCloseInvoiceBulkAction =
        getSelectedData &&
        getSelectedData.length > 0 &&
        getSelectedData.length ===
            getSelectedData.filter(
                (row) => row.original.invoice_status !== BillStatus.CLOSED
            ).length;

    const showPendingResolutionInvoiceBulkAction =
        getSelectedData &&
        getSelectedData.length > 0 &&
        getSelectedData.length ===
            getSelectedData.filter(
                (row) =>
                    row.original.invoice_status !==
                    BillStatus.PENDING_RESOLUTION
            ).length;

    const showPendingInsuranceResolutionInvoiceBulkAction =
        getSelectedData &&
        getSelectedData.length > 0 &&
        getSelectedData.length ===
            getSelectedData.filter(
                (row) =>
                    row.original.invoice_status !==
                    BillStatus.PENDING_INSURANCE_RESOLUTION
            ).length;

    const showPendingPatientResolutionInvoiceBulkAction =
        getSelectedData &&
        getSelectedData.length > 0 &&
        getSelectedData.length ===
            getSelectedData.filter(
                (row) =>
                    row.original.invoice_status !==
                    BillStatus.PENDING_PATIENT_RESOLUTION
            ).length;

    const clientDetails = useFetchClientBasicAndPersonalInfo(clientId);

    const canViewCreditCardInfo = useDoesUserHaveRole([
        UserRole.SUPER_ADMIN,
        UserRole.CLIENT_SUPPORT,
    ]);

    // check if logged in user can perform bulk actions
    const canPerformBulkActions = useDoesUserHavePermission([
        UserPermisions.CLIENT_INFO_PAYMENTS_WRITE,
        UserPermisions.CLIENT_INFO_ALL_ADMIN,
    ]);

    const queryClient = useQueryClient();

    // Toast for success and error states
    const { toast } = useToast();

    const updateInvoice = useUpdateInvoiceStatus();

    /**
     * Get client charge failed modal in view
     * Should be "prompt" | "pr" | null
     */
    const chargeModalInView = searchParams.get("cf_modal");

    const canSplitPayment = searchParams.get("split_payment");

    const chargeClientIsMutating = useIsMutating(["charge-client"]);
    const updateInVoiceIsMutating = useIsMutating(["update-invoice"]);
    const invoicePaymentIsMutating = useIsMutating(["bulk-pay-invoices"]);
    const bulkWriteOffIsMutating = useIsMutating(["bulk-invoice-payment"]);

    const paymentIsMutating = useIsMutating([
        "add-payment",
        selectedBills ? selectedBills[0]?.invoice_id : "",
    ]);

    const allowedRolesForUpdatingCharge = [
        UserRole.BILLER,
        UserRole.BILLER_ADMIN,
        UserRole.SUPER_ADMIN,
    ];

    const canUpdateChargeAmount = useDoesUserHaveRole(
        allowedRolesForUpdatingCharge
    );

    const handleModalInView = (obj: { modal: PaymentModals | null }) => {
        setModalInView(obj.modal);

        setSearchParams(searchParams);
    };

    // Hook for fetching all invoice
    const { data, isLoading, isSuccess, error } = useFetchInvoices({
        cpt_code: cptCodeFilter,
        invoice_status: invoiceStatusFilter,
        from_date: fromDateFilter,
        to_date: toDateFilter,
        page: pageFilter,
        limit: limitFilter,
        client_id: clientId,
    });

    const showInsOverSelfPay = useShowInsuranceOverSelfPay();

    const handlePageChange = (page: number) => {
        searchParams.set("page", String(page));
        setSearchParams(searchParams);
    };

    const handleLimitChange = (limit: number) => {
        searchParams.set("limit", String(limit));
        setSearchParams(searchParams);
    };

    const handleStatusChange = (status: string) => {
        if (invoiceStatusFilter.includes(status)) {
            searchParams.delete("invoice_status[]", status);
        } else {
            searchParams.append("invoice_status[]", status);
        }
        localStorage.setItem(
            "clientclaimslist_filters",
            searchParams.toString()
        );

        searchParams.set("page", "1");
        setSearchParams(searchParams);
    };

    const handleCptCodeChange = (value: string) => {
        if (value === "") searchParams.delete("cpt_code");
        else searchParams.set("cpt_code", value);
        localStorage.setItem(
            "clientclaimslist_filters",
            searchParams.toString()
        );
        searchParams.set("page", "1");
        setSearchParams(searchParams);
    };

    const handleFromDateFilterChange = (date: Date | null) => {
        if (date === null) searchParams.delete("from_date");
        else searchParams.set("from_date", format(date, "yyyy-MM-dd"));
        localStorage.setItem(
            "clientclaimslist_filters",
            searchParams.toString()
        );
        searchParams.set("page", "1");
        setSearchParams(searchParams);
    };

    const handleToDateFilterChange = (date: Date | null) => {
        if (date === null) searchParams.delete("to_date");
        else searchParams.set("to_date", format(date, "yyyy-MM-dd"));
        localStorage.setItem(
            "clientclaimslist_filters",
            searchParams.toString()
        );
        searchParams.set("page", "1");
        setSearchParams(searchParams);
    };

    const handleMessageToDisplay = () => {
        const clientName = displayNameInRightFormat({
            firstName: clientDetails.data?.data.first_name || "",
            lastName: clientDetails.data?.data.last_name || "",
        });

        if (fromDateFilterToDate && toDateFilterToDate) {
            return (
                <h4 className="text-base font-bold mb-24">
                    {`Showing bills for  ${
                        clientDetails.isLoading ? "" : clientName
                    } between ${format(
                        fromDateFilterToDate,
                        "MMM d, yyyy"
                    )} and ${format(toDateFilterToDate, "MMM d, yyyy")} . `}
                </h4>
            );
        }

        if (fromDateFilterToDate) {
            return (
                <h4 className="text-base font-bold mb-24">
                    {`Showing bills for  ${
                        clientDetails.isLoading ? "" : clientName
                    } from ${format(fromDateFilterToDate, "MMM d, yyyy")}.`}
                </h4>
            );
        }

        if (toDateFilterToDate) {
            return (
                <h4 className="text-base font-bold mb-24">
                    {`Showing bills for  ${
                        clientDetails.isLoading ? "" : clientName
                    } to ${format(toDateFilterToDate, "MMM d, yyyy")}.`}
                </h4>
            );
        }

        return (
            <h4 className="text-base font-bold mb-24">
                {`Showing all bills for ${
                    clientDetails.isLoading ? "" : clientName
                }. `}
            </h4>
        );
    };

    const handleGenerateStatement = () => {
        if (fromDateFilter && toDateFilter) {
            navigate(
                `/reports/billing-reports/${clientId}?from_date=${searchParams.get(
                    "from_date"
                )}&to_date=${searchParams.get("to_date")}`
            );
        } else if (fromDateFilter) {
            navigate(
                `/reports/billing-reports/${clientId}?from_date=${searchParams.get(
                    "from_date"
                )}`
            );
        } else if (toDateFilter) {
            navigate(
                `/reports/billing-reports/${clientId}?to_date=${searchParams.get(
                    "to_date"
                )}`
            );
        } else {
            navigate(`/reports/billing-reports/${clientId}`);
        }
    };

    // Function to charge modal search params
    const removeChargeModalParams = () => {
        searchParams.delete("cf_modal");
        setSearchParams(searchParams);
    };

    const handleCloseUnmatchPaymentsModal = () => {
        searchParams.delete("unmatch_page");
        searchParams.delete("payment_status");
        searchParams.delete("unmatch_limit");
        searchParams.delete("split_payment");
        handleModalInView({ modal: null });

        setSearchParams();
    };

    const renderRowSubComponentShow = React.useCallback(
        ({ row }: ISubComponent) => (
            <PaymentBreakdown
                row={row.original}
                isExpanded={row.getIsExpanded()}
            />
        ),
        []
    );

    const getOutstandingValueToDisplay = (row: Row<IBillingResponse>) => {
        const coInsurance = row.original.accounting_coinsurance;
        const insurancePending =
            row.original.patient_insurance_pending_settlement;

        if (coInsurance <= 0) {
            if (insurancePending && insurancePending > 0) {
                return (
                    <span className="flex items-center">
                        {showMoneyInAppFormat(Math.abs(coInsurance))} +{" "}
                        {showMoneyInAppFormat(insurancePending)}
                        <Tooltip content="Insurance balance transferred to patient">
                            <Button variant="normal" size="auto">
                                <InfoCircleIcon
                                    stroke={APP_COLORS.COLOR_BLACK}
                                    fill={APP_COLORS.COLOR_BLACK}
                                />
                            </Button>
                        </Tooltip>
                    </span>
                );
            }
            return showMoneyInAppFormat(Math.abs(coInsurance));
        }

        if (coInsurance > 0) {
            if (insurancePending && insurancePending > 0) {
                return (
                    <span className="flex items-center text-danger">
                        ({showMoneyInAppFormat(coInsurance)}) +{" "}
                        {showMoneyInAppFormat(insurancePending)}
                        <Tooltip content="Insurance balance transferred to patient">
                            <Button variant="normal" size="auto">
                                <InfoCircleIcon
                                    stroke={APP_COLORS.COLOR_BLACK}
                                    fill={APP_COLORS.COLOR_BLACK}
                                />
                            </Button>
                        </Tooltip>
                    </span>
                );
            }
            return (
                <span className="text-danger">
                    ({showMoneyInAppFormat(coInsurance)})
                </span>
            );
        }

        return "--";
    };

    const handleShowPendingResolutionDialog = (row?: Row<IBillingResponse>) => {
        setShowPendingResolutionDialog(true);
        setPropsForPendingResolution({
            noteId: row?.original.note_id,
            clientId: row?.original.client.client_id,
            invoiceId: row?.original.invoice_id,
            invoiceIds: getSelectedData
                ? getSelectedData.map((item) => item.original.invoice_id)
                : undefined,
        });
    };

    const handleClosePendingResolutionDialog = () => {
        setShowPendingResolutionDialog(false);
        setPropsForPendingResolution(null);
    };

    // function to update invoice status
    const handleUpdateInvoiceStatus = (
        status: BillStatus,
        invoiceId?: string
    ) => {
        const selectedIds = getSelectedData
            ? getSelectedData
                  .filter((item) => Boolean(item.original.invoice_id))
                  .map((item) => item.original.invoice_id)
            : [];

        const idsToChange = invoiceId ? [invoiceId] : selectedIds;

        const changeStatusPayload = {
            invoice_ids: idsToChange,
            status,
        };
        updateInvoice.mutate(changeStatusPayload, {
            onSuccess: async (response) => {
                await queryClient.invalidateQueries({
                    queryKey: ["invoices"],
                });
                toast({
                    mode: "success",
                    message: `${buildSuccessMessage(
                        response.data.updated_invoices,
                        response.data.unupdated_invoices
                    )}`,
                });
                if (selectedIds) setRowSelection({});
            },
            onError: (err) => {
                toast({
                    mode: "error",
                    message:
                        err?.response?.data.message ||
                        "Cannot update bill status at this time",
                });
            },
        });
    };

    const columns: ColumnDef<IBillingResponse>[] = [
        {
            id: "invoice_id",
            header: ({ table }) => (
                <Checkbox
                    handleChange={table.getToggleAllRowsSelectedHandler()}
                    indeterminate={table.getIsSomeRowsSelected()}
                    label=""
                    name="row"
                    value="row"
                    isChecked={table.getIsAllRowsSelected()}
                />
            ),
            cell: ({ row }) => (
                <Checkbox
                    handleChange={row.getToggleSelectedHandler()}
                    indeterminate={row.getIsSomeSelected()}
                    label=""
                    name="row"
                    value="row"
                    isChecked={row.getIsSelected()}
                />
            ),
        },
        {
            accessorKey: "date_of_service",
            header: "DATE OF SERVICE",
            cell: ({ row }) => (
                <div className="flex gap-12">
                    <Button
                        variant="normal"
                        size="auto"
                        onClick={() => {
                            row.toggleExpanded();

                            row.getToggleExpandedHandler();
                        }}
                    >
                        {row.getIsExpanded() ? (
                            <UnionUpIcon />
                        ) : (
                            <UnionDownIcon />
                        )}
                    </Button>

                    <Link
                        to={`/billing-and-claims/${row.original.client.client_id}/${row.original.note_id}`}
                        className="text-primary underline"
                    >
                        {formatDate(row.original.date_of_service) || "--"}
                    </Link>
                </div>
            ),
        },

        {
            accessorKey: "cpt_code",
            header: "CPT CODE",
            cell: ({ row }) => {
                if (row.original.note_type === NoteTypes.CANCELLATION_NOTE) {
                    return "NO SHOW";
                }

                if (row.original.cpt_code) {
                    return (
                        <Tooltip
                            content={
                                CPT_CODES_DESCRIPTION[
                                    row.original.cpt_code as string
                                ] || ""
                            }
                        >
                            <span
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                    columnGap: "5px",
                                }}
                            >
                                {removeEnumUnderscore(row.original.cpt_code)}
                                <InfoIcon />
                            </span>
                        </Tooltip>
                    );
                }

                return "--";
            },
        },

        {
            accessorKey: "pos_code",
            header: "pos code",
            cell: ({ row }) => {
                const regex = /^(\d+) - (.+)$/;

                if (row.original.pos_code) {
                    return (
                        <Tooltip
                            content={
                                row.original.pos_code.match(regex)?.[2] || ""
                            }
                        >
                            <span
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                    columnGap: "5px",
                                }}
                            >
                                {row.original.pos_code.match(regex)?.[1] || ""}
                                <InfoIcon />
                            </span>
                        </Tooltip>
                    );
                }
                return "--";
            },
        },
        {
            accessorKey: "insurance_provider_name",
            header: "Insurance Provider",
            cell: ({ row }) => (
                <span
                    className={
                        row.original.metadata?.self_pay && !showInsOverSelfPay
                            ? "normal-case"
                            : "uppercase"
                    }
                >
                    {row.original.metadata?.self_pay && !showInsOverSelfPay
                        ? "Self pay"
                        : (row.original.insurance_provider &&
                              removeEnumUnderscore(
                                  row.original.insurance_provider || ""
                              )) ||
                          "--"}
                </span>
            ),
        },
        {
            accessorKey: "note_author",
            header: "CLINICIAN",
            cell: ({ row }) =>
                row.original.note_author
                    ? displayNameInRightFormat({
                          firstName: row.original.note_author.first_name,
                          lastName: row.original.note_author.last_name,
                      })
                    : "--",
        },
        {
            accessorKey: "invoice_status",
            header: "BILL STATUS",
            cell: ({ row }) => (
                <span>
                    {getBillStatusTag(
                        row.original.invoice_status as BillStatus
                    )}
                </span>
            ),
        },
        {
            header: "INSURANCE",
            columns: [
                {
                    accessorKey: "charge",
                    header: "CHARGE",
                    cell: ({ row }) => (
                        <div className="flex items-center gap-2">
                            {editingInsuranceRowId ===
                            row.original.invoice_id ? (
                                <div>
                                    <UpdateForm
                                        invoiceId={invoiceToUpdateId as string}
                                        charge_amount={
                                            insuranceChargeAmount || 0
                                        }
                                        selectedChargeType="insurance"
                                        setEditingRowId={
                                            setEditingInsuranceRowId
                                        }
                                        updateType="charge_amount"
                                    />
                                </div>
                            ) : (
                                <div className="flex gap-4 items-center">
                                    <span>
                                        {showMoneyInAppFormat(
                                            row.original.insurance_amount
                                        )}
                                    </span>
                                    {canUpdateChargeAmount &&
                                        row.original.invoice_status !==
                                            BillStatus.CLOSED && (
                                            <Button
                                                size="auto"
                                                variant="normal"
                                                onClick={() => {
                                                    setInsuranceChargeAmount(
                                                        row.original
                                                            .insurance_amount
                                                    );
                                                    setInvoiceToUpdateId(
                                                        row.original.invoice_id
                                                    );
                                                    setEditingInsuranceRowId(
                                                        row.original.invoice_id
                                                    );
                                                }}
                                            >
                                                <PencilIcon className="w-12 h-12" />
                                            </Button>
                                        )}
                                </div>
                            )}
                        </div>
                    ),
                },
                {
                    accessorKey: "collected",
                    header: "COLLECTED",
                    cell: ({ row }) =>
                        showMoneyInAppFormat(
                            row.original.insurance_amount_collected
                        ),
                },
                {
                    accessorKey: "balance",
                    header: "OUTSTANDING",
                    cell: ({ row }) => (
                        <span
                            className={cn("text-sm", {
                                "text-danger":
                                    row.original.accounting_insurance > 0,
                            })}
                        >
                            {row.original.accounting_insurance > 0
                                ? `(${showMoneyInAppFormat(
                                      row.original.accounting_insurance
                                  )})`
                                : showMoneyInAppFormat(
                                      Math.abs(
                                          row.original.accounting_insurance
                                      )
                                  )}
                        </span>
                    ),
                },
            ],
        },

        {
            header: "CO-INSURANCE",
            columns: [
                {
                    accessorKey: "charge",
                    header: "CHARGE",
                    cell: ({ row }) => (
                        <div className="flex items-center gap-2">
                            {editingCoInsuranceRowId ===
                            row.original.invoice_id ? (
                                <div>
                                    <UpdateForm
                                        invoiceId={invoiceToUpdateId as string}
                                        charge_amount={
                                            coInsuranceChargeAmount || 0
                                        }
                                        selectedChargeType="coinsurance"
                                        setEditingRowId={
                                            setEditingCoInsuranceRowId
                                        }
                                        updateType="charge_amount"
                                    />
                                </div>
                            ) : (
                                <div className="flex gap-4 items-center">
                                    <span>
                                        {showMoneyInAppFormat(
                                            row.original.coinsurance_amount
                                        )}
                                    </span>
                                    {canUpdateChargeAmount &&
                                        row.original.invoice_status !==
                                            BillStatus.CLOSED && (
                                            <Button
                                                size="auto"
                                                variant="normal"
                                                onClick={() => {
                                                    setCoInsuranceChargeAmount(
                                                        row.original
                                                            .coinsurance_amount
                                                    );
                                                    setInvoiceToUpdateId(
                                                        row.original.invoice_id
                                                    );
                                                    setEditingCoInsuranceRowId(
                                                        row.original.invoice_id
                                                    );
                                                }}
                                            >
                                                <PencilIcon className="w-12 h-12" />
                                            </Button>
                                        )}
                                </div>
                            )}
                        </div>
                    ),
                },
                {
                    accessorKey: "collected",
                    header: "COLLECTED",
                    cell: ({ row }) =>
                        showMoneyInAppFormat(
                            row.original.coinsurance_amount_collected
                        ),
                },
                {
                    accessorKey: "balance",
                    header: "OUTSTANDING",
                    cell: ({ row }) => getOutstandingValueToDisplay(row),
                },
            ],
        },
        {
            header: "PR100",
            columns: [
                {
                    accessorKey: "charge",
                    header: "CHARGE",
                    cell: ({ row }) =>
                        showMoneyInAppFormat(row.original.pr100_amount),
                },
                {
                    accessorKey: "pr100_collected",
                    header: "COLLECTED",
                    cell: ({ row }) =>
                        showMoneyInAppFormat(row.original.pr100_collected),
                },
                {
                    accessorKey: "balance",
                    header: "OUTSTANDING",
                    cell: ({ row }) => (
                        <span
                            className={cn("text-sm", {
                                "text-danger":
                                    row.original.accounting_pr100 < 0,
                            })}
                        >
                            {row.original.accounting_pr100 < 0
                                ? `(${showMoneyInAppFormat(
                                      Math.abs(row.original.accounting_pr100)
                                  )})`
                                : showMoneyInAppFormat(
                                      row.original.accounting_pr100
                                  )}
                        </span>
                    ),
                },
            ],
        },
        {
            accessorKey: "metadata",
            header: "ACCEPTS ASSIGNMENT",
            cell: ({ row }) =>
                row.original?.metadata &&
                row.original?.metadata?.accepts_assignment ? (
                    <span
                        className={
                            row.original.metadata.accepts_assignment
                                ? "text-primary"
                                : "text-danger"
                        }
                    >
                        {row.original.metadata.accepts_assignment
                            ? "Yes"
                            : "No"}
                    </span>
                ) : (
                    "--"
                ),
        },
    ];

    return (
        <>
            {showPendingReolutionDialog && (
                <Dialog open={showPendingReolutionDialog}>
                    <DialogContent
                        title="Pending session fee"
                        handleCloseDialog={handleClosePendingResolutionDialog}
                        variant="center"
                        showFooter
                        cancelText="Cancel"
                        saveText="Mark as Pending session fee"
                        submitBtnFormValue="pending-resolution"
                        isSubmitBtnDisabled={updateInVoiceIsMutating > 0}
                        isCancelBtnDisabled={updateInVoiceIsMutating > 0}
                    >
                        <PendingResolutionForm
                            onFormSubmit={() => {
                                handleClosePendingResolutionDialog();
                                setRowSelection({});
                            }}
                            metaProps={propsForPendingResolution || undefined}
                        />
                    </DialogContent>
                </Dialog>
            )}
            <Dialog open={modalInView === "bulk_writeoff"}>
                <DialogContent
                    handleCloseDialog={() => setModalInView(null)}
                    title="Bulk Write-Off"
                    variant="center"
                    saveText="Bulk Write-Off"
                    submitBtnFormValue="bulk-writeoff-form"
                    isSubmitBtnDisabled={bulkWriteOffIsMutating > 0}
                    isCancelBtnDisabled={bulkWriteOffIsMutating > 0}
                >
                    <AddBulkWriteOffForm
                        onFormSubmit={() => setModalInView(null)}
                    />
                </DialogContent>
            </Dialog>
            {selectedBills && selectedBills[0]?.invoice_id && (
                <Dialog open={modalInView === "unmatched_payments"}>
                    <DialogContent
                        title="Unmatched Payments"
                        showFooter={false}
                        handleCloseDialog={handleCloseUnmatchPaymentsModal}
                        wrapperClass={cn("w-[900px]", {
                            "w-[1500px]": canSplitPayment,
                        })}
                    >
                        <UnmatchedPaymentList
                            invoiceBalance={
                                selectedBills[0].accounting_coinsurance || 0
                            }
                            invoiceId={selectedBills[0].invoice_id}
                            onFormSubmit={handleCloseUnmatchPaymentsModal}
                            dateOfService={
                                selectedBills[0].date_of_service || ""
                            }
                        />
                    </DialogContent>
                </Dialog>
            )}

            {selectedBills && selectedBills[0]?.invoice_id && (
                <Dialog open={modalInView === "charge_client"}>
                    <DialogContent
                        variant="center"
                        showFooter={
                            !chargeModalInView || chargeModalInView === "pr"
                        }
                        submitBtnFormValue={
                            chargeModalInView === "pr"
                                ? "pending-resolution"
                                : "charge-client"
                        }
                        handleCloseDialog={
                            chargeModalInView
                                ? () => {
                                      handleModalInView({ modal: null });
                                      removeChargeModalParams();
                                      setRowSelection({});
                                  }
                                : () => {
                                      handleModalInView({ modal: null });
                                      setRowSelection({});
                                  }
                        }
                        saveText={
                            chargeModalInView === "pr"
                                ? "Mark as Pending resolution"
                                : "Charge client"
                        }
                        isSubmitBtnDisabled={
                            chargeClientIsMutating > 0 ||
                            updateInVoiceIsMutating > 0
                        }
                        isCancelBtnDisabled={
                            chargeClientIsMutating > 0 ||
                            updateInVoiceIsMutating > 0
                        }
                        title={
                            chargeModalInView === "pr"
                                ? "Mark as Pending resolution"
                                : "Charge client"
                        }
                        isBackBtnDisabled={paymentIsMutating > 0}
                    >
                        <ChargeClient
                            onFormSubmit={
                                chargeModalInView
                                    ? () => {
                                          handleModalInView({ modal: null });
                                          removeChargeModalParams();
                                          setRowSelection({});
                                      }
                                    : () => {
                                          handleModalInView({ modal: null });
                                          setRowSelection({});
                                      }
                            }
                            invoiceId={selectedBills[0].invoice_id}
                            noteId={
                                selectedBills &&
                                selectedBills.length === 1 &&
                                selectedBills[0].note_id
                                    ? selectedBills[0].note_id
                                    : ""
                            }
                        />
                    </DialogContent>
                </Dialog>
            )}

            <Dialog open={showChargeModal}>
                <DialogContent
                    variant="center"
                    title="Charge invoices"
                    handleCloseDialog={() => {
                        setShowChargeModal(false);
                        setRowSelection({});
                    }}
                    showFooter
                    saveText="Charge invoices"
                    submitBtnFormValue="invoice-payment"
                    isSubmitBtnDisabled={invoicePaymentIsMutating > 0}
                    isCancelBtnDisabled={invoicePaymentIsMutating > 0}
                >
                    <InvoicePaymentForm
                        onFormSubmit={() => {
                            setShowChargeModal(false);
                            setRowSelection({});
                        }}
                        invoices={selectedBills || []}
                    />
                </DialogContent>
            </Dialog>

            <div>
                <PaymentsOverview />

                <div className="bg-white p-16 rounded-r8 mb-24 mt-32">
                    {handleMessageToDisplay()}

                    <div className="flex gap-12 flex-wrap justify-between">
                        <div className="flex gap-12 flex-wrap">
                            <Button
                                variant="normal"
                                className="border border-card-bg bg-white shadow-active"
                                onClick={() => handleGenerateStatement()}
                            >
                                {fromDateFilter || toDateFilter
                                    ? "Generate account statement for selected period"
                                    : "Generate account statement"}
                            </Button>

                            {selectedBills && selectedBills.length === 1 && (
                                <Button
                                    variant="normal"
                                    className="border border-card-bg bg-white shadow-active"
                                    onClick={() =>
                                        handleModalInView({
                                            modal: "unmatched_payments",
                                        })
                                    }
                                >
                                    Check unmatched payments
                                </Button>
                            )}
                            {canUserPerformAction ? (
                                <Button
                                    variant="normal"
                                    className="border border-card-bg bg-white shadow-active"
                                    onClick={() =>
                                        handleModalInView({
                                            modal: "bulk_writeoff",
                                        })
                                    }
                                >
                                    Bulk Writeoff
                                </Button>
                            ) : null}
                            <Dropdown>
                                <DropdownTrigger asChild>
                                    <FilterButton text="Bill Status" />
                                </DropdownTrigger>
                                <DropdownContent
                                    align="end"
                                    width="auto"
                                    maxHeight={216}
                                    className="overflow-y-auto"
                                >
                                    {Object.values(BillStatus).map((status) => (
                                        <DropdownItem
                                            key={status}
                                            onClick={() =>
                                                handleStatusChange(status)
                                            }
                                            isMulti
                                            itemValue={status}
                                            values={invoiceStatusFilter}
                                        >
                                            {removeEnumUnderscore(
                                                BillStatusLabels[status]
                                            )}
                                        </DropdownItem>
                                    ))}
                                </DropdownContent>
                            </Dropdown>

                            <Dropdown>
                                <DropdownTrigger asChild>
                                    <Button
                                        size="auto"
                                        variant="normal"
                                        className="px-16 h-32 shadow-active gap-x-8 text-sm data-[state=open]:border-primary font-medium capitalize"
                                    >
                                        {cptCodeFilter || "CPT Code"}
                                        <ChevronDownIcon
                                            stroke={APP_COLORS.COLOR_BLACK}
                                        />
                                    </Button>
                                </DropdownTrigger>

                                <DropdownContent
                                    align="start"
                                    width="auto"
                                    maxHeight={216}
                                    className="overflow-y-auto min-w-[120px]"
                                >
                                    <DropdownItem
                                        className={cn(
                                            "flex gap-x-8 items-center text-xs font-medium",
                                            {
                                                "text-primary": !cptCodeFilter,
                                            }
                                        )}
                                        onClick={() => handleCptCodeChange("")}
                                    >
                                        All
                                        {!cptCodeFilter ? (
                                            <CheckPrimaryColorIcon />
                                        ) : null}
                                    </DropdownItem>

                                    {Object.values(
                                        CPT_CODES.map((code) => (
                                            <DropdownItem
                                                className={cn(
                                                    "flex justify-between items-center capitalize text-xs font-medium",
                                                    {
                                                        "text-primary":
                                                            cptCodeFilter ===
                                                            code,
                                                    }
                                                )}
                                                key={code}
                                                onClick={() =>
                                                    handleCptCodeChange(code)
                                                }
                                            >
                                                {removeEnumUnderscore(code)}

                                                {cptCodeFilter === code ? (
                                                    <CheckPrimaryColorIcon />
                                                ) : null}
                                            </DropdownItem>
                                        ))
                                    )}
                                </DropdownContent>
                            </Dropdown>

                            <Popover>
                                <PopoverTrigger asChild>
                                    <Button
                                        size="auto"
                                        variant="normal"
                                        className="px-16 h-32 shadow-active gap-x-8 text-sm data-[state=open]:border-primary font-medium self-start md:self-center"
                                    >
                                        Date
                                        <ChevronDownIcon
                                            stroke={APP_COLORS.COLOR_BLACK}
                                        />
                                    </Button>
                                </PopoverTrigger>
                                <PopoverContent
                                    align="end"
                                    className="p-16 gap-y-12"
                                >
                                    <DatePicker
                                        label="From"
                                        placeholderText="MM/DD/YYYY"
                                        selected={fromDateFilterToDate}
                                        onChange={handleFromDateFilterChange}
                                        maxDate={
                                            toDateFilterToDate ||
                                            new Date(Date.now())
                                        }
                                        isClearable
                                    />

                                    <DatePicker
                                        label="To"
                                        placeholderText="MM/DD/YYYY"
                                        selected={toDateFilterToDate}
                                        onChange={handleToDateFilterChange}
                                        minDate={fromDateFilterToDate}
                                        isClearable
                                    />
                                </PopoverContent>
                            </Popover>
                        </div>
                    </div>

                    {getTotalAmountOfTableRowsSelected(rowSelection).amount >
                        0 && (
                        <div className="flex items-center mt-24 mb-16 gap-12 flex-wrap">
                            <div className="font-semibold text-base">
                                {
                                    getTotalAmountOfTableRowsSelected(
                                        rowSelection
                                    ).amountString
                                }{" "}
                                :
                            </div>
                            {getTotalAmountOfTableRowsSelected(rowSelection)
                                .amount === 1 && (
                                <Button
                                    onClick={() =>
                                        handleModalInView({
                                            modal: "charge_client",
                                        })
                                    }
                                >
                                    Charge Client
                                </Button>
                            )}
                            {canPerformBulkActions && (
                                <>
                                    {getTotalAmountOfTableRowsSelected(
                                        rowSelection
                                    ).amount > 1 && (
                                        <Button
                                            onClick={() =>
                                                setShowChargeModal(true)
                                            }
                                        >
                                            Charge Invoices
                                        </Button>
                                    )}
                                    {getTotalAmountOfTableRowsSelected(
                                        rowSelection
                                    ).amount > 0 ? (
                                        <>
                                            {showCloseInvoiceBulkAction && (
                                                <Button
                                                    onClick={() =>
                                                        handleUpdateInvoiceStatus(
                                                            BillStatus.CLOSED
                                                        )
                                                    }
                                                    variant="secondary"
                                                    disabled={
                                                        updateInvoice.isLoading
                                                    }
                                                >
                                                    Mark as close
                                                </Button>
                                            )}
                                            {showOpenInvoiceBulkAction && (
                                                <Button
                                                    onClick={() =>
                                                        handleUpdateInvoiceStatus(
                                                            BillStatus.OPEN
                                                        )
                                                    }
                                                    variant="secondary"
                                                    disabled={
                                                        updateInvoice.isLoading
                                                    }
                                                >
                                                    Mark as open
                                                </Button>
                                            )}
                                            {showPendingResolutionInvoiceBulkAction && (
                                                <Button
                                                    onClick={() =>
                                                        handleShowPendingResolutionDialog()
                                                    }
                                                    variant="secondary"
                                                    disabled={
                                                        updateInvoice.isLoading
                                                    }
                                                >
                                                    Mark as pending session fee
                                                </Button>
                                            )}
                                            {showPendingInsuranceResolutionInvoiceBulkAction && (
                                                <Button
                                                    onClick={() =>
                                                        handleUpdateInvoiceStatus(
                                                            BillStatus.PENDING_INSURANCE_RESOLUTION
                                                        )
                                                    }
                                                    variant="secondary"
                                                    disabled={
                                                        updateInvoice.isLoading
                                                    }
                                                >
                                                    Mark as pending insurance
                                                    resolution
                                                </Button>
                                            )}
                                            {showPendingPatientResolutionInvoiceBulkAction && (
                                                <Button
                                                    onClick={() =>
                                                        handleUpdateInvoiceStatus(
                                                            BillStatus.PENDING_INSURANCE_RESOLUTION
                                                        )
                                                    }
                                                    variant="secondary"
                                                    disabled={
                                                        updateInvoice.isLoading
                                                    }
                                                >
                                                    Mark as pending patient
                                                    resolution
                                                </Button>
                                            )}
                                        </>
                                    ) : null}
                                </>
                            )}
                        </div>
                    )}

                    <TableFiltersDisplay
                        appliedFilters={[
                            {
                                key: "invoice_status[]",
                                values: invoiceStatusFilter,
                            },
                        ]}
                        wrapperClass="mt-12"
                        localStorageKey="clientclaimslist_filters"
                    />
                </div>

                {error && (
                    <ListState
                        isError
                        stateHelperText="Try reloading this page to solve this issue"
                        errorMsg={
                            error?.response?.data.message ||
                            `Cannot display invoices at this time please try again later`
                        }
                    />
                )}
                {data && isSuccess && data.data.length === 0 && (
                    <ListState
                        stateHelperText="Invoice list will appear here"
                        isError={false}
                        emptyMessage="There are no invoices yet"
                    />
                )}
                {isLoading && (
                    <Skeleton
                        type="table"
                        containerTestId="client-payment-list-loader"
                        count={6}
                    />
                )}
                {!isLoading && isSuccess && data && data.data.length > 0 && (
                    <Table
                        data={data.data}
                        columns={columns}
                        rowSelection={rowSelection}
                        setRowSelection={setRowSelection}
                        setGetSelectedData={setGetSelectedData}
                        expandedRows={expandedRows}
                        setExpandedRows={setExpandedRows}
                        renderRowSubComponent={renderRowSubComponentShow}
                        className={styles.billslist}
                        hasPagination
                        isHeaderFixed
                        pagination={
                            <Pagination
                                totalCount={data.total_count}
                                totalPages={data.total_pages}
                                currentPage={pageFilter}
                                onPageChange={handlePageChange}
                                limit={limitFilter}
                                onLimitChange={handleLimitChange}
                            />
                        }
                    />
                )}

                {canViewCreditCardInfo ? (
                    <div className="mt-32">
                        <CreditCards />
                    </div>
                ) : null}
            </div>
        </>
    );
}
