import { ISortTable } from ".";
import {
    BillPaymentStatus,
    BillStatus,
} from "../../modules/billing/types/billing.types";
import { IApiResponse } from "./api-response";
import { NoteTypes } from "./notes";

export enum BillingStatus {
    OPEN = "open",
    // READY_FOR_SUBMISSION = "ready for submission",
    // SUBMITTED = "submitted",
    // INVALID = "invalid",
    // APPROVED = "approved",
    // REJECTED = "rejected",
    CLOSED = "closed",
    PENDING_RESOLUTION = "pending_resolution",
    PENDING_PATIENT_RESOLUTION = "pending_patient_resolution",
    PENDING_INSURANCE_RESOLUTION = "pending_insurance_resolution",
}

export enum PaymentStatus {
    COMPLETED = "completed",
    PENDING = "pending",
    FAILED = "failed",
    REFUNDED = "refunded",
    VOIDED = "voided",
}

export enum UnmatchedPaymentStatusFilter {
    HAS_OPEN_INVOICE = "has_open_invoice",
    HAS_NO_OPEN_INVOICE = "has_no_invoice",
}

export enum UnmatchedPaymentStatusFilterDescription {
    has_open_invoice = "show_payments_with_open_bills",
    has_no_invoice = "show_payments_with_no_bills",
}

export enum ReportStatus {
    SUBMITTED = "submitted",
    INVALID = "invalid",
    APPROVED = "approved",
}

export enum ShowFullyPaidInvoicesStatus {
    true = "show_fully_paid_bills",
    false = "hide_fully_paid_bills",
}

export enum ShowFullyPaidInvoicesBooleanOptions {
    show_fully_paid_bills = "true",
    hide_fully_paid_bills = "false",
}

export enum InsuranceProvider {
    ELEVEN_NINTY_NINE = "1199",
    AUNAMA = "aunama",
    ANTHEM = "anthem",
    ALLIED = "allied",
    AETNA = "aetna",
    BCBS = "bcbs",
    BEACON = "beacon",
    CIGNA = "cigna",
    CORESOURCE = "coresource",
    EMBLEM_HEALTH = "emblem_health",
    GEHA = "geha",
    GHI = "ghi",
    HARVARD_PILGRIM = "harvard_pilgrim",
    HUMANA = "humana",
    MERITIAN = "meritain",
    NYSHIP = "nyship",
    OLYMPUS = "olympus",
    OPTUM = "optum",
    OXFOFRD = "oxford",
    OTHER = "other",
    UMR = "umr",
    UNITED_HEALTHCARE_S = "united_healthcare_s",
}

export enum WriteOffCodes {
    FAP = "fap",
    WOBAD = "wobad",
    WOBAL = "wobal",
    WOBANK = "wobank",
    WOCOLL = "wocoll",
    WOCOUR = "wocour",
    WOHRDSHIP = "wohrdship",
    WOINS = "woins",
    WOMISC = "womisc",
    WONOSHOW = "wonoshow",
    WOREV = "worev",
    WOSINTK = "wosintk",
    WODED = "woded",
}

export enum WriteOffCodesDecription {
    fap = "FAP",
    wobad = "WOBAD: WRITE OFF - BAD DEBT",
    wobal = "WOBAL: WRITE OFF - BALANCE (INSADJ)",
    wobank = "WOBANK: WRITE OFF - BANKRUPTCY",
    wocoll = "WOCOLL: WRITE OFF - COLLECTIONS",
    wocour = "WOCOUR: WRITE OFF - COURTESY",
    wohrdship = "WOHRDSHIP: W/O - FINANCIAL HARDSHIP",
    woins = "WOINS: WRITE OFF - INSURANCE ",
    womisc = "WOMISC: WRITE OFF - MISC ",
    wonoshow = "WONOSHOW: WRITE OFF - THERAPIST WAIVED NO ",
    worev = "WOREV: WRITE OFF - REBILLED ",
    wosintk = "WOSINTK: WRITE OFF - SUPERVISOR INTAKE ",
    woded = "WODED: WRITE OFF - DEDUCTIBLE",
}

export enum WriteOffSource {
    INSURANCE = "insurance",
    CO_INSURANCE = "co_insurance",
}

export enum PaymentType {
    CREDIT = "credit",
    WRITEOFF = "write_off",
    REFUND = "refund",
}

export enum RefundReason {
    VOID_PAYMENT = "void_payment",
    DUPLICATE_PAYMENT = "duplicate_payment",
    OVER_PAYMENT = "over_payment",
    BILLING_ERROR = "billing_error",
    OTHERS = "others",
}

export enum Payor {
    ATENA = "atena",
    AUNAMA = "aunama",
    WELL_FLEET = "well_fleet",
    CIGNA = "cigna",
}
export enum PaymentMethod {
    CHEQUE = "cheque",
    CARD = "card",
    OTHER = "other",
}

export enum InvoiceView {
    AMOUNT_COLLECTED = "amount_collected",
    UNMATCHED_PAYMENTS = "unmatched_payments",
    AMOUNT_OUTSTANDING = "amount_outstanding",
    PENDING_SETTLEMENT = "pending_settlement",
}

export enum InvoiceIssueCategory {
    CARD_PAYMENT_FAILED = "card_payment_failed",
    OTHER = "other",
    NO_MATCHED_PAYMENT = "no_matched_payment",
}

export interface IBillingFilterTypes {
    search_string?: string;
    chart_number?: string;
    client_id?: string;
    insurance_provider?: string;
    provider_name?: string;
    invoice_status?: string[];
    cpt_code?: string;
    pending_resolution_category?: string;
    from_date?: null | Date;
    to_date?: null | Date;
    page?: number;
    limit?: number;
    sort_attribute?: string;
    sort_order?: string;
    invoice_id?: string;
    payment_view?: boolean;
}

export interface IUnmatchedPaymentFilterTypes {
    payment_status: string;
    page: number;
    limit?: number;
    client_id?: string;
}

export interface IAllUnmatchedPaymentFilterTypes {
    client_name: string;
    custom_date: string;
    invoice_status: string;
    page: number;
    limit: number;
    to_date: null | Date;
    from_date: null | Date;
    sort_attribute?: string;
    sort_order?: string;
}

export interface IMatchedPaymentFilterTypes {
    to_date: null | Date;
    from_date: null | Date;
    invoice_status: string;
    show_fully_paid_invoices: null | boolean;
    client_name: string;
    custom_date: string;
    page: number;
    limit: number;
    sort_attribute?: string;
    sort_order?: string;
}

export interface IGetMatchedPaymentResponse extends ISortTable {
    amount: number;
    payment_method: string;
    date_of_service: string;
    payment_date: string;
    invoice_status: string;
    payment_status: string;
    auth_code: string;
    client_name: string;
    transaction_id: string;
    posted_by: {
        user_id?: string;
        first_name: string;
        last_name: string;
    };
    invoice_id: string;
    cpt_code: string;
    processor_payment_status: string;
    accounting: number;
    payment_id: string;
    cheque_number: string;
    client_id: string;
    note_id: string;
}
export interface IBillingResponse {
    date_of_service: string;
    client: {
        client_id: string;
        first_name: string;
        last_name: string;
    };
    provider: {
        first_name: string;
        last_name: string;
    };
    insurance_provider: string;
    invoice_status: BillStatus;
    payment_status?: BillPaymentStatus;
    no_of_outstanding_bills: number;
    insurance_amount: number;
    insurance_amount_collected: number;
    coinsurance_amount: number;
    coinsurance_amount_collected: number;
    chart_number: string;
    note_id: string;
    note_type?: string;
    invoice_id: string;
    cpt_code?: string;
    pos_code?: string;
    accounting_coinsurance: number;
    accounting_insurance: number;
    pr100_collected: number;
    pr100_amount: number;
    issue_category?: string;
    issue_notes?: string;
    issue_notes_preview?: string;
    metadata?: {
        accepts_assignment?: boolean;
        self_pay?: boolean;
    };
    note_author?: {
        id: string;
        first_name: string;
        last_name: string;
    };
}

export interface IUnmatchedPaymentResponse extends ISortTable {
    payment_type: string;
    amount: number;
    payment_method: string;
    payment_date: string;
    unmatched_payment_id: string;
    payment_status: string;
    auth_code: string;
    processor_payment_status: string;
}

export interface IUpdatedInvoiceResponse {
    updated_invoices: string[];
    unupdated_invoices: string[];
}

export interface IRefreshedPaymentStatusResponse {
    message: string;
    updated_payments: string[];
    unupdated_payments: string[];
}

export interface IUpdateInvoice {
    invoice_ids: string[];
    status: string;
    issue_category?: string;
    issue_notes?: string;
}

export interface IRefreshPaymentStatus {
    payment_ids: string[];
}

export interface IPaymentInfo {
    payment_id: string;
    client: {
        first_name: string;
        last_name: string;
    };
    date_of_service: string;
    date_submitted: string;
    provider: string;
    ins_provider: string;
    biller: string;
    insurance_billed: number;
    insurance_paid: number;
    insurance_outstanding: number;
    co_insurance_billed: number;
    co_insurance_paid: number;
    accounting_insurance: number;
    accounting_co_insurance: number;
    status: ReportStatus;
}

export interface IAddPaymentResponse {
    message: string;
    payment_id: string;
}

export interface IAddPayment {
    payment_type: string | undefined;
    payment_method?: string;
    payment_date: string;
    payment_notes: string;
    writeoff_code?: string;
    write_off_comments: string;
    auth_code: string;
    payment_info: {
        refund_reason: string;
        payor: string;
        cheque_number: string;
        authorization_number: string;
    };
    remark_codes?: string[];
}

export interface IAddInvoicePayment extends IAddPayment {
    payment_amount: number;
}

export interface IRematchPayment {
    payment_id: string;
    invoice_id: string;
}

export interface IGetPayment {
    invoice_id: string;
    payment_type?: string;
}
export interface IDeleteUnMatchedPayment {
    unmatched_payment_ids: string[];
}
export interface PaymentBreakdownItem {
    payment_breakdown: [
        {
            payment_type: string;
            payment_date: string;
            amount: number;
            date_of_service: string;
            payment_method: string;
            payment_description: string;
            posted_by: {
                first_name: string;
                last_name: string;
            };
            date_posted: string;
            payment_notes: string;
            payment_status: string;
            writeoff_code: string;
            auth_code?: string;
            payment_id: string;
            transaction_id?: string;
            processor_payment_status?: string;
            payment_info: {
                refund_reason: string;
                cheque_number: string;
                authorization_number: string;
            };
            has_split_payments?: boolean;
            parent_payment_id?: string;
            remark_code?: string;
        }
    ];
}

export interface IDeleteUnMatchedPaymentReponse {
    message: string;
    deleted_payments: string[];
    undeleted_payments: string[];
}

export interface IUnmatchPayment {
    matched_payments: {
        [invoiceId: string]: string[];
    };
}

export interface IMovePayment {
    payment_id: string;
    invoice_id: string;
    amount: number;
}

export interface IMovePaymentResponse {
    payment_type: string;
    payment_amount: number;
    payment_method: string;
    payment_date: string;
    payment_notes: string;
    writeoff_code: string;
    writeoff_comments: string;
    auth_code: string;
    payment_info: {
        additionalProp1: string;
        additionalProp2: string;
        additionalProp3: string;
    };
    payment_description: string;
    claim_id: string;
    remittance_id: string;
}
export interface IRefundPayment {
    transaction_id: string;
    payment_table: string;
}

export interface IUnmatchPaymentResponse {
    successful_payments: string[];
    failed_payments: string[];
}
export interface IRefundPaymentResponse {
    message: string;
}

export interface BillingOverviewFilters {
    search_string?: string;
    custom_date?: string;
    from_date?: string;
    to_date?: string;
    page?: number;
    limit?: number;
    insurance_provider_id?: string;
    sort_attribute?: string;
    sort_order?: string;
}
export interface RefundPaymentApiResponse<DataType>
    extends IApiResponse<DataType> {
    payment_status: string;
}

export interface IGetPaymentResponse extends PaymentBreakdownItem {
    message: string;
    charged_amount: number;
    total_collected_amount: number;
    accounting: number;
    total_credit: number;
    total_refund: number;
    total_writeoff: number;
    coinsurance_amount: number;
    insurance_amount: number;
}

export interface IMatchedPaymentResponse {
    message: string;
    added_payments: string[];
    unadded_payments: string[];
}
export interface IAddPaymentToMatch {
    unmatched_payment_matches: {
        [invoiceId: string]: string[];
    };
    is_pr_100?: boolean;
}

export interface IClientInvoice {
    cpt_code: string;
    amount_charged: number;
    total_amount_collected: number;
    date_of_service: string;
    note_id: string;
    provider_name: string;
    invoice_status: string;
    invoice_id: string;
}

export interface IUnMatchedInvoiceResponse {
    payment_date: string;
    client_name: string;
    payment_method: string;
    client_id: string;
    unmatched_payment_id: string;
    cheque_number?: string;
    posted_by?: {
        first_name: string;
        last_name: string;
    };
    amount: number;
    auth_code?: string;
    payment_status: string;
    open_invoice: number;
    client_invoices: IClientInvoice[];
    transaction_id: string;
    processor_payment_status: string;
}

export interface IChargeClient {
    invoice_id: string;
    note_type: NoteTypes;
    amount: number;
    payment_card_id?: string;
    payment_description?: string;
    is_pr_100: boolean;
    payment_source?: string;
}

export interface IBillingAdminDashboardResponse {
    from_date: string;
    to_date: string;
    summary: {
        amount_billed: number;
        amount_billed_count: number;
        amount_collected_count: number;
        amount_outstanding_count: number;
        amount_pending_settlement_count: number;
        amount_unmatched_count: number;
        amount_outstanding: number;
        amount_collected: number;
        amount_pending_settlement: number;
        amount_unmatched: number;
        denied_claims_amount: number;
        denied_claims_count: number;
        submitted_claims_amount: number;
        submitted_claims_count: number;
        approved_claims_amount: number;
        approved_claims_count: number;
        pr100_collected: number;
        pr100_collected_count: number;
    };
    unmatched_payment_monthly_stat: IUnmatchedPaymentMonthlyStat[];
    invoice_breakdown: IBillingDashboardInvoiceBreakdown[];
    monthly_breakdown: IBillingDashboardMonthlyBreakdown[];
    unmatched_payments: IBillingDashboardUnmatchedPayments[];
}

export interface IUnmatchedPaymentMonthlyStat {
    unmatched_amount_collected: number;
    unmatched_amount_collected_count: number;
    payment_month: number;
    payment_year_month: string;
}

export interface IBillingAdminDashboardFilters {
    custom_date?: string;
    from_date?: string | null;
    to_date?: string | null;
    view?: string;
    show_invoice_breakdown?: boolean;
    show_report_data?: boolean;
    sort_attribute?: string;
    sort_order?: string;
    provider_name?: string;
    search_string?: string;
    type?: string;
    insurance_provider_id?: string;
    page?: number;
    limit?: number;
    provider_names?: string[];
    client_names?: string[];
    aging_day_intervals?: string[];
    invoice_status?: string[];
}

export interface IBillingDashboardMonthlyBreakdown {
    encounters: number;
    amount_billed: number;
    amount_billed_count: number;
    insurance_billed: number;
    coinsurance_billed: number;
    amount_collected: number;
    amount_collected_count: number;
    amount_outstanding: number;
    amount_outstanding_count: number;
    amount_pending_settlement: number;
    amount_pending_settlement_count: number;
    unmatched_amount_collected: number | null;
    unmatched_amount_collected_count: number;
    // adding the next two types so we have a match with the ui
    unmatched_payments: number;
    unmatched_payments_count: number;
    payment_month: number;
    payment_year_month: string;
    submitted_claims_count: number;
    submitted_claims_amount: number;
    denied_claims_count: number;
    denied_claims_amount: number;
    approved_claims_count: number;
    approved_claims_amount: number;
    pr100_collected: number;
    pr100_collected_count: number;
    total_credit: number;
    total_refunds: number;
}

export interface IBillingDashboardInvoiceBreakdown extends ISortTable {
    date_of_service: string;
    payment_date: string;
    collected: number;
    amount_outstanding: number;
    charge_amount: number;
    insurance_amount: number;
    coinsurance_amount: number;
    note_id: string;
    pending_settlement: number;
    invoice_status: BillingStatus;
    provider: {
        user_id: string;
        first_name: string;
        last_name: string;
        provider_id: string;
        npi: string;
    };
    client: {
        user_id: string;
        client_id: string;
        first_name: string;
        last_name: string;
        date_of_birth: string;
    };
    claim_external_reference_id: string;
    cpt_code: string;
    diagnosis_codes: string[];
    pr100_collected: number;
    pr100_refunded: number;
    payment_amount: number;
}

export interface IBillingDashboardUnmatchedPayments extends ISortTable {
    payment_type: string;
    amount: number;
    payment_method: string;
    payment_date: string;
    payment_notes: string;
    writeoff_code: string;
    writeoff_comments: string;
    auth_code: string;
    payment_info: {
        refund_reason: string;
        cheque_number: string;
        authorization_number: string;
    };
    practice_id: string;
    transaction_id: string;
    client_id: string;
    customer_profile_id: string;
    customer_payment_profile_id: string;
    processor_payment_status: string;
    unmatched_payment_id: string;
    payment_status: string;
    payment_channel: string;
    open_bills: number;
    client: {
        client_id: string;
        first_name: string;
        last_name: string;
    };
    client_invoices: [
        {
            cpt_code: string;
            amount_charged: number;
            total_amount_collected: number;
            date_of_service: string;
            note_id: string;
            provider_name: string;
            invoice_status: string;
            invoice_id: string;
        }
    ];
}

export interface IUpdateInvoiceCharge {
    invoice_id: string;
    amount: number;
    charge_type: string;
}

export interface ISelectedPayment {
    payment_id: string;
    date_posted: string;
    payment_date: string;
    amount: number;
    payment_notes: string;
    writeoff_code: WriteOffCodes;
    payment_type: string;
    remark_codes?: string[];
    payment_status: string;
    payment_channel?: string;
}

export interface IUpdatePayment {
    payment_id: string;
    amount?: number;
    payment_date: string;
    writeoff_code?: string;
    payment_notes?: string;
    remark_codes?: string[];
    payment_status?: string;
    remark_codes_payment?: Record<string, number | null>;
    remark_codes_payment_form?: { code: string; amount: string }[];
}

export interface IUpdateInvoicePayment extends ISelectedPayment {
    invoice_id?: string;
    charge_amount?: string;
}

export interface IUpdatePaymentResponse {
    message: string;
}

export interface IUpdateInvoiceChargeResponse {
    invoice_id: string;
    session_history_id: string;
    client_id: string;
    insurance_amount: number;
    coinsurance_amount: number;
    practice_id: string;
    invoice_status: BillingStatus;
}

export interface IBulkPayment {
    invoices: { invoice_id: string; amount: number }[];
    payment_card_id: string;
    payment_type?: string;
}

export interface IBulkPaymentResponse {
    invoices_charged: string[];
    invoices_not_charged: {
        invoice_ids: string;
        reason: string;
    }[];
}

export interface ISplitPaymentPayload {
    payment_id: string;
    invoices: {
        invoice_id: string;
        amount: number;
    }[];
    payment_type?: string;
}

export interface ISplitPaymentPayloadSingleBill extends ISplitPaymentPayload {
    bill: {
        invoice_id: string;
        amount: number;
    };
}

export interface ISplitPaymentPayloadForm {
    unmatched_payment_id: string;
    invoices: {
        invoice_id: string;
        amount: number;
    }[];
}

export interface ISplitPaymentResponse {
    created_payments: string[];
}

export interface IInvoiceNotesFilters {
    invoice_id: string;
    invoice_note_id?: string;
    author_name?: string;
    page?: number;
    limit?: number;
    from_date?: string | null;
    to_date?: string | null;
}

export interface IInvoiceNotesResponse {
    invoice_note_id: string;
    invoice_id: string;
    author: {
        first_name: string;
        last_name: string;
        user_id: string;
    };
    description: string;
    date_created: string;
}

export interface ISendStatementOfAccount {
    emails: string[];
}
