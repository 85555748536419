/* eslint-disable react/no-unstable-nested-components */
import * as React from "react";
import {
    SearchInput,
    Popover,
    PopoverTrigger,
    PopoverContent,
    Button,
    Dropdown,
    DropdownTrigger,
    DropdownContent,
    DropdownItem,
    Checkbox,
    DatePicker,
    Tooltip,
    Table,
    Pagination,
    Dialog,
    DialogContent,
} from "@jhool-io/fe-components";
import { useSearchParams, useNavigate } from "react-router-dom";
import { format } from "date-fns";
import { ColumnDef, ExpandedState, Row } from "@tanstack/react-table";
import { useIsMutating, useQueryClient } from "@tanstack/react-query";

import Navbar from "../../../../components/Navbar/Navbar";
import ChevronDownIcon from "../../../../components/Icons/ChevronDown";
import {
    APP_COLORS,
    CPT_CODES,
    CPT_CODES_DESCRIPTION,
    LISTS_DEFAULT_LIMIT,
} from "../../../../utils/constants";
import { useDebounce } from "../../../../hooks/helpers";
import {
    buildSuccessMessage,
    cn,
    convertDateFilterStringToDate,
    displayNameInRightFormat,
    formatDate,
    getTotalAmountOfTableRowsSelected,
    removeEnumUnderscore,
    showMoneyInAppFormat,
    truncateString,
} from "../../../../utils/helpers";
import Skeleton from "../../../../components/Skeleton/Skeleton";
import ListState from "../../../../components/ListState/ListState";

import { useGetPayers } from "../../../payers/hooks/payers.queries";
import {
    IBillingResponse,
    IUpdatedInvoiceResponse,
} from "../../../../utils/types/billing";
import CheckPrimaryColorIcon from "../../../../components/Icons/CheckPrimaryColor";
import InfoIcon from "../../../../components/Icons/InfoIcon";
import { NoteLabels, NoteTypes } from "../../../../utils/types/notes";
import { useUpdateInvoiceStatus } from "../../../../hooks/mutations/billing";
import { IPaginatedApiResponse } from "../../../../utils/types/api-response";
import useToast from "../../../../hooks/useToast";
import {
    BillPaymentStatus,
    BillStatus,
    BillStatusLabels,
} from "../../types/billing.types";
import {
    getBillPaymentStatusTag,
    getBillStatusTag,
} from "../../helpers/billing.helpers";
import useShowInsuranceOverSelfPay from "../../../practice-settings/hooks/useShowInsuranceOverSelfPay";
import FilterButton from "../../../../shared-ui/Buttons/FilterButton/FilterButton";
import TableFiltersDisplay from "../../../../shared-ui/TableFiltersDisplay/TableFiltersDisplay";
import { useFetchInvoices } from "../../hooks/queries/billing.queries";
import { UserRole } from "../../../../utils/types/user";
import PendingResolutionForm, {
    PendingResolutionMetaData,
} from "../PendingResolutionForm/PendingResolutionForm";
import { useAppSelector } from "../../../../hooks/useRedux";
import { useFetchProviders } from "../../../providers/hooks/providers.queries";
import styles from "./BillsList.module.scss";

type ComboOption = {
    value: string;
    label: string;
};

const PendingResolutionOptions = [
    "card_payment_failed",
    "no_matched_payment",
    "other",
];

export default function BillsList() {
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();

    const { toast } = useToast();

    const getBillStatus = () => {
        const invoiceStatusFilter = searchParams.getAll("invoice_status[]");

        if (invoiceStatusFilter.length > 0) {
            if (invoiceStatusFilter.includes("all")) {
                return [];
            }
            return invoiceStatusFilter;
        }

        return [];
    };

    const invoiceStatusFilter = getBillStatus() || [];
    const cptCodeFilter = searchParams.get("cpt_code") || "";
    const pendingResolutionFilter =
        searchParams.get("pending_resolution") || "";
    const fromDateFilter = searchParams.get("from_date");
    const toDateFilter = searchParams.get("to_date");
    const clientFilter = searchParams.get("search_string") || "";
    const pageFilter = Number(searchParams.get("page")) || 1;
    const limitFilter =
        Number(searchParams.get("limit")) || LISTS_DEFAULT_LIMIT;
    const [showPayerListPopover, setShowPayerListPopover] =
        React.useState(false);
    const [rowSelection, setRowSelection] = React.useState({});
    const [getSelectedData, setGetSelectedData] =
        React.useState<Row<IBillingResponse>[]>();
    const [propsForPendingResolution, setPropsForPendingResolution] =
        React.useState<PendingResolutionMetaData | null>(null);
    const [showPendingReolutionDialog, setShowPendingResolutionDialog] =
        React.useState(false);
    const [selectedPayer, setSelectedPayer] =
        React.useState<ComboOption | null>(null);
    const [payerSearchString, setPayerSearchString] = React.useState("");
    const [sortConfig, setSortConfig] = React.useState<{
        key: string;
        direction: string;
    }>({
        key: "",
        direction: "",
    });
    const [showCliniciansListPopover, setShowCliniciansListPopover] =
        React.useState(false);
    const [selectedProvider, setSelectedProvider] =
        React.useState<ComboOption | null>(null);
    const [providerSearchString, setProviderSearchString] = React.useState("");

    const fromDateFilterToDate = convertDateFilterStringToDate(fromDateFilter);
    const toDateFilterToDate = convertDateFilterStringToDate(toDateFilter);
    const debouncedPayerSearchValue = useDebounce(payerSearchString);
    const debouncedClientFilter = useDebounce(clientFilter);

    const debouncedProviderSearchValue = useDebounce(providerSearchString, 500);

    const showInsOverSelfPay = useShowInsuranceOverSelfPay();

    const sortableColumns = [
        "client_name",
        "provider_name",
        "coinsurance_charge",
        "coinsurance_amount_collected",
        "insurance_amount_collected",
        "insurance_charge",
        "insurance_provider",
        "cpt_code",
        "date_of_service",
        "note_author",
    ];

    const providerList = useFetchProviders({
        page: 1,
        search_string: debouncedProviderSearchValue.toLowerCase(),
        limit: 20,
    });

    const handleConfigAttribute = () => {
        if (sortableColumns.includes(sortConfig.key)) {
            return sortConfig.key;
        }

        return undefined;
    };

    const insuranceProviders = useGetPayers({
        insurance_provider_name: debouncedPayerSearchValue.toLowerCase(),
    });

    const { data, isLoading, error, isSuccess } = useFetchInvoices({
        search_string: debouncedClientFilter,
        insurance_provider: selectedPayer?.value,
        invoice_status: invoiceStatusFilter,
        cpt_code: cptCodeFilter,
        pending_resolution_category: pendingResolutionFilter,
        from_date: fromDateFilter,
        to_date: toDateFilter,
        page: pageFilter,
        limit: limitFilter,
        sort_attribute:
            localStorage.getItem("sort_attr_bills") ||
            searchParams.get("sort_attr") ||
            handleConfigAttribute(),
        sort_order:
            localStorage.getItem("sort_order_bills") ||
            searchParams.get("sort_order") ||
            sortConfig.direction,
        author_id: selectedProvider?.value,
    });

    const patchInvoiceStatus = useUpdateInvoiceStatus();

    const queryClient = useQueryClient();

    const { practice } = useAppSelector((state) => state.userPractice);

    const updateInVoiceIsMutating = useIsMutating(["update-invoice"]);

    const showOpenInvoiceBulkAction =
        getSelectedData &&
        getSelectedData.length > 0 &&
        getSelectedData.length ===
            getSelectedData.filter(
                (row) => row.original.invoice_status !== BillStatus.OPEN
            ).length;

    const showCloseInvoiceBulkAction =
        getSelectedData &&
        getSelectedData.length > 0 &&
        getSelectedData.length ===
            getSelectedData.filter(
                (row) => row.original.invoice_status !== BillStatus.CLOSED
            ).length;

    const showPendingResolutionInvoiceBulkAction =
        getSelectedData &&
        getSelectedData.length > 0 &&
        getSelectedData.length ===
            getSelectedData.filter(
                (row) =>
                    row.original.invoice_status !==
                    BillStatus.PENDING_RESOLUTION
            ).length;

    const showPendingInsuranceResolutionInvoiceBulkAction =
        getSelectedData &&
        getSelectedData.length > 0 &&
        getSelectedData.length ===
            getSelectedData.filter(
                (row) =>
                    row.original.invoice_status !==
                    BillStatus.PENDING_INSURANCE_RESOLUTION
            ).length;

    const showPendingPatientResolutionInvoiceBulkAction =
        getSelectedData &&
        getSelectedData.length > 0 &&
        getSelectedData.length ===
            getSelectedData.filter(
                (row) =>
                    row.original.invoice_status !==
                    BillStatus.PENDING_PATIENT_RESOLUTION
            ).length;

    const singleBillViewStatusParams = practice?.roles?.includes(
        UserRole.CLIENT_SUPPORT
    )
        ? "?invoice_status[]=pending_resolution"
        : "";

    const handleRowClick = (row: Row<IBillingResponse>) => {
        navigate(
            `/billing-and-claims/${row.original.client.client_id}/${row.original.note_id}${singleBillViewStatusParams}`
        );
    };

    const handleStatusChange = (status: string) => {
        if (invoiceStatusFilter.includes(status)) {
            searchParams.delete("invoice_status[]", status);
        } else {
            searchParams.append("invoice_status[]", status);
        }
        searchParams.set("page", "1");
        setSearchParams(searchParams);
    };

    const handleCptCodeChange = (value: string) => {
        if (value === "") searchParams.delete("cpt_code");
        else searchParams.set("cpt_code", value);

        searchParams.set("page", "1");
        setSearchParams(searchParams);
    };

    const handlePendingResolutionChange = (value: string) => {
        if (value === "") searchParams.delete("pending_resolution");
        else searchParams.set("pending_resolution", value);

        searchParams.set("page", "1");
        setSearchParams(searchParams);
    };

    const handleFromDateFilterChange = (date: Date | null) => {
        if (date === null) searchParams.delete("from_date");
        else searchParams.set("from_date", format(date, "yyyy-MM-dd"));

        searchParams.set("page", "1");
        setSearchParams(searchParams);
    };

    const handleToDateFilterChange = (date: Date | null) => {
        if (date === null) searchParams.delete("to_date");
        else searchParams.set("to_date", format(date, "yyyy-MM-dd"));

        searchParams.set("page", "1");
        setSearchParams(searchParams);
    };

    const handleClientFilterChange = (
        e: React.ChangeEvent<HTMLInputElement>
    ) => {
        if (e.target.value === "") searchParams.delete("search_string");
        else searchParams.set("search_string", e.target.value);

        searchParams.set("page", "1");
        setSearchParams(searchParams);
    };

    const handlePageChange = (page: number) => {
        searchParams.set("page", String(page));
        setSearchParams(searchParams);
    };

    const handleLimitChange = (limit: number) => {
        searchParams.set("limit", String(limit));
        setSearchParams(searchParams);
    };

    const handleShowPendingResolutionDialog = (row?: Row<IBillingResponse>) => {
        setShowPendingResolutionDialog(true);
        setPropsForPendingResolution({
            noteId: row?.original.note_id,
            clientId: row?.original.client.client_id,
            invoiceId: row?.original.invoice_id,
            invoiceIds: getSelectedData
                ? getSelectedData.map((item) => item.original.invoice_id)
                : undefined,
        });
    };

    const handleClosePendingResolutionDialog = () => {
        setShowPendingResolutionDialog(false);
        setPropsForPendingResolution(null);
    };

    const handleChangeStatus = (status: BillStatus) => {
        if (getSelectedData) {
            const selectedIds = getSelectedData.map(
                (item: Row<IBillingResponse>) => item.original.invoice_id
            ) as string[];
            const changeStatusPayload = {
                invoice_ids: selectedIds,
                status,
            };
            patchInvoiceStatus.mutate(changeStatusPayload, {
                onSuccess: async (response) => {
                    queryClient.setQueryData<
                        IPaginatedApiResponse<IUpdatedInvoiceResponse>
                    >(["invoices"], (prev) => {
                        const prevRequired =
                            prev as IPaginatedApiResponse<IUpdatedInvoiceResponse>;
                        return {
                            ...prevRequired,
                            invoice_status: response.status,
                        };
                    });
                    await queryClient.invalidateQueries({
                        queryKey: ["invoices"],
                    });
                    toast({
                        mode: "success",
                        message: `${buildSuccessMessage(
                            response.data.updated_invoices,
                            response.data.unupdated_invoices
                        )}`,
                    });
                    setRowSelection({});
                },
                onError: (err) => {
                    toast({
                        mode: "error",
                        message:
                            err.response?.data.message ||
                            "Could not update selected bills at this time",
                    });
                },
            });
        }
    };

    const invoiceItems =
        (data &&
            data.data.filter(
                (item) =>
                    item.note_type !== "supervision_note" &&
                    item.note_type !== "safety_plan"
            )) ||
        [];

    const columns: ColumnDef<IBillingResponse>[] = [
        {
            id: "select",
            header: ({ table }) => (
                <Checkbox
                    handleChange={table.getToggleAllRowsSelectedHandler()}
                    indeterminate={table.getIsSomeRowsSelected()}
                    label=""
                    name="row"
                    value="row"
                    isChecked={table.getIsAllRowsSelected()}
                    labelClass="z-0"
                />
            ),
            cell: ({ row }) => (
                <Checkbox
                    handleChange={row.getToggleSelectedHandler()}
                    indeterminate={row.getIsSomeSelected()}
                    id={row.original.invoice_id}
                    label=""
                    name="row"
                    value="row"
                    isChecked={row.getIsSelected()}
                    labelClass="z-0"
                />
            ),
        },
        {
            header: " ",
            columns: [
                {
                    accessorKey: "client_name",
                    header: "CLIENT",
                    cell: ({ row }) => (
                        <span>
                            {row.original.client
                                ? displayNameInRightFormat({
                                      firstName: row.original.client.first_name,
                                      lastName: row.original.client.last_name,
                                  })
                                : "--"}
                        </span>
                    ),
                },
            ],
        },
        {
            header: " ",
            columns: [
                {
                    accessorKey: "date_of_service",
                    header: "DATE OF SERVICE",
                    cell: ({ row }) => (
                        <span>
                            {formatDate(row.original.date_of_service) || "--"}
                        </span>
                    ),
                },
            ],
        },
        {
            header: " ",
            columns: [
                {
                    accessorKey: "cpt_code",
                    header: "CPT CODE",
                    cell: ({ row }) => {
                        if (
                            row.original.note_type ===
                            NoteTypes.CANCELLATION_NOTE
                        ) {
                            return "NO SHOW";
                        }

                        if (row.original.cpt_code) {
                            return (
                                <Tooltip
                                    content={
                                        CPT_CODES_DESCRIPTION[
                                            row.original.cpt_code as string
                                        ] || ""
                                    }
                                    classNames="z-20"
                                >
                                    <span
                                        style={{
                                            display: "flex",
                                            alignItems: "center",
                                            columnGap: "5px",
                                        }}
                                    >
                                        {removeEnumUnderscore(
                                            row.original.cpt_code
                                        )}
                                        <InfoIcon />
                                    </span>
                                </Tooltip>
                            );
                        }

                        return "--";
                    },
                },
            ],
        },
        {
            header: " ",
            columns: [
                {
                    accessorKey: "pos_code",
                    header: "pos code",
                    cell: ({ row }) => {
                        const regex = /^(\d+) - (.+)$/;

                        if (row.original.pos_code) {
                            return (
                                <Tooltip
                                    content={
                                        row.original.pos_code.match(
                                            regex
                                        )?.[2] || ""
                                    }
                                    classNames="z-20"
                                >
                                    <span
                                        style={{
                                            display: "flex",
                                            alignItems: "center",
                                            columnGap: "5px",
                                        }}
                                    >
                                        {row.original.pos_code.match(
                                            regex
                                        )?.[1] || ""}
                                        <InfoIcon />
                                    </span>
                                </Tooltip>
                            );
                        }
                        return "--";
                    },
                },
            ],
        },
        {
            header: " ",
            columns: [
                {
                    accessorKey: "note_type",
                    header: "NOTE",
                    cell: ({ row }) =>
                        row.original.note_type
                            ? NoteLabels[row.original.note_type as string]
                            : "--",
                },
            ],
        },
        {
            header: " ",
            columns: [
                {
                    accessorKey: "note_author",
                    header: "CLINICIAN",
                    cell: ({ row }) =>
                        row.original.note_author
                            ? displayNameInRightFormat({
                                  firstName:
                                      row.original.note_author.first_name,
                                  lastName: row.original.note_author.last_name,
                              })
                            : "--",
                },
            ],
        },
        {
            header: " ",
            columns: [
                {
                    accessorKey: "insurance_provider",
                    header: "INSURANCE PROVIDER",
                    cell: ({ row }) => (
                        <span
                            className={
                                row.original?.metadata?.self_pay
                                    ? "normal-case"
                                    : "uppercase"
                            }
                        >
                            {row.original?.metadata?.self_pay &&
                            !showInsOverSelfPay
                                ? "Self pay"
                                : (row.original.insurance_provider &&
                                      removeEnumUnderscore(
                                          row.original.insurance_provider
                                      )) ||
                                  "--"}
                        </span>
                    ),
                },
            ],
        },
        {
            header: "INSURANCE",
            columns: [
                {
                    accessorKey: "insurance_charge",
                    header: "CHARGE",
                    cell: ({ row }) =>
                        showMoneyInAppFormat(row.original.insurance_amount),
                },
                {
                    accessorKey: "insurance_amount_collected",
                    header: "COLLECTED",
                    cell: ({ row }) =>
                        showMoneyInAppFormat(
                            row.original.insurance_amount_collected
                        ),
                },
            ],
        },
        {
            header: "CO-INSURANCE",
            columns: [
                {
                    accessorKey: "coinsurance_charge",
                    header: "CHARGE",
                    cell: ({ row }) =>
                        showMoneyInAppFormat(row.original.coinsurance_amount),
                },
                {
                    accessorKey: "coinsurance_amount_collected",
                    header: "COLLECTED",
                    cell: ({ row }) =>
                        showMoneyInAppFormat(
                            row.original.coinsurance_amount_collected
                        ),
                },
            ],
        },
        {
            header: "PR100",

            columns: [
                {
                    accessorKey: "charge",
                    header: "CHARGE",
                    cell: ({ row }) =>
                        showMoneyInAppFormat(row.original.pr100_amount),
                },
                {
                    accessorKey: "collected",
                    header: "COLLECTED",
                    cell: ({ row }) =>
                        showMoneyInAppFormat(row.original.pr100_collected),
                },
            ],
        },
        {
            accessorKey: "invoice_status",
            header: "BILL STATUS",
            cell: ({ row }) => (
                <span>
                    {getBillStatusTag(
                        row.original.invoice_status as BillStatus
                    )}
                </span>
            ),
        },
        {
            accessorKey: "payment_status",
            header: "PAYMENT STATUS",
            cell: ({ row }) =>
                row.original.payment_status ? (
                    <span>
                        {getBillPaymentStatusTag(
                            row.original.payment_status as BillPaymentStatus
                        )}
                    </span>
                ) : (
                    "--"
                ),
        },
    ];
    const rowsToExpand = () => {
        const expanded: ExpandedState = {};
        data?.data?.forEach((bill, i) => {
            if (
                bill.invoice_status === BillStatus.PENDING_RESOLUTION &&
                bill.issue_category
            ) {
                expanded[i] = true;
            }
        });
        return expanded;
    };

    const rowSubComponent = ({ row }: { row: Row<IBillingResponse> }) => {
        if (!row.getIsExpanded) return null;
        return (
            <div
                className="flex text-gray font-medium text-[1rem] gap-2 pl-[5rem] my-10
            items-baseline"
            >
                <span className="text-danger capitalize">
                    {removeEnumUnderscore(
                        row.original.issue_category as string
                    )}
                </span>

                {row.original.issue_notes_preview &&
                    row.original.issue_notes_preview.length && (
                        <span>
                            -{" "}
                            {row.original.issue_notes_preview.length < 150
                                ? row.original.issue_notes_preview
                                : `${row.original.issue_notes_preview?.slice(
                                      0,
                                      200
                                  )}...`}
                        </span>
                    )}
            </div>
        );
    };

    // Get bills list count
    let invoicesCount;
    if (error) invoicesCount = "";
    else if (isLoading) invoicesCount = "";
    else if (data?.data && data?.data.length === 0) invoicesCount = 0;
    else if (data?.data && data?.data.length > 0)
        invoicesCount = data.total_count;

    return (
        <>
            {showPendingReolutionDialog && (
                <Dialog open={showPendingReolutionDialog}>
                    <DialogContent
                        title="Pending session fee"
                        handleCloseDialog={handleClosePendingResolutionDialog}
                        variant="center"
                        showFooter
                        cancelText="Cancel"
                        saveText="Mark as Pending session fee"
                        submitBtnFormValue="pending-resolution"
                        isSubmitBtnDisabled={updateInVoiceIsMutating > 0}
                        isCancelBtnDisabled={updateInVoiceIsMutating > 0}
                    >
                        <PendingResolutionForm
                            onFormSubmit={() => {
                                handleClosePendingResolutionDialog();
                                setRowSelection({});
                            }}
                            metaProps={propsForPendingResolution || undefined}
                        />
                    </DialogContent>
                </Dialog>
            )}
            <Navbar title={`Bills(${invoicesCount})`} />
            <div className="page">
                {(!error || (error && error?.response?.status === 404)) && (
                    <>
                        <div className="bg-white px-20 py-24 rounded-r8 border-strokelight mb-24">
                            <div className="max-w-[400px] mb-24">
                                <SearchInput
                                    placeholder="Search by client or chart number"
                                    value={clientFilter}
                                    onChange={handleClientFilterChange}
                                />
                            </div>

                            <div className="flex gap-x-12 flex-wrap gap-y-6">
                                <Popover
                                    open={showCliniciansListPopover}
                                    onOpenChange={setShowCliniciansListPopover}
                                >
                                    <PopoverTrigger
                                        asChild
                                        onClick={() =>
                                            setShowCliniciansListPopover(true)
                                        }
                                    >
                                        <Button
                                            size="auto"
                                            variant="normal"
                                            className={cn(
                                                "px-16 h-32 shadow-active gap-x-8 text-sm data-[state=open]:border-primary font-medium",
                                                { capitalize: selectedProvider }
                                            )}
                                        >
                                            {selectedProvider
                                                ? truncateString(
                                                      selectedProvider.label
                                                  )
                                                : "Clinician"}
                                            <ChevronDownIcon
                                                stroke={APP_COLORS.COLOR_BLACK}
                                            />
                                        </Button>
                                    </PopoverTrigger>

                                    <PopoverContent
                                        className="px-4 pt-[3px] py-[3px] w-[316px] rounded-r8 border-none shadow-dropdown z-20"
                                        align="start"
                                    >
                                        <SearchInput
                                            placeholder="Search by clinician"
                                            containerClass="h-24"
                                            onChange={(e) =>
                                                setProviderSearchString(
                                                    e.target.value
                                                )
                                            }
                                        />

                                        <div className="flex flex-col items-start max-h-[230px] overflow-y-auto">
                                            {providerList.isLoading && (
                                                <div className="flex flex-col gap-y-4 items-stretch w-full">
                                                    {[1, 2, 3, 4, 5].map(
                                                        (item) => (
                                                            <Skeleton
                                                                key={item}
                                                                width="100%"
                                                                height="24px"
                                                                containerTestId="provider-loader"
                                                            />
                                                        )
                                                    )}
                                                </div>
                                            )}

                                            {providerList.error && (
                                                <ListState
                                                    isError
                                                    context="general"
                                                    stateHelperText="Please try again later"
                                                    errorMsg={
                                                        providerList.error
                                                            ?.response?.data
                                                            ?.message ||
                                                        "Error loading providers"
                                                    }
                                                />
                                            )}

                                            {providerList.data?.data &&
                                                providerList.isSuccess && (
                                                    <>
                                                        <Button
                                                            size="auto"
                                                            variant="normal"
                                                            className={cn(
                                                                "text-xs px-[6px] py-4 font-medium gap-x-8 capitalize w-full justify-start !rounded-none hover:bg-[#f8f8f8f7]",
                                                                {
                                                                    "text-primary":
                                                                        !selectedProvider,
                                                                }
                                                            )}
                                                            onClick={() => {
                                                                setProviderSearchString(
                                                                    ""
                                                                );
                                                                setSelectedProvider(
                                                                    null
                                                                );
                                                                setShowCliniciansListPopover(
                                                                    false
                                                                );
                                                            }}
                                                        >
                                                            All
                                                            {!selectedProvider ? (
                                                                <CheckPrimaryColorIcon />
                                                            ) : null}
                                                        </Button>

                                                        {providerList.data.data.map(
                                                            (provider) => (
                                                                <Button
                                                                    key={
                                                                        provider.provider_id
                                                                    }
                                                                    size="auto"
                                                                    variant="normal"
                                                                    className={cn(
                                                                        "text-xs px-[6px] py-4 font-medium capitalize w-full justify-start !rounded-none gap-x-8 hover:bg-[#f8f8f8f7]",
                                                                        {
                                                                            "text-primary":
                                                                                selectedProvider?.value ===
                                                                                provider.provider_id,
                                                                        }
                                                                    )}
                                                                    onClick={() => {
                                                                        setSelectedProvider(
                                                                            {
                                                                                label: `${provider.provider_name}`,
                                                                                value: provider.user_id,
                                                                            }
                                                                        );
                                                                        setShowCliniciansListPopover(
                                                                            false
                                                                        );
                                                                    }}
                                                                >
                                                                    {
                                                                        provider.provider_name
                                                                    }
                                                                    {selectedProvider?.value ===
                                                                    provider.provider_id ? (
                                                                        <CheckPrimaryColorIcon />
                                                                    ) : null}
                                                                </Button>
                                                            )
                                                        )}
                                                    </>
                                                )}
                                        </div>
                                    </PopoverContent>
                                </Popover>
                                <Popover
                                    open={showPayerListPopover}
                                    onOpenChange={setShowPayerListPopover}
                                >
                                    <PopoverTrigger
                                        asChild
                                        onClick={() =>
                                            setShowPayerListPopover(true)
                                        }
                                    >
                                        <Button
                                            size="auto"
                                            variant="normal"
                                            className={cn(
                                                "px-16 h-32 shadow-active gap-x-8 text-sm data-[state=open]:border-primary font-medium",
                                                { capitalize: selectedPayer }
                                            )}
                                        >
                                            {selectedPayer
                                                ? truncateString(
                                                      selectedPayer.label
                                                  )
                                                : " Insurance Providers"}
                                            <ChevronDownIcon
                                                stroke={APP_COLORS.COLOR_BLACK}
                                            />
                                        </Button>
                                    </PopoverTrigger>

                                    <PopoverContent
                                        className="px-4 pt-[3px] py-[3px] w-[316px] rounded-r8 border-none shadow-dropdown z-20"
                                        align="start"
                                    >
                                        <SearchInput
                                            placeholder="Search by insurance name"
                                            containerClass="h-24"
                                            onChange={(e) =>
                                                setPayerSearchString(
                                                    e.target.value
                                                )
                                            }
                                        />

                                        <div className="flex flex-col items-start max-h-[230px] overflow-y-auto">
                                            {insuranceProviders.isLoading && (
                                                <div className="flex flex-col gap-y-4 items-stretch w-full">
                                                    {[1, 2, 3, 4, 5].map(
                                                        (item) => (
                                                            <Skeleton
                                                                key={item}
                                                                width="100%"
                                                                height="24px"
                                                                containerTestId="insurance-loader"
                                                            />
                                                        )
                                                    )}
                                                </div>
                                            )}

                                            {insuranceProviders.error && (
                                                <ListState
                                                    isError
                                                    context="general"
                                                    stateHelperText="Please try again later"
                                                    errorMsg="Error loading insurance"
                                                />
                                            )}

                                            {insuranceProviders.data?.data &&
                                                insuranceProviders.isSuccess && (
                                                    <>
                                                        <Button
                                                            size="auto"
                                                            variant="normal"
                                                            className={cn(
                                                                "text-xs px-[6px] py-4 font-medium capitalize w-full justify-start !rounded-none hover:bg-[#f8f8f8f7] gap-x-8",
                                                                {
                                                                    "text-primary":
                                                                        !selectedPayer,
                                                                }
                                                            )}
                                                            onClick={() => {
                                                                setPayerSearchString(
                                                                    ""
                                                                );
                                                                setSelectedPayer(
                                                                    null
                                                                );
                                                                setShowPayerListPopover(
                                                                    false
                                                                );
                                                            }}
                                                        >
                                                            All
                                                            {!selectedPayer ? (
                                                                <CheckPrimaryColorIcon />
                                                            ) : null}
                                                        </Button>

                                                        {(showInsOverSelfPay
                                                            ? [
                                                                  ...insuranceProviders
                                                                      .data
                                                                      .data,
                                                              ]
                                                            : [
                                                                  ...insuranceProviders
                                                                      .data
                                                                      .data,
                                                                  {
                                                                      name: "Self Pay",
                                                                      insurance_provider_id:
                                                                          "self_pay",
                                                                  },
                                                              ]
                                                        )
                                                            .sort((a, b) =>
                                                                a.name.localeCompare(
                                                                    b.name
                                                                )
                                                            )
                                                            .map((provider) => (
                                                                <Button
                                                                    key={
                                                                        provider.insurance_provider_id
                                                                    }
                                                                    size="auto"
                                                                    variant="normal"
                                                                    className={cn(
                                                                        "text-xs px-[6px] py-4 font-medium capitalize w-full justify-start !rounded-none hover:bg-[#f8f8f8f7] gap-x-8",
                                                                        {
                                                                            "text-primary":
                                                                                selectedPayer?.value ===
                                                                                provider.insurance_provider_id,
                                                                        }
                                                                    )}
                                                                    onClick={() => {
                                                                        setSelectedPayer(
                                                                            {
                                                                                label: `${provider.name}`,
                                                                                value: provider.insurance_provider_id,
                                                                            }
                                                                        );
                                                                        setShowPayerListPopover(
                                                                            false
                                                                        );
                                                                    }}
                                                                >
                                                                    {
                                                                        provider.name
                                                                    }

                                                                    {selectedPayer?.value ===
                                                                    provider.insurance_provider_id ? (
                                                                        <CheckPrimaryColorIcon />
                                                                    ) : null}
                                                                </Button>
                                                            ))}
                                                    </>
                                                )}
                                        </div>
                                    </PopoverContent>
                                </Popover>
                                <Dropdown>
                                    <DropdownTrigger asChild>
                                        <FilterButton text="Bill Status" />
                                    </DropdownTrigger>
                                    <DropdownContent
                                        align="end"
                                        width="auto"
                                        maxHeight={216}
                                        className="overflow-y-auto"
                                    >
                                        {Object.values(BillStatus).map(
                                            (status) => (
                                                <DropdownItem
                                                    key={status}
                                                    onClick={() =>
                                                        handleStatusChange(
                                                            status
                                                        )
                                                    }
                                                    isMulti
                                                    itemValue={status}
                                                    values={invoiceStatusFilter}
                                                >
                                                    {removeEnumUnderscore(
                                                        BillStatusLabels[status]
                                                    )}
                                                </DropdownItem>
                                            )
                                        )}
                                    </DropdownContent>
                                </Dropdown>

                                <Dropdown>
                                    <DropdownTrigger asChild>
                                        <Button
                                            size="auto"
                                            variant="normal"
                                            className="px-16 h-32 shadow-active gap-x-8 text-sm data-[state=open]:border-primary font-medium capitalize"
                                        >
                                            {cptCodeFilter || "CPT Code"}
                                            <ChevronDownIcon
                                                stroke={APP_COLORS.COLOR_BLACK}
                                            />
                                        </Button>
                                    </DropdownTrigger>

                                    <DropdownContent
                                        align="start"
                                        width="auto"
                                        maxHeight={216}
                                        className="overflow-y-auto min-w-[120px]"
                                    >
                                        <DropdownItem
                                            className={cn(
                                                "flex gap-x-8 items-center text-xs font-medium",
                                                {
                                                    "text-primary":
                                                        !cptCodeFilter,
                                                }
                                            )}
                                            onClick={() =>
                                                handleCptCodeChange("")
                                            }
                                        >
                                            All
                                            {!cptCodeFilter ? (
                                                <CheckPrimaryColorIcon />
                                            ) : null}
                                        </DropdownItem>

                                        {Object.values(
                                            CPT_CODES.map((code) => (
                                                <DropdownItem
                                                    className={cn(
                                                        "flex justify-between items-center capitalize text-xs font-medium",
                                                        {
                                                            "text-primary":
                                                                cptCodeFilter ===
                                                                code,
                                                        }
                                                    )}
                                                    key={code}
                                                    onClick={() =>
                                                        handleCptCodeChange(
                                                            code
                                                        )
                                                    }
                                                >
                                                    {removeEnumUnderscore(code)}

                                                    {cptCodeFilter === code ? (
                                                        <CheckPrimaryColorIcon />
                                                    ) : null}
                                                </DropdownItem>
                                            ))
                                        )}
                                    </DropdownContent>
                                </Dropdown>

                                <Dropdown>
                                    <DropdownTrigger asChild>
                                        <Button
                                            size="auto"
                                            variant="normal"
                                            className="px-16 h-32 shadow-active gap-x-8 text-sm data-[state=open]:border-primary font-medium capitalize"
                                        >
                                            {pendingResolutionFilter
                                                ? truncateString(
                                                      removeEnumUnderscore(
                                                          pendingResolutionFilter
                                                      )
                                                  )
                                                : " Resolution reason"}
                                            <ChevronDownIcon
                                                stroke={APP_COLORS.COLOR_BLACK}
                                            />
                                        </Button>
                                    </DropdownTrigger>

                                    <DropdownContent width="auto">
                                        <DropdownItem
                                            className={cn(
                                                "flex justify-between items-center capitalize text-xs font-medium",
                                                {
                                                    "text-primary":
                                                        !pendingResolutionFilter,
                                                }
                                            )}
                                            onClick={() =>
                                                handlePendingResolutionChange(
                                                    ""
                                                )
                                            }
                                        >
                                            All
                                            {!pendingResolutionFilter ? (
                                                <CheckPrimaryColorIcon />
                                            ) : null}
                                        </DropdownItem>

                                        {PendingResolutionOptions.map(
                                            (reason) => (
                                                <DropdownItem
                                                    key={reason}
                                                    className={cn(
                                                        "flex justify-between items-center capitalize text-xs font-medium gap-x-8",
                                                        {
                                                            "text-primary":
                                                                pendingResolutionFilter ===
                                                                reason,
                                                        }
                                                    )}
                                                    onClick={() =>
                                                        handlePendingResolutionChange(
                                                            reason
                                                        )
                                                    }
                                                >
                                                    {removeEnumUnderscore(
                                                        reason
                                                    )}
                                                    {pendingResolutionFilter ===
                                                    reason ? (
                                                        <CheckPrimaryColorIcon />
                                                    ) : null}
                                                </DropdownItem>
                                            )
                                        )}
                                    </DropdownContent>
                                </Dropdown>

                                <Popover>
                                    <PopoverTrigger asChild>
                                        <Button
                                            size="auto"
                                            variant="normal"
                                            className="px-16 h-32 shadow-active gap-x-8 text-sm data-[state=open]:border-primary font-medium self-start md:self-center"
                                        >
                                            Date
                                            <ChevronDownIcon
                                                stroke={APP_COLORS.COLOR_BLACK}
                                            />
                                        </Button>
                                    </PopoverTrigger>
                                    <PopoverContent
                                        align="end"
                                        className="p-16 gap-y-12"
                                    >
                                        <DatePicker
                                            label="From"
                                            placeholderText="MM/DD/YYYY"
                                            selected={fromDateFilterToDate}
                                            onChange={
                                                handleFromDateFilterChange
                                            }
                                            maxDate={
                                                toDateFilterToDate ||
                                                new Date(Date.now())
                                            }
                                            isClearable
                                        />
                                        <DatePicker
                                            label="To"
                                            placeholderText="MM/DD/YYYY"
                                            selected={toDateFilterToDate}
                                            onChange={handleToDateFilterChange}
                                            minDate={fromDateFilterToDate}
                                            isClearable
                                        />
                                    </PopoverContent>
                                </Popover>
                            </div>
                            <TableFiltersDisplay
                                appliedFilters={[
                                    {
                                        key: "invoice_status[]",
                                        values: invoiceStatusFilter,
                                    },
                                ]}
                                wrapperClass="mt-12"
                            />
                        </div>

                        {getTotalAmountOfTableRowsSelected(rowSelection)
                            .amount > 0 && (
                            <div className="flex mb-20 items-center gap-8">
                                <h6 className="text-base font-semibold">
                                    {
                                        getTotalAmountOfTableRowsSelected(
                                            rowSelection
                                        ).amountString
                                    }{" "}
                                    :
                                </h6>

                                {showCloseInvoiceBulkAction && (
                                    <Button
                                        onClick={() =>
                                            handleChangeStatus(
                                                BillStatus.CLOSED
                                            )
                                        }
                                        variant="secondary"
                                        disabled={patchInvoiceStatus.isLoading}
                                    >
                                        Mark as close
                                    </Button>
                                )}
                                {showOpenInvoiceBulkAction && (
                                    <Button
                                        onClick={() =>
                                            handleChangeStatus(BillStatus.OPEN)
                                        }
                                        variant="secondary"
                                        disabled={patchInvoiceStatus.isLoading}
                                    >
                                        Mark as open
                                    </Button>
                                )}
                                {showPendingResolutionInvoiceBulkAction && (
                                    <Button
                                        onClick={() =>
                                            handleShowPendingResolutionDialog()
                                        }
                                        variant="secondary"
                                        disabled={patchInvoiceStatus.isLoading}
                                    >
                                        Mark as pending session fee
                                    </Button>
                                )}
                                {showPendingInsuranceResolutionInvoiceBulkAction && (
                                    <Button
                                        onClick={() =>
                                            handleChangeStatus(
                                                BillStatus.PENDING_INSURANCE_RESOLUTION
                                            )
                                        }
                                        variant="secondary"
                                        disabled={patchInvoiceStatus.isLoading}
                                    >
                                        Mark as pending insurance resolution
                                    </Button>
                                )}
                                {showPendingPatientResolutionInvoiceBulkAction && (
                                    <Button
                                        onClick={() =>
                                            handleChangeStatus(
                                                BillStatus.PENDING_INSURANCE_RESOLUTION
                                            )
                                        }
                                        variant="secondary"
                                        disabled={patchInvoiceStatus.isLoading}
                                    >
                                        Mark as pending patient resolution
                                    </Button>
                                )}
                            </div>
                        )}
                    </>
                )}

                {error && error?.response?.status !== 404 && (
                    <ListState
                        isError
                        stateHelperText="Try reloading this page to solve this issue"
                        errorMsg={
                            error?.response?.data.message ||
                            `Cannot display invoices at this time please try again later`
                        }
                    />
                )}

                {data && isSuccess && data.data.length === 0 && (
                    <ListState
                        stateHelperText="Invoice list will appear here"
                        isError={false}
                        emptyMessage="There are no invoices yet"
                    />
                )}

                <div className="mt-24">
                    {isLoading && (
                        <Skeleton
                            type="table"
                            containerTestId="invoicelist-loader"
                            count={6}
                        />
                    )}

                    {!isLoading &&
                        isSuccess &&
                        data &&
                        data.data.length > 0 && (
                            <Table
                                columns={columns}
                                data={invoiceItems}
                                rowSelection={rowSelection}
                                setRowSelection={setRowSelection}
                                handleRowClick={handleRowClick}
                                setGetSelectedData={setGetSelectedData}
                                expandedRows={rowsToExpand()}
                                renderRowSubComponent={rowSubComponent}
                                setSortConfig={setSortConfig}
                                sortConfig={sortConfig}
                                sortableColumns={sortableColumns}
                                className={styles.billslist}
                                hasPagination
                                isHeaderFixed
                                pagination={
                                    <Pagination
                                        totalCount={data.total_count}
                                        totalPages={data.total_pages}
                                        currentPage={pageFilter}
                                        onPageChange={handlePageChange}
                                        limit={limitFilter}
                                        onLimitChange={handleLimitChange}
                                    />
                                }
                            />
                        )}
                </div>
            </div>
        </>
    );
}
