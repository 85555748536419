import axios from "axios";
import { auth } from "../firebase";

const baseURL = import.meta.env.VITE_API_BASE_URL;

// Global headers will be added here
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const headers: any = {};

const axiosInstance = axios.create({
    baseURL,
    headers,
});

axiosInstance.interceptors.request.use(
    async (config) => {
        /*  Check for practice_id and returns the current token if it will not expire in  
            the next five minutes. Otherwise, this will refresh the token and return a new one.
         */
        const practice = localStorage.getItem("practice")
            ? JSON.parse(localStorage.getItem("practice") || "{}")
            : null;

        if (practice) {
            axiosInstance.defaults.headers.common.practice_id =
                practice.practice_id;
        }

        const token = await auth.currentUser?.getIdToken();
        axiosInstance.defaults.headers.common.Authorization = `Bearer ${token}`;

        if (config && config.params) {
            Object.keys(config.params).forEach((item) => {
                // eslint-disable-next-line no-param-reassign
                config.params = {
                    ...config.params,
                    [item]:
                        typeof config.params[item] === "string"
                            ? config.params[item].replace(/,+/g, "")
                            : config.params[item],
                };
            });
        }

        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

axiosInstance.interceptors.response.use(
    function resFunction(response) {
        return response;
    },
    async function errFunction(error) {
        const originalRequest = error.config;

        if (error.response.status === 401 && !originalRequest?.retry) {
            originalRequest.retry = true;
            const token = await auth.currentUser?.getIdToken();
            axiosInstance.defaults.headers.common.Authorization = `Bearer ${token}`;
            return axiosInstance(originalRequest);
        }

        return new Promise((resolve, reject) => {
            reject(error);
        });
    }
);

export default axiosInstance;
