export default function MinusCircleIcon() {
    return (
        <svg
            width="17"
            height="17"
            viewBox="0 0 17 17"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g clipPath="url(#clip0_8472_14963)">
                <path
                    d="M12.2502 8.5026H4.25016M14.9168 8.5026C14.9168 12.1845 11.932 15.1693 8.25016 15.1693C4.56826 15.1693 1.5835 12.1845 1.5835 8.5026C1.5835 4.8207 4.56826 1.83594 8.25016 1.83594C11.932 1.83594 14.9168 4.8207 14.9168 8.5026Z"
                    stroke="#282A2E"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </g>
            <defs>
                <clipPath id="clip0_8472_14963">
                    <rect
                        width="16"
                        height="16"
                        fill="white"
                        transform="translate(0.25 0.5)"
                    />
                </clipPath>
            </defs>
        </svg>
    );
}
