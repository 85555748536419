/* eslint-disable react/no-unstable-nested-components */
import { ColumnDef } from "@tanstack/react-table";
import { format } from "date-fns";
import { Link, useSearchParams } from "react-router-dom";
import React from "react";
import {
    Button,
    DatePicker,
    Dropdown,
    DropdownContent,
    DropdownItem,
    DropdownTrigger,
    Pagination,
    Popover,
    PopoverContent,
    PopoverTrigger,
    SearchInput,
    Table,
} from "@jhool-io/fe-components";
import { AxiosError } from "axios";
import { AuditCategories, IAuditResponse } from "../../types/audit-log.types";
import {
    cn,
    convertDateFilterStringToDate,
    displayNameInRightFormat,
    formatDate,
    handleRemoveEmptyParamFromQueryParams,
    makeStringFirstLetterCapital,
    removeEnumUnderscore,
    showMoneyInAppFormat,
    truncateString,
} from "../../../../utils/helpers";
import CheckPrimaryColorIcon from "../../../../components/Icons/CheckPrimaryColor";
import { IAppCustomDates } from "../../../../utils/types";
import Skeleton from "../../../../components/Skeleton/Skeleton";
import ListState from "../../../../components/ListState/ListState";
import { useDebounce } from "../../../../hooks/helpers";
import {
    IApiResponse,
    IPaginatedApiResponse,
} from "../../../../utils/types/api-response";
import { auth } from "../../../../firebase";
import Navbar from "../../../../components/Navbar/Navbar";
import ChevronDownIcon from "../../../../components/Icons/ChevronDown";
import { APP_COLORS, LISTS_DEFAULT_LIMIT } from "../../../../utils/constants";
import { useFetchClientList } from "../../../../hooks/queries/client";
import MobileListItem from "../../../../shared-ui/MobileListItem/MobileListItem";
import { ClaimStatus } from "../../../claims/types/claims.types";
import { useAppSelector } from "../../../../hooks/useRedux";

interface IAuditLogMeta {
    clientId?: string;
    invoiceId?: string;
    noteId?: string;
    claimId?: string;
    remitId?: string;
    amount?: number;
    claimStatus?: ClaimStatus;
}

const customDates = [
    IAppCustomDates.ALL_TIME,
    IAppCustomDates.LAST_24_HRS,
    IAppCustomDates.THIS_WEEK,
    IAppCustomDates.LAST_WEEK,
    IAppCustomDates.LAST_TWO_WEEKS,
    IAppCustomDates.THIS_MONTH,
    IAppCustomDates.LAST_MONTH,
];

// function to add underline in description for the purpose of making it obvious its a link
function getAuditDescriptionToDisplay({
    initialDescription,
    meta,
    category,
}: {
    initialDescription: string;
    meta: IAuditLogMeta;
    category: AuditCategories;
}) {
    if (category === AuditCategories.PAYMENTS && meta.noteId && meta.clientId) {
        return (
            <Link
                className="text-wrap underline"
                to={`/billing-and-claims/${meta.clientId}/${meta.noteId}`}
            >{`${initialDescription} ${
                meta.amount ? showMoneyInAppFormat(meta.amount) : ""
            }`}</Link>
        );
    }

    if (
        category === AuditCategories.CLAIMS &&
        meta.noteId &&
        meta.claimId &&
        meta.clientId &&
        meta.claimStatus
    ) {
        return (
            <Link
                className="text-wrap underline"
                to={`/claims/${meta.claimId}/${meta.clientId}/${meta.noteId}?key=claims&claims_view=view&status=${meta.claimStatus}`}
            >
                {initialDescription}
            </Link>
        );
    }

    if (
        category === AuditCategories.REMITS &&
        meta.noteId &&
        meta.claimId &&
        meta.clientId &&
        meta.remitId
    ) {
        return (
            <Link
                className="text-wrap underline"
                to={`/remits/${meta.remitId}/${meta.claimId}/${meta.clientId}/${meta.noteId}`}
            >
                {initialDescription}
            </Link>
        );
    }

    if (category === AuditCategories.MEMO && meta.clientId) {
        return (
            <Link
                className="text-wrap underline"
                to={`/clients/${meta.clientId}?tab=memo`}
            >
                {initialDescription}
            </Link>
        );
    }

    return (
        <span className="text-wrap">{`${initialDescription} ${
            meta.amount ? showMoneyInAppFormat(meta.amount) : ""
        }`}</span>
    );
}

export default function AuditLog() {
    const baseURL = import.meta.env.VITE_API_BASE_URL;
    const [searchParams, setSearchParams] = useSearchParams();
    const clientNameFilter = searchParams.get("client_name") || "";
    const authorFilter = searchParams.get("author") || "";
    const fromDateFilter = React.useMemo(() => {
        return searchParams.get("from_date");
    }, [searchParams]);
    const toDateFilter = React.useMemo(() => {
        return searchParams.get("to_date");
    }, [searchParams]);
    const categoryFilter =
        searchParams.get("category") || AuditCategories.PAYMENTS;
    const customDateFilter =
        (searchParams.get("custom_date") as IAppCustomDates) || "this_month";

    const [clientSearchString, setClientSearchString] = React.useState("");
    const [showClientListPopover, setShowClientListPopover] =
        React.useState(false);
    const [isLoading, setIsLoading] = React.useState(false);
    const [isSuccess, setIsSuccess] = React.useState(false);
    const [error, setError] = React.useState<AxiosError<
        IApiResponse<IAuditResponse[]>
    > | null>();

    const { practice } = useAppSelector((state) => state.userPractice);

    const author = useDebounce(authorFilter, 500);

    const clients = useFetchClientList({ search_string: clientSearchString });

    const fromDateFilterToDate = convertDateFilterStringToDate(fromDateFilter);
    const toDateFilterToDate = convertDateFilterStringToDate(toDateFilter);

    // this is request body to send as filters also
    const payload = React.useMemo(() => {
        return {
            custom_date: customDateFilter,
            from_date: fromDateFilter,
            to_date: toDateFilter,
            author,
            category: categoryFilter === "all" ? [] : [categoryFilter],
            metadata: {
                client: clientNameFilter,
            },
        };
    }, [
        author,
        clientNameFilter,
        categoryFilter,
        customDateFilter,
        fromDateFilter,
        toDateFilter,
    ]);

    const [pageFilter, setPageFilter] = React.useState(
        Number(searchParams.get("page")) || 1
    );
    const [limitFilter, setLimitFilter] = React.useState(
        Number(searchParams.get("limit")) || LISTS_DEFAULT_LIMIT
    );
    const [data, setData] =
        React.useState<IPaginatedApiResponse<IAuditResponse[]>>();

    const canSeeDOSColumn =
        categoryFilter === AuditCategories.PAYMENTS ||
        categoryFilter === AuditCategories.REMITS ||
        categoryFilter === AuditCategories.CLAIMS;

    const canSeeClaimNumberColumn =
        categoryFilter === AuditCategories.REMITS ||
        categoryFilter === AuditCategories.CLAIMS;

    /** FILTER INPUTS ONCHANGE FUNCTIONS */
    const handleAuthorNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.value === "") searchParams.delete("author");
        else searchParams.set("author", e.target.value);
        searchParams.set("page", "1");
        setSearchParams(searchParams);
    };

    const handleClientNameChange = (value: string) => {
        if (value === "") searchParams.delete("client_name");
        else searchParams.set("client_name", value);
        searchParams.set("page", "1");
        setSearchParams(searchParams);
    };

    const handleFromDateFilterChange = (date: Date | null) => {
        if (date === null) searchParams.delete("from_date");
        else searchParams.set("from_date", format(date, "yyyy-MM-dd"));
        searchParams.set("page", "1");
        setSearchParams(searchParams);
    };

    const handleToDateFilterChange = (date: Date | null) => {
        if (date === null) searchParams.delete("to_date");
        else searchParams.set("to_date", format(date, "yyyy-MM-dd"));
        searchParams.set("page", "1");
        setSearchParams(searchParams);
    };

    const handleCustomDateChange = (value: string) => {
        if (value === "") searchParams.delete("custom_date");
        else searchParams.set("custom_date", value);
        searchParams.set("page", "1");
        setSearchParams(searchParams);
    };

    // onChange handler for page
    const handlePageChange = (page: number) => {
        searchParams.set("page", String(page));
        setSearchParams(searchParams);
        setPageFilter(page);
    };

    const handleLimitChange = (limit: number) => {
        searchParams.set("limit", String(limit));
        setSearchParams(searchParams);
        setLimitFilter(limit);
    };

    const handleCategoryChange = (value: string) => {
        if (value === "") searchParams.delete("category");
        else searchParams.set("category", value);
        searchParams.set("page", "1");
        setSearchParams(searchParams);
    };

    // Get audit log count
    let logCount;
    if (error) logCount = "";
    else if (isLoading) logCount = "";
    else if (data?.data && data?.data.length === 0) logCount = 0;
    else if (data?.data && data?.data.length > 0) logCount = data.total_count;

    const columns: ColumnDef<IAuditResponse>[] = [
        {
            accessorKey: "user_name",
            header: "User Name",
            cell: ({ row }) => (
                <span className="capitalize">
                    {row.original.author.first_name &&
                    row.original.author.last_name
                        ? displayNameInRightFormat({
                              firstName: row.original.author.first_name,
                              lastName: row.original.author.last_name,
                          })
                        : "Mantle"}
                </span>
            ),
        },
        {
            accessorKey: "client_name",
            header: "Client Name",
            cell: ({ row }) => (
                <span className="capitalize">
                    {row.original.client.first_name
                        ? displayNameInRightFormat({
                              firstName: row.original.client.first_name,
                              lastName: row.original.client.last_name,
                          })
                        : "--"}
                </span>
            ),
        },
        {
            accessorKey: "category",
            header: "Category",
            cell: ({ row }) =>
                makeStringFirstLetterCapital(
                    removeEnumUnderscore(row.original.category)
                ),
        },
        {
            accessorKey: "description",
            header: "Description",
            cell: ({ row }) =>
                getAuditDescriptionToDisplay({
                    initialDescription: row.original.description,
                    category: row.original.category,
                    meta: {
                        amount: row.original.metadata.amount,
                        claimId: row.original.metadata.claim_id,
                        remitId: row.original.metadata.remittance_id,
                        noteId: row.original.metadata.note_id,
                        clientId: row.original.metadata.client_id,
                        claimStatus: row.original.metadata.claim_status,
                    },
                }),
        },
        {
            accessorKey: "date_of_service",
            header: "Date of service",
            cell: ({ row }) =>
                row.original.date_of_service
                    ? formatDate(row.original.date_of_service)
                    : "--",
        },
        {
            accessorKey: "claim_number",
            header: "Claim number",
            cell: ({ row }) => row.original.claim_number || "--",
        },
        {
            accessorKey: "created_at",
            header: "Created at",
            cell: ({ row }) => formatDate(row.original.created_at),
        },
        {
            accessorKey: "created_at",
            header: "Time",
            cell: ({ row }) =>
                formatDate(row.original.created_at, false, "h:mm a"),
        },
    ];

    React.useEffect(() => {
        // This effect will run whenever payload, page, or limit changes
        const fetchData = async () => {
            const token = await auth.currentUser?.getIdToken();
            setIsLoading(true);
            setIsSuccess(false);
            setError(null);
            try {
                const response = await fetch(
                    `${baseURL}/audits?page=${pageFilter}&limit=${limitFilter}`,
                    {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json",
                            Authorization: `Bearer ${token}`,
                            practice_id: practice?.practice_id || "",
                        },
                        body: JSON.stringify(
                            handleRemoveEmptyParamFromQueryParams(payload)
                        ),
                    }
                );
                const result = await response.json();
                setData(result);
                setIsSuccess(true);
            } catch (err: unknown) {
                setError(err as AxiosError<IApiResponse<IAuditResponse[]>>);
            } finally {
                setIsLoading(false);
            }
            return null;
        };

        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [payload, pageFilter, limitFilter]);

    // function to handle export audit log to csv
    const handleExportAuditLog = async () => {
        const token = await auth.currentUser?.getIdToken();
        setIsLoading(true);
        setIsSuccess(false);
        setError(null);

        try {
            const response = await fetch(`${baseURL}/audits/export`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                    practice_id: practice?.practice_id || "",
                },
                body: JSON.stringify(
                    handleRemoveEmptyParamFromQueryParams(payload)
                ),
            });

            if (response) {
                const blob = await response.blob();

                const href = window.URL.createObjectURL(blob);
                const anchorElement = document.createElement("a");

                anchorElement.href = href;

                document.body.appendChild(anchorElement);
                anchorElement.click();
                document.body.removeChild(anchorElement);
                window.URL.revokeObjectURL(href);
            }

            setIsSuccess(true);
        } catch (err) {
            setError(err as AxiosError<IApiResponse<IAuditResponse[]>>);
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <>
            <Navbar title={`Audit Log(${logCount})`} />
            <div className="page">
                {(!error || (error && error?.response?.status === 404)) && (
                    <div className="mb-32 py-16 px-12 bg-white rounded-r8">
                        <div className="mb-24 flex gap-x-12 items-center">
                            <SearchInput
                                placeholder="Search by author"
                                value={authorFilter}
                                onChange={handleAuthorNameChange}
                                containerClass="w-[480px] h-32 max-w-full"
                            />
                        </div>
                        <div className="flex items-center flex-wrap gap-12">
                            <Popover
                                open={showClientListPopover}
                                onOpenChange={setShowClientListPopover}
                            >
                                <PopoverTrigger
                                    asChild
                                    onClick={() =>
                                        setShowClientListPopover(true)
                                    }
                                >
                                    <Button
                                        size="auto"
                                        variant="normal"
                                        className={cn(
                                            "px-16 h-32 shadow-active gap-x-8 text-sm data-[state=open]:border-primary font-medium",
                                            {
                                                capitalize: clientNameFilter,
                                            }
                                        )}
                                    >
                                        {clientNameFilter
                                            ? truncateString(clientNameFilter)
                                            : "Client name"}
                                        <ChevronDownIcon
                                            stroke={APP_COLORS.COLOR_BLACK}
                                        />
                                    </Button>
                                </PopoverTrigger>
                                <PopoverContent
                                    className="px-4 pt-[3px] py-[3px] w-[316px] rounded-r8 border-none shadow-[0px_0px_0px_1px_#98a1b219,_0px_15px_35px_-5px_#11182626,_0px_5px_15px_0px_#00000014]"
                                    align="start"
                                >
                                    <SearchInput
                                        placeholder="Search by client name"
                                        containerClass="h-24"
                                        onChange={(e) =>
                                            setClientSearchString(
                                                e.target.value
                                            )
                                        }
                                    />

                                    <div className="flex flex-col items-start max-h-[230px] overflow-y-auto">
                                        {clients.isLoading && (
                                            <div className="flex flex-col gap-y-4 items-stretch w-full">
                                                {[1, 2, 3, 4, 5].map((item) => (
                                                    <Skeleton
                                                        key={item}
                                                        width="100%"
                                                        height="24px"
                                                        containerTestId="client-loader"
                                                    />
                                                ))}
                                            </div>
                                        )}
                                        {clients.error && (
                                            <ListState
                                                isError
                                                context="general"
                                                stateHelperText="Please try again later"
                                                errorMsg={
                                                    clients.error?.response
                                                        ?.data?.message ||
                                                    "Error loading clients"
                                                }
                                            />
                                        )}
                                        {clients.data?.data &&
                                            clients.isSuccess && (
                                                <>
                                                    <Button
                                                        size="auto"
                                                        variant="normal"
                                                        className="text-xs px-[6px] py-4 font-medium capitalize w-full justify-start !rounded-none"
                                                        onClick={() => {
                                                            handleClientNameChange(
                                                                ""
                                                            );
                                                            setShowClientListPopover(
                                                                false
                                                            );
                                                        }}
                                                    >
                                                        All
                                                    </Button>
                                                    {clients?.data?.data.map(
                                                        (client) => (
                                                            <Button
                                                                key={
                                                                    client.client_id
                                                                }
                                                                size="auto"
                                                                variant="normal"
                                                                className="text-xs px-[6px] py-4 font-medium capitalize w-full justify-start !rounded-none"
                                                                onClick={() => {
                                                                    handleClientNameChange(
                                                                        `${client.first_name.toLowerCase()} ${client.last_name.toLowerCase()}`
                                                                    );
                                                                    setShowClientListPopover(
                                                                        false
                                                                    );
                                                                }}
                                                            >
                                                                {`${client.first_name} ${client.last_name}`}
                                                            </Button>
                                                        )
                                                    )}
                                                </>
                                            )}
                                    </div>
                                </PopoverContent>
                            </Popover>

                            <Dropdown>
                                <DropdownTrigger asChild>
                                    <Button
                                        size="auto"
                                        variant="normal"
                                        className="
                                        px-16 h-32 shadow-active gap-x-8 text-sm data-[state=open]:border-primary font-medium capitalize
                                    "
                                    >
                                        {categoryFilter
                                            ? truncateString(categoryFilter)
                                            : "Category"}
                                        <ChevronDownIcon
                                            stroke={APP_COLORS.COLOR_BLACK}
                                        />
                                    </Button>
                                </DropdownTrigger>
                                <DropdownContent width="auto">
                                    <DropdownItem
                                        className="capitalize flex gap-x-8 items-center text-xs font-medium"
                                        onClick={() =>
                                            handleCategoryChange("all")
                                        }
                                    >
                                        All
                                        {categoryFilter === "all" ? (
                                            <CheckPrimaryColorIcon />
                                        ) : null}
                                    </DropdownItem>
                                    {Object.values(AuditCategories).map(
                                        (category) => (
                                            <DropdownItem
                                                key={category}
                                                className="capitalize flex gap-x-8 items-center text-xs font-medium"
                                                onClick={() =>
                                                    handleCategoryChange(
                                                        category
                                                    )
                                                }
                                            >
                                                {removeEnumUnderscore(category)}
                                                {categoryFilter === category ? (
                                                    <CheckPrimaryColorIcon />
                                                ) : null}
                                            </DropdownItem>
                                        )
                                    )}
                                </DropdownContent>
                            </Dropdown>
                            <Dropdown>
                                <DropdownTrigger asChild>
                                    <Button
                                        size="auto"
                                        variant="normal"
                                        className="
                                        px-16 h-32 shadow-active gap-x-8 text-sm data-[state=open]:border-primary font-medium capitalize
                                    "
                                    >
                                        {customDateFilter
                                            ? truncateString(
                                                  removeEnumUnderscore(
                                                      customDateFilter
                                                  )
                                              )
                                            : "Custom date"}
                                        <ChevronDownIcon
                                            stroke={APP_COLORS.COLOR_BLACK}
                                        />
                                    </Button>
                                </DropdownTrigger>
                                <DropdownContent width="150px">
                                    {customDates.map((date) => (
                                        <DropdownItem
                                            key={date}
                                            className="capitalize flex gap-x-8 items-center text-xs font-medium"
                                            onClick={() =>
                                                handleCustomDateChange(date)
                                            }
                                        >
                                            {removeEnumUnderscore(date)}
                                            {customDateFilter === date ? (
                                                <CheckPrimaryColorIcon />
                                            ) : null}
                                        </DropdownItem>
                                    ))}
                                </DropdownContent>
                            </Dropdown>
                            <Popover>
                                <PopoverTrigger asChild>
                                    <Button
                                        size="auto"
                                        variant="normal"
                                        className="px-16 h-32 shadow-active gap-x-8 text-sm data-[state=open]:border-primary font-medium self-start md:self-center"
                                    >
                                        Date
                                        <ChevronDownIcon
                                            stroke={APP_COLORS.COLOR_BLACK}
                                        />
                                    </Button>
                                </PopoverTrigger>
                                <PopoverContent
                                    align="end"
                                    className="p-16 gap-y-12"
                                >
                                    <DatePicker
                                        label="From"
                                        placeholderText="MM/DD/YYYY"
                                        selected={fromDateFilterToDate}
                                        onChange={handleFromDateFilterChange}
                                        maxDate={
                                            toDateFilterToDate ||
                                            new Date(Date.now())
                                        }
                                        isClearable
                                    />
                                    <DatePicker
                                        label="To"
                                        placeholderText="MM/DD/YYYY"
                                        selected={toDateFilterToDate}
                                        onChange={handleToDateFilterChange}
                                        minDate={fromDateFilterToDate}
                                        isClearable
                                    />
                                </PopoverContent>
                            </Popover>
                            <div className="ml-auto">
                                <Button
                                    onClick={handleExportAuditLog}
                                    disabled={
                                        data?.data?.length === 0 || isLoading
                                    }
                                >
                                    Export
                                </Button>
                            </div>
                        </div>
                    </div>
                )}

                {error && error?.response?.status !== 404 && (
                    <ListState
                        isError
                        stateHelperText="Try reloading this page to solve this issue"
                        errorMsg={
                            error?.response?.data.message ||
                            `Cannot display audit logs at this time please try again later`
                        }
                    />
                )}
                {data && isSuccess && data?.data?.length === 0 && (
                    <ListState
                        stateHelperText="Audit log will appear here"
                        isError={false}
                        emptyMessage="There are no logs yet"
                    />
                )}
                {isLoading && (
                    <Skeleton
                        type="table"
                        containerTestId="audit-log-loader"
                        count={6}
                    />
                )}
                {!isLoading &&
                    isSuccess &&
                    data?.data &&
                    data?.data?.length > 0 && (
                        <>
                            <div className="hidden md:block">
                                <Table
                                    data={data.data}
                                    columns={columns}
                                    hasPagination={data.total_count > 20}
                                    isColumnVisible={{
                                        date_of_service: canSeeDOSColumn,
                                        claim_number: canSeeClaimNumberColumn,
                                    }}
                                    pagination={
                                        <Pagination
                                            totalCount={data?.total_count}
                                            totalPages={data?.total_pages}
                                            currentPage={pageFilter}
                                            onPageChange={handlePageChange}
                                            limit={limitFilter}
                                            onLimitChange={handleLimitChange}
                                        />
                                    }
                                />
                            </div>
                            <div className="block md:hidden" role="table">
                                <div className="flex flex-col gap-y-12">
                                    {data.data.map((log) => (
                                        <MobileListItem
                                            key={log.audit_id}
                                            topChildren={
                                                <div className="flex items-center gap-x-8 text-base font-semibold capitalize">
                                                    {`${log.author.first_name} ${log.author.last_name}`}
                                                </div>
                                            }
                                            bottomChildren={
                                                <div className="flex flex-col gap-y-8 w-full">
                                                    <div className="flex items-center w-full text-left gap-x-4">
                                                        <div className="basis-1/2">
                                                            <span className="block font-medium text-xss uppercase">
                                                                client name
                                                            </span>
                                                            <span className="block text-gray font-semibold text-xs capitalize">
                                                                {log.client
                                                                    .first_name
                                                                    ? `${log.client.first_name} ${log.client.last_name}`
                                                                    : "--"}
                                                            </span>
                                                        </div>
                                                        <div className="basis-1/2">
                                                            <span className="block font-medium text-xss uppercase">
                                                                category
                                                            </span>
                                                            <span className="block text-gray font-semibold text-xs">
                                                                {makeStringFirstLetterCapital(
                                                                    removeEnumUnderscore(
                                                                        log.category
                                                                    )
                                                                )}
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div className="flex items-center w-full text-left gap-x-4">
                                                        <div className="basis-1/2">
                                                            <span className="block font-medium text-xss uppercase">
                                                                created at
                                                            </span>
                                                            <span className="block text-gray font-semibold text-xs capitalize">
                                                                {formatDate(
                                                                    log.created_at
                                                                )}
                                                            </span>
                                                        </div>
                                                        <div className="basis-1/2">
                                                            <span className="block font-medium text-xss uppercase">
                                                                time
                                                            </span>
                                                            <span className="block text-gray font-semibold text-xs">
                                                                {formatDate(
                                                                    log.created_at,
                                                                    false,
                                                                    "h:mm a"
                                                                )}
                                                            </span>
                                                        </div>
                                                    </div>
                                                    {canSeeDOSColumn ||
                                                    canSeeClaimNumberColumn ? (
                                                        <div className="flex items-center w-full text-left gap-x-4">
                                                            {canSeeDOSColumn && (
                                                                <div className="basis-1/2">
                                                                    <span className="block font-medium text-xss uppercase">
                                                                        date of
                                                                        service
                                                                    </span>
                                                                    <span className="block text-gray font-semibold text-xs capitalize">
                                                                        {log.date_of_service
                                                                            ? formatDate(
                                                                                  log.date_of_service
                                                                              )
                                                                            : "--"}
                                                                    </span>
                                                                </div>
                                                            )}
                                                            {canSeeClaimNumberColumn && (
                                                                <div className="basis-1/2">
                                                                    <span className="block font-medium text-xss uppercase">
                                                                        claim
                                                                        number
                                                                    </span>
                                                                    <span className="block text-gray font-semibold text-xs">
                                                                        {log.claim_number ||
                                                                            "--"}
                                                                    </span>
                                                                </div>
                                                            )}
                                                        </div>
                                                    ) : null}
                                                    <div className="text-xs text-gray">
                                                        <span className="block font-medium text-xss uppercase text-black">
                                                            description
                                                        </span>
                                                        <span className="block text-gray font-semibold text-xs">
                                                            {getAuditDescriptionToDisplay(
                                                                {
                                                                    initialDescription:
                                                                        log.description,
                                                                    category:
                                                                        log.category,
                                                                    meta: {
                                                                        amount: log
                                                                            .metadata
                                                                            .amount,
                                                                        claimId:
                                                                            log
                                                                                .metadata
                                                                                .claim_id,
                                                                        remitId:
                                                                            log
                                                                                .metadata
                                                                                .remittance_id,
                                                                        noteId: log
                                                                            .metadata
                                                                            .note_id,
                                                                        clientId:
                                                                            log
                                                                                .metadata
                                                                                .client_id,
                                                                        claimStatus:
                                                                            log
                                                                                .metadata
                                                                                .claim_status,
                                                                    },
                                                                }
                                                            )}
                                                        </span>
                                                    </div>
                                                </div>
                                            }
                                        />
                                    ))}
                                </div>
                                {data.total_count > 20 && (
                                    <div className="bg-white p-12 rounded-r8 mt-16">
                                        <Pagination
                                            totalCount={data?.total_count}
                                            totalPages={data?.total_pages}
                                            currentPage={pageFilter}
                                            onPageChange={handlePageChange}
                                            limit={limitFilter}
                                            onLimitChange={handleLimitChange}
                                        />
                                    </div>
                                )}
                            </div>
                        </>
                    )}
            </div>
        </>
    );
}
