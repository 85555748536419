import SendIcon from "../../../../components/Icons/Send";
import { APP_COLORS } from "../../../../utils/constants";

interface AuthWrapperProps {
    children: React.ReactNode;
    footer: React.ReactElement;
}

export default function AuthWrapper({ children, footer }: AuthWrapperProps) {
    return (
        <>
            <div className="md:top-[40px] md:absolute hidden md:block md:right-[40px]">
                <a
                    href="https://docs.google.com/document/d/1BDxPqNJQbg4O8ZOuQ6mM4Q2yj-iUijIBXYC6-6XHjhc/edit?usp=sharing"
                    target="_blank"
                    rel="noreferrer"
                    className="text-sm font-medium flex gap-8 items-center border text-gray border-strokedark rounded-r8 py-12 px-16 h-32"
                >
                    Frequently Asked Questions <SendIcon />
                </a>
            </div>
            <a
                href="https://docs.google.com/document/d/1BDxPqNJQbg4O8ZOuQ6mM4Q2yj-iUijIBXYC6-6XHjhc/edit?usp=sharing"
                target="_blank"
                rel="noreferrer"
                className="md:hidden flex bottom-0 rounded-tl-r8 rounded-tr-r8 bg-white items-center justify-center shadow-[1px_4px_20px_0px_#0b132b14] h-40 w-[79px] right-[12px] fixed text-sm font-semibold text-primary gap-x-8"
            >
                FAQ <SendIcon stroke={APP_COLORS.COLOR_PRIMARY} />
            </a>
            <div className="mx-auto py-[64px] overflow-y-auto sm:mx-0 sm:py-0 sm:overflow-y-auto sm:flex items-center justify-center min-h-dvh sm:min-h-auto sm:h-dvh">
                <article
                    className="rounded-r12 border border-strokedark
                 bg-[#F1F1F3] shadow-[0px_16px_32px_-12px_#0e121b19] w-[488px] max-w-[95%] mx-auto"
                >
                    <div className="bg-white px-24 sm:px-48 pt-[32px] sm:pt-48 pb-40 rounded-r12">
                        <h2 className="uppercase text-primary text-xxl font-extrabold mb-24">
                            Mantle
                        </h2>
                        {children}
                    </div>
                    <footer className="pt-[10px] pb-[18px]">{footer}</footer>
                </article>
            </div>
        </>
    );
}
