import * as React from "react";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";

import {
    Dropdown,
    DropdownContent,
    DropdownItem,
    DropdownTrigger,
    Input,
    TextArea,
} from "@jhool-io/fe-components";
import { IGetClientNotes, ISessionNote } from "../../../../utils/types/notes";
import { useFetchClientNotesList } from "../../../../hooks/queries/note";

import {
    formatDate,
    makeStringFirstLetterCapital,
    removeEnumUnderscore,
} from "../../../../utils/helpers";
import LoadPreviousSessionNotificationModal from "../../LoadPreviousSessionNotificatiModal/LoadPreviousSessionNotificationModal";
import FilterButton from "../../../../shared-ui/Buttons/FilterButton/FilterButton";
import NoteFormInfoBox from "../../NoteFormInfoBox/NoteFormInfoBox";
import { IEditPauseNotificationNote } from "../../../../modules/notes/types/notes.types";
import { useEditNote } from "../../../../modules/notes/hooks/edit-note";
import EditNoteFooter from "../../../../modules/notes/components/NoteCreationFooters/EditNoteFooter/EditNoteFooter";
import { PAUSE_NOTIFICATION_NOTE_LABELS } from "../../../../utils/constants";

interface EditGeneralNoteProps {
    noteDetails: ISessionNote;
}

export default function EditPauseNotification({
    noteDetails,
}: EditGeneralNoteProps) {
    const [showPrompt, setShowPrompt] = React.useState(false);
    const [selectedNote, setSelectedNote] = React.useState(
        {} as IGetClientNotes
    );

    const clientNoteParams = {
        type: noteDetails?.type as string,
        load_previous_notes: true,
        provider_id: noteDetails?.provider?.provider_id as string,
    };

    const isSigning = React.useRef(false);

    const { data: notes } = useFetchClientNotesList(
        noteDetails?.client?.client_id as string,
        clientNoteParams
    );

    const { handleAutoSaveNote, handleEditDraftNote, handleSignNote } =
        useEditNote();

    const {
        register,
        handleSubmit,
        setValue,
        formState: { errors },
    } = useForm<IEditPauseNotificationNote>({
        resolver: yupResolver(
            yup.object({
                pause_reason: yup.string().when([], {
                    is: () => isSigning.current,
                    then: yup.string().required("This field is required"),
                    otherwise: yup.string(),
                }),
                signature: yup.string().when([], {
                    is: () => isSigning.current,
                    then: yup.string().required("This field is required"),
                    otherwise: yup.string(),
                }),
            })
        ),
        mode: "onChange",
        defaultValues: {
            pause_reason: noteDetails?.note_content?.pause_reason || "",
            signature: noteDetails?.note_content?.signature || "",
        },
        shouldFocusError: false,
    });

    const handleLoadPreviousSession = (note: IGetClientNotes) => {
        setValue("pause_reason", note.note_content.pause_reason);
        setShowPrompt(false);
    };

    return (
        <>
            <LoadPreviousSessionNotificationModal
                showPrompt={showPrompt}
                setShowPrompt={setShowPrompt}
                handleLoadPreviousSession={() =>
                    handleLoadPreviousSession(selectedNote)
                }
            />

            {notes && notes.data.length > 0 && (
                <div className="flex justify-end mb-24">
                    <Dropdown>
                        <DropdownTrigger asChild>
                            <FilterButton text="Load previous session note" />
                        </DropdownTrigger>
                        <DropdownContent width="auto" align="end">
                            {notes &&
                                notes?.data?.map((note) => (
                                    <DropdownItem
                                        key={note.note_id}
                                        onClick={() => {
                                            setShowPrompt(true);
                                            setSelectedNote(note);
                                        }}
                                    >
                                        <div>
                                            {makeStringFirstLetterCapital(
                                                removeEnumUnderscore(
                                                    note.note_type
                                                )
                                            )}{" "}
                                            - Note content Details -{" "}
                                            {formatDate(note.date_of_service)}
                                        </div>
                                    </DropdownItem>
                                ))}
                        </DropdownContent>
                    </Dropdown>
                </div>
            )}

            <form
                onSubmit={handleSubmit(handleSignNote)}
                aria-label="general update form"
                id="edit-note-form"
                className="pb-[60px] md:pb-[150px]"
            >
                <div className="mb-24">
                    <NoteFormInfoBox />
                </div>
                <TextArea
                    {...register("pause_reason")}
                    label={PAUSE_NOTIFICATION_NOTE_LABELS.pause_reason}
                    errorText={errors.pause_reason?.message}
                    onBlur={handleSubmit(handleAutoSaveNote)}
                    hasError={!!errors.pause_reason}
                    isRequired
                />

                <div className="fg-info fg-line">
                    <p>Sign note here</p>
                    <div className="fg">
                        <Input
                            {...register("signature")}
                            hasError={!!errors.signature}
                            errorText={errors.signature?.message}
                            label="Provider's Initials"
                            placeholder="Provider's Initials"
                            autoComplete="off"
                            isRequired
                        />
                    </div>
                </div>
                <div>
                    <EditNoteFooter
                        onSaveButtonClick={handleSubmit(handleEditDraftNote)}
                        noteDOS={noteDetails.date_of_service}
                        onSubmitButtonClick={() => {
                            isSigning.current = true;
                        }}
                    />
                </div>
            </form>
        </>
    );
}
