import { SvgProps } from "../../utils/types";

export default function PencilIcon({ className }: SvgProps) {
    return (
        <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={className}
        >
            <path
                d="M2 11.6416V13.6683C2 13.855 2.14667 14.0016 2.33333 14.0016H4.36C4.44667 14.0016 4.53333 13.9683 4.59333 13.9016L11.8733 6.6283L9.37333 4.1283L2.1 11.4016C2.03333 11.4683 2 11.5483 2 11.6416ZM13.8067 4.69496C13.8685 4.63329 13.9175 4.56003 13.951 4.47938C13.9844 4.39873 14.0016 4.31228 14.0016 4.22496C14.0016 4.13765 13.9844 4.0512 13.951 3.97055C13.9175 3.8899 13.8685 3.81664 13.8067 3.75496L12.2467 2.19496C12.185 2.13316 12.1117 2.08413 12.0311 2.05067C11.9504 2.01722 11.864 2 11.7767 2C11.6894 2 11.6029 2.01722 11.5223 2.05067C11.4416 2.08413 11.3683 2.13316 11.3067 2.19496L10.0867 3.41496L12.5867 5.91496L13.8067 4.69496Z"
                fill="#282A2E"
            />
        </svg>
    );
}
