import * as React from "react";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import {
    Button,
    DatePicker,
    FormCheckbox,
    Input,
    Select,
} from "@jhool-io/fe-components";
import { Controller, useForm } from "react-hook-form";
import { FormatOptionLabelMeta } from "react-select";
import { useDropzone } from "react-dropzone";
import { useNavigate } from "react-router-dom";
import {
    CPT_CODES,
    NoteLabels,
    NoteTypes,
    SessionServiceType,
} from "../../../notes/types/notes.types";
import {
    cn,
    displayNameInRightFormat,
    handleFormatDatePickerValue,
    removeEnumUnderscore,
    truncateString,
} from "../../../../utils/helpers";
import { SessionPlaceOfServiceCode } from "../../../../utils/types/session";
import { useFetchDiagnosisCodes } from "../../../../hooks/queries";
import { ModifierTypes } from "../../../../utils/types/notes";
import { CPT_CODES_DESCRIPTION } from "../../../../utils/constants";
import FolderOpenIcon from "../../../../components/Icons/FolderOpen";
import { useCreateManualClaim } from "../../hooks/claims.mutations";
import useToast from "../../../../hooks/useToast";
import { ICreateManualClaim } from "../../types/claims.types";
import TimesIcon from "../../../../components/Icons/Times";
import { useFetchProviders } from "../../../providers/hooks/providers.queries";
import { useFetchClientList } from "../../../../hooks/queries/client";
import { useDebounce } from "../../../../hooks/helpers";

type Options = {
    label: string;
    value: string;
};

interface CreateClaimProps {
    onFormSubmit: () => void;
}

const schema = yup.object().shape({
    pos_code: yup.string().required("POS code is required"),
    cpt_code: yup.string().required("CPT code is required"),
    primary_diagnosis: yup
        .array()
        .min(1, "Primary diagnosis code is required")
        .max(1, "You can only select 1 diagnosis"),
    secondary_diagnosis: yup
        .array()
        .nullable()
        .max(1, "You can only select 1 diagnosis"),
    tertiary_diagnosis: yup
        .array()
        .nullable()
        .max(1, "You can only select 1 diagnosis"),
    // modifier_codes: yup.array().min(1).required("Modifier code is required"),
    modifier_codes: yup.array().nullable(),
    provider_id: yup.string().required("Provider is required"),
    client_id: yup.string().required("Client is required"),
    date_of_service: yup.date().required("Date of service is required"),
    note_type: yup.string().required("Note type is required"),
    appointment_type: yup.string().required("Session type is required"),
    session_start_time: yup.string().required("Session start time is required"),
    session_end_time: yup
        .string()
        .required("Session end time is required")
        .test({
            name: "is-greater",
            message: "Session end time should be later than the start time",
            test: (
                value: string | undefined,
                context: {
                    parent: {
                        session_start_time: string;
                    };
                }
            ) => {
                const { session_start_time: sessionStartTime } = context.parent;
                if (!sessionStartTime || !value) {
                    return true;
                }
                const start = new Date(`1970-01-01T${sessionStartTime}:00`);
                const end = new Date(`1970-01-01T${value}:00`);
                return end > start;
            },
        }),
});

export default function CreateManualClaimForm({
    onFormSubmit,
}: CreateClaimProps) {
    const [diagnosticSearchValue, setDiagnosticSearchValue] =
        React.useState("");
    const [dateOfService, setDateOfService] = React.useState<Date | null>(null);
    const [selectedFiles, setSelectedFiles] = React.useState<File[] | null>(
        null
    );
    const [isSelfPay, setIsSelfPay] = React.useState(false);
    const [providerSearchValue, setProviderSearchValue] = React.useState("");
    const [clientSearchValue, setClientSearchValue] = React.useState("");

    const debouncedProviderSearchValue = useDebounce(providerSearchValue, 200);
    const debouncedClientSearchValue = useDebounce(clientSearchValue, 200);

    const providers = useFetchProviders(
        { search_string: debouncedProviderSearchValue },
        providerSearchValue.length > 0
    );

    const clients = useFetchClientList(
        { search_string: debouncedClientSearchValue },
        clientSearchValue.length > 0
    );

    const { data: diagnosisCodes, isLoading, error } = useFetchDiagnosisCodes();

    const createManualClaim = useCreateManualClaim();

    const { toast } = useToast();

    const {
        control,
        handleSubmit,
        formState: { errors },
        watch,
        register,
    } = useForm<ICreateManualClaim>({
        resolver: yupResolver(schema),
        mode: "onChange",
    });

    const navigate = useNavigate();
    const PlaceOfServiceOptions = [
        ...Object.values(SessionPlaceOfServiceCode).map((place) => ({
            value: place,
            label: place,
        })),
    ];

    const diagnosisCodesFromForm = watch("primary_diagnosis");

    const secondaryDiagnosisCode = watch("secondary_diagnosis");

    const tertiaryDiagnosisCode = watch("tertiary_diagnosis");

    const providerForSelectOptions = providers?.data?.data?.map((provider) => ({
        label: displayNameInRightFormat(provider.provider_name),
        value: provider.provider_id,
    }));

    const clientsForSelectOptions = clients?.data?.data?.map((client) => ({
        label: displayNameInRightFormat({
            firstName: client.first_name,
            lastName: client.last_name,
        }),
        value: client.client_id,
    }));

    const getSessionTypeSelectOptions = Object.values(SessionServiceType).map(
        (sessionType) => ({
            label: (
                <span style={{ textTransform: "capitalize" }}>
                    {removeEnumUnderscore(sessionType)}
                </span>
            ),
            value: sessionType,
        })
    );

    const getNoteTypeSelectOptions = [
        NoteTypes.FAMILY_SOAP_NOTE,
        NoteTypes.INDIVIDUAL_SOAP_NOTE,
        NoteTypes.RECORD_OF_DISCLOSURE,
        NoteTypes.TERMINATION_NOTE,
        NoteTypes.INDIVIDUAL_TREATMENT_REVIEW,
        NoteTypes.FAMILY_TREATMENT_REVIEW,
        NoteTypes.INDIVIDUAL_INTAKE_NOTE,
        NoteTypes.FAMILY_INTAKE_NOTE,
        NoteTypes.MINOR_INTAKE_NOTE,
    ]
        .sort((a, b) => a.localeCompare(b))
        .map((noteType) => ({
            label: NoteLabels[noteType],
            value: noteType,
        }));

    const DiagnosisCodesForSelect = diagnosisCodes?.data.map((diagnosis) => ({
        label: `${diagnosis.code} ${diagnosis.description}`,
        value: diagnosis.diagnosis_code_id,
    }));

    // Get filter options for diagnosis list
    const filterDiagnosisOptions = (phrases: string[]) => {
        const filteredOptions = DiagnosisCodesForSelect?.filter((option) =>
            phrases.every(
                (phrase) =>
                    option &&
                    option.label.toLowerCase().includes(phrase.toLowerCase())
            )
        );
        return filteredOptions;
    };

    const modifiersTypesForSelect: Options[] = [
        ...Object.values(ModifierTypes).map((item) => ({
            value: item.split(" ")[0],
            label: item,
        })),
    ];

    const CPTOptions = CPT_CODES.sort((a, b) => Number(a) - Number(b)).map(
        (code) => ({
            label: `${removeEnumUnderscore(code)} - ${
                CPT_CODES_DESCRIPTION[code]
            }`,
            value: code,
        })
    );

    // Function to call when file(s) are dropped in dropzone
    const onDrop = React.useCallback(
        (acceptedFiles: File[]) => {
            if (selectedFiles && selectedFiles.length > 0) {
                setSelectedFiles([...acceptedFiles, ...selectedFiles]);
            } else setSelectedFiles([...acceptedFiles]);
        },
        [selectedFiles]
    );

    // Initialize the useDropzone hook
    const { getRootProps, isDragActive } = useDropzone({
        onDrop,
        maxFiles: 1,
        accept: {
            "application/pdf": [".pdf"],
            "image/*": [".png", ".webp", ".jpeg", ".jpg", ".avif"],
        },
    });

    const formData = new FormData();

    // Function for setting selected files
    const handleSetSelectedFiles = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files) {
            if (selectedFiles && selectedFiles.length > 0) {
                setSelectedFiles([...e.target.files, ...selectedFiles]);
            } else setSelectedFiles([...e.target.files]);
        }
    };

    const handleRemoveSelectedFile = (filename: string) => {
        if (selectedFiles) {
            const filesArr = [...selectedFiles].filter(
                (file) => file.name !== filename
            );
            if (filesArr.length === 0) setSelectedFiles(null);
            else setSelectedFiles(filesArr);
        }
    };

    const convertZeroTimeFromDoubleToSingle = (timeString: string) => {
        return timeString === "00" ? "0" : timeString;
    };

    const onSubmit = (payload: ICreateManualClaim) => {
        let diagnosisToSend: { id: string; label: string }[] = [
            { id: payload.primary_diagnosis[0], label: "primary" },
        ];

        if (
            payload.secondary_diagnosis &&
            payload.secondary_diagnosis.length > 0
        ) {
            diagnosisToSend = [
                ...diagnosisToSend,
                {
                    id: payload.secondary_diagnosis[0],
                    label: "secondary",
                },
            ];
        }
        if (
            payload.tertiary_diagnosis &&
            payload.tertiary_diagnosis.length > 0
        ) {
            diagnosisToSend = [
                ...diagnosisToSend,
                {
                    id: payload.tertiary_diagnosis[0],
                    label: "tertiary",
                },
            ];
        }

        const [sessionStartHr, sesssionStartMin] =
            payload.session_start_time.split(":");
        const [sessionEndHr, sesssionEndMin] =
            payload.session_end_time.split(":");

        formData.append("provider_id", payload.provider_id);
        formData.append("client_id", payload.client_id);
        formData.append(
            "session_start_hr",
            convertZeroTimeFromDoubleToSingle(sessionStartHr)
        );
        formData.append(
            "session_start_min",
            convertZeroTimeFromDoubleToSingle(sesssionStartMin)
        );
        formData.append(
            "session_end_hr",
            convertZeroTimeFromDoubleToSingle(sessionEndHr)
        );
        formData.append(
            "session_end_min",
            convertZeroTimeFromDoubleToSingle(sesssionEndMin)
        );
        formData.append("note_type", payload.note_type);
        formData.append("appointment_type", String(payload.appointment_type));
        formData.append("cpt_code", payload.cpt_code);
        formData.append("pos_code", payload.pos_code);
        formData.append(
            "date_of_service",
            handleFormatDatePickerValue(payload.date_of_service)
        );
        if (isSelfPay) {
            formData.append("insurance_provider_id", "self_pay");
        }

        if (diagnosisToSend.length > 0) {
            for (let i = 0; i < diagnosisToSend.length; i += 1) {
                formData.append(
                    "diagnosis_codes",
                    JSON.stringify(diagnosisToSend)
                );
            }
        }
        if (payload.modifier_codes && payload.modifier_codes.length > 0) {
            for (let i = 0; i < [...payload.modifier_codes].length; i += 1) {
                formData.append(
                    "modifier_codes",
                    JSON.stringify(payload.modifier_codes)
                );
            }
        }

        if (selectedFiles && selectedFiles.length > 0) {
            for (let i = 0; i < [...selectedFiles].length; i += 1) {
                formData.append("note_file", [...selectedFiles][i]);
            }
        }

        createManualClaim.mutate(formData, {
            onSuccess: (res) => {
                toast({
                    mode: "success",
                    message:
                        res.message || "Manual claim created successfully!",
                });
                onFormSubmit();
                if (isSelfPay) {
                    navigate(
                        `/billing-and-claims/${payload.client_id}/${res.data.note_id}`
                    );
                } else {
                    navigate(
                        `/claims/${res.claim_id}/${res.data.claim.client_id}/${res.data.claim.note_id}`
                    );
                }
            },
            onError: (err) => {
                toast({
                    mode: "error",
                    message:
                        err.response?.data.message ||
                        "Could not create claim at this time.",
                });
            },
        });
    };

    return (
        <form id="create-claim" onSubmit={handleSubmit(onSubmit)}>
            <div className="fg">
                <FormCheckbox
                    label="Create as self-pay"
                    checked={isSelfPay}
                    onChange={() => setIsSelfPay(!isSelfPay)}
                />
            </div>
            <div className="fg">
                <Controller
                    name="provider_id"
                    control={control}
                    render={({ field }) => (
                        <Select
                            isSearchable
                            filterOption={() => true}
                            placeholder="Provider name"
                            aria-label="Provider name"
                            label="Provider name"
                            options={providerForSelectOptions || []}
                            onChange={(val) => {
                                field.onChange((val as Options).value);
                                setProviderSearchValue("");
                            }}
                            onInputChange={(value) => {
                                setProviderSearchValue(value);
                            }}
                            openMenuOnClick={Boolean(field.value)}
                            menuIsOpen={debouncedProviderSearchValue.length > 0}
                            noOptionsMessage={() =>
                                providers.isFetching
                                    ? "Fetching providers..."
                                    : "No options"
                            }
                            classNames={{
                                input: () => "text-sm",
                            }}
                            hasError={!!errors.provider_id}
                            errorText={errors.provider_id?.message}
                        />
                    )}
                />
            </div>
            <div className="fg">
                <Controller
                    name="client_id"
                    control={control}
                    render={({ field }) => (
                        <Select
                            isSearchable
                            filterOption={() => true}
                            placeholder="Client name"
                            aria-label="Client name"
                            label="Client name"
                            options={clientsForSelectOptions || []}
                            onChange={(val) => {
                                field.onChange((val as Options).value);
                                setClientSearchValue("");
                            }}
                            onInputChange={(value) => {
                                setClientSearchValue(value);
                            }}
                            openMenuOnClick={Boolean(field.value)}
                            menuIsOpen={debouncedClientSearchValue.length > 0}
                            noOptionsMessage={() => {
                                return clients.isFetching
                                    ? "Fetching clients..."
                                    : "No options";
                            }}
                            classNames={{
                                input: () => "text-sm",
                            }}
                            hasError={!!errors.client_id}
                            errorText={errors.client_id?.message}
                        />
                    )}
                />
            </div>
            <div className="fg">
                <Controller
                    name="date_of_service"
                    control={control}
                    render={({ field }) => (
                        <DatePicker
                            label="Date of Service"
                            selected={dateOfService}
                            onChange={(date) => {
                                field.onChange(date);
                                setDateOfService(date);
                            }}
                            hasError={!!errors.date_of_service}
                            errorText={
                                errors.date_of_service?.type === "typeError"
                                    ? "invalid date value"
                                    : errors.date_of_service?.message
                            }
                            maxDate={new Date(Date.now())}
                        />
                    )}
                />
            </div>
            <div className="fg fg-space-between two flex">
                <Input
                    {...register("session_start_time")}
                    label="Start time"
                    placeholder="Start time"
                    hasError={!!errors.session_start_time}
                    errorText={errors.session_start_time?.message}
                    type="time"
                />

                <Input
                    {...register("session_end_time")}
                    label="End time"
                    placeholder="End time"
                    hasError={!!errors.session_end_time}
                    errorText={errors.session_end_time?.message}
                    type="time"
                />
            </div>
            <div className="fg fg-space-between two flex">
                <Controller
                    name="appointment_type"
                    control={control}
                    render={({ field }) => (
                        <Select
                            label="Session type"
                            placeholder="Select session type"
                            isSearchable
                            options={getSessionTypeSelectOptions}
                            isLongListInDialog
                            onChange={(val) => {
                                field.onChange((val as Options).value);
                            }}
                            value={getSessionTypeSelectOptions.find(
                                (option) =>
                                    option.value === watch("appointment_type")
                            )}
                            hasError={!!errors.appointment_type}
                            errorText={errors.appointment_type?.message}
                        />
                    )}
                />
                <Controller
                    name="pos_code"
                    control={control}
                    render={({ field }) => (
                        <Select
                            label="POS code"
                            placeholder="Select POS code"
                            options={PlaceOfServiceOptions}
                            isSearchable
                            isLongListInDialog
                            onChange={(val) =>
                                field.onChange((val as Options).value)
                            }
                            hasError={!!errors.pos_code}
                            errorText={errors.pos_code?.message}
                            formatOptionLabel={(
                                selectData: unknown,
                                formatOptionLabelMeta: FormatOptionLabelMeta<unknown>
                            ) => {
                                return formatOptionLabelMeta.context === "menu"
                                    ? (selectData as Options).label
                                    : truncateString(
                                          (selectData as Options)
                                              .label as string,
                                          35
                                      );
                            }}
                        />
                    )}
                />
            </div>
            <div className="fg fg-space-between two flex">
                <Controller
                    name="cpt_code"
                    control={control}
                    render={({ field }) => (
                        <Select
                            label="CPT Code"
                            placeholder="CPT Code"
                            options={CPTOptions}
                            onChange={(val) =>
                                field.onChange((val as Options).value)
                            }
                            hasError={!!errors.cpt_code}
                            errorText={errors.cpt_code?.message}
                            isSearchable
                            isLongListInDialog
                            formatOptionLabel={(
                                selectData: unknown,
                                formatOptionLabelMeta: FormatOptionLabelMeta<unknown>
                            ) => {
                                return formatOptionLabelMeta.context === "menu"
                                    ? (selectData as Options).label
                                    : truncateString(
                                          (selectData as Options)
                                              .label as string,
                                          35
                                      );
                            }}
                        />
                    )}
                />
                <Controller
                    name="note_type"
                    control={control}
                    render={({ field }) => (
                        <Select
                            label="Note type"
                            placeholder="Select note type"
                            isSearchable
                            options={getNoteTypeSelectOptions}
                            isLongListInDialog
                            onChange={(val) => {
                                field.onChange((val as Options).value);
                            }}
                            hasError={!!errors.note_type}
                            errorText={errors.note_type?.message}
                        />
                    )}
                />
            </div>
            <div className="fg">
                <Controller
                    name="primary_diagnosis"
                    control={control}
                    render={({ field }) => (
                        <Select
                            label="Primary diagnosis"
                            placeholder="Primary diagnosis"
                            options={filterDiagnosisOptions(
                                diagnosticSearchValue.split(" ")
                            )}
                            isDisabled={isLoading || Boolean(error)}
                            isSearchable
                            isLongListInDialog
                            onChange={(val) => {
                                field.onChange(
                                    (
                                        val as {
                                            label: string;
                                            value: string;
                                        }[]
                                    ).map((code) => code.value)
                                );
                            }}
                            hasError={!!errors.primary_diagnosis}
                            errorText={errors.primary_diagnosis?.message}
                            onInputChange={(value) =>
                                setDiagnosticSearchValue(value)
                            }
                            isMulti
                            multiHasValues={
                                diagnosisCodesFromForm &&
                                diagnosisCodesFromForm.length > 0
                            }
                            closeMenuOnSelect
                        />
                    )}
                />
            </div>
            <div className="fg">
                <Controller
                    name="secondary_diagnosis"
                    control={control}
                    render={({ field }) => (
                        <Select
                            label="Secondary diagnosis"
                            placeholder="Secondary diagnosis"
                            options={filterDiagnosisOptions(
                                diagnosticSearchValue.split(" ")
                            )}
                            isDisabled={isLoading || Boolean(error)}
                            isSearchable
                            isLongListInDialog
                            onChange={(val) => {
                                field.onChange(
                                    (
                                        val as {
                                            label: string;
                                            value: string;
                                        }[]
                                    ).map((code) => code.value)
                                );
                            }}
                            onInputChange={(value) =>
                                setDiagnosticSearchValue(value)
                            }
                            hasError={!!errors.secondary_diagnosis}
                            errorText={errors.secondary_diagnosis?.message}
                            isMulti
                            multiHasValues={
                                secondaryDiagnosisCode &&
                                secondaryDiagnosisCode.length > 0
                            }
                            closeMenuOnSelect
                        />
                    )}
                />
            </div>
            <div className="fg">
                <Controller
                    name="tertiary_diagnosis"
                    control={control}
                    render={({ field }) => (
                        <Select
                            label="Tertiary diagnosis"
                            placeholder="Tertiary diagnosis"
                            options={filterDiagnosisOptions(
                                diagnosticSearchValue.split(" ")
                            )}
                            isDisabled={isLoading || Boolean(error)}
                            isSearchable
                            isLongListInDialog
                            onChange={(val) => {
                                field.onChange(
                                    (
                                        val as {
                                            label: string;
                                            value: string;
                                        }[]
                                    ).map((code) => code.value)
                                );
                            }}
                            onInputChange={(value) =>
                                setDiagnosticSearchValue(value)
                            }
                            hasError={!!errors.tertiary_diagnosis}
                            errorText={errors.tertiary_diagnosis?.message}
                            isMulti
                            multiHasValues={
                                tertiaryDiagnosisCode &&
                                tertiaryDiagnosisCode.length > 0
                            }
                            closeMenuOnSelect
                        />
                    )}
                />
            </div>
            <div className="fg">
                <Controller
                    name="modifier_codes"
                    control={control}
                    render={({ field }) => (
                        <Select
                            label="Modifier code"
                            placeholder="Modifier code"
                            options={modifiersTypesForSelect}
                            isSearchable
                            isLongListInDialog
                            isMulti
                            onChange={(val) => {
                                field.onChange(
                                    (
                                        val as {
                                            label: string;
                                            value: string;
                                        }[]
                                    ).map((code) => code.value)
                                );
                            }}
                            hasError={!!errors.modifier_codes}
                            errorText={
                                errors?.modifier_codes?.type === "min"
                                    ? "Modifier codes are required"
                                    : errors?.diagnosis_codes?.message
                            }
                            multiHasValues={Boolean(watch("modifier_codes"))}
                        />
                    )}
                />
            </div>
            <div
                className={cn(
                    "flex w-full items-center gap-x-[13px] pt-[31px] pr-[92px] pb-[30px] pl-24 border border-dashed border-primary rounded-[16px]",
                    {
                        "opacity-20": selectedFiles?.length === 1,
                        "border-[2px] border-dashed border-primary-dark":
                            isDragActive,
                    }
                )}
                {...getRootProps()}
            >
                <div className="px-[17px] py-16 border border-[#D3F7FA]  bg-primary-50 rounded-[9.667px]">
                    <FolderOpenIcon />
                </div>
                <div className="flex flex-col">
                    <div className="text-sm font-medium">
                        <label
                            htmlFor="file-upload"
                            className="font-semibold text-primary"
                        >
                            Click to upload session note{" "}
                            <input
                                className="w-full absolute left-[-99999px]"
                                id="file-upload"
                                name="file-upload"
                                type="file"
                                onChange={handleSetSelectedFiles}
                                disabled={selectedFiles?.length === 1}
                                max="1"
                            />
                        </label>
                        or drag your file here
                    </div>
                    <p className="text-xs font-normal">
                        Maximum file size of 50 MB: .png, .jpg, .jpeg
                    </p>
                </div>
            </div>

            {createManualClaim.uploadProgress > 0 && (
                <div className="mt-5 h-4 bg-[rgba(185_186_163_0.4)]">
                    <div
                        role="progressbar"
                        aria-label="file upload progress value"
                        aria-valuenow={createManualClaim.uploadProgress}
                        style={{
                            width: `${createManualClaim.uploadProgress}%`,
                        }}
                        className="h-full rounded-r8 bg-primary"
                    />
                </div>
            )}

            <div className="mt-16">
                {selectedFiles &&
                    selectedFiles.map((file, i) => (
                        <div
                            key={`${file.name} - ${i + 1}`}
                            className="flex items-center justify-between w-full gap-x-16 mt-24"
                        >
                            <div className=" border border-strokedark rounded-r6 w-full px-10 py-[6px] font-normal text-xs ">
                                {file.name}
                            </div>
                            <Button
                                variant="normal"
                                aria-label="delete icon"
                                className="flex bg-card-bg !rounded-full p-8"
                                onClick={() =>
                                    handleRemoveSelectedFile(file.name)
                                }
                            >
                                <TimesIcon stroke="#8E8E8E" />
                            </Button>
                        </div>
                    ))}
            </div>
        </form>
    );
}
