import * as React from "react";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
import { DatePicker, Input, Select, TextArea } from "@jhool-io/fe-components";
import { useQueryClient } from "@tanstack/react-query";
import { useParams } from "react-router-dom";

import { FormatOptionLabelMeta } from "react-select";
import useToast from "../../../../hooks/useToast";
import {
    ISelectedPayment,
    IUpdatePayment,
    WriteOffCodes,
    WriteOffCodesDecription,
} from "../../../../utils/types/billing";
import {
    formatDate,
    handleFormatDatePickerValue,
    handleRemoveEmptyParamFromQueryParams,
    truncateString,
} from "../../../../utils/helpers";
import {
    RemarkCodeDescription,
    RemarkCodes,
} from "../../../billing/types/billing.types";
import { usePostPayments } from "../../hooks/remits.mutations";
import { useUpdatePayment } from "../../../../hooks/mutations/billing";

type Options = {
    label: React.ReactNode;
    value: string | undefined;
};

interface UpdatePaymentFormProps {
    /** function to call when the form's submit button is clicked */
    onFormSubmit(): void;
    /** the selected payment */
    selectedPayment: ISelectedPayment;
}

const writeOffCodesForSelect: Options[] = [
    ...Object.values(WriteOffCodes).map((item) => ({
        value: item,
        label: WriteOffCodesDecription[item],
    })),
];

const remarkCodeForSelect: Options[] = Object.values(RemarkCodes)
    .map((item) => ({
        value: item, // Use the enum value as the option value
        label: RemarkCodeDescription[
            item as keyof typeof RemarkCodeDescription
        ],
        // Use this for the description
    }))
    .sort((a, b) => a.value.localeCompare(b.value));

export default function UpdatePaymentForm({
    onFormSubmit,
    selectedPayment,
}: UpdatePaymentFormProps) {
    const { toast } = useToast();
    const { noteId } = useParams();
    const queryClient = useQueryClient();

    const {
        register,
        control,
        handleSubmit,
        watch,
        formState: { errors },
    } = useForm<IUpdatePayment>({
        resolver: yupResolver(
            yup.object().shape({
                amount: yup.number().min(0),
                payment_date: yup.string(),
                writeoff_code: yup.string(),
                payment_notes: yup.string(),
            })
        ),
        mode: "onChange",
        defaultValues: {
            amount: selectedPayment.amount,
            payment_date: formatDate(selectedPayment.payment_date, true),
            writeoff_code: writeOffCodesForSelect.find(
                (code) => code.value === selectedPayment.writeoff_code
            )?.value,
            payment_notes: selectedPayment.payment_notes || "",
            remark_codes: selectedPayment?.remark_codes || [],
        },
    });

    const selectedDate = watch("payment_date");
    const selectedWriteoffCode = watch("writeoff_code");
    const remarkCodes = watch("remark_codes");

    const updatePayment = useUpdatePayment();

    const postPayments = usePostPayments();

    const onSubmit = (data: IUpdatePayment) => {
        const payload = {
            ...data,
            amount:
                selectedPayment.payment_type === "wbci" ||
                selectedPayment.payment_type === "wbi"
                    ? data.amount
                    : undefined,
            payment_id: selectedPayment.payment_id,
            payment_date: handleFormatDatePickerValue(selectedDate),
            payment_notes: data.payment_notes || undefined,
            remark_code: data.remark_codes || undefined,
        };

        updatePayment.mutate(payload, {
            onSuccess: async () => {
                toast({
                    mode: "success",
                    message: "Payment updated successfully.",
                });
                if (selectedPayment.payment_status === "pending") {
                    await new Promise<void>((resolve, reject) => {
                        postPayments.mutate(
                            { payment_ids: [payload.payment_id] },
                            {
                                onSuccess: () => {
                                    toast({
                                        mode: "success",
                                        message:
                                            "Payment updated and confirmed successfully.",
                                    });
                                    resolve();
                                },
                                onError: (error) => {
                                    toast({
                                        mode: "error",
                                        message:
                                            "Could not confirm payment at this time",
                                    });
                                    reject(error);
                                },
                            }
                        );
                    });
                }

                queryClient.invalidateQueries({
                    queryKey: ["get-payment"],
                });

                queryClient.invalidateQueries({
                    queryKey: [
                        "remit-claims",
                        handleRemoveEmptyParamFromQueryParams({
                            note_id: noteId,
                        }),
                    ],
                });

                onFormSubmit();
            },

            onError: (err) => {
                toast({
                    mode: "error",
                    message:
                        err.response?.data.message ||
                        "Error updating payment please try again later",
                });
            },
        });
    };

    return (
        <form
            id="update-payment"
            onSubmit={handleSubmit(onSubmit)}
            className="flex flex-col gap-32"
        >
            <Input
                disabled
                label="Date of Service"
                value={
                    selectedPayment.date_posted
                        ? formatDate(selectedPayment.date_posted)
                        : ""
                }
            />

            <Input
                label="Payment Type"
                disabled
                value={
                    selectedPayment.payment_type === "wbi" ||
                    selectedPayment.payment_type === "i" ||
                    selectedPayment.payment_type === "ri"
                        ? "Insurance"
                        : "Co-insurance"
                }
            />

            <Input
                {...register("amount")}
                label="Payment amount($)"
                disabled={
                    selectedPayment.payment_type !== "wbi" &&
                    selectedPayment.payment_type !== "wbci"
                }
                placeholder="Confirm payment amount($)"
                hasError={!!errors.amount}
                errorText={
                    errors.amount?.type === "typeError"
                        ? "This field must be a number"
                        : errors.amount?.message
                }
                showCurrency
            />

            <Controller
                name="payment_date"
                control={control}
                render={({ field }) => (
                    <DatePicker
                        label="Payment Date"
                        onChange={(date) => field.onChange(date)}
                        selected={
                            selectedDate ? new Date(selectedDate) : new Date()
                        }
                        hasError={!!errors.payment_date}
                        errorText={
                            errors.payment_date?.type === "typeError"
                                ? "Invalid date value"
                                : errors.payment_date?.message
                        }
                    />
                )}
            />

            {(selectedPayment.payment_type === "wbi" ||
                selectedPayment.payment_type === "wbci") && (
                <Controller
                    name="writeoff_code"
                    control={control}
                    render={({ field }) => (
                        <Select
                            label="Writeoff Code"
                            placeholder="Write-Off Code"
                            wrapperClass="select"
                            options={writeOffCodesForSelect}
                            onChange={(val) =>
                                field.onChange((val as Options).value)
                            }
                            value={{
                                label: WriteOffCodesDecription[
                                    selectedWriteoffCode as WriteOffCodes
                                ],
                                value: selectedWriteoffCode,
                            }}
                            hasError={!!errors.writeoff_code}
                            errorText={errors.writeoff_code?.message}
                            isSearchable
                        />
                    )}
                />
            )}

            <Controller
                name="remark_codes"
                control={control}
                render={({ field }) => (
                    <Select
                        label="Remark Codes"
                        placeholder="Remark Codes"
                        options={remarkCodeForSelect}
                        onChange={(val) => {
                            field.onChange(
                                (val as Options[]).map((code) => code.value)
                            );
                        }}
                        isSearchable
                        formatOptionLabel={(
                            data: unknown,
                            formatOptionLabelMeta: FormatOptionLabelMeta<unknown>
                        ) => {
                            return formatOptionLabelMeta.context === "menu"
                                ? (data as Options).label
                                : truncateString(
                                      (data as Options).label as string,
                                      60
                                  );
                        }}
                        defaultValue={remarkCodeForSelect.filter((code) =>
                            field.value?.includes(code.value || "")
                        )}
                        isLongListInDialog
                        isMulti
                        multiHasValues={remarkCodes && remarkCodes.length > 0}
                    />
                )}
            />

            <div className="fg">
                <TextArea
                    {...register("payment_notes")}
                    label="Notes"
                    placeholder="Notes"
                    hasError={!!errors.payment_notes}
                    errorText={errors.payment_notes?.message}
                />
            </div>
        </form>
    );
}
