import { FormCheckbox, TextArea } from "@jhool-io/fe-components";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useQueryClient } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";

import {
    ICancelSession,
    ICancelSessionForm,
} from "../../../../types/schedule.types";
import useToast from "../../../../../../hooks/useToast";
import { useCancelSession } from "../../../../../tasks/hooks/tasks.mutations";
import { useFetchUserDetails } from "../../../../../../hooks/queries/user";
import { useAppSelector } from "../../../../../../hooks/useRedux";

interface CancelSessionProps {
    onFormSubmit: () => void;
    shouldNavigate: boolean;
    sessionHistoryId: string | null;
    clientId: string;
    viewFrom: "caseload" | "todos" | "calendar";
    externalSessionId?: string | null;
    intakeClientId?: string | null;
    action: "cancel" | "terminate";
}

export default function CancelSessionForm({
    onFormSubmit,
    shouldNavigate,
    sessionHistoryId,
    clientId,
    viewFrom,
    externalSessionId,
    intakeClientId,
    action,
}: CancelSessionProps) {
    const intakeBaseURL = import.meta.env.VITE_INTAKE_BASE_URL;
    const token = import.meta.env.VITE_INTAKE_UPDATE_TOKEN;

    // Query client
    const queryClient = useQueryClient();
    const cancelSession = useCancelSession();
    const { toast } = useToast();
    const loggedInUser = useFetchUserDetails();
    const { practice } = useAppSelector((state) => state.userPractice);
    const navigate = useNavigate();

    const {
        register,
        watch,
        handleSubmit,
        formState: { errors },
    } = useForm<ICancelSessionForm>({
        mode: "onChange",
        resolver: yupResolver(
            yup.object().shape({
                notes: yup
                    .string()
                    .required(
                        "Please explain the reason for termination in sufficient detail"
                    )
                    .min(
                        50,
                        "Reason for termination must be at least 50 characters"
                    ),
                termination_message_requested: yup.bool(),
                reason_for_termination_msg_skip: yup
                    .string()
                    .when(
                        "termination_message_requested",
                        (termination_message_requested, field) =>
                            termination_message_requested === false
                                ? field.required(
                                      "Provide a reason why you want to skip notification"
                                  )
                                : field
                    ),
            })
        ),
    });

    const queryRefreshKey = () => {
        if (viewFrom === "todos") {
            return { queryKey: [`todos`] };
        }
        if (viewFrom === "calendar") {
            return {
                queryKey: ["calendar", practice?.provider_id || ""],
            };
        }
        if (viewFrom === "caseload") {
            return {
                queryKey: [practice?.provider_id, `caseload`],
            };
        }
        return {
            queryKey: ["calendar", clientId || ""],
        };
    };

    const isMessageRequested = watch("termination_message_requested");

    const handleCancelSession = async (data: ICancelSessionForm) => {
        if (!sessionHistoryId) {
            toast({
                mode: "error",
                message: "Could not cancel session at this time",
            });
        } else {
            const payload = {
                session_history_ids: [sessionHistoryId],
                reason: action === "terminate" ? "termination" : "cancelled",
            };
            const payloadToSend: ICancelSession = {
                ...data,
                reason: "Termination",
                caller_role: "admin",
                updated_by: loggedInUser?.data?.user_email as string,
            };
            if (action === "terminate") {
                await fetch(
                    `${intakeBaseURL}/client/${intakeClientId}/appointments/${externalSessionId}`,
                    {
                        method: "DELETE",
                        headers: {
                            "Content-Type": "application/json",
                            "X-App-Authorization-Token": `${token}`,
                        },
                        body: JSON.stringify(payloadToSend),
                    }
                );
            }
            cancelSession.mutate(payload, {
                onSuccess: async () => {
                    queryClient.invalidateQueries(queryRefreshKey());
                    toast({
                        mode: "success",
                        message: `${
                            action === "terminate"
                                ? "Session terminated successfully!"
                                : "Session cancelled successfully!"
                        }`,
                    });
                    if (shouldNavigate) {
                        navigate(`/add-note?note_type=cancellation_note`);
                    }
                    if (shouldNavigate && action === "terminate") {
                        navigate(
                            `/add-note?note_type=termination_note&session_history_id=${sessionHistoryId}`
                        );
                    }
                    onFormSubmit();
                },
                onError: () => {
                    toast({
                        mode: "error",
                        message: "Could not cancel session",
                    });
                },
            });
        }
    };

    return (
        <form
            id="cancel_client_session"
            name="cancel_client_session"
            aria-label="cancel_client_session"
            onSubmit={handleSubmit(handleCancelSession)}
        >
            <div className="pb-24">
                {action === "cancel" && (
                    <h3 className="font-medium text-base text-xl mb-8">
                        Report session as Cancelled
                    </h3>
                )}
                {action === "terminate" ? (
                    <div className="text-gray font-normal text-sm">
                        <p>Terminating this client is permanent.</p>
                    </div>
                ) : (
                    <p className="text-gray font-normal text-sm">
                        Did the client cancel the session at least 24 hours
                        before the start time
                    </p>
                )}
            </div>
            {action === "terminate" && (
                <>
                    <div className="fg">
                        <TextArea
                            {...register("notes")}
                            label="Reasons for Termination"
                            placeholder="Please explain the reason for termination in sufficient detail"
                            hasError={!!errors?.notes}
                            errorText={errors?.notes?.message}
                        />
                    </div>
                    <div className="fg">
                        <FormCheckbox
                            {...register("termination_message_requested")}
                            label="Notify the client of the termination"
                            defaultChecked
                            labelClass="text-sm font-normal"
                        />
                    </div>
                </>
            )}

            {action === "terminate" && isMessageRequested === false && (
                <div className="fg">
                    <TextArea
                        {...register("reason_for_termination_msg_skip")}
                        label="Provide a reason for not notifying the client"
                        placeholder="Enter a reason"
                        hasError={!!errors?.reason_for_termination_msg_skip}
                        errorText={
                            errors?.reason_for_termination_msg_skip?.message
                        }
                    />
                </div>
            )}
        </form>
    );
}
