export default function DeletePaymentIcon() {
    return (
        <svg
            width="14"
            height="14"
            viewBox="0 0 14 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M5 1H9M1 3H13M5.66667 9.66667V6.33333M8.33333 9.66667V6.33333M2.33333 3H11.6667L11.083 11.7553C11.0363 12.4557 10.4545 13 9.7526 13H4.23073C3.52781 13 2.94561 12.4543 2.90019 11.7528L2.33333 3Z"
                stroke="#0B132B"
                strokeWidth="1.2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}
