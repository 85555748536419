import * as React from "react";
import { Button, Card } from "@jhool-io/fe-components";
import {
    IClaimAdjustments,
    IInsurancePayments,
} from "../../types/remits.types";
import PlusIcon from "../../../../components/Icons/Plus";
import AddRemitInsurancePaymentForm from "../AddRemitInsurancePaymentForm/AddRemitInsurancePaymentForm";
import InsurancePaymentItem from "./InsurancePaymentItem/InsurancePaymentItem";

interface InsurancePaymentsProps {
    insuransePayments: IInsurancePayments[];
    invoiceId: string;
    remitClaimAdjustments: IClaimAdjustments[];
    remittanceId: string;
}

export default function InsurancePayments({
    insuransePayments,
    invoiceId,
    remitClaimAdjustments,
    remittanceId,
}: InsurancePaymentsProps) {
    const [isAddingRow, setIsAddingRow] = React.useState(false);

    return (
        <Card classNames="p-24 mt-32 ">
            <div className="flex justify-between items-center mb-24">
                <h3 className="text-sm font-bold">Insurance payments</h3>
            </div>
            <div className="mt-24 rounded-r8 border border-strokedark w-full !overflow-x-auto">
                <div className="table w-full">
                    <div className="p-12 border-b border-b-strokedark grid gap-x-12 grid-cols-[100px_150px_300px_100px_120px_260px_150px_100px_100px_200px] justify-stretch text-left text-xs uppercase font-semibold text-gray items-center">
                        <span className="bg-blue-300 w-[100px]" />
                        <span>payment date</span>
                        <span>payment type</span>
                        <span>amount</span>
                        <span>payment status</span>
                        <span>remark codes</span>
                        <span>eft</span>
                        <span>date posted</span>
                        <span>posted by</span>
                        <span>payment notes</span>
                    </div>
                    {insuransePayments.map((item) => (
                        <InsurancePaymentItem
                            key={item.payment_id}
                            payment={item}
                            invoiceId={invoiceId}
                            remitClaimAdjustments={remitClaimAdjustments}
                            remittanceId={remittanceId}
                        />
                    ))}
                </div>

                <div className="p-12">
                    {!isAddingRow ? (
                        <div className="flex justify-center">
                            <Button
                                size="auto"
                                variant="normal"
                                className="font-normal text-sm flex gap-x-12 items-center"
                                onClick={() => setIsAddingRow(true)}
                            >
                                <PlusIcon stroke="#0B132B" />
                                Add insurance payment
                            </Button>
                        </div>
                    ) : (
                        <AddRemitInsurancePaymentForm
                            invoiceId={invoiceId}
                            onCancelClick={() => setIsAddingRow(false)}
                            onFormSubmit={() => setIsAddingRow(false)}
                        />
                    )}
                </div>
            </div>
        </Card>
    );
}
