export default function CheckCircleIcon() {
    return (
        <svg
            width="21"
            height="20"
            viewBox="0 0 21 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="size-16"
        >
            <path
                d="M14.1754 8.73745C14.5827 8.36444 14.6105 7.73188 14.2375 7.3246C13.8644 6.91732 13.2319 6.88954 12.8246 7.26255L9.13252 10.644L8.1754 9.76739C7.76812 9.39437 7.13557 9.42215 6.76255 9.82943C6.38954 10.2367 6.41732 10.8693 6.8246 11.2423L8.45712 12.7374C8.83934 13.0875 9.42569 13.0875 9.80792 12.7374L14.1754 8.73745Z"
                fill="#282A2E"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M10.5 0C4.97715 0 0.5 4.47715 0.5 10C0.5 15.5228 4.97715 20 10.5 20C16.0228 20 20.5 15.5228 20.5 10C20.5 4.47715 16.0228 0 10.5 0ZM2.5 10C2.5 5.58172 6.08172 2 10.5 2C14.9183 2 18.5 5.58172 18.5 10C18.5 14.4183 14.9183 18 10.5 18C6.08172 18 2.5 14.4183 2.5 10Z"
                fill="#282A2E"
            />
        </svg>
    );
}
