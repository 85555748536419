import * as React from "react";
import { ColumnDef, Row } from "@tanstack/react-table";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import {
    Card,
    Dropdown,
    DropdownContent,
    DropdownItem,
    DropdownTrigger,
    Table,
} from "@jhool-io/fe-components";
import {
    cn,
    convertTimeInSecondsToOtherTimeUnits,
    displayNameInRightFormat,
    formatDate,
    makeStringFirstLetterCapital,
    removeEnumUnderscore,
    truncateString,
} from "../../../../utils/helpers";
import { INoteAuditDashboardList, NoteStatus } from "../../types/notes.types";
import { getNoteStatusTag } from "../../helpers/notes.helpers";
import { useFetchNoteAuditDashboardList } from "../../hooks/notes.queries";
import { IAppCustomDates } from "../../../../utils/types";
import Skeleton from "../../../../components/Skeleton/Skeleton";
import FilterButton from "../../../../shared-ui/Buttons/FilterButton/FilterButton";
import CheckPrimaryColorIcon from "../../../../components/Icons/CheckPrimaryColor";
import ListState from "../../../../components/ListState/ListState";

const columns: ColumnDef<INoteAuditDashboardList>[] = [
    {
        accessorKey: "overdue_period",
        header: "OVERDUE PERIOD",
        cell: ({ row }) =>
            convertTimeInSecondsToOtherTimeUnits(
                row.original.overdue_period_secs
            ) || 0,
    },
    {
        accessorKey: "date_of_service",
        header: "DATE OF SERVICE",
        cell: ({ row }) => formatDate(row.original.date_of_service),
    },
    {
        accessorKey: "provider_name",
        header: "Provider",
        cell: ({ row }) =>
            row.original.provider && row.original.provider?.id ? (
                <Link
                    to={`/providers/${row.original.provider.id}?tab=Provider caseload`}
                >
                    {displayNameInRightFormat({
                        firstName: row.original.provider.first_name,
                        lastName: row.original.provider.last_name,
                    })}
                </Link>
            ) : (
                "--"
            ),
    },
    {
        accessorKey: "note_status",
        header: "Note status",
        cell: ({ row }) => getNoteStatusTag(row.original.note_status),
    },
    {
        accessorKey: "supervisor_name",
        header: "Supervisor",
        cell: ({ row }) =>
            row.original.supervisor && row.original.supervisor?.id
                ? displayNameInRightFormat({
                      firstName: row.original.supervisor.first_name,
                      lastName: row.original.supervisor.last_name,
                  })
                : "--",
    },
    {
        accessorKey: "client_name",
        header: "Client",
        cell: ({ row }) =>
            row.original.client && row.original.client?.id ? (
                <Link to={`/clients/${row.original.client.id}`}>
                    {displayNameInRightFormat({
                        firstName: row.original.client.first_name,
                        lastName: row.original.client.last_name,
                    })}
                </Link>
            ) : (
                "--"
            ),
    },
    {
        accessorKey: "author",
        header: "CREATED BY",
        cell: ({ row }) => (
            <span className="capitalize">
                {displayNameInRightFormat({
                    firstName: row.original.author.first_name,
                    lastName: row.original.author.last_name,
                })}
            </span>
        ),
    },
    {
        accessorKey: "session_service",
        header: "Service type",
        cell: ({ row }) =>
            makeStringFirstLetterCapital(
                removeEnumUnderscore(row.original.session_service)
            ),
    },
];

export default function NoteAuditDashboard() {
    const [searchParams, setSearchParams] = useSearchParams();

    const dateFilter = searchParams.get("date") || "this_month";

    const [sortConfig, setSortConfig] = React.useState<{
        key: string;
        direction: string;
    }>({
        key: "",
        direction: "",
    });

    const sortableColumns = [
        "date_of_service",
        "client_name",
        "provider_name",
        "overdue_period",
        "supervisor_name",
    ];

    const handleConfigAttribute = () => {
        if (sortableColumns.includes(sortConfig.key)) {
            return sortConfig.key as string;
        }

        return undefined;
    };

    const navigate = useNavigate();

    const { data, error, isLoading, isSuccess } =
        useFetchNoteAuditDashboardList(
            dateFilter,
            searchParams.get("sort_attr") || handleConfigAttribute(),
            searchParams.get("sort_order") || sortConfig.direction
        );

    const getErrorAndLoadingStates = () => {
        if (error) {
            return "0";
        }

        if (isLoading) {
            return (
                <Skeleton
                    containerTestId="audit-overview-loader"
                    height={20}
                    width={20}
                />
            );
        }

        return "";
    };

    const handleRowClick = (row: Row<INoteAuditDashboardList>) => {
        if (row.original.note_status !== NoteStatus.DRAFT) {
            navigate(
                `/notes/${row.original.client.id}/${row.original.note_id}`
            );
        }
    };

    const handleRowStyle = (row: Row<INoteAuditDashboardList>) => {
        if (row.original.note_status === NoteStatus.DRAFT) {
            return {
                cursor: "auto",
                backgroundColor: "white",
            };
        }
        return {
            cursor: "pointer",
            backgroundColor: "",
        };
    };

    const handleDateChange = (value: IAppCustomDates) => {
        searchParams.set("date", value);
        searchParams.set("page", "1");
        setSearchParams(searchParams);
    };

    return (
        <>
            <div className="flex justify-end mb-16">
                <Dropdown>
                    <DropdownTrigger asChild>
                        <FilterButton
                            text={
                                dateFilter
                                    ? truncateString(
                                          removeEnumUnderscore(
                                              removeEnumUnderscore(dateFilter)
                                          )
                                      )
                                    : "Date"
                            }
                        />
                    </DropdownTrigger>
                    <DropdownContent
                        align="end"
                        width={200}
                        maxHeight={216}
                        className="overflow-y-auto"
                    >
                        {Object.values(IAppCustomDates).map((range) => (
                            <DropdownItem
                                className={cn(
                                    "flex gap-x-8 items-center capitalize text-xs font-medium",
                                    {
                                        "text-primary": dateFilter === range,
                                    }
                                )}
                                key={range}
                                onClick={() => handleDateChange(range)}
                            >
                                {removeEnumUnderscore(range)}{" "}
                                {dateFilter === range ? (
                                    <CheckPrimaryColorIcon />
                                ) : null}
                            </DropdownItem>
                        ))}
                    </DropdownContent>
                </Dropdown>
            </div>
            <div className="grid grid-cols-[repeat(3,minmax(320px,1fr))] overflow-x-auto overflow-y-hidden gap-x-12 mb-32 bg-white rounded-r8 px-12 py-16">
                <Card classNames="bg-card-bg flex flex-col gap-y-8 px-16 py-12 border border-[#0974461e]">
                    <div className="font-medium uppercase text-xs">
                        Overdue notes
                    </div>
                    <div className="font-bold text-xxl">
                        {getErrorAndLoadingStates() ||
                            data?.data.summary.overdue_notes_count}
                    </div>
                </Card>
                <Card classNames="bg-card-bg flex flex-col gap-y-8 px-16 py-12 border border-[#0974461e]">
                    <div className="font-medium uppercase text-xs">
                        AVERAGE TIME TO COMPLETE NOTES
                    </div>
                    <div className="font-bold text-xxl">
                        {getErrorAndLoadingStates() ||
                            convertTimeInSecondsToOtherTimeUnits(
                                data?.data.summary
                                    .average_time_to_sign_note_secs || 0
                            )}
                    </div>
                </Card>
                <Card classNames="bg-card-bg flex flex-col gap-y-8 px-16 py-12 border border-[#0974461e]">
                    <div className="font-medium uppercase text-xs">
                        AVERAGE TIME TO COMPLETE REVIEWS
                    </div>
                    <div className="font-bold text-xxl">
                        {getErrorAndLoadingStates() ||
                            convertTimeInSecondsToOtherTimeUnits(
                                data?.data.summary
                                    .average_time_to_complete_first_review_secs ||
                                    0
                            )}
                    </div>
                </Card>
            </div>

            {isLoading && (
                <Skeleton
                    type="table"
                    containerTestId="audit-loader"
                    count={6}
                />
            )}

            {error && (
                <ListState
                    isError
                    stateHelperText="Try reloading this page to solve this issue"
                    errorMsg={
                        error?.response?.data.message ||
                        `Cannot display notes audit dashboard list at this time please try again later`
                    }
                />
            )}

            {data && isSuccess && data.data.overdue_notes.length === 0 && (
                <ListState
                    stateHelperText="Note audit dashboard list will appear here once available"
                    isError={false}
                    emptyMessage="No note audit dashboard list item to display"
                />
            )}

            {data && isSuccess && data.data.overdue_notes.length > 0 && (
                <Table
                    columns={columns}
                    data={data.data.overdue_notes}
                    handleRowClick={handleRowClick}
                    isHeaderFixed
                    setSortConfig={setSortConfig}
                    sortConfig={sortConfig}
                    sortableColumns={sortableColumns}
                    handleRowStyle={handleRowStyle}
                />
            )}
        </>
    );
}
