import { Controller, useForm } from "react-hook-form";
import { useQueryClient } from "@tanstack/react-query";
import {
    Button,
    DatePicker,
    Input,
    Select,
    TextArea,
} from "@jhool-io/fe-components";

import {
    ISelectedPayment,
    IUpdateInvoicePayment,
    IUpdatePayment,
} from "../../../../utils/types/billing";
import GreenCheckIcon from "../../../../components/Icons/GreenCheck";
import CancelPaymentIcon from "../../../../components/Icons/CancelPayment";
import {
    useUpdateInvoiceCharge,
    useUpdatePayment,
} from "../../../../hooks/mutations/billing";
import useToast from "../../../../hooks/useToast";
import {
    handleFormatDatePickerValue,
    makeStringFirstLetterCapital,
} from "../../../../utils/helpers";
import { usePostPayments } from "../../../remits/hooks/remits.mutations";
import {
    RemarkCodeDescription,
    RemarkCodes,
    WriteOffCodes,
    WriteOffCodesDecription,
} from "../../types/billing.types";
import {
    formatRemarkOptionLabel,
    paymentDateToDisplay,
} from "../../helpers/billing.helpers";

type Options = {
    label: React.ReactNode;
    value: string | undefined;
};

interface UpdateProps {
    /** the ID of the invoice */
    invoiceId?: string;
    /** Current charge amount */
    charge_amount?: number;
    /** Current payment note */
    writeoff_code?: string;
    // the selected action type for the form.
    selectedChargeType?: string;
    // the selected row id
    setEditingRowId: (value: string | null) => void;
    // what type of update are we doing
    updateType:
        | "charge_amount"
        | "payment_date"
        | "payment_note"
        | "payment_amount"
        | "writeoff_code"
        | "remark_code";
    selectedPayment?: ISelectedPayment;
}

const writeOffCodesForSelect: Options[] = [
    ...Object.values(WriteOffCodes).map((item) => ({
        value: item,
        label: WriteOffCodesDecription[item],
    })),
];
const remarkCodeForSelect: Options[] = Object.values(RemarkCodes)
    .map((item) => ({
        value: item, // Use the enum value as the option value
        label: RemarkCodeDescription[
            item as keyof typeof RemarkCodeDescription
        ],
        // Use this for the description
    }))
    .sort((a, b) => a.value.localeCompare(b.value));

const getRemarkCodeDescription = (code: string): string => {
    const matchingDescription = RemarkCodeDescription[code.toLowerCase()];
    return matchingDescription || "--";
};

const getWriteOffCodeDescription = (code: string) => {
    const writeOffDescriptions = Object.values(WriteOffCodesDecription);
    const matchingDescription = writeOffDescriptions?.find(
        (description) =>
            code?.toLowerCase() === description.split(":")[0]?.toLowerCase()
    );
    return matchingDescription || "--";
};

export default function UpdateForm({
    invoiceId,
    charge_amount,
    setEditingRowId,
    selectedChargeType,
    updateType,
    selectedPayment,
}: UpdateProps) {
    const { toast } = useToast();
    const queryClient = useQueryClient();

    const updateCharge = useUpdateInvoiceCharge();
    const updatePayment = useUpdatePayment();
    const postPayments = usePostPayments();

    const { register, watch, control } = useForm<IUpdateInvoicePayment>({
        mode: "onChange",
        defaultValues: {
            amount: selectedPayment?.amount,
            payment_date: paymentDateToDisplay(
                selectedPayment?.payment_date as string,
                (selectedPayment?.payment_channel as string) || ""
            ),
            writeoff_code: writeOffCodesForSelect.find(
                (code) => code.value === selectedPayment?.writeoff_code
            )?.value as WriteOffCodes,
            payment_notes: selectedPayment?.payment_notes || "",
            remark_codes: selectedPayment?.remark_codes || [],
        },
    });

    const chargeAmountToUpdate = watch("charge_amount");
    const selectedDate = watch("payment_date");
    const remarkCodes = watch("remark_codes");
    const paymentNote = watch("payment_notes");
    const paymentAmount = watch("amount");
    const selectedWriteoffCode = watch("writeoff_code");

    function handleUpdate() {
        const updateChargeData = {
            invoice_id: invoiceId as string,
            amount: Number(chargeAmountToUpdate),
            charge_type: selectedChargeType as string,
        };

        if (updateType === "charge_amount") {
            updateCharge.mutate(updateChargeData, {
                onSuccess: (res) => {
                    queryClient.invalidateQueries({
                        queryKey: ["get-payment"],
                    });
                    queryClient.invalidateQueries({
                        queryKey: ["invoices"],
                    });

                    toast({
                        mode: "success",
                        message:
                            makeStringFirstLetterCapital(res.message) ||
                            "Charge updated successfully",
                        duration: 6000,
                    });
                    setEditingRowId(null);
                },
                onError: (err) => {
                    toast({
                        mode: "error",
                        message:
                            err.response?.data.message ||
                            "Error updating charge please try again later",
                        duration: 6000,
                    });
                    setEditingRowId(null);
                },
            });
        } else {
            const payload: IUpdatePayment = {
                payment_id: selectedPayment?.payment_id || "",
                payment_date: handleFormatDatePickerValue(selectedDate),
                ...(selectedPayment?.payment_type === "wbci" ||
                selectedPayment?.payment_type === "wbi"
                    ? {
                          amount:
                              Number(paymentAmount) ||
                              selectedPayment?.amount ||
                              undefined,
                      }
                    : {}),
                payment_notes:
                    paymentNote || selectedPayment?.payment_notes || undefined,
                remark_codes:
                    remarkCodes || selectedPayment?.remark_codes || undefined,
                writeoff_code:
                    selectedWriteoffCode ||
                    selectedPayment?.writeoff_code ||
                    undefined,
            };

            updatePayment.mutate(payload, {
                onSuccess: async () => {
                    toast({
                        mode: "success",
                        message: "Payment updated successfully.",
                    });
                    if (selectedPayment?.payment_status === "pending") {
                        await new Promise<void>((resolve, reject) => {
                            postPayments.mutate(
                                { payment_ids: [payload.payment_id as string] },
                                {
                                    onSuccess: () => {
                                        toast({
                                            mode: "success",
                                            message:
                                                "Payment updated and confirmed successfully.",
                                        });
                                        resolve();
                                    },
                                    onError: (error) => {
                                        toast({
                                            mode: "error",
                                            message:
                                                "Could not confirm payment at this time",
                                        });
                                        reject(error);
                                    },
                                }
                            );
                        });
                    }
                    setEditingRowId(null);
                    queryClient.invalidateQueries({
                        queryKey: ["get-payment"],
                    });
                },

                onError: (err) => {
                    toast({
                        mode: "error",
                        message:
                            err.response?.data.message ||
                            "Error updating payment please try again later",
                    });
                    setEditingRowId(null);
                },
            });
        }
    }

    const handleCancel = () => {
        setEditingRowId(null);
    };

    return (
        <form>
            <div className="flex items-center gap-2">
                {updateType === "charge_amount" && (
                    <div>
                        <Input
                            {...register("charge_amount")}
                            className="w-[70px] h-28"
                            defaultValue={Math.abs(
                                charge_amount as number
                            ).toFixed(2)}
                        />
                    </div>
                )}
                {updateType === "payment_amount" && (
                    <div>
                        <Input {...register("amount")} className="w-[70px]" />
                    </div>
                )}
                {updateType === "payment_note" && (
                    <div>
                        <TextArea
                            {...register("payment_notes")}
                            className="w-[270px]"
                        />
                    </div>
                )}
                {updateType === "payment_date" && (
                    <div>
                        <Controller
                            name="payment_date"
                            control={control}
                            defaultValue={paymentDateToDisplay(
                                selectedPayment?.payment_date as string,
                                (selectedPayment?.payment_channel as string) ||
                                    ""
                            )}
                            render={({ field }) => (
                                <DatePicker
                                    className="w-[110px]"
                                    selected={
                                        selectedDate
                                            ? new Date(selectedDate)
                                            : new Date()
                                    }
                                    onChange={(date) => {
                                        field.onChange(date);
                                    }}
                                />
                            )}
                        />
                    </div>
                )}
                {updateType === "remark_code" && (
                    <Controller
                        name="remark_codes"
                        control={control}
                        render={({ field }) => (
                            <Select
                                label="Select code"
                                placeholder=""
                                options={remarkCodeForSelect}
                                onChange={(val) => {
                                    field.onChange(
                                        (val as Options[]).map(
                                            (code) => code.value
                                        )
                                    );
                                }}
                                isSearchable
                                formatOptionLabel={(data, meta) =>
                                    formatRemarkOptionLabel(
                                        data,
                                        meta,
                                        getRemarkCodeDescription,
                                        "remark_code"
                                    )
                                }
                                selectStyles={{
                                    container: (base) => ({
                                        ...base,
                                        width: "170px",
                                        padding: "6px",
                                        border: "1.5px dotted #666B77",
                                        borderRadius: "8px",
                                        boxSizing: "border-box",
                                        backgroundColor: "transparent",
                                    }),
                                    control: (base) => ({
                                        ...base,
                                        border: "none",
                                        boxShadow: "none",
                                        minHeight: "28px",
                                        backgroundColor: "transparent",
                                        cursor: "pointer",
                                    }),
                                    placeholder: () => ({
                                        margin: 0,
                                        opacity: 0.9,
                                        fontSize: "14px",
                                    }),
                                    valueContainer: (base) => ({
                                        ...base,
                                        padding: 0,
                                        gap: "4px",
                                    }),

                                    multiValueLabel: (base) => ({
                                        ...base,
                                        color: "#44474F",
                                        fontSize: "14px",
                                        textTransform: "uppercase",
                                    }),
                                    multiValueRemove: (base) => ({
                                        ...base,
                                        color: "#717784",
                                        cursor: "pointer",
                                        marginTop: "-5px",
                                        marginLeft: "-10px",
                                        ":hover": {
                                            backgroundColor: "transparent",
                                            color: "#F71735",
                                        },
                                    }),
                                }}
                                defaultValue={remarkCodeForSelect.filter(
                                    (code) =>
                                        field.value?.includes(code.value || "")
                                )}
                                onBlur={() => handleUpdate()}
                                isLongListInDialog
                                isMulti
                                multiHasValues={
                                    remarkCodes && remarkCodes.length > 0
                                }
                            />
                        )}
                    />
                )}

                {updateType === "writeoff_code" && (
                    <Controller
                        name="writeoff_code"
                        control={control}
                        render={({ field }) => (
                            <Select
                                label="Select code"
                                placeholder=""
                                options={writeOffCodesForSelect}
                                onChange={(val) => {
                                    field.onChange((val as Options).value);
                                }}
                                defaultValue={writeOffCodesForSelect.filter(
                                    (code) =>
                                        field.value?.includes(code.value || "")
                                )}
                                isSearchable
                                formatOptionLabel={(data, meta) =>
                                    formatRemarkOptionLabel(
                                        data,
                                        meta,
                                        getWriteOffCodeDescription,
                                        "writeoff_code"
                                    )
                                }
                                selectStyles={{
                                    container: (base) => ({
                                        ...base,
                                        width: "170px",
                                        padding: "6px",
                                        border: "1.5px dotted #666B77",
                                        borderRadius: "8px",
                                        boxSizing: "border-box",
                                        backgroundColor: "transparent",
                                    }),
                                    control: (base) => ({
                                        ...base,
                                        backgroundColor: "transparent",
                                        border: "none",
                                        minHeight: "28px",
                                        boxShadow: "none",
                                    }),
                                }}
                                onBlur={() => handleUpdate()}
                                isLongListInDialog
                            />
                        )}
                    />
                )}
                {updateType !== "remark_code" &&
                    updateType !== "writeoff_code" && (
                        <>
                            <div>
                                <Button
                                    size="auto"
                                    variant="normal"
                                    className="ml-2"
                                    onClick={() => handleUpdate()}
                                >
                                    <GreenCheckIcon />
                                </Button>
                            </div>
                            <div>
                                <Button
                                    size="auto"
                                    variant="normal"
                                    onClick={handleCancel}
                                >
                                    <CancelPaymentIcon />
                                </Button>
                            </div>
                        </>
                    )}
            </div>
        </form>
    );
}
