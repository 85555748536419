export default function RefundPaymentIcon() {
    return (
        <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M5.78125 9.55062C5.78125 10.4106 6.44125 11.104 7.26125 11.104H8.93458C9.64792 11.104 10.2279 10.4973 10.2279 9.75063C10.2279 8.93729 9.87458 8.65062 9.34792 8.46396L6.66125 7.53063C6.13458 7.34396 5.78125 7.05729 5.78125 6.24396C5.78125 5.49729 6.36125 4.89062 7.07458 4.89062H8.74792C9.56792 4.89062 10.2279 5.58396 10.2279 6.44396"
                stroke="#0B132B"
                strokeWidth="1.2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M8 4V12"
                stroke="#0B132B"
                strokeWidth="1.2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M7.99992 14.6693C11.6818 14.6693 14.6666 11.6845 14.6666 8.0026C14.6666 4.32071 11.6818 1.33594 7.99992 1.33594C4.31802 1.33594 1.33325 4.32071 1.33325 8.0026C1.33325 11.6845 4.31802 14.6693 7.99992 14.6693Z"
                stroke="#0B132B"
                strokeWidth="1.2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}
