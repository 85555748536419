import { Button, Input } from "@jhool-io/fe-components";
import { Link, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { sendPasswordResetEmail } from "firebase/auth";
import { FirebaseError } from "firebase/app";
import AuthWrapper from "../components/AuthWrapper/AuthWrapper";
import useToast from "../../../hooks/useToast";
import { auth } from "../../../firebase";

interface IResetPasswordProps {
    email: string;
}

const schema = yup.object({
    email: yup
        .string()
        .email("Please enter a valid email")
        .required("Email is required"),
});

export default function ResetPasswordPage() {
    const {
        register,
        handleSubmit,
        formState: { errors, isSubmitting },
    } = useForm<IResetPasswordProps>({
        resolver: yupResolver(schema),
        mode: "onChange",
    });

    const navigate = useNavigate();

    const { toast } = useToast();

    const handleErrorMessageToShow = (errorCode: string) => {
        if (errorCode === "auth/user-not-found") {
            return "We couldn't find an account with that email. please check your email.";
        }

        return "Could not send password reset email, please try again later";
    };

    const onSubmit = async (data: IResetPasswordProps) => {
        try {
            await sendPasswordResetEmail(auth, data.email);
            toast({
                mode: "success",
                message:
                    "Password reset email sent successfully, please check your email",
            });

            navigate("/login");
        } catch (error) {
            if (error instanceof FirebaseError) {
                toast({
                    mode: "error",
                    message: handleErrorMessageToShow(error.code),
                });
            } else {
                toast({
                    mode: "error",
                    message:
                        "Could not send password reset email, please try again later",
                });
            }
        }
    };

    return (
        <AuthWrapper
            footer={
                <div className="text-center">
                    <p className="text-[#525866] font-normal text-sm tracking-[-0.154px]">
                        Remember password?{" "}
                        <Link className="text-primary font-medium" to="/login">
                            Login
                        </Link>
                    </p>
                </div>
            }
        >
            <>
                <h3 className="text-xxl font-bold mb-4 text-black">
                    Reset Password
                </h3>
                <p className="text-[#525866] text-base tracking-[-0.176px] font-normal leading-[24px]">
                    We will send an email with verification link. If you don’t
                    see it, please check your spam folder
                </p>
                <form
                    className="mt-24 md:mt-40"
                    onSubmit={handleSubmit(onSubmit)}
                >
                    <div className="mb-24">
                        <Input
                            {...register("email")}
                            label="Email Address"
                            placeholder="Enter your email address"
                            hasError={!!errors.email}
                            errorText={errors.email?.message}
                        />
                    </div>
                    <Button
                        disabled={isSubmitting}
                        className="w-full"
                        size="medium"
                        type="submit"
                    >
                        Send email
                    </Button>
                </form>
            </>
        </AuthWrapper>
    );
}
