import * as React from "react";
import {
    Card,
    Dialog,
    DialogContent,
    Button,
    Tooltip,
    Tag,
    DropdownTrigger,
    DropdownContent,
    Dropdown,
    DropdownItem,
} from "@jhool-io/fe-components";
import { useParams, useSearchParams } from "react-router-dom";
import { useIsMutating, useQueryClient } from "@tanstack/react-query";
import styles from "./BasicInfo.module.scss";
import { isMinor } from "../../../../utils/helpers/age";
import EditBasicInfo from "./EditBasicInfo/EditBasicInfo";
import { useFetchClientBasicAndPersonalInfo } from "../../../../hooks/queries/client";
import ListState from "../../../ListState/ListState";
import Skeleton from "../../../Skeleton/Skeleton";
import { UserPermisions, UserRole } from "../../../../utils/types/user";
import {
    useDoesUserHavePermission,
    useDoesUserHaveRole,
} from "../../../../hooks/permissions";
import {
    cn,
    displayNameInRightFormat,
    formatDate,
    handleDisplayClientEmailCorrectly,
    removeEnumUnderscore,
} from "../../../../utils/helpers";
import EditIcon from "../../../Icons/Edit";
import { getStateInShortForm } from "../../../../utils/helpers/us-states/us-states";
import ArrowFilledUp from "../../../Icons/ArrowFilledUp";
import ArrowFilledDown from "../../../Icons/ArrowFilledDown";
import PinIcon from "../../../Icons/Pin";
import UnpinIcon from "../../../Icons/Unpin";
import { PinnedClientsContext } from "../../../../context/pinned-clients/PinnedClientsContext";
import { getProviderCredenetialPillStyle } from "../../../../modules/providers/helpers/providers.helper";
import { ProviderCredential } from "../../../../modules/clients/types/clients.types";
import {
    ClientStatus,
    FinancialAssistanceStatus,
} from "../../../../utils/types/client";
import { useFetchClientFlags } from "../../../../modules/clients/hooks/clients.queries";
import ChevronDownIcon from "../../../Icons/ChevronDown";
import { useDeleteClientFlag } from "../../../../modules/clients/hooks/clients.mutations";
import useToast from "../../../../hooks/useToast";
import EditClientFlagForm from "../../../../modules/clients/components/EditClientFlagForm/EditClientFlagForm";
import AddClientFlagForm from "../../../../modules/clients/components/AddClientFlagForm/AddClientFlagForm";
import { useAppSelector } from "../../../../hooks/useRedux";

type Dialogs = "edit-info" | "add-flag" | "edit-flag" | "delete-flag";

export default function BasicInfo() {
    const isSmallScreen = window.matchMedia("(max-width: 768px)").matches;
    // Component local states
    const [isMaximised, setIsMaximised] = React.useState(!isSmallScreen);
    const [dialogInView, setDialogInView] = React.useState<Dialogs | null>(
        null
    );

    const [selectedFlagId, setSelectedFlagId] = React.useState<string | null>(
        null
    );

    const itemsRef = React.useRef<HTMLDivElement>(null);

    // Get id from url params
    const params = useParams();
    const clientId = params.clientId as string;

    const handleToggleMaximizeState = () => {
        setIsMaximised((state) => !state);
    };

    // Fetch client basic info
    const { data, error } = useFetchClientBasicAndPersonalInfo(clientId);

    const { practice } = useAppSelector((state) => state.userPractice);

    const userCanViewFlags = !(
        practice?.roles.length === 1 &&
        practice?.roles?.[0] === UserRole.PROVIDER
    );

    // Fetch client flags
    const clientFlags = useFetchClientFlags(clientId, userCanViewFlags);

    const deleteFlag = useDeleteClientFlag(clientId, selectedFlagId || "");

    const pinnedClientsContext = React.useContext(PinnedClientsContext);

    // Get serach params from url
    const [searchParams] = useSearchParams();
    const tabInView = searchParams.get("tab");

    // Function for opening or closing modal
    const handleDialogInView = (modal: Dialogs | null) => {
        setDialogInView(() => modal);
    };

    const { toast } = useToast();

    const queryClient = useQueryClient();

    // Function to delete client flag
    const handleDeleteFlag = () => {
        deleteFlag.mutate(null, {
            onSuccess: async (res) => {
                await queryClient.invalidateQueries({
                    queryKey: [clientId, "client-flags"],
                });
                toast({
                    mode: "success",
                    message: res.message || "Flag added successfully",
                });
                setSelectedFlagId(null);
                setDialogInView(null);
            },
            onError: (err) => {
                toast({
                    mode: "error",
                    message:
                        err.response?.data.message ||
                        "Could not add flag at this time.",
                });
            },
        });
    };

    // Roles restricted from editing
    const restrictedRoles = [UserRole.AUDITOR, UserRole.PROVIDER];

    // check if logged in user can edit client info
    const hasEditPermission = useDoesUserHavePermission([
        UserPermisions.CLIENT_INFO_ALL_ADMIN,
        UserPermisions.CLIENT_INFO_PERSONAL_WRITE,
        UserPermisions.CLIENT_INFO_ALL_WRITE,
        UserPermisions.CLIENT_INFO_PROVIDER_WRITE,
    ]);

    const hasRestrictedRole = useDoesUserHaveRole(restrictedRoles);

    // Check if the logged in user can edit personal info
    const canEditClientInfo = hasEditPermission && !hasRestrictedRole;

    const canSeeProviderHistory = useDoesUserHavePermission([
        UserPermisions.CLIENT_INFO_ALL_ADMIN,
        UserPermisions.CLIENT_INFO_PROVIDER_READ,
        UserPermisions.CLIENT_INFO_PROVIDER_WRITE,
    ]);

    // Function for scrolling to provider history list section
    const handleProviderHistoryButtonClick = () => {
        /**  Cannot access element ref here because 
         provider history list is not in this
         component so we perform action directly on the dom */
        const providerHistoryHtml = document.getElementById(
            "provider-history"
        ) as HTMLDivElement;

        providerHistoryHtml.scrollIntoView({ behavior: "smooth" });
    };

    // To determine if mutation is currently running, returns 0 || 1
    const isMutating = useIsMutating(["edit-personal-info", clientId]);
    const addFlagIsMutating = useIsMutating(["add-client-flag", clientId]);
    const editFlagIsMutating = useIsMutating([
        "update-client-flag",
        selectedFlagId,
    ]);

    const getConsentStatus = (consent: boolean | undefined) => {
        switch (consent) {
            case true:
                return "Yes";
            case false:
                return "No";
            default:
                return "--";
        }
    };

    React.useEffect(() => {
        if (itemsRef.current) {
            if (isMaximised) {
                itemsRef.current.style.height = `auto`;
            } else {
                itemsRef.current.style.height = `0`;
            }
        }
    }, [isMaximised, isSmallScreen]);

    return (
        <>
            <Dialog open={dialogInView === "edit-info" && Boolean(data)}>
                <DialogContent
                    title="Edit personal info"
                    handleCloseDialog={() => handleDialogInView(null)}
                    variant="center"
                    cancelText="Cancel"
                    saveText="Save Changes"
                    submitBtnFormValue="edit-basic-info"
                    isCancelBtnDisabled={isMutating > 0}
                    isSubmitBtnDisabled={isMutating > 0}
                >
                    <div>
                        {data?.data && (
                            <EditBasicInfo
                                clientBasicInfo={data.data}
                                onFormSubmit={() => handleDialogInView(null)}
                            />
                        )}
                    </div>
                </DialogContent>
            </Dialog>
            <Dialog open={dialogInView === "add-flag" && Boolean(clientId)}>
                <DialogContent
                    title="Add client flag"
                    handleCloseDialog={() => handleDialogInView(null)}
                    variant="center"
                    saveText="Add flag"
                    submitBtnFormValue="add-client-flag"
                    isCancelBtnDisabled={addFlagIsMutating > 0}
                    isSubmitBtnDisabled={addFlagIsMutating > 0}
                >
                    <AddClientFlagForm
                        onFormSubmit={() => handleDialogInView(null)}
                    />
                </DialogContent>
            </Dialog>
            <Dialog
                open={dialogInView === "delete-flag" && Boolean(selectedFlagId)}
            >
                <DialogContent
                    title="Delete client flag"
                    handleCloseDialog={() => handleDialogInView(null)}
                    variant="center"
                    saveText="Delete flag"
                    onSaveClick={handleDeleteFlag}
                    isCancelBtnDisabled={addFlagIsMutating > 0}
                    isSubmitBtnDisabled={addFlagIsMutating > 0}
                    isDeleting
                >
                    <div>
                        <h3 className="font-medium text-xl mb-8">
                            Are you sure you want to delete flag?
                        </h3>
                        <p className="text-gray font-normal text-base">
                            This will delete this flag from the client flags and
                            cannot be reversed
                        </p>
                    </div>
                </DialogContent>
            </Dialog>
            <Dialog
                open={dialogInView === "edit-flag" && Boolean(selectedFlagId)}
            >
                <DialogContent
                    title="Edit client flag"
                    handleCloseDialog={() => handleDialogInView(null)}
                    variant="center"
                    saveText="Save changes"
                    submitBtnFormValue="edit-client-flag"
                    isCancelBtnDisabled={editFlagIsMutating > 0}
                    isSubmitBtnDisabled={editFlagIsMutating > 0}
                >
                    <EditClientFlagForm
                        onFormSubmit={() => handleDialogInView(null)}
                        flagId={selectedFlagId || ""}
                    />
                </DialogContent>
            </Dialog>
            {error && (
                <div className={styles.bi_stateclass}>
                    <ListState
                        isError
                        errorMsg={
                            error?.response?.data.message ||
                            `Cannot display client basic info at this time please try again later`
                        }
                        listHeader="BASIC INFO"
                        stateHelperText="Try reloading this page to solve this issue"
                    />
                </div>
            )}

            {!error && (
                <Card classNames="mb-32 py-24">
                    <div className="flex justify-between items-center gap-x-12 gap-y-16 flex-wrap ">
                        {(data && (
                            <h3 className="text-base flex items-center flex-wrap gap-x-12 gap-y-8">
                                <>
                                    <span className="capitalize">
                                        {displayNameInRightFormat({
                                            firstName: data.data.first_name,
                                            lastName: data.data.last_name,
                                        })}
                                    </span>
                                    <span>
                                        <Tag
                                            title={
                                                data.data.client_is_b2b
                                                    ? "B2B"
                                                    : ""
                                            }
                                            textColor="#634D17"
                                            bgColor="rgba(247, 229, 164, 0.50)"
                                        />
                                    </span>
                                    {isMinor(data.data.age) ? (
                                        <span>
                                            <Tag
                                                title=" Minor"
                                                textColor="#981F41"
                                                bgColor="rgba(251, 199, 198, 0.50)"
                                                className="font-normal px-8 py-4 h-24 rounded-r4"
                                            />
                                        </span>
                                    ) : null}
                                    {data.data.financial_assistance_status &&
                                    (data.data.financial_assistance_status ===
                                        FinancialAssistanceStatus.EXPIRED ||
                                        data.data
                                            .financial_assistance_status ===
                                            FinancialAssistanceStatus.YES) ? (
                                        <Tooltip
                                            content={
                                                data?.data
                                                    .financial_assistance_end_date
                                                    ? `${
                                                          data.data
                                                              .financial_assistance_start_date
                                                              ? `Starts ${formatDate(
                                                                    data.data
                                                                        .financial_assistance_start_date,
                                                                    true
                                                                )}, `
                                                              : ""
                                                      }Ends ${formatDate(
                                                          data.data
                                                              .financial_assistance_end_date,
                                                          true
                                                      )}`
                                                    : "Date not added"
                                            }
                                            classNames="px-8 py-4"
                                        >
                                            <Button
                                                variant="normal"
                                                size="auto"
                                                className={cn(
                                                    "font-normal px-8 py-4 h-24 !rounded-r4 text-[#165574] bg-[#acdefa7f] text-nowrap text-xs",
                                                    {
                                                        "text-[#981F41] bg-[rgba(251,_199,_198,_0.50)]":
                                                            data.data
                                                                .financial_assistance_status ===
                                                            FinancialAssistanceStatus.EXPIRED,
                                                    }
                                                )}
                                            >
                                                {data.data
                                                    .financial_assistance_status ===
                                                FinancialAssistanceStatus.EXPIRED
                                                    ? "Expired Financial Assistance"
                                                    : "Financial assistance"}
                                            </Button>
                                        </Tooltip>
                                    ) : null}
                                    {data?.data.client_status ===
                                    ClientStatus.INACTIVE ? (
                                        <span>
                                            <Tag
                                                title="Inactive"
                                                textColor="#981F41"
                                                bgColor="rgba(251, 199, 198, 0.50)"
                                                className="font-normal px-8 py-4 h-24 rounded-r4"
                                            />
                                        </span>
                                    ) : (
                                        <span>
                                            <Tag
                                                title={data?.data.client_status}
                                                textColor="#00563E"
                                                bgColor="rgba(204, 250, 233, 0.50)"
                                                className="font-normal px-8 py-4 h-24 rounded-r4 capitalize"
                                            />
                                        </span>
                                    )}
                                </>
                            </h3>
                        )) || (
                            <Skeleton
                                containerTestId="basicinfo-loader"
                                width="150px"
                            />
                        )}

                        {(data && (
                            <div className="flex gap-x-12 items-center">
                                {userCanViewFlags ? (
                                    <Button
                                        variant="secondary"
                                        onClick={() =>
                                            handleDialogInView("add-flag")
                                        }
                                    >
                                        Add flag
                                    </Button>
                                ) : null}
                                {canSeeProviderHistory &&
                                    (!tabInView ||
                                        tabInView === "personal-info") && (
                                        <Button
                                            variant="secondary"
                                            onClick={
                                                handleProviderHistoryButtonClick
                                            }
                                            className="hidden md:flex"
                                        >
                                            Providers history
                                        </Button>
                                    )}

                                {canEditClientInfo && (
                                    <>
                                        <Button
                                            onClick={() =>
                                                handleDialogInView("edit-info")
                                            }
                                            className="hidden md:flex"
                                        >
                                            Edit
                                        </Button>
                                        <Button
                                            variant="normal"
                                            size="auto"
                                            className="rounded-full bg-[#576a810d] flex items-center justify-center md:hidden"
                                            onClick={() =>
                                                handleDialogInView("edit-info")
                                            }
                                        >
                                            <EditIcon />
                                        </Button>
                                    </>
                                )}
                                {pinnedClientsContext?.isClientPinnedInLocation(
                                    clientId,
                                    "client_chart"
                                ) ? (
                                    <Tooltip
                                        content="Unpin Client"
                                        classNames="px-8 py-4"
                                    >
                                        <Button
                                            onClick={() => {
                                                pinnedClientsContext.handleUnpinClient(
                                                    clientId,
                                                    "client_chart"
                                                );
                                            }}
                                        >
                                            <UnpinIcon />
                                        </Button>
                                    </Tooltip>
                                ) : (
                                    <Tooltip
                                        content="Pin client"
                                        classNames="px-8 py-4"
                                    >
                                        <Button
                                            onClick={() =>
                                                pinnedClientsContext?.handlePinClient(
                                                    {
                                                        clientId,
                                                        clientName: `${data.data.first_name} ${data.data.last_name}`,
                                                        pinModule:
                                                            "client_chart",
                                                    }
                                                )
                                            }
                                        >
                                            <PinIcon />
                                        </Button>
                                    </Tooltip>
                                )}
                                <Button onClick={handleToggleMaximizeState}>
                                    {isMaximised ? (
                                        <ArrowFilledUp />
                                    ) : (
                                        <ArrowFilledDown />
                                    )}
                                </Button>
                            </div>
                        )) || (
                            <Skeleton
                                containerTestId="basicinfo-loader"
                                width="150px"
                            />
                        )}
                    </div>

                    {(data && (
                        <span className="text-sm text-gray capitalize">
                            {data.data.pronouns || "--"}
                        </span>
                    )) || (
                        <Skeleton
                            containerTestId="basicinfo-loader"
                            width="150px"
                        />
                    )}

                    <div className="pt-2 flex gap-x-2 gap-y-2 items-center flex-wrap">
                        {clientFlags.data &&
                            clientFlags.data.data.length > 0 &&
                            clientFlags.data.data.map((flag) => (
                                <Dropdown key={flag.client_flag_id}>
                                    <DropdownTrigger asChild>
                                        <Button
                                            variant="normal"
                                            size="auto"
                                            className="capitalize !rounded-r4 px-8 py-4 h-24 text- text-xs text-[#165574] bg-[#acdefa7f]"
                                        >
                                            {flag.content}
                                            <ChevronDownIcon
                                                className="ml-4"
                                                stroke="#165574"
                                            />
                                        </Button>
                                    </DropdownTrigger>
                                    <DropdownContent width="auto" align="end">
                                        <DropdownItem
                                            onClick={() => {
                                                setSelectedFlagId(
                                                    flag.client_flag_id
                                                );
                                                setDialogInView("edit-flag");
                                            }}
                                        >
                                            Edit
                                        </DropdownItem>
                                        <DropdownItem
                                            onClick={() => {
                                                setSelectedFlagId(
                                                    flag.client_flag_id
                                                );
                                                setDialogInView("delete-flag");
                                            }}
                                        >
                                            Delete
                                        </DropdownItem>
                                    </DropdownContent>
                                </Dropdown>
                            ))}
                    </div>

                    <div
                        ref={itemsRef}
                        className="transition-all h-0 duration-1000 ease-out overflow-y-auto"
                    >
                        <div className="rounded-r8 border border-[#fafafa] bg-[#fafafa] p-12 mt-24">
                            <div className="grid grid-cols-[repeat(auto-fit,minmax(250px,1fr))] gap-y-12 gap-x-8 overflow-y-auto ">
                                <div className=" text-xs font-semibold">
                                    <p className="text-gray">
                                        CLIENT CHART NUMBER
                                    </p>
                                    {(data && (
                                        <p className="text-sm font-medium capitalize">
                                            {data.data.client_chart_number ||
                                                "--"}
                                        </p>
                                    )) || (
                                        <Skeleton
                                            containerTestId="basicinfo-loader"
                                            width="150px"
                                        />
                                    )}
                                </div>
                                <div className=" text-xs font-semibold">
                                    <p className="text-gray">PREFERRED NAME</p>
                                    {(data && (
                                        <p className="text-sm font-medium capitalize">
                                            {data.data.preferred_name || "--"}
                                        </p>
                                    )) || (
                                        <Skeleton
                                            containerTestId="basicinfo-loader"
                                            width="150px"
                                        />
                                    )}
                                </div>
                                <div className=" text-xs font-semibold">
                                    <p className="text-gray">SEX</p>
                                    {(data && (
                                        <p className="text-sm font-medium capitalize">
                                            {data.data.sex || "--"}
                                        </p>
                                    )) || (
                                        <Skeleton
                                            containerTestId="basicinfo-loader"
                                            width="150px"
                                        />
                                    )}
                                </div>
                                <div className=" text-xs font-semibold">
                                    <p className="text-gray">AGE</p>
                                    {(data && (
                                        <p className="text-sm font-medium capitalize">
                                            {data.data.age} yrs
                                        </p>
                                    )) || (
                                        <Skeleton
                                            containerTestId="basicinfo-loader"
                                            width="150px"
                                        />
                                    )}
                                </div>
                                <div className=" text-xs font-semibold">
                                    <p className="text-gray">DATE OF BIRTH</p>

                                    {(data && (
                                        <p className="text-sm font-medium capitalize">
                                            {formatDate(
                                                data.data.date_of_birth,
                                                true
                                            )}
                                        </p>
                                    )) || (
                                        <Skeleton
                                            containerTestId="basicinfo-loader"
                                            width="150px"
                                        />
                                    )}
                                </div>
                                <div className=" text-xs font-semibold ">
                                    <p className="text-gray"> CONTACT</p>

                                    {(data && (
                                        <p className="text-sm font-medium capitalize">
                                            {data.data.contact}
                                        </p>
                                    )) || (
                                        <Skeleton
                                            containerTestId="basicinfo-loader"
                                            width="150px"
                                        />
                                    )}
                                </div>
                                <div className=" text-xs font-semibold  uppercase">
                                    <p className="text-gray">EMAIL</p>
                                    {(data && (
                                        <p className="text-sm font-medium lowercase">
                                            {handleDisplayClientEmailCorrectly(
                                                data.data.email
                                            )}
                                        </p>
                                    )) || (
                                        <Skeleton
                                            containerTestId="basicinfo-loader"
                                            width="150px"
                                        />
                                    )}
                                </div>

                                <div className=" text-xs font-semibold  uppercase">
                                    <p className="text-gray">PROVIDER</p>
                                    {(data && (
                                        <div className="flex gap-x-8 items-center">
                                            <p className="text-sm font-medium capitalize ">
                                                {data.data.current_provider
                                                    ? displayNameInRightFormat(
                                                          data.data
                                                              .current_provider
                                                      )
                                                    : "--"}
                                            </p>
                                            {data?.data
                                                ?.provider_credential && (
                                                <p>
                                                    {getProviderCredenetialPillStyle(
                                                        data?.data
                                                            ?.provider_credential as ProviderCredential
                                                    )}
                                                </p>
                                            )}
                                        </div>
                                    )) || (
                                        <Skeleton
                                            containerTestId="basicinfo-loader"
                                            width="150px"
                                        />
                                    )}
                                </div>

                                <div className=" text-xs font-semibold  uppercase">
                                    <p className="text-gray">DIAGNOSIS</p>
                                    {(data && (
                                        <p className="text-sm font-medium capitalize">
                                            {data.data.latest_diagnosis
                                                .description || "--"}
                                        </p>
                                    )) || (
                                        <Skeleton
                                            containerTestId="basicinfo-loader"
                                            width="150px"
                                        />
                                    )}
                                </div>

                                <div className=" text-xs font-semibold  uppercase">
                                    <p className="text-gray">DIAGNOSIS CODE</p>
                                    {(data && (
                                        <p className="text-sm font-medium capitalize">
                                            {data.data.latest_diagnosis.code ||
                                                "--"}
                                        </p>
                                    )) || (
                                        <Skeleton
                                            containerTestId="basicinfo-loader"
                                            width="150px"
                                        />
                                    )}
                                </div>

                                <div className=" text-xs font-semibold  uppercase">
                                    <p className="text-gray">LAST SESSION</p>
                                    {(data && (
                                        <p className="text-sm font-medium capitalize">
                                            {formatDate(
                                                data.data.last_session || ""
                                            ) || "--"}
                                        </p>
                                    )) || (
                                        <Skeleton
                                            containerTestId="basicinfo-loader"
                                            width="150px"
                                        />
                                    )}
                                </div>

                                <div className=" text-xs font-semibold  uppercase">
                                    <p className="text-gray">
                                        insurance provider
                                    </p>
                                    {(data && (
                                        <p className="text-sm font-medium uppercase">
                                            {data.data.insurance_provider
                                                ? removeEnumUnderscore(
                                                      data.data
                                                          .insurance_provider
                                                  )
                                                : "--"}
                                        </p>
                                    )) || (
                                        <Skeleton
                                            containerTestId="basicinfo-loader"
                                            width="150px"
                                        />
                                    )}
                                </div>

                                <div className=" text-xs font-semibold ">
                                    <p className="text-gray  uppercase">
                                        FINANCIAL ASSISTANCE STATUS
                                    </p>
                                    {(data && (
                                        <span className="section-value capitalize">
                                            {data.data
                                                .financial_assistance_status ||
                                                "--"}
                                            {data.data
                                                .financial_assistance_status &&
                                                data.data
                                                    .financial_assistance_end_date &&
                                                `(${
                                                    data.data
                                                        .financial_assistance_start_date
                                                        ? `Starts ${formatDate(
                                                              data.data
                                                                  .financial_assistance_start_date,
                                                              true
                                                          )}, `
                                                        : ""
                                                }Ends ${formatDate(
                                                    data.data
                                                        .financial_assistance_end_date,
                                                    true
                                                )})`}
                                        </span>
                                    )) || (
                                        <Skeleton
                                            containerTestId="basicinfo-loader"
                                            width="150px"
                                        />
                                    )}
                                </div>

                                <div className=" text-xs font-semibold  uppercase">
                                    <p className="text-gray">Address</p>
                                    {(data && (
                                        <p className="text-sm font-medium capitalize">
                                            {data.data.address}
                                        </p>
                                    )) || <Skeleton width="150px" />}
                                </div>

                                <div className=" text-xs font-semibold  uppercase">
                                    <p className="text-gray">City</p>
                                    {(data && (
                                        <p className="text-sm font-medium capitalize">
                                            {data.data.city || "--"}
                                        </p>
                                    )) || <Skeleton width="150px" />}
                                </div>

                                <div className=" text-xs font-semibold  uppercase">
                                    <p className="text-gray">State</p>
                                    {(data && (
                                        <p className="text-sm font-medium capitalize">
                                            {getStateInShortForm(
                                                data.data.state
                                            )}
                                        </p>
                                    )) || <Skeleton width="150px" />}
                                </div>

                                <div className=" text-xs font-semibold  uppercase">
                                    <p className="text-gray">Zipcode</p>
                                    {(data && (
                                        <p className="text-sm font-medium capitalize">
                                            {data.data.zip_code || "--"}
                                        </p>
                                    )) || <Skeleton width="150px" />}
                                </div>
                                <div className=" text-xs font-semibold  uppercase">
                                    <p className="text-gray">Consent to Sms</p>
                                    {(data && (
                                        <p className="text-sm font-medium capitalize">
                                            {getConsentStatus(
                                                data.data.consent_to_sms
                                            )}
                                        </p>
                                    )) || <Skeleton width="150px" />}
                                </div>
                            </div>
                        </div>
                    </div>
                </Card>
            )}
        </>
    );
}
