import { SvgProps } from "../../utils/types";

export default function ConfirmPaymentIcon({ fill }: SvgProps) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            height="16"
            width="16"
            viewBox="0 0 24 24"
        >
            <g fill={fill || "#0B132B"}>
                <path
                    d="M8.5,20c-.398,0-.78-.158-1.061-.439L1.086,13.207c-.39-.391-.39-1.024,0-1.414l.707-.707c.391-.391,1.024-.391,1.414,0l5.293,5.293L20.793,4.086c.391-.391,1.024-.391,1.414,0l.707,.707c.391,.391,.391,1.024,0,1.414l-13.353,13.354c-.281,.281-.663,.439-1.061,.439Z"
                    fill={fill || "#0B132B"}
                />
            </g>
        </svg>
    );
}
