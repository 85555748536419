/* eslint-disable react/no-unstable-nested-components */
import {
    Button,
    Card,
    // Checkbox,
    DatePicker,
    Pagination,
    Popover,
    PopoverContent,
    PopoverTrigger,
    SearchInput,
    Table,
} from "@jhool-io/fe-components";
import * as React from "react";
import { ColumnDef } from "@tanstack/react-table";
import { format } from "date-fns";
import { Link, useSearchParams } from "react-router-dom";
import ChevronDownIcon from "../../../../components/Icons/ChevronDown";
import { APP_COLORS } from "../../../../utils/constants";
import patternBg from "../../../../assets/images/patternbg.png";
import { ExpiringPolicy, ExpiryDateRange } from "../../types/dashboard.types";
import {
    cn,
    displayNameInRightFormat,
    formatDate,
    makeStringFirstLetterCapital,
    removeEnumUnderscore,
} from "../../../../utils/helpers";
import { momentNotTz } from "../../../../utils/moment";
import { useDebounce } from "../../../../hooks/helpers";
import { useFetchExpiringPolicies } from "../../hooks/dashboard.queries";
import ListState from "../../../../components/ListState/ListState";
import Skeleton from "../../../../components/Skeleton/Skeleton";

export default function ExpiringPoliciesList() {
    const [searchParams, setSearchParams] = useSearchParams();
    const [startDate, setStartDate] = React.useState<Date | null>(null);
    const [endDate, setEndDate] = React.useState<Date | null>(null);
    const [searchString, setSearchString] = React.useState<string>("");
    const searchFilter = useDebounce(searchString, 500);

    const [sortConfig, setSortConfig] = React.useState<{
        key: string;
        direction: string;
    }>({
        key: "end_date",
        direction: "DESC",
    });

    const customDateFilter = searchParams.get("expiry_date") || "this_month";

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const onChange = (dates: any) => {
        const [start, end] = dates;
        setStartDate(start);
        setEndDate(end);
        searchParams.delete("expiry_date");
        setSearchParams(searchParams);
    };

    const sortableColumns = [
        "financial_assistance_end_date",
        "start_date",
        "end_date",
    ];

    const handleConfigAttribute = () => {
        if (sortableColumns.includes(sortConfig.key)) {
            return sortConfig.key;
        }

        return undefined;
    };

    const startDateFilter = startDate
        ? format(startDate, "yyyy-MM-dd")
        : undefined;
    const endDateFilter = endDate ? format(endDate, "yyyy-MM-dd") : undefined;
    const insuranceProviderFilter =
        searchParams.get("insurance_provider") || "";

    const pageFilter = Number(searchParams.get("page") || 1);
    const limitFilter = Number(searchParams.get("limit")) || 20;
    const { data, isSuccess, isLoading, error } = useFetchExpiringPolicies({
        search_string: searchFilter,
        custom_date: customDateFilter,
        from_date: startDateFilter,
        to_date: endDateFilter,
        page: pageFilter,
        limit: limitFilter,
        insurance_provider_id: insuranceProviderFilter,
        sort_attribute:
            searchParams.get("sort_attr") || handleConfigAttribute(),
        sort_order: searchParams.get("sort_order") || sortConfig.direction,
    });

    const columns: ColumnDef<ExpiringPolicy>[] = React.useMemo(
        () => [
            // {
            //     id: "select",
            //     header: ({ table }) => (
            //         <div className="mt-[4px]">
            //             <Checkbox
            //                 handleChange={table.getToggleAllRowsSelectedHandler()}
            //                 indeterminate={table.getIsSomeRowsSelected()}
            //                 label=""
            //                 name="row"
            //                 value="row"
            //                 isChecked={table.getIsAllRowsSelected()}
            //             />
            //         </div>
            //     ),
            //     cell: ({ row }) => (
            //         <Checkbox
            //             handleChange={row.getToggleSelectedHandler()}
            //             indeterminate={row.getIsSomeSelected()}
            //             label=""
            //             name="row"
            //             value="row"
            //             isChecked={row.getIsSelected()}
            //         />
            //     ),
            // },
            {
                accessorKey: "client_name",
                header: "Client",
                cell: ({ row }) => (
                    <Link
                        to={`/clients/${row.original.client_id}`}
                        className="text-primary underline capitalize"
                    >
                        {displayNameInRightFormat(row.original.client_name)}
                    </Link>
                ),
            },
            {
                accessorKey: "date_of_birth",
                header: "DATE OF BIRTH",
                cell: ({ row }) => (
                    <span className="capitalize">
                        {row.original.date_of_birth
                            ? `${formatDate(
                                  `${row.original.date_of_birth}T00:00:00`,
                                  true
                              )}`
                            : "--"}
                    </span>
                ),
            },

            {
                accessorKey: "start_date",
                header: "POLICY START DATE",
                cell: ({ row }) => formatDate(row.original.start_date, true),
            },
            {
                accessorKey: "end_date",
                header: "POLICY END DATE",
                cell: ({ row }) => formatDate(row.original.end_date, true),
            },
            {
                accessorKey: "insurance_provider",
                header: "INSURANCE PROVIDER",
                cell: ({ row }) => (
                    <span className="capitalize">
                        {row.original.insurance_provider}
                    </span>
                ),
            },

            {
                accessorKey: "employee_name",
                header: "EMPLOYEE NAME",
                cell: ({ row }) => (
                    <span className="capitalize">
                        {row.original.employer_name}
                    </span>
                ),
            },
            {
                accessorKey: "insurance_member_id",
                header: "INSURACE MEMBER ID",
            },
            {
                accessorKey: "financial_assistance_end_date",
                header: "FAP end date",
                cell: ({ row }) =>
                    row.original.financial_assistance_end_date
                        ? formatDate(
                              row.original.financial_assistance_end_date,
                              true
                          )
                        : "--",
            },
            // {
            //     accessorKey: "more",
            //     header: "",
            //     cell: () => (
            //         <Dropdown>
            //             <DropdownTrigger>
            //                 <Button
            //                     variant="normal"
            //                     size="auto"
            //                     className="w-16 h-16 rounded-[16px] shadow-morebtn"
            //                 >
            //                     <MoreIcon />
            //                 </Button>
            //             </DropdownTrigger>
            //             <DropdownContent
            //                 width="auto"
            //                 className="dd-modal data-[data-radix-popper-content-wrapper]:z-[200]"
            //             >
            //                 <Button size="auto" variant="normal">
            //                     Mark as complete
            //                 </Button>
            //             </DropdownContent>
            //         </Dropdown>
            //     ),
            // },
        ],
        []
    );

    const customDateForSelect = [
        ...Object.values(ExpiryDateRange).map((item) => {
            return item;
        }),
    ];

    const handleCustomDateChange = (range: string) => {
        searchParams.set("expiry_date", range);
        setStartDate(null);
        setEndDate(null);
        setSearchParams(searchParams);
    };

    // onChange handler for page
    const handlePageChange = (page: number) => {
        searchParams.set("page", String(page));
        setSearchParams(searchParams);
    };

    const handleLimitChange = (limit: number) => {
        searchParams.set("limit", String(limit));
        setSearchParams(searchParams);
    };

    return (
        <div>
            <div className="p-24 bg-[#FBFBFB] mb-32">
                <div className="flex items-center justify-between mb-16 max-[768px]:flex-col max-[768px]:items-start max-[768px]:gap-y-24">
                    <h3 className="text-xxl font-bold">Expiring Policies</h3>
                    <Popover>
                        <PopoverTrigger asChild>
                            <Button
                                variant="normal"
                                size="auto"
                                className="px-16 shadow-active border-[0.2px] border-strokedark gap-x-8 h-32 text-sm data-[state=open]:border-primary"
                            >
                                {startDateFilter != null &&
                                endDateFilter !== null ? (
                                    <>
                                        {momentNotTz(startDateFilter).format(
                                            "MMM DD"
                                        )}{" "}
                                        -{" "}
                                        {momentNotTz(endDateFilter).format(
                                            "MMM DD"
                                        )}
                                    </>
                                ) : (
                                    <span>
                                        {makeStringFirstLetterCapital(
                                            removeEnumUnderscore(
                                                customDateFilter
                                            )
                                        )}
                                    </span>
                                )}
                                <ChevronDownIcon
                                    stroke={APP_COLORS.COLOR_BLACK}
                                />
                            </Button>
                        </PopoverTrigger>
                        <PopoverContent
                            align="end"
                            className="h-auto w-auto p-16 z-[50]"
                        >
                            <div className="flex gap-y-[14px]">
                                <div className="flex flex-col gap-x-10 mt-[6px] w-auto items-start">
                                    {customDateForSelect.map((range) => (
                                        <Button
                                            variant="normal"
                                            onClick={() =>
                                                handleCustomDateChange(range)
                                            }
                                            className={cn({
                                                "text-primary":
                                                    customDateFilter === range,
                                            })}
                                            key={range}
                                        >
                                            {makeStringFirstLetterCapital(
                                                removeEnumUnderscore(range)
                                            )}
                                        </Button>
                                    ))}
                                </div>
                                <div>
                                    <DatePicker
                                        selected={startDate}
                                        onChange={onChange}
                                        startDate={startDate}
                                        endDate={endDate}
                                        canSelectRange
                                        additionalCalendarClass="shadow-none"
                                        inline
                                    />
                                </div>
                            </div>
                        </PopoverContent>
                    </Popover>
                </div>
                <div className="grid grid-cols-2 gap-x-3 max-[768px]:grid-cols-1 max-[768px]:gap-y-16">
                    <Card classNames="bg-white p-24 border border-[#e7e7e7e8] h-[104px] rounded-r8 relative">
                        <div className="flex items-center gap-x-8">
                            <span className="uppercase text-xs font-medium">
                                Duration
                            </span>
                        </div>
                        <span className="text-xxl font-bold mt-8">
                            {startDateFilter != null &&
                            endDateFilter !== null ? (
                                <>
                                    {momentNotTz(startDateFilter).format(
                                        "MMM DD"
                                    )}{" "}
                                    -{" "}
                                    {momentNotTz(endDateFilter).format(
                                        "MMM DD"
                                    )}
                                </>
                            ) : (
                                <span>
                                    {makeStringFirstLetterCapital(
                                        removeEnumUnderscore(customDateFilter)
                                    )}
                                </span>
                            )}
                        </span>
                        <img
                            src={patternBg}
                            alt="pattern of boxes"
                            className="absolute right-[8px] h-[80px] w-[95px] -translate-y-1/2 top-1/2"
                        />
                    </Card>
                    <Card classNames="bg-white p-24 border border-[#e7e7e7e8] h-[104px] rounded-r8 relative">
                        <div className="flex items-center gap-x-8">
                            <span className="uppercase text-xs font-medium">
                                TOTAL POLICIES EXPIRING THIS PERIOD
                            </span>
                        </div>
                        <span className="text-xxl font-bold mt-8">
                            {data?.total_count || 0}
                        </span>
                        <img
                            src={patternBg}
                            alt="pattern of boxes"
                            className="absolute right-[8px] h-[80px] w-[95px] -translate-y-1/2 top-1/2"
                        />
                    </Card>
                </div>
            </div>
            <div className="mb-12">
                <SearchInput
                    containerClass="w-[40%] h-32 max-[768px]:w-full"
                    onChange={(e) => setSearchString(e.target.value)}
                    placeholder="Search by client name or Insurance member ID"
                />
            </div>
            {error && error?.response?.status !== 404 && (
                <ListState
                    isError
                    stateHelperText="Try reloading this page to solve this issue"
                    errorMsg={
                        error?.response?.data.message ||
                        `Cannot display expiring policies at this time please try again later`
                    }
                />
            )}
            {data && isSuccess && data?.data?.length === 0 && (
                <ListState
                    stateHelperText="Expiring policy list will appear here"
                    isError={false}
                    emptyMessage="There are no expiring policies"
                />
            )}
            {isLoading && (
                <Skeleton
                    type="table"
                    containerTestId="policy-list-loader"
                    count={6}
                />
            )}

            {data && data?.data?.length > 0 && isSuccess && !isLoading && (
                <Table
                    columns={columns}
                    data={data?.data}
                    setSortConfig={setSortConfig}
                    sortConfig={sortConfig}
                    sortableColumns={sortableColumns}
                    hasPagination={data.total_count > 20}
                    pagination={
                        <Pagination
                            totalCount={data.total_count}
                            totalPages={data.total_pages}
                            currentPage={pageFilter}
                            onPageChange={handlePageChange}
                            onLimitChange={handleLimitChange}
                        />
                    }
                />
            )}
        </div>
    );
}
