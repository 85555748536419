import * as React from "react";
import { ColumnDef } from "@tanstack/react-table";
import {
    Card,
    Dialog,
    DialogContent,
    Dropdown,
    DropdownContent,
    DropdownItem,
    DropdownTrigger,
    Table,
} from "@jhool-io/fe-components";
import { useParams, useSearchParams } from "react-router-dom";
import { format } from "date-fns";
import { useIsMutating } from "@tanstack/react-query";
import { formatDate, showMoneyInAppFormat } from "../../../../utils/helpers";
import ListState from "../../../../components/ListState/ListState";
import Skeleton from "../../../../components/Skeleton/Skeleton";
import Navbar from "../../../../components/Navbar/Navbar";
import { useFetchClientBasicAndPersonalInfo } from "../../../../hooks/queries/client";
import { IAppCustomDates } from "../../../../utils/types";
import useToast from "../../../../hooks/useToast";
import { useExportPdfs } from "../../../../hooks/queries";
import SendStatementOfAccountForm from "../SendClientStatementOfAccountForm/SendClientStatementOfAccountForm";
import {
    useFetchStatementOfAccount,
    useGetDownloadJobId,
} from "../../hooks/queries/reports.queries";
import {
    ClientStatementBreakdown,
    IStatementOfAccount,
} from "../../types/reports.types";
import FilterButton from "../../../../shared-ui/Buttons/FilterButton/FilterButton";
import { APP_COLORS } from "../../../../utils/constants";

const columns: ColumnDef<ClientStatementBreakdown>[] = [
    {
        accessorKey: "date_of_service",
        header: "DATE OF SERVICE",
        cell: ({ row }) => formatDate(row.original.date_of_service),
    },
    {
        accessorKey: "cpt_code",
        header: "CPT CODE",
        cell: ({ row }) => row.original.cpt_code || "--",
    },
    {
        accessorKey: "description",
        header: "DESCRIPTION",
        cell: ({ row }) =>
            `${row.original.description ? row.original.description : "--"}`,
    },
    {
        accessorKey: "insurance_paid",
        header: "Insurance Paid",
        cell: ({ row }) =>
            row.original.insurance_payment_amount
                ? showMoneyInAppFormat(row.original.insurance_payment_amount)
                : "--",
    },
    {
        accessorKey: "insurance_payment_date",
        header: "Date paid",
        cell: ({ row }) =>
            row.original.insurance_payment_date
                ? formatDate(row.original.insurance_payment_date)
                : "--",
    },
    {
        accessorKey: "coinsurance_paid",
        header: "Co-Insurance Paid",
        cell: ({ row }) =>
            row.original.coinsurance_payment_amount
                ? showMoneyInAppFormat(row.original.coinsurance_payment_amount)
                : "--",
    },
    {
        accessorKey: "coinsurance_payment_date",
        header: "Date paid",
        cell: ({ row }) =>
            row.original.coinsurance_payment_date
                ? formatDate(row.original.coinsurance_payment_date)
                : "--",
    },
    {
        accessorKey: "pr100_refunded_payment_amount",
        header: "PR100 PAID",
        cell: ({ row }) =>
            row.original.pr100_refunded_payment_amount
                ? showMoneyInAppFormat(
                      row.original.pr100_refunded_payment_amount
                  )
                : "--",
    },
    {
        accessorKey: "pr100_refunded_payment_date",
        header: "Date paid",
        cell: ({ row }) =>
            row.original.pr100_refunded_payment_date
                ? formatDate(row.original.pr100_refunded_payment_date)
                : "--",
    },
];

export default function ClientStatementOfAccount() {
    const [isDownloading, setIsDownloading] = React.useState(false);
    const [jobId, setJobId] = React.useState<string | null>(null);
    const [showLoader, setShowLoader] = React.useState(false);
    const [showForm, setShowForm] = React.useState(false);

    const params = useParams();

    const { clientId } = params;

    const [searchParams, setSearchParams] = useSearchParams();

    const clientDetails = useFetchClientBasicAndPersonalInfo(
        clientId as string
    );

    const { data, isLoading, error, isSuccess } = useFetchStatementOfAccount(
        {
            action: "preview",
            client_ids: [clientId as string],
            insurance_provider_id:
                searchParams.get("insurance_provider") || undefined,
            custom_date:
                (searchParams.get("custom_date") as IAppCustomDates) ||
                undefined,
            from_date: searchParams.get("from_date")
                ? format(
                      new Date(searchParams.get("from_date") as string),
                      "yyyy-MM-dd"
                  )
                : null,
            to_date: searchParams.get("to_date")
                ? format(
                      new Date(searchParams.get("to_date") as string),
                      "yyyy-MM-dd"
                  )
                : null,
        },
        true
    );

    const statementDownload = useGetDownloadJobId(
        {
            action: "download",
            client_ids: [clientId as string],
            insurance_provider_id:
                searchParams.get("insurance_provider") || undefined,
            custom_date:
                (searchParams.get("custom_date") as IAppCustomDates) ||
                undefined,
            from_date: searchParams.get("from_date")
                ? format(
                      new Date(searchParams.get("from_date") as string),
                      "yyyy-MM-dd"
                  )
                : null,
            to_date: searchParams.get("to_date")
                ? format(
                      new Date(searchParams.get("to_date") as string),
                      "yyyy-MM-dd"
                  )
                : null,
            only_coins_outstanding: searchParams.get("only_coins") === "yes",
        },
        isDownloading
    );
    const toast = useToast();

    const exportPdf = useExportPdfs(
        { export_job_id: jobId as string, pdf_type: "statement_of_account" },
        Boolean(jobId) && showLoader
    );

    const statementData = () => {
        if (data?.data) {
            return Object.values(
                data.data[0]?.client_statements
            ) as IStatementOfAccount[];
        }

        return [];
    };

    const getErrorAndLoadingStates = () => {
        if (error) {
            return "0";
        }

        if (isLoading) {
            return (
                <Skeleton
                    containerTestId="overview-loader"
                    height={20}
                    width={20}
                />
            );
        }

        return "";
    };

    const removeOnlyCoinsParam = () => {
        searchParams.delete("only_coins");
        setSearchParams(searchParams);
    };

    const handleDownload = () => {
        setIsDownloading(true);
        if (statementDownload.error) {
            toast.toast({
                mode: "error",
                message:
                    (statementDownload.error.response?.data
                        .message as string) ||
                    "Could not download statement of account.",
            });
        }

        setShowLoader(true);
    };

    const sendStatementIsMutating = useIsMutating(["send-statement"]);

    let clientName;
    if (clientDetails.error) clientName = "";
    else if (clientDetails.isLoading) clientName = "";
    else if (clientDetails.data)
        clientName = `${clientDetails.data.data.first_name} ${clientDetails.data?.data.last_name}`;

    React.useEffect(() => {
        // Check if the exportPdf query is currently loading
        const isExportPdfLoading = exportPdf.isLoading;

        if (statementDownload.isSuccess) {
            setIsDownloading(false);
        }

        // Check if there's a job ID and the exportPdf query is not loading
        if (jobId && !isExportPdfLoading) {
            setShowLoader(false);
            setJobId(null);
        }
    }, [statementDownload.isSuccess, jobId, exportPdf.isLoading]);

    React.useEffect(() => {
        if (statementDownload.data?.data.export_job_id) {
            const timeoutId = setTimeout(() => {
                setJobId(statementDownload.data?.data.export_job_id);
            }, 4000);

            return () => {
                clearTimeout(timeoutId);
            };
        }

        return () => {};
    }, [statementDownload.data?.data.export_job_id, statementDownload.isStale]);

    return (
        <>
            <Navbar title={`${clientName}'s Statement of Account`} />
            <div className="page">
                <Dialog open={showLoader}>
                    <DialogContent
                        title="Download in progress"
                        variant="center"
                        handleCloseDialog={() => setShowLoader(false)}
                        showFooter={false}
                    >
                        <div className="loader">
                            <div className="loader-icon" />
                            <div className="loader-text">
                                Please wait while the download is in progress.
                            </div>
                        </div>
                    </DialogContent>
                </Dialog>
                <Dialog open={showForm}>
                    <DialogContent
                        title="Send statement of account"
                        showFooter
                        saveText="Send email"
                        cancelText="Cancel"
                        variant="center"
                        handleCloseDialog={() => {
                            setShowForm(false);
                            removeOnlyCoinsParam();
                        }}
                        onSaveClick={removeOnlyCoinsParam}
                        submitBtnFormValue="send-statement"
                        isCancelBtnDisabled={sendStatementIsMutating > 0}
                        isSubmitBtnDisabled={sendStatementIsMutating > 0}
                        backBtnFormValue="send-coins-attement"
                        canBackButtonSubmit
                        backText="Send email(Coinsurance due only)"
                        isBackBtnDisabled={sendStatementIsMutating > 0}
                        onBackClick={() => {
                            searchParams.set("only_coins", "yes");
                            setSearchParams(searchParams);
                        }}
                        showBackBtn
                    >
                        <SendStatementOfAccountForm
                            defaultEmail={clientDetails.data?.data.email}
                            onFormSubmit={() => {
                                setShowForm(false);
                                removeOnlyCoinsParam();
                            }}
                        />
                    </DialogContent>
                </Dialog>
                {data?.data && data?.data[0].client_statements && (
                    <div className="flex justify-end mb-16">
                        <Dropdown>
                            <DropdownTrigger asChild>
                                <FilterButton
                                    classNames="bg-primary normal-case text-white *:stroke-white"
                                    text="Actions"
                                    stroke={APP_COLORS.COLOR_WHITE}
                                />
                            </DropdownTrigger>

                            <DropdownContent width="auto" align="end">
                                <>
                                    <DropdownItem
                                        onClick={() => {
                                            removeOnlyCoinsParam();
                                            handleDownload();
                                        }}
                                    >
                                        Download Statement
                                    </DropdownItem>
                                    <DropdownItem
                                        onClick={() => {
                                            searchParams.set(
                                                "only_coins",
                                                "yes"
                                            );
                                            setSearchParams(searchParams);
                                            handleDownload();
                                        }}
                                    >
                                        Download Statement (Coinsurance due
                                        only)
                                    </DropdownItem>

                                    <DropdownItem
                                        onClick={() => setShowForm(true)}
                                    >
                                        Send Statement
                                    </DropdownItem>
                                </>
                            </DropdownContent>
                        </Dropdown>
                    </div>
                )}
                <div className="grid grid-cols-[repeat(3,minmax(320px,1fr))] overflow-x-auto overflow-y-hidden gap-x-12 mb-32 bg-white rounded-r8 px-12 py-16">
                    <Card classNames="bg-card-bg flex flex-col gap-y-8 px-16 py-12 border border-[#0974461e]">
                        <div className="font-medium uppercase text-xs">
                            Total amount due
                        </div>
                        <div className="font-bold text-xxl">
                            {getErrorAndLoadingStates() ||
                                showMoneyInAppFormat(
                                    statementData()[0]?.statement_summary
                                        .total_amount_outstanding
                                )}
                        </div>
                    </Card>
                    <Card classNames="bg-card-bg flex flex-col gap-y-8 px-16 py-12 border border-[#0974461e]">
                        <div className="font-medium uppercase text-xs">
                            Insurance due
                        </div>
                        <div className="font-bold text-xxl">
                            {getErrorAndLoadingStates() ||
                                showMoneyInAppFormat(
                                    statementData()[0]?.statement_summary
                                        .amount_outstanding_ins
                                )}
                        </div>
                    </Card>
                    <Card classNames=" bg-card-bg flex flex-col gap-y-8  px-16 py-12 border border-[#0974461e]">
                        <div className="font-medium uppercase text-xs">
                            Co-Insurance due
                        </div>
                        <div className="font-bold text-xxl">
                            {getErrorAndLoadingStates() ||
                                showMoneyInAppFormat(
                                    statementData()[0]?.statement_summary
                                        .amount_outstanding_coins
                                )}
                        </div>
                    </Card>
                </div>

                {isLoading ? (
                    <Skeleton
                        containerTestId="statement-list-loader"
                        count={3}
                        type="table"
                    />
                ) : null}

                {error && error.response?.status !== 404 && (
                    <ListState
                        isError
                        stateHelperText="Try reloading this page to solve this issue"
                        errorMsg={
                            error?.response?.data.message ||
                            `Cannot display statement of account breakdown at this time please try again later`
                        }
                    />
                )}

                {data && isSuccess && statementData().length === 0 && (
                    <ListState
                        isError={false}
                        emptyMessage={data.message}
                        stateHelperText="Statement of account will appear here when available"
                    />
                )}

                {data &&
                    isSuccess &&
                    statementData()[0]?.statement_breakdown && (
                        <Table
                            columns={columns}
                            data={statementData()[0]?.statement_breakdown}
                        />
                    )}
            </div>
        </>
    );
}
