/* eslint-disable react/no-unstable-nested-components */
import { ColumnDef, Row } from "@tanstack/react-table";
import * as React from "react";
import {
    Button,
    Dialog,
    DialogContent,
    Dropdown,
    DropdownContent,
    DropdownItem,
    DropdownTrigger,
    Pagination,
    SearchInput,
    Table,
    Tag,
} from "@jhool-io/fe-components";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { useIsMutating } from "@tanstack/react-query";
import {
    IProvider,
    ProviderRole,
    ProviderStatus,
} from "../../types/providers.types";
import { APP_COLORS, LISTS_DEFAULT_LIMIT } from "../../../../utils/constants";
import {
    cn,
    displayNameInRightFormat,
    makeStringFirstLetterCapital,
    removeEnumUnderscore,
    truncateString,
} from "../../../../utils/helpers";
import { UserPermisions } from "../../../../utils/types/user";
import MoreButton from "../../../../shared-ui/Buttons/MoreButton/MoreButton";
import ListState from "../../../../components/ListState/ListState";
import { useDebounce } from "../../../../hooks/helpers";
import { useFetchProviders } from "../../hooks/providers.queries";
import Skeleton from "../../../../components/Skeleton/Skeleton";
import EditProvider from "../EditProvider/EditProvider";
import { ProviderCredential } from "../../../clients/types/clients.types";
import FilterButton from "../../../../shared-ui/Buttons/FilterButton/FilterButton";
import CheckPrimaryColorIcon from "../../../../components/Icons/CheckPrimaryColor";
import Navbar from "../../../../components/Navbar/Navbar";
import MobileListItem from "../../../../shared-ui/MobileListItem/MobileListItem";
import EditIcon from "../../../../components/Icons/Edit";
import { useAppSelector } from "../../../../hooks/useRedux";

export default function ProvidersList() {
    const [showForm, setShowForm] = React.useState(false);
    const [selectedProviderId, setSelectedProviderId] = React.useState<
        string | null
    >(null);
    const [searchParams, setSearchParams] = useSearchParams();
    const [sortConfig, setSortConfig] = React.useState<{
        key: string;
        direction: string;
    }>({
        key: "",
        direction: "",
    });

    const { practice } = useAppSelector((state) => state.userPractice);

    const sortableColumns = [
        "provider_name",
        "contact",
        "email",
        "no_of_clients",
        "no_of_supervisees",
        "supervisor_name",
    ];

    const handleConfigAttribute = () => {
        if (sortableColumns.includes(sortConfig.key)) {
            return sortConfig.key;
        }

        return undefined;
    };

    const searchString = searchParams.get("search") || "";
    const statusFilter = searchParams.get("status") || undefined;
    const roleFilter = searchParams.get("role") || undefined;
    const credentialFilter = searchParams.get("credential") || undefined;
    const pageFilter = Number(searchParams.get("page")) || 1;
    const limitFilter =
        Number(searchParams.get("limit")) || LISTS_DEFAULT_LIMIT;

    const name = useDebounce(searchString, 500);

    const { data, isLoading, isSuccess, error } = useFetchProviders({
        page: pageFilter,
        limit: limitFilter,
        status: statusFilter,
        role: roleFilter || "",
        search_string: name,
        credential: credentialFilter,
        sort_attribute:
            searchParams.get("sort_attr") || handleConfigAttribute(),
        sort_order: searchParams.get("sort_order") || sortConfig.direction,
    });

    // Get provider list count
    let providersCount;
    if (error) providersCount = "";
    else if (isLoading) providersCount = "";
    else if (data?.data && data?.data.length === 0) providersCount = 0;
    else if (data?.data && data?.data.length > 0)
        providersCount = data.total_count;

    const isEditMutationRunning = useIsMutating(["update-provider-info"]);

    // Details for a single provider
    const providerDetails = data?.data.find((provider) => {
        return provider.provider_id === selectedProviderId;
    });

    const credentialOptionsForSelect = [
        ...Object.values(ProviderCredential).map((status) => {
            return status;
        }),
    ];

    const statusOptionsForSelect = [
        ...Object.values(ProviderStatus).map((status) => {
            return status;
        }),
    ];

    const rolesForSelect = [
        ...Object.values(ProviderRole).map((status) => {
            return status;
        }),
    ];

    const navigate = useNavigate();

    const handleRowClick = (row: Row<IProvider>) => {
        navigate(`/providers/${row.original.provider_id as string}`);
    };

    const handleSetProviderToEdit = (id: string) => {
        setShowForm(true);
        setSelectedProviderId(id);
    };

    // onChange handler for search input
    const handleSetSearchString = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.value === "") searchParams.delete("search");
        else searchParams.set("search", e.target.value);

        setSearchParams(searchParams);
    };

    const handleStatusChange = (value: string) => {
        if (value === "") searchParams.delete("status");
        else searchParams.set("status", value);
        searchParams.set("page", "1");
        setSearchParams(searchParams);
    };

    const handleCredentialChange = (value: string) => {
        if (value === "") searchParams.delete("credential");
        else searchParams.set("credential", value);
        searchParams.set("page", "1");
        setSearchParams(searchParams);
    };

    const handleRoleChange = (value: string) => {
        if (value === "") searchParams.delete("role");
        else searchParams.set("role", value);
        searchParams.set("page", "1");
        setSearchParams(searchParams);
    };

    // onChange handler for page
    const handlePageChange = (page: number) => {
        searchParams.set("page", String(page));
        setSearchParams(searchParams);
    };

    // onChange handler for limit
    const handleLimitChange = (limit: number) => {
        searchParams.set("limit", String(limit));
        setSearchParams(searchParams);
    };

    // Provider status tag
    const getStatusTag = (status: ProviderStatus) => {
        let bgColor: string;
        let textColor: string;

        switch (status) {
            case ProviderStatus.ACTIVE:
                textColor = "#00563E";
                bgColor = "rgba(204, 250, 233, 0.50)";
                break;
            case ProviderStatus.INACTIVE:
                textColor = "#981F41";
                bgColor = "rgba(251, 199, 198, 0.50)";
                break;
            default:
                bgColor = "rgba(245, 245, 245, 0.50)";
                textColor = APP_COLORS.COLOR_BLACK;
                break;
        }

        return (
            <Tag
                textColor={textColor}
                bgColor={bgColor}
                title={makeStringFirstLetterCapital(status)}
                className="capitalize w-auto h-auto text-nowrap px-8 py-4 rounded-r4"
            />
        );
    };

    const getCredentialTag = (credential: ProviderCredential) => {
        let bgColor: string;
        let textColor: string;

        switch (credential) {
            case ProviderCredential.LMSW:
            case ProviderCredential.MHCLP:
                textColor = "#634D17";
                bgColor = "rgba(247, 229, 164, 0.50)";
                break;
            case ProviderCredential.LMSWA:
            case ProviderCredential.LMHCA:
            case ProviderCredential.PSYDA:
            case ProviderCredential.PHDA:
            case ProviderCredential.LCSWA:
                textColor = "#165574";
                bgColor = "rgba(172, 222, 250, 0.50)";
                break;
            case ProviderCredential.LMHCB:
            case ProviderCredential.PSYDB:
            case ProviderCredential.PHDB:
            case ProviderCredential.LCSWB:
                textColor = "#981F41";
                bgColor = "rgba(251, 199, 198, 0.50)";
                break;
            case ProviderCredential.LMHCC:
            case ProviderCredential.PSYDC:
            case ProviderCredential.PHDC:
            case ProviderCredential.LCSWC:
                textColor = "#4B0082";
                bgColor = "rgba(221, 160, 221, 0.50)";
                break;
            default:
                textColor = "#00563E";
                bgColor = "rgba(204, 250, 233, 0.50)";
                break;
        }

        return (
            <Tag
                title={credential}
                textColor={textColor}
                bgColor={bgColor}
                className="font-normal px-8 py-4 h-24 rounded-r4 uppercase"
            />
        );
    };

    const columns: ColumnDef<IProvider>[] = [
        {
            accessorKey: "provider_name",
            header: "PROVIDER'S NAME",
            cell: ({ row }) =>
                displayNameInRightFormat(row.original.provider_name),
        },
        {
            accessorKey: "credential",
            header: "CREDENTIAL",
            cell: ({ row }) =>
                getCredentialTag(row.original.credential) || "--",
        },
        {
            accessorKey: "contact",
            header: "CONTACT",
            cell: ({ row }) => row.original.provider_contact_number || "--",
        },
        {
            accessorKey: "email",
            header: "EMAIL",
            cell: ({ row }) => row.original.email || "--",
        },
        {
            accessorKey: "no_of_clients",
            header: "NO OF CLIENTS",
            cell: ({ row }) => row.original.no_of_active_clients || "--",
        },
        {
            accessorKey: "npi",
            header: "NPI",
            cell: ({ row }) => row.original.npi || "--",
        },
        {
            accessorKey: "no_of_supervisees",
            header: "NO OF SUPERVISEES",
            cell: ({ row }) => row.original.no_of_supervisees || "--",
        },
        {
            accessorKey: "supervisor_name",
            header: "SUPERVISOR NAME",
            cell: ({ row }) =>
                row.original.supervisor_name
                    ? displayNameInRightFormat(row.original.supervisor_name)
                    : "--",
        },
        {
            accessorKey: "status",
            header: "PROVIDER STATUS",
            cell: ({ row }) => getStatusTag(row.original.status),
        },
        {
            accessorKey: "provider_id",
            header: "",
            cell: ({ row }) =>
                practice?.permissions?.includes(
                    UserPermisions.PROVIDER_INFO_UPDATE
                ) && (
                    <Dropdown>
                        <DropdownTrigger asChild>
                            <MoreButton />
                        </DropdownTrigger>
                        <DropdownContent width="auto" align="end">
                            <DropdownItem
                                onClick={(e) => {
                                    e.stopPropagation();
                                    handleSetProviderToEdit(
                                        row.original.provider_id
                                    );
                                }}
                            >
                                Edit provider info
                            </DropdownItem>
                        </DropdownContent>
                    </Dropdown>
                ),
        },
    ];

    return (
        <>
            <Dialog open={showForm}>
                <DialogContent
                    title="Edit provider info"
                    showFooter
                    saveText="Save changes"
                    cancelText="Cancel"
                    variant="center"
                    handleCloseDialog={() => setShowForm(false)}
                    submitBtnFormValue="update-provider-info"
                    isCancelBtnDisabled={isEditMutationRunning > 0}
                    isSubmitBtnDisabled={isEditMutationRunning > 0}
                >
                    <EditProvider
                        onFormSubmit={() => setShowForm(false)}
                        providerId={selectedProviderId as string}
                        defaultNPI={providerDetails?.npi as string}
                    />
                </DialogContent>
            </Dialog>
            <Navbar title={`Providers List (${providersCount})`} />
            <div className="page">
                <div className="flex flex-col gap-y-24 mb-32 bg-white p-16 rounded-r8">
                    <SearchInput
                        containerClass="w-[400px]"
                        placeholder="Search by provider"
                        onChange={handleSetSearchString}
                        defaultValue={searchString}
                    />
                    <div className="flex gap-x-12 gap-y-24 flex-wrap">
                        <Dropdown>
                            <DropdownTrigger asChild>
                                <FilterButton
                                    text={
                                        statusFilter
                                            ? truncateString(
                                                  removeEnumUnderscore(
                                                      statusFilter
                                                  )
                                              )
                                            : "Status"
                                    }
                                />
                            </DropdownTrigger>
                            <DropdownContent width="auto">
                                <DropdownItem
                                    className={cn(
                                        "flex gap-x-8 items-center text-xs font-medium",
                                        {
                                            "text-primary": !statusFilter,
                                        }
                                    )}
                                    onClick={() => handleStatusChange("")}
                                >
                                    All
                                    {!statusFilter ? (
                                        <CheckPrimaryColorIcon />
                                    ) : null}
                                </DropdownItem>
                                {statusOptionsForSelect.map((status) => (
                                    <DropdownItem
                                        className={cn(
                                            "flex gap-x-8 items-center capitalize text-xs font-medium",
                                            {
                                                "text-primary":
                                                    statusFilter === status,
                                            }
                                        )}
                                        key={status}
                                        onClick={() =>
                                            handleStatusChange(status)
                                        }
                                    >
                                        {makeStringFirstLetterCapital(status)}
                                        {statusFilter === status ? (
                                            <CheckPrimaryColorIcon />
                                        ) : null}
                                    </DropdownItem>
                                ))}
                            </DropdownContent>
                        </Dropdown>
                        <Dropdown>
                            <DropdownTrigger asChild>
                                <FilterButton
                                    text={
                                        credentialFilter
                                            ? truncateString(
                                                  removeEnumUnderscore(
                                                      credentialFilter
                                                  )
                                              )
                                            : "Credential"
                                    }
                                />
                            </DropdownTrigger>
                            <DropdownContent
                                width="auto"
                                className="overflow-y-auto"
                            >
                                <DropdownItem
                                    className={cn(
                                        "flex gap-x-8 items-center text-xs font-medium",
                                        {
                                            "text-primary": !credentialFilter,
                                        }
                                    )}
                                    onClick={() => handleCredentialChange("")}
                                >
                                    All
                                    {!credentialFilter ? (
                                        <CheckPrimaryColorIcon />
                                    ) : null}
                                </DropdownItem>
                                {credentialOptionsForSelect.map(
                                    (credential) => (
                                        <DropdownItem
                                            className={cn(
                                                "flex gap-x-8 items-center capitalize text-xs font-medium",
                                                {
                                                    "text-primary":
                                                        credentialFilter ===
                                                        credential,
                                                }
                                            )}
                                            key={credential}
                                            onClick={() =>
                                                handleCredentialChange(
                                                    credential
                                                )
                                            }
                                        >
                                            {makeStringFirstLetterCapital(
                                                credential
                                            )}
                                            {credentialFilter === credential ? (
                                                <CheckPrimaryColorIcon />
                                            ) : null}
                                        </DropdownItem>
                                    )
                                )}
                            </DropdownContent>
                        </Dropdown>
                        <Dropdown>
                            <DropdownTrigger asChild>
                                <FilterButton
                                    text={
                                        roleFilter
                                            ? truncateString(
                                                  removeEnumUnderscore(
                                                      roleFilter
                                                  )
                                              )
                                            : "Role"
                                    }
                                />
                            </DropdownTrigger>
                            <DropdownContent width="auto">
                                <DropdownItem
                                    className={cn(
                                        "flex gap-x-8 items-center text-xs font-medium",
                                        {
                                            "text-primary": !roleFilter,
                                        }
                                    )}
                                    onClick={() => handleRoleChange("")}
                                >
                                    All
                                    {!roleFilter ? (
                                        <CheckPrimaryColorIcon />
                                    ) : null}
                                </DropdownItem>
                                {rolesForSelect.map((role) => (
                                    <DropdownItem
                                        className={cn(
                                            "flex gap-x-8 items-center capitalize text-xs font-medium",
                                            {
                                                "text-primary":
                                                    roleFilter === role,
                                            }
                                        )}
                                        key={role}
                                        onClick={() => handleRoleChange(role)}
                                    >
                                        {makeStringFirstLetterCapital(role)}
                                        {roleFilter === role ? (
                                            <CheckPrimaryColorIcon />
                                        ) : null}
                                    </DropdownItem>
                                ))}
                            </DropdownContent>
                        </Dropdown>
                    </div>
                </div>
                {isLoading && (
                    <Skeleton
                        type="table"
                        containerTestId="userslist-loader"
                        count={6}
                    />
                )}
                {error && (
                    <ListState
                        isError
                        stateHelperText="Try reloading this page to solve this issue"
                        errorMsg={
                            error?.response?.data.message ||
                            `Cannot display providers list at this time please try again later`
                        }
                    />
                )}
                {data && isSuccess && data.data.length === 0 && (
                    <ListState
                        stateHelperText="Providers list will appear here once providers are added"
                        isError={false}
                        emptyMessage="No providers added yet"
                    />
                )}
                {isSuccess && data && data.data.length > 0 && (
                    <>
                        <div className="hidden sm:block">
                            <Table
                                data={data.data}
                                columns={columns}
                                setSortConfig={setSortConfig}
                                sortConfig={sortConfig}
                                isHeaderFixed
                                sortableColumns={sortableColumns}
                                hasPagination={data.total_count > 20}
                                handleRowClick={handleRowClick}
                                pagination={
                                    <Pagination
                                        totalCount={data.total_count}
                                        totalPages={data.total_pages}
                                        currentPage={pageFilter}
                                        onPageChange={handlePageChange}
                                        limit={limitFilter}
                                        onLimitChange={handleLimitChange}
                                    />
                                }
                            />
                        </div>
                        <div role="table" className="block sm:hidden">
                            <div className="flex flex-col gap-y-12">
                                {data.data.map((provider) => (
                                    <MobileListItem
                                        key={provider.provider_id}
                                        topChildren={
                                            <div className="flex items-center gap-x-8 text-base font-semibold capitalize">
                                                {displayNameInRightFormat(
                                                    provider.provider_name
                                                )}
                                                <span className=" w-1 h-1 rounded-full bg-gray" />
                                                <span className=" text-sm font-medium">
                                                    {provider.credential}
                                                </span>
                                            </div>
                                        }
                                        bottomChildren={
                                            <Link
                                                className="flex flex-col gap-y-8 w-full items-start"
                                                to={`/providers/${provider.provider_id}`}
                                            >
                                                <div className="flex items-center w-full text-left gap-x-4">
                                                    <span className="w-1/2 text-gray font-semibold text-xs">
                                                        Contact:
                                                    </span>
                                                    <span className="w-1/2 font-semibold text-xs">
                                                        {
                                                            provider.provider_contact_number
                                                        }
                                                    </span>
                                                </div>

                                                <div className="flex items-center w-full text-left gap-x-4">
                                                    <span className="w-1/2 text-gray font-semibold text-xs">
                                                        Email:
                                                    </span>
                                                    <span className="w-1/2 font-semibold text-xs">
                                                        {provider.email}
                                                    </span>
                                                </div>

                                                <div className="flex items-center w-full text-left gap-x-4">
                                                    <span className="w-1/2 text-gray font-semibold text-xs">
                                                        No of clients:
                                                    </span>
                                                    <span className="w-1/2 font-semibold text-xs">
                                                        {
                                                            provider.no_of_active_clients
                                                        }
                                                    </span>
                                                </div>

                                                <div className="flex items-center w-full text-left gap-x-4">
                                                    <span className="w-1/2 text-gray font-semibold text-xs">
                                                        Provider status:
                                                    </span>

                                                    {getStatusTag(
                                                        provider.status
                                                    )}
                                                </div>

                                                <div className="flex items-center w-full text-left gap-x-4">
                                                    <span className="w-1/2 text-gray font-semibold text-xs">
                                                        NPI:
                                                    </span>
                                                    <span className=" flex items-center w-1/2 font-semibold text-xs">
                                                        {provider.npi}

                                                        <Button
                                                            variant="normal"
                                                            onClick={(e) => {
                                                                e.stopPropagation();
                                                                handleSetProviderToEdit(
                                                                    provider.provider_id
                                                                );
                                                            }}
                                                        >
                                                            <EditIcon />
                                                        </Button>
                                                    </span>
                                                </div>
                                                {provider.is_supervisor && (
                                                    <div className="flex items-center w-full text-left gap-x-4">
                                                        <span className="w-1/2 text-gray font-semibold text-xs">
                                                            No of supervisees:
                                                        </span>
                                                        <span className=" w-1/2 font-semibold text-xs">
                                                            {
                                                                provider.no_of_supervisees
                                                            }
                                                        </span>
                                                    </div>
                                                )}
                                                {provider.supervisor_name && (
                                                    <div className="flex items-center w-full text-left gap-x-4">
                                                        <span className="w-1/2 text-gray font-semibold text-xs">
                                                            Supervisor name:
                                                        </span>
                                                        <span className=" w-1/2 font-semibold text-xs">
                                                            {provider.supervisor_name
                                                                ? displayNameInRightFormat(
                                                                      provider.supervisor_name
                                                                  )
                                                                : "--"}
                                                        </span>
                                                    </div>
                                                )}
                                            </Link>
                                        }
                                    />
                                ))}
                            </div>
                        </div>
                    </>
                )}
            </div>
        </>
    );
}
