/* eslint-disable react/no-unstable-nested-components */
import * as React from "react";
import { Button, Card, SearchInput, Table, Tag } from "@jhool-io/fe-components";
import { useNavigate, useSearchParams } from "react-router-dom";
import { ColumnDef, Row } from "@tanstack/react-table";
import { getDaysInMonth } from "date-fns";
import patternBg from "../../../../assets/images/patternbg.png";
import {
    displayNameInRightFormat,
    formatDate,
    makeStringFirstLetterCapital,
    removeEnumUnderscore,
    showMoneyInAppFormat,
} from "../../../../utils/helpers";
import { useFetchBillingAdminDashboardList } from "../../../../hooks/queries/billing";
import {
    BillingStatus,
    IBillingDashboardInvoiceBreakdown,
    IBillingDashboardUnmatchedPayments,
    PaymentStatus,
} from "../../../../utils/types/billing";
import CopyIcon from "../../../../components/Icons/Copy";
import { copyToClipboard } from "../../../../utils/helpers/copy";
import Skeleton from "../../../../components/Skeleton/Skeleton";
import ListState from "../../../../components/ListState/ListState";
import { useDebounce } from "../../../../hooks/helpers";
import {
    BillingSummaryCardsFilters,
    billingPaymentMonths,
    getTimeRangeFromFilterValue,
} from "../../helpers/billing.helpers";
import { useFetchDiagnosisCodes } from "../../../../hooks/queries";
import { useExportBillingSummaryReport } from "../../hooks/queries/billing.queries";
import useToast from "../../../../hooks/useToast";

const getTimeRangeForMonthFromFilterValue = (
    month: string,
    breakdownYear: string
) => {
    const currentFilterYear = breakdownYear.split("-")[0];
    const lastDayOfMonth = getDaysInMonth(
        new Date(
            Number(currentFilterYear),
            billingPaymentMonths.indexOf(month || "")
        )
    );
    const monthIndex =
        billingPaymentMonths.indexOf(month) + 1 < 10
            ? `0${billingPaymentMonths.indexOf(month) + 1}`
            : billingPaymentMonths.indexOf(month) + 1;

    return {
        fromDate: `${currentFilterYear}-${monthIndex}-01`,
        toDate: `${currentFilterYear}-${monthIndex}-${lastDayOfMonth}`,
    };
};

export default function BillingSummaryBreakdown() {
    const [searchParams, setSearchParams] = useSearchParams();
    const [clickedBillTransactionId, setClickedBillTransactionId] =
        React.useState<string | null>(null);

    const [sortConfig, setSortConfig] = React.useState<{
        key: string;
        direction: string;
    }>({
        key: "",
        direction: "",
    });

    const sortableColumns = [
        "date_of_service",
        "client_name",
        "provider_name",
        "payment_date",
        "payment_amount",
        "non_closed_bill",
        "coinsurance_charge",
        "collected",
        "insurance_charge",
        "claim_id",
        "dob",
        "cpt_code",
        "status",
    ];

    const handleConfigAttribute = () => {
        if (sortableColumns.includes(sortConfig.key)) {
            return sortConfig.key as string;
        }

        return undefined;
    };

    const view = searchParams.get("view") || "";
    const typeFilter = searchParams.get("type") || "coinsurance";
    const filter = searchParams.get("filter") || BillingSummaryCardsFilters[0];
    const insuranceProvider = searchParams.get("insurance_provider") || "";
    const month = searchParams.get("month") || "";
    const providerName = searchParams.get("provider") || "";
    const clientFilter = searchParams.get("client") || "";
    const { toDate } = getTimeRangeFromFilterValue(filter);
    const amount = Number(searchParams.get("amount")) || 0;
    const credit = Number(searchParams.get("credit")) || 0;
    const refund = Number(searchParams.get("refund")) || 0;
    const count = Number(searchParams.get("count")) || 0;
    const [isExporting, setIsExporting] = React.useState(false);
    const doesViewHasClaimString = /claims/i.test(view);

    const navigate = useNavigate();
    const toast = useToast();
    const debouncedClientFilter = useDebounce(clientFilter, 500);
    const debouncedProviderName = useDebounce(providerName, 500);

    const monthValues = getTimeRangeForMonthFromFilterValue(
        month || "",
        toDate
    );

    const { data, error, isLoading, isSuccess } =
        useFetchBillingAdminDashboardList(
            {
                view,
                type: insuranceProvider === "self_pay" ? undefined : typeFilter,
                show_invoice_breakdown: true,
                from_date: monthValues.fromDate,
                to_date: monthValues.toDate,
                insurance_provider_id: insuranceProvider,
                search_string: debouncedClientFilter,
                provider_name: debouncedProviderName,
                sort_attribute:
                    searchParams.get("sort_attr") || handleConfigAttribute(),
                sort_order:
                    searchParams.get("sort_order") || sortConfig.direction,
            },
            Boolean(view) && Boolean(month)
        );

    const exportQuery = useExportBillingSummaryReport(
        {
            view,
            type: insuranceProvider === "self_pay" ? undefined : typeFilter,
            from_date: monthValues.fromDate,
            to_date: monthValues.toDate,
            insurance_provider_id: insuranceProvider,
            client_name: debouncedClientFilter,
            provider_name: debouncedProviderName,
        },
        isExporting
    );

    const diagnosisCodes = useFetchDiagnosisCodes();
    const getCardTitles = () => {
        let cardTwoTitle;
        let cardThreeTitle;

        if (view.includes("claims")) {
            cardTwoTitle = "Total Amount";
            cardThreeTitle = `TOTAL ${removeEnumUnderscore(view)}`;
        }

        if (view === "amount_billed") {
            cardTwoTitle = "Total Amount";
            cardThreeTitle = `TOTAL bills`;
        }

        if (view === "amount_collected") {
            cardTwoTitle = "Total Amount Collected";
            cardThreeTitle = `TOTAL payments collected `;
        }

        if (view === "amount_outstanding") {
            cardTwoTitle = "Total Amount";
            cardThreeTitle = `TOTAL bills`;
        }

        if (view === "unmatched_payments") {
            cardTwoTitle = "Total unmatched payment";
            cardThreeTitle = `Total unmatched payment`;
        }

        if (view === "pr100_collected") {
            cardTwoTitle = "Total PR100 Collected";
            cardThreeTitle = `TOTAL PR100 Payments`;
        }
        return { cardTwoTitle, cardThreeTitle };
    };

    const handleClientFilterChange = (
        e: React.ChangeEvent<HTMLInputElement>
    ) => {
        if (e.target.value === "") searchParams.delete("client");
        else searchParams.set("client", e.target.value);
        setSearchParams(searchParams);
    };

    const handleProviderFilterChange = (
        e: React.ChangeEvent<HTMLInputElement>
    ) => {
        if (e.target.value === "") searchParams.delete("provider");
        else searchParams.set("provider", e.target.value);
        setSearchParams(searchParams);
    };

    // function to handle copy transaction ID
    const handleCopyClick = (clickedItemTransactionid: string) => {
        setClickedBillTransactionId(clickedItemTransactionid);
        copyToClipboard(clickedItemTransactionid);
        setTimeout(() => {
            setClickedBillTransactionId(null);
        }, 1000);
    };

    const handleRowClick = (row: Row<IBillingDashboardInvoiceBreakdown>) => {
        navigate(
            `/billing-and-claims/${row.original.client.client_id}/${
                row.original.note_id
            }${doesViewHasClaimString ? `?tab=claims` : ""}`
        );
    };

    const handleExportClick = async () => {
        setIsExporting(true);
        await exportQuery.refetch();
        setIsExporting(false);
        if (exportQuery.error) {
            toast.toast({
                mode: "error",
                message: "Could not export report.",
            });
        }
    };

    const columns: ColumnDef<IBillingDashboardInvoiceBreakdown>[] = [
        {
            id: "payment_date",
            accessorKey: "payment_date",
            header: "PAYMENT DATE",
            cell: ({ row }) => formatDate(row.original.payment_date),
        },
        {
            accessorKey: "date_of_service",
            header: "DATE OF SERVICE",
            cell: ({ row }) => formatDate(row.original.date_of_service),
        },
        {
            accessorKey: "client_name",
            header: "CLIENT",
            cell: ({ row }) =>
                displayNameInRightFormat({
                    firstName: row.original.client.first_name,
                    lastName: row.original.client.last_name,
                }),
        },
        {
            id: "payment_amount",
            accessorKey: "payment_amount",
            header: "PAYMENT AMOUNT",
            cell: ({ row }) =>
                showMoneyInAppFormat(row.original.payment_amount),
        },
        {
            id: "dob",
            accessorKey: "date_of_birth",
            header: "DOB",
            cell: ({ row }) =>
                formatDate(row.original.client.date_of_birth, true),
        },
        {
            accessorKey: "provider_name",
            header: "PROVIDER",
            cell: ({ row }) =>
                displayNameInRightFormat({
                    firstName: row.original.provider.first_name,
                    lastName: row.original.provider.last_name,
                }),
        },
        {
            accessorKey: "npi",
            header: "PROVIDER npi",
            cell: ({ row }) => row.original.provider.npi,
        },
        {
            id: "coinsurance_charge",
            accessorKey: "coinsurance_amount",
            header: "CO-INSURANCE CHARGE",
            cell: ({ row }) =>
                showMoneyInAppFormat(row.original.coinsurance_amount),
        },
        {
            id: "charge_amount",
            accessorKey: "charge_amount",
            header: "CLAIM CHARGE",
            cell: ({ row }) => showMoneyInAppFormat(row.original.charge_amount),
        },
        {
            id: "insurance_charge",
            accessorKey: "insurance_amount",
            header: "INSURANCE CHARGE",
            cell: ({ row }) =>
                showMoneyInAppFormat(row.original.insurance_amount),
        },
        {
            id: "pr100_collected",
            accessorKey: "pr100_collected",
            header: "PR100 COLLECTED",
            cell: ({ row }) =>
                row.original.pr100_collected
                    ? showMoneyInAppFormat(row.original.pr100_collected)
                    : "--",
        },
        {
            id: "pr100_refunded",
            accessorKey: "pr100_refunded",
            header: "PR100 REFUNDED",
            cell: ({ row }) =>
                row.original.pr100_refunded
                    ? showMoneyInAppFormat(row.original.pr100_refunded)
                    : "--",
        },
        {
            id: "insurance_charge",
            accessorKey: "insurance_amount",
            header: "INSURANCE CHARGE",
            cell: ({ row }) =>
                showMoneyInAppFormat(row.original.insurance_amount),
        },
        {
            id: "claim_id",
            accessorKey: "claim_external_reference_id",
            header: "CLAIM ID",
            cell: ({ row }) => row.original.claim_external_reference_id || "--",
        },
        {
            accessorKey: "collected",
            header: "COLLECTED",
            cell: ({ row }) => showMoneyInAppFormat(row.original.collected),
        },
        {
            accessorKey: "cpt_code",
            header: "CPT CODE",
            cell: ({ row }) => row.original.cpt_code || "--",
        },
        {
            id: "status",
            accessorKey: "invoice_status",
            header: "STATUS",
            cell: ({ row }) => {
                let bgColor: string;
                let textColor: string;

                switch (row.original.invoice_status) {
                    case BillingStatus.OPEN:
                        textColor = "#0B132B";
                        bgColor = "rgba(245, 245, 245, 0.50)";
                        break;
                    case BillingStatus.CLOSED:
                        textColor = "#0B132B";
                        bgColor = "rgba(229, 229, 229, 0.50)";
                        break;
                    case BillingStatus.PENDING_RESOLUTION:
                        textColor = "#634D17";
                        bgColor = "rgba(247, 229, 164, 0.50)";
                        break;
                    case BillingStatus.PENDING_PATIENT_RESOLUTION:
                        textColor = "#634D17";
                        bgColor = "rgba(247, 229, 164, 0.50)";
                        break;
                    case BillingStatus.PENDING_INSURANCE_RESOLUTION:
                        textColor = "#634D17";
                        bgColor = "rgba(247, 229, 164, 0.50)";
                        break;
                    default:
                        textColor = "#0B132B";
                        bgColor = "rgba(245, 245, 245, 0.50)";
                }
                return (
                    <Tag
                        textColor={textColor}
                        bgColor={bgColor}
                        title={removeEnumUnderscore(
                            row.original.invoice_status
                        )}
                        className="capitalize h-auto text-nowrap px-8 py-4 rounded-r4"
                    />
                );
            },
        },
        {
            accessorKey: "diagnosis",
            header: "DIAGNOSIS CODE",
            cell: ({ row }) => {
                const getDiagnosisCodes = () => {
                    if (
                        row.original.diagnosis_codes &&
                        row.original.diagnosis_codes.length > 0
                    ) {
                        return diagnosisCodes?.data?.data?.filter((code) => {
                            return row.original.diagnosis_codes.includes(
                                code.code
                            );
                        });
                    }
                    return undefined;
                };
                return (
                    <div>
                        {getDiagnosisCodes()?.map((code) => (
                            <p key={code.code}>
                                {`${code.code} ${code.description}`}
                            </p>
                        )) || "--"}
                    </div>
                );
            },
        },
    ];

    const unmatchedPaymentsColumns: ColumnDef<IBillingDashboardUnmatchedPayments>[] =
        [
            {
                accessorKey: "client_name",
                header: "CLIENT",
                cell: ({ row }) =>
                    displayNameInRightFormat({
                        firstName: row.original.client.first_name,
                        lastName: row.original.client.last_name,
                    }),
            },
            {
                accessorKey: "payment_date",
                header: "PAYMENT DATE",
                cell: ({ row }) => (
                    <span className="flex flex-col items-start gap-y-8">
                        <span>{formatDate(row.original.payment_date)}</span>
                        <span>
                            {formatDate(
                                row.original.payment_date,
                                false,
                                "hh:mmaaa"
                            )}
                        </span>
                    </span>
                ),
            },
            {
                accessorKey: "payment_method",
                header: "PAYMENT METHOD",
                cell: ({ row }) => {
                    let bgColor: string;
                    let textColor: string;

                    switch (row.original.payment_status) {
                        case PaymentStatus.COMPLETED:
                            textColor = "#00563E";
                            bgColor = "rgba(204, 250, 233, 0.50)";
                            break;
                        case PaymentStatus.PENDING:
                            textColor = "#634D17";
                            bgColor = "rgba(247, 229, 164, 0.50)";
                            break;
                        case PaymentStatus.FAILED:
                            textColor = "#981F41";
                            bgColor = "rgba(251, 199, 198, 0.50)";
                            break;
                        case PaymentStatus.REFUNDED:
                            textColor = "#165574";
                            bgColor = "rgba(172, 222, 250, 0.50)";
                            break;
                        case PaymentStatus.VOIDED:
                            textColor = "#981F41";
                            bgColor = "rgba(251, 199, 198, 0.50)";
                            break;
                        default:
                            textColor = "#165574";
                            bgColor = "rgba(172, 222, 250, 0.50)";
                    }
                    return (
                        <span className="flex flex-col items-start gap-y-8">
                            <span>
                                {`${makeStringFirstLetterCapital(
                                    row.original.payment_method || "--"
                                )} - ${
                                    (row.original.auth_code &&
                                        row.original.auth_code) ||
                                    "--"
                                }
                            `}
                            </span>

                            <Tag
                                bgColor={bgColor}
                                textColor={textColor}
                                title={removeEnumUnderscore(
                                    row.original.payment_status
                                )}
                                className="capitalize font-normal px-8 py-4 h-24 rounded-r4"
                            />
                        </span>
                    );
                },
            },
            {
                accessorKey: "processor_payment_status",
                header: "PROCESSOR STATUS",
                cell: ({ row }) => {
                    return (
                        <span className="flex flex-col items-start gap-y-8">
                            <span>
                                {makeStringFirstLetterCapital(
                                    row.original.processor_payment_status ||
                                        "--"
                                )}
                            </span>
                            <span className="gap-x-8 flex items-center relative">
                                {row.original.transaction_id}
                                <Button
                                    variant="normal"
                                    size="auto"
                                    onClick={() =>
                                        handleCopyClick(
                                            row.original.transaction_id
                                        )
                                    }
                                    type="button"
                                >
                                    <CopyIcon />
                                </Button>
                                {clickedBillTransactionId &&
                                    clickedBillTransactionId ===
                                        row.original.transaction_id && (
                                        <span className="font-semibold text-xs absolute right-[-50px]">
                                            Copied!
                                        </span>
                                    )}
                            </span>
                        </span>
                    );
                },
            },
            {
                accessorKey: "payment_amount",
                header: "PAYMENT AMOUNT",
                cell: ({ row }) =>
                    showMoneyInAppFormat(row.original.amount) || "--",
            },
            {
                accessorKey: "open_bill",
                header: "OPEN BILL",
                cell: ({ row }) => row.original.open_bills || "--",
            },
        ];

    const actualView = () => {
        if (view === "amount_outstanding") {
            return "Aging";
        }
        if (view === "pr100_collected") {
            return "PR100 Collected";
        }
        return removeEnumUnderscore(view || "");
    };
    return (
        <div className="pb-16 md:py-24">
            <div className="px-0 md:px-32">
                <div className="p-16 md:p-24 border-b border-b-[#e7e7e7e8] md:border-b-transparent md:bg-[#FBFBFB] rounded-none">
                    <h3 className="capitalize extrabold text-xxl mb-24 md:mb-16">
                        {actualView()}
                    </h3>
                    <div className="grid gap-y-16 gap-24 grid-cols-[repeat(auto-fit,minmax(300px,1fr))] max-w-full overflow-x-auto">
                        <Card classNames="p-12 md:p-24 border border-[#e7e7e7e8] rounded-r8 flex flex-col gap-y-8 h-[104px] justify-center relative">
                            <div className="flex gap-x-8">
                                <span className="text-xs font-medium uppercase">
                                    MONTH
                                </span>
                            </div>
                            <span className="text-xxl font-medium capitalize">
                                {month}
                            </span>
                            <img
                                className="top-0 absolute right-[16px] h-full w-[97px]"
                                alt="pattern background"
                                src={patternBg}
                            />
                        </Card>
                        <Card classNames="p-12 md:p-24 border border-[#e7e7e7e8] rounded-r8 flex flex-col gap-y-8 h-[104px] justify-center relative">
                            <div className="flex gap-x-8">
                                <span className="text-xs font-medium uppercase">
                                    {getCardTitles().cardTwoTitle}
                                </span>
                            </div>
                            <span className="text-xxl font-medium capitalize flex items-center gap-x-12">
                                {showMoneyInAppFormat(amount)}
                                {/* <Tag
                                    bgColor="#FBC7C6"
                                    textColor="#981F41"
                                    title="-2.5%"
                                    className="px-8 h-16 rounded-r4 text-xs flex items-center"
                                /> */}
                            </span>
                            <img
                                className="top-0 absolute right-[16px] h-full w-[97px]"
                                alt="pattern background"
                                src={patternBg}
                            />
                        </Card>
                        {view === "amount_collected" ? (
                            <>
                                <Card classNames="p-12 md:p-24 border border-[#e7e7e7e8] rounded-r8 flex flex-col gap-y-8 h-[104px] justify-center relative">
                                    <div className="flex gap-x-8">
                                        <span className="text-xs font-medium uppercase">
                                            Total Credit
                                        </span>
                                    </div>
                                    <span className="text-xxl font-medium capitalize flex items-center gap-x-12">
                                        {showMoneyInAppFormat(credit)}
                                    </span>
                                    <img
                                        className="top-0 absolute right-[16px] h-full w-[97px]"
                                        alt="pattern background"
                                        src={patternBg}
                                    />
                                </Card>
                                <Card classNames="p-12 md:p-24 border border-[#e7e7e7e8] rounded-r8 flex flex-col gap-y-8 h-[104px] justify-center relative">
                                    <div className="flex gap-x-8">
                                        <span className="text-xs font-medium uppercase">
                                            Total Refund
                                        </span>
                                    </div>
                                    <span className="text-xxl font-medium capitalize flex items-center gap-x-12">
                                        {showMoneyInAppFormat(refund)}
                                    </span>
                                    <img
                                        className="top-0 absolute right-[16px] h-full w-[97px]"
                                        alt="pattern background"
                                        src={patternBg}
                                    />
                                </Card>
                            </>
                        ) : null}
                        <Card classNames="p-12 md:p-24 border border-[#e7e7e7e8] rounded-r8 flex flex-col gap-y-8 h-[104px] justify-center relative">
                            <div className="flex gap-x-8">
                                <span className="text-xs font-medium uppercase">
                                    {getCardTitles().cardThreeTitle}
                                </span>
                            </div>
                            <span className="text-xxl font-medium">
                                {count}
                            </span>
                            <img
                                className="top-0 absolute right-[16px] h-full w-[97px]"
                                alt="pattern background"
                                src={patternBg}
                            />
                        </Card>
                    </div>
                </div>
            </div>

            <div className="mt-40 md:mt-32 pl-16 pr-0 md:px-32">
                <div className="mb-12 flex flex-col sm:flex-row items-start sm:items-center gap-x-24 pr-16 gap-y-16">
                    <SearchInput
                        placeholder="Search by client name or client chart number"
                        containerClass="w-full sm:w-[400px] max-w-full h-32"
                        value={clientFilter}
                        onChange={handleClientFilterChange}
                    />
                    {view !== "unmatched_payments" && (
                        <SearchInput
                            placeholder="Search by provider"
                            containerClass="w-full sm:w-[400px] max-w-full h-32"
                            value={providerName}
                            onChange={handleProviderFilterChange}
                        />
                    )}
                    {view === "amount_outstanding" ||
                    view === "amount_collected" ? (
                        <div className="ml-auto">
                            <Button
                                onClick={handleExportClick}
                                disabled={
                                    data?.data?.invoice_breakdown?.length ===
                                        0 || exportQuery.isFetching
                                }
                            >
                                Export
                            </Button>
                        </div>
                    ) : null}
                </div>
                {isLoading && (
                    <Skeleton
                        containerTestId="breakdown-loader"
                        count={5}
                        type="table"
                    />
                )}
                {error && (
                    <ListState
                        isError
                        stateHelperText="Try reloading this page to solve this issue"
                        errorMsg={
                            error.response?.data.message ||
                            "Cannot display breakdown at this time, please try again later"
                        }
                    />
                )}
                {view !== "unmatched_payments" &&
                data?.data &&
                isSuccess &&
                data.data.invoice_breakdown.length === 0 ? (
                    <div>
                        <ListState
                            stateHelperText="Billing breakdown list will be displayed here when available"
                            isError={false}
                            emptyMessage="No billing breakdown to display"
                        />
                    </div>
                ) : null}
                {view !== "unmatched_payments" &&
                data?.data &&
                isSuccess &&
                data.data.invoice_breakdown.length > 0 ? (
                    <Table
                        data={data.data.invoice_breakdown}
                        columns={columns
                            .filter((column) =>
                                doesViewHasClaimString
                                    ? column.id !== "coinsurance_amount"
                                    : column.id !== "charge_amount"
                            )
                            .filter((column) =>
                                view !== "amount_collected"
                                    ? column.id !== "payment_date" &&
                                      column.id !== "payment_amount"
                                    : true
                            )}
                        handleRowClick={handleRowClick}
                        setSortConfig={setSortConfig}
                        sortConfig={sortConfig}
                        sortableColumns={sortableColumns}
                        isColumnVisible={{
                            coinsurance_charge: view !== "amount_collected",
                            insurance_charge: view !== "amount_collected",
                            charge_amount: view !== "amount_collected",
                            pr100_collected: view !== "amount_collected",
                            pr100_refunded: view !== "amount_collected",
                            claim_id: view !== "amount_collected",
                            collected: view !== "amount_collected",
                            cpt_code: view !== "amount_collected",
                            status: view !== "amount_collected",
                            diagnosis: view !== "amount_collected",
                        }}
                    />
                ) : null}

                {view === "unmatched_payments" &&
                data?.data &&
                isSuccess &&
                data.data.unmatched_payments.length === 0 ? (
                    <div>
                        <ListState
                            stateHelperText="Billing breakdown list will be displayed here when available"
                            isError={false}
                            emptyMessage="No billing breakdown to display"
                        />
                    </div>
                ) : null}
                {view === "unmatched_payments" &&
                data?.data &&
                isSuccess &&
                data.data.unmatched_payments.length > 0 ? (
                    <div>
                        <Table
                            data={data.data.unmatched_payments}
                            columns={unmatchedPaymentsColumns}
                            setSortConfig={setSortConfig}
                            sortConfig={sortConfig}
                            sortableColumns={sortableColumns}
                        />
                    </div>
                ) : null}
            </div>
        </div>
    );
}
