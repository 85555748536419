import { Link } from "react-router-dom";
import empty from "../assets/images/empty.svg";

export default function NotFound() {
    return (
        <div className="flex items-center justify-center h-dvh md:h-screen px-8">
            <div
                className="w-[400px] max-w-full text-center rounded-r12 border border-strokedark
                 bg-[#F1F1F3] shadow-[0px_16px_32px_-12px_#0e121b19] p-20 md:p-40"
            >
                <h1 className="uppercase text-primary text-xxl font-extrabold mb-32">
                    Mantle
                </h1>
                <div className="mt-24 text-center">
                    <h2 className="text-xxl font-bold mb-12 text-black text-center">
                        The page you are trying to access does not exist
                    </h2>
                    <p className="text-sm">
                        You can access other parts of the app here{" "}
                        <Link
                            to="/"
                            className="text-primary underline font-medium"
                        >
                            Dashboard
                        </Link>
                    </p>
                    <div>
                        <img src={empty} alt="" loading="lazy" />
                    </div>
                </div>
            </div>
        </div>
    );
}
