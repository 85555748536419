/* eslint-disable react/no-unstable-nested-components */
import * as React from "react";
import {
    useParams,
    useSearchParams,
    useNavigate,
    Link,
} from "react-router-dom";

import {
    Button,
    DatePicker,
    Dialog,
    DialogContent,
    Dropdown,
    DropdownContent,
    DropdownItem,
    DropdownTrigger,
    Pagination,
    Popover,
    PopoverContent,
    PopoverTrigger,
    Table,
    Tooltip,
} from "@jhool-io/fe-components";
import { ColumnDef, ExpandedState, Row } from "@tanstack/react-table";
import { format } from "date-fns";
import { useIsMutating } from "@tanstack/react-query";
import { useFetchClientBasicAndPersonalInfo } from "../../../../hooks/queries/client";
import Skeleton from "../../../../components/Skeleton/Skeleton";
import ChevronDownIcon from "../../../../components/Icons/ChevronDown";
import {
    APP_COLORS,
    CPT_CODES,
    CPT_CODES_DESCRIPTION,
    LISTS_DEFAULT_LIMIT,
} from "../../../../utils/constants";
import {
    cn,
    convertDateFilterStringToDate,
    formatDate,
    removeEnumUnderscore,
    showMoneyInAppFormat,
} from "../../../../utils/helpers";
import InfoIcon from "../../../../components/Icons/InfoIcon";
import UnionUpIcon from "../../../../components/Icons/UnionUp";
import UnionDownIcon from "../../../../components/Icons/UnionDown";
import ListState from "../../../../components/ListState/ListState";
import CheckPrimaryColorIcon from "../../../../components/Icons/CheckPrimaryColor";
import { UserRole } from "../../../../utils/types/user";
import { useDoesUserHaveRole } from "../../../../hooks/permissions";
import {
    BillStatus,
    PaymentModals,
    IBillingResponse,
    BillStatusLabels,
} from "../../types/billing.types";
import { useFetchSessionNote } from "../../../../hooks/queries/note";
import UnmatchedPaymentList from "../UnmatchedPaymentList/UnmatchedPaymentList";
import { getBillStatusTag } from "../../helpers/billing.helpers";
import InfoCircleIcon from "../../../../components/Icons/InfoCircle";
import ChargeClient from "../ChargeClientForm/ChargeClientForm";
import AddBulkWriteOffForm from "../WriteOff/AddBulkWriteOffForm/AddBulkWriteOffForm";
import FilterButton from "../../../../shared-ui/Buttons/FilterButton/FilterButton";
import TableFiltersDisplay from "../../../../shared-ui/TableFiltersDisplay/TableFiltersDisplay";
import { useFetchInvoices } from "../../hooks/queries/billing.queries";
import useShowInsuranceOverSelfPay from "../../../practice-settings/hooks/useShowInsuranceOverSelfPay";
import { NoteTypes } from "../../../notes/types/notes.types";
import UpdateForm from "../UpdateForm/UpdateForm";
import PencilIcon from "../../../../components/Icons/Pencil";
import PaymentBreakdown from "../PaymentBreakdown/PaymentBreakdown";
import styles from "./PatientsBillsList.module.scss";

interface ISubComponent {
    // Eslint is misbehaving here giving a false error, so ading this.
    // eslint-disable-next-line react/no-unused-prop-types
    row: Row<IBillingResponse>;
}

export default function PatientsBillsList() {
    const [expandedRows, setExpandedRows] = React.useState<ExpandedState>({});

    const [modalInView, setModalInView] = React.useState<PaymentModals | null>(
        null
    );

    const [invoiceToUpdateId, setInvoiceToUpdateId] = React.useState<
        string | null
    >(null);

    const [editingInsuranceRowId, setEditingInsuranceRowId] = React.useState<
        string | null
    >();
    const [insuranceChargeAmount, setInsuranceChargeAmount] = React.useState(0);

    const [editingCoInsuranceRowId, setEditingCoInsuranceRowId] =
        React.useState<string | null>();

    const [coInsuranceChargeAmount, setCoInsuranceChargeAmount] =
        React.useState(0);

    const [sortConfig, setSortConfig] = React.useState<{
        key: string;
        direction: string;
    }>({
        key: "",
        direction: "",
    });

    const navigate = useNavigate();
    const params = useParams();
    const [searchParams, setSearchParams] = useSearchParams();
    const clientId = params.clientId as string;
    const noteId = params.noteId as string;

    const canSplitPayment = searchParams.get("split_payment");

    const showInsOverSelfPay = useShowInsuranceOverSelfPay();

    // Fetch note related to this bill
    const noteDetails = useFetchSessionNote(
        clientId,
        noteId,
        Boolean(clientId) && Boolean(noteId)
    );

    const getBillStatus = () => {
        const invoiceStatusFilter = searchParams.getAll("invoice_status[]");

        if (invoiceStatusFilter.length > 0) {
            if (invoiceStatusFilter.includes("all")) {
                return [];
            }
            return invoiceStatusFilter;
        }

        return [];
    };

    const pageFilter = Number(searchParams.get("page")) || 1;
    const limitFilter =
        Number(searchParams.get("limit")) || LISTS_DEFAULT_LIMIT;
    const cptCodeFilter = searchParams.get("cpt_code") || "";
    const invoiceStatusFilter = getBillStatus() || [];
    const fromDateFilter = searchParams.get("from_date");
    const toDateFilter = searchParams.get("to_date");

    const fromDateFilterToDate = convertDateFilterStringToDate(fromDateFilter);
    const toDateFilterToDate = convertDateFilterStringToDate(toDateFilter);

    /**
     * Get client charge failed modal in view
     * Should be "prompt" | "pr" | null
     */
    const chargeModalInView = searchParams.get("cf_modal");

    const removeChargeModalParams = () => {
        searchParams.delete("cf_modal");
        setSearchParams(searchParams);
    };

    const chargeClientIsMutating = useIsMutating(["charge-client"]);
    const bulkWriteOffIsMutating = useIsMutating(["bulk-invoice-payment"]);

    const sortableColumns = [
        "client_name",
        "provider_name",
        "coinsurance_charge",
        "coinsurance_amount_collected",
        "insurance_amount_collected",
        "insurance_charge",
        "insurance_provider",
        "cpt_code",
        "date_of_service",
        "note_author",
    ];

    const handleConfigAttribute = () => {
        if (sortableColumns.includes(sortConfig.key)) {
            return sortConfig.key;
        }

        return undefined;
    };

    const selectedListInvoice = useFetchInvoices({
        invoice_id: noteDetails?.data?.data.invoice_id || "",
    });

    // Hook for fetching all invoice
    const { data, isLoading, isSuccess, error } = useFetchInvoices({
        client_id: clientId,
        cpt_code: cptCodeFilter,
        invoice_status: invoiceStatusFilter,
        from_date: fromDateFilter,
        to_date: toDateFilter,
        page: pageFilter,
        limit: limitFilter,
        sort_attribute:
            localStorage.getItem("sort_attr_bills") ||
            searchParams.get("sort_attr") ||
            handleConfigAttribute(),
        sort_order:
            localStorage.getItem("sort_order_bills") ||
            searchParams.get("sort_order") ||
            sortConfig.direction,
    });

    // Hook for fetching client details
    const clientInfo = useFetchClientBasicAndPersonalInfo(clientId);

    const memoizedTableData = React.useMemo(() => {
        if (isSuccess && selectedListInvoice.isSuccess) {
            if (data.total_count > 0) {
                return [
                    ...selectedListInvoice.data.data,
                    ...data.data.filter(
                        (item) =>
                            item.invoice_id !==
                            noteDetails.data?.data.invoice_id
                    ),
                ];
            }
        }

        return [];
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data?.data, selectedListInvoice]);

    const handlePageChange = (page: number) => {
        searchParams.set("page", String(page));
        setSearchParams(searchParams);
    };

    const handleLimitChange = (limit: number) => {
        searchParams.set("limit", String(limit));
        setSearchParams(searchParams);
    };

    const handleCptCodeChange = (value: string) => {
        if (value === "") searchParams.delete("cpt_code");
        else searchParams.set("cpt_code", value);

        searchParams.set("page", "1");
        setSearchParams(searchParams);
    };

    const handleStatusChange = (status: string) => {
        if (invoiceStatusFilter.includes(status)) {
            searchParams.delete("invoice_status[]", status);
        } else {
            searchParams.append("invoice_status[]", status);
        }
        searchParams.set("page", "1");
        setSearchParams(searchParams);
    };

    const handleFromDateFilterChange = (date: Date | null) => {
        if (date === null) searchParams.delete("from_date");
        else searchParams.set("from_date", format(date, "yyyy-MM-dd"));

        searchParams.set("page", "1");
        setSearchParams(searchParams);
    };

    const handleToDateFilterChange = (date: Date | null) => {
        if (date === null) searchParams.delete("to_date");
        else searchParams.set("to_date", format(date, "yyyy-MM-dd"));

        searchParams.set("page", "1");
        setSearchParams(searchParams);
    };

    const allowedRolesForUpdatingCharge = [
        UserRole.BILLER,
        UserRole.BILLER_ADMIN,
        UserRole.SUPER_ADMIN,
    ];

    const canUpdateChargeAmount = useDoesUserHaveRole(
        allowedRolesForUpdatingCharge
    );

    const handleModalInView = (obj: { modal: PaymentModals | null }) => {
        setModalInView(obj.modal);

        setSearchParams(searchParams);
    };

    const renderRowSubComponentShow = React.useCallback(
        ({ row }: ISubComponent) => (
            <PaymentBreakdown
                row={row.original}
                isExpanded={row.getIsExpanded()}
            />
        ),
        []
    );

    const handleCloseUnmatchPaymentsModal = () => {
        searchParams.delete("unmatch_page");
        searchParams.delete("payment_status");
        searchParams.delete("unmatch_limit");
        searchParams.delete("split_payment");

        handleModalInView({ modal: null });

        setSearchParams();
    };

    function handleGenerateStatement() {
        if (fromDateFilter && toDateFilter) {
            navigate(
                `/reports/billing-reports/${clientId}?from_date=${searchParams.get(
                    "from_date"
                )}&to_date=${searchParams.get("to_date")}`
            );
        } else if (fromDateFilter) {
            navigate(
                `/reports/billing-reports/${clientId}?from_date=${searchParams.get(
                    "from_date"
                )}`
            );
        } else if (toDateFilter) {
            navigate(
                `/reports/billing-reports/${clientId}?to_date=${searchParams.get(
                    "to_date"
                )}`
            );
        } else {
            navigate(`/reports/billing-reports/${clientId}`);
        }
    }

    const getOutstandingValueToDisplay = (row: Row<IBillingResponse>) => {
        const coInsurance = row.original.accounting_coinsurance;
        const insurancePending =
            row.original.patient_insurance_pending_settlement;

        if (coInsurance <= 0) {
            if (insurancePending && insurancePending > 0) {
                return (
                    <span className="flex items-center">
                        {showMoneyInAppFormat(Math.abs(coInsurance))} +{" "}
                        {showMoneyInAppFormat(insurancePending)}
                        <Tooltip content="Insurance balance transferred to patient">
                            <Button variant="normal" size="auto">
                                <InfoCircleIcon
                                    stroke={APP_COLORS.COLOR_BLACK}
                                    fill={APP_COLORS.COLOR_BLACK}
                                />
                            </Button>
                        </Tooltip>
                    </span>
                );
            }
            return showMoneyInAppFormat(Math.abs(coInsurance));
        }

        if (coInsurance > 0) {
            if (insurancePending && insurancePending > 0) {
                return (
                    <span className="flex items-center text-danger">
                        ({showMoneyInAppFormat(coInsurance)}) +{" "}
                        {showMoneyInAppFormat(insurancePending)}
                        <Tooltip content="Insurance balance transferred to patient">
                            <Button variant="normal" size="auto">
                                <InfoCircleIcon
                                    stroke={APP_COLORS.COLOR_BLACK}
                                    fill={APP_COLORS.COLOR_BLACK}
                                />
                            </Button>
                        </Tooltip>
                    </span>
                );
            }
            return (
                <span className="text-danger">
                    ({showMoneyInAppFormat(coInsurance)})
                </span>
            );
        }

        return "--";
    };

    const columns: ColumnDef<IBillingResponse>[] = [
        {
            header: " ",
            columns: [
                {
                    accessorKey: "date_of_service",
                    header: "DATE OF SERVICE",
                    cell: ({ row }) => (
                        <div className="flex gap-12">
                            <Button
                                variant="normal"
                                size="auto"
                                onClick={() => {
                                    row.toggleExpanded();

                                    row.getToggleExpandedHandler();
                                }}
                            >
                                {row.getIsExpanded() ? (
                                    <UnionUpIcon />
                                ) : (
                                    <UnionDownIcon />
                                )}
                            </Button>
                            <Link
                                to={`/billing-and-claims/${row.original.client.client_id}/${row.original.note_id}`}
                                className="text-primary underline"
                            >
                                {formatDate(row.original.date_of_service) ||
                                    "--"}
                            </Link>
                        </div>
                    ),
                },
            ],
        },
        {
            header: " ",
            columns: [
                {
                    accessorKey: "cpt_code",
                    header: "CPT CODE",
                    cell: ({ row }) => {
                        if (
                            row.original.note_type ===
                            NoteTypes.CANCELLATION_NOTE
                        ) {
                            return "NO SHOW";
                        }

                        if (row.original.cpt_code) {
                            return (
                                <Tooltip
                                    content={
                                        CPT_CODES_DESCRIPTION[
                                            row.original.cpt_code as string
                                        ] || ""
                                    }
                                >
                                    <span
                                        style={{
                                            display: "flex",
                                            alignItems: "center",
                                            columnGap: "5px",
                                        }}
                                    >
                                        {row.original.cpt_code
                                            ? removeEnumUnderscore(
                                                  row.original.cpt_code
                                              )
                                            : "--"}
                                        <InfoIcon />
                                    </span>
                                </Tooltip>
                            );
                        }
                        return "--";
                    },
                },
            ],
        },
        {
            header: " ",
            columns: [
                {
                    accessorKey: "pos_code",
                    header: "pos code",
                    cell: ({ row }) => {
                        const regex = /^(\d+) - (.+)$/;

                        if (row.original.pos_code) {
                            return (
                                <Tooltip
                                    content={
                                        row.original.pos_code.match(
                                            regex
                                        )?.[2] || ""
                                    }
                                >
                                    <span
                                        style={{
                                            display: "flex",
                                            alignItems: "center",
                                            columnGap: "5px",
                                        }}
                                    >
                                        {row.original.pos_code.match(
                                            regex
                                        )?.[1] || ""}
                                        <InfoIcon />
                                    </span>
                                </Tooltip>
                            );
                        }
                        return "--";
                    },
                },
            ],
        },
        {
            header: " ",
            columns: [
                {
                    accessorKey: "insurance_provider_name",
                    header: "Insurance Provider",
                    cell: ({ row }) => (
                        <span
                            className={
                                row.original?.metadata?.self_pay &&
                                !showInsOverSelfPay
                                    ? "normal-case"
                                    : "uppercase"
                            }
                        >
                            {row.original?.metadata?.self_pay &&
                            !showInsOverSelfPay
                                ? "Self pay"
                                : (row.original.insurance_provider &&
                                      removeEnumUnderscore(
                                          row.original.insurance_provider || ""
                                      )) ||
                                  "--"}
                        </span>
                    ),
                },
            ],
        },
        {
            header: " ",
            columns: [
                {
                    accessorKey: "note_author",
                    header: "CLINICIAN",
                    cell: ({ row }) =>
                        row.original.note_author
                            ? `${row.original.note_author.last_name} ${row.original.note_author.first_name}`
                            : "--",
                },
            ],
        },
        {
            header: " ",
            columns: [
                {
                    accessorKey: "invoice_status",
                    header: "BILL STATUS",
                    cell: ({ row }) => (
                        <span>
                            {getBillStatusTag(
                                row.original.invoice_status as BillStatus
                            )}
                        </span>
                    ),
                },
            ],
        },

        {
            header: "INSURANCE",
            columns: [
                {
                    accessorKey: "charge",
                    header: "CHARGE",
                    cell: ({ row }) => (
                        <div className="flex items-center gap-2">
                            {editingInsuranceRowId ===
                            row.original.invoice_id ? (
                                <div>
                                    <UpdateForm
                                        invoiceId={invoiceToUpdateId as string}
                                        charge_amount={
                                            insuranceChargeAmount || 0
                                        }
                                        selectedChargeType="insurance"
                                        setEditingRowId={
                                            setEditingInsuranceRowId
                                        }
                                        updateType="charge_amount"
                                    />
                                </div>
                            ) : (
                                <div className="flex gap-4 items-center">
                                    <span>
                                        {showMoneyInAppFormat(
                                            row.original.insurance_amount
                                        )}
                                    </span>
                                    {canUpdateChargeAmount &&
                                        row.original.invoice_status !==
                                            BillStatus.CLOSED && (
                                            <Button
                                                size="auto"
                                                variant="normal"
                                                onClick={() => {
                                                    setInsuranceChargeAmount(
                                                        row.original
                                                            .insurance_amount
                                                    );
                                                    setInvoiceToUpdateId(
                                                        row.original.invoice_id
                                                    );
                                                    setEditingInsuranceRowId(
                                                        row.original.invoice_id
                                                    );
                                                }}
                                            >
                                                <PencilIcon className="w-12 h-12" />
                                            </Button>
                                        )}
                                </div>
                            )}
                        </div>
                    ),
                },
                {
                    accessorKey: "collected",
                    header: "COLLECTED",
                    cell: ({ row }) =>
                        showMoneyInAppFormat(
                            row.original.insurance_amount_collected
                        ),
                },
                {
                    accessorKey: "balance",
                    header: "OUTSTANDING",
                    cell: ({ row }) => (
                        <span
                            className={cn("text-sm", {
                                "text-danger":
                                    row.original.accounting_insurance > 0,
                            })}
                        >
                            {row.original.accounting_insurance > 0
                                ? `(${showMoneyInAppFormat(
                                      row.original.accounting_insurance
                                  )})`
                                : showMoneyInAppFormat(
                                      Math.abs(
                                          row.original.accounting_insurance
                                      )
                                  )}
                        </span>
                    ),
                },
            ],
        },
        {
            header: "CO-INSURANCE",
            columns: [
                {
                    accessorKey: "charge",
                    header: "CHARGE",
                    cell: ({ row }) => (
                        <div className="flex items-center gap-2">
                            {editingCoInsuranceRowId ===
                            row.original.invoice_id ? (
                                <div>
                                    <UpdateForm
                                        invoiceId={invoiceToUpdateId as string}
                                        charge_amount={
                                            coInsuranceChargeAmount || 0
                                        }
                                        selectedChargeType="coinsurance"
                                        setEditingRowId={
                                            setEditingCoInsuranceRowId
                                        }
                                        updateType="charge_amount"
                                    />
                                </div>
                            ) : (
                                <div className="flex gap-4 items-center">
                                    <span>
                                        {showMoneyInAppFormat(
                                            row.original.coinsurance_amount
                                        )}
                                    </span>
                                    {canUpdateChargeAmount &&
                                        row.original.invoice_status !==
                                            BillStatus.CLOSED && (
                                            <Button
                                                size="auto"
                                                variant="normal"
                                                onClick={() => {
                                                    setCoInsuranceChargeAmount(
                                                        row.original
                                                            .coinsurance_amount
                                                    );
                                                    setInvoiceToUpdateId(
                                                        row.original.invoice_id
                                                    );
                                                    setEditingCoInsuranceRowId(
                                                        row.original.invoice_id
                                                    );
                                                }}
                                            >
                                                <PencilIcon className="w-12 h-12" />
                                            </Button>
                                        )}
                                </div>
                            )}
                        </div>
                    ),
                },
                {
                    accessorKey: "collected",
                    header: "COLLECTED",
                    cell: ({ row }) =>
                        showMoneyInAppFormat(
                            row.original.coinsurance_amount_collected
                        ),
                },
                {
                    accessorKey: "balance",
                    header: "OUTSTANDING",
                    cell: ({ row }) => getOutstandingValueToDisplay(row),
                },
            ],
        },
        {
            header: "PR100",
            columns: [
                {
                    accessorKey: "charge",
                    header: "CHARGE",
                    cell: ({ row }) =>
                        showMoneyInAppFormat(row.original.pr100_amount),
                },
                {
                    accessorKey: "pr100_collected",
                    header: "COLLECTED",
                    cell: ({ row }) =>
                        showMoneyInAppFormat(row.original.pr100_collected),
                },
                {
                    accessorKey: "balance",
                    header: "OUTSTANDING",
                    cell: ({ row }) => (
                        <span
                            className={cn("text-sm", {
                                "text-danger":
                                    row.original.accounting_pr100 < 0,
                            })}
                        >
                            {row.original.accounting_pr100 < 0
                                ? `(${showMoneyInAppFormat(
                                      Math.abs(row.original.accounting_pr100)
                                  )})`
                                : showMoneyInAppFormat(
                                      row.original.accounting_pr100
                                  )}
                        </span>
                    ),
                },
            ],
        },
        {
            header: " ",
            columns: [
                {
                    accessorKey: "metadata",
                    header: "ACCEPTS ASSIGNMENT",
                    cell: ({ row }) =>
                        row.original?.metadata &&
                        row.original?.metadata?.accepts_assignment ? (
                            <span
                                className={
                                    row.original.metadata.accepts_assignment
                                        ? "text-primary"
                                        : "text-danger"
                                }
                            >
                                {row.original.metadata.accepts_assignment
                                    ? "Yes"
                                    : "No"}
                            </span>
                        ) : (
                            "--"
                        ),
                },
            ],
        },
    ];

    return (
        <>
            <Dialog open={modalInView === "bulk_writeoff"}>
                <DialogContent
                    handleCloseDialog={() => setModalInView(null)}
                    title="Bulk Write-Off"
                    variant="center"
                    saveText="Bulk Write-Off"
                    submitBtnFormValue="bulk-writeoff-form"
                    isSubmitBtnDisabled={bulkWriteOffIsMutating > 0}
                    isCancelBtnDisabled={bulkWriteOffIsMutating > 0}
                >
                    <AddBulkWriteOffForm
                        onFormSubmit={() => setModalInView(null)}
                    />
                </DialogContent>
            </Dialog>
            {noteDetails?.data?.data.invoice_id && (
                <Dialog open={modalInView === "unmatched_payments"}>
                    <DialogContent
                        title="Unmatched Payments"
                        showFooter={false}
                        handleCloseDialog={handleCloseUnmatchPaymentsModal}
                        wrapperClass={cn("w-[900px]", {
                            "w-[1500px]": canSplitPayment,
                        })}
                    >
                        <UnmatchedPaymentList
                            invoiceBalance={
                                selectedListInvoice.data?.data[0]
                                    ?.accounting_coinsurance || 0
                            }
                            invoiceId={noteDetails?.data?.data.invoice_id}
                            onFormSubmit={handleCloseUnmatchPaymentsModal}
                            dateOfService={
                                noteDetails.data.data.date_of_service
                            }
                        />
                    </DialogContent>
                </Dialog>
            )}

            {noteDetails.data?.data.invoice_id &&
                noteDetails.data?.data.note_id && (
                    <Dialog open={modalInView === "charge_client"}>
                        <DialogContent
                            variant="center"
                            showFooter={
                                !chargeModalInView || chargeModalInView === "pr"
                            }
                            submitBtnFormValue={
                                chargeModalInView === "pr"
                                    ? "pending-resolution"
                                    : "charge-client"
                            }
                            handleCloseDialog={
                                chargeModalInView
                                    ? () => {
                                          handleModalInView({ modal: null });
                                          removeChargeModalParams();
                                      }
                                    : () => {
                                          handleModalInView({ modal: null });
                                      }
                            }
                            saveText={
                                chargeModalInView === "pr"
                                    ? "Mark as Pending resolution"
                                    : "Charge client"
                            }
                            isSubmitBtnDisabled={chargeClientIsMutating > 0}
                            isCancelBtnDisabled={chargeClientIsMutating > 0}
                            title={
                                chargeModalInView === "pr"
                                    ? "Mark as Pending resolution"
                                    : "Charge client"
                            }
                        >
                            <ChargeClient
                                onFormSubmit={
                                    chargeModalInView
                                        ? () => {
                                              handleModalInView({
                                                  modal: null,
                                              });
                                              removeChargeModalParams();
                                          }
                                        : () => {
                                              handleModalInView({
                                                  modal: null,
                                              });
                                          }
                                }
                                invoiceId={noteDetails.data?.data.invoice_id}
                                noteId={noteDetails.data?.data.note_id}
                            />
                        </DialogContent>
                    </Dialog>
                )}

            <div className="mt-8">
                <div className="bg-white p-16 rounded-r8 ">
                    <h4 className="text-base font-bold mb-24 flex items-center">
                        All bills for{" "}
                        {clientInfo.isLoading ? (
                            <Skeleton width="120px" />
                        ) : (
                            `${clientInfo.data?.data.last_name} ${clientInfo.data?.data.first_name}`
                        )}
                    </h4>

                    <div className="flex gap-12 gap-y-16 flex-wrap justify-between">
                        <div className="flex gap-12 flex-wrap">
                            <Button
                                variant="normal"
                                className="border border-card-bg bg-white shadow-active"
                                onClick={() => handleGenerateStatement()}
                            >
                                {fromDateFilter || toDateFilter
                                    ? "Generate account statement for selected period"
                                    : "Generate account statement"}
                            </Button>

                            <Button
                                variant="normal"
                                className="border border-card-bg bg-white shadow-active"
                                onClick={() =>
                                    handleModalInView({
                                        modal: "unmatched_payments",
                                    })
                                }
                            >
                                Check unmatched payments
                            </Button>
                            <Button
                                variant="normal"
                                className="border border-card-bg bg-white shadow-active"
                                onClick={() =>
                                    handleModalInView({
                                        modal: "charge_client",
                                    })
                                }
                            >
                                Charge Client
                            </Button>
                            <Button
                                variant="normal"
                                className="border border-card-bg bg-white shadow-active"
                                onClick={() => {
                                    handleModalInView({
                                        modal: "bulk_writeoff",
                                    });
                                }}
                            >
                                Bulk Writeoff
                            </Button>
                            <Dropdown>
                                <DropdownTrigger asChild>
                                    <FilterButton text="Bill Status" />
                                </DropdownTrigger>
                                <DropdownContent
                                    align="end"
                                    width="auto"
                                    maxHeight={216}
                                    className="overflow-y-auto"
                                >
                                    {Object.values(BillStatus).map((status) => (
                                        <DropdownItem
                                            key={status}
                                            onClick={() =>
                                                handleStatusChange(status)
                                            }
                                            isMulti
                                            itemValue={status}
                                            values={invoiceStatusFilter}
                                        >
                                            {removeEnumUnderscore(
                                                BillStatusLabels[status]
                                            )}
                                        </DropdownItem>
                                    ))}
                                </DropdownContent>
                            </Dropdown>

                            <Dropdown>
                                <DropdownTrigger asChild>
                                    <Button
                                        size="auto"
                                        variant="normal"
                                        className="px-16 h-32 shadow-active gap-x-8 text-sm data-[state=open]:border-primary font-medium capitalize"
                                    >
                                        {cptCodeFilter || "CPT Code"}
                                        <ChevronDownIcon
                                            stroke={APP_COLORS.COLOR_BLACK}
                                        />
                                    </Button>
                                </DropdownTrigger>

                                <DropdownContent
                                    align="start"
                                    width="auto"
                                    maxHeight={216}
                                    className="overflow-y-auto min-w-[120px]"
                                >
                                    <DropdownItem
                                        className={cn(
                                            "flex gap-x-8 items-center text-xs font-medium",
                                            { "text-primary": !cptCodeFilter }
                                        )}
                                        onClick={() => handleCptCodeChange("")}
                                    >
                                        All
                                        {!cptCodeFilter ? (
                                            <CheckPrimaryColorIcon />
                                        ) : null}
                                    </DropdownItem>

                                    {Object.values(
                                        CPT_CODES.map((code) => (
                                            <DropdownItem
                                                className={cn(
                                                    "flex justify-between items-center capitalize text-xs font-medium",
                                                    {
                                                        "text-primary":
                                                            cptCodeFilter ===
                                                            code,
                                                    }
                                                )}
                                                key={code}
                                                onClick={() =>
                                                    handleCptCodeChange(code)
                                                }
                                            >
                                                {removeEnumUnderscore(code)}

                                                {cptCodeFilter === code ? (
                                                    <CheckPrimaryColorIcon />
                                                ) : null}
                                            </DropdownItem>
                                        ))
                                    )}
                                </DropdownContent>
                            </Dropdown>

                            <Popover>
                                <PopoverTrigger asChild>
                                    <Button
                                        size="auto"
                                        variant="normal"
                                        className="px-16 h-32 shadow-active gap-x-8 text-sm data-[state=open]:border-primary font-medium self-start md:self-center"
                                    >
                                        Date
                                        <ChevronDownIcon
                                            stroke={APP_COLORS.COLOR_BLACK}
                                        />
                                    </Button>
                                </PopoverTrigger>
                                <PopoverContent
                                    align="end"
                                    className="p-16 gap-y-12"
                                >
                                    <DatePicker
                                        label="From"
                                        placeholderText="MM/DD/YYYY"
                                        selected={fromDateFilterToDate}
                                        onChange={handleFromDateFilterChange}
                                        maxDate={
                                            toDateFilterToDate ||
                                            new Date(Date.now())
                                        }
                                        isClearable
                                    />

                                    <DatePicker
                                        label="To"
                                        placeholderText="MM/DD/YYYY"
                                        selected={toDateFilterToDate}
                                        onChange={handleToDateFilterChange}
                                        minDate={fromDateFilterToDate}
                                        isClearable
                                    />
                                </PopoverContent>
                            </Popover>
                        </div>
                    </div>
                    <TableFiltersDisplay
                        appliedFilters={[
                            {
                                key: "invoice_status[]",
                                values: invoiceStatusFilter,
                            },
                        ]}
                        wrapperClass="mt-12"
                    />
                </div>

                {(error || selectedListInvoice.error) &&
                    (error?.response?.status !== 404 ||
                        selectedListInvoice.error?.response?.status !==
                            404) && (
                        <ListState
                            isError
                            stateHelperText="Try reloading this page to solve this issue"
                            errorMsg={
                                error?.response?.data.message ||
                                `Cannot display bills at this time please try again later`
                            }
                        />
                    )}

                {data && isSuccess && data.data.length === 0 && (
                    <ListState
                        stateHelperText="Bills list will appear here"
                        isError={false}
                        emptyMessage="There are no bills yet"
                    />
                )}

                <div className="mt-24">
                    {(isLoading || selectedListInvoice.isLoading) && (
                        <Skeleton
                            type="table"
                            containerTestId="invoicelist-loader"
                            count={6}
                        />
                    )}

                    {!(isLoading || selectedListInvoice.isLoading) &&
                        (isSuccess || selectedListInvoice) &&
                        data &&
                        selectedListInvoice.data &&
                        data.data.length > 0 && (
                            <Table
                                columns={columns}
                                data={memoizedTableData}
                                className={styles.billslist}
                                expandedRows={expandedRows}
                                setExpandedRows={setExpandedRows}
                                renderRowSubComponent={
                                    renderRowSubComponentShow
                                }
                                hasPagination
                                isHeaderFixed
                                enableRowPinning
                                setSortConfig={setSortConfig}
                                sortConfig={sortConfig}
                                sortableColumns={sortableColumns}
                                getRowId={(row) => row.invoice_id as string}
                                topPinnedRowsIds={
                                    noteDetails.isSuccess &&
                                    noteDetails.data.data.invoice_id
                                        ? [noteDetails.data.data.invoice_id]
                                        : []
                                }
                                pagination={
                                    <Pagination
                                        totalCount={data.total_count}
                                        totalPages={data.total_pages}
                                        currentPage={pageFilter}
                                        onPageChange={handlePageChange}
                                        limit={limitFilter}
                                        onLimitChange={handleLimitChange}
                                    />
                                }
                            />
                        )}
                </div>
            </div>
        </>
    );
}
