import { Button } from "@jhool-io/fe-components";
import { useIsMutating } from "@tanstack/react-query";
import { isFuture } from "date-fns";
import { cn } from "../../../../../utils/helpers";
import { useAppSelector } from "../../../../../hooks/useRedux";

interface EditNoteFooterProps {
    onSaveButtonClick: () => void;
    noteDOS: string | null;
    onSubmitButtonClick: () => void;
    submitBtnIsDisabled?: boolean;
}

export default function EditNoteFooter({
    onSaveButtonClick,
    noteDOS,
    onSubmitButtonClick,
    submitBtnIsDisabled,
}: EditNoteFooterProps) {
    const { isOpen } = useAppSelector((state) => state.nav);

    const isEditDraftNoteMutating = useIsMutating(["edit-note"]);
    const isSignNoteMutating = useIsMutating(["sign-note"]);

    // function to disable button when dos is in the future
    const isTheDateOfServiceInTheFuture = () => {
        if (noteDOS) {
            return isFuture(new Date(noteDOS));
        }
        return false;
    };

    return (
        <div
            className={cn(
                "fixed right-0 bottom-0 bg-white px-[19px] pt-[9px] pb-[29px] md:h-auto md:px-32 md:py-24px lg:h-[64px] w-[calc(100%-25.7rem)] lg:px-32 lg:py-0 flex z-[3] items-center justify-between md:border-t md:border-t-stroke flex-wrap gap-16",
                {
                    "w-full": !isOpen,
                }
            )}
        >
            <Button
                type="button"
                variant="secondary"
                aria-label="save as draft"
                className="px-16 min-h-32 w-full min-[450px]:w-auto"
                disabled={isEditDraftNoteMutating > 0 || isSignNoteMutating > 0}
                onClick={onSaveButtonClick}
            >
                Save as draft
            </Button>
            <Button
                form="edit-note-form"
                type="submit"
                aria-label="submit for review"
                className="px-16 min-h-32 w-full min-[450px]:w-auto"
                disabled={
                    submitBtnIsDisabled ||
                    isEditDraftNoteMutating > 0 ||
                    isSignNoteMutating > 0 ||
                    isTheDateOfServiceInTheFuture()
                }
                onClick={onSubmitButtonClick}
            >
                Submit for review
            </Button>
        </div>
    );
}
