import { useParams, useSearchParams } from "react-router-dom";
import { useQueryClient } from "@tanstack/react-query";
import { Switch, Tag } from "@jhool-io/fe-components";
import useToast from "../../../../hooks/useToast";
import { useCreateDraftClaim } from "../../hooks/claims.mutations";
import Skeleton from "../../../../components/Skeleton/Skeleton";
import ListState from "../../../../components/ListState/ListState";
import SingleClaim from "../Claim/SingleClaim";
import EditClaims from "../EditClaims/EditClaims";
import { useGetClaim } from "../../hooks/claims.queries";
import ClaimSubmissionHistory from "../ClaimSubmissionHistory/ClaimSubmissionHistory";
import { formatDate } from "../../../../utils/helpers";
import { useFetchSessionNote } from "../../../../hooks/queries/note";
import { useFetchInvoices } from "../../../billing/hooks/queries/billing.queries";

interface ClaimsWrapperProps {
    allowUserGenerate?: boolean;
}

interface SwitchProps {
    title: React.ReactNode;
    disabled?: boolean;
    onClick: (title: string) => void;
    key: string;
}

export default function ClaimsWrapper({
    allowUserGenerate,
}: ClaimsWrapperProps) {
    const [searchParams, setSearchParams] = useSearchParams();

    const params = useParams();
    const { noteId, clientId } = params;

    const { data, isLoading, error } = useGetClaim(
        { note_id: noteId },
        Boolean(noteId)
    );

    const noteDetails = useFetchSessionNote(
        clientId || "",
        noteId || "",
        Boolean(clientId) && Boolean(noteId)
    );

    const invoiceDetails = useFetchInvoices(
        { invoice_id: noteDetails?.data?.data?.invoice_id },
        Boolean(noteDetails?.data?.data?.invoice_id)
    );

    const isSelfPayInvoice =
        invoiceDetails?.data?.data?.[0]?.metadata?.self_pay;

    const showGenerateClaimButton =
        Boolean(invoiceDetails) && !isSelfPayInvoice;

    const view = searchParams.get("claims_view");

    const claimTabInUrl = searchParams.get("claim_tab") || "content";

    const handleSetTabInUrl = (claimTab: string) => {
        searchParams.set("claim_tab", claimTab);
        setSearchParams(searchParams);
    };

    const handleSetClaimInUrl = (claimId: string) => {
        searchParams.set("active_claim_id", claimId);
        setSearchParams(searchParams);
    };

    let claimsContentSwitchOptions: SwitchProps[] = [];
    let activeClaimId: string = searchParams.get("active_claim_id") || "";

    const queryClient = useQueryClient();
    // Toast for success and error states
    const { toast } = useToast();

    // hook for updating claim status
    const createADraftClaim = useCreateDraftClaim();

    // function to generate a claim
    const handleGenerateClaim = () => {
        const createDraftPayload = {
            note_id: noteId as string,
        };
        createADraftClaim.mutate(createDraftPayload, {
            onSuccess: (response) => {
                queryClient.invalidateQueries([
                    "get-claim",
                    { note_id: noteId },
                ]);
                toast({
                    mode: "success",
                    message: response.message || `Claim created successfully`,
                });
                searchParams.set(
                    "active_claim_id",
                    response.data.claim.claim_id
                );

                setSearchParams(searchParams);
            },
            onError: (err) => {
                toast({
                    mode: "error",
                    message:
                        err.response?.data.message ||
                        "Could not generate claim at this time",
                });
            },
        });
    };

    const switchOptions = [
        {
            title: "Content",
            key: "content",
            onClick: () => handleSetTabInUrl("content"),
        },
        {
            title: "Claims Activity",
            key: "claims_activity",
            onClick: () => handleSetTabInUrl("claims_activity"),
        },
    ];

    if (data?.data && data.data.length > 1) {
        claimsContentSwitchOptions = data?.data
            .sort(
                (a, b) =>
                    new Date(b.generated_on.replace("Z", "")).getTime() -
                    new Date(a.generated_on.replace("Z", "")).getTime()
            )
            .map((item, i) => ({
                title: (
                    <span className="flex items-center gap-x-4">
                        Claim {formatDate(item.generated_on)} -
                        {item.external_reference_id}
                        {i === 0 ? (
                            <span>
                                <Tag
                                    title="Current"
                                    textColor="#00563E"
                                    bgColor="rgba(204, 250, 233, 0.50)"
                                    className="capitalize font-normal px-8 py-4 h-24 rounded-r4"
                                />
                            </span>
                        ) : null}
                    </span>
                ),
                key: item.claim_id,
                onClick: () => handleSetClaimInUrl(item.claim_id),
            }));
    }

    if (!activeClaimId && claimsContentSwitchOptions.length > 1) {
        activeClaimId = claimsContentSwitchOptions[0].key;
    }

    if (allowUserGenerate && !noteDetails.data?.data.claim_id) {
        return (
            <ListState
                isError={false}
                stateHelperText={
                    showGenerateClaimButton
                        ? "Click the button to generate a new claim"
                        : ""
                }
                emptyMessage="No claim has been generated for this bill"
                emptyBtnProps={{
                    showButton: showGenerateClaimButton,
                    buttonText: "Generate Claims",
                    onButtonClick: handleGenerateClaim,
                    disabled: createADraftClaim.isLoading,
                }}
            />
        );
    }

    return (
        <div>
            {isLoading && (
                <>
                    <Skeleton
                        height={50}
                        width="200px"
                        className="mb-24 rounded-r8"
                    />
                    <div className="flex flex-col gap-y-24 bg-secondary-light p-12">
                        <Skeleton
                            height={400}
                            width="100%"
                            className=" bg-[#FFF6FB] border-strokelight border-[1.5px] rounded-r8"
                        />
                        <Skeleton
                            height={200}
                            width="100%"
                            className="bg-[#FFF6FB] border-strokelight border-[1.5px] rounded-r8"
                        />
                        <Skeleton
                            height={200}
                            width="100%"
                            className="bg-[#FFF6FB] border-strokelight border-[1.5px] rounded-r8"
                        />
                    </div>
                </>
            )}
            {error ? (
                <div>
                    {error?.response?.status === 404 && (
                        <ListState
                            isError={false}
                            stateHelperText="Click the button to generate a new claim"
                            emptyMessage="No claim has been generated for this bill"
                            emptyBtnProps={{
                                showButton: true,
                                buttonText: "Generate Claims",
                                onButtonClick: handleGenerateClaim,
                                disabled: createADraftClaim.isLoading,
                            }}
                        />
                    )}
                </div>
            ) : null}
            {data && data?.data ? (
                <div>
                    {data.data.length === 0 ? (
                        <ListState
                            isError={false}
                            stateHelperText={
                                showGenerateClaimButton
                                    ? "Click the button to generate a new claim"
                                    : ""
                            }
                            emptyMessage="No claim has been generated for this bill"
                            emptyBtnProps={{
                                showButton: showGenerateClaimButton,
                                buttonText: "Generate Claims",
                                onButtonClick: handleGenerateClaim,
                                disabled: createADraftClaim.isLoading,
                            }}
                        />
                    ) : (
                        <div>
                            {data?.data && data?.data.length > 1 ? (
                                <div className="mb-24 p-16 bg-white rounded-r8 overflow-x-auto">
                                    <Switch
                                        switchOptions={
                                            claimsContentSwitchOptions
                                        }
                                        activeSwitch={activeClaimId}
                                    />
                                </div>
                            ) : null}
                            <Switch
                                switchOptions={switchOptions}
                                activeSwitch={claimTabInUrl}
                            />

                            <div className="mt-24">
                                {data?.data && data?.data?.length > 1 ? (
                                    <>
                                        {data.data.map((singleC, i) => {
                                            return activeClaimId ===
                                                singleC.claim_id ? (
                                                <div key={singleC.claim_id}>
                                                    {claimTabInUrl ===
                                                        "content" && (
                                                        <div>
                                                            {view === "edit" ? (
                                                                <EditClaims
                                                                    claimToEditId={
                                                                        singleC.claim_id
                                                                    }
                                                                />
                                                            ) : (
                                                                <SingleClaim
                                                                    claim={
                                                                        singleC
                                                                    }
                                                                    isCurrent={
                                                                        i === 0
                                                                    }
                                                                />
                                                            )}
                                                        </div>
                                                    )}
                                                    {claimTabInUrl ===
                                                        "claims_activity" && (
                                                        <ClaimSubmissionHistory
                                                            claimId={
                                                                singleC.claim_id
                                                            }
                                                        />
                                                    )}
                                                </div>
                                            ) : null;
                                        })}
                                    </>
                                ) : (
                                    <>
                                        {claimTabInUrl === "content" &&
                                            data?.data && (
                                                <div>
                                                    {view === "edit" ? (
                                                        <EditClaims
                                                            claimToEditId={
                                                                data.data[0]
                                                                    .claim_id
                                                            }
                                                        />
                                                    ) : (
                                                        <SingleClaim
                                                            claim={data.data[0]}
                                                            isCurrent
                                                        />
                                                    )}
                                                </div>
                                            )}
                                        {claimTabInUrl === "claims_activity" &&
                                            data?.data && (
                                                <ClaimSubmissionHistory
                                                    claimId={
                                                        data.data[0].claim_id
                                                    }
                                                />
                                            )}
                                    </>
                                )}
                            </div>
                        </div>
                    )}
                </div>
            ) : null}
        </div>
    );
}
