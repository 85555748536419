/* eslint-disable no-param-reassign */
import {
    Button,
    Card,
    Dialog,
    DialogContent,
    Dropdown,
    DropdownContent,
    DropdownItem,
    DropdownTrigger,
    Switch,
} from "@jhool-io/fe-components";
import * as React from "react";
import { useSearchParams } from "react-router-dom";
import ChevronDownIcon from "../../../../components/Icons/ChevronDown";
import { APP_COLORS } from "../../../../utils/constants";
import BillingSummaryCard from "../BillingSummaryCard/BillingSummaryCard";
import { useFetchInsuranceProviders } from "../../../../hooks/queries/client";
import {
    cn,
    isSmallScreen,
    makeStringFirstLetterCapital,
    removeEnumUnderscore,
    showMoneyInAppFormat,
    truncateString,
} from "../../../../utils/helpers";
import TrendingUpIcon from "../../../../components/Icons/TrendingUp";
import TrendingDownIcon from "../../../../components/Icons/TrendingDown";
import BillingSummaryBreakdown from "../BillingSummaryBreakdown/BillingSummaryBreakdown";
import { useFetchBillingAdminDashboardList } from "../../../../hooks/queries/billing";
import Skeleton from "../../../../components/Skeleton/Skeleton";
import ListState from "../../../../components/ListState/ListState";
import { IBillingDashboardMonthlyBreakdown } from "../../../../utils/types/billing";
import CheckPrimaryColorIcon from "../../../../components/Icons/CheckPrimaryColor";
import {
    billingPaymentMonths,
    BillingSummaryCardsFilters,
    getTimeRangeFromFilterValue,
} from "../../helpers/billing.helpers";
import FilterButton from "../../../../shared-ui/Buttons/FilterButton/FilterButton";

export default function BillingSummaryWrapper() {
    const [searchParams, setSearchParams] = useSearchParams();
    const [openDialog, setOpenDialog] = React.useState(false);

    const insuranceProviders = useFetchInsuranceProviders();

    const filter = searchParams.get("filter") || BillingSummaryCardsFilters[0];
    const insuranceProvider = searchParams.get("insurance_provider") || "";
    const typeFilter = searchParams.get("type") || "coinsurance";

    const { fromDate } = getTimeRangeFromFilterValue(filter);
    const { toDate } = getTimeRangeFromFilterValue(filter);

    const tableInView = searchParams.get("view") || "";

    const handleSwitchButtonClick = (key: string) => {
        searchParams.set("type", key);
        localStorage.setItem("billingsummary_filters", searchParams.toString());
        searchParams.delete("insurance_provider");
        setSearchParams(searchParams);
    };

    const { data, error, isLoading, isSuccess } =
        useFetchBillingAdminDashboardList(
            {
                type: insuranceProvider === "self_pay" ? undefined : typeFilter,
                show_invoice_breakdown: false,
                from_date: fromDate,
                to_date: toDate,
                insurance_provider_id: insuranceProvider,
            },
            !tableInView
        );

    const switchOptions = [
        {
            title: "Co-insurance",
            key: "coinsurance",
            onClick: () => handleSwitchButtonClick("coinsurance"),
        },
        {
            title: "Insurance",
            key: "insurance",
            onClick: () => handleSwitchButtonClick("insurance"),
        },
    ];

    // Get percenatage difference between two numbers
    const getPercentageDifference = (prev: number, next: number) => {
        if (prev === 0) return <span>--%</span>;

        const diff = Math.abs(prev - next);

        const average = diff / prev;

        return (
            <span
                className={cn("text-[#00B69B]", {
                    "text-[#F93C65]": prev > next,
                })}
            >
                {Math.abs(Math.round(average * 100))}%
            </span>
        );
    };

    // Get encounters amount to display
    const handleGetTotalEncounters = (
        monthtlyBreakdown: IBillingDashboardMonthlyBreakdown[]
    ) => {
        if (monthtlyBreakdown.length === 0) return 0;

        return monthtlyBreakdown
            .map((value) => value.encounters)
            .reduce(
                (accumulator, currentValue) => accumulator + currentValue,
                0
            );
    };

    const handleSetFilterValue = (value: string | number) => {
        searchParams.set("filter", String(value));
        localStorage.setItem("billingsummary_filters", searchParams.toString());
        setSearchParams(searchParams);
    };

    const handleInsuranceProviderFilter = (id: string) => {
        if (id === "") searchParams.delete("insurance_provider");
        else searchParams.set("insurance_provider", id);
        localStorage.setItem("billingsummary_filters", searchParams.toString());
        setSearchParams(searchParams);
    };

    const handleMonthClicked = (
        view: string,
        month: string,
        amount: number,
        count: number,
        credit?: number,
        refund?: number
    ) => {
        searchParams.set("view", view);
        searchParams.set("month", month);
        searchParams.set("amount", String(amount) || "");
        searchParams.set("count", String(count) || "");
        if (credit) {
            searchParams.set("credit", String(credit));
        }
        if (refund) {
            searchParams.set("refund", String(refund));
        }
        setOpenDialog(true);
        setSearchParams(searchParams);
    };

    const closeBreakdownDialog = () => {
        searchParams.delete("month");
        searchParams.delete("view");
        searchParams.delete("amount");
        searchParams.delete("count");
        searchParams.delete("credit");
        searchParams.delete("refund");
        searchParams.delete("provider");
        searchParams.delete("client");
        setOpenDialog(false);
        setSearchParams(searchParams);
    };

    const handleGetMonthlyBreakdown = () => {
        if (data?.data) {
            const sortMonthlyBreakdownDescending = data?.data.monthly_breakdown
                .sort((a, b) => b.payment_month - a.payment_month)
                .map((breakdown) => {
                    return {
                        ...breakdown,
                        amount_billed: breakdown.amount_billed,
                    };
                });

            return sortMonthlyBreakdownDescending;
        }

        return undefined;
    };

    // Check if breakdown list have data to display
    const breakdownList = handleGetMonthlyBreakdown();
    const doesBreakdownListHaveData = breakdownList && breakdownList.length > 0;

    const insuranceProviderOptionsForDropdown = [
        {
            insurance_provider_id: "self_pay",
            alias: "Self pay",
        },
        ...(insuranceProviders?.data?.data || []).map((insurance) => ({
            insurance_provider_id: insurance.insurance_provider_id,
            alias: insurance.alias,
        })),
    ];

    const getInsuranceTextToDisplay = React.useMemo(
        () =>
            [
                {
                    insurance_provider_id: "self_pay",
                    alias: "Self pay",
                },
                ...(insuranceProviders?.data?.data || []),
            ].find(
                (provider) =>
                    provider.insurance_provider_id === insuranceProvider
            )?.alias,
        [insuranceProviders?.data?.data, insuranceProvider]
    );

    return (
        <>
            <Dialog open={openDialog}>
                <DialogContent
                    handleCloseDialog={closeBreakdownDialog}
                    showFooter={false}
                    wrapperClass="w-[1186px]"
                    classNames="p-0"
                >
                    <BillingSummaryBreakdown />
                </DialogContent>
            </Dialog>
            <Card classNames="py-32 px-24 border border-strokedark">
                <div className="flex items-center gap-x-12 flex-wrap gap-y-24 justify-between">
                    <div className="flex flex-col gap-y-8 md:gap-y-12">
                        <h2 className="font-extrabold text-xl md:text-xxl">
                            Billing Summary
                        </h2>
                        <p className="text-graydark font-normal text-sm">
                            Get a quick overview of your billing activity and
                            performance
                        </p>
                    </div>
                    <div className="flex flex-col min-[500px]:flex-row items-start min-[500px]:items-center gap-x-12 flex-wrap gap-y-8 self-end">
                        <Dropdown>
                            <DropdownTrigger asChild>
                                <FilterButton
                                    text={makeStringFirstLetterCapital(
                                        removeEnumUnderscore(filter)
                                    )}
                                />
                            </DropdownTrigger>
                            <DropdownContent
                                align={isSmallScreen() ? "start" : "end"}
                                width={200}
                                maxHeight={216}
                                className="overflow-y-auto"
                            >
                                {[
                                    ...Object.values(
                                        BillingSummaryCardsFilters
                                    ),
                                ].map((item) => (
                                    <DropdownItem
                                        className={cn(
                                            "uppercase text-xs font-medium gap-x-8 flex items-center",
                                            {
                                                "text-primary": filter === item,
                                            }
                                        )}
                                        key={item}
                                        onClick={() =>
                                            handleSetFilterValue(item)
                                        }
                                    >
                                        {item === BillingSummaryCardsFilters[0]
                                            ? removeEnumUnderscore(item)
                                            : item}
                                        {filter === item ? (
                                            <CheckPrimaryColorIcon />
                                        ) : null}
                                    </DropdownItem>
                                ))}
                            </DropdownContent>
                        </Dropdown>
                        <Dropdown>
                            <DropdownTrigger
                                asChild
                                disabled={
                                    Boolean(insuranceProviders.error) ||
                                    insuranceProviders.isLoading ||
                                    typeFilter === "coinsurance"
                                }
                            >
                                <Button
                                    size="auto"
                                    variant="normal"
                                    className={cn(
                                        "px-16 h-32 shadow-active gap-x-8 text-sm data-[state=open]:border-primary font-medium capitalize",
                                        {
                                            uppercase: insuranceProvider,
                                        }
                                    )}
                                >
                                    {insuranceProviders.isLoading &&
                                        "Loading.."}
                                    {insuranceProviders.error &&
                                        "Error loading providers"}
                                    {insuranceProviders?.data?.data && (
                                        <>
                                            {getInsuranceTextToDisplay
                                                ? truncateString(
                                                      removeEnumUnderscore(
                                                          getInsuranceTextToDisplay
                                                      )
                                                  )
                                                : "Insurance"}
                                            <ChevronDownIcon
                                                stroke={APP_COLORS.COLOR_BLACK}
                                            />
                                        </>
                                    )}
                                </Button>
                            </DropdownTrigger>
                            <DropdownContent
                                align={isSmallScreen() ? "start" : "end"}
                                width={isSmallScreen() ? "250px" : "auto"}
                                maxHeight={216}
                                className="overflow-y-auto"
                            >
                                <DropdownItem
                                    className={cn(
                                        "uppercase text-xs font-medium flex items-center gap-x-8",
                                        {
                                            "text-primary": !insuranceProvider,
                                        }
                                    )}
                                    onClick={() =>
                                        handleInsuranceProviderFilter("")
                                    }
                                >
                                    All
                                    {!insuranceProvider ? (
                                        <CheckPrimaryColorIcon />
                                    ) : null}
                                </DropdownItem>
                                {insuranceProviderOptionsForDropdown
                                    .sort((a, b) =>
                                        a.alias.localeCompare(b.alias)
                                    )
                                    .map((provider) => (
                                        <DropdownItem
                                            className={cn(
                                                "uppercase text-xs font-medium flex items-center gap-x-8",
                                                {
                                                    "text-primary":
                                                        provider.insurance_provider_id ===
                                                        insuranceProvider,
                                                }
                                            )}
                                            onClick={() =>
                                                handleInsuranceProviderFilter(
                                                    provider.insurance_provider_id
                                                )
                                            }
                                            key={provider.insurance_provider_id}
                                        >
                                            {removeEnumUnderscore(
                                                provider.alias
                                            ).toUpperCase()}{" "}
                                            {insuranceProvider ===
                                            provider.insurance_provider_id ? (
                                                <CheckPrimaryColorIcon />
                                            ) : null}
                                        </DropdownItem>
                                    ))}
                            </DropdownContent>
                        </Dropdown>
                        <Switch
                            activeSwitch={typeFilter}
                            switchOptions={switchOptions}
                        />
                    </div>
                </div>

                {isLoading && (
                    <div className="mt-16 grid gap-24 grid-cols-[repeat(auto-fit,minmax(300px,1fr))] md:grid-cols-[repeat(auto-fit,minmax(500px,1fr))]">
                        <Skeleton
                            height="300px"
                            className="rounded-r8"
                            containerTestId="summary-loader"
                        />
                        <Skeleton
                            height="300px"
                            className="rounded-r8"
                            containerTestId="summary-loader"
                        />
                        <Skeleton
                            height="300px"
                            className="rounded-r8"
                            containerTestId="summary-loader"
                        />
                        <Skeleton
                            height="300px"
                            className="rounded-r8"
                            containerTestId="summary-loader"
                        />
                        <Skeleton
                            height="300px"
                            className="rounded-r8"
                            containerTestId="summary-loader"
                        />
                        <Skeleton
                            height="300px"
                            className="rounded-r8"
                            containerTestId="summary-loader"
                        />
                    </div>
                )}
                {error && (
                    <ListState
                        isError
                        stateHelperText="Try reloading this page to solve this issue"
                        errorMsg={
                            error.response?.data.message ||
                            "Cannot display billing dashboard breakdown at this time please try again later"
                        }
                        cardClass="mt-16"
                    />
                )}
                {data && isSuccess && (
                    <div className="mt-16 grid gap-24 grid-cols-[repeat(auto-fit,minmax(400px,1fr))] md:grid-cols-[repeat(auto-fit,minmax(500px,1fr))] max-w-full overflow-x-auto">
                        <BillingSummaryCard
                            bgColor="#FFF6FB"
                            title="Encounter"
                            tooltipDescription="Encounter shows the total number of sessions."
                            description="Total Encounter"
                            count={handleGetTotalEncounters(
                                data.data.monthly_breakdown
                            )}
                            showList={doesBreakdownListHaveData}
                            list={
                                <>
                                    <div className="r4 bg-[#F6E7EF] py-[5px] px-8 grid grid-cols-3 gap-x-4 md:gap-x-8 mb-12 md:mb-16 text-graydark font-normal text-xss text-left">
                                        <span className="capitalize">
                                            Month
                                        </span>
                                        <span>Total number</span>
                                        <span>Indicator</span>
                                    </div>
                                    <div className="flex flex-col gap-y-12">
                                        {handleGetMonthlyBreakdown()?.map(
                                            (item, i, arr) => (
                                                <span
                                                    key={`encounter - ${item.payment_month}`}
                                                    className="grid grid-cols-3 px-8 py-0 gap-x-4 md:gap-x-8 rounded-none items-start justify-start text-xss text-left  font-normal"
                                                >
                                                    <span className="capitalize">
                                                        {
                                                            billingPaymentMonths[
                                                                item.payment_month -
                                                                    1
                                                            ]
                                                        }
                                                    </span>
                                                    <span>
                                                        {item.encounters}
                                                    </span>
                                                    <span className="flex items-center justify-start gap-x-4">
                                                        {arr[i]?.encounters ===
                                                            arr[i + 1]
                                                                ?.encounters &&
                                                            ""}
                                                        {arr[i]?.encounters >
                                                            arr[i + 1]
                                                                ?.encounters && (
                                                            <>
                                                                <TrendingUpIcon />
                                                                {getPercentageDifference(
                                                                    arr[i + 1]
                                                                        ?.encounters,
                                                                    arr[i]
                                                                        ?.encounters
                                                                )}
                                                            </>
                                                        )}
                                                        {arr[i]?.encounters <
                                                            arr[i + 1]
                                                                ?.encounters && (
                                                            <>
                                                                <TrendingDownIcon />
                                                                {getPercentageDifference(
                                                                    arr[i + 1]
                                                                        ?.encounters,
                                                                    arr[i]
                                                                        ?.encounters
                                                                )}
                                                            </>
                                                        )}
                                                    </span>
                                                </span>
                                            )
                                        )}
                                    </div>
                                </>
                            }
                        />
                        <BillingSummaryCard
                            bgColor="#ECFBFF"
                            title="Billed"
                            tooltipDescription="Billed displays charges that have been submitted for billing."
                            description="Total Amount Billed"
                            count={showMoneyInAppFormat(
                                data.data.summary.amount_billed
                            )}
                            smallCount={`${
                                data.data.summary.amount_billed_count
                            } bill${
                                data.data.summary.amount_billed_count > 1
                                    ? "s"
                                    : ""
                            }`}
                            showList={doesBreakdownListHaveData}
                            list={
                                <>
                                    <div className="r4 bg-[#DFEFF3] py-[5px] px-8 grid grid-cols-4 gap-x-4 md:gap-x-8 mb-12 md:mb-16 text-graydark font-normal text-xss text-left">
                                        <span className="capitalize">
                                            Month
                                        </span>
                                        <span>Total amount</span>
                                        <span>Total bills</span>
                                        <span>Indicator</span>
                                    </div>
                                    <div className="flex flex-col gap-y-12">
                                        {handleGetMonthlyBreakdown()?.map(
                                            (item, i, arr) => (
                                                <Button
                                                    size="auto"
                                                    variant="normal"
                                                    key={`billed - ${item.payment_month}`}
                                                    className="grid grid-cols-4 px-8 py-0 gap-x-4 md:gap-x-8 rounded-none items-start justify-start text-xss hover:bg-[#DFEFF3] font-normal text-left"
                                                    onClick={() =>
                                                        handleMonthClicked(
                                                            "amount_billed",
                                                            billingPaymentMonths[
                                                                item.payment_month -
                                                                    1
                                                            ],

                                                            item.amount_billed,
                                                            item.amount_billed_count
                                                        )
                                                    }
                                                >
                                                    <span className="capitalize">
                                                        {
                                                            billingPaymentMonths[
                                                                item.payment_month -
                                                                    1
                                                            ]
                                                        }
                                                    </span>
                                                    <span>
                                                        {showMoneyInAppFormat(
                                                            item.amount_billed
                                                        )}
                                                    </span>
                                                    <span>
                                                        {
                                                            item.amount_billed_count
                                                        }
                                                    </span>
                                                    <span className="flex items-center justify-start gap-x-4">
                                                        {arr[i]
                                                            ?.amount_billed ===
                                                            arr[i + 1]
                                                                ?.amount_billed &&
                                                            ""}
                                                        {arr[i]?.amount_billed >
                                                            arr[i + 1]
                                                                ?.amount_billed && (
                                                            <>
                                                                <TrendingUpIcon />
                                                                {getPercentageDifference(
                                                                    arr[i + 1]
                                                                        ?.amount_billed,
                                                                    arr[i]
                                                                        ?.amount_billed
                                                                )}
                                                            </>
                                                        )}
                                                        {arr[i]?.amount_billed <
                                                            arr[i + 1]
                                                                ?.amount_billed && (
                                                            <>
                                                                <TrendingDownIcon />
                                                                {getPercentageDifference(
                                                                    arr[i + 1]
                                                                        ?.amount_billed,
                                                                    arr[i]
                                                                        ?.amount_billed
                                                                )}
                                                            </>
                                                        )}
                                                    </span>
                                                </Button>
                                            )
                                        )}
                                    </div>
                                </>
                            }
                        />
                        <BillingSummaryCard
                            bgColor="#EDF0FA"
                            title="Collected"
                            tooltipDescription="Collected shows payments that have been successfully collected."
                            description="Total Amount Collected"
                            count={showMoneyInAppFormat(
                                data.data.summary.amount_collected
                            )}
                            smallCount={`${
                                data.data.summary.amount_collected_count
                            } payment${
                                data.data.summary.amount_collected_count > 1
                                    ? "s"
                                    : ""
                            } collected`}
                            showList={doesBreakdownListHaveData}
                            list={
                                <>
                                    <div className="r4 bg-[#E0E5F7] py-[5px] px-8 grid grid-cols-4 gap-x-4 md:gap-x-8 mb-12 md:mb-16 text-graydark font-normal text-xss text-left">
                                        <span className="capitalize">
                                            Month
                                        </span>
                                        <span>Total amount</span>
                                        <span className="text-wrap">
                                            Total collected payments
                                        </span>
                                        <span>Indicator</span>
                                    </div>
                                    <div className="flex flex-col gap-y-12">
                                        {handleGetMonthlyBreakdown()?.map(
                                            (item, i, arr) => (
                                                <Button
                                                    size="auto"
                                                    variant="normal"
                                                    key={`collected - ${item.payment_month}`}
                                                    className="grid grid-cols-4 px-8 py-0 gap-x-4 md:gap-x-8 rounded-none items-start justify-start text-xss hover:bg-[#E0E5F7] font-normal text-left"
                                                    onClick={() =>
                                                        handleMonthClicked(
                                                            "amount_collected",
                                                            billingPaymentMonths[
                                                                item.payment_month -
                                                                    1
                                                            ],
                                                            item.amount_collected,
                                                            item.amount_collected_count,
                                                            item.total_credit,
                                                            item.total_refunds
                                                        )
                                                    }
                                                >
                                                    <span className="capitalize">
                                                        {
                                                            billingPaymentMonths[
                                                                item.payment_month -
                                                                    1
                                                            ]
                                                        }
                                                    </span>
                                                    <span>
                                                        {showMoneyInAppFormat(
                                                            item.amount_collected
                                                        )}
                                                    </span>
                                                    <span>
                                                        {
                                                            item.amount_collected_count
                                                        }
                                                    </span>
                                                    <span className="flex items-center justify-start gap-x-4">
                                                        {arr[i]
                                                            ?.amount_collected ===
                                                            arr[i + 1]
                                                                ?.amount_collected &&
                                                            ""}
                                                        {arr[i]
                                                            ?.amount_collected >
                                                            arr[i + 1]
                                                                ?.amount_collected && (
                                                            <>
                                                                <TrendingUpIcon />
                                                                {getPercentageDifference(
                                                                    arr[i + 1]
                                                                        ?.amount_collected,
                                                                    arr[i]
                                                                        ?.amount_collected
                                                                )}
                                                            </>
                                                        )}
                                                        {arr[i]
                                                            ?.amount_collected <
                                                            arr[i + 1]
                                                                ?.amount_collected && (
                                                            <>
                                                                <TrendingDownIcon />
                                                                {getPercentageDifference(
                                                                    arr[i + 1]
                                                                        ?.amount_collected,
                                                                    arr[i]
                                                                        ?.amount_collected
                                                                )}
                                                            </>
                                                        )}
                                                    </span>
                                                </Button>
                                            )
                                        )}
                                    </div>
                                </>
                            }
                        />
                        <BillingSummaryCard
                            bgColor="rgba(26, 115, 141, 0.05)"
                            title="Aging"
                            tooltipDescription="Aging shows the aging status of outstanding invoices or claims."
                            description="Total Amount"
                            count={showMoneyInAppFormat(
                                data.data.summary.amount_outstanding
                            )}
                            smallCount={`${
                                data.data.summary.amount_outstanding_count
                            } bill${
                                data.data.summary.amount_outstanding_count > 1
                                    ? "s"
                                    : ""
                            }`}
                            showList={doesBreakdownListHaveData}
                            list={
                                <>
                                    <div className="r4 bg-[#DDE5E7] py-[5px] px-8 grid grid-cols-4 gap-x-4 md:gap-x-8 mb-12 md:mb-16 text-graydark font-normal text-xss text-left">
                                        <span className="capitalize">
                                            Month
                                        </span>
                                        <span>Total amount</span>
                                        <span>Total aged bills</span>
                                        <span>Indicator</span>
                                    </div>
                                    <div className="flex flex-col gap-y-12">
                                        {handleGetMonthlyBreakdown()?.map(
                                            (item, i, arr) => (
                                                <Button
                                                    size="auto"
                                                    variant="normal"
                                                    key={`aging - ${item.payment_month}`}
                                                    className="grid grid-cols-4 px-8 py-0 gap-x-4 md:gap-x-8 rounded-none items-start justify-start text-xss hover:bg-[#DDE5E7] font-normal text-left"
                                                    onClick={() =>
                                                        handleMonthClicked(
                                                            "amount_outstanding",
                                                            billingPaymentMonths[
                                                                item.payment_month -
                                                                    1
                                                            ],
                                                            item.amount_outstanding,
                                                            item.amount_outstanding_count
                                                        )
                                                    }
                                                >
                                                    <span className="capitalize">
                                                        {
                                                            billingPaymentMonths[
                                                                item.payment_month -
                                                                    1
                                                            ]
                                                        }
                                                    </span>
                                                    <span>
                                                        {showMoneyInAppFormat(
                                                            item.amount_outstanding
                                                        )}
                                                    </span>
                                                    <span>
                                                        {
                                                            item.amount_outstanding_count
                                                        }
                                                    </span>
                                                    <span className="flex items-center justify-start gap-x-4">
                                                        {arr[i]
                                                            ?.amount_outstanding ===
                                                            arr[i + 1]
                                                                ?.amount_outstanding &&
                                                            ""}
                                                        {arr[i]
                                                            ?.amount_outstanding >
                                                            arr[i + 1]
                                                                ?.amount_outstanding && (
                                                            <>
                                                                <TrendingUpIcon />
                                                                {getPercentageDifference(
                                                                    arr[i + 1]
                                                                        ?.amount_outstanding,
                                                                    arr[i]
                                                                        ?.amount_outstanding
                                                                )}
                                                            </>
                                                        )}
                                                        {arr[i]
                                                            ?.amount_outstanding <
                                                            arr[i + 1]
                                                                ?.amount_outstanding && (
                                                            <>
                                                                <TrendingDownIcon />
                                                                {getPercentageDifference(
                                                                    arr[i + 1]
                                                                        ?.amount_outstanding,
                                                                    arr[i]
                                                                        ?.amount_outstanding
                                                                )}
                                                            </>
                                                        )}
                                                    </span>
                                                </Button>
                                            )
                                        )}
                                    </div>
                                </>
                            }
                        />
                        <BillingSummaryCard
                            bgColor="#F7F3E9"
                            title="Unmatched Payment"
                            tooltipDescription="Unmatched payments shows payments with no matching claims or invoices."
                            description="Total Unmatched Payment"
                            count={showMoneyInAppFormat(
                                data.data.summary.amount_unmatched
                            )}
                            smallCount={`${data.data.summary.amount_unmatched_count} unmatched payment`}
                            showList={
                                data.data.unmatched_payment_monthly_stat &&
                                data.data.unmatched_payment_monthly_stat
                                    .length > 0
                            }
                            list={
                                <>
                                    <div className="r4 bg-[#EBE7DB] py-[5px] px-8 grid grid-cols-4 gap-x-4 md:gap-x-8 mb-12 md:mb-16 text-graydark font-normal text-xss text-left">
                                        <span className="capitalize">
                                            Month
                                        </span>
                                        <span>Total amount</span>
                                        <span>Total aged bills</span>
                                        <span>Indicator</span>
                                    </div>
                                    <div className="flex flex-col gap-y-12">
                                        {data.data.unmatched_payment_monthly_stat
                                            ?.sort(
                                                (a, b) =>
                                                    b.payment_month -
                                                    a.payment_month
                                            )
                                            .map((item, i, arr) => (
                                                <Button
                                                    size="auto"
                                                    variant="normal"
                                                    key={`unmatched amount - ${item.payment_month}`}
                                                    className="grid grid-cols-4 px-8 py-0 gap-x-4 md:gap-x-8 rounded-none items-start justify-start text-xss hover:bg-[#EBE7DB] font-normal text-left"
                                                    onClick={() =>
                                                        handleMonthClicked(
                                                            "unmatched_payments",
                                                            billingPaymentMonths[
                                                                item.payment_month -
                                                                    1
                                                            ],
                                                            item.unmatched_amount_collected,
                                                            item.unmatched_amount_collected_count
                                                        )
                                                    }
                                                >
                                                    <span className="capitalize">
                                                        {
                                                            billingPaymentMonths[
                                                                item.payment_month -
                                                                    1
                                                            ]
                                                        }
                                                    </span>
                                                    <span>
                                                        {showMoneyInAppFormat(
                                                            item.unmatched_amount_collected
                                                        )}
                                                    </span>
                                                    <span>
                                                        {
                                                            item.unmatched_amount_collected_count
                                                        }
                                                    </span>
                                                    <span className="flex items-center justify-start gap-x-4">
                                                        {arr[i]
                                                            ?.unmatched_amount_collected ===
                                                            arr[i + 1]
                                                                ?.unmatched_amount_collected &&
                                                            ""}
                                                        {arr[i]
                                                            ?.unmatched_amount_collected >
                                                            arr[i + 1]
                                                                ?.unmatched_amount_collected && (
                                                            <>
                                                                <TrendingUpIcon />
                                                                {getPercentageDifference(
                                                                    arr[i + 1]
                                                                        ?.unmatched_amount_collected,
                                                                    arr[i]
                                                                        ?.unmatched_amount_collected
                                                                )}
                                                            </>
                                                        )}
                                                        {arr[i]
                                                            ?.unmatched_amount_collected <
                                                            arr[i + 1]
                                                                ?.unmatched_amount_collected && (
                                                            <>
                                                                <TrendingDownIcon />
                                                                {getPercentageDifference(
                                                                    arr[i + 1]
                                                                        ?.unmatched_amount_collected,
                                                                    arr[i]
                                                                        ?.unmatched_amount_collected
                                                                )}
                                                            </>
                                                        )}
                                                    </span>
                                                </Button>
                                            ))}
                                    </div>
                                </>
                            }
                        />
                        <BillingSummaryCard
                            bgColor="#F9EDED"
                            title="Submitted claims"
                            tooltipDescription="Submitted claims lists claims that have been submitted for processing."
                            description="Total Submitted Claims"
                            count={data.data.summary.submitted_claims_count}
                            smallCount={showMoneyInAppFormat(
                                data.data.summary.submitted_claims_amount
                            )}
                            hide={typeFilter === "coinsurance"}
                            showList={doesBreakdownListHaveData}
                            list={
                                <>
                                    <div className="r4 bg-[#EEDEDE] py-[5px] px-8 grid grid-cols-4 gap-x-4 md:gap-x-8 mb-12 md:mb-16 text-graydark font-normal text-xss text-left">
                                        <span className="capitalize">
                                            Month
                                        </span>
                                        <span>Total amount</span>
                                        <span>Total claims</span>
                                        <span>Indicator</span>
                                    </div>
                                    <div className="flex flex-col gap-y-12">
                                        {handleGetMonthlyBreakdown()?.map(
                                            (item, i, arr) => (
                                                <Button
                                                    size="auto"
                                                    variant="normal"
                                                    key={`submitted claims - ${item.payment_month}`}
                                                    className="grid grid-cols-4 px-8 py-0 gap-x-4 md:gap-x-8 rounded-none items-start justify-start text-xss hover:bg-[#EEDEDE] font-normal text-left"
                                                    onClick={() =>
                                                        handleMonthClicked(
                                                            "submitted_claims",
                                                            billingPaymentMonths[
                                                                item.payment_month -
                                                                    1
                                                            ],
                                                            item.submitted_claims_amount,
                                                            item.submitted_claims_count
                                                        )
                                                    }
                                                >
                                                    <span className="capitalize">
                                                        {
                                                            billingPaymentMonths[
                                                                item.payment_month -
                                                                    1
                                                            ]
                                                        }
                                                    </span>
                                                    <span>
                                                        {showMoneyInAppFormat(
                                                            item.submitted_claims_amount
                                                        )}
                                                    </span>
                                                    <span>
                                                        {
                                                            item.submitted_claims_count
                                                        }
                                                    </span>
                                                    <span className="flex items-center justify-start gap-x-4">
                                                        {arr[i]
                                                            ?.submitted_claims_amount ===
                                                            arr[i + 1]
                                                                ?.submitted_claims_amount &&
                                                            ""}
                                                        {arr[i]
                                                            ?.submitted_claims_amount >
                                                            arr[i + 1]
                                                                ?.submitted_claims_amount && (
                                                            <>
                                                                <TrendingUpIcon />
                                                                {getPercentageDifference(
                                                                    arr[i + 1]
                                                                        ?.submitted_claims_amount,
                                                                    arr[i]
                                                                        ?.submitted_claims_amount
                                                                )}
                                                            </>
                                                        )}
                                                        {arr[i]
                                                            ?.submitted_claims_amount <
                                                            arr[i + 1]
                                                                ?.submitted_claims_amount && (
                                                            <>
                                                                <TrendingDownIcon />
                                                                {getPercentageDifference(
                                                                    arr[i + 1]
                                                                        ?.submitted_claims_amount,
                                                                    arr[i]
                                                                        ?.submitted_claims_amount
                                                                )}
                                                            </>
                                                        )}
                                                    </span>
                                                </Button>
                                            )
                                        )}
                                    </div>
                                </>
                            }
                        />
                        <BillingSummaryCard
                            bgColor="#E9F8E8"
                            title="Approved claims"
                            tooltipDescription="Approved claims displays claims that have been approved for payment by insurance."
                            description="Total Approved Claims"
                            count={data.data.summary.approved_claims_count}
                            smallCount={showMoneyInAppFormat(
                                data.data.summary.approved_claims_amount
                            )}
                            hide={typeFilter === "coinsurance"}
                            list={
                                <>
                                    <div className="r4 bg-[#D9EAD8] py-[5px] px-8 grid grid-cols-4 gap-x-8 mb-12 md:mb-16 text-graydark font-normal text-xss text-left">
                                        <span className="capitalize">
                                            Month
                                        </span>
                                        <span>Total amount</span>
                                        <span>Total claims</span>
                                        <span>Indicator</span>
                                    </div>
                                    <div className="flex flex-col gap-y-12">
                                        {handleGetMonthlyBreakdown()?.map(
                                            (item, i, arr) => (
                                                <Button
                                                    size="auto"
                                                    variant="normal"
                                                    key={`approved claims - ${item.payment_month}`}
                                                    className="grid grid-cols-4 px-8 py-0 gap-x-4 md:gap-x-8 rounded-none items-start justify-start text-xss hover:bg-[#D9EAD8] font-normal text-left"
                                                    onClick={() =>
                                                        handleMonthClicked(
                                                            "approved_claims",
                                                            billingPaymentMonths[
                                                                item.payment_month -
                                                                    1
                                                            ],
                                                            item.approved_claims_amount,
                                                            item.approved_claims_count
                                                        )
                                                    }
                                                >
                                                    <span className="capitalize text-left">
                                                        {
                                                            billingPaymentMonths[
                                                                item.payment_month -
                                                                    1
                                                            ]
                                                        }
                                                    </span>
                                                    <span>
                                                        {showMoneyInAppFormat(
                                                            item.approved_claims_amount
                                                        )}
                                                    </span>
                                                    <span>
                                                        {
                                                            item.approved_claims_count
                                                        }
                                                    </span>
                                                    <span className="flex items-center justify-start gap-x-4">
                                                        {arr[i]
                                                            ?.approved_claims_amount ===
                                                            arr[i + 1]
                                                                ?.approved_claims_amount &&
                                                            ""}
                                                        {arr[i]
                                                            ?.approved_claims_amount >
                                                            arr[i + 1]
                                                                ?.approved_claims_amount && (
                                                            <>
                                                                <TrendingUpIcon />
                                                                {getPercentageDifference(
                                                                    arr[i + 1]
                                                                        ?.approved_claims_amount,
                                                                    arr[i]
                                                                        ?.approved_claims_amount
                                                                )}
                                                            </>
                                                        )}
                                                        {arr[i]
                                                            ?.approved_claims_amount <
                                                            arr[i + 1]
                                                                ?.approved_claims_amount && (
                                                            <>
                                                                <TrendingDownIcon />
                                                                {getPercentageDifference(
                                                                    arr[i + 1]
                                                                        ?.approved_claims_amount,
                                                                    arr[i]
                                                                        ?.approved_claims_amount
                                                                )}
                                                            </>
                                                        )}
                                                    </span>
                                                </Button>
                                            )
                                        )}
                                    </div>
                                </>
                            }
                        />
                        <BillingSummaryCard
                            bgColor="#F4E8F8"
                            title="Denied claims"
                            tooltipDescription="Denied claims shows claims that have been denied by insurance."
                            description="Total Denied Claims"
                            count={data.data.summary.denied_claims_count}
                            smallCount={showMoneyInAppFormat(
                                data.data.summary.denied_claims_amount
                            )}
                            hide={typeFilter === "coinsurance"}
                            showList={doesBreakdownListHaveData}
                            list={
                                <>
                                    <div className="r4 bg-[#E8D7EE] py-[5px] px-8 grid grid-cols-4 gap-x-4 md:gap-x-8 mb-12 md:mb-16 text-graydark font-normal text-xss text-left">
                                        <span className="capitalize">
                                            Month
                                        </span>
                                        <span>Total amount</span>
                                        <span>Total claims</span>
                                        <span>Indicator</span>
                                    </div>
                                    <div className="flex flex-col gap-y-12">
                                        {handleGetMonthlyBreakdown()?.map(
                                            (item, i, arr) => (
                                                <Button
                                                    size="auto"
                                                    variant="normal"
                                                    key={`submitted claims - ${item.payment_month}`}
                                                    className="grid grid-cols-4 px-8 py-0 gap-x-4 md:gap-x-8 rounded-none items-start justify-start text-xss hover:bg-[#E8D7EE] font-normal text-left"
                                                    onClick={() =>
                                                        handleMonthClicked(
                                                            "denied_claims",
                                                            billingPaymentMonths[
                                                                item.payment_month -
                                                                    1
                                                            ],
                                                            item.denied_claims_amount,
                                                            item.denied_claims_count
                                                        )
                                                    }
                                                >
                                                    <span className="capitalize">
                                                        {
                                                            billingPaymentMonths[
                                                                item.payment_month -
                                                                    1
                                                            ]
                                                        }
                                                    </span>
                                                    <span>
                                                        {showMoneyInAppFormat(
                                                            item.denied_claims_amount
                                                        )}
                                                    </span>
                                                    <span>
                                                        {
                                                            item.denied_claims_count
                                                        }
                                                    </span>
                                                    <span className="flex items-center justify-start gap-x-4">
                                                        {arr[i]
                                                            ?.denied_claims_amount ===
                                                            arr[i + 1]
                                                                ?.denied_claims_amount &&
                                                            ""}
                                                        {arr[i]
                                                            ?.denied_claims_amount >
                                                            arr[i + 1]
                                                                ?.denied_claims_amount && (
                                                            <>
                                                                <TrendingUpIcon />
                                                                {getPercentageDifference(
                                                                    arr[i + 1]
                                                                        ?.denied_claims_amount,
                                                                    arr[i]
                                                                        ?.denied_claims_amount
                                                                )}
                                                            </>
                                                        )}
                                                        {arr[i]
                                                            ?.denied_claims_amount <
                                                            arr[i + 1]
                                                                ?.denied_claims_amount && (
                                                            <>
                                                                <TrendingDownIcon />
                                                                {getPercentageDifference(
                                                                    arr[i + 1]
                                                                        ?.denied_claims_amount,
                                                                    arr[i]
                                                                        ?.denied_claims_amount
                                                                )}
                                                            </>
                                                        )}
                                                    </span>
                                                </Button>
                                            )
                                        )}
                                    </div>
                                </>
                            }
                        />
                        <BillingSummaryCard
                            bgColor="#F8E8E8"
                            title="PR100 Collected"
                            tooltipDescription="Collected shows PR100 payments that have been successfully collected from the Patient."
                            description="Total PR100 Collected"
                            count={data.data.summary.pr100_collected_count || 0}
                            smallCount={
                                data.data.summary.pr100_collected
                                    ? showMoneyInAppFormat(
                                          data.data.summary.pr100_collected
                                      )
                                    : "--"
                            }
                            hide={typeFilter === "coinsurance"}
                            showList={doesBreakdownListHaveData}
                            list={
                                <>
                                    <div className="r4 bg-[#EED7D7] py-[5px] px-8 grid grid-cols-4 gap-x-4 md:gap-x-8 mb-12 md:mb-16 text-graydark font-normal text-xss text-left">
                                        <span className="capitalize">
                                            Month
                                        </span>
                                        <span>Total amount</span>
                                        <span>Total PR100 collected</span>
                                        <span>Indicator</span>
                                    </div>
                                    <div className="flex flex-col gap-y-12">
                                        <div className="flex flex-col gap-y-12">
                                            {handleGetMonthlyBreakdown()?.map(
                                                (item, i, arr) => (
                                                    <Button
                                                        size="auto"
                                                        variant="normal"
                                                        key={`collected - ${item.payment_month}`}
                                                        className="grid grid-cols-4 px-8 py-0 gap-x-4 md:gap-x-8 rounded-none items-start justify-start text-xss hover:bg-[#EED7D7] font-normal text-left"
                                                        onClick={() =>
                                                            handleMonthClicked(
                                                                "pr100_collected",
                                                                billingPaymentMonths[
                                                                    item.payment_month -
                                                                        1
                                                                ],
                                                                item.pr100_collected,
                                                                item.pr100_collected_count
                                                            )
                                                        }
                                                    >
                                                        <span className="capitalize">
                                                            {
                                                                billingPaymentMonths[
                                                                    item.payment_month -
                                                                        1
                                                                ]
                                                            }
                                                        </span>
                                                        <span>
                                                            {showMoneyInAppFormat(
                                                                item.pr100_collected
                                                            )}
                                                        </span>
                                                        <span>
                                                            {
                                                                item.pr100_collected_count
                                                            }
                                                        </span>
                                                        <span className="flex items-center justify-start gap-x-4">
                                                            {arr[i]
                                                                ?.pr100_collected ===
                                                                arr[i + 1]
                                                                    ?.pr100_collected &&
                                                                ""}
                                                            {arr[i]
                                                                ?.pr100_collected >
                                                                arr[i + 1]
                                                                    ?.pr100_collected && (
                                                                <>
                                                                    <TrendingUpIcon />
                                                                    {getPercentageDifference(
                                                                        arr[
                                                                            i +
                                                                                1
                                                                        ]
                                                                            ?.pr100_collected,
                                                                        arr[i]
                                                                            ?.pr100_collected
                                                                    )}
                                                                </>
                                                            )}
                                                            {arr[i]
                                                                ?.pr100_collected <
                                                                arr[i + 1]
                                                                    ?.pr100_collected && (
                                                                <>
                                                                    <TrendingDownIcon />
                                                                    {getPercentageDifference(
                                                                        arr[
                                                                            i +
                                                                                1
                                                                        ]
                                                                            ?.pr100_collected,
                                                                        arr[i]
                                                                            ?.pr100_collected
                                                                    )}
                                                                </>
                                                            )}
                                                        </span>
                                                    </Button>
                                                )
                                            )}
                                        </div>
                                    </div>
                                </>
                            }
                        />
                    </div>
                )}
            </Card>
        </>
    );
}
