import cn from "classnames";
import { useGetDiagnosisCodesDisplay } from "../../../../hooks/helpers";
import styles from "../../../../modules/notes/components/NoteContent/NoteContent.module.scss";
import CheckIcon from "../../../Icons/Check";
import { formatDate, removeEnumUnderscore } from "../../../../utils/helpers";
import {
    IAddendumDetails,
    IIndividualSoapNoteAnalysisResponse,
} from "../../../../utils/types/notes";
import NoteSuggestion, {
    NoteSuggestionType,
} from "../../NoteSuggestion/NoteSuggestion";
import NoteContentItem from "../NoteContentItem/NoteContentItem";
import {
    SOAP_NOTE_SECTIONS,
    SOAP_PLAN_NOTE_LABELS,
} from "../../../../utils/constants";
import { AppointmentLocationTeletherapy } from "../../../../utils/types/session";
import useGetDiagnosisCodeToDisplay from "../../../../modules/notes/hooks";

interface ViewSoapNoteProps {
    noteContent: {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        [key: string]: any;
    };
    /**
     * Is this view for family soap note
     */
    isFamilySoap: boolean;
    /** Note analysis deescription */
    noteAnalysis?: Partial<IIndividualSoapNoteAnalysisResponse>;
    fieldAddendums: {
        [key: string]: IAddendumDetails[];
    } | null;
}

function buildNoteSuggestion(score?: number, suggestion?: string) {
    if (
        score === undefined ||
        !Number.isInteger(score) ||
        !suggestion ||
        !suggestion?.trim()
    ) {
        return null;
    }
    let noteSuggestionType: NoteSuggestionType = "green";
    if (score > 8) {
        noteSuggestionType = "green";
    } else if (score > 5) {
        noteSuggestionType = "amber";
    } else if (score > 0) {
        noteSuggestionType = "red";
    }
    return <NoteSuggestion type={noteSuggestionType} details={suggestion} />;
}

export default function ViewSoapNote({
    noteContent,
    isFamilySoap,
    noteAnalysis,
    fieldAddendums,
}: ViewSoapNoteProps) {
    const diagonosisCodes = useGetDiagnosisCodesDisplay(
        noteContent.diagnosis_codes
    );

    const isCheckboxChecked = (checkboxValue: string, key: string) => {
        return Boolean(
            noteContent?.[key]?.find((value: string) => value === checkboxValue)
        );
    };

    return (
        <>
            {isFamilySoap ? (
                <NoteContentItem
                    headerText={SOAP_PLAN_NOTE_LABELS.isClientPresent}
                    fieldId="isClientPresent"
                    addendums={fieldAddendums?.isClientPresent}
                    content={
                        <div className={styles.checkbox}>
                            <span
                                className={cn(styles.checkbox_wrap, {
                                    [styles.checked]:
                                        noteContent.isClientPresent,
                                })}
                            >
                                <CheckIcon />
                            </span>
                            {SOAP_PLAN_NOTE_LABELS.isClientPresent}
                        </div>
                    }
                />
            ) : null}
            <NoteContentItem
                headerText={SOAP_PLAN_NOTE_LABELS.appointment_location}
                fieldId="appointment_location"
                addendums={fieldAddendums?.appointment_location}
                content={
                    removeEnumUnderscore(noteContent.appointment_location) ||
                    "--"
                }
            />
            <NoteContentItem
                headerText={SOAP_PLAN_NOTE_LABELS.delivery_option}
                fieldId="delivery_option"
                addendums={fieldAddendums?.delivery_option}
                content={noteContent.delivery_option || "--"}
            />
            {noteContent.other_delivery_option &&
            noteContent.delivery_option ===
                AppointmentLocationTeletherapy.OTHER ? (
                <NoteContentItem
                    headerText={SOAP_PLAN_NOTE_LABELS.other_delivery_option}
                    fieldId="other_delivery_option"
                    addendums={fieldAddendums?.other_delivery_option}
                    content={noteContent.other_delivery_option || "--"}
                />
            ) : null}
            <NoteContentItem
                headerText={SOAP_PLAN_NOTE_LABELS.provider_location}
                fieldId="provider_location"
                addendums={fieldAddendums?.provider_location}
                content={noteContent.provider_location || "--"}
            />

            {noteContent.other_provider_location &&
            noteContent.provider_location ===
                AppointmentLocationTeletherapy.OTHER ? (
                <NoteContentItem
                    headerText={SOAP_PLAN_NOTE_LABELS.other_provider_location}
                    fieldId="other_provider_location"
                    addendums={fieldAddendums?.other_provider_location}
                    content={noteContent.other_provider_location || "--"}
                />
            ) : null}
            <NoteContentItem
                headerText={
                    SOAP_PLAN_NOTE_LABELS.consent_was_received_for_the_teletherapy_session
                }
                fieldId="consent_was_received_for_the_teletherapy_session"
                addendums={
                    fieldAddendums?.consent_was_received_for_the_teletherapy_session
                }
                content={
                    <div className={styles.checkbox}>
                        <span
                            className={cn(styles.checkbox_wrap, {
                                [styles.checked]:
                                    noteContent.consent_was_received_for_the_teletherapy_session,
                            })}
                        >
                            <CheckIcon />
                        </span>
                        {
                            SOAP_PLAN_NOTE_LABELS.consent_was_received_for_the_teletherapy_session
                        }
                    </div>
                }
            />
            <NoteContentItem
                headerText={
                    SOAP_PLAN_NOTE_LABELS.the_teletherapy_session_was_conducted_via_video
                }
                fieldId="the_teletherapy_session_was_conducted_via_video"
                addendums={
                    fieldAddendums?.the_teletherapy_session_was_conducted_via_video
                }
                content={
                    <div className={styles.checkbox}>
                        <span
                            className={cn(styles.checkbox_wrap, {
                                [styles.checked]:
                                    noteContent.the_teletherapy_session_was_conducted_via_video,
                            })}
                        >
                            <CheckIcon />
                        </span>
                        {
                            SOAP_PLAN_NOTE_LABELS.the_teletherapy_session_was_conducted_via_video
                        }
                    </div>
                }
            />
            <NoteContentItem
                headerText={SOAP_PLAN_NOTE_LABELS.diagnosis_codes}
                fieldId="diagnosis_codes"
                addendums={fieldAddendums?.diagnosis_codes}
                content={
                    diagonosisCodes && Array.isArray(diagonosisCodes) ? (
                        <div className={styles.checkboxes}>
                            {diagonosisCodes.map((code) => (
                                <div className={styles.checkbox} key={code}>
                                    <span
                                        className={cn(styles.checkbox_wrap, {
                                            [styles.checked]: true,
                                        })}
                                    >
                                        <CheckIcon />
                                    </span>
                                    {code}
                                </div>
                            ))}
                        </div>
                    ) : (
                        "--"
                    )
                }
            />
            <NoteContentItem
                headerText={SOAP_PLAN_NOTE_LABELS.secondary_diagnosis}
                fieldId="secondary_diagnosis"
                addendums={fieldAddendums?.secondary_diagnosis}
                content={useGetDiagnosisCodeToDisplay(
                    noteContent.secondary_diagnosis
                )}
            />
            <NoteContentItem
                headerText={SOAP_PLAN_NOTE_LABELS.tertiary_diagnosis}
                fieldId="tertiary_diagnosis"
                addendums={fieldAddendums?.tertiary_diagnosis}
                content={useGetDiagnosisCodeToDisplay(
                    noteContent.tertiary_diagnosis
                )}
            />
            <NoteContentItem
                headerText={
                    SOAP_PLAN_NOTE_LABELS.emotional_behavioural_symptoms_demonstrated
                }
                fieldId="emotional_behavioural_symptoms_demonstrated"
                addendums={
                    fieldAddendums?.emotional_behavioural_symptoms_demonstrated
                }
                content={
                    noteContent.emotional_behavioural_symptoms_demonstrated ||
                    "--"
                }
            />
            <NoteContentItem
                headerText={SOAP_PLAN_NOTE_LABELS.causing}
                fieldId="causing"
                addendums={fieldAddendums?.causing}
                content={noteContent.causing || "--"}
            />
            <NoteContentItem
                headerText={SOAP_PLAN_NOTE_LABELS.intention_for_session}
                fieldId="intention_for_session"
                addendums={fieldAddendums?.intention_for_session}
                content={noteContent.intention_for_session || "--"}
            />
            <p
                className={styles.sectionheader}
                id={SOAP_NOTE_SECTIONS["session note"]}
            >
                Session Note
            </p>
            <NoteContentItem
                headerText={SOAP_PLAN_NOTE_LABELS.subjective}
                fieldId="subjective"
                addendums={fieldAddendums?.subjective}
                content={noteContent.subjective || "--"}
            />
            {buildNoteSuggestion(
                noteAnalysis?.subjective?.score,
                noteAnalysis?.subjective?.suggestion
            )}
            <NoteContentItem
                headerText={SOAP_PLAN_NOTE_LABELS.objective}
                fieldId="objective"
                addendums={fieldAddendums?.objective}
                content={noteContent.objective || "--"}
            />
            {buildNoteSuggestion(
                noteAnalysis?.objective?.score,
                noteAnalysis?.objective?.suggestion
            )}
            <NoteContentItem
                headerText={SOAP_PLAN_NOTE_LABELS.assessment}
                fieldId="assessment"
                addendums={fieldAddendums?.assessment}
                content={noteContent.assessment || "--"}
            />
            {buildNoteSuggestion(
                noteAnalysis?.assessment?.score,
                noteAnalysis?.assessment?.suggestion
            )}

            <p
                className={styles.sectionheader}
                id={SOAP_NOTE_SECTIONS["therapy intervention techniques"]}
            >
                Therapy Intervention Techniques
            </p>
            <NoteContentItem
                headerText={
                    SOAP_PLAN_NOTE_LABELS.cognitive_behavioral_therapies
                }
                fieldId="cognitive_behavioral_therapies"
                addendums={fieldAddendums?.cognitive_behavioral_therapies}
                content={
                    noteContent?.cognitive_behavioral_therapies?.length > 0 ? (
                        <div className={styles.checkboxes}>
                            {isCheckboxChecked(
                                "Cognitive-Behavioral therapy (CBT)",
                                "cognitive_behavioral_therapies"
                            ) && (
                                <div className={styles.checkbox}>
                                    <span
                                        className={cn(
                                            styles.checkbox_wrap,
                                            styles.checked
                                        )}
                                    >
                                        <CheckIcon />
                                    </span>
                                    Cognitive-Behavioral therapy (CBT)
                                </div>
                            )}
                            {isCheckboxChecked(
                                "Dialectical Behavioral therapy (DBT)",
                                "cognitive_behavioral_therapies"
                            ) && (
                                <div className={styles.checkbox}>
                                    <span
                                        className={cn(
                                            styles.checkbox_wrap,
                                            styles.checked
                                        )}
                                    >
                                        <CheckIcon />
                                    </span>
                                    Dialectical Behavioral therapy (DBT)
                                </div>
                            )}
                            {isCheckboxChecked(
                                "Modeling and skills training",
                                "cognitive_behavioral_therapies"
                            ) && (
                                <div className={styles.checkbox}>
                                    <span
                                        className={cn(
                                            styles.checkbox_wrap,
                                            styles.checked
                                        )}
                                    >
                                        <CheckIcon />
                                    </span>
                                    Modeling and skills training
                                </div>
                            )}
                            {isCheckboxChecked(
                                "Trauma-focused CBT",
                                "cognitive_behavioral_therapies"
                            ) && (
                                <div className={styles.checkbox}>
                                    <span
                                        className={cn(
                                            styles.checkbox_wrap,
                                            styles.checked
                                        )}
                                    >
                                        <CheckIcon />
                                    </span>
                                    Trauma-focused CB
                                </div>
                            )}
                            {isCheckboxChecked(
                                "EDMR",
                                "cognitive_behavioral_therapies"
                            ) && (
                                <div className={styles.checkbox}>
                                    <span
                                        className={cn(
                                            styles.checkbox_wrap,
                                            styles.checked
                                        )}
                                    >
                                        <CheckIcon />
                                    </span>
                                    EDMR
                                </div>
                            )}
                            {isCheckboxChecked(
                                "Rational Emotive Behaviour therapy",
                                "cognitive_behavioral_therapies"
                            ) && (
                                <div className={styles.checkbox}>
                                    <span
                                        className={cn(
                                            styles.checkbox_wrap,
                                            styles.checked
                                        )}
                                    >
                                        <CheckIcon />
                                    </span>
                                    Rational Emotive Behaviour therapy
                                </div>
                            )}
                            {isCheckboxChecked(
                                "Acceptance Commitment Therapy",
                                "cognitive_behavioral_therapies"
                            ) && (
                                <div className={styles.checkbox}>
                                    <span
                                        className={cn(
                                            styles.checkbox_wrap,
                                            styles.checked
                                        )}
                                    >
                                        <CheckIcon />
                                    </span>
                                    Acceptance Commitment Therapy
                                </div>
                            )}
                            {isCheckboxChecked(
                                "Solution Based Brief Therapy",
                                "cognitive_behavioral_therapies"
                            ) && (
                                <div className={styles.checkbox}>
                                    <span
                                        className={cn(
                                            styles.checkbox_wrap,
                                            styles.checked
                                        )}
                                    >
                                        <CheckIcon />
                                    </span>
                                    Solution Based Brief Therapy
                                </div>
                            )}
                            {isCheckboxChecked(
                                "Mindfulness Based Cognitive Therapy",
                                "cognitive_behavioral_therapies"
                            ) && (
                                <div className={styles.checkbox}>
                                    <span
                                        className={cn(
                                            styles.checkbox_wrap,
                                            styles.checked
                                        )}
                                    >
                                        <CheckIcon />
                                    </span>
                                    Mindfulness Based Cognitive Therapy
                                </div>
                            )}
                        </div>
                    ) : (
                        "--"
                    )
                }
            />
            <NoteContentItem
                headerText={
                    SOAP_PLAN_NOTE_LABELS.relationship_based_interventions
                }
                fieldId="relationship_based_interventions"
                addendums={fieldAddendums?.relationship_based_interventions}
                content={
                    noteContent?.relationship_based_interventions?.length >
                    0 ? (
                        <div className={styles.checkboxes}>
                            {isCheckboxChecked(
                                "Attachment-oriented interventions",
                                "relationship_based_interventions"
                            ) && (
                                <div className={styles.checkbox}>
                                    <span
                                        className={cn(
                                            styles.checkbox_wrap,
                                            styles.checked
                                        )}
                                    >
                                        <CheckIcon />
                                    </span>
                                    Attachment-oriented interventions
                                </div>
                            )}
                            {isCheckboxChecked(
                                "Parent-child interaction therapy",
                                "relationship_based_interventions"
                            ) && (
                                <div className={styles.checkbox}>
                                    <span
                                        className={cn(
                                            styles.checkbox_wrap,
                                            styles.checked
                                        )}
                                    >
                                        <CheckIcon />
                                    </span>
                                    Parent-child interaction therapy
                                </div>
                            )}
                            {isCheckboxChecked(
                                "Parent interventions",
                                "relationship_based_interventions"
                            ) && (
                                <div className={styles.checkbox}>
                                    <span
                                        className={cn(
                                            styles.checkbox_wrap,
                                            styles.checked
                                        )}
                                    >
                                        <CheckIcon />
                                    </span>
                                    Parent interventions
                                </div>
                            )}
                        </div>
                    ) : (
                        "--"
                    )
                }
            />
            <NoteContentItem
                headerText={SOAP_PLAN_NOTE_LABELS.other}
                fieldId="other"
                addendums={fieldAddendums?.other}
                content={
                    noteContent?.other?.length > 0 ? (
                        <div className={styles.checkboxes}>
                            {isCheckboxChecked(
                                "Client centered therapy/ Humanism",
                                "other"
                            ) && (
                                <div className={styles.checkbox}>
                                    <span
                                        className={cn(
                                            styles.checkbox_wrap,
                                            styles.checked
                                        )}
                                    >
                                        <CheckIcon />
                                    </span>
                                    Client centered therapy/ Humanism
                                </div>
                            )}
                            {isCheckboxChecked("Gestalt therapy", "other") && (
                                <div className={styles.checkbox}>
                                    <span
                                        className={cn(
                                            styles.checkbox_wrap,
                                            styles.checked
                                        )}
                                    >
                                        <CheckIcon />
                                    </span>
                                    Gestalt therapy
                                </div>
                            )}
                            {isCheckboxChecked(
                                "Existential therapy",
                                "other"
                            ) && (
                                <div className={styles.checkbox}>
                                    <span
                                        className={cn(
                                            styles.checkbox_wrap,
                                            styles.checked
                                        )}
                                    >
                                        <CheckIcon />
                                    </span>
                                    Existential therapy
                                </div>
                            )}
                            {isCheckboxChecked("Feminist therapy", "other") && (
                                <div className={styles.checkbox}>
                                    <span
                                        className={cn(
                                            styles.checkbox_wrap,
                                            styles.checked
                                        )}
                                    >
                                        <CheckIcon />
                                    </span>
                                    Feminist therapy
                                </div>
                            )}
                            {isCheckboxChecked(
                                "Psychodynamic therapy",
                                "other"
                            ) && (
                                <div className={styles.checkbox}>
                                    <span
                                        className={cn(
                                            styles.checkbox_wrap,
                                            styles.checked
                                        )}
                                    >
                                        <CheckIcon />
                                    </span>
                                    Psychodynamic therapy
                                </div>
                            )}
                            {isCheckboxChecked("Grief therapy", "other") && (
                                <div className={styles.checkbox}>
                                    <span
                                        className={cn(
                                            styles.checkbox_wrap,
                                            styles.checked
                                        )}
                                    >
                                        <CheckIcon />
                                    </span>
                                    Grief therapy
                                </div>
                            )}
                            {isCheckboxChecked(
                                "Internal family systems (IFS)",
                                "other"
                            ) && (
                                <div className={styles.checkbox}>
                                    <span
                                        className={cn(
                                            styles.checkbox_wrap,
                                            styles.checked
                                        )}
                                    >
                                        <CheckIcon />
                                    </span>
                                    Internal family systems (IFS)
                                </div>
                            )}
                            {isCheckboxChecked(
                                "Narrative therapy",
                                "other"
                            ) && (
                                <div className={styles.checkbox}>
                                    <span
                                        className={cn(
                                            styles.checkbox_wrap,
                                            styles.checked
                                        )}
                                    >
                                        <CheckIcon />
                                    </span>
                                    Narrative therapy
                                </div>
                            )}
                            {isCheckboxChecked(
                                "Positive psychology",
                                "other"
                            ) && (
                                <div className={styles.checkbox}>
                                    <span
                                        className={cn(
                                            styles.checkbox_wrap,
                                            styles.checked
                                        )}
                                    >
                                        <CheckIcon />
                                    </span>
                                    Positive psychology
                                </div>
                            )}
                            {isCheckboxChecked("Psychoeducation", "other") && (
                                <div className={styles.checkbox}>
                                    <span
                                        className={cn(
                                            styles.checkbox_wrap,
                                            styles.checked
                                        )}
                                    >
                                        <CheckIcon />
                                    </span>
                                    Psychoeducation
                                </div>
                            )}
                            {isCheckboxChecked("Sex therapy", "other") && (
                                <div className={styles.checkbox}>
                                    <span
                                        className={cn(
                                            styles.checkbox_wrap,
                                            styles.checked
                                        )}
                                    >
                                        <CheckIcon />
                                    </span>
                                    Sex therapy
                                </div>
                            )}
                            {isCheckboxChecked(
                                "Strength based theory",
                                "other"
                            ) && (
                                <div className={styles.checkbox}>
                                    <span
                                        className={cn(
                                            styles.checkbox_wrap,
                                            styles.checked
                                        )}
                                    >
                                        <CheckIcon />
                                    </span>
                                    Strength based theory
                                </div>
                            )}
                            {isCheckboxChecked(
                                "Career Counseling",
                                "other"
                            ) && (
                                <div className={styles.checkbox}>
                                    <span
                                        className={cn(
                                            styles.checkbox_wrap,
                                            styles.checked
                                        )}
                                    >
                                        <CheckIcon />
                                    </span>
                                    Career Counseling
                                </div>
                            )}
                            {isCheckboxChecked(
                                "Multisystemic family theory",
                                "other"
                            ) && (
                                <div className={styles.checkbox}>
                                    <span
                                        className={cn(
                                            styles.checkbox_wrap,
                                            styles.checked
                                        )}
                                    >
                                        <CheckIcon />
                                    </span>
                                    Multisystemic family theory
                                </div>
                            )}
                        </div>
                    ) : (
                        "--"
                    )
                }
            />
            <NoteContentItem
                headerText={SOAP_PLAN_NOTE_LABELS.plan}
                fieldId="plan"
                addendums={fieldAddendums?.plan}
                content={noteContent.plan || "--"}
            />
            {buildNoteSuggestion(
                noteAnalysis?.plan?.score,
                noteAnalysis?.plan?.suggestion
            )}
            <NoteContentItem
                headerText={SOAP_PLAN_NOTE_LABELS.frequency_of_sessions}
                fieldId="frequency_of_sessions"
                addendums={fieldAddendums?.frequency_of_sessions}
                content={noteContent.frequency_of_sessions || "--"}
            />

            <NoteContentItem
                headerText={SOAP_PLAN_NOTE_LABELS.other_frequency_of_sessions}
                fieldId="other_frequency_of_sessions"
                addendums={fieldAddendums?.other_frequency_of_sessions}
                content={noteContent.other_frequency_of_sessions || "--"}
            />

            <NoteContentItem
                headerText={SOAP_PLAN_NOTE_LABELS.date_of_next_session}
                fieldId="date_of_next_session"
                addendums={fieldAddendums?.date_of_next_session}
                content={
                    noteContent.date_of_next_session
                        ? formatDate(noteContent.date_of_next_session, true)
                        : "--"
                }
            />
            <NoteContentItem
                headerText={SOAP_PLAN_NOTE_LABELS.prognosis}
                fieldId="prognosis"
                addendums={fieldAddendums?.prognosis}
                content={noteContent.prognosis || "--"}
            />
            {buildNoteSuggestion(
                noteAnalysis?.prognosis?.score,
                noteAnalysis?.prognosis?.suggestion
            )}
            <NoteContentItem
                headerText={SOAP_PLAN_NOTE_LABELS.client_response}
                fieldId="client_response"
                addendums={fieldAddendums?.client_response}
                content={noteContent.client_response || "--"}
            />
            {buildNoteSuggestion(
                noteAnalysis?.client_response?.score,
                noteAnalysis?.client_response?.suggestion
            )}
            <p
                className={styles.sectionheader}
                id={SOAP_NOTE_SECTIONS["treatment plan/ progress"]}
            >
                Treatment Plan/ Progress
            </p>
            <NoteContentItem
                headerText={SOAP_PLAN_NOTE_LABELS.goal_one}
                fieldId="goal_one"
                addendums={fieldAddendums?.goal_one}
                content={noteContent.goal_one || "--"}
            />
            {buildNoteSuggestion(
                noteAnalysis?.goal_one?.score,
                noteAnalysis?.goal_one?.suggestion
            )}
            <NoteContentItem
                headerText={SOAP_PLAN_NOTE_LABELS.progress_one}
                fieldId="progress_one"
                addendums={fieldAddendums?.progress_one}
                content={noteContent.progress_one || "--"}
            />
            {buildNoteSuggestion(
                noteAnalysis?.progress_one?.score,
                noteAnalysis?.progress_one?.suggestion
            )}
            <NoteContentItem
                headerText={SOAP_PLAN_NOTE_LABELS.goal_two}
                fieldId="goal_two"
                addendums={fieldAddendums?.goal_two}
                content={noteContent.goal_two || "--"}
            />
            {buildNoteSuggestion(
                noteAnalysis?.goal_two?.score,
                noteAnalysis?.goal_two?.suggestion
            )}
            <NoteContentItem
                headerText={SOAP_PLAN_NOTE_LABELS.progress_two}
                fieldId="progress_two"
                addendums={fieldAddendums?.progress_two}
                content={noteContent.progress_two || "--"}
            />
            {buildNoteSuggestion(
                noteAnalysis?.progress_two?.score,
                noteAnalysis?.progress_two?.suggestion
            )}
            <NoteContentItem
                headerText={SOAP_PLAN_NOTE_LABELS.goal_three}
                fieldId="goal_three"
                addendums={fieldAddendums?.goal_three}
                content={noteContent.goal_three || "--"}
            />
            {buildNoteSuggestion(
                noteAnalysis?.goal_three?.score,
                noteAnalysis?.goal_three?.suggestion
            )}
            <NoteContentItem
                headerText={SOAP_PLAN_NOTE_LABELS.progress_three}
                fieldId="progress_three"
                addendums={fieldAddendums?.progress_three}
                content={noteContent.progress_three || "--"}
            />
            {buildNoteSuggestion(
                noteAnalysis?.progress_three?.score,
                noteAnalysis?.progress_three?.suggestion
            )}
            <NoteContentItem
                headerText={
                    SOAP_PLAN_NOTE_LABELS.communication_factors_impacting_treatment
                }
                fieldId="communication_factors_impacting_treatment"
                addendums={
                    fieldAddendums?.communication_factors_impacting_treatment
                }
                content={
                    noteContent?.communication_factors_impacting_treatment
                        ?.length > 0 ? (
                        <div className={styles.checkboxes}>
                            {isCheckboxChecked(
                                "Need to manage maladaptive communication (e.g., related to high anxiety, high reactivity repeated questions, or disagreement) among participants",
                                "communication_factors_impacting_treatment"
                            ) && (
                                <div className={styles.checkbox}>
                                    <span
                                        className={cn(styles.checkbox_wrap, {
                                            [styles.checked]: isCheckboxChecked(
                                                "Need to manage maladaptive communication (e.g., related to high anxiety, high reactivity repeated questions, or disagreement) among participants",
                                                "communication_factors_impacting_treatment"
                                            ),
                                        })}
                                    >
                                        <CheckIcon />
                                    </span>
                                    Need to manage maladaptive communication
                                    (e.g., related to high anxiety, high
                                    reactivity repeated questions, or
                                    disagreement) among participants
                                </div>
                            )}
                            {isCheckboxChecked(
                                "Caregiver emotions or behaviors interfered with the caregiver's understanding and ability",
                                "communication_factors_impacting_treatment"
                            ) && (
                                <div className={styles.checkbox}>
                                    <span
                                        className={cn(styles.checkbox_wrap, {
                                            [styles.checked]: isCheckboxChecked(
                                                "Caregiver emotions or behaviors interfered with the caregiver's understanding and ability",
                                                "communication_factors_impacting_treatment"
                                            ),
                                        })}
                                    >
                                        <CheckIcon />
                                    </span>
                                    Caregiver emotions or behaviors interfered
                                    with the caregiver&apos;s understanding and
                                    ability
                                </div>
                            )}
                            {isCheckboxChecked(
                                "Evidence or disclosure of a sentinel event and mandated reporting to a third party",
                                "communication_factors_impacting_treatment"
                            ) && (
                                <div className={styles.checkbox}>
                                    <span
                                        className={cn(styles.checkbox_wrap, {
                                            [styles.checked]: isCheckboxChecked(
                                                "Evidence or disclosure of a sentinel event and mandated reporting to a third party",
                                                "communication_factors_impacting_treatment"
                                            ),
                                        })}
                                    >
                                        <CheckIcon />
                                    </span>
                                    Evidence or disclosure of a sentinel event
                                    and mandated reporting to a third party
                                </div>
                            )}
                            {isCheckboxChecked(
                                "Use of play equipment or other physical devices to communicate with the client to overcome barriers to therapeutic or diagnostic interaction",
                                "communication_factors_impacting_treatment"
                            ) && (
                                <div className={styles.checkbox}>
                                    <span
                                        className={cn(styles.checkbox_wrap, {
                                            [styles.checked]: isCheckboxChecked(
                                                "Use of play equipment or other physical devices to communicate with the client to overcome barriers to therapeutic or diagnostic interaction",
                                                "communication_factors_impacting_treatment"
                                            ),
                                        })}
                                    >
                                        <CheckIcon />
                                    </span>
                                    Use of play equipment or other physical
                                    devices to communicate with the client to
                                    overcome barriers to therapeutic or
                                    diagnostic interaction
                                </div>
                            )}
                            {isCheckboxChecked(
                                "Client has not developed, or lost, either the expressive language communication skills to explain their symptoms and respond to treatment",
                                "communication_factors_impacting_treatment"
                            ) && (
                                <div className={styles.checkbox}>
                                    <span
                                        className={cn(styles.checkbox_wrap, {
                                            [styles.checked]: isCheckboxChecked(
                                                "Client has not developed, or lost, either the expressive language communication skills to explain their symptoms and respond to treatment",
                                                "communication_factors_impacting_treatment"
                                            ),
                                        })}
                                    >
                                        <CheckIcon />
                                    </span>
                                    Client has not developed, or lost, either
                                    the expressive language communication skills
                                    to explain their symptoms and respond to
                                    treatment
                                </div>
                            )}
                            <div className={styles.checkbox}>
                                <span
                                    className={cn(styles.checkbox_wrap, {
                                        [styles.checked]: isCheckboxChecked(
                                            "Client lacks the receptive communication skills to understand the therapist when using typical language for communication",
                                            "communication_factors_impacting_treatment"
                                        ),
                                    })}
                                >
                                    <CheckIcon />
                                </span>
                                Client lacks the receptive communication skills
                                to understand the therapist when using typical
                                language for communication
                            </div>
                        </div>
                    ) : (
                        "--"
                    )
                }
            />
            <NoteContentItem
                headerText={
                    SOAP_PLAN_NOTE_LABELS.details_around_communication_factors_impacting_treatment
                }
                fieldId="details_around_communication_factors_impacting_treatment"
                addendums={
                    fieldAddendums?.details_around_communication_factors_impacting_treatment
                }
                content={
                    noteContent.details_around_communication_factors_impacting_treatment ||
                    "--"
                }
            />
        </>
    );
}
