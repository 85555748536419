import * as React from "react";
import { useQueryClient } from "@tanstack/react-query";
import cn from "classnames";

import { Dialog, DialogContent } from "@jhool-io/fe-components";
import { useFetchInvoiceNotes } from "../../../hooks/queries/billing";
import { useCreateInvoiceNote } from "../../../hooks/mutations/billing";
import Card from "../../Card/Card";
import Skeleton from "../../Skeleton/Skeleton";

import styles from "./InvoiceNotes.module.scss";
import ListState from "../../ListState/ListState";
import Button from "../../Button/Button";
import AddIcon from "../../Icons/AddIcon";
import useToast from "../../../hooks/useToast";
import {
    checkIfRichTextHasTextContent,
    displayNameInRightFormat,
    formatDate,
} from "../../../utils/helpers";
import InvoiceNotesList from "./InvoiceNotesList/InvoiceNotesList";
import { AddTextEditor } from "../../TextEditor/AddTextEditor/AddTextEditor";
import { ViewEditorText } from "../../TextEditor/ViewEditorText/ViewEditorText";
import { IInvoiceNotesResponse } from "../../../utils/types/billing";

interface InvoiceNotesProps {
    invoiceId: string;
    isNoShowNote?: boolean;
}

function InvoiceNotes({ invoiceId, isNoShowNote }: InvoiceNotesProps) {
    const [showAddNoteModal, setShowAddNoteModal] = React.useState(false);
    const [showAllNotesModal, setShowAllNotesModal] = React.useState(false);
    const [description, setDescription] = React.useState("");
    const [isCreatingNote, setIsCreatingNote] = React.useState(false);

    const { mutate } = useCreateInvoiceNote();

    // query client
    const queryClient = useQueryClient();
    const { toast } = useToast();

    // fetch 3 latest invoice notes
    const { data, isLoading, error, isSuccess } = useFetchInvoiceNotes(
        invoiceId,
        {
            invoice_id: invoiceId,
            limit: 3,
        },
        Boolean(invoiceId)
    );

    const listHeader = (
        <div className={styles.header}>
            <h3>Internal notes</h3>

            <div className={styles.btnwrapper}>
                {!error && !(isSuccess && data.data.length === 0) && (
                    <Button
                        mode="transparent"
                        size="small"
                        type="button"
                        ariaLabel="view all internal notes"
                        className={styles.viewallbtn}
                        onClick={() => setShowAllNotesModal(true)}
                    >
                        view all notes
                    </Button>
                )}

                <Button
                    mode="transparent"
                    type="button"
                    size="auto"
                    ariaLabel="add internal note button"
                    className={styles.addbtn}
                    onClick={() => setShowAddNoteModal(true)}
                >
                    <AddIcon fill="#FFF" />
                </Button>
            </div>
        </div>
    );

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        if (!checkIfRichTextHasTextContent(description)) {
            toast({ mode: "warning", message: "Description is required" });
            return;
        }

        setIsCreatingNote(true);
        mutate(
            { invoice_id: invoiceId, description },
            {
                onSuccess: (res) => {
                    queryClient.invalidateQueries({
                        queryKey: ["invoice-notes", invoiceId],
                    });
                    toast({
                        mode: "success",
                        message:
                            res.message || "Internal note added successfully",
                    });
                    setShowAddNoteModal(false);
                },
                onError: (err) => {
                    toast({
                        mode: "error",
                        message:
                            err.response?.data.message ||
                            "Could not add internal note at this time",
                    });
                },
                onSettled: () => {
                    setIsCreatingNote(false);
                    setDescription("");
                },
            }
        );
    };

    // isolate the different unique dates
    const recentInternalNotesDates = new Set<string>();
    data?.data.forEach((note) =>
        recentInternalNotesDates.add(formatDate(note.date_created))
    );

    // group recent internal notes by unique dates
    const sortedInternalNotes: { [key: string]: IInvoiceNotesResponse[] } = {};

    recentInternalNotesDates.forEach((date) => {
        const availableNotes: IInvoiceNotesResponse[] = [];
        data?.data.forEach((note) => {
            if (formatDate(note.date_created) === date) {
                availableNotes.push(note);
            }
        });

        sortedInternalNotes[date] = availableNotes;
    });

    return (
        <>
            <Dialog open={showAddNoteModal}>
                <DialogContent
                    title="Add internal note"
                    handleCloseDialog={() => setShowAddNoteModal(false)}
                    variant="center"
                    showFooter
                    saveText="Add internal note"
                    cancelText="Cancel"
                    submitBtnFormValue="add-internal-note"
                    isSubmitBtnDisabled={!description || isCreatingNote}
                    isCancelBtnDisabled={!description || isCreatingNote}
                >
                    <form id="add-internal-note" onSubmit={handleSubmit}>
                        <div className="fg">
                            <AddTextEditor
                                title="Enter internal note description"
                                onEditorTextChange={setDescription}
                                isRequiredFieldProvided
                            />
                        </div>
                    </form>
                </DialogContent>
            </Dialog>
            <Dialog open={showAllNotesModal}>
                <DialogContent
                    title="All internal notes"
                    handleCloseDialog={() => setShowAllNotesModal(false)}
                    showFooter={false}
                >
                    <InvoiceNotesList invoiceId={invoiceId} />
                </DialogContent>
            </Dialog>

            <div className={styles.wrapper}>
                {isLoading && (
                    <Card className="flex flex-col gap-y-12 h-full p-10">
                        <Skeleton height={30} width="30%" />
                        <div className="mt-20">
                            <Skeleton className="h-[200px] w-full" />
                        </div>
                    </Card>
                )}
                {error && error?.response?.status !== 404 && (
                    <ListState
                        errorMsg={
                            error?.response?.data.message ||
                            `Cannot display internal notes`
                        }
                        stateHelperText="Try reloading this page to solve this issue"
                        isError
                        cardClass="h-full"
                        wrapperClass="h-full"
                        listHeader={listHeader}
                    />
                )}

                {data && isSuccess && data.data.length === 0 && (
                    <ListState
                        stateHelperText="Internal notes will be added here when added"
                        isError={false}
                        listHeader={listHeader}
                        cardClass="h-full"
                        emptyMessage="No internal notes"
                        wrapperClass="h-full"
                    />
                )}

                {isSuccess && data.data && data.data.length > 0 && (
                    <Card className={styles.notescard}>
                        {listHeader}

                        <div
                            className={cn(styles.cards, {
                                [styles.cards_small]: isNoShowNote,
                            })}
                            role="listbox"
                        >
                            {Object.keys(sortedInternalNotes).map(
                                (item, idx) => {
                                    return (
                                        <ul
                                            key={item + String(idx)}
                                            className={styles.card}
                                        >
                                            <li className={styles.card_date}>
                                                {item}
                                            </li>

                                            {sortedInternalNotes[item].map(
                                                (note) => (
                                                    <React.Fragment
                                                        key={
                                                            note.invoice_note_id
                                                        }
                                                    >
                                                        <li
                                                            className={
                                                                styles.card_author
                                                            }
                                                        >
                                                            {displayNameInRightFormat(
                                                                {
                                                                    firstName:
                                                                        note
                                                                            .author
                                                                            .first_name,
                                                                    lastName:
                                                                        note
                                                                            .author
                                                                            .last_name,
                                                                }
                                                            )}
                                                        </li>

                                                        <li
                                                            className={
                                                                styles.card_content
                                                            }
                                                        >
                                                            <ViewEditorText
                                                                text={
                                                                    note.description
                                                                }
                                                            />
                                                        </li>
                                                    </React.Fragment>
                                                )
                                            )}
                                        </ul>
                                    );
                                }
                            )}
                        </div>
                    </Card>
                )}
            </div>
        </>
    );
}

export default InvoiceNotes;
