/* eslint-disable react/no-unstable-nested-components */
import * as React from "react";
import {
    Button,
    Checkbox,
    Dialog,
    DialogContent,
    Dropdown,
    DropdownContent,
    DropdownItem,
    DropdownTrigger,
    Pagination,
    SearchInput,
    Table,
    Tag,
} from "@jhool-io/fe-components";
import { ColumnDef, Row } from "@tanstack/react-table";
import { useSearchParams } from "react-router-dom";
import { useIsMutating, useQueryClient } from "@tanstack/react-query";
import {
    IInternalUser,
    InternalUserStatus,
    UserRole,
} from "../../types/user-management.types";

import useToast from "../../../../hooks/useToast";
import { useFetchUserDetails } from "../../../../hooks/queries/user";
import {
    useChangeUserStatus,
    useDeleteUsers,
    useResetPassword,
} from "../../hooks/user-management.mutations";
import { useFetchUsersList } from "../../hooks/user-management.queries";
import { useDebounce } from "../../../../hooks/helpers";
import { APP_COLORS, LISTS_DEFAULT_LIMIT } from "../../../../utils/constants";
import {
    cn,
    displayNameInRightFormat,
    getTotalAmountOfTableRowsSelected,
    makeStringFirstLetterCapital,
    removeEnumUnderscore,
    truncateString,
} from "../../../../utils/helpers";
import MoreButton from "../../../../shared-ui/Buttons/MoreButton/MoreButton";
import ModifyUserRoleForm from "../ModifyUserRoleForm/ModifyUserRoleForm";
import FilterButton from "../../../../shared-ui/Buttons/FilterButton/FilterButton";
import CheckPrimaryColorIcon from "../../../../components/Icons/CheckPrimaryColor";
import Skeleton from "../../../../components/Skeleton/Skeleton";
import ListState from "../../../../components/ListState/ListState";
import ArrowDownIcon from "../../../../components/Icons/ArrowDownIcon";
import MobileListItem from "../../../../components/MobileListItem/MobileListItem";
import AddUserForm from "../AddUserForm/AddUserForm";

type DialogTypes =
    | "add-user"
    | "delete-user"
    | "deactivate-user"
    | "modify-user"
    | "activate-user"
    | "reset-password";

export default function InternalUsersList() {
    const [searchParams, setSearchParams] = useSearchParams();
    const [rowSelection, setRowSelection] = React.useState({});
    const [dialogInView, setDialogInView] = React.useState<DialogTypes | null>(
        null
    );
    const [selectedUserId, setSelectedUserId] = React.useState<string | null>(
        null
    );
    // Selected users id for mobile
    const [selectedUsersId, setSelectedUsersId] = React.useState<string[]>([]);
    const [getSelectedData, setGetSelectedData] = React.useState<
        Row<IInternalUser>[]
    >([]);
    const [sortConfig, setSortConfig] = React.useState<{
        key: string;
        direction: string;
    }>({
        key: "",
        direction: "",
    });

    const pageFilter = Number(searchParams.get("page")) || 1;
    const searchFilter = searchParams.get("search") || "";
    const roleFilter = searchParams.get("role") || "";
    const statusFilter =
        searchParams.get("status") === null
            ? ""
            : searchParams.get("status") || "active";
    const limitFilter =
        Number(searchParams.get("limit")) || LISTS_DEFAULT_LIMIT;

    const debouncedSearchFilter = useDebounce(searchFilter);

    const userIdsFromSelectedData =
        getSelectedData && getSelectedData.map((item) => item.original.user_id);

    const queryClient = useQueryClient();

    const loggedInUser = useFetchUserDetails();
    const deleteUsers = useDeleteUsers();
    const changeUserStatus = useChangeUserStatus();
    const resetPassword = useResetPassword();

    const { toast } = useToast();

    const sortableColumns = ["user_name", "email", "roles"];

    const modifyRolesIsMutating = useIsMutating(["modify-roles"]);
    const addUserIsMutating = useIsMutating(["useAddUsers"]);
    const resetPasswordIsMutating = useIsMutating(["reset-password"]);
    const updateStatusIsMutating = useIsMutating(["update-status"]);
    const deleteUserIsMutating = useIsMutating(["delete-users"]);

    const handleConfigAttribute = () => {
        if (sortableColumns.includes(sortConfig.key)) {
            return sortConfig.key;
        }

        return undefined;
    };

    const { data, isLoading, isSuccess, error } = useFetchUsersList({
        page: pageFilter,
        limit: limitFilter,
        status: statusFilter,
        role: roleFilter || "",
        search_string: debouncedSearchFilter,
        sort_attribute:
            searchParams.get("sort_attr") || handleConfigAttribute(),
        sort_order: searchParams.get("sort_order") || sortConfig.direction,
    });

    // Details for a single user web
    const selectedUserDetails = data?.data.find((user) => {
        return user.user_id === selectedUserId;
    });

    // Array of details of the selected users(web)
    const selectedUsersDetailsWeb =
        userIdsFromSelectedData &&
        userIdsFromSelectedData.map((userId: string) =>
            data?.data.find((user) => user.user_id === userId)
        );

    // Array of details of the selected users(mobile)
    const selectedUsersDetailsMobile =
        selectedUsersId &&
        selectedUsersId.map((userId: string) =>
            data?.data.find((user) => user.user_id === userId)
        );

    const detailsArrayToUse =
        selectedUsersId.length > 0
            ? selectedUsersDetailsMobile
            : selectedUsersDetailsWeb;

    const handleSearchStringChange = (
        e: React.ChangeEvent<HTMLInputElement>
    ) => {
        if (e.target.value === "") searchParams.delete("search");
        else searchParams.set("search", e.target.value);

        setSearchParams(searchParams);
    };

    const handleStatusSelectFilterChange = (value: string) => {
        if (value === "") searchParams.delete("status");
        else searchParams.set("status", value);

        searchParams.set("page", "1");
        setSearchParams(searchParams);
    };

    const handleRoleSelectFilterChange = (value: string) => {
        if (value === "") searchParams.delete("role");
        else searchParams.set("role", value);

        searchParams.set("page", "1");
        setSearchParams(searchParams);
    };

    const handlePageChange = (page: number) => {
        searchParams.set("page", String(page));
        setSearchParams(searchParams);
    };

    const handleLimitChange = (limit: number) => {
        searchParams.set("limit", String(limit));
        setSearchParams(searchParams);
    };

    const handleSetDialogInView = (
        dialog: DialogTypes | null,
        id: string | null
    ) => {
        setDialogInView(dialog);
        setSelectedUserId(id);
    };

    const handleCloseDialog = () => {
        setDialogInView(null);
    };

    const handleResetLocalStates = () => {
        if (selectedUserId) setSelectedUserId(null);
        if (rowSelection && Object.keys(rowSelection).length > 0)
            setRowSelection({});
        if (selectedUsersId.length > 0) setSelectedUsersId([]);
    };

    // Toggles user selection for mobile
    const toggleUserSelection = (userId: string) => {
        if (selectedUsersId.includes(userId)) {
            setSelectedUsersId(selectedUsersId.filter((id) => id !== userId));
        } else {
            setSelectedUsersId([...selectedUsersId, userId]);
        }
    };

    const getStatusDisplayForMobile = (status: string) => {
        let bgColor: string;
        let textColor: string;

        switch (status) {
            case InternalUserStatus.ACTIVE:
                textColor = "#00563E";
                bgColor = "rgba(204, 250, 233, 0.50)";
                break;
            case InternalUserStatus.DEACTIVATED:
                textColor = "#981F41";
                bgColor = "rgba(251, 199, 198, 0.50)";
                break;
            default:
                bgColor = "rgba(245, 245, 245, 0.50)";
                textColor = APP_COLORS.COLOR_BLACK;
                break;
        }

        return (
            <Tag
                textColor={textColor}
                bgColor={bgColor}
                title={makeStringFirstLetterCapital(status)}
                className="capitalize w-auto h-auto text-nowrap px-8 py-4 rounded-r4"
            />
        );
    };

    const handleDeleteUsers = () => {
        deleteUsers.mutate(
            userIdsFromSelectedData && userIdsFromSelectedData.length > 0
                ? userIdsFromSelectedData
                : [selectedUserId || ""],
            {
                onSuccess(res) {
                    queryClient.invalidateQueries({ queryKey: [`users`] });
                    toast({
                        mode: "success",
                        message: res.message || "User(s) deleted successfully",
                    });
                    handleResetLocalStates();
                    handleCloseDialog();
                },
                onError(err) {
                    toast({
                        mode: "error",
                        message:
                            err.response?.data.message ||
                            "Could not delete user(s), please try again",
                    });
                },
            }
        );
    };

    const handleChangeStatus = () => {
        const singlePayload = {
            user_ids: [selectedUserDetails?.user_id as string],
            status:
                selectedUserDetails?.status === InternalUserStatus.ACTIVE
                    ? InternalUserStatus.DEACTIVATED
                    : InternalUserStatus.ACTIVE,
        };

        const multiPayload = {
            user_ids:
                userIdsFromSelectedData.length === 0
                    ? selectedUsersId
                    : userIdsFromSelectedData,
            status:
                dialogInView === "activate-user"
                    ? InternalUserStatus.ACTIVE
                    : InternalUserStatus.DEACTIVATED,
        };
        changeUserStatus.mutate(
            detailsArrayToUse.length !== 0 ? multiPayload : singlePayload,
            {
                onSuccess(res) {
                    queryClient.invalidateQueries({ queryKey: [`users`] });
                    toast({
                        mode: "success",
                        message:
                            res.message || "User status updated successfully",
                    });
                    handleResetLocalStates();
                    handleCloseDialog();
                },
                onError(err) {
                    toast({
                        mode: "error",
                        message:
                            err.response?.data.message ||
                            "Could not update status, please try again",
                    });
                },
            }
        );
    };

    const handleResetUserPassword = () => {
        const emails = detailsArrayToUse.map((user) => user?.email);
        resetPassword.mutate(
            detailsArrayToUse && detailsArrayToUse?.length !== 0
                ? (emails as string[])
                : [selectedUserDetails?.email as string],
            {
                onSuccess() {
                    toast({
                        mode: "success",
                        message:
                            "A password reset link has been sent to the user",
                    });
                    handleResetLocalStates();
                    handleCloseDialog();
                },
                onError() {
                    toast({
                        mode: "error",
                        message: "User password reset failed",
                    });
                },
            }
        );
    };

    const columns: ColumnDef<IInternalUser>[] = [
        {
            id: "select",
            header: ({ table }) => (
                <Checkbox
                    handleChange={table.getToggleAllRowsSelectedHandler()}
                    indeterminate={table.getIsSomeRowsSelected()}
                    name="row"
                    value="row"
                    isChecked={table.getIsAllRowsSelected()}
                />
            ),
            cell: ({ row }) => (
                <Checkbox
                    handleChange={row.getToggleSelectedHandler()}
                    indeterminate={row.getIsSomeSelected()}
                    name="row"
                    value="row"
                    isChecked={row.getIsSelected()}
                />
            ),
        },
        {
            accessorKey: "user_name",
            header: "USER NAME",
            cell: ({ row }) =>
                displayNameInRightFormat({
                    firstName: row.original.first_name,
                    lastName: row.original.last_name,
                }),
        },
        {
            accessorKey: "email",
            header: "EMAIL",
        },
        {
            accessorKey: "roles",
            header: "ROLES",
            cell: ({ row }) => {
                const role = row.original.roles.join(", ");
                let roleName = role;
                switch (role) {
                    case "superadmin":
                        roleName = "Super Admin";
                        break;
                    case "billeradmin":
                        roleName = "Biller Admin";
                        break;
                    default:
                        roleName = role;
                        break;
                }
                return <span className="capitalize">{roleName}</span>;
            },
        },
        {
            accessorKey: "status",
            header: "STATUS",
            cell: ({ row }) => {
                let bgColor: string;
                let textColor: string;

                switch (row.original.status) {
                    case InternalUserStatus.ACTIVE:
                        textColor = "#00563E";
                        bgColor = "rgba(204, 250, 233, 0.50)";
                        break;
                    case InternalUserStatus.DEACTIVATED:
                        textColor = "#981F41";
                        bgColor = "rgba(251, 199, 198, 0.50)";
                        break;
                    default:
                        bgColor = "rgba(245, 245, 245, 0.50)";
                        textColor = APP_COLORS.COLOR_BLACK;
                        break;
                }

                return (
                    <Tag
                        textColor={textColor}
                        bgColor={bgColor}
                        title={makeStringFirstLetterCapital(
                            row.original.status
                        )}
                        className="capitalize w-auto h-auto text-nowrap px-8 py-4 rounded-r4"
                    />
                );
            },
        },
        {
            accessorKey: "user_id",
            header: "",
            cell: ({ row }) => {
                return (
                    loggedInUser.data?.user_id !== row.original.user_id && (
                        <Dropdown>
                            <DropdownTrigger asChild>
                                <MoreButton />
                            </DropdownTrigger>
                            <DropdownContent width="auto" align="end">
                                <DropdownItem
                                    onClick={() =>
                                        handleSetDialogInView(
                                            "modify-user",
                                            row.original.user_id
                                        )
                                    }
                                >
                                    Modify user role
                                </DropdownItem>
                                <DropdownItem
                                    onClick={() =>
                                        handleSetDialogInView(
                                            "reset-password",
                                            row.original.user_id
                                        )
                                    }
                                >
                                    Reset user password
                                </DropdownItem>
                                <DropdownItem
                                    onClick={
                                        row.original.status ===
                                        InternalUserStatus.ACTIVE
                                            ? () =>
                                                  handleSetDialogInView(
                                                      "deactivate-user",
                                                      row.original.user_id
                                                  )
                                            : () =>
                                                  handleSetDialogInView(
                                                      "activate-user",
                                                      row.original.user_id
                                                  )
                                    }
                                >
                                    {row.original.status ===
                                    InternalUserStatus.ACTIVE
                                        ? "Deactivate user"
                                        : "Activate user"}
                                </DropdownItem>
                                <DropdownItem
                                    className="text-danger"
                                    onClick={() =>
                                        handleSetDialogInView(
                                            "delete-user",
                                            row.original.user_id
                                        )
                                    }
                                >
                                    Delete user
                                </DropdownItem>
                            </DropdownContent>
                        </Dropdown>
                    )
                );
            },
        },
    ];

    return (
        <>
            <Dialog open={dialogInView === "add-user"}>
                <DialogContent
                    handleCloseDialog={handleCloseDialog}
                    variant="center"
                    classNames="p-24 pb-48"
                    title="Create internal user"
                    saveText="Create internal user"
                    submitBtnFormValue="add-user"
                    isSubmitBtnDisabled={addUserIsMutating > 0}
                    isCancelBtnDisabled={addUserIsMutating > 0}
                >
                    <AddUserForm
                        onFormSubmit={() => {
                            handleCloseDialog();
                            handleResetLocalStates();
                        }}
                        selectedActionType="invite_user"
                    />
                </DialogContent>
            </Dialog>
            <Dialog open={dialogInView === "modify-user"}>
                <DialogContent
                    handleCloseDialog={handleCloseDialog}
                    variant="center"
                    classNames="p-24 pb-48"
                    title="Modify user role"
                    saveText="Modify role"
                    submitBtnFormValue="modify_user_role"
                    isSubmitBtnDisabled={modifyRolesIsMutating > 0}
                    isCancelBtnDisabled={modifyRolesIsMutating > 0}
                >
                    <ModifyUserRoleForm
                        onFormSubmit={() => {
                            handleCloseDialog();
                            handleResetLocalStates();
                        }}
                        defaultRoles={
                            userIdsFromSelectedData.length === 0
                                ? selectedUserDetails?.roles
                                : []
                        }
                        defaultUsers={
                            selectedUserDetails
                                ? [selectedUserDetails.user_id]
                                : detailsArrayToUse
                                      .filter(Boolean)
                                      .map((user) => user?.user_id as string)
                        }
                    />
                </DialogContent>
            </Dialog>
            <Dialog open={dialogInView === "delete-user"}>
                <DialogContent
                    handleCloseDialog={handleCloseDialog}
                    variant="center"
                    classNames="p-24"
                    title="Delete user"
                    saveText="Delete user"
                    onSaveClick={handleDeleteUsers}
                    isSubmitBtnDisabled={deleteUserIsMutating > 0}
                    isCancelBtnDisabled={deleteUserIsMutating > 0}
                >
                    <div className="px-8 text-center">
                        <h3 className="font-medium text-lg mb-8">
                            {detailsArrayToUse.length === 0 ? (
                                `Are you sure you want to remove ${selectedUserDetails?.email}?`
                            ) : (
                                <>
                                    Are you sure you want to remove these users:
                                </>
                            )}
                        </h3>
                        {detailsArrayToUse.length === 0 ? (
                            <p className="text-gray font-normal text-base">
                                Deleting this user means all of its data will be
                                removed and this action can not be reversed
                            </p>
                        ) : (
                            <>
                                <p className="text-sm">
                                    {detailsArrayToUse.map((user) => (
                                        <span key={user?.email}>
                                            {user?.email}
                                            <br />
                                        </span>
                                    ))}
                                </p>
                                <p className="text-gray font-normal text-sm">
                                    Deleting these users means all of their data
                                    will be removed and this action cannot be
                                    reversed.
                                </p>
                            </>
                        )}
                    </div>
                </DialogContent>
            </Dialog>
            <Dialog open={dialogInView === "deactivate-user"}>
                <DialogContent
                    handleCloseDialog={handleCloseDialog}
                    variant="center"
                    classNames="p-24"
                    title="Deactivate user"
                    saveText="Deactive user"
                    onSaveClick={handleChangeStatus}
                    isSubmitBtnDisabled={updateStatusIsMutating > 0}
                    isCancelBtnDisabled={updateStatusIsMutating > 0}
                >
                    <div className=" px-8 text-center">
                        <h3 className="font-medium text-lg mb-8">
                            {detailsArrayToUse.length === 0 ? (
                                `Are you sure you want to deactivate ${selectedUserDetails?.email}?`
                            ) : (
                                <>
                                    Are you sure you want to deactivate these
                                    users:
                                </>
                            )}
                        </h3>
                        {detailsArrayToUse.length === 0 ? (
                            <p className="text-gray font-normal text-lg">
                                This will restrict the user from using mantle
                            </p>
                        ) : (
                            <>
                                <p className="text-sm">
                                    {detailsArrayToUse.map((user) => (
                                        <span key={user?.email}>
                                            {user?.email}
                                            <br />
                                        </span>
                                    ))}
                                </p>
                                <p className="text-gray font-normal text-sm">
                                    This will restrict them from using mantle
                                </p>
                            </>
                        )}
                    </div>
                </DialogContent>
            </Dialog>
            <Dialog open={dialogInView === "activate-user"}>
                <DialogContent
                    handleCloseDialog={handleCloseDialog}
                    variant="center"
                    classNames="p-24"
                    title="Activate user"
                    saveText="Activate user"
                    onSaveClick={handleChangeStatus}
                    isSubmitBtnDisabled={updateStatusIsMutating > 0}
                    isCancelBtnDisabled={updateStatusIsMutating > 0}
                >
                    <div className=" px-8 text-center">
                        <h3 className="font-medium text-lg mb-8">
                            {detailsArrayToUse.length === 0 ? (
                                `Are you sure you want to activate ${selectedUserDetails?.email}?`
                            ) : (
                                <>
                                    Are you sure you want to activate these
                                    users:
                                </>
                            )}
                        </h3>
                        {detailsArrayToUse.length === 0 ? (
                            <p className="text-gray font-normal text-base">
                                This will give them access to use mantle
                            </p>
                        ) : (
                            <>
                                <p className="text-sm">
                                    {detailsArrayToUse.map((user) => (
                                        <span key={user?.email}>
                                            {user?.email}
                                            <br />
                                        </span>
                                    ))}
                                </p>
                                <p className="text-gray font-normal text-sm">
                                    This will give them access to use mantle
                                </p>
                            </>
                        )}
                    </div>
                </DialogContent>
            </Dialog>
            <Dialog open={dialogInView === "reset-password"}>
                <DialogContent
                    handleCloseDialog={handleCloseDialog}
                    variant="center"
                    classNames="p-24"
                    title="Reset Password"
                    saveText="Reset Password"
                    onSaveClick={handleResetUserPassword}
                    isSubmitBtnDisabled={resetPasswordIsMutating > 0}
                    isCancelBtnDisabled={resetPasswordIsMutating > 0}
                >
                    <div className=" px-8 text-center">
                        <h3 className="font-medium text-base mb-8">
                            {detailsArrayToUse.length === 0 ? (
                                `Are you sure you want to reset password for ${selectedUserDetails?.email}?`
                            ) : (
                                <>
                                    Are you sure you want to reset password for
                                    these users:
                                </>
                            )}
                        </h3>
                        {detailsArrayToUse.length !== 0 && (
                            <p className="text-sm">
                                {detailsArrayToUse.map((user) => (
                                    <span key={user?.email}>
                                        {user?.email}
                                        <br />
                                    </span>
                                ))}
                            </p>
                        )}
                    </div>
                </DialogContent>
            </Dialog>
            <div>
                <div className="rounded-r8 bg-white flex-col flex gap-y-24 py-16 px-12 mb-32">
                    <SearchInput
                        placeholder="Search by name, email"
                        defaultValue={searchFilter}
                        onChange={handleSearchStringChange}
                        containerClass="w-[400px]"
                    />
                    <div className="flex flex-wrap gap-12 justify-between items-center">
                        <div className="flex items-center flex-wrap gap-12">
                            <Dropdown>
                                <DropdownTrigger asChild>
                                    <FilterButton
                                        text={
                                            roleFilter
                                                ? truncateString(
                                                      removeEnumUnderscore(
                                                          roleFilter
                                                      )
                                                  )
                                                : "Role"
                                        }
                                    />
                                </DropdownTrigger>
                                <DropdownContent
                                    width="auto"
                                    maxHeight={216}
                                    className="overflow-y-auto"
                                >
                                    <DropdownItem
                                        className={cn(
                                            "flex gap-x-8 items-center text-xs font-medium",
                                            {
                                                "text-primary":
                                                    roleFilter === "",
                                            }
                                        )}
                                        onClick={() =>
                                            handleRoleSelectFilterChange("")
                                        }
                                    >
                                        All
                                        {!roleFilter ? (
                                            <CheckPrimaryColorIcon />
                                        ) : null}
                                    </DropdownItem>
                                    {Object.values(UserRole).map((role) => (
                                        <DropdownItem
                                            className={cn(
                                                "flex gap-x-8 items-center capitalize text-xs font-medium",
                                                {
                                                    "text-primary":
                                                        roleFilter === role,
                                                }
                                            )}
                                            key={role}
                                            onClick={() =>
                                                handleRoleSelectFilterChange(
                                                    role
                                                )
                                            }
                                        >
                                            {removeEnumUnderscore(role)}
                                            {roleFilter === role ? (
                                                <CheckPrimaryColorIcon />
                                            ) : null}
                                        </DropdownItem>
                                    ))}
                                </DropdownContent>
                            </Dropdown>
                            <Dropdown>
                                <DropdownTrigger asChild>
                                    <FilterButton
                                        text={
                                            statusFilter
                                                ? truncateString(
                                                      removeEnumUnderscore(
                                                          statusFilter
                                                      )
                                                  )
                                                : "Status"
                                        }
                                    />
                                </DropdownTrigger>
                                <DropdownContent
                                    width="auto"
                                    maxHeight={216}
                                    className="overflow-y-auto"
                                >
                                    <DropdownItem
                                        className={cn(
                                            "flex gap-x-8 items-center text-xs font-medium",
                                            {
                                                "text-primary":
                                                    statusFilter === "",
                                            }
                                        )}
                                        onClick={() =>
                                            handleStatusSelectFilterChange("")
                                        }
                                    >
                                        All
                                        {!statusFilter ? (
                                            <CheckPrimaryColorIcon />
                                        ) : null}
                                    </DropdownItem>
                                    {Object.values(InternalUserStatus).map(
                                        (status) => (
                                            <DropdownItem
                                                className={cn(
                                                    "flex gap-x-8 items-center capitalize text-xs font-medium",
                                                    {
                                                        "text-primary":
                                                            statusFilter ===
                                                            status,
                                                    }
                                                )}
                                                key={status}
                                                onClick={() =>
                                                    handleStatusSelectFilterChange(
                                                        status
                                                    )
                                                }
                                            >
                                                {removeEnumUnderscore(status)}
                                                {statusFilter === status ? (
                                                    <CheckPrimaryColorIcon />
                                                ) : null}
                                            </DropdownItem>
                                        )
                                    )}
                                </DropdownContent>
                            </Dropdown>
                        </div>
                        <Button
                            aria-label="Invite User"
                            onClick={() => setDialogInView("add-user")}
                        >
                            Create Internal User
                        </Button>
                    </div>
                </div>
                {isLoading && (
                    <Skeleton
                        type="table"
                        containerTestId="userslist-loader"
                        count={6}
                    />
                )}
                {error && (
                    <ListState
                        isError
                        stateHelperText="Try reloading this page to solve this issue"
                        errorMsg={
                            error?.response?.data.message ||
                            `Cannot display users list at this time please try again later`
                        }
                    />
                )}
                {data && isSuccess && data.data.length === 0 && (
                    <ListState
                        stateHelperText="Users list will appear here once users are added"
                        isError={false}
                        emptyMessage="No users added yet"
                        emptyBtnProps={{
                            showButton: true,
                            buttonText: "Create Internal User",
                            onButtonClick: () => setDialogInView("add-user"),
                        }}
                    />
                )}
                {isSuccess && data && data.data.length > 0 && (
                    <div>
                        {getTotalAmountOfTableRowsSelected(rowSelection)
                            .amount > 0 && (
                            <div className="flex items-center gap-x-5 mb-6">
                                <div className="text-base font-medium">
                                    {
                                        getTotalAmountOfTableRowsSelected(
                                            rowSelection
                                        ).amountString
                                    }
                                    :
                                </div>
                                <Button
                                    variant="secondary"
                                    aria-label="actions"
                                    className=" ml-12"
                                    onClick={() =>
                                        setDialogInView("modify-user")
                                    }
                                >
                                    Modify role
                                </Button>
                                {detailsArrayToUse.every(
                                    (user) =>
                                        user?.status ===
                                            detailsArrayToUse[0]?.status || ""
                                ) && (
                                    <Button
                                        variant="secondary"
                                        aria-label="actions"
                                        onClick={
                                            detailsArrayToUse[0]?.status ===
                                            InternalUserStatus.ACTIVE
                                                ? () =>
                                                      setDialogInView(
                                                          "deactivate-user"
                                                      )
                                                : () =>
                                                      setDialogInView(
                                                          "activate-user"
                                                      )
                                        }
                                    >
                                        {detailsArrayToUse[0]?.status ===
                                        InternalUserStatus.ACTIVE
                                            ? "Deactivate user"
                                            : "Activate user"}
                                    </Button>
                                )}

                                <Button
                                    variant="secondary"
                                    aria-label="actions"
                                    onClick={() =>
                                        setDialogInView("reset-password")
                                    }
                                >
                                    Reset Password
                                </Button>

                                <Button
                                    variant="normal"
                                    className="text-white bg-danger hover:bg-danger"
                                    aria-label="actions"
                                    onClick={() =>
                                        setDialogInView("delete-user")
                                    }
                                >
                                    Delete
                                </Button>
                            </div>
                        )}
                        {selectedUsersId.length > 0 && (
                            <div className="flex items-center gap-x-12 mb-24 sm:hidden">
                                <div className="text-base font-medium">
                                    {`${selectedUsersId.length}  selected`} :
                                </div>
                                <Dropdown>
                                    <DropdownTrigger asChild>
                                        <Button className="gap-x-8 items-center">
                                            Actions
                                            <ArrowDownIcon
                                                className="w-12"
                                                fill="#ffffff"
                                            />
                                        </Button>
                                    </DropdownTrigger>
                                    <DropdownContent width="auto" align="end">
                                        <DropdownItem
                                            onClick={() =>
                                                setDialogInView("modify-user")
                                            }
                                        >
                                            Modify user role
                                        </DropdownItem>
                                        <DropdownItem
                                            onClick={() =>
                                                setDialogInView(
                                                    "reset-password"
                                                )
                                            }
                                        >
                                            Reset user password
                                        </DropdownItem>
                                        <DropdownItem
                                            onClick={
                                                detailsArrayToUse[0]?.status ===
                                                InternalUserStatus.ACTIVE
                                                    ? () =>
                                                          setDialogInView(
                                                              "deactivate-user"
                                                          )
                                                    : () =>
                                                          setDialogInView(
                                                              "activate-user"
                                                          )
                                            }
                                        >
                                            {detailsArrayToUse[0]?.status ===
                                            InternalUserStatus.ACTIVE
                                                ? "Deactivate user"
                                                : "Activate user"}
                                        </DropdownItem>
                                        <DropdownItem
                                            className="text-danger"
                                            onClick={() =>
                                                setDialogInView("delete-user")
                                            }
                                        >
                                            Delete user
                                        </DropdownItem>
                                    </DropdownContent>
                                </Dropdown>
                            </div>
                        )}
                        <div className="hidden sm:block">
                            <Table
                                data={data.data}
                                rowSelection={rowSelection}
                                setRowSelection={setRowSelection}
                                columns={columns}
                                setGetSelectedData={setGetSelectedData}
                                setSortConfig={setSortConfig}
                                sortConfig={sortConfig}
                                sortableColumns={sortableColumns}
                                hasPagination={data.total_count > 20}
                                pagination={
                                    <Pagination
                                        totalCount={data.total_count}
                                        totalPages={data.total_pages}
                                        currentPage={pageFilter}
                                        onPageChange={handlePageChange}
                                        limit={limitFilter}
                                        onLimitChange={handleLimitChange}
                                    />
                                }
                            />
                        </div>
                        <div className="block sm:hidden" role="table">
                            {data.data.map((user) => (
                                <MobileListItem
                                    key={user.user_id}
                                    isSelected={selectedUsersId?.includes(
                                        user.user_id
                                    )}
                                    className="mb-5"
                                    showCheckButton={
                                        loggedInUser.data?.user_id !==
                                        user.user_id
                                    }
                                    onClickCheckButton={() =>
                                        toggleUserSelection(user.user_id)
                                    }
                                >
                                    <span className="flex items-center text-left">
                                        <span className="font-medium text-base mr-10">
                                            {`${user.first_name} ${user.last_name}`}
                                        </span>
                                        <span className="whitespace-nowrap">
                                            {getStatusDisplayForMobile(
                                                user.status
                                            )}
                                        </span>
                                    </span>
                                    <p className="text-sm font-medium text-gray my-[1.45rem] opacity-80">
                                        {user.email}
                                    </p>
                                    <p className="text-sm font-medium text-gray my-[1.45rem] opacity-80 capitalize">
                                        {user.roles.join(",")}
                                    </p>
                                </MobileListItem>
                            ))}

                            {data.total_pages > 1 && (
                                <div className="fixed bottom-32 w-[calc(100%_-_38px)] sm:hidden">
                                    <Pagination
                                        totalCount={data.total_count}
                                        totalPages={data.total_pages}
                                        currentPage={pageFilter}
                                        onPageChange={handlePageChange}
                                        limit={limitFilter}
                                        onLimitChange={handleLimitChange}
                                    />
                                </div>
                            )}
                        </div>
                    </div>
                )}
            </div>
        </>
    );
}
