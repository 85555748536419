export enum ProviderStatus {
    ACTIVE = "active",
    INACTIVE = "inactive",
}

export enum SessionRecurrenceRule {
    WEEKLY = "Weekly",
    BI_WEEKLY = "Bi-Weekly",
    TRI_WEEKKLY = "Tri-Weekly",
    MONTHLY = "Monthly",
    ONE_OFF = "One-Off",
}

export enum SessionDeliveryOption {
    IN_PERSON = "in_person",
    REMOTE = "remote",
}

export enum SessionPlaceOfServiceCode {
    TELEHEALTH_PROVIDED_OTHER_THAN_IN_PATIENTS_HOME = "02 - Telehealth Provided Other than in Patient's Home",
    TELEHEALTH_PROVIDED_IN_PATIENTS_HOME = "10 - Telehealth Provided in Patient's Home",
    OFFICE = "11 - Office",
}

export enum AppointmentLocation {
    IN_PERSON = "in_person",
    TELETHERAPY = "teletherapy",
}

export enum AppointmentLocationTeletherapy {
    OFFICE = "Office",
    HOME = "Home",
    OTHER = "Other",
}

export enum SessionServiceType {
    CHILD_THERAPY = "child_therapy",
    FAMILY_THERAPY = "family_therapy",
    INDIVIDUAL_THERAPY = "individual_therapy",
    INTUITIVE_EATING = "intuitive_eating",
    MEDICATION_MANAGEMENT = "medication_management",
    PSYCHIATRIC_COUNSELING = "psychiatric_counseling",
    MENTAL_HEALTH_ASSESSMENT = "mental_health_assessment",
    TELETHERAPY = "teletherapy",
}

export const SessionLabels: { [key: string]: string } = {
    [SessionServiceType.CHILD_THERAPY]: "Child Therapy",
    [SessionServiceType.FAMILY_THERAPY]: "Family Therapy",
    [SessionServiceType.INDIVIDUAL_THERAPY]: "Individual Therapy",
    [SessionServiceType.INTUITIVE_EATING]: "Intuitive Eating",
    [SessionServiceType.MEDICATION_MANAGEMENT]: "Medication Management",
    [SessionServiceType.PSYCHIATRIC_COUNSELING]: "Psychiatric Counseling",
    [SessionServiceType.MENTAL_HEALTH_ASSESSMENT]: "Mental Health Assessment",
    [SessionServiceType.TELETHERAPY]: "Teletherapy",
};

// Provider's session
export interface IProviderSession {
    session_id: string;
    session_history_id: string;
    status: ProviderStatus;
    service: SessionServiceType;
    start_date_time: string;
    end_date_time: string;
    recurrence_rule: SessionRecurrenceRule;
    delivery_option: SessionDeliveryOption;
    is_cancelled: boolean;
    no_of_sessions: number;
    first_session_date: string | null;
    session_day: string;
    client: {
        client_id: string;
        first_name: string;
        last_name: string;
    };
    provider: ISessionProvider;
    supervisor?: {
        provider_id: string;
        first_name: string;
        last_name: string;
    };
}

export interface ISessionProvider {
    provider_id: string;
    first_name: string;
    last_name: string;
}
