import { ISortTable } from "../../../utils/types";

export enum PaymentStatus {
    COMPLETED = "completed",
    PENDING = "pending",
    FAILED = "failed",
    REFUNDED = "refunded",
    VOIDED = "voided",
}

export enum PaymentChannel {
    MANTLE = "mantle",
    AUTHORIZE_NET = "authorize_net",
    WAYSTAR = "waystar",
}

export interface IMatchedPayment extends ISortTable {
    amount: number;
    payment_method: string;
    date_of_service: string;
    payment_date: string;
    invoice_status: string;
    payment_status: string;
    auth_code: string;
    client_name: string;
    transaction_id: string;
    posted_by: {
        user_id?: string;
        first_name: string;
        last_name: string;
    };
    invoice_id: string;
    cpt_code: string;
    processor_payment_status: string;
    accounting: number;
    payment_id: string;
    cheque_number: string;
    client_id: string;
    note_id: string;
    insurance_outstanding: number;
    payment_channel: PaymentChannel;
}

export interface IUnmatchedPayment {
    payment_date: string;
    client_name: string;
    payment_method: string;
    client_id: string;
    unmatched_payment_id: string;
    cheque_number?: string;
    posted_by?: {
        first_name: string;
        last_name: string;
    };
    amount: number;
    auth_code?: string;
    payment_status: string;
    non_closed_invoice: number;
    client_invoices: IClientInvoice[];
    transaction_id: string;
    processor_payment_status: string;
}

export interface IClientInvoice {
    cpt_code: string;
    amount_charged: number;
    total_amount_collected: number;
    date_of_service: string;
    note_id: string;
    provider_name: string;
    invoice_status: string;
    invoice_id: string;
}

export interface IMatchedPaymentListFilterTypes {
    to_date: null | string;
    from_date: null | string;
    invoice_status?: string[];
    show_fully_paid_invoices?: string;
    search_string?: string;
    custom_date?: string;
    page: number;
    limit: number;
    sort_attribute?: string;
    sort_order?: string;
}

export interface IUnmatchedPaymentFilterTypes {
    search_string?: string;
    custom_date?: string;
    invoice_status?: string;
    page: number;
    limit: number;
    to_date: null | string;
    from_date: null | string;
    sort_attribute?: string;
    sort_order?: string;
}

export interface IRefreshPaymentStatusPayload {
    payment_ids: string[];
}

export interface IRefreshedPaymentStatusResponse {
    message: string;
    updated_payments: string[];
    unupdated_payments: string[];
}

export interface IUpdatedInvoiceResponse {
    message: string;
    updated_invoices: string[];
    unupdated_invoices: string[];
}

export interface IDeleteUnmatchedPaymentReponse {
    message: string;
    deleted_payments: string[];
    undeleted_payments: string[];
}

export interface IUpdateInvoicePayload {
    invoice_ids: string[];
    status: string;
    issue_category?: string;
    issue_notes?: string;
}

export interface IDeleteUnmatchedPaymentPayload {
    unmatched_payment_ids: string[];
}

export interface IMatchedPaymentResponse {
    message: string;
    added_payments: string[];
    unadded_payments: string[];
}

export interface IPaymentToMatchPayload {
    unmatched_payment_matches: {
        [invoiceId: string]: string[];
    };
}

export interface IRematchPayment {
    payment_id: string;
    invoice_id: string;
}

export interface ISplitPaymentPayload {
    payment_id: string;
    invoices: {
        invoice_id: string;
        amount: number;
    }[];
}

export interface ISplitPaymentResponse {
    created_payments: string[];
}
