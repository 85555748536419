import { useParams } from "react-router-dom";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useQueryClient } from "@tanstack/react-query";
import { Controller, useForm } from "react-hook-form";
import { Input, Select } from "@jhool-io/fe-components";
import { useFetchClientCreditCards } from "../../../../../hooks/queries/client";
import { useBulkPayInvoices } from "../../../../../hooks/mutations/billing";
import useToast from "../../../../../hooks/useToast";
import { IBulkPayment } from "../../../../../utils/types/billing";
import Skeleton from "../../../../../components/Skeleton/Skeleton";
import { ClientCardTypes } from "../../../../../utils/types/client";
import useCreditCardInput from "../../../../../hooks/useCreditCard";
import { IBillingResponse } from "../../../../billing/types/billing.types";
import {
    cn,
    formatDate,
    showMoneyInAppFormat,
} from "../../../../../utils/helpers";

interface InvoicePaymentFormProps {
    /** function to call when the form's submit button is clicked */
    onFormSubmit(): void;
    // Array of invoices to be paid
    invoices: IBillingResponse[];
}

type Option = {
    label: React.ReactNode;
    value: boolean;
};

export default function InvoicePaymentForm({
    onFormSubmit,
    invoices,
}: InvoicePaymentFormProps) {
    const schema = yup.object().shape({
        invoices: yup.array().of(
            yup.object().shape({
                amount: yup
                    .string()
                    .matches(
                        /^(?!0$)\d+(\.\d+)?$/,
                        "Please enter a valid amount greater than zero"
                    ),
                invoice_id: yup.string().required("Invoice ID is required"),
            })
        ),
        payment_card_id: yup.string(),
    });

    const {
        handleSubmit,
        control,
        register,
        watch,
        formState: { errors },
    } = useForm<IBulkPayment>({
        resolver: yupResolver(schema),
        mode: "onChange",
    });

    // Get noteId from url params
    const params = useParams();
    const clientId = params.clientId as string;

    // Fetch client credit cards
    const {
        data: cards,
        isLoading,
        error,
    } = useFetchClientCreditCards(clientId);

    const bulkPayInvoices = useBulkPayInvoices();

    const { toast } = useToast();

    const { cardImages } = useCreditCardInput();

    const formInvoices = watch("invoices");

    const total = formInvoices
        ? Number(
              formInvoices
                  .filter((invoice) => invoice.amount || 0)
                  .reduce(
                      (accumulator, invoice) =>
                          accumulator + Number(invoice.amount),
                      0
                  )
                  .toFixed(2)
          )
        : 0;

    const paymentTypeForSelect = ["co-insurance", "pr100"].map((type) => ({
        label: type.toUpperCase(),
        value: type,
    }));

    // Function to determine credit card provider icon to display
    const getCreditCardIconToDisplay = (cardType: string) => {
        switch (cardType) {
            case ClientCardTypes.AMERICAN_EXPRESS:
                return cardImages.amex;

            case ClientCardTypes.MASTERCARD:
                return cardImages.mastercard;

            case ClientCardTypes.DISCOVER_FINANCIAL:
                return cardImages.discover;

            case ClientCardTypes.CHASE:
                return cardImages.chase;

            case ClientCardTypes.JCB:
                return cardImages.jcb;

            case ClientCardTypes.CAPITAL_ONE:
                return cardImages.capitalone;

            case ClientCardTypes.VISA:
                return cardImages.visa;

            case ClientCardTypes.DINERSCLUB:
                return cardImages.dinersclub;

            default:
                return cardImages.capitalone;
        }
    };

    const clientCardsForSelect = cards?.data
        ?.filter((card) => !card.is_card_expired)
        .map((card) => ({
            label: (
                <div className="flex items-center justify-between">
                    <div className="flex justify-between items-center bg-[#F7FAF5] px-12 py-4 rounded-r6 gap-x-32 w-[200px] ">
                        {getCreditCardIconToDisplay(card.card_type)}
                        <p className=" text-sm font-medium">
                            {card.masked_card_number}
                        </p>
                    </div>

                    <span className="text-[#5B6A7F] flex flex-wrap text-xs leading-[16px] w-6/12 ml-auto">
                        {card.card_label}
                    </span>
                </div>
            ),
            value: card.payment_card_id,
        }));

    // Query client
    const queryClient = useQueryClient();

    const clientCurrentCards = cards?.data.filter((card) =>
        Boolean(card.is_current)
    );

    const getDefaultCard = () => {
        if (clientCurrentCards && clientCurrentCards.length !== 0) {
            return clientCurrentCards[clientCurrentCards.length - 1]
                .payment_card_id;
        }

        if (
            clientCurrentCards &&
            clientCurrentCards.length === 0 &&
            cards &&
            cards.data.length !== 0
        ) {
            return cards.data[cards.data.length - 1].payment_card_id;
        }

        return "";
    };

    const onSubmit = (data: IBulkPayment) => {
        const filteredInvoices = data.invoices.filter(
            (invoice) => invoice.amount
        );

        const payload = {
            ...data,
            invoices: filteredInvoices.map((invoice) => ({
                amount: Number(invoice.amount),
                invoice_id: invoice.invoice_id,
            })),
            payment_type: data.payment_type === "pr100" ? "pr100" : undefined,
        };

        bulkPayInvoices.mutate(payload, {
            onSuccess: (res) => {
                queryClient.invalidateQueries({
                    queryKey: ["invoices"],
                });
                toast({
                    mode: "success",
                    message:
                        res.data.invoices_not_charged.length > 0
                            ? `${res.data.invoices_charged.length} out of ${
                                  res.data.invoices_not_charged.length +
                                  res.data.invoices_charged.length
                              } invoices charged successfully `
                            : "Invoices charged successfully.",
                });
                onFormSubmit();
            },

            onError: () => {
                toast({
                    mode: "error",
                    message: "Could not charge invoices at this time.",
                });
            },
        });
    };

    return (
        <form id="invoice-payment" onSubmit={handleSubmit(onSubmit)}>
            <p className="text-center text-sm font-medium mb-24">
                A sum of{" "}
                <span className="text-primary">
                    {showMoneyInAppFormat(total)}
                </span>{" "}
                will be deducted from your card
            </p>

            <p className="text-center mx-auto max-w-[60%] mb-24 text-sm font-medium">
                This charge will be made to the last payment card used.Kindly
                confirm proceed.
            </p>
            <div className="fg">
                <Controller
                    name="payment_type"
                    control={control}
                    render={({ field }) => (
                        <Select
                            label="Payment type"
                            placeholder="Payment type"
                            options={paymentTypeForSelect}
                            onChange={(val) => {
                                field.onChange((val as Option).value);
                            }}
                            isOptional
                            isLongListInDialog
                        />
                    )}
                />
            </div>
            <div className="fg p-12 rounded-r8 border-[0.2px] border-stroke max-h-[250px] overflow-y-auto">
                {invoices.map((invoice, i) => (
                    <div
                        className={`flex items-center gap-x-8 justify-between ${
                            i === invoices.length - 1 ? "" : "mb-8"
                        }`}
                        key={invoice.invoice_id}
                    >
                        <div className="flex gap-x-8 items-center text-sm">
                            <span>{formatDate(invoice.date_of_service)},</span>
                            <span>
                                CPT: {invoice.cpt_code},{" "}
                                <span
                                    className={cn({
                                        "text-danger":
                                            invoice.accounting_coinsurance > 0,
                                    })}
                                >
                                    CINS DUE:{" "}
                                    {invoice.accounting_coinsurance > 0
                                        ? `(${showMoneyInAppFormat(
                                              invoice.accounting_coinsurance
                                          )})`
                                        : showMoneyInAppFormat(
                                              Math.abs(
                                                  invoice.accounting_coinsurance
                                              )
                                          )}
                                </span>
                            </span>
                        </div>
                        <div>
                            <Input
                                {...register(`invoices.${i}.amount`)}
                                showCurrency
                                placeholder="Enter Amount"
                                errorText={
                                    errors?.invoices?.[i]?.amount?.message
                                }
                                hasError={!!errors.invoices?.[i]?.amount}
                                errorTextClassName="text-[10px] text-danger"
                            />
                            <input
                                type="hidden"
                                value={invoice.invoice_id}
                                {...register(`invoices.${i}.invoice_id`)}
                            />
                        </div>
                    </div>
                ))}
            </div>

            {isLoading && (
                <Skeleton className=" w-full h-[6rem] rounded-r6 mb-32" />
            )}

            {error && (
                <>
                    <div className=" text-sm h-[6rem] flex items-center px-24 text-danger border border-[rgba(185,_186,_163,_0.4)] bg-[rgba(185,_186,_163,_0.1)] rounded-r6 mb-[1rem]">
                        Error loading client&apos;s cards.
                    </div>
                    <p className="mb-[2rem] text-xs text-danger">
                        Kindly link the client merchant id to client profile on
                        Authorize.For more information{" "}
                        <a
                            href="https://docs.google.com/document/d/16Sl2vNO7U4Mzk8HGCbN1mDf--awHw0vT_pkxNBcfr3U/edit?usp=sharing"
                            target="_blank"
                            rel="noreferrer"
                            className="underline"
                        >
                            click here
                        </a>
                    </p>
                </>
            )}

            {cards?.data && (
                <div className="fg">
                    <Controller
                        name="payment_card_id"
                        control={control}
                        defaultValue={
                            clientCardsForSelect?.find(
                                (card) =>
                                    card.value ===
                                    (clientCurrentCards &&
                                    clientCurrentCards.length !== 0
                                        ? clientCurrentCards[
                                              clientCurrentCards.length - 1
                                          ].payment_card_id
                                        : "")
                            )?.value
                        }
                        render={({ field }) => (
                            <Select
                                label="Payment card"
                                placeholder="Card to charge"
                                defaultValue={clientCardsForSelect?.filter(
                                    (card) => card.value === getDefaultCard()
                                )}
                                options={clientCardsForSelect}
                                onChange={(val) => {
                                    field.onChange((val as Option).value);
                                }}
                                isDisabled={
                                    !cards || Boolean(error) || isLoading
                                }
                                menuPlacement="bottom"
                                isLongListInDialog
                            />
                        )}
                    />
                </div>
            )}
        </form>
    );
}
