import * as React from "react";
import { useParams } from "react-router-dom";
import { showMoneyInAppFormat } from "../../../../utils/helpers";
import { useFetchInvoices } from "../../hooks/queries/billing.queries";
import { LISTS_DEFAULT_LIMIT } from "../../../../utils/constants";
import ListState from "../../../../components/ListState/ListState";
import Skeleton from "../../../../components/Skeleton/Skeleton";

export default function PaymentsOverview() {
    // Get id from url params
    const params = useParams();
    const clientId = params.clientId as string;

    // Hook for fetching all invoice
    const { data, isLoading, error, isSuccess } = useFetchInvoices({
        client_id: clientId,
        limit: LISTS_DEFAULT_LIMIT,
        page: 1,
    });

    const summary = data?.summary;

    const addBracketToAmount = (value: string) => `(${value})`;

    return (
        <>
            {isLoading ? (
                <Skeleton
                    height={173}
                    width="100%"
                    className="mb-24 rounded-r12"
                />
            ) : null}
            {error ? (
                <ListState
                    isError
                    errorMsg={
                        error?.response?.data.message ||
                        `Cannot display payment info at this time please try again later`
                    }
                    listHeader="Payment Info"
                    stateHelperText="Try reloading this page to solve this issue"
                />
            ) : null}
            {data?.data && isSuccess ? (
                <div className="p-8 bg-white rounded-r12">
                    <div className="w-full rounded-r12 border border-[rgba(231,231,231,0.91)]">
                        <div className="grid grid-cols-4 gap-x-4 bg-card-bg py-8 border-b border-b-[rgba(231,231,231,0.91)] px-24 text-gray text-xs font-semibold uppercase rounded-tl-r12 rounded-tr-r12">
                            <span />
                            <span> OUTSTANDING</span>
                            <span>COLLECTED</span>
                            <span>WRITE-OFF</span>
                        </div>
                        <div className="grid grid-cols-4 gap-x-4 bg-card-bg py-8 border-b border-b-[rgba(231,231,231,0.91)] px-24 text-black font-semibold text-sm">
                            <span className="text-gray text-sm font-medium">
                                INSURANCE
                            </span>
                            <span
                                className={
                                    summary?.total_insurance_amount_owed &&
                                    summary.total_insurance_amount_owed > 0
                                        ? "text-danger"
                                        : "text-black"
                                }
                            >
                                {summary?.total_insurance_amount_owed &&
                                summary.total_insurance_amount_owed <= 0
                                    ? showMoneyInAppFormat(
                                          Math.abs(
                                              summary.total_insurance_amount_owed
                                          )
                                      )
                                    : (summary?.total_insurance_amount_owed &&
                                          addBracketToAmount(
                                              showMoneyInAppFormat(
                                                  summary.total_insurance_amount_owed
                                              )
                                          )) ||
                                      showMoneyInAppFormat(0)}
                            </span>
                            <span>
                                {(summary &&
                                    showMoneyInAppFormat(
                                        summary.total_insurance_amount_collected
                                    )) ||
                                    0}
                            </span>
                            <span>
                                {(summary &&
                                    showMoneyInAppFormat(
                                        summary.total_insurance_amount_written_off
                                    )) ||
                                    0}
                            </span>
                        </div>
                        <div className="grid grid-cols-4 gap-x-4 bg-white py-8 border-b border-b-[rgba(231,231,231,0.91)] px-24 text-black font-semibold text-sm">
                            <span className="text-gray text-sm font-medium">
                                CO-INSURANCE
                            </span>
                            <span
                                className={
                                    summary?.total_coinsurance_amount_owed &&
                                    summary.total_coinsurance_amount_owed > 0
                                        ? "text-danger"
                                        : "text-black"
                                }
                            >
                                {summary?.total_coinsurance_amount_owed &&
                                summary.total_coinsurance_amount_owed <= 0
                                    ? showMoneyInAppFormat(
                                          Math.abs(
                                              summary.total_coinsurance_amount_owed
                                          )
                                      )
                                    : (summary?.total_coinsurance_amount_owed &&
                                          addBracketToAmount(
                                              showMoneyInAppFormat(
                                                  summary.total_coinsurance_amount_owed
                                              )
                                          )) ||
                                      showMoneyInAppFormat(0)}
                            </span>
                            <span>
                                {(summary &&
                                    showMoneyInAppFormat(
                                        summary.total_coinsurance_amount_collected
                                    )) ||
                                    0}
                            </span>
                            <span>
                                {(summary &&
                                    showMoneyInAppFormat(
                                        summary.total_coinsurance_amount_written_off
                                    )) ||
                                    0}
                            </span>
                        </div>
                        <div className="grid grid-cols-4 gap-x-4 bg-white py-8 border-b border-b-[rgba(231,231,231,0.91)] px-24 text-black font-semibold text-sm">
                            <span className="text-gray text-sm font-medium">
                                UNMATCHED
                            </span>
                            <span>--</span>
                            <span>
                                {(summary &&
                                    showMoneyInAppFormat(
                                        summary.total_unmatched_amount
                                    )) ||
                                    0}
                            </span>
                            <span>--</span>
                        </div>
                        <div className="grid grid-cols-4 gap-x-4 bg-card-bg py-8 px-24 text-black font-semibold text-sm rounded-bl-r12 rounded-br-r12">
                            <span className="text-gray text-sm font-medium">
                                PR100
                            </span>
                            <span
                                className={
                                    summary?.total_pr_100_amount_owed &&
                                    summary.total_pr_100_amount_owed > 0
                                        ? "text-danger"
                                        : "text-black"
                                }
                            >
                                {summary?.total_pr_100_amount_owed &&
                                summary.total_pr_100_amount_owed <= 0
                                    ? showMoneyInAppFormat(
                                          Math.abs(
                                              summary.total_pr_100_amount_owed
                                          )
                                      )
                                    : (summary?.total_pr_100_amount_owed &&
                                          addBracketToAmount(
                                              showMoneyInAppFormat(
                                                  summary.total_pr_100_amount_owed
                                              )
                                          )) ||
                                      showMoneyInAppFormat(0)}
                            </span>
                            <span>
                                {(summary &&
                                    showMoneyInAppFormat(
                                        summary.total_pr_100_amount_collected
                                    )) ||
                                    0}
                            </span>
                            <span>--</span>
                        </div>
                    </div>
                </div>
            ) : null}
        </>
    );
}
